import React, {
  useState,
  useMemo,
  FC,
  useEffect,
  HtmlHTMLAttributes,
} from "react";
import {
  FilterMenu as LuiFilterMenu,
  ToggleButtonGroup,
  Grid,
  Theme,
  useMediaQuery,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { Filters } from "./Documents";
import { makeStyles } from 'tss-react/mui';

interface FilterMenuProps
  extends Omit<HtmlHTMLAttributes<HTMLElement>, "onChange"> {
  filters: Filters;
  onChange: (newFilters: Filters) => void;
  onClear: () => void;
}

const useStyles = makeStyles()({
  mobileToggleButton: {
    fontSize: "12px",
  },
});

const FilterMenu: FC<FilterMenuProps> = ({ filters, onChange, onClear }) => {
  const { classes, cx } = useStyles();
  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [state, setState] = useState(filters);

  useEffect(() => {
    setState(filters);
  }, [filters]);

  const handleConfirm = () => onChange(state);

  const handleCancel = () => setState(filters);

  const handleClear = () => onClear();

  const activeCount = useMemo(() => {
    let result = 0;
    if (!filters.onlyMine) result++;

    return result;
  }, [state]);

  const toggleData = [
    {
      label: t("Mine"),
      value: true,
    },
    {
      label: t("All"),
      value: false,
    },
  ];

  return (
    <LuiFilterMenu
      activeCount={activeCount}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      onClear={handleClear}
    >
      <Grid container direction="column">
        <ToggleButtonGroup
          orientation={isMobile ? "vertical" : "horizontal"}
          value={state.onlyMine}
          exclusive
          options={toggleData}
          onChange={(e, value) => {
            if (value === null) return;
            setState((prev) => ({ ...prev, onlyMine: value }));
          }}
          classes={{
            grouped: cx({
              [classes.mobileToggleButton]: i18n.language === "ru" && isMobile,
            }),
          }}
        />
      </Grid>
    </LuiFilterMenu>
  );
};

export default FilterMenu;
