import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const useMarkDeclarationDiscussionAsRead = () => {
  const [result] = useMutation<null, { disc_id: number; read_date: Date }>(gql`
    mutation ADIT_MARK_DECLARATION_DISCUSSION_AS_READ(
      $disc_id: bigint
      $read_date: timestamptz!
    ) {
      update_declaration_message(
        _set: { read_date: $read_date }
        where: {
          discussion_id: { _eq: $disc_id }
          read_date: { _is_null: true }
          user: { tenant_id: { _neq: "b4b61b73-4f38-45a0-b460-7e38b2777479" } }
        }
      ) {
        returning {
          discussion_id
        }
      }
    }
  `);

  return result;
};

export default useMarkDeclarationDiscussionAsRead;
