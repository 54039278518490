import React, { FC } from "react";
import {
  Typography,
  Button,
  Theme,
  Paper,
  useMediaQuery,
} from "@periplus/ui-library";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { Tenant } from "graphql/hooks/useGetTenants";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { makeStyles } from 'tss-react/mui';

const colors = [
  "#2EE7AF",
  "#3971FF",
  "#FF65C0",
  "#FFB800",
  "#A258FF",
  "#EB5757",
];

interface TenantCardProps {
  tenant: Tenant;
  index: number;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "grid",
    gridTemplate: `
        "title    controls"
        "divider  divider"
        "fields   fields"
        /1fr      auto
    `,
    "&:not(:last-child)": {
      marginBottom: 12,
    },
    [theme.breakpoints.up("md")]: {
      padding: 10,
      columnGap: 24,
      gridTemplate: `
            "title  divider  fields  controls"
            /175px  auto     1fr     auto
        `,
      alignItems: "center",
    },
  },
  title: {
    gridArea: "title",
    display: "flex",
    alignItems: "center",
    padding: "16px 4px 16px 16px",
    [theme.breakpoints.up("md")]: {
      padding: "unset",
    },
  },
  controls: {
    gridArea: "controls",
    padding: "16px 16px 16px 4px",
    display: "flex",
    alignItems: "center",
    justifySelf: "right",
    [theme.breakpoints.up("md")]: {
      padding: "unset",
    },
  },
  divider: {
    gridArea: "divider",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      borderTop: "unset",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    },
  },
  linkButton: {
    backgroundColor: "#FAFAFE",

    "& .MuiButton-startIcon": {
      margin: 0,

      [theme.breakpoints.up("md")]: {
        marginRight: 8,
        marginLeft: -4,
      },
    },
  },
  fields: {
    gridArea: "fields",
    padding: 16,
    display: "grid",
    alignItems: "center",
    gap: "10px",
    gridTemplateColumns: "repeat(2, auto)",
    [theme.breakpoints.up("sm")]: {
      gap: "10px 32px",
      gridTemplateColumns: "unset",
      gridTemplateRows: "repeat(2, 1fr)",
      gridAutoFlow: "column",
    },
    [theme.breakpoints.up("md")]: {
      padding: "unset",
    },
  },
  fieldValue: {
    minHeight: 18,
    textAlign: "right",
    color: "#4F4F4F",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("sm")]: {
      textAlign: "unset",
    },
  },
  tenantIcon: {
    width: 36,
    height: 36,
    minWidth: 36,
    minHeight: 36,
    borderRadius: "50%",
    backgroundColor: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 16,
  },
}));

const TenantCard: FC<TenantCardProps> = ({ tenant, index }) => {
  const { classes } = useStyles();
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const { t } = useTranslation();
  const tenantNameAbbreviation = tenant.tenant_name
    .split(" ")
    .slice(0, 2)
    .reduce((acc, el) => acc + (el[0]?.toUpperCase() || ""), "");
  const color = colors[index % 6];

  return (
    <Paper className={classes.root} square variant="outlined">
      <div className={classes.title}>
        <div
          className={classes.tenantIcon}
          style={{ backgroundColor: `${color}50` }}
        >
          <Typography variant="subtitle1" style={{ color }}>
            {tenantNameAbbreviation}
          </Typography>
        </div>
        <Typography variant="subtitle1" color="textPrimary">
          {tenant.tenant_name}
        </Typography>
      </div>
      <div className={classes.controls}>
        <Link
          to={`/settings/tenants/${tenant.id}/settings`}
          style={{ marginRight: 8 }}
        >
          <Button
            className={classes.linkButton}
            variant="outlined"
            color="primary"
            startIcon={<SettingsOutlinedIcon />}
          >
            {isTablet && t("common:setup")}
          </Button>
        </Link>
        <Link to={`/settings/tenants/${tenant.id}`}>
          <Button
            className={classes.linkButton}
            variant="outlined"
            color="primary"
            startIcon={<LaunchIcon />}
          >
            {isTablet && t("details")}
          </Button>
        </Link>
      </div>
      <div className={classes.divider} />
      <div className={classes.fields}>
        {[
          {
            label: t("common:creationDate"),
            text: dayjs(tenant.creation_date).format("DD.MM.YYYY"),
          },
          {
            label: t("common:createdBy"),
            text: tenant.creationUser.display_name || tenant.creation_user,
          },
          {
            label: t("common:lastUpdated"),
            text:
              tenant.last_modified_date &&
              dayjs(tenant.last_modified_date).format("DD.MM.YYYY"),
          },
          {
            label: t("common:lastUpdatedBy"),
            text:
              tenant.lastModifiedUser?.display_name ||
              tenant.last_modified_user,
          },
        ].map(({ label, text }) => (
          <React.Fragment key={label}>
            <Typography
              noWrap
              variant="overline"
              color="textSecondary"
              title={label}
            >
              {label}
            </Typography>
            <Typography
              noWrap
              color="textSecondary"
              title={text}
              className={classes.fieldValue}
            >
              {text}
            </Typography>
          </React.Fragment>
        ))}
      </div>
    </Paper>
  );
};

export default TenantCard;
