import React from "react";
import { Tabs, Theme } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { makeStyles } from 'tss-react/mui';

interface Props {
  status: "open" | "done";
  count: {
    open: number;
    finished: number;
  };
  onChange(status: "open" | "done"): void;
}

const useStyles = makeStyles()((theme: Theme) =>
  ({
    tabsContainer: {
      minHeight: theme.spacing(4.5),
    },

    indicatorColor: {
      backgroundColor: theme.palette.primary.main,
    },

    tab: {
      minWidth: theme.spacing(13),
      minHeight: theme.spacing(4.5),
      textTransform: "capitalize",
      "&.Mui-selected": {
        color: theme.palette.primary.main,
      },
    }
  }));

const TasksStatusTabs = ({ status, count, onChange }: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <Tabs
      selected={status === "open" ? 0 : 1}
      onChange={(newTab) => onChange(newTab === 0 ? "open" : "done")}
      classes={{
        root: classes.tabsContainer,
        indicator: classes.indicatorColor,
      }}
      tabs={[
        { value: 0, label: `${t("tasks:Open")} (${count.open})` },
        { value: 1, label: `${t("tasks:Done")} (${count.finished})` },
      ]}
    />
  );
};

export default TasksStatusTabs;
