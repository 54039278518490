import React, { FC, useEffect } from "react";
import { Loader } from "@periplus/ui-library";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AuthRoutes from "./Auth";
import NonAuthRoutes from "./NonAuth";

import { useAuth } from "contexts/AuthContext";
import useFirebaseAnalytics from "hooks/useFirebaseAnalytics";
import { useApplicationAction } from "contexts/ApplicationContext";

import ErrorBoundary from "../ErrorBoundary";

const useAnalytics = () => {
  const { analytics } = useFirebaseAnalytics();
  const { isAuthenticated, user } = useAuth();
  const { i18n } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(useLocation().search);
  const appAction = useApplicationAction();

  const search = queryParams.get("q");

  useEffect(() => {
    /* eslint-disable no-restricted-globals */
    if (screen) {
      // @ts-ignore
      analytics.logEvent("screen_view", {
        screen_resolution: `${screen.width}x${screen.height}`,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    analytics.setUserProperties({ guest: !isAuthenticated });

    if (isAuthenticated && user) {
      analytics.setUserProperties({ name: user.name });
      analytics.setUserId(user.userId);
    }
    // eslint-disable-next-line
  }, [isAuthenticated, user]);

  useEffect(() => {
    const { pathname } = location;
    appAction.saveLocation(pathname);
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    const { pathname } = location;

    analytics.logEvent("page_view", {
      page_location: pathname,
      search,
      language: i18n.language,
    });
    // eslint-disable-next-line
  }, [location, i18n.language]);

  useEffect(() => {
    // @ts-ignore
    analytics.logEvent("search", { search_term: search });
    //eslint-disable-next-line
  }, []);
};

const Routes: FC = () => {
  const { loading, isAuthenticated, isAuthorized } = useAuth();
  useAnalytics();

  if (loading) {
    return <Loader />;
  }

  const UserRoutes =
    isAuthenticated && isAuthorized ? AuthRoutes : NonAuthRoutes;
  return (
    <ErrorBoundary>
      <UserRoutes />
    </ErrorBoundary>
  );
};

export default Routes;
