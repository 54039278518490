import React, { FC } from "react";
import { Box, Tooltip } from "@periplus/ui-library";
import { CheckBox } from "@mui/icons-material";
import noop from "lodash/noop";
import { useTranslation } from "react-i18next";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  alreadyClassified: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 2,
    cursor: "default",
    backgroundColor: "rgba(199, 214, 222, 0.22)",
  },
  checkIcon: {
    position: "absolute",
    left: 10,
    bottom: 10,
    fontSize: 40,
    color: "rgb(63,81,181)",
  },
});

interface IDocumentSelector {
  selected?: boolean;
  alreadyClassified?: boolean;
  onDeselect?: () => void;
  [key: string]: any;
}

const DocumentSelector: FC<IDocumentSelector> = ({
  children,
  selected = false,
  onDeselect = noop,
  alreadyClassified = false,
  ...rest
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
      }}
      style={{ userSelect: "none" }}
      {...rest}
    >
      <>
        {alreadyClassified && (
          <Tooltip
            title={t(`classify:alreadyClassified`) as string}
            enterDelay={500}
            leaveDelay={200}
          >
            <Box className={classes.alreadyClassified} />
          </Tooltip>
        )}
        {children}
        {selected && (
          <CheckBox className={classes.checkIcon} onClick={onDeselect} />
        )}
      </>
    </Box>
  );
};

export default DocumentSelector;
