import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
} from "@periplus/ui-library";
import FolderOpenTwoToneIcon from "@mui/icons-material/FolderOpenTwoTone";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  listItemIcon: {
    minWidth: "auto",
    paddingRight: theme.spacing(2),
    color: "#604DFF",
  },
  listItem: {
    position: "relative",
    paddingLeft: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    width: `calc(100% - ${theme.spacing(1.5)})`,

    "&:before": {
      content: '""',
      position: "absolute",
      height: "50%",
      borderBottom: "1px dashed #C8C8D4",
      borderLeft: "1px dashed #C8C8D4",
      top: 0,
      left: 0,
      width: theme.spacing(1),
    },

    "&:not(:last-child):after": {
      content: '""',
      position: "absolute",
      height: "50%",
      borderLeft: "1px dashed #C8C8D4",
      bottom: 0,
      left: 0,
      width: 1,
    },
  },
  padding4: {
    padding: 4,
  },
}));

type FileTreeProps = {
  title?: string;
  files: React.ReactNode[];
  onItemClick?: (index: number) => void;
};

const FileTree = ({ title, files, onItemClick }: FileTreeProps) => {
  const { classes } = useStyles();

  return (
    <List dense>
      <ListItem disableGutters>
        <ListItemIcon className={classes.listItemIcon}>
          <FolderOpenTwoToneIcon />
        </ListItemIcon>
        {title && <ListItemText primary={title} />}
      </ListItem>
      {files.map((text, index) => (
        <div className={classes.listItem} key={index}>
          <ListItem
            key={index}
            disableGutters
            button
            className={classes.padding4}
            onClick={() => onItemClick && onItemClick(index)}
          >
            {typeof text === "string" ? <ListItemText primary={text} /> : text}
          </ListItem>
        </div>
      ))}
    </List>
  );
};

export default FileTree;
