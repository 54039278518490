import React, { FC, useState, useEffect, useMemo } from "react";
import {
  ThreeDotsMenu,
  ListItemIcon,
  CircularProgress,
  ListItemText,
} from "@periplus/ui-library";
import { GetAppOutlined, DeleteOutlined } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import useErrorHandling from "hooks/useErrorHandling";
import { DELETE_DOCUMENT, SCHEDULE_DOCUMENT } from "graphql/mutations/document";
import { getDownloadUrlAsync } from "utils/azureBlobStorageUtils";
import { Text } from "components/Text";
import { Blob } from "../types";

type Close = () => void;

interface RowActionsProps {
  id: string;
  document_blobs?: {
    blobs: Blob[];
  };
  name: string;
  status?: string;
  onDelete: (id: string) => void;
}

const DocumentRowActions: FC<RowActionsProps> = ({
  id,
  document_blobs,
  name,
  status,
  onDelete,
}) => {
  const [deleteDocument] = useMutation(DELETE_DOCUMENT);
  const [scheduleDocument] = useMutation(SCHEDULE_DOCUMENT);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const withErrorHandling = useErrorHandling();
  const [deleting, setDeleting] = useState<{
    close?: Close;
  }>({});

  const url = useMemo(
    () => document_blobs?.blobs?.slice(-1)?.[0]?.url || "",
    [document_blobs]
  );

  useEffect(() => {
    setDeleting({});
    if (deleting.close) {
      deleting.close();
    }
    // eslint-disable-next-line
  }, [id]);

  const options = useMemo(() => {
    let baseOptions = [
      {
        disabled: !url,
        content: (
          <>
            <ListItemIcon style={{ minWidth: 28 }}>
              <GetAppOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("common:download")}</ListItemText>
          </>
        ),
        action: async (close: Close) => {
          if (url) {
            window.open(
              await getDownloadUrlAsync(
                url,
                "application/pdf",
                `inline; filename="${encodeURI(name)}"`
              ),
              "_blank"
            );
          }
          close();
        },
      },
      {
        content: (
          <>
            <ListItemIcon style={{ minWidth: 28 }}>
              {deleting.close ? (
                <CircularProgress size={24} thickness={4.5} />
              ) : (
                <DeleteOutlined fontSize="small" />
              )}
            </ListItemIcon>
            <Text>common:delete</Text>
          </>
        ),
        action: (close: Close) => {
          setDeleting({ close });
          deleteDocument({
            variables: { id },
          })
            .then(() => {
              onDelete(id);
            })
            .catch((e) => {
              enqueueSnackbar(t("common:serverError"), { variant: "error" });
            });
        },
      },
    ];
    if (status === "thumbnails_failed") {
      baseOptions = [
        ...baseOptions,
        {
          content: (
            <>
              <Text>common:reschedule</Text>
            </>
          ),
          action: async (close: Close) => {
            await withErrorHandling(scheduleDocument, {
              variables: {
                documentIds: [id],
              },
            });
            close();
          },
        },
      ];
    }
    return baseOptions;
  }, [
    deleteDocument,
    scheduleDocument,
    deleting.close,
    status,
    id,
    onDelete,
    url,
    t,
    enqueueSnackbar,
    withErrorHandling,
    name,
  ]);

  return <ThreeDotsMenu options={options} />;
};

export default DocumentRowActions;
