import React, { FC, useState } from "react";
import { BoxProps } from "@periplus/ui-library";
import SmallThumbnail from "./SmallThumbnail";
import BigThumbnail from "./BigThumbnail";
import { DeclarationDocumentTableEntity } from "../hooks/useGetDeclarationDocumentsTable";

interface PagesProps extends BoxProps {
  document: DeclarationDocumentTableEntity;
}

const Pages: FC<PagesProps> = ({ document }) => {
  const [bigThumbnailOpen, setBbigThumbnailOpen] = useState(false);

  return (
    <>
      <SmallThumbnail
        pages={document.pages}
        extension={document.documentExtension}
        onClick={() => setBbigThumbnailOpen(true)}
      />
      {bigThumbnailOpen && (
        <BigThumbnail
          pages={document.pages}
          onClose={() => setBbigThumbnailOpen(false)}
        />
      )}
    </>
  );
};

export default Pages;
