import React, { FC, HtmlHTMLAttributes, useMemo } from "react";
import {
  SubTable as LuiSubTable,
  SubTableColumns,
  SubTableRows,
  SubTableRow,
  Theme,
} from "@periplus/ui-library";
import { Document } from "domain/document/types";
import { useTranslation } from "react-i18next";
import { getExtensionDescriptionIcon } from "../extensionDescription";
import dayjs from "dayjs";
import { useAuth } from "contexts/AuthContext";
import DocumentStatus from "domain/document/components/DocumentStatus";
import DocumentRowActions from "domain/document/components/DocumentRowActions";
import getDocumentExtension from "utils/getDocumentExtension";
import { makeStyles } from 'tss-react/mui';

interface SubTableProps
  extends Omit<HtmlHTMLAttributes<HTMLElement>, "onSelect"> {
  rows: SubTableRows<Document>;
  onSelect: (newSubTableRows: SubTableRows<Document>) => void;
  onMultiSelect: (
    newSubTableRows: SubTableRows<Document>,
    toggledSubTableRow: SubTableRow<Document>
  ) => void;
  onDelete: (id: string) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  columnCell: {
    wordBreak: "break-all",
  },
  attachmentNameColumn: {
    width: "30%",
  },
  pagesColumn: {
    width: "5%",
  },
  typeColumn: {
    width: "5%",
  },
  creationDateColumn: {
    width: "24%",
  },
  statusColumn: {
    width: "24%",
  },
  actionsColumn: {
    width: "0%",
    textAlign: "end",
  },
}));

const SubTable: FC<SubTableProps> = ({
  rows,
  onSelect,
  onMultiSelect,
  onDelete,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { tenantConfig } = useAuth();

  const columns = useMemo(
    () =>
      ({
        document_name: {
          header: t("attachmentName"),
          headerClassName: classes.attachmentNameColumn,
          cellClassName: classes.columnCell,
          cell: (row) =>
            row.data.document_name.split(".").slice(0, -1).join("."),
        },
        number_of_pages: {
          header: t("pages"),
          headerClassName: classes.pagesColumn,
          cellClassName: classes.columnCell,
          cell: (row) => row.data.pages_m2m_aggregate.aggregate.count,
        },
        document_extension: {
          header: t("type"),
          headerClassName: classes.typeColumn,
          cellClassName: classes.columnCell,
          cell: (row) => {
            const Icon = getExtensionDescriptionIcon(
              getDocumentExtension(
                row.data.document_name,
                row.data.document_blobs
              )
            );

            return <Icon />;
          },
        },
        creation_date: {
          header: t("creationDate"),
          headerClassName: classes.creationDateColumn,
          cellClassName: classes.columnCell,
          cell: (row) =>
            dayjs(row.data.creation_date).format("DD.MM.YYYY HH:mm:ss"),
        },
        document_status: {
          header: t("status"),
          headerClassName: classes.statusColumn,
          cellClassName: classes.columnCell,
          cell: (row) => {
            const defaultFlags = tenantConfig?.UseAutoclassification
              ? row.data.document_flags
              : 0;
            return (
              <div title={"Status: " + row.data.document_status}>
                <DocumentStatus
                  status={row.data.document_status}
                  defaultFlags={defaultFlags}
                />
              </div>
            );
          },
        },
        actions: {
          cellClassName: classes.actionsColumn,
          cell: (row) => (
            <DocumentRowActions
              id={row.data.id}
              document_blobs={row.data.document_blobs}
              name={row.data.document_name}
              status={row.data.document_status}
              onDelete={onDelete}
            />
          ),
        },
      } as SubTableColumns<Document>),
    []
  );

  return (
    <LuiSubTable<Document>
      columns={columns}
      rows={rows}
      onSelect={onSelect}
      onMultiSelect={onMultiSelect}
    />
  );
};

export default SubTable;
