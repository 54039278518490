import { Paper, styled } from "@periplus/ui-library";

const ContentWrapper = styled(Paper)(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.grey3.main}`,
  boxShadow: "unset",
}));

export default ContentWrapper;
