import React, { FC, ElementType, Fragment, useMemo } from "react";
import {
  Grid,
  Collapse as MuiCollapse,
  CollapseProps as MuiCollapseProps,
  Typography,
  Tooltip,
  Theme,
} from "@periplus/ui-library";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  titleContainer: {
    padding: `${theme.spacing(1.5)} 0`,
    width: "100%",
  },
  title: {
    cursor: "pointer",
    fontWeight: 400,
    fontSize: "1.125rem",
    textTransform: "capitalize",
    lineHeight: "1.25",
  },
  subTitle: {
    color: "#828282",
  },
  expandArrow: {
    cursor: "pointer",
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    color: "#716EDE",
  },
  expandArrowOpen: {
    transform: "rotate(180deg)",
  },
  expandArrowLeftSpace: {
    marginLeft: "auto",
  },
}));

interface CollapseProps extends Omit<MuiCollapseProps, "in" | "classes"> {
  TitleComponent?: ElementType;
  title: string;
  subTitle?: string;
  expanded?: boolean;
  toggleExpand: (expanded: boolean) => void;
  titleFirst?: boolean;
  ArrowIcon?: ElementType;
  classes?: {
    title?: string;
    subTitle?: string;
    arrow?: string;
    titleContainer?: string;
  };
}

const Collapse: FC<CollapseProps> = ({
  TitleComponent = Fragment,
  title,
  subTitle,
  children,
  expanded = false,
  toggleExpand,
  titleFirst = false,
  ArrowIcon = ArrowDropDown,
  classes: outerClasses,
  ...rest
}) => {
  const { classes, cx } = useStyles();

  const Arrows = useMemo(() => {
    return (
      <ArrowIcon
        className={cx(
          classes.expandArrow,
          {
            [classes.expandArrowOpen]: expanded,
            [classes.expandArrowLeftSpace]: titleFirst,
          },
          outerClasses?.arrow
        )}
      />
    );
  }, [
    classes.expandArrow,
    classes.expandArrowLeftSpace,
    classes.expandArrowOpen,
    expanded,
    outerClasses,
    titleFirst,
  ]);

  return <>
    <TitleComponent>
      <Grid
        container
        direction="row"
        alignItems="center"
        onClick={() => {
          toggleExpand(!expanded);
        }}
        wrap="nowrap"
      >
        {!titleFirst && Arrows}
        <Grid
          item
          className={cx(
            classes.titleContainer,
            outerClasses?.titleContainer
          )}
        >
          <Grid container direction="column" wrap="nowrap">
            <Tooltip title={title}>
              <Typography
                noWrap
                className={cx(classes.title, outerClasses?.title)}
              >
                {title}
              </Typography>
            </Tooltip>
            {subTitle && (
              <Typography
                noWrap
                className={cx(classes.subTitle, outerClasses?.subTitle)}
              >
                {subTitle}
              </Typography>
            )}
          </Grid>
        </Grid>

        {titleFirst && Arrows}
      </Grid>
    </TitleComponent>
    <MuiCollapse in={expanded} {...rest}>
      {children}
    </MuiCollapse>
  </>;
};

export default Collapse;
