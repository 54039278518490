import React, { useState, useEffect } from "react";
import {
  Grid,
  Theme,
  InputAdornment,
  TextField,
  Typography,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import WarningComponent from "@mui/icons-material/ReportProblemRounded";
import config from "config";
import useValidateJourney from "graphql/hooks/useValidateJourney";
import Text from "components/Text/Text";
import JourneyResponse from "./JourneyResponse";
import { makeStyles } from "tss-react/mui";
import { File } from "hooks/useClassifier";

const { prefixMRSNumber } = config;
const useStyles = makeStyles()((theme: Theme) => ({
  container: {},

  detailName: {
    fontSize: 14,
    fontWeight: "bold",
    marginRight: theme.spacing(1),
  },

  details: {
    fontSize: 14,
    width: "90%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textTransform: "none",
  },

  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "-12px",
  },
}));

interface ValidationData {
  fileNumber?: string;
  orgId?: number;
  type?: number;
}

interface IJourneyValidation {
  file?: File;
  validationData: (object: ValidationData) => void;
  checkForDuplicate: (fileNumber: string) => void;
  warning?: string | null;
}

const JourneyValidation: React.FC<IJourneyValidation> = ({
  file,
  validationData,
  checkForDuplicate,
  warning,
}) => {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();

  const [fileNumber, setFileNumber] = useState<string>(
    (() => {
      if (!file?.fileNumber) return "";

      return file?.fileNumber.includes("newFileNumber") ? "" : file.fileNumber;
    })()
  );
  const [shipmentResponseData, setShipmentResponseData] = useState<any>(null);

  const { data, loading, error } = useValidateJourney(fileNumber);
  const shipmentResponse = data && data.validateJourneyNumber;

  useEffect(() => {
    validationData({});
  }, [validationData]);

  useEffect(() => {
    if (
      shipmentResponse &&
      shipmentResponse.data &&
      !shipmentResponse.error_code
    ) {
      const { JourneyNumber, Orgid } = shipmentResponse.data;
      setShipmentResponseData({
        fileNumber: JourneyNumber,
        orgId: Orgid,
      });
    }
  }, [shipmentResponse]);

  useEffect(() => {
    if (error) {
      validationData({});
    }

    if (shipmentResponseData) {
      validationData({
        fileNumber: shipmentResponseData.fileNumber,
        orgId: shipmentResponseData.orgId,
        type: 1,
      });
    }
  }, [validationData, shipmentResponseData, error]);

  const handleChange = (fileNumber: string) => {
    const numbersOnly = fileNumber.replace(/[^0-9]/g, "");
    setFileNumber(numbersOnly);
    checkForDuplicate(numbersOnly);
  };

  return (
    <>
      <Grid
        container={true}
        justifyContent="space-between"
        className={classes.container}
      >
        <Grid
          item
          sm={5}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
          }}
        >
          <Text color="textSecondary">classify:existingJourneyNumber</Text>
          <TextField
            fullWidth
            placeholder={t("classify:enterFileNumber")}
            id="fileNumberInput"
            value={fileNumber}
            onChange={(e) => handleChange(e.target.value)}
            // eslint-disable-next-line
            inputProps={{
              maxLength: 7,
            }}
            // eslint-disable-next-line
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {prefixMRSNumber}-
                </InputAdornment>
              ),
            }}
            disabled={!!file}
          />
        </Grid>
      </Grid>

      {!!error && (
        <Typography color="error">
          {i18n.exists(`error:${error}`) ? t(`error:${error}`) : t("error:566")}
        </Typography>
      )}

      {!!warning && (
        <Grid container direction="row" wrap="nowrap">
          <WarningComponent htmlColor="#F7D012" fontSize="large" />
          <Typography style={{ marginLeft: "10px" }}>
            {t(`warning:${warning}`)}
          </Typography>
        </Grid>
      )}

      <JourneyResponse
        loading={loading}
        data={shipmentResponse}
        error={error}
      />
    </>
  );
};

export default JourneyValidation;
