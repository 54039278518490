import React, { FC, useEffect, useState } from "react";
import {
  SpeedDial,
  SpeedDialAction,
  Fab as FabButton,
  Theme,
} from "@periplus/ui-library";
import useToggle from "hooks/useToggle";
import useSubscribeDiscussionChanges from "graphql/hooks/useSubscribeDiscussionChanges";
import { useApplicationContext } from "contexts/ApplicationContext";
import { ChatIcon } from "assets/Icons";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    chatIcon: {
      backgroundColor: theme.palette.primary.main,
    },
    chatTooltip: {
      width: 200,
    },
    fab: {
      position: "fixed",
      right: 20,
      bottom: 20,
    },
    fabList: {
      alignItems: "flex-end",

      "& > #Openchat-actions": {
        minWidth: 300,
        overflowY: "scroll",
        maxHeight: 350,
        alignItems: "flex-end",
        marginBottom: theme.spacing(1.5),
        paddingBottom: 0,
      },
    },
  };
});

const ChatButton: FC = () => {
  const { classes, cx } = useStyles();
  const [appState, appAction] = useApplicationContext();
  const [chatListOpen, setChatListOpen] = useToggle();
  const [isListClosed, setListStatus] = useState(false);

  const {
    data: { discussion_changes },
  } = useSubscribeDiscussionChanges();

  useEffect(() => {
    if (discussion_changes?.length) {
      appAction.setChatNewMessages(discussion_changes);
    }
  }, [appAction, discussion_changes]);

  useEffect(() => {
    setTimeout(
      () => {
        setListStatus(chatListOpen);
      },
      chatListOpen ? 0 : 350
    );
  }, [chatListOpen]);

  if (appState.chatNewMessages.length === 0) {
    return null;
  }

  if (appState.chatNewMessages.length === 1) {
    return (
      <FabButton
        color="primary"
        className={classes.fab}
        aria-label="Open chat"
        style={{
          right: appState.isNotificationsShown ? 440 : 20,
        }}
        onClick={() => {
          if (appState.chat.show) {
            appAction.closeChat();
            return;
          }

          const { file_name, discussion_theme, discussion_id, reference } =
            appState.chatNewMessages[0];
          const fileName = file_name || reference;
          appAction.showChat({
            discussionId: discussion_id,
            title: fileName,
            subTitle: discussion_theme,
            options: {
              offset: {
                right: 70,
              },
            },
          });
        }}
      >
        {appState.chat.show ? <CloseIcon fontSize="large" /> : <ChatIcon />}
      </FabButton>
    );
  }

  return (
    <SpeedDial
      ariaLabel="Open chat"
      icon={appState.chat.show ? <CloseIcon fontSize="large" /> : <ChatIcon />}
      open={chatListOpen}
      className={cx(classes.fab, { [classes.fabList]: isListClosed })}
      style={{
        right: appState.isNotificationsShown ? 440 : 20,
      }}
      FabProps={{
        onClick: () => {
          if (appState.chat.show) {
            appAction.closeChat();
            return;
          }
          setChatListOpen();
        },
      }}
    >
      {appState.chatNewMessages.map(
        ({ file_name, discussion_theme, discussion_id, reference }) => {
          const fileName = file_name || reference;
          return (
            <SpeedDialAction
              key={fileName}
              className={classes.chatIcon}
              icon={<ChatIcon height={30} width={30} />}
              tooltipTitle={fileName}
              tooltipOpen
              onClick={() => {
                appAction.showChat({
                  discussionId: discussion_id,
                  title: fileName,
                  subTitle: discussion_theme,
                  options: {
                    offset: {
                      right: 70,
                    },
                  },
                });
                setChatListOpen(false);
              }}
              classes={{
                staticTooltipLabel: classes.chatTooltip,
              }}
            />
          );
        }
      )}
    </SpeedDial>
  );
};

export default ChatButton;
