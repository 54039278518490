import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { File } from "./types";
import { Sorted } from "graphql/helpers";
import { useAuth } from "contexts/AuthContext";

export const GET_FILES = gql`
  query getFilesForSubscription(
    $offset: Int
    $limit: Int
    $currUserId: uuid
    $orderBy: String
    $fileFlags: _int8
    $minDate: timestamptz
    $maxDate: timestamptz
    $search: String
    $orgIds: _int2
    $myFilesOnly: Boolean
    $fileStatuses: _text
  ) {
    files: pp_file_search(
      args: {
        _limit: $limit
        _offset: $offset
        _orderby: $orderBy
        _file_flags: $fileFlags
        _max_date: $maxDate
        _min_date: $minDate
        _searchval: $search
        _curr_userid: $currUserId
        _org_ids: $orgIds
        _my_files_only: $myFilesOnly
        _file_statuses: $fileStatuses
      }
    ) {
      id
      identification
      name
      org_id
      org_id_description
      meta
      file_flags
      file_status
      creation_user
      creation_date
      last_modified_date
      file_url
      total_rows
      customs_relevant
      extraction_required
    }
  }
`;

export interface Variables {
  page: number;
  itemsPerPage: number;
  sorted?: Sorted;
  search?: string;
  startDate?: string;
  endDate?: string;
  files?: boolean;
  journey?: boolean;
  myFilesOnly?: boolean;
  status?: string[];
  orgIds?: number[];
}

const useGetFiles = (variables: Variables) => {
  const { user, tenantConfig } = useAuth();

  const convertedVariables = useMemo(() => {
    const {
      page,
      itemsPerPage,
      startDate,
      endDate,
      journey,
      files,
      search,
      myFilesOnly,
      orgIds,
      status,
      sorted,
    } = variables;

    let fileFlags = [2];

    if (tenantConfig?.UseJourneys?.value) {
      fileFlags = [];
      journey && fileFlags.push(1);
      files && fileFlags.push(0);
      files && fileFlags.push(8);
    }

    return {
      offset: (page - 1) * itemsPerPage,
      limit: itemsPerPage + 1,
      ...(sorted && {
        orderBy:
          Object.entries(sorted)
            .sort(([, a], [, b]) => a.pos - b.pos)
            .map(([id, sort]) => `${id} ${sort.dir} NULLS LAST`)
            .join(", ") + ", id ASC",
      }),
      currUserId: user?.userId,
      fileFlags: `{${fileFlags.join(",")}}`,
      ...(startDate && { minDate: startDate }),
      ...(endDate && { maxDate: endDate }),
      myFilesOnly,
      search,
      orgIds: orgIds?.length ? `{${orgIds.join(",")}}` : undefined,
      fileStatuses: status?.length
        ? `{${(status as string[])
            .reduce((acc, el) => {
              switch (el) {
                case "archived":
                  acc.push("archived", "created");
                  break;
                case "processing":
                  acc.push(
                    "identification_requested",
                    "transmission_pending",
                    "transmission_generated",
                    "ready_for_archive",
                    "archived_pending",
                    "pending"
                  );
                  break;
                case "failed":
                  acc.push(
                    "archived_failed",
                    "transmission_failed",
                    "identification_request_failed"
                  );
                  break;
              }
              return acc;
            }, [] as string[])
            .join(",")}}`
        : undefined,
    };
  }, [variables, user, tenantConfig?.UseJourneys?.value]);

  const result = useQuery(GET_FILES, {
    variables: convertedVariables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const returnValue = useMemo(() => {
    const files = ((result.data ?? result.previousData)?.files as File[]) ?? [];
    return {
      ...result,
      data: {
        files: files.slice(0, variables.itemsPerPage),
        isLastPage: files.length <= variables.itemsPerPage,
      },
    };
  }, [result, variables.itemsPerPage]);

  return returnValue;
};

export default useGetFiles;
