import React from "react";
import {
  IconButton,
  Popover,
  Theme,
  MenuItem,
  MenuList,
  ListSubheader,
} from "@periplus/ui-library";
import LinkIcon from "@mui/icons-material/Link";
import { TextNaked } from "components/Text";
import { IColumnNamesState } from "./MagicGrid";
import { makeStyles } from 'tss-react/mui';

interface IAssignColumnNameButtonProps {
  assignType: (type: string) => void;
  columnNames: IColumnNamesState;
  [key: string]: any;
}

const useStyles = makeStyles()((theme: Theme) =>
  ({
    menu: {
      "& .MuiMenuItem-dense": {
        padding: `6px 10px`,
      },
      "& .MuiListSubheader-root": {
        padding: `6px 10px`,
        lineHeight: "1.43rem",
      },
    },

    menuItem: {
      display: "flex",
      justifyContent: "space-between",
    },

    groupName: {
      fontWeight: "bold",
      textTransform: "uppercase",
    }
  }));

const AssignColumnNameButton = ({
  assignType,
  columnNames,
  ...props
}: IAssignColumnNameButtonProps) => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (lineItem: string) => {
    assignType(lineItem);
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        {...props}
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <LinkIcon fontSize="small" />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <MenuList dense className={classes.menu}>
          <ListSubheader
            disableSticky
            classes={{
              root: classes.groupName,
            }}
          >
            <TextNaked>validation:lineItems</TextNaked>
          </ListSubheader>
          {Object.entries(columnNames).map(
            ([columnName, assignedColumnIndex]) => (
              <MenuItem
                key={columnName}
                onClick={() => handleItemClick(columnName)}
                className={classes.menuItem}
              >
                <TextNaked>specificTerms:{columnName}</TextNaked>
                {assignedColumnIndex !== -1 && <LinkIcon fontSize="small" />}
              </MenuItem>
            )
          )}
        </MenuList>
      </Popover>
    </>
  );
};

export default AssignColumnNameButton;
