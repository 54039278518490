import React, { ReactNode, useState } from "react";
import { v4 as uuid } from "uuid";
import { LineItem } from "../../graphql/hooks/useTableQuery";
import {
  DataPoint,
  DataPointCoordinates,
} from "../../graphql/hooks/useGetValidationDocument";
import DrawableCanvas from "./DrawableCanvas";
import {
  IChildrenProps,
  IRenderDataPointProps,
  IRenderGridBoxProps,
} from "./types";

interface Props extends IChildrenProps {
  renderDataPoint: (props: IRenderDataPointProps) => ReactNode;
  onCreateDataPoint?: (coordinates: DataPointCoordinates) => Promise<any>;
  drawingGrid?: boolean;
  grids?: LineItem[];
  renderGridBox?: (props: IRenderGridBoxProps) => ReactNode;
  dataPoints?: DataPoint[];
  newDataPoint?: DataPoint;
}

const DataPointsLayer = ({
  pageIndex,
  scale,
  page,
  size,
  originalSize,
  drawingGrid,
  onCreateDataPoint,
  grids = [],
  renderGridBox,
  dataPoints = [],
  renderDataPoint,
  newDataPoint,
}: Props) => {
  const [creatingDataPoints, setCreatingDataPoints] = useState<{
    [id: string]: DataPoint;
  }>({});

  const handleOnFinishDrawing = (rec: DataPointCoordinates) => {
    const [x, y, w, h] = rec;
    if (w < 0.008 || h < 0.008) return;
    const [top, left, width, height] = [
      y * originalSize.height,
      x * originalSize.width,
      w * originalSize.width,
      h * originalSize.height,
    ];
    const id = uuid();
    const coordinates = [left, top, width, height] as DataPointCoordinates;

    if (drawingGrid) {
      return onCreateDataPoint?.(coordinates);
    }

    setCreatingDataPoints((prevData) => ({
      ...prevData,
      [id]: {
        id,
        coordinates,
        value: "",
      },
    }));
    onCreateDataPoint?.(coordinates).finally(() =>
      setCreatingDataPoints(({ [id]: _, ...prevData }) => prevData)
    );
  };

  const isDataPointDrawable = !!onCreateDataPoint;

  return (
    <>
      <DrawableCanvas
        drawingGrid={drawingGrid}
        width={size.width}
        height={size.height}
        {...(isDataPointDrawable && {
          onFinishDrawing: handleOnFinishDrawing,
        })}
      >
        {grids.map((grid) =>
          renderGridBox?.({
            grid,
            scale,
            size,
          })
        )}

        {dataPoints.map((dataPoint, index) => {
          const innerDataPoints = dataPoint.dataPointsInside
            ? dataPoints.filter(
                (dp) => dataPoint.dataPointsInside?.indexOf(dp.id) !== -1
              )
            : undefined;
          return renderDataPoint({
            dataPoint,
            innerDataPoints: innerDataPoints,
            index,
            scale,
            page,
          });
        })}

        {Object.values(creatingDataPoints).map((dataPoint) =>
          renderDataPoint({
            dataPoint,
            scale,
            loading: true,
            page,
          })
        )}

        {newDataPoint &&
          renderDataPoint({
            dataPoint: newDataPoint,
            innerDataPoints: newDataPoint.dataPointsInside
              ? dataPoints.filter((dp) =>
                  newDataPoint.dataPointsInside?.includes(dp.id)
                )
              : undefined,
            scale,
            page,
            newlyCreated: true,
          })}
      </DrawableCanvas>
    </>
  );
};

export default DataPointsLayer;
