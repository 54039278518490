import React, { useRef, useState } from "react";
import { Popper, List, Button, ListItem } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { makeStyles } from "tss-react/mui";

const languages = [
  {
    key: "en",
    text: "English",
  },
  {
    key: "de",
    text: "Deutsch",
  },
];

const languageKeyToTextMap: any = {
  en: "English",
  de: "Deutsch",
};

const useStyles = makeStyles()({
  languageSelector: {
    textTransform: "capitalize",

    "&:hover": {
      backgroundColor: "initial",
    },

    "&:after": {
      content: '"\u02C5"',
      marginLeft: 6,
    },
  },
  languageList: {
    backgroundColor: "#fff",
    minWidth: 100,
    padding: "2px 0",
    border: "solid 1px #bbb",
  },
  languageListItem: {
    textAlign: "right",
    "&:hover": {
      backgroundColor: "#d4edfa",
    },
  },
});

const NativeLanguageSelector = () => {
  const { classes } = useStyles();
  const { i18n } = useTranslation();
  const anchorEl = useRef(null);
  const [open, setOpen] = useState(false);

  const handleToggle = (status?: boolean) => () => {
    setOpen((prevState) => (status != null ? status : !prevState));
  };

  const handleChooseLanguage = (lng: string) => () => {
    dayjs.locale(lng);
    i18n.changeLanguage(lng);
    setOpen(false);
  };

  return (
    <Button
      ref={anchorEl}
      onMouseEnter={handleToggle(true)}
      onClick={handleToggle()}
      onMouseLeave={handleToggle()}
      className={classes.languageSelector}
      aria-controls="simple-menu"
      aria-haspopup="true"
      disableRipple
    >
      {languageKeyToTextMap[i18n.language]}
      <Popper anchorEl={anchorEl.current} open={open} placement="bottom">
        <List className={classes.languageList}>
          {languages.map(({ key, text }) => (
            <ListItem
              button
              key={key}
              onClick={handleChooseLanguage(key)}
              className={classes.languageListItem}
              disableRipple
              disableTouchRipple
            >
              {text}
            </ListItem>
          ))}
        </List>
      </Popper>
    </Button>
  );
};

export default NativeLanguageSelector;
