import {
  PageDocIcon,
  PageMsgIcon,
  PagePdfIcon,
  PageXlsIcon
} from "assets/Icons";

const getExtensionName = (extension: string) => {
  if (/docx?/.test(extension)) {
    return "doc";
  }

  if (/xl[sxmtab]{1,2}/.test(extension)) {
    return "excel";
  }

  if (extension.toLowerCase() === "msg" || extension.toLowerCase() === "eml") {
    return "mail";
  }

  return "pdf";
};

const convertExtensionToDescription = (
  extension: string,
  returnIcon = false
) => {
  const extensionToDescription = returnIcon
    ? {
        doc: PageDocIcon,
        excel: PageXlsIcon,
        mail: PageMsgIcon,
        pdf: PagePdfIcon
      }
    : {
        doc: "Microsoft Word",
        excel: "Microsoft Excel",
        mail: "E-Mail",
        pdf: "PDF"
      };

  const extensionName = getExtensionName(extension);
  return extensionToDescription[extensionName];
};

export const getExtensionDescription = (extension: string) => {
  return convertExtensionToDescription(extension, false);
};

export const getExtensionDescriptionIcon = (extension: string) => {
  return convertExtensionToDescription(extension, true);
};
