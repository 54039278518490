import { AutocompleteProps } from "@periplus/ui-library";
import useGetUserOrgIdList, {
  UserOrgIdListEntity,
} from "./useGetUserOrgIdList";
import { StrictOmit } from "utils/utilityTypes";

interface UserOrgIdAutocompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends AutocompleteProps<
    UserOrgIdListEntity,
    Multiple,
    DisableClearable,
    false
  > {}

export interface UserOrgIdAutocompleteHookProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends StrictOmit<
    UserOrgIdAutocompleteProps<Multiple, DisableClearable>,
    "options"
  > {}

export default <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>({
  ...rest
}: UserOrgIdAutocompleteHookProps<
  Multiple,
  DisableClearable
>): UserOrgIdAutocompleteProps<Multiple, DisableClearable> => {
  const { data: { userOrgIds: options } = { userOrgIds: [] }, loading } =
    useGetUserOrgIdList();

  return {
    options,
    loading,
    getOptionLabel: (otpion) => otpion.toString(),
    ...rest,
  };
};
