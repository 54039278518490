import React, { FC } from "react";
import { Route, RouteProps, useLocation } from "react-router-dom";

interface AuthRouteProps extends RouteProps {}

const AuthRoute: FC<AuthRouteProps> = (props) => {
  const location = useLocation();
  location.state = { path: props.path };

  return <Route {...props} />;
};

export default AuthRoute;
