import React from "react";
import { FormAutocomplete } from "@periplus/ui-library";
import useAutocomplete, {
  CommodityCodeAutocompleteHookProps as AutocompleteHookProps,
} from "./useCommodityCodeAutocomplete";

interface Props<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends Omit<AutocompleteHookProps<Multiple, DisableClearable>, "value"> {
  name: string;
}

export default function <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined
>({ name, ...rest }: Props<Multiple, DisableClearable>) {
  const props = useAutocomplete<Multiple, DisableClearable>(rest);

  return <FormAutocomplete name={name} {...props} />;
}
