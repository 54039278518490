import React, { FC, HtmlHTMLAttributes, useState, useEffect } from "react";
import {
  Table,
  Search,
  Columns,
  Rows,
  ThreeDotsMenu,
  Typography,
  Label,
  Theme,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import usePageLocalStorage from "hooks/usePageLocalStorage";
import Colored from "components/Colored";
import useGetTenantDocumentTypes from "domain/documentType/tenantDocumentType/useGetTenantDocumentTypes";
import TenantDocumentType from "domain/documentType/tenantDocumentType/TenantDocumentType";
import DocumentTypeFormDialog from "./DocumentTypeFormDialog";
import { Tenant } from "domain/tenant/type";
import { makeStyles } from 'tss-react/mui';

interface DocumentTypesTabProps extends HtmlHTMLAttributes<HTMLElement> {
  tenant: Tenant;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  actionsColumn: {
    width: "0%",
    textAlign: "end",
  },
  controlsContainer: {
    display: "flex",
    gap: theme.spacing(),
  },
}));

const DocumentTypesTab: FC<DocumentTypesTabProps> = ({ tenant }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [action, setAction] = useState<{
    selected: TenantDocumentType;
    type: string;
  }>();

  const { pageLocalStorage, setPageLocalStorage } = usePageLocalStorage<{
    itemsPerPage: number;
  }>({
    itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
  });
  const [filters, setFilters] = useState<{
    page: number;
    itemsPerPage: number;
    search?: string;
  }>({
    page: 1,
    itemsPerPage: pageLocalStorage.itemsPerPage,
  });

  const {
    tenantDocumentTypes,
    tenantDocumentTypesTotalCount,
    loading,
    refetch,
  } = useGetTenantDocumentTypes({
    tenantId: tenant.id,
    search: filters.search,
    page: filters.page,
    itemsPerPage: filters.itemsPerPage,
  });
  const [columns, setColumns] = useState({});

  useEffect(() => {
    setColumns(
      Object.entries({
        name: {
          header: t("name"),
          sortable: false,
        },
        color: {
          header: t("color"),
          sortable: false,
          cell: (row) =>
            row.data.meta?.color && <Colored color={row.data.meta.color} />,
        },
        code: {
          header: t("identifierCode"),
          sortable: false,
        },
        labels: {
          header: t("flags"),
          sortable: false,
          cell: (row) => (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              {row.data.defaultFlags?.map((defaultFlag) => (
                <Label
                  color={defaultFlag.color}
                  key={defaultFlag.id}
                  style={{
                    marginRight: "5px",
                    marginBottom: "5px",
                  }}
                >
                  {defaultFlag.name}
                </Label>
              ))}
            </div>
          ),
        },
        actions: {
          cellClassName: classes.actionsColumn,
          sortable: false,
          cell: (row) => {
            return (
              <ThreeDotsMenu
                options={[
                  {
                    content: (
                      <Typography color="textSecondary">{t("edit")}</Typography>
                    ),
                    action: (close) => {
                      setAction({ selected: row.data, type: "edit" });
                      close();
                    },
                  },
                ]}
              />
            );
          },
        },
      } as Columns<TenantDocumentType>).reduce((acc, [id, column]) => {
        acc[id] = {
          ...column,
        };
        return acc;
      }, {} as Columns<TenantDocumentType>)
    );
  }, [t]);

  const [rows, setRows] = useState<Rows<TenantDocumentType>>({});

  useEffect(() => {
    setRows((prev) => ({
      ...Object.entries(prev).reduce((newRows, [id, row]) => {
        newRows[id] = {
          ...row,
          activePos: undefined,
        };
        return newRows;
      }, {} as Rows<TenantDocumentType>),
      ...tenantDocumentTypes.reduce((newRows, documentType, i) => {
        newRows[documentType.document_type] = {
          ...prev[documentType.document_type],
          data: documentType,
          activePos: i,
        };
        return newRows;
      }, {} as Rows<TenantDocumentType>),
    }));
  }, [tenantDocumentTypes]);

  return (
    <>
      <Table<TenantDocumentType>
        rows={rows}
        columns={columns}
        dataIdentifier="document_type"
        loading={loading}
        controls={
          <div className={classes.controlsContainer}>
            <Search
              value={filters.search}
              onChange={(newSearch) =>
                setFilters((prev) => ({ ...prev, search: newSearch, page: 1 }))
              }
            />
          </div>
        }
        totalItems={tenantDocumentTypesTotalCount}
        page={filters.page}
        itemsPerPage={filters.itemsPerPage}
        onChangePage={(newPage) => {
          setFilters((prev) => ({ ...prev, page: newPage }));
        }}
        onChangeItemsPerPage={(newItemsPerPage) => {
          setPageLocalStorage({
            itemsPerPage: newItemsPerPage,
          });
          setFilters((prev) => ({
            ...prev,
            itemsPerPage: newItemsPerPage,
            page: 1,
          }));
        }}
      />
      {action?.type === "edit" && (
        <DocumentTypeFormDialog
          tenantDocumentType={action.selected}
          onConfirm={() => {
            refetch();
            setAction(undefined);
          }}
          onClose={() => setAction(undefined)}
        />
      )}
    </>
  );
};

export default DocumentTypesTab;
