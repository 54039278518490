import React, {
  FC,
  ReactElement,
  CSSProperties,
  useCallback,
  useState,
  useEffect,
} from "react";
import { Grid } from "@periplus/ui-library";
import { Text } from "components/Text";
import DocumentList from "./DocumentList";
import { Document } from "graphql/hooks/useGetDocuments";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  singlePageWrapper: {
    height: "100%",
  },
});

export enum ExpandStatus {
  Full = "full",
  Partial = "partial",
  None = "none",
}

const SinglePageView = (props: any) => {
  const { classes } = useStyles();
  return (
    <Grid container direction="row" className={classes.singlePageWrapper}>
      <Grid item xs={12} md={10} className={classes.singlePageWrapper}>
        <DocumentList
          pagePerRow={1}
          scrollOptions={{ alwaysToTop: true }}
          withoutBookmarks
          {...props}
        >
          {props.children}
        </DocumentList>
      </Grid>
      <Grid item xs="auto" md={2} className={classes.singlePageWrapper}>
        <DocumentList pagePerRow={1} {...props}>
          {({ ...rest }) =>
            props.children({
              ...rest,
              focusOnly: true,
            })
          }
        </DocumentList>
      </Grid>
    </Grid>
  );
};

interface DocumentViewProps {
  documents: Document[];
  expandAll?: boolean;
  status?: ExpandStatus;
  onChangeExpand?: (status: ExpandStatus) => void;
  pagePerRow?: number;
  onExpand?: (index: number) => void;
  onCollapse?: (index: number) => void;
  children: (args: {
    style: CSSProperties;
    path: string;
  }) => ReactElement | null;
  focused: string;
}

const DocumentView: FC<DocumentViewProps> = ({
  documents,
  status = ExpandStatus.Partial,
  onChangeExpand,
  onExpand,
  onCollapse,
  pagePerRow = 6,
  children,
  focused,
}) => {
  const [expandedDocuments, setExpanded] = useState<{ [key: string]: any }>({});

  const toggleExpand = useCallback(
    ({ id }: Document, index: number) => {
      setExpanded((prevIds) => {
        if (prevIds[id]) {
          onCollapse && onCollapse(index);
          delete prevIds[id];
        } else {
          onExpand && onExpand(index);
          prevIds[id] = true;
        }

        const newIdsLength = Object.keys(prevIds).length;
        let newStatus = ExpandStatus.Partial;
        if (newIdsLength === documents.length) {
          newStatus = ExpandStatus.Full;
        } else if (newIdsLength === 0) {
          newStatus = ExpandStatus.None;
        }

        if (newStatus !== status && onChangeExpand) {
          onChangeExpand(newStatus);
        }

        return { ...prevIds };
      });
    },
    [documents.length, onChangeExpand, onCollapse, onExpand, status]
  );

  useEffect(() => {
    if (status === ExpandStatus.Full) {
      setExpanded(
        documents.reduce((acc, document, index) => {
          onExpand && onExpand(index);
          return { ...acc, [document.id]: true };
        }, {})
      );
    }
    if (status === ExpandStatus.None) {
      setExpanded({});
      documents.forEach((_, index) => {
        onCollapse && onCollapse(index);
      });
    }
    // eslint-disable-next-line
  }, [documents, status]);

  if (pagePerRow === 0) {
    throw new Error("You should provide correct pagePerRow value");
  }

  if (documents.length === 0) {
    return (
      <Grid
        item
        container
        alignItems="center"
        style={{ flex: 1 }}
        justifyContent="center"
      >
        <Text>No document</Text>
      </Grid>
    );
  }

  return (
    <>
      {pagePerRow > 1 ? (
        <DocumentList
          pagePerRow={pagePerRow}
          toggleExpand={toggleExpand}
          documents={documents}
          expandedDocuments={expandedDocuments}
          focused={focused}
        >
          {children}
        </DocumentList>
      ) : (
        <SinglePageView
          toggleExpand={toggleExpand}
          documents={documents}
          expandedDocuments={expandedDocuments}
          focused={focused}
        >
          {children}
        </SinglePageView>
      )}
    </>
  );
};

export default DocumentView;
