import { useCallback, useMemo } from "react";
import { gql } from "@apollo/client";
import { useSubscription, useQuery } from "@apollo/client";
import get from "lodash/get";
import { updateFileName } from "utils/updateFileName";

export interface TransformedFile {
  files: File[];
  filesCount: number;
}

export type Document = {
  id: string;
  document_type: string;
  document_status: string;
  document_name: string;
  document_extension: string;
  last_modified_date: string;
  created_by_user: {
    display_name: string;
  };
  number_of_pages: number;
  document_flags: number;
  meta: any;
  document_blobs: any;
};

export type File = {
  id: string;
  identification: string;
  userId: string;
  meta: string[];
  name: string;
  status: string;
  type: number;
  creationDate: string;
  fileUrl: string;
  org_id?: number;
  org_id_description?: string;
  documents: Document[];
  displayName: string;
  typeName: string;
};

export const GET_FILES = gql`
  query getFilesForSubscriptionForAnnotation(
    $offset: Int
    $limit: Int
    $currUserId: uuid
    $orderBy: String
    $fileFlags: _int8
    $minDate: timestamptz
    $maxDate: timestamptz
    $search: String
    $orgIds: _int2
    $myFilesOnly: Boolean
    $fileStatuses: _text
  ) {
    files: pp_file_search(
      args: {
        _limit: $limit
        _offset: $offset
        _orderby: $orderBy
        _file_flags: $fileFlags
        _max_date: $maxDate
        _min_date: $minDate
        _searchval: $search
        _curr_userid: $currUserId
        _org_ids: $orgIds
        _my_files_only: $myFilesOnly
        _file_statuses: $fileStatuses
      }
    ) {
      id
      identification
      name
      org_id
      org_id_description
      meta
      type: file_flags
      status: file_status
      userId: creation_user
      creationDate: creation_date
      lastUpdate: last_modified_date
      fileUrl: file_url
      total_rows
      customs_relevant
      extraction_required
    }
  }
`;

export const GET_FILE_UPDATED_SUBSCRIPTION = gql`
  subscription getFileUpdatesSubscriptionForAnnotation {
    pp_file_max_date_hs {
      max_change_date
    }
  }
`;

export interface IUseGetFile {
  offset?: number;
  limit?: number;
  search?: string;
  orderBy?: string;
}

export interface IReturnFile {
  data: TransformedFile;
  filter: (filterBy: {
    fileFlags?: string;
    minDate?: string;
    maxDate?: string;
    search?: string;
  }) => void;
  sort: (orderBy: { key: string; direction: "ASC" | "DESC" }[]) => void;
  fetchMore: (offset?: number, limit?: number) => void;
  loading: boolean;
  error: any;
}

export const fieldMap: any = {
  identification: "identification",
  creationDate: "creation_date",
  lastUpdate: "last_modified_date ",
  status: "file_status",
  type: "file_flags",
};

const useGetFilesSubscription = (variables: IUseGetFile): IReturnFile => {
  const { data, loading, error, refetch } = useQuery(GET_FILES, {
    variables,
    fetchPolicy: "no-cache",
  });

  useSubscription(GET_FILE_UPDATED_SUBSCRIPTION, {
    onSubscriptionData: ({ subscriptionData: { data: fileUpdateTime } }) => {
      const changeDate = get(
        fileUpdateTime,
        "pp_file_max_date_hs.0.max_change_date",
        null
      );

      if (changeDate !== null) {
        refetch();
      }
    },
  });

  const getNewPage = useCallback(
    (offset, limit) => {
      refetch({
        ...variables,
        offset,
        limit,
      });
    },
    [refetch, variables]
  );
  const filter = useCallback(
    (filterBy) => {
      refetch({
        ...variables,
        offset: 0,
        ...filterBy,
      });
    },
    [refetch, variables]
  );

  const sort = useCallback(
    (orderBy: { key: string; direction: "ASC" | "DESC" }[]) => {
      refetch({
        ...variables,
        offset: 0,
        orderBy:
          orderBy
            .map(
              (el) => `${fieldMap[el.key] || el.key} ${el.direction} NULLS LAST`
            )
            .join(", ") + ", id ASC",
      });
    },
    [refetch, variables]
  );

  const returnValue = useMemo(
    () => ({
      data: {
        files: updateFileName<File>(get(data, "files", [])),
        filesCount: get(data, "files.0.total_rows", 0),
      },
      filter,
      sort,
      fetchMore: getNewPage,
      loading: loading,
      error: error,
    }),
    [data, filter, sort, getNewPage, loading, error]
  );

  return returnValue;
};

export default useGetFilesSubscription;
