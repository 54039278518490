import React, { useMemo } from "react";
import { Typography, Theme } from "@periplus/ui-library";
import dayjs from "dayjs";
import { CellProps, Renderer } from "react-table";
import { useTranslation } from "react-i18next";
import Table, { headerTranslation, TableProps } from "components/Table";
import { IDeclarationTask } from "graphql/hooks/useGetDeclarationTasks";
import PriorityDueDate from "pages/Select/Tasks/PriorityDueDate";
import { makeStyles } from 'tss-react/mui';

interface Props
  extends Pick<
    TableProps,
    "loading" | "itemCount" | "loadMore" | "initialPage"
  > {
  rowData: IDeclarationTask[];
  renderActionCell: Renderer<CellProps<IDeclarationTask>>;
}

const useStyles = makeStyles()((theme: Theme) =>
  ({
    textWrapper: {
      display: "flex",
      flexDircetion: "row",
    },

    taskNumber: {
      flex: 1,
    },

    taskText: {
      flex: 3,
    }
  }));

const TasksTable = ({ rowData, renderActionCell, ...props }: Props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { classes } = useStyles();
  const columnDefs = useMemo(
    () => [
      {
        Header: () => headerTranslation("common", "task"),
        accessor: "title",
        Cell: ({
          cell: {
            row: { original },
          },
        }: any) => {
          return (
            <div className={classes.textWrapper}>
              <Typography color="textSecondary" className={classes.taskNumber}>
                #{original.id}
              </Typography>
            </div>
          );
        },
      },
      {
        Header: () => headerTranslation("common", "who"),
        accessor: "assigned_user.display_name",
      },
      {
        Header: () => headerTranslation("common", "type"),
        accessor: "task_type.display_name",
        Cell: ({
          cell: {
            row: { original },
          },
        }: any) => {
          return (
            <Typography>
              {t(`tasks:${original.task_type.display_name}`)}
            </Typography>
          );
        },
      },
      {
        Header: () => headerTranslation("common", "file"),
        accessor: "file.identification",
        minWidth: 190,
        Cell: ({
          cell: {
            row: { original },
          },
        }: any) => {
          return (
            original?.file?.identification ||
            original?.document?.documentFile?.identification ||
            ""
          );
        },
      },
      {
        Header: () => headerTranslation("common", "documentType"),
        accessor: `document.documentType.translations.${language}`,
      },
      {
        Header: () => headerTranslation("common", "created"),
        accessor: "creation_date",
        Cell: ({ cell }: { cell: { value: string } }) =>
          dayjs(cell.value).format("DD.MM.YYYY HH:mm:ss"),
      },
      {
        Header: () => headerTranslation("common", "priority"),
        accessor: "due_date",
        Cell: ({ cell, row }: any) => {
          return (
            row.original.task_status_id === 0 && (
              <PriorityDueDate dueDate={cell.value} />
            )
          );
        },
      },
      {
        Header: () => headerTranslation("common", "action"),
        accessor: "task_type_id",
        Cell: renderActionCell,
      },
    ],
    [renderActionCell, language]
  );
  return (
    <Table
      columnDefs={columnDefs}
      rowData={rowData}
      initialItemsPerPage={6}
      {...props}
    />
  );
};

export default TasksTable;
