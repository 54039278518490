import { gql } from "@apollo/client";

export const VALIDATE_JOURNEY_NUMBER = gql`
  mutation ValidateJourneyNumber($number: String!) {
    validateJourneyNumber(number: $number) {
      error_code
      error_description
      data
    }
  }
`;
