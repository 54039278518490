import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  ReactType,
  useEffect,
} from "react";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
  PopoverOrigin,
} from "@periplus/ui-library";
import { ArrowDropDown } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import useToggle from "hooks/useToggle";

type Element<T> = {
  id: T;
  Icon: ReactType;
  Text: ReactType | string;
};

interface IViewLayoutProps<T> {
  onChange: (id: T) => void;
  elements: Element<T>[];
  RootElement?: ReactType;
  placement?: PopoverOrigin;
  initialElementId?: T;
  [key: string]: any;
}

/* TODO: Fix placement with icon size calculation */
const ViewLayout = <T extends any>({
  onChange,
  elements,
  RootElement = Button,
  placement = {
    vertical: "bottom",
    horizontal: "center",
  },
  initialElementId,
  ...rest
}: IViewLayoutProps<T>) => {
  const { Icon } = useMemo(
    () =>
      initialElementId
        ? (elements.find((el) => el.id === initialElementId) as Element<T>)
        : elements[0],
    [elements, initialElementId]
  );

  const { t } = useTranslation();
  const [CurrentIcon, switchIcon] = useState<ReactType>(Icon);
  const [isMenuShown, toggleMenuShow] = useToggle();

  useEffect(() => {
    switchIcon(Icon);
  }, [Icon, initialElementId]);

  const buttonRef = useRef();

  const closeMenu = useCallback(() => toggleMenuShow(false), [toggleMenuShow]);

  const changeItem = useCallback(
    (id: T, Icon: ReactType) => () => {
      onChange(id);
      switchIcon(Icon);
      closeMenu();
    },
    [closeMenu, onChange]
  );

  const menuItems = elements.map(({ id, Icon, Text }) => (
    <MenuItem
      // @ts-ignore
      key={id.toString()}
      onClick={changeItem(id, Icon)}
      data-pp={`change-view-layout-item-${id}`}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={t(`menus:${Text}`)} />
    </MenuItem>
  ));

  return (
    <>
      <RootElement
        onClick={toggleMenuShow}
        ref={buttonRef}
        data-pp="change-view-layout-button"
        {...rest}
      >
        <CurrentIcon />
        <ArrowDropDown />
      </RootElement>
      <Menu
        anchorOrigin={placement}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={isMenuShown}
        anchorEl={buttonRef.current}
        keepMounted
        onClose={closeMenu}
        data-pp="change-view-layout-options"
      >
        {menuItems}
      </Menu>
    </>
  );
};

export default ViewLayout;
