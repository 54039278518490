import { gql } from "@apollo/client";
import {
  AditGetCommodityCodesListQuery,
  useAditGetCommodityCodesListLazyQuery,
  useAditGetCommodityCodesListQuery,
} from "graphql/generated";

export type CommodityCodeListEntity =
  AditGetCommodityCodesListQuery["commodityCodes"][number];

gql`
  query aditGetCommodityCodesList(
    $ids: [String!]
    $search: String
    $limit: Int
  ) {
    commodityCodes: getCommodityCodes(
      ids: $ids
      search: $search
      limit: $limit
    ) {
      id
      commodityCode
      descriptionDe
      hierarchyVisual
    }
  }
`;

export const useGetCommodityCodesListLazy =
  useAditGetCommodityCodesListLazyQuery;

const useGetCommodityCodesList = useAditGetCommodityCodesListQuery;
export default useGetCommodityCodesList;
