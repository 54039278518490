import React, { useCallback, useMemo, useState } from "react";
import { Dialog } from "@periplus/ui-library";
import { RouteProps } from "react-router";
import { useTranslation } from "react-i18next";
import FileValidation, { ValidationData } from "./FileValidation";
import JourneyValidation from "./JourneyValidation";
import DescriptionIcon from "@mui/icons-material/Description";

import { EdecCustomsOffice } from "domain/edec/useGetEdecMasterData";
import dayjs, { Dayjs } from "dayjs";
import { useApplicationState } from "contexts/ApplicationContext";
import { AddressListEntity } from "domain/address/useGetAddresses";
import { AddressType } from "domain/address/types";
import { Role } from "rbac-rules";
import { useAuth } from "contexts/AuthContext";
import { makeStyles } from "tss-react/mui";
import { Declaration } from "graphql/hooks/useGetDeclaration";
import isEqual from "lodash/isEqual";
import { File } from "hooks/useClassifier";

const useStyles = makeStyles()((theme) => ({
  dialogTitleRoot: {},
  whiteText: {},
}));

interface IFileDialogProps extends RouteProps {
  file?: File;
  toggleDialog: (arg0: boolean) => void;
  shipmentInfo: (args: {
    fileNumber: string;
    orgId?: number;
    type: number;
    fileId?: string;
    scheduleFileExport?: boolean;
    declarationData?: DeclarationState | null;
  }) => void;
  onCancel?: () => void;
  isFileNumberDuplicate?: (fileNumber: string) => boolean;
  declaration: Declaration;
}

export type Tenant = {
  id: string;
  tenantName: string;
};

export type DeclarationState = {
  customsOffice?: EdecCustomsOffice | null;
  etaDate?: Dayjs | null;
  payerAddress: AddressListEntity | null;
  consignorAddress: AddressListEntity | null;
  consigneeAddress: AddressListEntity | null;
  importerAddress: AddressListEntity | null;
  totalGrossWeight: number | null;
  totalColis: number | null;
  payerReference: string | null;
  payerName: string | null;
};

const FileDialog: React.FC<IFileDialogProps> = ({
  file,
  toggleDialog,
  shipmentInfo,
  onCancel,
  isFileNumberDuplicate,
  declaration,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [validationData, setValidationData] = useState<ValidationData>({});
  const [warning, setWarning] = useState<string | null>(null);
  const [tab, setTab] = useState(file?.type === 1 ? 1 : 0);
  const { user } = useAuth();
  const { edecData } = useApplicationState();
  const isCustomsBroker = user?.hasAllowedRoles([Role.CustomsBroker]) === true;

  const defaultDeclarationData = useMemo<DeclarationState>(
    () => ({
      etaDate: null,
      customsOffice: null,
      payerAddress: null,
      consignorAddress: null,
      consigneeAddress: null,
      importerAddress: null,
      totalGrossWeight: null,
      totalColis: null,
      payerName: null,
      payerReference: "",
    }),
    []
  );
  const [declarationData, setDeclarationData] = useState<DeclarationState>(
    (() => {
      if (file?.declarationData) return file.declarationData;

      if (declaration) {
        const customsOffice =
          edecData.edec_customs_offices?.find(
            (office) =>
              office.number === declaration?.declaration?.customs_office_number
          ) || null;

        const payerDeclarationAddress =
          declaration.declaration_request_addresses.find(
            (address) => address.address_type === AddressType.payer
          );
        const payerReference = payerDeclarationAddress?.refs[0] || "";
        const payerAddress = payerDeclarationAddress?.address;

        const consignorAddress = declaration.declaration_request_addresses.find(
          (address) => address.address_type === AddressType.consignor
        )?.address;
        const consigneeAddress = declaration.declaration_request_addresses.find(
          (address) => address.address_type === AddressType.consignee
        )?.address;
        const importerAddress = declaration.declaration_request_addresses.find(
          (address) => address.address_type === AddressType.importer
        )?.address;

        return {
          customsOffice: customsOffice,
          etaDate: declaration.eta ? dayjs(declaration.eta) : null,
          payerAddress: payerAddress
            ? {
                id: payerAddress.id,
                companyName: payerAddress.company_name,
                companyName2: payerAddress.company_name_2,
                country: payerAddress.country,
                city: payerAddress.city,
                street: payerAddress.street,
                zipcode: payerAddress.zipcode,
                customsAccountNumber: payerAddress.customs_account_number,
                isLocked: payerAddress.is_locked,
                uidNumber: payerAddress.uid_number,
                usageFrequency: payerAddress.usage_frequency,
                vatAccountNumber: payerAddress.vat_account_number,
                traderIdentificationNumber:
                  payerAddress.trader_identification_number,
                reference: payerAddress.reference,
                isPlatformUser: payerAddress.is_platform_user,
              }
            : null,
          consignorAddress: consignorAddress
            ? {
                id: consignorAddress.id,
                companyName: consignorAddress.company_name,
                companyName2: consignorAddress.company_name_2,
                country: consignorAddress.country,
                city: consignorAddress.city,
                street: consignorAddress.street,
                zipcode: consignorAddress.zipcode,
                customsAccountNumber: consignorAddress.customs_account_number,
                isLocked: consignorAddress.is_locked,
                uidNumber: consignorAddress.uid_number,
                usageFrequency: consignorAddress.usage_frequency,
                vatAccountNumber: consignorAddress.vat_account_number,
                traderIdentificationNumber:
                  consignorAddress.trader_identification_number,
                reference: consignorAddress.reference,
                isPlatformUser: consignorAddress.is_platform_user,
              }
            : null,
          consigneeAddress: consigneeAddress
            ? {
                id: consigneeAddress.id,
                companyName: consigneeAddress.company_name,
                companyName2: consigneeAddress.company_name_2,
                country: consigneeAddress.country,
                city: consigneeAddress.city,
                street: consigneeAddress.street,
                zipcode: consigneeAddress.zipcode,
                customsAccountNumber: consigneeAddress.customs_account_number,
                isLocked: consigneeAddress.is_locked,
                uidNumber: consigneeAddress.uid_number,
                usageFrequency: consigneeAddress.usage_frequency,
                vatAccountNumber: consigneeAddress.vat_account_number,
                traderIdentificationNumber:
                  consigneeAddress.trader_identification_number,
                reference: consigneeAddress.reference,
                isPlatformUser: consigneeAddress.is_platform_user,
              }
            : null,
          importerAddress: importerAddress
            ? {
                id: importerAddress.id,
                companyName: importerAddress.company_name,
                companyName2: importerAddress.company_name_2,
                country: importerAddress.country,
                city: importerAddress.city,
                street: importerAddress.street,
                zipcode: importerAddress.zipcode,
                customsAccountNumber: importerAddress.customs_account_number,
                isLocked: importerAddress.is_locked,
                uidNumber: importerAddress.uid_number,
                usageFrequency: importerAddress.usage_frequency,
                vatAccountNumber: importerAddress.vat_account_number,
                traderIdentificationNumber:
                  importerAddress.trader_identification_number,
                reference: importerAddress.reference,
                isPlatformUser: importerAddress.is_platform_user,
              }
            : null,
          totalColis: parseInt(declaration.declaration.total_colis) || null,
          totalGrossWeight:
            parseFloat(declaration.declaration.total_gross_weight) || null,
          payerName: payerAddress?.company_name || null,
          payerReference: payerReference,
        };
      }

      return defaultDeclarationData;
    })()
  );

  const [declarationExpanded, setDeclarationExpanded] = useState(
    !isEqual(declarationData, defaultDeclarationData)
  );
  const handleDeclarationExpandedChange = useCallback(
    (newExpanded: boolean) => {
      if (!newExpanded) {
        setDeclarationData(defaultDeclarationData);
      }
      setDeclarationExpanded(newExpanded);
    },
    []
  );

  const isDeclarationValid = (state: DeclarationState): boolean => {
    const { customsOffice, payerAddress } = state;
    if (declarationExpanded) {
      if (customsOffice === null || payerAddress === null) {
        return false;
      }
    }
    return true;
  };

  const badValidationData: boolean =
    !file &&
    (!Object.keys(validationData).length ||
      (!validationData.scheduleFileExport && !validationData.fileNumber));

  const handleClear = () => {
    onCancel && onCancel();
    toggleDialog(false);
  };

  const assignFileNumber = () => {
    if (file) {
      shipmentInfo({
        fileNumber: file.fileNumber,
        type: declarationExpanded ? 8 : 0,
        declarationData: !isEqual(declarationData, defaultDeclarationData)
          ? declarationData
          : null,
      });
    }

    const { fileNumber, orgId, type, fileId, scheduleFileExport } =
      validationData as any;
    shipmentInfo({
      fileNumber,
      orgId: parseInt(orgId),
      type,
      fileId,
      scheduleFileExport,
      declarationData: !isEqual(declarationData, defaultDeclarationData)
        ? declarationData
        : null,
    });
    toggleDialog(false);
  };

  const checkForDuplicate = (value: any) => {
    if (value.length === 7) {
      if (isFileNumberDuplicate && isFileNumberDuplicate(value)) {
        setWarning("fileNameDuplicate");
      }
    } else {
      setWarning(null);
    }
  };

  return (
    <Dialog
      open
      mainTitle={t(`classify:file`)}
      subTitle={t(`classify:fileDialogDescription`)}
      TitleIcon={DescriptionIcon}
      onClose={handleClear}
      onConfirm={assignFileNumber}
      maxWidth="md"
      fullWidth
      ConfirmButtonProps={{
        label: t("Save"),
        disabled:
          (!declarationExpanded && badValidationData) ||
          (declarationExpanded &&
            (badValidationData || !isDeclarationValid(declarationData))),
      }}
      DialogTitleProps={{
        classes: {
          root: classes.dialogTitleRoot,
          title: classes.whiteText,
          subTitle: classes.whiteText,
          closeButton: classes.whiteText,
        },
      }}
      selectedTab={tab}
      tabs={[t("classify:enterFileNumber"), t("classify:journeyNumber")]}
      onChangeTab={(newTab) => {
        setTab(newTab);
        handleDeclarationExpandedChange(false);
      }}
      getTabDisabled={() => !!file}
    >
      {
        [
          <FileValidation
            file={file}
            checkForDuplicate={checkForDuplicate}
            warning={warning}
            validationData={setValidationData}
            declarationData={declarationData}
            setDeclarationData={setDeclarationData}
            isCustomsBroker={isCustomsBroker}
            declarationExpanded={declarationExpanded}
            onDeclarationExpandedChange={handleDeclarationExpandedChange}
          />,
          <JourneyValidation
            file={file}
            checkForDuplicate={checkForDuplicate}
            warning={warning}
            validationData={setValidationData}
          />,
        ][tab]
      }
    </Dialog>
  );
};

export default FileDialog;
