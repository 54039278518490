import React, { forwardRef } from "react";
import { Magnifier, Theme } from "@periplus/ui-library";
import { useLocation } from "react-router-dom";
import { IDataPointPageProps } from "./types";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    pageContainer: {
      position: "relative",
      userSelect: "none",
      border: "1px solid #ccc",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      overflow: "hidden",
    },

    pageImage: {
      width: "100%",
      objectFit: "contain",
    },

    pdfViewer: {
      willChange: "auto !important",
    }
  }));

const DataPointPage = forwardRef<HTMLImageElement, IDataPointPageProps>(
  (
    { index, data, size, originalSize, onImageLoad, children, toggleMagnifier },
    ref
  ) => {
    const { classes } = useStyles();
    let location = useLocation();

    const { thumbnailBigUrl, rotate } = data;
    const allowMagnifier = toggleMagnifier;

    const horizontalValues = [90, -90, 270, -270];
    const imgWidth: string = horizontalValues.includes(rotate) ? "70%" : "100%";

    /**
     * @todo TODO parse original size & size instead of this
     */
    const scale = [
      size.width / originalSize.width,
      size.height / originalSize.height,
    ] as [number, number];

    const isDeclarationNewRoute = location.pathname.includes("declaration");

    return (
      <>
        <Magnifier
          imageSrc={thumbnailBigUrl}
          enabled={isDeclarationNewRoute && allowMagnifier}
          tooltipColor="dark"
        >
          <img
            ref={ref}
            onLoad={onImageLoad}
            alt="document_page"
            data-pp="document_page"
            src={thumbnailBigUrl}
            className={classes.pageImage}
            style={{
              transform: `rotate(${rotate}deg)`,
              width: imgWidth,
            }}
          />

          {children?.({
            page: data,
            pageIndex: index,
            scale,
            size,
            originalSize,
          })}
        </Magnifier>
      </>
    );
  }
);

export default DataPointPage;
