import React, { FC } from "react";
import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  combineSX,
} from "@periplus/ui-library";

interface FieldProps extends BoxProps {
  label: string;
  value?: string | number | null;
  color: "green" | "grey";
  LabelProps?: TypographyProps;
}

const Field: FC<FieldProps> = ({ label, value, color, LabelProps }) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <Typography
        variant="overline"
        color="textSecondary"
        {...LabelProps}
        sx={combineSX({ alignSelf: "center" }, LabelProps?.sx)}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          borderRadius: 1,
          mt: 1,
          backgroundColor: {
            green: "#27AE60",
            grey: "#EDEDF5",
          }[color],
          p: "5px",
          minHeight: 32.5,
        }}
      >
        <Typography
          variant="subtitle1"
          color={{ green: "white", grey: "textPrimary" }[color]}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default Field;
