import React from "react";
import { Theme } from "@periplus/ui-library";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@periplus/ui-library";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    nested: {
      paddingLeft: theme.spacing(4),
    },

    listIcon: {
      color: "black",
    }
  }));

type ListItems = {
  element: string;
  abbreviation: string;
  Icon?: React.ElementType;
  action: () => void;
};
interface ListItemProps {
  items: ListItems[];
  label: string;
  sectionIcon?: React.ReactNode;
}

const NestedList = ({ items, label, sectionIcon }: ListItemProps) => {
  const { classes } = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      component="nav"
      sx={{
        width: "100%",
        padding: 0,
        borderTop: 1,
        borderColor: "grey4.main",
      }}
    >
      <ListItem button onClick={handleClick}>
        <ListItemIcon
          sx={{
            color: "text.secondary",
          }}
        >
          {sectionIcon}
        </ListItemIcon>
        <ListItemText
          primary={label}
          sx={{
            color: "text.secondary",
          }}
        />
        {open ? <ExpandLess sx={{ ml: 2 }} /> : <ExpandMore sx={{ ml: 2 }} />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map(({ Icon, element, abbreviation, action }) => (
            <ListItem
              key={abbreviation}
              button
              className={classes.nested}
              onClick={action}
            >
              <ListItemIcon className={classes.listIcon}>
                {Icon && <Icon />}
              </ListItemIcon>
              <ListItemText
                primary={element}
                sx={{
                  color: "text.secondary",
                }}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  );
};

export default NestedList;
