import React, { FC } from "react";
import {
  ActionBar as LuiActionBar,
  ActionBarItem as LuiActionBarItem,
  Search,
  Theme,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import FilterMenu from "./FilterMenu";
import { Filters } from "../Tasks";
import { makeStyles } from 'tss-react/mui';

interface Props {
  filters: Filters;
  onChangeFilters: (newFilters: Filters) => void;
  onClearFilters: () => void;
  onChangeSearch: (newSearch: string) => void;
}

const useStyles = makeStyles()((theme: Theme) =>
  ({
    actionBarItem: {
      display: "flex",
      flexWrap: "nowrap",
      maxWidth: 760,
      "& > *": {
        marginRight: theme.spacing(1),
      },
    },

    rightActionBar: {
      width: "auto",
      maxWidth: 190,
    },

    search: {}
  }));

const ActionBar: FC<Props> = ({
  onChangeFilters,
  onChangeSearch,
  filters,
  onClearFilters,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <LuiActionBar
      sx={{
        border: 1,
        borderColor: "grey3.main",
      }}
      style={{ overflow: "auto" }}
    >
      <LuiActionBarItem container className={classes.actionBarItem}>
        <FilterMenu
          onChangeFilters={onChangeFilters}
          filters={filters}
          onClearFilters={onClearFilters}
        />
      </LuiActionBarItem>
      <LuiActionBarItem
        direction="row"
        container
        justifyContent="flex-end"
        spacing={0}
        wrap="nowrap"
        className={classes.rightActionBar}
      >
        <Search
          label={t("common:search") + "..."}
          value={""}
          onChange={onChangeSearch}
        />
      </LuiActionBarItem>
    </LuiActionBar>
  );
};

export default ActionBar;
