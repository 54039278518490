import React, { FC, HtmlHTMLAttributes, useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Theme,
  ListItemIcon,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import GridOnIcon from "@mui/icons-material/GridOn";
import { gql } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import TitleIcon from "@mui/icons-material/Title";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import { makeStyles } from 'tss-react/mui';

interface GridMenuProps extends HtmlHTMLAttributes<HTMLElement> {
  onGridDrawModeToggle: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
}));

const GridMenu: FC<GridMenuProps> = ({ onGridDrawModeToggle }) => {
  const { classes } = useStyles();
  const { t } = useTranslation("validation");
  const { id } = useParams<{ id: string }>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const [markDocumentAsTemplate] = useMutation<
    void,
    { id: string; isTemplate: boolean }
  >(gql`
    mutation ADIT_markDocumentAsTemplate($id: ID!, $isTemplate: Boolean) {
      markDocumentAsTemplate(docId: $id, isTemplate: $isTemplate)
    }
  `);

  const [scheduleTableCalculation] = useMutation<void, { id: string }>(gql`
    mutation ADIT_scheduleTableCalculation($id: ID!) {
      scheduleTableCalculation(docId: $id)
    }
  `);

  return (
    <>
      <Button
        className={classes.root}
        variant="outlined"
        color="primary"
        endIcon={<GridOnIcon />}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        {t("Grid")}
      </Button>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        keepMounted
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            onGridDrawModeToggle();
          }}
        >
          <ListItemIcon>
            <GridOnIcon />
          </ListItemIcon>
          {t("Draw grid")}
        </MenuItem>
        <MenuItem
          onClick={async () => {
            setAnchorEl(null);
            await scheduleTableCalculation({
              variables: {
                id,
              },
            });
            enqueueSnackbar(t("common:success"), {
              variant: "success",
            });
          }}
        >
          <ListItemIcon>
            <FindReplaceIcon />
          </ListItemIcon>
          {t("Read from table")}
        </MenuItem>
        <MenuItem
          onClick={async () => {
            setAnchorEl(null);
            await markDocumentAsTemplate({
              variables: {
                id,
                isTemplate: true,
              },
            });
            enqueueSnackbar(t("common:success"), { variant: "success" });
          }}
        >
          <ListItemIcon>
            <TitleIcon />
          </ListItemIcon>
          {t("Use as template")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default GridMenu;
