import React from "react";
import { Loader, Grid, Theme } from "@periplus/ui-library";
import Text from "components/Text/Text";
import { makeStyles } from 'tss-react/mui';

interface IJourneyResponse {
  loading: any;
  data: any;
  error: any;
}

const JourneyResponse: React.FC<IJourneyResponse> = ({
  loading,
  data,
  error,
}) => {
  const { classes } = useStyles();
  return (
    <>
      {loading && <Loader />}
      {data && !error && (
        <Grid container={true} justifyContent="space-between">
          <Grid item sm={12} style={{ marginTop: 20, marginBottom: 20 }}>
            <Text>{data.data.JourneyNumber} Details</Text>
          </Grid>
          <Grid item sm={6} container>
            <Grid item sm={5}>
              <Text className={classes.detailName}>specificTerms:orgid</Text>
              <Text className={classes.detailName}>
                specificTerms:carriername
              </Text>
              <Text className={classes.detailName}>
                specificTerms:direction
              </Text>
            </Grid>
            <Grid item sm={7}>
              <Text className={classes.details}>{data.data.Orgid}</Text>
              <Text className={classes.details}>{data.data.CarrierName}</Text>
              <Text className={classes.details}>{data.data.DirectionName}</Text>
            </Grid>
          </Grid>

          <Grid item sm={6} container>
            <Grid item sm={5}>
              <Text className={classes.detailName}>
                specificTerms:journeydestination
              </Text>
              <Text className={classes.detailName}>
                specificTerms:journeydesignation
              </Text>
              <Text className={classes.detailName}>
                specificTerms:journeystatus
              </Text>
            </Grid>
            <Grid item sm={7}>
              <Text className={classes.details}>
                {data.data.JourneyDestination}
              </Text>
              <Text className={classes.details}>
                {data.data.JourneyDesignation}
              </Text>
              <Text className={classes.details}>{data.data.JourneyStatus}</Text>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles()((theme: Theme) =>
  ({
    detailName: {
      fontSize: 14,
      fontWeight: "bold",
      marginRight: theme.spacing(1),
    },

    details: {
      fontSize: 14,
      width: "90%",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textTransform: "none",
    }
  }));

export default JourneyResponse;
