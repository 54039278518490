import React from "react";
import { Carousel as UiCarousel } from "@periplus/ui-library";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { makeStyles } from 'tss-react/mui';

const {
  Slider,
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide: UiSlide,
} = UiCarousel;

const useStyle = makeStyles()({
  outerContainer: {
    height: "100%",
    position: "relative",
    padding: "0px 30px",
    width: "100%",
    "& .carousel__inner-slide": {
      padding: "12px 8px",
      borderRadius: 4,
      overflow: "hidden",
      display: "flex",
      justifyItems: "center",
      alignItems: "center",
    },
  },
  button: {
    position: "absolute",
    top: "calc(50% - 15px)",
    border: "none",
    background: "#fff0",
    color: "rgba(0, 0, 0, 0.54)",
    outline: "none",
    padding: 0,
    "&:disabled": {
      opacity: 0.4,
      cursor: "unset",
    },
  },
  buttonBack: {
    left: 4,
  },
  buttonNext: {
    right: 4,
  },
});

export interface CarouselProps<T> {
  className?: string;
  slides: T[];
  renderSlide: (arg0: {
    slide: T;
    index: number;
  }) => React.ElementType | JSX.Element;
  slideHeight?: number;
  slideWidth?: number;
  step: number;
}

const Carousel = <T extends any>({
  className,
  slides,
  renderSlide,
  ...rest
}: CarouselProps<T>) => {
  const { classes, cx } = useStyle();

  if (slides.length === 0) {
    return null;
  }

  return (
    // @ts-ignore
    <CarouselProvider
      style={{ maxWidth: "auto" }}
      totalSlides={slides.length}
      classes={{
        outerContainer: cx(classes.outerContainer, className),
      }}
      {...rest}
    >
      <ButtonBack className={cx(classes.buttonBack, classes.button)}>
        <ChevronLeftIcon />
      </ButtonBack>
      <Slider>
        {slides.map((slide: any, index: number) => (
          <UiSlide index={index} key={index}>
            {renderSlide({ slide, index })}
          </UiSlide>
        ))}
      </Slider>
      <ButtonNext className={cx(classes.buttonNext, classes.button)}>
        <ChevronRightIcon />
      </ButtonNext>
    </CarouselProvider>
  );
};

export default Carousel;
