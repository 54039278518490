import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useSubscription } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";
import { useApplicationState } from "contexts/ApplicationContext";
import dayjs from "dayjs";

export const SUBSCRIBE_DISCUSSION_CHANGES = gql`
  subscription aditSubscribeDiscussionChanges(
    $user: uuid!
    $date: timestamptz!
  ) {
    discussion_changes(
      where: {
        adit_user: { _eq: $user }
        last_msg_date: { _gt: $date }
        last_user: { _neq: $user }
      }
      distinct_on: file_id
    ) {
      file_name
      liber_user
      last_msg_date
      file_id
      discussion_theme
      discussion_id
      adit_user
      reference
    }
  }
`;

interface DeclarationDiscussion {
  file_name: string;
  liber_user: string;
  last_msg_date: string;
  file_id: string;
  discussion_theme: string;
  discussion_id: number;
  adit_user: string;
  reference: string;
}

type SubscriptionResult = {
  discussion_changes: DeclarationDiscussion[];
};

const useSubscribeDiscussionChanges = () => {
  const { user } = useAuth();
  const appState = useApplicationState();

  const { data } = useSubscription<SubscriptionResult>(
    SUBSCRIBE_DISCUSSION_CHANGES,
    {
      variables: {
        user: user?.userId,
        date: dayjs(appState.chat.lastUpdateDate).format(),
      },
      shouldResubscribe: true,
    }
  );

  return useMemo(
    () => ({
      data: {
        discussion_changes: data?.discussion_changes || [],
      },
    }),
    [data]
  );
};

export default useSubscribeDiscussionChanges;
