import React, { useState } from "react";
import { IconButton, Theme } from "@periplus/ui-library";
import AddIcon from "@mui/icons-material/Add";
import useMouse from "@react-hook/mouse-position";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    line: {
      border: `1px solid ${theme.palette.primary.main}`,
      position: "absolute",
    },

    verticalLine: {
      height: "100%",
      cursor: "col-resize",
    },

    horizontalLine: {
      width: "100%",
      cursor: "row-resize",
    },

    ruler: {
      position: "absolute",
      cursor: "pointer",
      pointerEvents: "auto",
    },

    topRuler: {
      top: -16,
      left: 0,
      right: 0,
      height: 18,
    },

    leftRuler: {
      left: -16,
      top: 0,
      bottom: 0,
      width: 18,
    },

    addButton: {
      position: "absolute",
      width: 18,
      height: 18,
      border: "none",
      borderRadius: "50%",
      cursor: "pointer",
      background: theme.palette.primary.main,
      pointerEvents: "none",
      padding: 0,
      margin: 0,
      color: "#fff",
    },

    verticalAddButton: {
      top: -9,
      left: "calc(50% - 9px)",
    },

    horizontalAddButton: {
      left: -9,
      top: "calc(50% - 9px)",
    }
  }));
interface INewLineIndicatorProps {
  direction: "row" | "column";
  onAddNewPosition: (newPos: number) => void;
}

const NewLineIndicator = ({
  direction,
  onAddNewPosition,
}: INewLineIndicatorProps) => {
  const { classes, cx } = useStyles();
  const ref = React.useRef(null);
  const [mouseLeave, setMouseLeave] = useState(false);
  const mouse = useMouse(ref);
  const isRowDirection = direction === "row";
  const activeAnchor = isRowDirection ? "left" : "top";

  const newPosition = isRowDirection ? mouse.x : mouse.y;
  return <>
    {!mouseLeave && !!newPosition && (
      <>
        <div
          className={cx(
            classes.line,
            isRowDirection ? classes.verticalLine : classes.horizontalLine
          )}
          style={{
            [activeAnchor]: newPosition - 1,
          }}
        ></div>
        <IconButton
          className={cx(
            classes.addButton,
            isRowDirection
              ? classes.verticalAddButton
              : classes.horizontalAddButton
          )}
          style={{
            [activeAnchor]: newPosition - 9,
          }}
          size="large"
        >
          <AddIcon style={{ fontSize: 16 }} />
        </IconButton>
      </>
    )}

    <div
      ref={ref}
      className={cx(
        classes.ruler,
        isRowDirection ? classes.topRuler : classes.leftRuler
      )}
      onClick={() => newPosition && onAddNewPosition(newPosition)}
      onMouseOver={() => setMouseLeave(false)}
      onMouseLeave={() => setMouseLeave(true)}
    />
  </>;
};

export default NewLineIndicator;
