import React, { FC, useCallback, useMemo } from "react";
import {
  ThreeDotsMenu,
  Skeleton,
  MuiTable as Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@periplus/ui-library";
import { Link } from "react-router-dom";
import { TextNaked } from "components/Text";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
import config from "config";
import { Text } from "components/Text";
import { useGetDownloadUrl } from "utils/azureBlobStorageUtils";
import { sortDocuments } from "utils/customRelevant";
import { SCHEDULE_DOC_ANNOTATION_EXPORT } from "graphql/mutations/validation";
import { DocumentTypeFlag } from "domain/documentType/types";
import useGetFilePages from "graphql/hooks/useGetFilePages";
import { SubTable, headerTranslation } from "components/Table";
import { makeStyles } from 'tss-react/mui';

interface IDocumentsExpandedRowViewProps {
  fileId: string;
  isUserAllowedToAutoClassification: boolean;
}

const useStyles = makeStyles()({
  link: {
    textDecoration: "none",
    width: "100%",
    display: "block",
    color: "#323232",
    "&:hover": {
      textDecoration: "none",
    },
    listIcon: {
      minWidth: 28,
    },
  },
});

const DownloadAction = ({
  document_name,
  document_blob,
}: {
  document_name: string;
  document_blob: { url: string };
}) => {
  // TODO: refactor this function to remove console error
  const { url: downloadUrl } = useGetDownloadUrl(
    get(document_blob, "url", ""),
    "application/pdf",
    `inline; filename="${encodeURI(document_name)}.pdf"`
  );

  const { classes } = useStyles();

  return Boolean(downloadUrl) ? (
    <a
      href={downloadUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.link}
      onClick={(e) => e.stopPropagation()}
    >
      <Text>{headerTranslation("common", "download")}</Text>
    </a>
  ) : null;
};

const DocumentValidate = ({
  row,
  isInternalValidation,
  isNanonet,
  isRossum,
}: {
  row: any;
  isInternalValidation: boolean;
  isNanonet: boolean;
  isRossum: boolean;
}) => {
  const { classes } = useStyles();

  if (isNanonet || isInternalValidation) {
    return (
      <Link
        to={`/select/validation/${row.original.id}`}
        className={classes.link}
      >
        <Text>{headerTranslation("common", "validate")}</Text>
      </Link>
    );
  }

  if (row.original.rossumUrl && isRossum) {
    return (
      <a
        href={row.original.rossumUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
        onClick={(e) => e.stopPropagation()}
      >
        <Text>{headerTranslation("common", "validate")}</Text>
      </a>
    );
  }
  return null;
};

const DocumentsExpandedRowView: FC<IDocumentsExpandedRowViewProps> = ({
  fileId,
  isUserAllowedToAutoClassification,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { data: documents, loading } = useGetFilePages(fileId);

  const [scheduleDocAnnotationExport] = useMutation(
    SCHEDULE_DOC_ANNOTATION_EXPORT
  );

  const handleClickExport = useCallback(
    (row: any) => {
      scheduleDocAnnotationExport({
        variables: {
          documentID: row.original.id,
        },
      });
    },
    [scheduleDocAnnotationExport]
  );

  const columns = useMemo(
    () => [
      {
        Header: headerTranslation("common", "document_type"),
        accessor: "document_type",
        Cell: ({ row }: { row: any }) => {
          return (
            <TextNaked>
              {row.original.documentType?.translations[language] ||
                row.original.documentType?.translations[config.defaultLanguage]}
            </TextNaked>
          );
        },
      },
      {
        Header: headerTranslation("common", "sequence"),
        accessor: "sequence",
      },
      {
        Header: headerTranslation("common", "page"),
        accessor: "number_of_pages",
      },
      {
        Header: headerTranslation("common", "is_validated"),
        accessor: "meta.is_validated",
        Cell: ({ row }: { row: any }) =>
          row.original.meta?.is_validated
            ? headerTranslation("common", "is_validated_true")
            : headerTranslation("common", "is_validated_false"),
      },
      {
        Header: headerTranslation("common", "status"),
        accessor: "document_status",
        Cell: ({ row }: { row: any }) =>
          headerTranslation("document_status", row.original.document_status),
      },
      {
        Header: headerTranslation("common", "last_export_date"),
        accessor: "meta",
        Cell: ({ row }: { row: any }) =>
          row.original.meta?.AnnotationExportedData
            ? dayjs(row.original.meta.AnnotationExportedData).format(
                "DD.MM.YYYY HH:mm:ss"
              )
            : "",
      },
      {
        Header: headerTranslation("common", "lastUpdate"),
        accessor: "last_modified_date",
        Cell: ({ cell }: { cell: { value: string } }) =>
          cell.value ? dayjs(cell.value).format("DD.MM.YYYY HH:mm:ss") : "",
      },
      {
        Header: headerTranslation("common", "user"),
        accessor: "created_by_user.display_name",
      },
      {
        id: "action",
        Header: headerTranslation("common", "actions"),
        disableSortBy: true,
        Cell: ({ row }: { row: any }) => {
          const { document_blobs, document_name } = row.original;
          const blobWithSameExtension = document_blobs?.slice(-1)?.[0];

          const options = [];
          if (blobWithSameExtension) {
            options.push({
              content: (
                <DownloadAction
                  document_blob={blobWithSameExtension}
                  document_name={document_name}
                />
              ),
              action: () => {},
            });
          }

          const is_validated = row.original.meta.is_validated;
          const documentFlags = Number(row.original.document_flags);

          const isInternalValidation =
            (documentFlags & DocumentTypeFlag.ocr_datapoint_completed) ===
            DocumentTypeFlag.ocr_datapoint_completed;

          const isNanonetsRequired = false;

          const isRossumExported = false;

          const isNanonetsCompleted = false;

          if (isUserAllowedToAutoClassification) {
            if (
              (isNanonetsCompleted && isNanonetsRequired) ||
              isInternalValidation ||
              isRossumExported
            ) {
              options.push({
                content: (
                  <DocumentValidate
                    row={row}
                    isInternalValidation={isInternalValidation}
                    isNanonet={isNanonetsRequired}
                    isRossum={isRossumExported}
                  />
                ),
                action: () => {},
              });
            }

            if (is_validated) {
              options.push({
                content: (
                  <>
                    <Text>common:export</Text>
                  </>
                ),
                action: () => handleClickExport(row),
              });
            }
          }

          return options.length > 0 ? (
            <ThreeDotsMenu options={options.filter(Boolean)} />
          ) : null;
        },
      },
    ],
    [isUserAllowedToAutoClassification, handleClickExport, language]
  );

  if (loading) {
    const rows = (
      <TableRow>
        {columns.map((_, index) => (
          <TableCell key={index}>
            <Skeleton animation="wave" />
          </TableCell>
        ))}
      </TableRow>
    );

    return (
      <Table>
        <TableHead>{rows}</TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    );
  }

  if (!documents.length)
    return <div style={{ textAlign: "center" }}>{t("noData")}</div>;

  return (
    <SubTable
      columns={columns}
      data={sortDocuments(documents)}
      tableConfig={{
        initialState: {
          pageSize: 1000,
        },
      }}
      columnSizes={[
        "19.5%",
        "10.5%",
        "10.5%",
        "10.5%",
        "10.5%",
        "10.5%",
        "10.5%",
        "10.5%",
        "7%",
      ]}
    />
  );
};

export default DocumentsExpandedRowView;
