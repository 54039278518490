import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const UPDATE_TENANT = gql`
  mutation ADIT_UPDATE_TENANT(
    $tenant_id: uuid!
    $address_id: Int!
    $tenant_name: String!
    $country: String!
    $city: String!
    $street: String!
    $street_number: String!
    $zipcode: String!
    $traderIdentificationNumber: String!
    $addressType: Int!
  ) {
    update_tenant_by_pk(
      pk_columns: { id: $tenant_id }
      _set: {
        tenant_name: $tenant_name
        trader_identification_number: $traderIdentificationNumber
      }
    ) {
      id
    }
    update_address_by_pk(
      pk_columns: { id: $address_id }
      _set: {
        company_name: $tenant_name
        country: $country
        city: $city
        street: $street
        street_number: $street_number
        zipcode: $zipcode
        address_type: $addressType
      }
    ) {
      id
    }
  }
`;

const useUpdateTenant = () => {
  const [updateTenant] = useMutation(UPDATE_TENANT);

  return updateTenant;
};

export default useUpdateTenant;
