import React, { FC, HtmlHTMLAttributes, useCallback, useReducer } from "react";
import {
  ActionBar,
  ActionBarItem,
  Button,
  Search,
  Theme,
  PageContainer,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
} from "@periplus/ui-library";
import OrgIdsTable from "./OrgIdsTable";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useTranslation } from "react-i18next";
import useUrlSearchParams from "hooks/useUrlSearchParams";
import { OrgId } from "graphql/hooks/useGetOrgIds";
import OrgIdFormDialog from "./OrgIdFormDialog";
import OrgIdDeleteDialog from "./OrgIdDeleteDialog";
import useGetOrgIdsSecured from "graphql/hooks/useGetOrgIdsSecured";
import usePageLocalStorage from "hooks/usePageLocalStorage";
import { makeStyles } from 'tss-react/mui';

interface OrgIdsProps extends HtmlHTMLAttributes<HTMLElement> {}

const useStyles = makeStyles()((theme: Theme) => ({
  searchBox: {
    maxWidth: 140,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(1.5),
  },
}));

type State = {
  selectedOrgId?: OrgId;
  isFormDialogOpen: boolean;
  isDeleteDialogOpen: boolean;
};

enum ActionType {
  Create,
  Edit,
  Delete,
  CloseFormDialog,
  CloseDeleteDialog,
}

type Action =
  | { type: ActionType.Create }
  | { type: ActionType.Edit; payload: OrgId }
  | { type: ActionType.Delete; payload: OrgId }
  | { type: ActionType.CloseFormDialog }
  | { type: ActionType.CloseDeleteDialog };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.Create:
      return { ...state, isFormDialogOpen: true };
    case ActionType.Edit:
      return {
        ...state,
        isFormDialogOpen: true,
        selectedOrgId: action.payload,
      };
    case ActionType.Delete:
      return {
        ...state,
        isDeleteDialogOpen: true,
        selectedOrgId: action.payload,
      };
    case ActionType.CloseFormDialog:
      return {
        ...state,
        selectedOrgId: undefined,
        isFormDialogOpen: false,
      };
    case ActionType.CloseDeleteDialog:
      return {
        ...state,
        selectedOrgId: undefined,
        isDeleteDialogOpen: false,
      };
  }
};

const OrgIds: FC<OrgIdsProps> = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    urlSearchParams: { search },
    setUrlSearchParams,
  } = useUrlSearchParams({ search: "" });
  const { pageLocalStorage } = usePageLocalStorage({
    itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
  });

  const [state, dispatch] = useReducer(reducer, {
    selectedOrgId: undefined,
    isFormDialogOpen: false,
    isDeleteDialogOpen: false,
  });

  const { data, loading, refetch } = useGetOrgIdsSecured({
    search,
    offset: 0,
    limit: pageLocalStorage.itemsPerPage,
  });

  const handleCreate = useCallback(() => {
    dispatch({ type: ActionType.Create });
  }, []);

  const handleSearchChange = useCallback(
    (search) => {
      setUrlSearchParams({ search });
    },
    [setUrlSearchParams]
  );

  const handleTableFetch = useCallback(
    (offset, limit) => refetch({ limit, offset, search }),
    [refetch, search]
  );

  return (
    <PageContainer title={t("navigation:org-ids")}>
      <ActionBar justifyContent="flex-end">
        <ActionBarItem>
          <Search
            className={classes.searchBox}
            value={search}
            onChange={handleSearchChange}
          />
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowRightAltIcon />}
            className={classes.button}
            onClick={handleCreate}
          >
            {t("common:createNew")}
          </Button>
        </ActionBarItem>
      </ActionBar>
      <OrgIdsTable
        loading={loading}
        data={data?.org_ids}
        totalCount={data?.org_ids_total_count}
        fetch={handleTableFetch}
        onEdit={(orgId) => dispatch({ type: ActionType.Edit, payload: orgId })}
        onDelete={(orgId) =>
          dispatch({ type: ActionType.Delete, payload: orgId })
        }
      />
      {state.isFormDialogOpen && (
        <OrgIdFormDialog
          orgId={state.selectedOrgId}
          onClose={() => dispatch({ type: ActionType.CloseFormDialog })}
          onConfirm={() => {
            dispatch({ type: ActionType.CloseFormDialog });
            refetch();
          }}
        />
      )}
      {state.isDeleteDialogOpen && state.selectedOrgId && (
        <OrgIdDeleteDialog
          orgId={state.selectedOrgId}
          onClose={() => dispatch({ type: ActionType.CloseDeleteDialog })}
          onConfirm={() => {
            dispatch({ type: ActionType.CloseDeleteDialog });
            refetch();
          }}
        />
      )}
    </PageContainer>
  );
};

export default OrgIds;
