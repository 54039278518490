import React, { FC } from "react";
import { Typography, Checkbox } from "@periplus/ui-library";
import { IApiKeyScope } from "graphql/hooks/useGetApiKeys";
import { makeStyles } from 'tss-react/mui';

interface ScopeCheckboxProps {
  scope: IApiKeyScope;
  checked: boolean;
  description: string;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
}

const useStyles = makeStyles()({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    "&:not(:last-child)": {
      marginBottom: 8,
    },
  },
  checkbox: {
    padding: 0,
  },
  infoContainer: {
    marginLeft: 8,
    display: "flex",
    flexDirection: "column",
  },
  label: {
    color: "#333333",
  },
});

const ScopeCheckbox: FC<ScopeCheckboxProps> = ({
  scope,
  checked,
  description,
  onChange,
  disabled,
}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Checkbox
        className={classes.checkbox}
        checked={checked}
        onChange={(e, checked) => onChange && onChange(checked)}
        disabled={disabled}
      />
      <div className={classes.infoContainer}>
        <Typography className={classes.label}>{scope}</Typography>
        <Typography color="textSecondary">{description}</Typography>
      </div>
    </div>
  );
};
export default ScopeCheckbox;
