import React from "react";
import { TypographyProps } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import Children from "react-children-utilities";

// Note: This type is not exported by default. With this, we can actually import and use it!
type TOptions = import("i18next").default.TOptions;

interface IText extends Omit<TypographyProps, "translate"> {
  /**
   * If set as false, then the content (children) passed along will not be attempted to be translated!
   */
  translate?: boolean;
  translationOptions?: TOptions;
}

const TextNaked: React.FC<IText> = ({
  children,
  translate = true,
  translationOptions = {},
  style = {},
}) => {
  const { t } = useTranslation();

  const text = Children.onlyText(children);

  return (
    <div style={style}>{translate ? t(text, translationOptions) : text}</div>
  );
};

export default TextNaked;
