//import { IUser } from "contexts/AuthContext";

import { LanguageType } from "i18n";

export interface EntityDependencies {
  language: string;
}

export default abstract class Entity {
  constructor(dep: EntityDependencies) {
    this._dep = {
      ...dep,
      language: dep.language as LanguageType,
    };
  }

  abstract toDBObject(): { [key: string]: any };

  protected _dep: {
    language: LanguageType;
    //currentUser: IUser;
  };
}
