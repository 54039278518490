import Children from "react-children-utilities";
import getDocumentExtension from "./getDocumentExtension";

function getDocumentNameWithCorrectExtension(document?: any) {
  const extension = getDocumentExtension(
    document.name,
    document.document_blobs
  );
  const name = Children.onlyText(document.name ?? document.document_name);
  const extensionIndex = name.lastIndexOf(".");
  const nameWithoutExtension = name.substring(
    0,
    extensionIndex === -1 ? name.length : extensionIndex
  );

  return extension
    ? `${nameWithoutExtension}.${extension}`
    : nameWithoutExtension;
}

export default getDocumentNameWithCorrectExtension;
