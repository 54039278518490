import axios from "axios";
import { keycloak } from "graphql/client";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_ADIT_API_URL}/api`,
});

instance.interceptors.response.use(
  (success) => {
    if (success?.data?.status === "error") {
      return Promise.reject(success.data.error);
    }

    return Promise.resolve(success);
  },
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      keycloak.updateToken(25).success(() => {
        axios.request({
          ...error.config,
          headers: {
            ...error.config.headers,
            Authorization: `bearer ${keycloak.token}`,
          },
        });
      });
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
