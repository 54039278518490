import { gql } from "@apollo/client";
import {
  Declaration_Constraint,
  Declaration_Request_Address_Constraint,
  Declaration_Request_Address_Update_Column,
  Declaration_Update_Column,
  Goods_Item_Constraint,
  Goods_Item_Update_Column,
  Packagings_Constraint,
  Packagings_Update_Column,
  Previous_Documents_Constraint,
  Produced_Documents_Constraint,
  Produced_Documents_Update_Column,
  useAditUpsertDeclarationMutation,
} from "graphql/generated";
import { useCallback } from "react";
import {
  DeclarationFormDB,
  DeclarationGoodsItemPackagingFormDB,
  DeclarationGoodsItemProducedDocumentFormDB,
} from "./types";
import { Previous_Documents_Update_Column } from "graphql/generated";
import { Containers_Constraint } from "graphql/generated";
import { Containers_Update_Column } from "graphql/generated";
import { Declaration_Special_Mentions_Constraint } from "graphql/generated";
import { Declaration_Special_Mentions_Update_Column } from "graphql/generated";

gql`
  mutation AditUpsertDeclaration(
    $file_id: uuid!
    $dr_no: smallint!
    $object: declaration_insert_input!
    $constraint: declaration_constraint!
    $update_columns: [declaration_update_column!]!
    $goodsItemsToDeleteExp: [goods_item_bool_exp!]!
    $previousDocumentsToDeleteExp: [previous_documents_bool_exp!]!
    $containersToDeleteExp: [containers_bool_exp!]!
    $declarationSpecialMentionsToDeleteExp: [declaration_special_mentions_bool_exp!]!
    $packagingsToDeleteExp: [packagings_bool_exp!]!
    $producedDocumentsToDeleteExp: [produced_documents_bool_exp!]!
  ) {
    insert_declaration_one(
      object: $object
      on_conflict: { constraint: $constraint, update_columns: $update_columns }
    ) {
      file_id
      dr_no
    }
    delete_previous_documents(
      where: {
        file_id: { _eq: $file_id }
        dr_no: { _eq: $dr_no }
        _or: $previousDocumentsToDeleteExp
      }
    ) {
      affected_rows
    }
    delete_containers(
      where: {
        file_id: { _eq: $file_id }
        dr_no: { _eq: $dr_no }
        _or: $containersToDeleteExp
      }
    ) {
      affected_rows
    }
    delete_declaration_special_mentions(
      where: {
        file_id: { _eq: $file_id }
        dr_no: { _eq: $dr_no }
        _or: $declarationSpecialMentionsToDeleteExp
      }
    ) {
      affected_rows
    }
    delete_goods_item(
      where: {
        file_id: { _eq: $file_id }
        dr_no: { _eq: $dr_no }
        _or: $goodsItemsToDeleteExp
      }
    ) {
      affected_rows
    }
    delete_packagings(
      where: {
        file_id: { _eq: $file_id }
        dr_no: { _eq: $dr_no }
        _or: $packagingsToDeleteExp
      }
    ) {
      affected_rows
    }
    delete_produced_documents(
      where: {
        file_id: { _eq: $file_id }
        dr_no: { _eq: $dr_no }
        _or: $producedDocumentsToDeleteExp
      }
    ) {
      affected_rows
    }
  }
`;

export default () => {
  const [upsertDeclaration] = useAditUpsertDeclarationMutation();

  return useCallback(
    (
      {
        addresses,
        previous_documents,
        containers,
        declaration_special_mentions,
        goods_items,
        ...restDeclaration
      }: DeclarationFormDB,
      oldDeclaration: DeclarationFormDB
    ) => {
      const oldGoodsItemsPackagings = oldDeclaration.goods_items.reduce<
        DeclarationGoodsItemPackagingFormDB[]
      >((acc, { packagings }) => [...acc, ...packagings], []);
      const newGoodsItemsPackagings = goods_items.reduce<
        DeclarationGoodsItemPackagingFormDB[]
      >((acc, { packagings }) => [...acc, ...packagings], []);
      const oldGoodsItemsProducedDocuments = oldDeclaration.goods_items.reduce<
        DeclarationGoodsItemProducedDocumentFormDB[]
      >((acc, { produced_documents }) => [...acc, ...produced_documents], []);
      const newGoodsItemsProducedDocuments = goods_items.reduce<
        DeclarationGoodsItemProducedDocumentFormDB[]
      >((acc, { produced_documents }) => [...acc, ...produced_documents], []);

      return upsertDeclaration({
        variables: {
          file_id: restDeclaration.file_id,
          dr_no: restDeclaration.dr_no,
          object: {
            ...restDeclaration,
            addresses: {
              data: addresses.map(({ address, ...restDeclarationAddress }) => ({
                ...restDeclarationAddress,
                address_id: address?.id,
              })),
              on_conflict: {
                constraint:
                  Declaration_Request_Address_Constraint.DeclarationRequestAddressPkey,
                update_columns: [
                  Declaration_Request_Address_Update_Column.AddressId,
                  Declaration_Request_Address_Update_Column.Refs,
                ],
              },
            },
            previous_documents: {
              data: previous_documents,
              on_conflict: {
                constraint: Previous_Documents_Constraint.PreviousDocumentsPk,
                update_columns: [
                  Previous_Documents_Update_Column.PreviousDocumentType,
                  Previous_Documents_Update_Column.PreviousDocumentReference,
                  Previous_Documents_Update_Column.AdditionalInformation,
                ],
              },
            },
            containers: {
              data: containers,
              on_conflict: {
                constraint: Containers_Constraint.ContainersPkey,
                update_columns: [Containers_Update_Column.ContainerNumber],
              },
            },
            declaration_special_mentions: {
              data: declaration_special_mentions,
              on_conflict: {
                constraint:
                  Declaration_Special_Mentions_Constraint.DeclarationSpecialMentionsPkey,
                update_columns: [
                  Declaration_Special_Mentions_Update_Column.SequenceNumber,
                  Declaration_Special_Mentions_Update_Column.Text,
                ],
              },
            },
            goods_items: {
              data: goods_items.map(
                ({ packagings, produced_documents, ...restGoodItem }) => ({
                  ...restGoodItem,
                  packagings: {
                    data: packagings,
                    on_conflict: {
                      constraint: Packagings_Constraint.PackagingsPkey,
                      update_columns: [
                        Packagings_Update_Column.PackagingType,
                        Packagings_Update_Column.PackagingQuantity,
                        Packagings_Update_Column.PackagingReferenceNumber,
                      ],
                    },
                  },
                  produced_documents: {
                    data: produced_documents,
                    on_conflict: {
                      constraint:
                        Produced_Documents_Constraint.ProducedDocumentsPkey,
                      update_columns: [
                        Produced_Documents_Update_Column.ProducedDocumentType,
                        Produced_Documents_Update_Column.ProducedDocumentReferenceNumber,
                        Produced_Documents_Update_Column.ProducedDocumentIssueDate,
                        Produced_Documents_Update_Column.AdditionalInformation,
                      ],
                    },
                  },
                })
              ),
              on_conflict: {
                constraint: Goods_Item_Constraint.GoodsItemResponsePkey,
                update_columns: [
                  Goods_Item_Update_Column.SelectionResult,
                  Goods_Item_Update_Column.CommodityCode,
                  Goods_Item_Update_Column.CommodityCodeConfirmation,
                  Goods_Item_Update_Column.StatisticalCode,
                  Goods_Item_Update_Column.DutyRate,
                  Goods_Item_Update_Column.RateConfirmation,
                  Goods_Item_Update_Column.Description,
                  Goods_Item_Update_Column.NetDuty,
                  Goods_Item_Update_Column.NetMass,
                  Goods_Item_Update_Column.NetMassConfirmation,
                  Goods_Item_Update_Column.TareSupplement,
                  Goods_Item_Update_Column.TareSupplementConfirmation,
                  Goods_Item_Update_Column.GrossMass,
                  Goods_Item_Update_Column.GrossMassConfirmation,
                  Goods_Item_Update_Column.CustomsNetWeight,
                  Goods_Item_Update_Column.AdditionalUnit,
                  Goods_Item_Update_Column.AdditionalUnitConfirmation,
                  Goods_Item_Update_Column.CommercialGood,
                  Goods_Item_Update_Column.ClearanceType,
                  Goods_Item_Update_Column.CustomsFavourCode,
                  Goods_Item_Update_Column.OriginCountry,
                  Goods_Item_Update_Column.OriginPreference,
                  Goods_Item_Update_Column.PreferenceConfirmation,
                  Goods_Item_Update_Column.VatCode,
                  Goods_Item_Update_Column.VatCodeConfirmation,
                  Goods_Item_Update_Column.StatisticalValue,
                  Goods_Item_Update_Column.StatisticalValueConfirmation,
                  Goods_Item_Update_Column.VatValue,
                  Goods_Item_Update_Column.VatValueConfirmation,
                  Goods_Item_Update_Column.Repair,
                ],
              },
            },
          },
          constraint: Declaration_Constraint.CustomsResponsePkey,
          update_columns: [
            Declaration_Update_Column.GrossMass,
            Declaration_Update_Column.NetMass,
            Declaration_Update_Column.TransportMode,
            Declaration_Update_Column.TransportType,
            Declaration_Update_Column.TransportCountry,
            Declaration_Update_Column.TransportNumber,
            Declaration_Update_Column.ServiceType,
            Declaration_Update_Column.InvoiceCurrencyType,
            Declaration_Update_Column.ClearanceLocation,
            Declaration_Update_Column.PlaceOfUnloading,
            Declaration_Update_Column.DeclarationTime,
            Declaration_Update_Column.DeclarationType,
            Declaration_Update_Column.Reason,
            Declaration_Update_Column.Lang,
            Declaration_Update_Column.CustomsOfficeNumber,
            Declaration_Update_Column.DispatchCountry,
            Declaration_Update_Column.DispatchCountryConfirmation,
            Declaration_Update_Column.CorrectionCode,
            Declaration_Update_Column.CorrectionReason,
            Declaration_Update_Column.PayerId,
            Declaration_Update_Column.TransportInContainer,
            Declaration_Update_Column.CustomsAccountNumber,
            Declaration_Update_Column.CustomsAccountAddressType,
            Declaration_Update_Column.VatAccountAddressType,
            Declaration_Update_Column.VatAccountNumber,
            Declaration_Update_Column.Incoterms,
            Declaration_Update_Column.VatNumber,
          ],
          previousDocumentsToDeleteExp: oldDeclaration.previous_documents
            .filter(
              (oldEl) =>
                !previous_documents.some((newEl) => oldEl.id === newEl.id)
            )
            .map((oldEl) => ({
              id: { _eq: oldEl.id },
            })),
          containersToDeleteExp: oldDeclaration.containers
            .filter(
              (oldEl) => !containers.some((newEl) => oldEl.id === newEl.id)
            )
            .map((oldEl) => ({
              id: { _eq: oldEl.id },
            })),
          declarationSpecialMentionsToDeleteExp:
            oldDeclaration.declaration_special_mentions
              .filter(
                (oldEl) =>
                  !declaration_special_mentions.some(
                    (newEl) => oldEl.id === newEl.id
                  )
              )
              .map((oldEl) => ({
                id: { _eq: oldEl.id },
              })),
          goodsItemsToDeleteExp: oldDeclaration.goods_items
            .filter(
              (oldEl) =>
                !goods_items.some(
                  (newEl) =>
                    oldEl.customs_item_number === newEl.customs_item_number
                )
            )
            .map((oldEl) => ({
              customs_item_number: { _eq: oldEl.customs_item_number },
            })),
          packagingsToDeleteExp: oldGoodsItemsPackagings
            .filter(
              (oldEl) =>
                !newGoodsItemsPackagings.some((newEl) => oldEl.id === newEl.id)
            )
            .map((oldEl) => ({
              id: { _eq: oldEl.id },
            })),
          producedDocumentsToDeleteExp: oldGoodsItemsProducedDocuments
            .filter(
              (oldEl) =>
                !newGoodsItemsProducedDocuments.some(
                  (newEl) => oldEl.id === newEl.id
                )
            )
            .map((oldEl) => ({
              id: { _eq: oldEl.id },
            })),
        },
      });
    },
    [upsertDeclaration]
  );
};
