import React, { FC, useCallback, ReactNode, memo, useMemo } from "react";
import { Grid, Paper, Box, Divider, Theme } from "@periplus/ui-library";
import Progress from "components/Progress";
import Carousel from "components/Carousel";
import { Carousel as UiCarousel } from "@periplus/ui-library";
import Children from "react-children-utilities";
import { Page } from "graphql/hooks/useGetDocumentPages";
import DocumentDetails from "./DocumentDetails";
import getDocumentExtension from "utils/getDocumentExtension";
import { useTranslation } from "react-i18next";
import { Document } from "domain/document/types";
import useSasToken from "hooks/useSasToken";
import { makeStyles } from 'tss-react/mui';

const { Image } = UiCarousel;

const useStyles = makeStyles()((theme: Theme) => ({
  previewerContent: {
    height: "100%",
    "&>*:not(:last-child)": {
      height: "100%",
    },
  },
  fileDetails: {
    height: "100%",
    padding: 12,
  },
  dividerContainer: {
    padding: "12px 0",
  },
  slideContainer: {
    borderRadius: 5,
    boxShadow: "0px 2px 8px 1px rgba(0, 0, 30, 0.1)",
  },
  sliderWrapper: {
    flex: "auto",
    position: "relative",
  },
}));

interface IPDFPreviewProps {
  file?: {
    name: React.ReactNode;
    creationDate: string;
    org_id_description?: string;
    pages: Page[];
    extension?: string;
    status?: ReactNode;
    document_blobs?: Document["document_blobs"];
  };
  selected?: boolean;
  [key: string]: any;
  finish?: boolean;
  placeholder?: ReactNode;
  loading?: boolean;
}

const PDFPreview: FC<IPDFPreviewProps> = memo(
  ({ file, finish = false, placeholder, loading = false }) => {
    const { classes } = useStyles();
    const thumbnailsSasToken = useSasToken({ container: "thumbs" });

    const { t } = useTranslation();

    const cachedPages = useMemo(() => {
      const statusesToFilter = ["classified"];

      let pagesToLook = file?.pages || [];
      if (!finish) {
        pagesToLook = pagesToLook.filter(
          (page: any) => !statusesToFilter.includes(page.page_status)
        );
      }

      return pagesToLook.map((page) => {
        const thumbnailBigUrl =
          thumbnailsSasToken && `${page.thumbnailBigUrl}?${thumbnailsSasToken}`;
        const thumbnailSmallUrl =
          thumbnailsSasToken &&
          `${page.thumbnailSmallUrl}?${thumbnailsSasToken}`;

        return {
          ...page,
          thumbnailSmallUrl,
          thumbnailBigUrl,
        };
      });
    }, [file, finish, thumbnailsSasToken]);

    const details = useMemo(() => {
      const { name, creationDate, org_id_description, status } = file || {
        name: "Doc Name",
        creationDate: undefined,
        org_id_description: "",
        status: "",
      };

      const nameAsString = Children.onlyText(name);
      const isFileName = /^MRS|^MRT/.test(nameAsString);

      const documentExtension = getDocumentExtension(
        file?.name,
        file?.document_blobs
      );
      const nameWithoutExtension = nameAsString.replace(/\.[^.]*$/, "");

      return {
        name: nameWithoutExtension,
        creationDate,
        org_id_description,
        status,
        extension: !isFileName && documentExtension,
        pages: cachedPages,
      };
    }, [file]);

    const renderPreview = useCallback(
      ({ slide }) => {
        if (loading || !slide) {
          return (
            <Paper elevation={6}>
              <Progress />
            </Paper>
          );
        }

        return (
          <Paper
            elevation={6}
            style={{ transform: `rotate(${slide.rotate}deg)` }}
          >
            <img
              style={{ width: "100%" }}
              src={slide.thumbnailBigUrl}
              alt="document page preview"
              data-pp="slide-preview"
            />
          </Paper>
        );
      },
      [loading]
    );

    const renderSlide = useCallback(({ slide }) => {
      const isHorizontal = [90, 270].includes(Math.abs(slide.rotate));
      const style = slide.rotate
        ? {
            transform: `rotate(${slide.rotate}deg) ${
              isHorizontal ? "scale(0.7)" : ""
            } `,
          }
        : {};
      return (
        <Box className={classes.slideContainer} style={style}>
          <Image
            hasMasterSpinner={false}
            src={slide.thumbnailBigUrl}
            alt="document page preview"
          />
        </Box>
      );
    }, []);

    return (
      <Paper
        variant="outlined"
        sx={{
          height: 240,
          width: "100%",
        }}
      >
        <Grid container direction="row" className={classes.previewerContent}>
          <Grid item className={classes.fileDetails}>
            <DocumentDetails details={details} />
          </Grid>
          <Grid item className={classes.dividerContainer}>
            <Divider flexItem={false} orientation="vertical" />
          </Grid>
          <Grid item className={classes.sliderWrapper}>
            {!file && (
              <Box
                position="absolute"
                top="50%"
                left="50%"
                style={{ transform: "translate(-50%, -50%)" }}
              >
                {placeholder || t("PDFPreviewPlaceholder")}
              </Box>
            )}
            <Carousel
              renderSlide={renderSlide}
              renderPreview={renderPreview}
              step={1}
              slideHeight={240}
              slideWidth={168}
              slides={cachedPages}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
);

export default PDFPreview;
