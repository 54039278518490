import React, { FC, useState, useCallback } from "react";
import {
  ActionBar,
  ActionBarItem,
  TextField,
  Button,
  Panel,
  PageContainer,
  Box,
  Theme,
  FormHelperText,
  Checkbox,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { useDebounce } from "@react-hook/debounce";
import isEmpty from "lodash/isEmpty";
import { HARDCODED_TENANTS } from "domain/tenant/type";
import useGetTenantSettings from "graphql/hooks/useGetTenantSettings";
import usePatchUpdateTenantSettings from "graphql/hooks/usePatchUpdateTenantSettings";
import Progress from "components/Progress";
import { useParams } from "react-router-dom";
import FormGroup from "./FormGroup";
import { makeStyles } from "tss-react/mui";

const getConfigGroups = (tenantId: string) => [
  {
    groupName: "Adit Settings",
    groupDescription: "General Settings of Adit",
    fields: [
      {
        fieldName: "FileExportProviderType",
        fieldDescription:
          "Where document is exported : MRSG, Dropbox, Default.",
      },
      {
        fieldName: "MaxPdfDocumentPagesCount",
        fieldDescription:
          "Number of pages thumbnails are generated for PDF document",
      },
      {
        fieldName: "MaxOfficeDocumentPagesCount",
        fieldDescription:
          "Number of pages thumbnails are generated for Office document",
      },
      {
        fieldName: "FileIdentificationProviderType",
        fieldDescription:
          "How File.Identification is obtained : MRSG, Default.",
      },
      {
        fieldName: "MaxEmailDocumentPagesProcessed",
        fieldDescription:
          "Number of pages thumbnails are generated for Email document",
      },
      {
        fieldName: "SystemLabelsAvailableInClassification",
        fieldDescription:
          "Which labels user can choose from in document type management",
      },
      {
        fieldName: "PdfCreatorSuffix",
        fieldDescription:
          "PDF document's creator suffix (default format is %username% %creator suffix%)",
      },
      {
        fieldName: "DocumentsContainer",
        fieldDescription: "Azure blob container name for storing documents",
      },
      {
        fieldName: "ThumbnailsContainer",
        fieldDescription: "Azure blob container name for storing thumbnails",
      },
      {
        fieldName: "AllowAddUserInUserManagement",
        fieldDescription:
          "Whether new users can be created in users management",
      },
      {
        fieldName: "UseAutoclassification",
        fieldDescription: "Show autoclassification dialog in classify",
      },
      {
        fieldName: "UseJourneys",
        fieldDescription: "Uses journeys in file screen",
      },
      {
        fieldName: "DocumentExtractorType",
        fieldDescription:
          "Which OCR engine to use, 0 for GoogleVision, 1 for Tesseract",
      },
    ],
  },
  ...(tenantId === HARDCODED_TENANTS.mnr
    ? [
        {
          groupName: "M+R Settings",
          groupDescription: "Tenant Settings",
          fields: [
            {
              fieldName: "MNR_NEW_SHIPMENT_REQUEST_ENV_DATA",
              fieldDescription: "M+R param for new shipment request",
            },
            {
              fieldName: "MNR_EXPORT_DOCUMENT_ANNOTATION_URL",
              fieldDescription:
                "M+R endpoint for exporting document annotation data",
            },
            {
              fieldName: "MNR_EXPORT_DOCUMENT_BASIC_CREDENTIALS",
              fieldDescription:
                "M+R authentication credentials for endpoints using Basic authentication",
            },
            {
              fieldName: "MNR_AUTH_URL",
              fieldDescription: "M+R token endpoint",
            },
            {
              fieldName: "MNR_AUTH_LOGIN",
              fieldDescription: "M+R user login",
            },
            {
              fieldName: "MNR_AUTH_PASSWORD",
              fieldDescription: "M+R user password",
            },
            {
              fieldName: "MNR_EXPORT_JOURNEY_URL",
              fieldDescription: "M+R edpoint for exporting Journey folders",
            },
            {
              fieldName: "MNR_EXPORT_DOCUMENT_URL",
              fieldDescription: "M+R endpoint for exporting FIle folders",
            },
            {
              fieldName: "MNR_NEW_SHIPMENT_REQUEST_URL",
              fieldDescription:
                "M+R endpoint for requesting File.Identification",
            },
          ],
        },
      ]
    : []),
  {
    groupName: "Data Extraction",
    groupDescription: "Data Extraction service settings",
    fields: [
      {
        fieldName: "TENANT_REQUIRES_FOUN",
        fieldDescription:
          "If set to 'true', FoUn-related Document Type settings are ignored and FoUn is used for selected Document Types",
      },
      {
        fieldName: "TENANT_REQUIRES_FOUN_DOC_TYPES",
        fieldDescription:
          "List of Document Types that requires FoUn ( relevant if TENANT_REQUIRES_FOUN is opted in )",
      },
      {
        fieldName: "TENANT_FOUN_USER_IDS",
        fieldDescription:
          "List of user id that may use FoUn ( relevant if TENANT_REQUIRES_FOUN is opted in )",
      },
    ],
  },
  {
    groupName: "WeDeclare Declaration Request",
    groupDescription: "WeDeclare Declaration Request settings",
    fields: [
      {
        fieldName: "TENANT_REQUEST_IDENTIFICATION_FOR_DECLARATIONS",
        fieldDescription:
          "If set to 'true', creating declaration automatically calls identification request api.",
      },
      {
        fieldName: "TENANT_DEFAULT_NEW_SHIPMENT_REQUEST_ENV_DATA",
        fieldDescription:
          "Consignor,Shipper,Consignee,Importer address references, separated by comma, without spaces, e.g. 508849,508850,508851,508852",
      },
    ],
  },
];

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    height: "calc(100vh - 200px)",
    overflow: "auto",
    width: "100%",
    marginTop: theme.spacing(2),
  },
  formGroup: {
    "&:not(:first-of-type)": {
      marginTop: 0,
    },
    marginBottom: theme.spacing(4),
    justifyContent: "space-between",
    alignItems: "unset",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  formGroupTitle: {
    marginTop: 6,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: "#000000de",
  },
  leftMargin: {
    marginLeft: 0,

    [theme.breakpoints.up("md")]: {
      marginLeft: 25,
    },
  },
  formGroupContent: {
    width: "100%",
    maxWidth: "100%",

    [theme.breakpoints.up("md")]: {
      maxWidth: 400,
    },
  },
  inputContainer: {
    width: "100%",
    height: 40,
  },
  maxWidth: {
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
  },
}));

const TenantSettings: FC<{}> = () => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const [open, setOpen] = useState<{ [key: string]: boolean }>({});
  const [savedData, setSavedData] = useState<any>({});
  const [changedData, setData] = useDebounce({}, 300);

  const { data, loading } = useGetTenantSettings(id);

  const { update: updateTenantSettings, loading: updateInProgress } =
    usePatchUpdateTenantSettings(id);

  const handleToggleExpand = useCallback(
    (collapser: string) => (expanded: boolean) => {
      setOpen((previous) => ({
        ...previous,
        [collapser]: !expanded,
      }));
    },
    []
  );

  const handleSaveSettings = useCallback(() => {
    updateTenantSettings(changedData).then(() => {
      setSavedData((prevData: any) => ({
        [id]: {
          ...prevData[id],
          ...changedData,
        },
      }));
      setData({});
    });
  }, [changedData, setData, id, updateTenantSettings]);

  const renderField = useCallback(
    ({ fieldName, fieldDescription }, index) => {
      const config =
        (savedData[id] && savedData[id][fieldName]) || data[fieldName];

      const { value, isSecret } = config || {
        value: "",
        isSecret: [
          "MNR_EXPORT_DOCUMENT_BASIC_CREDENTIALS",
          "MNR_AUTH_PASSWORD",
          "RossumAuthPassword",
          "NanonetsAuthKey",
        ].includes(fieldName),
      };

      if (
        [
          "AllowAddUserInUserManagement",
          "UseAutoclassification",
          "UseJourneys",
        ].includes(fieldName)
      ) {
        // @ts-ignore
        const changedValue = changedData[fieldName]?.value;
        const checked =
          changedValue != null ? JSON.parse(changedValue) : value || false;

        return (
          <FormGroup
            title={fieldName}
            key={fieldName}
            classes={{
              root: cx(classes.formGroup, classes.maxWidth),
              content: cx(classes.formGroupContent, classes.leftMargin),
              title: classes.formGroupTitle,
            }}
          >
            <Checkbox
              checked={checked}
              style={{ padding: 0 }}
              onChange={(event: any) => {
                event.persist();
                const newValue = event.target?.checked;

                setData((prevData: any) => {
                  if (newValue === value) {
                    delete prevData[fieldName];
                  } else {
                    prevData[fieldName] = {
                      value: newValue,
                      isSecret,
                    };
                  }

                  return { ...prevData };
                });
              }}
            />
            <FormHelperText>{fieldDescription}</FormHelperText>
          </FormGroup>
        );
      }

      return (
        <FormGroup
          title={fieldName}
          key={`${fieldName}.${index}`}
          classes={{
            root: cx(classes.formGroup, classes.maxWidth),
            content: cx(classes.formGroupContent, classes.leftMargin),
            title: classes.formGroupTitle,
          }}
        >
          <TextField
            type={isSecret ? "password" : "string"}
            variant="outlined"
            defaultValue={value}
            helperText={fieldDescription}
            className={classes.formGroupContent}
            onChange={(event: any) => {
              event.persist();
              const newValue = event.target?.value;

              setData((prevData: any) => {
                if (
                  [null, undefined, "", value.toString()].includes(newValue) &&
                  !config
                ) {
                  delete prevData[fieldName];
                } else {
                  prevData[fieldName] = {
                    value: newValue,
                    isSecret,
                  };
                }

                return { ...prevData };
              });
            }}
          />
        </FormGroup>
      );
    },

    // eslint-disable-next-line
    [changedData, data]
  );

  const renderGroup = useCallback(
    ({ groupName, groupDescription, fields }) => (
      <Panel title={groupName} key={groupName}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {fields.map(renderField)}
        </div>
      </Panel>
    ),
    // eslint-disable-next-line
    [open, renderField, handleToggleExpand, data]
  );

  return (
    <PageContainer title={t("navigation:settings")}>
      <ActionBar>
        <ActionBarItem style={{ justifyContent: "flex-end", width: "100%" }}>
          <Button
            variant="contained"
            color="primary"
            disabled={isEmpty(changedData)}
            onClick={handleSaveSettings}
            loading={updateInProgress}
          >
            {t("common:save")}
          </Button>
        </ActionBarItem>
      </ActionBar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          mt: 2,
        }}
      >
        {loading ? <Progress /> : getConfigGroups(id).map(renderGroup)}
      </Box>
    </PageContainer>
  );
};

export default TenantSettings;
