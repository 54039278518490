import { gql } from "@apollo/client";

export const DELETE_DOCUMENT = gql`
  mutation ADIT_DELETE_DOCUMENT($id: [uuid!]!) {
    update_document(
      where: { id: { _in: $id } }
      _set: { is_deleted: true, checksum: null }
    ) {
      affected_rows
    }
  }
`;

export const APPEND_META_DOCUMENT = gql`
  mutation APPEND_META_DOCUMENT($id: [uuid!]!, $meta: jsonb) {
    update_document(where: { id: { _in: $id } }, _append: { meta: $meta }) {
      affected_rows
    }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation createDocument($document: CreateDocumentInput!) {
    createDocument(document: $document) {
      success
      error
      documentId
    }
  }
`;

export const CLEAR_DOCUMENT_CHECKSUM = gql`
  mutation CLEAR_DOCUMENT_CHECKSUM($checksums: [String!]!) {
    update_document(
      where: {
        checksum: { _in: $checksums }
        document_type: { _eq: "unknown" }
      }
      _set: { checksum: null }
    ) {
      affected_rows
    }
  }
`;

// export const FETCH_ROSSUM_EMBED_URL = gql`
//   mutation FETCH_ROSSUM_EMBED_URL($documentLinks: [document_set_input!]!) {
//     getRossumEmbedUrl(documentLinks: $documentLinks) {
//       url
//       return_url
//       cancel_url
//       annotUrl
//     }
//   }
// `;

export const UPSERT_DOC_ANNOTATION = gql`
  mutation UPSERT_DOC_ANNOTATION(
    $docId: uuid!
    $annoTypeId: smallint!
    $value: jsonb!
  ) {
    insert_doc_annotation(
      objects: { anno_type_id: $annoTypeId, doc_id: $docId, value: $value }
      on_conflict: {
        constraint: pk_doc_annotation_doc_id_anno_type_id
        update_columns: [value]
      }
    ) {
      affected_rows
    }
  }
`;

export const SCHEDULE_DOCUMENT = gql`
  mutation scheduleDocuments($documentIds: [ID!]!) {
    scheduleDocuments(documentIds: $documentIds)
  }
`;
