import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { DocumentTypeFlagDB, DocumentTypeFlag } from "./DocumentTypeFlag";
import { useTranslation } from "react-i18next";

export const ADIT_GET_DOCUMENT_TYPE_FLAGS = gql`
  query ADIT_GET_DOCUMENT_TYPE_FLAGS {
    document_flag(where: { id: { _neq: 0 } }, order_by: { id: asc }) {
      id
      name
      translation
      color
    }
  }
`;

const useGetDocumentTypeFlags = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const { data, ...rest } = useQuery<{
    document_flag: DocumentTypeFlagDB[];
  }>(ADIT_GET_DOCUMENT_TYPE_FLAGS);

  const documentTypeFlags = useMemo(
    () =>
      data?.document_flag.map(
        (document_flag) => new DocumentTypeFlag(document_flag, { language })
      ) || [],
    [data, language]
  );

  return {
    documentTypeFlags,
    ...rest,
  };
};

export default useGetDocumentTypeFlags;
