import React, { FC } from "react";
import { useLayoutContext, Theme, useMediaQuery } from "@periplus/ui-library";
import { NavigationItemType } from "../Layout";
import { Typography } from "@periplus/ui-library";
import { useHistory, useRouteMatch } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

interface NavigationItemProps extends NavigationItemType {}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    "&:not(:first-of-type)": {
      marginTop: 8,
    },
    "&:hover p": {
      color: "#604DFF",
      fill: "#604DFF",
    },
  },
  text: {
    color: "#737A96",
    fontSize: 14,
  },
  active: {
    color: "#604DFF",
    fill: "#604DFF",
    fontWeight: 400,
  },
  badge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 18,
    height: 18,
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#604DFF",
    fontSize: 11,
    marginLeft: 8,
  },
}));

const NavigationItem: FC<NavigationItemProps> = ({
  text,
  redirecting,
  indicator,
}) => {
  const { classes, cx } = useStyles();
  const isUpDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );
  const { layoutActions } = useLayoutContext();
  const history = useHistory();

  const match = useRouteMatch({
    path: redirecting,
  });

  return (
    <div
      className={classes.root}
      onClick={(e: any) => {
        e.stopPropagation();
        if (redirecting.includes("http")) {
          window.open(redirecting, "_blank");
        } else if (!match) {
          history.push(redirecting);
        }

        if (!isUpDesktop) {
          layoutActions.setSidebarOpen(false);
        }
      }}
    >
      <Typography
        className={cx(classes.text, {
          [classes.active]: Boolean(match),
        })}
        variant={match ? "subtitle1" : "body1"}
      >
        {text}
      </Typography>
      {Boolean(indicator) && <div className={classes.badge}>{indicator}</div>}
    </div>
  );
};

export default NavigationItem;
