import React from "react";
import { useMutation } from "@apollo/client";
import { TOGGLE_AI_CONFIRMED } from "graphql/mutations/declaration";
import { Checkbox } from "@periplus/ui-library";

// Define the props type
type AIConfirmedCheckboxProps = {
  fileId: string;
  drNo: number;
  initialChecked: boolean;
};

export function AIConfirmedCheckbox({
  fileId,
  drNo,
  initialChecked,
}: AIConfirmedCheckboxProps) {
  const [checked, setChecked] = React.useState(initialChecked);

  const [updateAIConfirmed] = useMutation(TOGGLE_AI_CONFIRMED);

  const handleCheckboxChange = async () => {
    const newValue = !checked;
    setChecked(newValue);

    try {
      await updateAIConfirmed({
        variables: {
          file_id: fileId,
          dr_no: drNo,
          value: newValue,
        },
      });
    } catch (error) {
      console.error("Failed to update ai_confirmed:", error);
      // If the update fails, revert the checkbox state
      setChecked(!newValue);
    }
  };

  return <Checkbox checked={checked} onChange={handleCheckboxChange} />;
}
