import React, { forwardRef, memo, useCallback, CSSProperties } from "react";
import { FixedSizeList, FixedSizeListProps, areEqual } from "react-window";
import get from "lodash/get";

export interface IVirtualizationListProps extends FixedSizeListProps {
  /**
   * top/bottom for vertical and left/right for horizontal spacing between items
   */
  itemsSpacing: number;
  /**
   * top/bottom for vertical and left/right for horizontal spacing of item
   */
  itemWidthSpacing: number;
  /**
   * top/bottom for vertical and left/right for horizontal spacing of items container
   */
  containerSpacing: number;
}

const asCssValue = (value: string | number): string => {
  return typeof value === "number" ? `${value}px` : value;
};

const renderChild = memo(
  ({ data, index, style }: { data: any; index: number; style: any }) => {
    const {
      itemData,
      children,
      spacing: { top, left, width, height },
    } = data;

    const styles = {
      ...style,
      left: style.left + left,
      top: style.top + top,
      width: `calc(${asCssValue(style.width)} - ${asCssValue(width)})`,
      height: `calc(${asCssValue(style.height)} - ${asCssValue(height)})`,
    };

    return React.createElement(children, {
      data: itemData,
      index,
      style: styles,
    });
  },
  areEqual
);

const VirtualizationList = forwardRef(
  (
    {
      itemCount,
      height,
      width,
      onItemsRendered,
      children,
      itemSize,
      itemData,
      itemsSpacing,
      itemWidthSpacing,
      containerSpacing,
      ...rest
    }: IVirtualizationListProps,
    ref: any
  ) => {
    const [top, left, widthSpacing, heightSpacing] =
      rest.layout === "horizontal"
        ? [
            itemWidthSpacing / 2,
            containerSpacing || itemWidthSpacing / 2,
            itemsSpacing,
            itemWidthSpacing,
          ]
        : [
            containerSpacing || itemWidthSpacing / 2,
            itemWidthSpacing / 2,
            itemWidthSpacing,
            itemsSpacing,
          ];

    const getContainerPadding = useCallback(
      (style) => {
        const padding =
          rest.layout === "horizontal"
            ? {
                width: `${parseFloat(style.width) + containerSpacing}px`,
              }
            : {
                height: `${parseFloat(style.height) + containerSpacing}px`,
              };

        return {
          ...style,
          ...padding,
        };
      },
      [containerSpacing, rest.layout]
    );

    return (
      <FixedSizeList
        innerElementType={innerElementType}
        itemCount={itemCount}
        itemData={{
          itemData,
          children,
          spacing: { top, left, width: widthSpacing, height: heightSpacing },
          getPadding: getContainerPadding,
        }}
        itemSize={itemSize}
        height={height}
        width={width}
        onItemsRendered={onItemsRendered}
        ref={ref}
        {...rest}
      >
        {renderChild}
      </FixedSizeList>
    );
  }
);

const innerElementType = forwardRef(
  ({ style, children }: { style: any; children: any }, ref: any) => {
    const [child] = children;
    const defaultGetPadding = (styles: CSSProperties) => styles;
    const getPadding = get(child, "props.data.getPadding", defaultGetPadding);

    return <div ref={ref} style={getPadding(style)} children={children} />;
  }
);

export default VirtualizationList;
