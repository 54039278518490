import React, { useCallback, useMemo } from "react";
import { FormAutocomplete, FormAutocompleteProps } from "@periplus/ui-library";
import { LanguageType } from "i18n";
import { useTranslation } from "react-i18next";
import { EdecDomain } from "../useGetEdecMasterData";

interface Props<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> extends Omit<
    FormAutocompleteProps<string, Multiple, DisableClearable, FreeSolo>,
    "options"
  > {
  options: EdecDomain[];
}

export default function <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({ options, ...rest }: Props<Multiple, DisableClearable, FreeSolo>) {
  const { i18n } = useTranslation();
  const language = i18n.language as LanguageType;

  const transformedOptions = useMemo(
    () => options.map((el) => el.value!),
    [options]
  );

  const getOptionLabel = useCallback(
    (option: string | number) =>
      options.find((el) => el.value === option)?.[`meaning_${language}`]!,
    [language, options]
  );

  return (
    <FormAutocomplete
      {...rest}
      options={transformedOptions as any}
      getOptionLabel={getOptionLabel}
    />
  );
}
