import React, { useMemo, useState } from "react";
import {
  Loader,
  TablePagination,
  PageContainer,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
} from "@periplus/ui-library";
import useGetTenants from "graphql/hooks/useGetTenants";
import TenantCard from "./TenantCard";
import ActionBar from "./ActionBar";
import useUrlSearchParams from "hooks/useUrlSearchParams";
import { useTranslation } from "react-i18next";
import CreateDialog from "./CreateDialog";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const TenantsList = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams<any>({
    page: "1",
    itemsPerPage:
      localStorage.getItem("tenantsItemsPerPage") ||
      TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
  });
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const { page, itemsPerPage, search } = useMemo(
    () => ({
      page: Number(urlSearchParams.page),
      itemsPerPage: Number(urlSearchParams.itemsPerPage),
      search: urlSearchParams.search,
    }),
    [urlSearchParams]
  );

  const getTenantsVariables = useMemo(
    () => ({
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
      search,
    }),
    [page, itemsPerPage, search]
  );

  const { tenants, tenantsTotalCount, loading, refetch } =
    useGetTenants(getTenantsVariables);

  const handleSearchChange = (value: string) =>
    setUrlSearchParams({ search: value, page: 1 });

  const handlePageChange = (value: number) => {
    setUrlSearchParams({ page: value });
  };
  const handleChangeItemsPerPage = (value: number) => {
    setUrlSearchParams({ page: 1, itemsPerPage: value });
    localStorage.setItem("tenantsItemsPerPage", String(value));
  };

  const handleCreateClick = () => {
    setIsCreateDialogOpen(true);
  };

  const handleCreateDialogClose = () => {
    setIsCreateDialogOpen(false);
  };

  const handleCreateDialogConfirm = async () => {
    await refetch();
    setIsCreateDialogOpen(false);
  };

  return (
    <PageContainer title={t("navigation:tenants")}>
      {!tenants.length && loading ? (
        <Loader />
      ) : (
        <>
          <ActionBar
            onSearchChange={handleSearchChange}
            onCreateClick={handleCreateClick}
          />
          {!tenants.length ? (
            <div className={classes.noData}>{t("noData")}</div>
          ) : (
            <>
              {tenants.map((tenant, index) => (
                <TenantCard key={tenant.id} tenant={tenant} index={index} />
              ))}
              {tenantsTotalCount > 6 && (
                <div className={classes.paginationContainer}>
                  <TablePagination
                    page={+urlSearchParams.page}
                    totalItems={tenantsTotalCount}
                    itemsPerPage={+urlSearchParams.itemsPerPage}
                    onChangePage={handlePageChange}
                    onChangeItemsPerPage={handleChangeItemsPerPage}
                  />
                </div>
              )}
            </>
          )}
          {isCreateDialogOpen && (
            <CreateDialog
              onClose={handleCreateDialogClose}
              onConfirm={handleCreateDialogConfirm}
            />
          )}
        </>
      )}
    </PageContainer>
  );
};

export default TenantsList;
