import { useCallback } from "react";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import useErrorHandling from "hooks/useErrorHandling";
import { useAuth } from "contexts/AuthContext";

export const SEND_MESSAGE = gql`
  mutation ADIT_CREATE_DECLARATION_MESSAGE(
    $discussionId: Int!
    $message: String!
    $user: ID!
    $source: String!
  ) {
    createDeclarationMessage(
      discussionId: $discussionId
      message: $message
      user: $user
      source: $source
      messageType: "message"
    ) {
      affected_rows
    }
  }
`;

const useSendMessage = () => {
  const { user } = useAuth();
  const withErrorHandling = useErrorHandling();
  const [send] = useMutation(SEND_MESSAGE);

  const sendMessage = useCallback(
    async ({
      discussionId,
      message,
    }: {
      discussionId: number;
      message: string;
    }) => {
      return await withErrorHandling(send, {
        variables: {
          discussionId,
          message,
          user: user?.userId,
          source: "adit",
        },
      });
    },
    [send, user, withErrorHandling]
  );

  return sendMessage;
};

export default useSendMessage;
