import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useQuery, QueryHookOptions } from "@apollo/client";
import { ApolloError, ApolloQueryResult } from "@apollo/client";

import { mapPages } from "../helpers";

export type AutoClassificationPage = {
  doc_type: string;
  page_id: string;
  page_number: number;
  // confidence_levels: {
  //   [key: string]: any;
  // };
};

export interface Pages {
  forEach(arg0: (page: AutoClassificationPage) => void): any;
}

export type Page = {
  id: string;
  page_id: string;
  thumbnailBigUrl: string;
  page_status: string;
  rotate: number;
  creation_user: string;
  page_number: number;
  meta: {
    [key: string]: any;
  };
};

export type Blob = {
  url: string;
  initial?: boolean;
  extension: string;
};

export type DocumentSchema = {
  lineItems: string[];
  annotations: { name: string; group: string }[];
};

export type DeclarationDocumentSchema = DocumentSchema & {
  boxingItems: string[];
};

export type BaseDocument = {
  id: string;
  name: string;
  document_extension: string;
  meta: {
    doc_classification?: {
      sdoc_id: number;
      doc_type: string;
      page_numbers: number[];
    }[];
    uploadTime: number;
    autoClassification: boolean;
    rossum_export_url?: string;
    rossum_validated?: boolean;
    language?: string;
    layout?: string;
    validationMeasureTime?: {
      [key: string]: number;
    };
  };
  document_blobs?: {
    blobs: Blob[];
  };
  document_flags: number;
};

export interface Document extends BaseDocument {
  pages: Page[];
}

export interface ServerDocument extends BaseDocument {
  pages: {
    page_number: number;
    page: Page;
  }[];
}

interface ReturnType {
  data: Document[];
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: {
    ids: string[];
  }) => Promise<ApolloQueryResult<Document[]>>;
}

export const GET_DOCUMENTS = gql`
  query getDocuments($ids: _uuid) {
    documents: pp_document(args: { _ids: $ids }, limit: 1024) {
      id
      name: document_name
      meta
      pages: pages_m2m(order_by: { page_number: asc }) {
        page {
          id
          thumbnailBigUrl: thumbnail_big_url
          page_status
          rotate
          meta
          creation_user
        }
        page_number
      }
      document_blobs
      document_flags
    }
  }
`;

const useGetDocuments = (
  ids: string[],
  options: QueryHookOptions = {}
): ReturnType => {
  const { data, loading, error, refetch } = useQuery(GET_DOCUMENTS, {
    variables: {
      ids: ids?.length ? `{${ids.join(",")}}` : undefined,
    },
    ...options,
    fetchPolicy: "network-only",
  });

  const returnValue = useMemo(() => {
    let documents: Document[] = [];

    if (data) {
      documents = data.documents;
    }

    const updatedDocuments: Document[] = documents.map((document) => ({
      ...document,
      pages: mapPages(document.pages),
    }));

    return {
      data: updatedDocuments,
      loading,
      error,
      refetch,
    };
  }, [data, error, loading, refetch]);

  return returnValue;
};

export default useGetDocuments;
