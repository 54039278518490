import React from "react";
import {
  CircularProgress,
  CircularProgressProps,
  Theme,
} from "@periplus/ui-library";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    progress: {
      margin: theme.spacing(2),
    }
  }));

interface IProgressProps {
  color?: CircularProgressProps["color"];
  [key: string]: any;
}

const Progress: React.FC<IProgressProps> = ({ color, ...other }) => {
  const { classes } = useStyles();
  return (
    <CircularProgress className={classes.progress} color={color} {...other} />
  );
};

export default Progress;
