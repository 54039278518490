import { gql } from "@apollo/client";
import {
  AditGetDeclarationQuery,
  useAditGetDeclarationQuery,
} from "graphql/generated";

export type DeclarationListEntity =
  AditGetDeclarationQuery["declarations"][number];

gql`
  query AditGetDeclaration($file_id: uuid!, $dr_no: smallint!) {
    declarations: declaration(
      where: { file_id: { _eq: $file_id }, dr_no: { _eq: $dr_no } }
    ) {
      file_id
      dr_no
      file {
        identification
      }
      ai_results
      ai_confirmed
      declaration_request {
        cev_status
      }
    }
    declarationsForm: declaration(
      where: { file_id: { _eq: $file_id }, dr_no: { _eq: $dr_no } }
    ) {
      file_id
      dr_no
      send_status
      declarant_number
      trader_identification_number
      send_date
      receive_status
      acceptance_date
      customs_declaration_number
      trader_reference
      duty_total_amount
      vat_total_amount
      customs_declaration_version
      material_check
      release
      gross_mass
      net_mass
      transport_mode
      transport_type
      transport_country
      transport_number
      service_type
      invoice_currency_type
      clearance_location
      place_of_unloading
      declaration_time
      declaration_type
      reason
      lang
      customs_office_number
      dispatch_country
      dispatch_country_confirmation
      correction_code
      correction_reason
      addresses {
        address_type
        address_id
        refs
        address {
          id
          company_name
          street
          street_number
          country
          zipcode
          city
          reference
          customs_account_number
          vat_account_number
        }
      }
      transport_in_container
      incoterms
      customs_account_address_type
      customs_account_number
      vat_account_address_type
      vat_account_number
      vat_number
      previous_documents {
        id
        previous_document_type
        previous_document_reference
        additional_information
      }
      containers {
        id
        container_number
      }
      declaration_special_mentions(order_by: { sequence_number: asc }) {
        id
        sequence_number
        text
      }
      goods_items {
        customs_item_number
        selection_result
        commodity_code
        commodity_code_confirmation
        statistical_code
        duty_rate
        rate_confirmation
        description
        net_duty
        net_mass
        net_mass_confirmation
        tare_supplement
        tare_supplement_confirmation
        gross_mass
        gross_mass_confirmation
        customs_net_weight
        additional_unit
        additional_unit_confirmation
        commercial_good
        clearance_type
        customs_favour_code
        origin_country
        origin_preference
        preference_confirmation
        vat_code
        vat_code_confirmation
        statistical_value
        statistical_value_confirmation
        vat_value
        vat_value_confirmation
        repair
        packagings {
          id
          packaging_type
          packaging_quantity
          packaging_reference_number
        }
        produced_documents {
          id
          produced_document_type
          produced_document_reference_number
          produced_document_issue_date
          additional_information
        }
      }
    }
  }
`;

export default ({ dr_no, file_id }: { dr_no: number; file_id: string }) => {
  const result = useAditGetDeclarationQuery({
    variables: {
      dr_no,
      file_id,
    },
    fetchPolicy: "no-cache",
  });

  return {
    ...result,
    data: {
      ...result.data,
      declaration: result.data?.declarations[0],
      declarationForm: result.data?.declarationsForm[0],
    },
  };
};
