import React, { useCallback } from "react";
import { FormAutocomplete } from "@periplus/ui-library";
import FormField, { FormFieldProps } from "../components/FormField/FormField";
import { AddressType } from "domain/address/types";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { DeclarationForm } from "../Declaration";

interface Props extends Omit<FormFieldProps, "name" | "renderInput"> {
  type: "customs" | "vat";
}

const ADDRESS_TYPES_FOR_ACCOUNT_NUMBER = [
  AddressType.consignor,
  AddressType.consignee,
  AddressType.importer,
  AddressType.authorized_consignee,
  AddressType.carrier,
];

export default ({ type, label, ...rest }: Props) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<DeclarationForm>();

  const handleChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: AddressType | null
    ) => {
      const accountNumberFieldName = `${type}_account_number` as const;
      setFieldValue(
        accountNumberFieldName,
        value
          ? values.addresses[value]?.address?.[accountNumberFieldName]
          : null
      );
    },
    [type, setFieldValue]
  );

  return (
    <FormField
      name={`${type}_account_address_type`}
      {...rest}
      renderInput={({ inputRef, ...rest }) => {
        return (
          <FormAutocomplete
            {...rest}
            options={ADDRESS_TYPES_FOR_ACCOUNT_NUMBER}
            onChange={handleChange}
            getOptionLabel={(option) =>
              t(`addressTypes:${AddressType[option]}`)
            }
            disableClearable
            InputProps={{
              inputRef,
              label,
            }}
            sx={{
              gridColumn: "1",
            }}
          />
        );
      }}
    />
  );
};
