import React, {
  useRef,
  useCallback,
  useEffect,
  MouseEvent,
  useState,
} from "react";
import { Theme } from "@periplus/ui-library";
import { DataPointCoordinates } from "graphql/hooks/useGetValidationDocument";
import { makeStyles } from 'tss-react/mui';

interface IDrawableCanvasProps {
  width: number;
  height: number;
  onStartDrawing?: (rec: DataPointCoordinates) => void;
  onFinishDrawing?: (rec: DataPointCoordinates) => void;
  drawingGrid?: boolean;
  children: any;
}

const useStyles = makeStyles()((theme: Theme) =>
  ({
    absoluteContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },

    drawingCursor: {
      cursor: "crosshair",
    },

    dragging: {
      zIndex: theme.zIndex.modal,
    }
  }));

const DrawableCanvas = ({
  width,
  height,
  onStartDrawing,
  onFinishDrawing,
  drawingGrid,
  children,
}: IDrawableCanvasProps) => {
  const { classes, cx } = useStyles();
  const [isDragging, setIsDragging] = useState(false);
  const canvasRef = useRef(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
  const isDrag = useRef(false);
  const isDirty = useRef(false);
  const startX = useRef(0);
  const startY = useRef(0);
  const curX = useRef(0);
  const curY = useRef(0);

  const updateCanvas = useCallback(() => {
    if (isDrag.current) {
      requestAnimationFrame(updateCanvas);
    }
    if (!isDirty.current) {
      return;
    }

    const ctx = ctxRef.current as CanvasRenderingContext2D;
    ctx.clearRect(0, 0, width, height);
    if (isDrag.current) {
      const rect = {
        x: startX.current,
        y: startY.current,
        w: curX.current - startX.current,
        h: curY.current - startY.current,
      };
      ctx.strokeRect(rect.x, rect.y, rect.w, rect.h);
    }
    isDirty.current = false;
  }, [height, width]);

  const getOffset = useCallback(
    (
      clientX: number,
      clientY: number
    ):
      | {
          offsetX: number;
          offsetY: number;
        }
      | undefined => {
      if (!containerRef.current) return;
      const rect = containerRef.current.getBoundingClientRect();
      const offsetX = clientX - rect.left;
      const offsetY = clientY - rect.top;
      return { offsetX, offsetY };
    },
    []
  );

  const onMouseDown = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      isDrag.current = true;
      const offset = getOffset(e.nativeEvent.clientX, e.nativeEvent.clientY);
      if (!offset) return;
      const { offsetX, offsetY } = offset;
      curX.current = startX.current = offsetX;
      curY.current = startY.current = offsetY;
      onStartDrawing &&
        onStartDrawing([offsetX / width, offsetY / height, 0, 0]);
      setIsDragging(true);
      requestAnimationFrame(updateCanvas);
    },
    [height, onStartDrawing, updateCanvas, width]
  );

  const onMouseUp = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (!isDrag.current) return;
      const offset = getOffset(e.nativeEvent.clientX, e.nativeEvent.clientY);
      if (!offset) return;
      const { offsetX, offsetY } = offset;
      isDrag.current = false;
      isDirty.current = true;
      setIsDragging(false);
      onFinishDrawing &&
        onFinishDrawing([
          Math.min(startX.current, curX.current) / width,
          Math.min(startY.current, curY.current) / height,
          Math.abs(offsetX - startX.current) / width,
          Math.abs(offsetY - startY.current) / height,
        ]);
    },
    [height, onFinishDrawing, width]
  );

  const onMouseMove = useCallback((e: MouseEvent<HTMLElement>) => {
    if (!isDrag.current) return;
    if (!containerRef.current) return;
    const offset = getOffset(e.nativeEvent.clientX, e.nativeEvent.clientY);
    if (!offset) return;
    const { offsetX, offsetY } = offset;
    curX.current = offsetX;
    curY.current = offsetY;
    isDirty.current = true;
  }, []);

  useEffect(() => {
    ctxRef.current = (
      canvasRef.current as unknown as HTMLCanvasElement
    ).getContext("2d");
  }, []);

  return (
    <div
      ref={containerRef}
      className={cx(classes.absoluteContainer, {
        [classes.drawingCursor]: drawingGrid,
        [classes.dragging]: isDragging,
      })}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
    >
      <canvas width={width} height={height} ref={canvasRef} />
      {children}
    </div>
  );
};

export default DrawableCanvas;
