import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { EmailView } from "./types";
import { Sorted } from "graphql/helpers";

interface Variables {
  id?: string;
  page: number;
  itemsPerPage: number;
  sorted?: Sorted;
  search?: string;
}

const useGetEmail = ({ id, page, itemsPerPage, sorted, search }: Variables) => {
  const result = useQuery<{ emails: EmailView[] }>(
    gql`
      query ADIT_GET_EMAIL(
        $id: uuid!
        $offset: Int
        $limit: Int
        $orderBy: String
        $search: String
      ) {
        emails: pp_email_search_hs(
          args: {
            _offset: $offset
            _limit: $limit
            _search: $search
            _orderby: $orderBy
          }
          where: { id: { _eq: $id } }
        ) {
          id
          name
          meta
          file_status
          creation_user
          creation_date
          file_url
          company
          sender
          total_rows
          documents(
            where: {
              _or: {
                is_deleted: { _eq: false }
                document_status: { _neq: "classified" }
              }
            }
          ) {
            id
            document_type
            document_status
            document_name
            document_extension
            document_flags
            creation_date
            sequence
            meta
            document_blobs
            created_by_user {
              display_name
            }
            computedPagesM2M(where: { page_status: { _neq: "classified" } }) {
              id: page
              thumbnail_small_url
              thumbnail_big_url
              page_status
              rotate
              meta
            }
          }
        }
      }
    `,
    {
      variables: {
        id,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        search,
        ...(sorted && {
          orderBy:
            Object.entries(sorted)
              .sort(([, a], [, b]) => a.pos - b.pos)
              .map(([id, sort]) => `${id} ${sort.dir} NULLS LAST`)
              .join(", ") + ", id ASC",
        }),
      },
      fetchPolicy: "no-cache",
      skip: !id,
    }
  );
  return result;
};

export default useGetEmail;
