import { Autocomplete } from "@periplus/ui-library";
import useAutocomplete, {
  UserOrgIdAutocompleteHookProps,
} from "./useUserOrgIdAutocomplete";
import React from "react";

interface Props<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends UserOrgIdAutocompleteHookProps<Multiple, DisableClearable> {}

export default function <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined
>({ ...rest }: Props<Multiple, DisableClearable>) {
  const props = useAutocomplete<Multiple, DisableClearable>(rest);

  return <Autocomplete {...props} />;
}
