import React, { FC } from "react";
import {
  FormTextField,
  Dialog,
  FormAutocomplete,
  Theme,
  Grid,
} from "@periplus/ui-library";
import { Form } from "formik";
import { useTranslation } from "react-i18next";
import ImportListTriggerFormik from "../ImportListTriggerFormik";
import {
  ImportListTrigger,
  NOTIFICATION_TRIGGER_CONFIG_TYPES,
  TriggerTypesEnum,
} from "../../../../graphql/hooks/useGetImportListTriggers";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  stepContentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    minWidth: "700px",
  },
}));

const CreateImportListTriggerDialog: FC<{
  onClose: () => void;
  onConfirm: () => void;
  importListTrigger?: ImportListTrigger | null;
}> = ({ onClose, onConfirm, importListTrigger }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <ImportListTriggerFormik
      onSubmit={onConfirm}
      importListTrigger={importListTrigger}
    >
      {({ submitForm, isSubmitting, isValid, dirty, values }: any) => (
        <Dialog
          maxWidth="xl"
          onClose={onClose}
          mainTitle={`${t("importListTrigger:createImportListTrigger")}`}
          onConfirm={submitForm}
          ConfirmButtonProps={{
            disabled: isSubmitting || !dirty || !isValid,
          }}
        >
          <Form className={classes.stepContentContainer}>
            <Grid item container>
              <Grid item xs={12}>
                <FormTextField
                  name="display_name"
                  variant="outlined"
                  fullWidth
                  label={t("importListTrigger:displayName")}
                  required
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormAutocomplete
                  name="type"
                  fullWidth
                  options={NOTIFICATION_TRIGGER_CONFIG_TYPES}
                  getOptionLabel={(option) => option.name}
                  InputProps={{
                    required: true,
                    variant: "outlined",
                    label: t("importListTrigger:trigger_type"),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={12}>
                <FormTextField
                  name="recipients"
                  variant="outlined"
                  fullWidth
                  label={t("importListTrigger:recipients")}
                  required
                />
              </Grid>
            </Grid>
            {values.type?.id === TriggerTypesEnum.SpecificHsCodes && (
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="specificHsCodes"
                    variant="outlined"
                    fullWidth
                    label={t("importListTrigger:hsCodes")}
                  />
                </Grid>
              </Grid>
            )}
            {values.type?.id === TriggerTypesEnum.HighCustomFeesTrigger && (
              <Grid item container>
                <Grid item xs={12}>
                  <FormTextField
                    name="countryOfOrigin"
                    variant="outlined"
                    fullWidth
                    label={t("importListTrigger:countryOfOrigin")}
                  />
                </Grid>
              </Grid>
            )}
            {values.type?.id === TriggerTypesEnum.HighCustomFeesTrigger && (
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="feeAmount"
                    variant="outlined"
                    fullWidth
                    label={t("importListTrigger:feeAmount")}
                  />
                </Grid>
              </Grid>
            )}
          </Form>
        </Dialog>
      )}
    </ImportListTriggerFormik>
  );
};

export default CreateImportListTriggerDialog;
