import React, {
  FC,
  useMemo,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from "react";
import {
  MuiTable,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Box,
  Grid,
  Tooltip,
  TablePagination,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
} from "@periplus/ui-library";
import {
  useTable,
  useRowSelect,
  usePagination,
  useExpanded,
  useFilters,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import useDidUpdateEffect from "hooks/useDidUpdateEffect";

import Text from "components/Text/Text";
import Progress from "../Progress";

export interface TableProps {
  rowData: any[];
  columnDefs: any[];
  loading?: boolean;
  onToggle?: (args: { selecting: any[]; deselecting: any[] }) => void;
  onExpand?: (rowData?: any) => void;
  itemCount: number;
  loadMore?: (offset: number, limit: number, search?: string) => void;
  sortBy?: (orderBy: { key: string; direction: "ASC" | "DESC" }[]) => void;
  initialItemsPerPage: number;
  onChangeItemsPerPage?: (items: number) => void;
  isRowSelected?: (data: any) => boolean;
  isRowPartiallySelected?: (data: any) => boolean;
  isRowExpanded?: (data: any) => boolean;
  selectedCount?: number;
  tableConfig?: {
    initialState?: any;
    [key: string]: any;
  };
  onDoubleClick?: (data: any) => void;
  selectionType?: "multiple" | "single";
  withSorting?: boolean;
  getSelectionCellProps?: (args: object) => any;
  renderExpandRowView?: (row: any) => ReactNode;
  disableMultiSort?: boolean;
  search?: string;
  onRowsPathToggle?: (args: {
    selectingPaths: string[];
    deselectingPaths: string[];
  }) => void;
  isRowPathSelected?: (path: string) => boolean;
  withoutSelectAll?: boolean;
  getRowProps?: (row: any) => {
    expandable?: boolean;
    disabled?: boolean;
  };
  initialPage?: number;
  onPageChange?: (value: number) => void;
  [key: string]: any;
}

const perPageGetData = 1;
let loadData = false;
const defaultRowProps = {
  expandable: true,
  disabled: false,
};

const Table: FC<TableProps> = ({
  rowData,
  columnDefs,
  loading,
  onToggle,
  onRowsPathToggle,
  onExpand,
  itemCount,
  loadMore,
  sortBy,
  isRowSelected,
  isRowPartiallySelected,
  isRowPathSelected,
  isRowExpanded,
  selectedCount = 0,
  tableConfig = {},
  initialItemsPerPage = TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
  onChangeItemsPerPage,
  onDoubleClick,
  selectionType = "multiple",
  withoutSelectAll = false,
  withSorting = false,
  withPagination = true,
  getSelectionCellProps,
  renderExpandRowView,
  disableMultiSort,
  search,
  getRowProps,
  initialPage = 1,
  onPageChange,
  ...rest
}) => {
  const [currentPage, setPage] = useState(initialPage - 1);

  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);
  const pageCount = useMemo(
    () => Math.ceil(itemCount / itemsPerPage),
    [itemCount, itemsPerPage]
  );

  const { initialState = {}, ...tableRest } = tableConfig;

  const expandColumn = useMemo(() => {
    if (!renderExpandRowView) return [];
    return [
      {
        id: "expander",
        Header: () => null,
        Cell: ({ row }: { row: any }) => {
          const rowProps = {
            ...defaultRowProps,
            ...getRowProps?.(row),
          };
          if (!rowProps.expandable) return null;

          const { onClick, style, ...rest } = row.getToggleRowExpandedProps();
          const rowExpanded = isRowExpanded
            ? isRowExpanded(row.original)
            : row.isExpanded;
          const handleExpand = (e: any) => {
            e.stopPropagation();

            onExpand && onExpand(row.original);
            onClick(e);
          };
          return (
            <span
              {...rest}
              onClick={!rowProps.disabled ? handleExpand : undefined}
              style={{
                ...style,
                ...(rowProps.disabled && {
                  cursor: "unset",
                  color: "rgba(0, 0, 0, 0.26)",
                }),
              }}
            >
              {rowExpanded ? <ExpandLess /> : <ExpandMore />}
            </span>
          );
        },
      },
    ];
  }, [isRowExpanded, onExpand, renderExpandRowView]);

  const toggleColumn = useMemo(() => {
    if (!onToggle) return [];
    return [
      {
        ...(selectionType === "multiple" && !withoutSelectAll
          ? {
              Header: ({
                getToggleAllRowsSelectedProps,
                toggleAllRowsSelected,
                data,
              }: any) => {
                const filteredData = getSelectionCellProps
                  ? data.filter((d: any) => !getSelectionCellProps(d).disabled)
                  : data;

                return (
                  <Box style={{ width: "42px" }} display="inline-block">
                    <Checkbox
                      {...getToggleAllRowsSelectedProps()}
                      indeterminate={
                        selectedCount > 0 && selectedCount < itemCount
                      }
                      checked={itemCount > 0 && selectedCount >= itemCount}
                      onChange={() => {
                        const isAllRowsSelected =
                          isRowSelected && filteredData.every(isRowSelected);
                        toggleAllRowsSelected();

                        const [selecting, deselecting] = isAllRowsSelected
                          ? [[], filteredData]
                          : [filteredData, []];
                        onToggle({
                          selecting,
                          deselecting,
                        });
                      }}
                    />
                  </Box>
                );
              },
            }
          : {
              Header: ({ getToggleAllRowsSelectedProps }: any) => (
                <Box
                  style={{ width: "42px" }}
                  display="inline-block"
                  visibility="hidden"
                >
                  <Checkbox {...getToggleAllRowsSelectedProps()} />
                </Box>
              ),
            }),
        id: "selection",
        Cell: ({ row }: { row: any }) => {
          const rowProps = {
            ...defaultRowProps,
            ...getRowProps?.(row),
          };
          const isSelected = isRowSelected && isRowSelected(row.original);
          const isPartiallySelected =
            isRowPartiallySelected && isRowPartiallySelected(row.original);
          const dummyFunction = () => ({});
          const getPropsFunction = getSelectionCellProps || dummyFunction;
          const { title, ...rest } = getPropsFunction(row.original);

          return (
            <Tooltip title={title || ""} disableHoverListener={!title}>
              <Box style={{ width: "42px" }} display="inline-block">
                <Checkbox
                  inputProps={{
                    title: "",
                  }}
                  {...row.getToggleRowSelectedProps()}
                  checked={isSelected}
                  indeterminate={isSelected ? false : isPartiallySelected}
                  disabled={rowProps.disabled}
                  {...rest}
                />
              </Box>
            </Tooltip>
          );
        },
      },
    ];
  }, [
    getSelectionCellProps,
    isRowPartiallySelected,
    isRowSelected,
    itemCount,
    onToggle,
    selectedCount,
    selectionType,
    withoutSelectAll,
  ]);

  const columns = useMemo(
    () => [...expandColumn, ...toggleColumn, ...columnDefs],
    [columnDefs, expandColumn, toggleColumn]
  );

  const {
    getTableProps,
    headerGroups,
    // @ts-ignore
    page,
    prepareRow,
    getTableBodyProps,
    // @ts-ignore
    toggleAllRowsSelected,
    // @ts-ignore
    selectedFlatRows,
    // @ts-ignore
    state: { sortBy: tableSortBy },
    // @ts-ignore
    visibleColumns,
    // @ts-ignore
    setPageSize,
  } = useTable(
    {
      columns,
      data: rowData,
      // @ts-ignore
      pageCount,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetExpanded: false,
      manualPagination: !!loadMore,
      autoResetSelectedRows: !!loadMore,
      manualSortBy: !!sortBy,
      disableMultiSort,
      disableSortRemove: true,
      initialState: {
        // @ts-ignore
        pageIndex: 0,
        pageSize: itemsPerPage,
        ...initialState,
      },
      ...tableRest,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    setItemsPerPage(initialItemsPerPage);
    setPageSize(initialItemsPerPage);
  }, [initialItemsPerPage, setPageSize]);

  const loadPages = useCallback(
    (nextPage: number) => {
      if (loadMore && nextPage % perPageGetData === 0) {
        loadMore(
          nextPage * itemsPerPage,
          itemsPerPage * perPageGetData,
          search
        );
        loadData = true;
      }
    },
    [loadMore, itemsPerPage, search]
  );

  const handlePageChange = (page: number) => {
    const adaptedPage = page - 1;
    onPageChange?.(adaptedPage);
    loadPages(adaptedPage);
    setPage(adaptedPage);
    onExpand && onExpand({});
  };

  const handleItemsPerPageChange = (items: number) => {
    if (items === itemsPerPage || items === rowData.length) {
      return;
    }

    if (onChangeItemsPerPage) {
      onChangeItemsPerPage(items);
    }

    if (loadMore) {
      loadMore(0 * items, items * perPageGetData, search);
    }
    setPage(0);
    setItemsPerPage(items);
    loadData = true;
  };

  useEffect(() => {
    const page = pageCount - 1;
    if (pageCount > 0 && currentPage > page) {
      loadPages(page);
      setPage(page);
    }
  }, [currentPage, loadPages, pageCount]);

  useEffect(() => {
    loadData = false;
    if (!loadData && rowData.length === 0 && !loading && currentPage > 1) {
      loadData = true;
      setPage((page) => {
        const prevPage = page - 1;
        loadMore && loadMore(prevPage, itemsPerPage, search);
        loadPages(prevPage);
        return prevPage;
      });
    }
    // eslint-disable-next-line
  }, [rowData, loading, setPage]);

  useDidUpdateEffect(() => {
    if (loading && !loadData) {
      setPage(initialPage - 1);
      toggleAllRowsSelected(false);
    }
    loadData = false;
    // eslint-disable-next-line
  }, [loading]);

  useDidUpdateEffect(() => {
    if (loadMore) loadMore(0, itemsPerPage, search);
    setPage(0);
    // eslint-disable-next-line
  }, [loadMore, search]);

  useEffect(() => {
    if (sortBy && tableSortBy) {
      setPage(0);
      sortBy(
        tableSortBy.map((el: any) => ({
          key: el.id,
          direction: el.desc ? "DESC" : "ASC",
        }))
      );
    }
  }, [sortBy, tableSortBy]);

  const isTableSortable = withSorting || !!sortBy;

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
      <div
        style={{
          backgroundColor: "white",
          overflowX: "auto",
          overflowY: "hidden",
          minHeight: "110px",
          position: "relative",
          border: "1px solid rgb(224, 224, 224)",
          padding: "8px",
          paddingBottom: 0,
          borderRadius: "4px",
        }}
      >
        <MuiTable
          {...getTableProps()}
          size="small"
          style={{ marginBottom: "-1px", minHeight: "110px" }}
          {...rest}
        >
          <TableHead>
            {headerGroups.map((headerGroup: any) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, index: number) => (
                  <TableCell
                    {...column.getHeaderProps([
                      isTableSortable ? column.getSortByToggleProps() : {},
                      {
                        style: {
                          ...(index <
                          [onToggle, onExpand].filter(Boolean).length
                            ? {
                                width: "42px",
                                padding: "6px",
                                height: "54.5px",
                              }
                            : {
                                minWidth: column.minWidth,
                                maxWidth: column.maxWidth,
                              }),
                          ...(isTableSortable && column.canSort
                            ? { cursor: "pointer" }
                            : {}),
                        },
                      },
                      {
                        style: column.style,
                      },
                    ])}
                  >
                    <Grid
                      container
                      item
                      direction="row"
                      alignItems="center"
                      style={column.headerStyles}
                    >
                      {column.render("Header")}
                      <span style={{ height: "21px" }}>
                        {isTableSortable && column.isSorted ? (
                          column.isSortedDesc ? (
                            <ExpandMore />
                          ) : (
                            <ExpandLess />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rowData.length === 0 && !(loading || loadData) ? (
              <TableRow>
                <TableCell
                  colSpan={
                    columnDefs.length +
                    +!!(onToggle || onRowsPathToggle) +
                    +!!renderExpandRowView
                  }
                  style={{
                    textAlign: "center",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                >
                  <Text>common:noData</Text>
                </TableCell>
              </TableRow>
            ) : (
              page.map((row: any) => {
                const rowProps = {
                  ...defaultRowProps,
                  ...getRowProps?.(row),
                };
                prepareRow(row);
                const isDisabled =
                  getSelectionCellProps &&
                  getSelectionCellProps(row.original).disabled;
                const { key, style, ...others } = row.getRowProps(
                  isDisabled
                    ? {}
                    : {
                        onDoubleClick: () =>
                          !rowProps.disabled &&
                          onDoubleClick &&
                          onDoubleClick(row.original),
                        onClick: () => {
                          const isCurrentRowSelected =
                            isRowSelected && isRowSelected(row.original);

                          const isPathSelected =
                            isRowPathSelected && isRowPathSelected(row.id);

                          const isSelected =
                            isCurrentRowSelected || isPathSelected;

                          row.toggleRowSelected(!isSelected);

                          if (!(onToggle || onRowsPathToggle)) {
                            return;
                          }

                          const selectedRow = selectedFlatRows[0];

                          const rowPath = [row.id];
                          const [selectingPaths, deselectingPaths]: [
                            string[],
                            string[]
                          ] = isSelected ? [[], rowPath] : [rowPath, []];

                          const rowData = [row.original];
                          const [selecting, deselecting]: [any[], any[]] =
                            isSelected ? [[], rowData] : [rowData, []];

                          if (selectionType === "single" && selectedRow) {
                            selectedRow.toggleRowSelected(false);
                            deselecting.push(selectedRow.original);
                          }

                          !rowProps.disabled &&
                            onRowsPathToggle &&
                            onRowsPathToggle({
                              selectingPaths,
                              deselectingPaths,
                            });

                          !rowProps.disabled &&
                            onToggle &&
                            onToggle({
                              selecting,
                              deselecting,
                            });
                        },
                      }
                );
                return (
                  <React.Fragment key={key}>
                    <TableRow
                      style={{
                        ...style,
                        ...(rowProps.disabled && {
                          backgroundColor: "#F3F4FB",
                        }),
                      }}
                      {...others}
                    >
                      {row.cells.map((cell: any, index: number) => {
                        return (
                          <TableCell
                            {...cell.getCellProps()}
                            style={
                              index <
                              [onToggle, onExpand].filter(Boolean).length
                                ? {
                                    width: "42px",
                                    padding: "6px",
                                    height: "61px",
                                  }
                                : {
                                    minWidth: cell.column.minWidth,
                                    maxWidth: cell.column.maxWidth,
                                  }
                            }
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    {renderExpandRowView &&
                      (isRowExpanded
                        ? isRowExpanded(row.original)
                        : row.isExpanded) && (
                        <TableRow>
                          <TableCell />
                          <TableCell
                            colSpan={visibleColumns.length}
                            padding="none"
                          >
                            {renderExpandRowView(row)}
                          </TableCell>
                        </TableRow>
                      )}
                  </React.Fragment>
                );
              })
            )}
          </TableBody>
        </MuiTable>
        {(loading || loadData) && (
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            style={{
              backgroundColor: "rgba(0, 0, 0, .1)",
            }}
          >
            <Progress
              style={{
                position: "relative",
                left: "calc(50% - 20px)",
                top: "calc(50% - 5px)",
              }}
            />
          </Box>
        )}
      </div>
      {itemCount > TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0] &&
        withPagination && (
          <Box display="flex" justifyContent="flex-end">
            <TablePagination
              page={currentPage + 1}
              totalItems={itemCount}
              itemsPerPage={itemsPerPage}
              onChangePage={handlePageChange}
              onChangeItemsPerPage={handleItemsPerPageChange}
            />
          </Box>
        )}
    </div>
  );
};

export default React.memo(Table);
