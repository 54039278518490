import React, { FC, memo } from "react";
import {} from "@periplus/ui-library";
import useSasToken from "hooks/useSasToken";

interface PageImgProps {
  thumbnailBigUrl: any;
  pageRotation: any;
  classifiedPage: any;
  isPageDisabled: any;
  width: any;
}

const PageImg: FC<PageImgProps> = ({
  thumbnailBigUrl,
  pageRotation,
  classifiedPage,
  isPageDisabled,
  width,
}) => {
  const thumbnailsSasToken = useSasToken({ container: "thumbs" });

  return (
    <img
      alt="document_page"
      data-pp="document_page"
      src={thumbnailsSasToken && `${thumbnailBigUrl}?${thumbnailsSasToken}`}
      style={{
        transform: `rotate(${pageRotation}deg)`,
        opacity: !classifiedPage && isPageDisabled ? 0.5 : 1,
        userSelect: "none",
        ...width,
      }}
    />
  );
};

export default memo(PageImg);
