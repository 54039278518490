import React, { FC, CSSProperties, ReactNode } from "react";
import { Typography, Box } from "@periplus/ui-library";
import { makeStyles } from "tss-react/mui";

interface FormGroupProps extends React.PropsWithChildren {
  title: ReactNode;
  direction?: "row" | "column";
  classes?: {
    root?: string;
    title?: string;
    content?: string;
  };
  className?: string;
  style?: CSSProperties;
}

interface FormGroupStyleProps {
  direction: "row" | "column";
}

const useStyles = makeStyles<FormGroupStyleProps>()((theme, { direction }) => ({
  root: {
    "&:not(:first-of-type)": {
      marginTop: 25,
    },
  },
  content: {
    row: {
      marginLeft: 25,
    },
    column: {
      marginTop: 10,
    },
  }[direction],
}));

const FormGroup: FC<FormGroupProps> = ({
  title,
  children,
  direction = "row",
  classes,
  className,
  ...rest
}) => {
  const { classes: innerClasses, cx } = useStyles({ direction });
  return (
    <Box
      className={cx(className, innerClasses.root, classes?.root)}
      display="flex"
      flexDirection={direction}
      alignItems="baseline"
      {...rest}
    >
      {typeof title !== "string" ? (
        title
      ) : (
        <Typography className={classes?.title}>{title}</Typography>
      )}
      <Box className={cx(innerClasses.content, classes?.content)}>
        {children}
      </Box>
    </Box>
  );
};

export default FormGroup;
