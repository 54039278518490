import React from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import WeDeclare, { WE_DECLARE_TABS } from "./WeDeclare";

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={path} to={`${path}/${WE_DECLARE_TABS[0].id}`} />
      <WeDeclare />
    </Switch>
  );
};
