export const ADDRESS_TYPES = [
  {
    id: 1,
    name: "Consignor",
  },
  {
    id: 2,
    name: "Consignee",
  },
  {
    id: 3,
    name: "Supplier",
  },
  {
    id: 4,
    name: "Importer",
  },
  {
    id: 5,
    name: "Freight forwarder",
  },
];

export enum AddressType {
  consignor = 1,
  consignee = 2,
  supplier = 3,
  importer = 4,
  freight_forwarder = 5,
  carrier = 6,
  consignee_transit = 7,
  consignor_transit = 8,
  payer = 9,
  authorized_consignee = 10,
}

export type PartialAddress = {
  address_type: number;
  city: string;
  contact_email: string;
  contact_phone: string;
  contact_website: string;
  country: string;
  street: string;
  street_number: string;
  zipcode: string;
  company_name: string;
  reference: string;
  is_deleted?: boolean;
};

export type Address = PartialAddress & {
  id: number;
  creation_date: string;
  creation_user: string;
  last_modified_date: string;
  last_modified_user: string;
  qty: number;
  country: string;
  city: string;
  street: string;
  street_number: string;
  zipcode: string;
};
