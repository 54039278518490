import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import config from "config";

import { VALIDATE_JOURNEY_NUMBER } from "graphql/mutations/journey";
import { GET_FILE_BY_IDENTIFICATION } from "graphql/queries/files";

export type IValidatedJourneyData = {
  validateJourneyNumber: JourneyValidation;
};

export type JourneyValidation = {
  error_code: string;
  error_description: string;
  data: JourneyData;
};

export type JourneyData = {
  CarrierName: string;
  DirectionName: string;
  JourneyDestination: string;
  JourneyDesignation: string;
  JourneyStatus: string;
  JourneyNumber: string;
  Orgid: number;
};

export type IFileStatus = {
  file: File[];
};

export type File = {
  id: string;
  identification: string;
  file_status: string;
};

const { prefixMRSNumber } = config;

const useValidateJourney = (fileNumber: string) => {
  let isValidLength = fileNumber.length === 7;
  let fileEditable = true;
  let errorCode: number = 0;

  const [requestDataResult, setRequestDataResult] = useState<
    Partial<IValidatedJourneyData>
  >({});

  const { data, loading, error, refetch } = useQuery<IFileStatus>(
    GET_FILE_BY_IDENTIFICATION,
    {
      fetchPolicy: "network-only",
      variables: {
        fileNumber: `${prefixMRSNumber}-${fileNumber}`,
      },
    }
  );

  useEffect(() => {
    setRequestDataResult({});

    if (data && data.file.length && isValidLength) {
      refetch();
    }
  }, [data, isValidLength, refetch]);

  const [
    validateJourneyNumber,
    { data: requestData, loading: requestLoading, error: requestError },
  ] = useMutation<IValidatedJourneyData>(VALIDATE_JOURNEY_NUMBER);

  useEffect(() => {
    if (fileEditable && !loading) {
      validateJourneyNumber({
        variables: {
          number: `${prefixMRSNumber}-${fileNumber}`,
        },
      });
    }
  }, [fileEditable, loading, validateJourneyNumber, fileNumber]);

  useEffect(() => {
    if (requestData) {
      setRequestDataResult(requestData);
    }
  }, [requestData]);

  if (fileNumber.length < 7) {
    errorCode = 0;
    fileEditable = false;

    if (Object.keys(requestDataResult).length) {
      setRequestDataResult({});
    }
  }

  if (
    requestDataResult &&
    requestData &&
    requestData.validateJourneyNumber.error_code &&
    isValidLength
  ) {
    errorCode = parseInt(requestData.validateJourneyNumber.error_code);
  }

  const returnValue = {
    data: requestDataResult,
    loading: requestLoading,
    loadingStatus: loading,
    error: error || requestError || errorCode,
  };

  return returnValue;
};

export default useValidateJourney;
