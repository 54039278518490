import React, { FC } from "react";
import { Paper, Typography, Theme } from "@periplus/ui-library";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  card: {
    filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.14))",
  },
  cardContent: {
    minWidth: 140,
    minHeight: 88,
    boxSizing: "border-box",
    padding: theme.spacing(2),
  },
}));

interface StatisticCardProps {
  title: string;
  value: string | number;
  [key: string]: any;
}

const formatValue = (value?: string | number) => {
  if (!value) {
    return value;
  }

  const valueAsString = value.toString();
  const splitValueInGroupByThree = valueAsString.split(/(?=(?:...)*$)/);
  return splitValueInGroupByThree.join("'");
};

const StatisticCard: FC<StatisticCardProps> = ({
  title,
  value,
  className,
  ...other
}) => {
  const { classes, cx } = useStyles();

  return (
    <Paper elevation={0} className={cx(classes.card, className)} {...other}>
      <div className={classes.cardContent}>
        <Typography
          style={{
            color: "white",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h4"
          style={{
            color: "white",
          }}
        >
          {formatValue(value)}
        </Typography>
      </div>
    </Paper>
  );
};

export default StatisticCard;
