import React, { FC, HtmlHTMLAttributes, useCallback, useState } from "react";
import { AlertDialog } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import useDeleteOrgId from "graphql/hooks/useDeleteOrgId";
import { OrgId } from "graphql/hooks/useGetOrgIds";

interface OrgIdDeleteDialogProps extends HtmlHTMLAttributes<HTMLElement> {
  orgId: OrgId;
  onConfirm: () => void;
  onClose: () => void;
}

const OrgIdDeleteDialog: FC<OrgIdDeleteDialogProps> = ({
  orgId,
  onConfirm,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const deleteOrgId = useDeleteOrgId();

  const handleConfirm = useCallback(async () => {
    setLoading(true);
    await deleteOrgId({ org_id: orgId.org_id });
    setLoading(false);
    onConfirm();
  }, []);

  return (
    <AlertDialog
      variant="warning"
      onConfirm={handleConfirm}
      onClose={onClose}
      ConfirmButtonProps={{
        loading,
      }}
    >
      {t("deleteDialogMessage", { entity: "OrgId", name: orgId.display_name })}
    </AlertDialog>
  );
};

export default OrgIdDeleteDialog;
