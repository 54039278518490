import React, { HtmlHTMLAttributes } from "react";
import PDFPreview from "components/PDFPreview/PDFPreview";
import useGetDocument from "domain/document/useGetDocument";
import DocumentStatus from "domain/document/components/DocumentStatus";
import { useAuth } from "contexts/AuthContext";

interface Props extends HtmlHTMLAttributes<HTMLElement> {
  id?: string;
}

export default function ({ id }: Props) {
  const { tenantConfig } = useAuth();

  const { data: { document } = {}, loading } = useGetDocument({ id });

  return (
    <PDFPreview
      file={
        document && {
          name: document.document_name,
          creationDate: document.creation_date,
          pages: document.pages_m2m.map((selectedPage) => ({
            ...selectedPage.page,
            thumbnailBigUrl: selectedPage.page.thumbnail_big_url,
            thumbnailSmallUrl: selectedPage.page.thumbnail_small_url,
          })),
          extension: document.document_extension,
          document_blobs: document.document_blobs,
          status: (
            <DocumentStatus
              status={document?.document_status}
              defaultFlags={
                tenantConfig?.UseAutoclassification
                  ? document?.document_flags
                  : 0
              }
            />
          ),
        }
      }
      loading={loading}
    />
  );
}
