import React, { useState, useCallback, useContext } from "react";
import {
  InputBase,
  CircularProgress,
  Paper,
  LinearProgress,
  Grid,
  Box,
  Divider,
  Typography,
  AlertDialog,
} from "@periplus/ui-library";
import Add from "@mui/icons-material/Add";
import Text from "components/Text/Text";
import config from "../../config";
import { useTranslation } from "react-i18next";
import { FileRejection, useDropzone } from "react-dropzone";
import { useMutation } from "@apollo/client";
import { get } from "lodash";
import { useSnackbar } from "notistack";
import { CREATE_DOCUMENT } from "graphql/mutations/document";
import { GET_DOCUMENTS_BY_CHECKSUM } from "graphql/queries/document";
import { CLEAR_DOCUMENT_CHECKSUM } from "graphql/mutations/document";
import { AuthContext } from "contexts/AuthContext";
import uuid from "uuid";
import useErrorHandling from "hooks/useErrorHandling";
import {
  BlockBlobClient,
  AnonymousCredential,
  newPipeline,
} from "@azure/storage-blob";
import { SasStore } from "utils/blockBlobClient";
import WaitText from "components/WaitText";
import SparkMD5 from "spark-md5";
import client from "graphql/client";
import { useApplicationAction } from "contexts/ApplicationContext";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  paper: {
    outline: "none",
    display: "flex",
    flexDirection: "column",
    width: "auto !important",
    alignItems: "center",
  },
  hiddenTextPaper: {
    padding: "12px 7.5px",
    width: "fit-content",
  },
  shownTextUploadingPaper: {
    justifyContent: "flex-start",
    overflow: "auto",
    height: "100%",
  },
  text: {
    marginTop: theme.spacing(1),
  },
  iconButton: {
    padding: theme.spacing(2),
    borderRadius: "50%",
    textAlign: "center",
    boxShadow: "0px 8px 20px rgba(11, 99, 146, 0.2)",
    width: "1.5rem",
    height: "1.5rem",
    boxSizing: "content-box",
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginTop: theme.spacing(1),

    "&:hover": {
      boxShadow: "0px 8px 20px rgba(11, 99, 146, 0.4)",
    },
  },
  uploadStatusesContainer: {
    maxWidth: "var(--sidebar-width)",
    minWidth: "var(--sidebar-width)",
  },
  fileUploadList: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    overflow: "auto",
    height: "100%",
    "& > *": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  uploadCircleProgressContainer: {
    width: "56px",
    height: "56px",
    marginBottom: theme.spacing(2),
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  circleProgressPercent: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  uploadText: {
    wordBreak: "break-word",
    textAlign: "center",
  },
  spacer: {
    marginBottom: theme.spacing(3),
  },
}));

const MAX_FILES_COUNT = 80;
const MAX_FILE_SIZE = 1024 * 1024 * +config.maxFileSize;
const ACCEPTED_FILES = [
  ".pdf",
  ".msg",
  ".eml",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
];

const getUploadStatus = (
  {
    fileName,
    progress,
    error,
  }: { fileName: string; progress: number; error: string },
  index: number,
  array: any[]
) => {
  if (progress === 102) return null;

  let Result = (
    <Grid item container direction="row" alignItems="baseline">
      <Text variant="caption">common:upload</Text>
      &nbsp;
      <LinearProgress
        variant="buffer"
        value={progress}
        valueBuffer={progress}
        style={{ width: "calc(100% - 62px)" }}
      />
    </Grid>
  );

  if (progress >= 100) {
    Result = (
      <WaitText>
        <Text variant="caption">common:processing</Text>
      </WaitText>
    );
  }

  if (error) {
    Result = (
      <Grid item container direction="row">
        <Text variant="caption" color="error" textToTranslate="common:error">
          : {error}
        </Text>
      </Grid>
    );
  }

  if (progress === 101) {
    Result = (
      <Text variant="caption" style={{ color: "#2e7d32" }}>
        common:success
      </Text>
    );
  }

  return (
    <div style={{ marginBottom: 8 }}>
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "100%",
        }}
      >
        {fileName}
      </div>
      {Result}
      <Divider flexItem={false} style={{ marginBottom: 4, marginTop: 8 }} />
    </div>
  );
};

function FileUpload({ minimalMode = false }: { minimalMode?: boolean }) {
  const { classes, cx } = useStyles();
  const appAction = useApplicationAction();
  const [filesToUpload, setFilesToUpload] = useState<any>({});
  const [duplicatedDialogState, setDuplicatedDialogState] = useState<
    { file: File; checksum: string; duplicated: boolean }[]
  >([]);
  const { user, tenantConfig } = useContext(AuthContext);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [createDocument] = useMutation(CREATE_DOCUMENT);
  const [clearDocumentChecksum] = useMutation(CLEAR_DOCUMENT_CHECKSUM);
  const withErrorHandling = useErrorHandling();

  const setFileInfo = useCallback(
    (name: string, info: any) => (prevFiles: any) => ({
      ...prevFiles,
      [name]: {
        fileName: name,
        ...info,
      },
    }),
    []
  );

  const handleErrorMessage = useCallback(
    (error) => {
      if (typeof error === "object") {
        return t("error:566");
      }

      if (error && error.includes("Uniqueness violation")) {
        return t("error:duplicate");
      }
      return error;
    },
    [t]
  );

  const uploadFiles = useCallback(
    (files: File[]) => {
      if (files.length === 0) {
        return;
      }

      const chunkedFiles = files.reduce((result, item, index) => {
        const chunkIndex = Math.floor(index / 5);

        if (!result[chunkIndex]) {
          result[chunkIndex] = [];
        }

        result[chunkIndex].push(item);

        return result;
      }, [] as File[][]);

      const processAllChunks = async () => {
        for (const filesChunk of chunkedFiles) {
          const requests = filesChunk.map((file: File, i) => {
            const { name } = file;
            setFilesToUpload(setFileInfo(name, { progress: 1 }));
            const promise = new Promise((resolve, reject) => {
              setTimeout(async () => {
                // blob client
                try {
                  // @ts-ignore
                  const blobUri = `https://${
                    config.blobStorage.storageAccountName
                  }.blob.core.windows.net/${
                    tenantConfig.DocumentsContainer.value
                  }/${uuid()}.${name.split(".").pop()}`;
                  const sasStore = new SasStore();
                  const pipeline = newPipeline(new AnonymousCredential());
                  // Inject SAS update policy factory into current pipeline
                  // pipeline.factories.unshift(new SasUpdatePolicyFactory(sasStore));
                  let validSASForContainer = sasStore.getValidSASForContainer(
                    tenantConfig.DocumentsContainer.value
                  );
                  if (!validSASForContainer) {
                    await sasStore.updateSASForContainer(
                      tenantConfig.DocumentsContainer.value
                    );
                    validSASForContainer = sasStore.getValidSASForContainer(
                      tenantConfig.DocumentsContainer.value
                    );
                  }
                  const blockBlobClient = new BlockBlobClient(
                    `${blobUri}?${validSASForContainer?.value}`, // A SAS should start with "?"
                    pipeline
                  );
                  //const beginTime = performance.now();

                  await blockBlobClient.uploadData(file, {
                    blobHTTPHeaders: {
                      blobCacheControl: "max-age=300, must-revalidate",
                      blobContentType: file.type,
                    },
                    concurrency: 10,
                    maxSingleShotSize: 4 * 1024 * 1024,
                    onProgress: (progress: any) => {
                      const res = Math.round(
                        (progress.loadedBytes * 100) / file.size
                      );
                      setFilesToUpload(setFileInfo(name, { progress: res }));
                    },
                  });

                  //const endTime = performance.now();
                  // const uploadTime = endTime - beginTime;
                  const {
                    data: {
                      createDocument: { documentId, error, success },
                    },
                  } = await withErrorHandling(createDocument, {
                    variables: {
                      document: {
                        userId: user && user.userId,
                        fileName: file.name,
                        fileUrl: blobUri,
                      },
                    },
                  });

                  if (files.length - 1 === i)
                    appAction.lastUploadedFile(documentId);

                  // if (documentId) {
                  //   await appendDocumentMeta(documentId, { uploadTime });
                  // }

                  setFilesToUpload(
                    setFileInfo(name, {
                      error: handleErrorMessage(error),
                      progress: success ? 101 : 0,
                    })
                  );

                  if (!error) {
                    setTimeout(() => {
                      setFilesToUpload(
                        setFileInfo(name, {
                          progress: 102,
                        })
                      );
                    }, 3000);
                  }

                  resolve({ name });
                } catch (error) {
                  setFilesToUpload(
                    setFileInfo(name, {
                      error: handleErrorMessage(error),
                      progress: 0,
                    })
                  );
                  resolve({ name });
                }
              }, 500);
            });

            return promise;
          });

          await Promise.all(requests);
        }
      };

      processAllChunks().then(() => {
        setTimeout(() => {
          setTimeout(() => setFilesToUpload({}), 3000);
        }, 3000);
      });
    },
    [
      setFileInfo,
      withErrorHandling,
      createDocument,
      user,
      handleErrorMessage,
      //appendDocumentMeta,
      tenantConfig,
    ]
  );

  const handleCloseDuplicateDialog = useCallback(() => {
    uploadFiles(
      duplicatedDialogState
        .filter((dds) => !dds.duplicated)
        .map((dds) => dds.file)
    );
    setDuplicatedDialogState([]);
  }, [duplicatedDialogState, uploadFiles]);

  const handleConfirmDuplicateDialog = useCallback(() => {
    clearDocumentChecksum({
      variables: {
        checksums: duplicatedDialogState
          .filter((dds) => dds.duplicated)
          .map((dds) => dds.checksum),
      },
    })
      .then(() => {
        uploadFiles(duplicatedDialogState.map((dds) => dds.file));
        setDuplicatedDialogState([]);
      })
      .catch((e) => {
        enqueueSnackbar(t("common:serverError"), { variant: "error" });
      });
  }, [
    enqueueSnackbar,
    clearDocumentChecksum,
    duplicatedDialogState,
    uploadFiles,
    t,
  ]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) {
        return;
      }
      if (acceptedFiles.length > MAX_FILES_COUNT)
        enqueueSnackbar(
          t("select:limitIsExceeded", { maxFilesCount: MAX_FILES_COUNT }),
          { variant: "warning" }
        );
      const filteredAcceptedFiles = acceptedFiles.splice(0, MAX_FILES_COUNT);
      const filesWithChecksums: any[] = [];
      for (let i = 0; i < filteredAcceptedFiles.length; i++) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(filteredAcceptedFiles[i]);
        reader.onloadend = () => {
          filesWithChecksums.push({
            fileName: filteredAcceptedFiles[i].name,
            //@ts-ignore
            checksum: SparkMD5.ArrayBuffer.hash(reader.result),
          });

          if (filesWithChecksums.length === filteredAcceptedFiles.length) {
            client
              .query({
                query: GET_DOCUMENTS_BY_CHECKSUM,
                fetchPolicy: "no-cache",
                variables: {
                  checksums: filesWithChecksums.map((fwc) => fwc.checksum),
                },
              })
              .then(
                ({
                  data: { documents },
                }: {
                  data: { documents: { checksum: string }[] };
                }) => {
                  if (documents.length) {
                    setDuplicatedDialogState(
                      filteredAcceptedFiles.map((faf) => {
                        const checksum = get(
                          filesWithChecksums.find(
                            (fwc) => fwc.fileName === faf.name
                          ),
                          "checksum"
                        );
                        return {
                          file: faf,
                          checksum,
                          duplicated: documents.some(
                            (d) => d.checksum === checksum
                          ),
                        };
                      })
                    );
                  } else {
                    uploadFiles(filteredAcceptedFiles);
                  }
                }
              );
          }
        };
      }
    },
    [enqueueSnackbar, t, uploadFiles]
  );

  const onDropRejected = useCallback(
    (files: FileRejection[]) => {
      let rejectedFiles = 0;
      files.forEach(({ file: { name, size, type } }) => {
        if (
          !ACCEPTED_FILES.some((accepterFormat) =>
            type.includes(accepterFormat.slice(1))
          )
        ) {
          rejectedFiles++;
          setFilesToUpload(
            setFileInfo(name, {
              error: `${t("error:startFileType")} ${type + " "} ${t(
                "error:endFileType"
              )} `,
            })
          );
        }

        if (size > MAX_FILE_SIZE) {
          rejectedFiles++;
          setFilesToUpload(
            setFileInfo(name, { error: t("select:upload_100mb_error") })
          );
        }
      });

      if (rejectedFiles === files.length) {
        setTimeout(() => setFilesToUpload({}), 3000);
      }
    },
    [setFileInfo, t]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILES,
    maxSize: MAX_FILE_SIZE,
    multiple: true,
    onDropRejected,
  });

  const renderDropArea = () => (
    <>
      <InputBase inputProps={getInputProps()} />
      <Box className={classes.iconButton}>
        <Add />
      </Box>
      {!minimalMode && (
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.text}
        >
          <Typography variant="h5" color="primary">
            {t("common:upload")}
          </Typography>
          <Typography color="textSecondary" className={classes.uploadText}>
            {t("select:drag_docs_here")}
          </Typography>
          <Text
            color="primary"
            className={classes.uploadText}
            translationOptions={{
              maxFilesCount: MAX_FILES_COUNT,
            }}
          >
            select:uploadLimitation
          </Text>
          <Box className={classes.spacer} />
        </Grid>
      )}
    </>
  );

  const renderFileUploadStatus = (fileStatuses: any[]) => {
    if (minimalMode) {
      const allProgress = fileStatuses.reduce(
        (sum, { progress, error }: { progress: number; error: string }) =>
          progress === 101 || error ? sum + 100 : sum + progress,
        0
      );
      const averageProgress = allProgress / fileStatuses.length;
      const roundAverageProgress = Math.round(averageProgress);

      const circularProgressView = (
        <>
          <CircularProgress variant="determinate" value={averageProgress} />
          <Typography
            variant="caption"
            className={classes.circleProgressPercent}
          >
            {roundAverageProgress}%
          </Typography>
        </>
      );

      const circularLoaderView = <CircularProgress />;

      return (
        <Box className={classes.uploadCircleProgressContainer}>
          {roundAverageProgress > 0 && roundAverageProgress < 100
            ? circularProgressView
            : circularLoaderView}
        </Box>
      );
    }

    return (
      <Box
        className={classes.uploadStatusesContainer}
        sx={{
          maxHeight: 180,
          overflowY: "auto",
          p: 1,
        }}
      >
        <Typography variant="h6">{t("select:uploading_files")}</Typography>

        {fileStatuses.map(getUploadStatus)}
      </Box>
    );
  };

  const uploadingFiles = Object.values(filesToUpload);
  const isFilesUploads =
    uploadingFiles.filter((file: any) => file.progress !== 102).length > 0;
  return (
    <>
      {!!duplicatedDialogState.length && (
        <AlertDialog
          onClose={handleCloseDuplicateDialog}
          onConfirm={handleConfirmDuplicateDialog}
          variant="warning"
        >
          {t(`select:duplicateDialog`)}
        </AlertDialog>
      )}
      <Paper
        elevation={0}
        className={cx(classes.paper, {
          [classes.hiddenTextPaper]: minimalMode,
          [classes.shownTextUploadingPaper]: !minimalMode && isFilesUploads,
        })}
        {...getRootProps()}
      >
        {isFilesUploads
          ? renderFileUploadStatus(uploadingFiles)
          : renderDropArea()}
      </Paper>
    </>
  );
}

export default FileUpload;
