import React, { FC } from "react";
import {
  ActionBar as LuiActionBar,
  ActionBarItem as LuiActionBarItem,
  Button,
  Theme,
} from "@periplus/ui-library";
import Add from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { makeStyles } from 'tss-react/mui';

interface ActionBarProps {
  onCreateClick: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginBottom: 12,
  },
  newButton: {
    [theme.breakpoints.up("sm")]: {
      minWidth: 155,
    },
  },
}));

const ActionBar: FC<ActionBarProps> = ({ onCreateClick }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <LuiActionBar className={classes.root}>
      <LuiActionBarItem>
        <Button
          className={classes.newButton}
          color="primary"
          endIcon={<Add />}
          variant="contained"
          onClick={onCreateClick}
          minimizeForMobiles
        >
          {t("create")} {t("new")}
        </Button>
      </LuiActionBarItem>
    </LuiActionBar>
  );
};

export default ActionBar;
