import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Switch,
  DatePickerRange,
  FilterMenu as LuiFilterMenu,
  Typography,
  Select,
  ToggleButtonGroup,
  Theme,
  Grid,
  Box,
  MenuItem,
} from "@periplus/ui-library";
import dayjs from "dayjs";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import useUrlSearchParams from "hooks/useUrlSearchParams";
import useGetOrgIds from "graphql/hooks/useGetOrgIds";
import { makeStyles } from 'tss-react/mui';

type StatusType = "archived" | "processing" | "failed";

const statuses = {
  archived: {
    color: "rgba(46,231,175,0.7)",
  },
  processing: {
    color: "rgba(255,153,0,0.7)",
  },
  failed: {
    color: "rgba(230,28,28,0.7)",
  },
};

const useStyles = makeStyles()((theme: Theme) => ({
  row: {
    maxWidth: 452,
    "&:not(:last-child)": {
      marginBottom: 24,
    },
  },
  filtersGroup: {
    maxWidth: 210,
  },
  filtersGroupContent: {
    display: "flex",
    alignItems: "center",
    marginTop: 12,
    minHeight: 30,
  },
  label: {},
  statusChip: {
    display: "flex",
    alignItems: "baseline",
  },
  statusChipDot: {
    width: 9,
    height: 9,
    borderRadius: "50%",
    marginRight: 6,
  },
  statusChipLabel: {
    fontSize: 12,
  },
}));

const FilterMenu: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams();
  const { tenantConfig, user } = useAuth();
  const { data: possibleOrgIds } = useGetOrgIds(user?.tenantId);

  const [state, setState] = useState<{
    files?: boolean;
    journey?: boolean;
    myFilesOnly?: boolean;
    status?: string[];
    orgIds: string[];
    startDate?: string;
    endDate?: string;
  }>(urlSearchParams);

  const defaultFilters = useMemo(
    () => ({
      startDate: dayjs().subtract(2, "week").startOf("day").format(),
      endDate: dayjs().endOf("day").format(),
      ...(tenantConfig?.UseJourneys?.value && {
        files: true,
        journey: true,
      }),
      myFilesOnly: false,
      status: [],
      orgIds: [],
    }),
    [tenantConfig?.UseJourneys?.value]
  );

  useEffect(() => {
    setState(urlSearchParams);
  }, [urlSearchParams]);

  const onConfirm = () => {
    setUrlSearchParams({
      startDate: state.startDate,
      endDate: state.endDate,
      files: state.files,
      journey: state.journey,
      myFilesOnly: state.myFilesOnly,
      status: state.status,
      orgIds: state.orgIds,
    });
  };

  const onCancel = () => setState(urlSearchParams);

  const onClear = useCallback(() => {
    setUrlSearchParams(defaultFilters);
  }, [defaultFilters]);

  const activeCount = useMemo(() => {
    let result = 0;
    if (tenantConfig?.UseJourneys?.value && !state.files) result++;
    if (tenantConfig?.UseJourneys?.value && !state.journey) result++;
    if (state.myFilesOnly) result++;
    if (state.status?.length) result++;
    if (state.orgIds?.length) result++;
    if (state.startDate !== dayjs().subtract(2, "week").startOf("day").format())
      result++;
    if (state.endDate !== dayjs().endOf("day").format()) result++;

    return result;
  }, [tenantConfig?.UseJourneys?.value, state]);

  const renderStatusChip = (status: StatusType) => (
    <div className={classes.statusChip} key={status}>
      <div
        className={classes.statusChipDot}
        style={{
          backgroundColor: statuses[status].color,
        }}
      />
      <Typography
        className={classes.statusChipLabel}
        variant="subtitle1"
        style={{
          color: statuses[status].color,
        }}
      >
        {t(status)}
      </Typography>
    </div>
  );

  return (
    <LuiFilterMenu
      activeCount={activeCount}
      onCancel={onCancel}
      onConfirm={onConfirm}
      onClear={onClear}
    >
      <Grid className={classes.row} container>
        {tenantConfig?.UseJourneys?.value && (
          <Grid
            className={classes.filtersGroup}
            item
            xs
            style={{ marginRight: 32 }}
          >
            <Typography
              className={classes.label}
              variant="overline"
              color="textSecondary"
            >
              {t("classify:fileType")}
            </Typography>
            <Box className={classes.filtersGroupContent}>
              <Switch
                label={t("classify:files")}
                checked={Boolean(state.files)}
                onToggle={(event) =>
                  setState((prev) => ({ ...prev, files: event.target.checked }))
                }
              />
              <Switch
                label={t("classify:journey")}
                checked={Boolean(state.journey)}
                onToggle={(event) =>
                  setState((prev) => ({
                    ...prev,
                    journey: event.target.checked,
                  }))
                }
                style={{ marginLeft: 16 }}
              />
            </Box>
          </Grid>
        )}
        <Grid className={classes.filtersGroup} item xs>
          <Typography
            className={classes.label}
            variant="overline"
            color="textSecondary"
          >
            {t("classify:display")}
          </Typography>
          <Box className={classes.filtersGroupContent}>
            <ToggleButtonGroup
              value={Boolean(state.myFilesOnly)}
              exclusive
              onChange={(e, value) =>
                setState((prev) => ({ ...prev, myFilesOnly: value }))
              }
              options={[
                {
                  label: t("classify:allEntries"),
                  value: false,
                },
                {
                  label: t("classify:myEntries"),
                  value: true,
                },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid className={classes.row} container>
        <Grid
          className={classes.filtersGroup}
          item
          xs
          style={{ marginRight: 32 }}
        >
          <Typography
            className={classes.label}
            variant="overline"
            color="textSecondary"
          >
            {t("classify:status")}
          </Typography>
          <Box className={classes.filtersGroupContent}>
            <Select
              clearable
              value={state.status || []}
              onChange={(value) =>
                setState((prev) => ({ ...prev, status: value }))
              }
              variant="outlined"
              MenuProps={{
                variant: "menu",
              }}
              multiple
              renderValue={(selectedOptions) => {
                return (
                  <div
                    style={{
                      display: "inline-flex",
                      flexDirection: "column",
                    }}
                  >
                    {(selectedOptions as StatusType[]).map((selectedOption) => {
                      return renderStatusChip(selectedOption);
                    })}
                  </div>
                );
              }}
              style={{ width: "100%" }}
            >
              {Object.keys(statuses).map((statusesKey) => (
                <MenuItem value={statusesKey} key={statusesKey}>
                  {renderStatusChip(statusesKey as StatusType)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
        <Grid className={classes.filtersGroup} item xs>
          <Typography
            className={classes.label}
            variant="overline"
            color="textSecondary"
          >
            {t("classify:orgId")}
          </Typography>
          <Box className={classes.filtersGroupContent}>
            <Select
              clearable
              value={state.orgIds || []}
              onChange={(value) =>
                setState((prev) => ({ ...prev, orgIds: value }))
              }
              variant="outlined"
              MenuProps={{
                variant: "menu",
              }}
              multiple
              style={{ width: "100%" }}
            >
              {possibleOrgIds?.map((orgId) => (
                <MenuItem value={orgId.org_id} key={orgId.org_id}>
                  {orgId.org_id_description}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
      </Grid>
      <Grid className={classes.row} container>
        <Grid item xs>
          <Typography
            className={classes.label}
            variant="overline"
            color="textSecondary"
          >
            {t("classify:dateRange")}
          </Typography>
          <Box className={classes.filtersGroupContent}>
            <DatePickerRange
              startDate={dayjs(state.startDate)}
              endDate={dayjs(state.endDate)}
              onChange={({ startDate, endDate }) =>
                setState((prev) => ({
                  ...prev,
                  startDate: startDate?.format(),
                  endDate: endDate?.format(),
                }))
              }
            />
          </Box>
        </Grid>
      </Grid>
    </LuiFilterMenu>
  );
};

export default FilterMenu;
