import { DocumentTypeFlag } from "domain/documentType/types";

export const CUSTOM_RELEVANT_TYPES = [
  "commercial_invoice",
  "packing_list",
  "certificate_of_origin",
  "import_licence",
  "export_licence",
  "certificate",
];

type DocumentType = {
  document_type: string;
  sequence: number;
  document_flags: number;
};
export const sortDocuments = (documents: DocumentType[]): any => {
  let documentsCopy = [...documents];

  documentsCopy
    .sort((a, b) => {
      if (a.document_type !== b.document_type) {
        return a.document_type.localeCompare(b.document_type);
      }
      return a.sequence - b.sequence;
    })
    .sort((a, b) => {
      const isACustomRelevant =
        (a.document_flags & DocumentTypeFlag.custom_relevant) ===
        DocumentTypeFlag.custom_relevant;
      const isBCustomRelevant =
        (b.document_flags & DocumentTypeFlag.custom_relevant) ===
        DocumentTypeFlag.custom_relevant;

      if (isACustomRelevant === isBCustomRelevant) {
        return 0;
      }

      if (isACustomRelevant) {
        return -1;
      }

      return 1;
    })
    .sort((a, b) => {
      const firstIndex = CUSTOM_RELEVANT_TYPES.indexOf(a.document_type);
      const secondIndex = CUSTOM_RELEVANT_TYPES.indexOf(b.document_type);

      const first = firstIndex === -1 ? Number.MAX_SAFE_INTEGER : firstIndex;
      const second = secondIndex === -1 ? Number.MAX_SAFE_INTEGER : secondIndex;
      const result = first - second;

      return result;
    });

  return documentsCopy;
};
