import React, { FC } from "react";
import { Theme, Typography } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { DECLARATION_STATUSES } from "domain/declaration/types";
import { DeclarationStatus } from "graphql/generated";
import { makeStyles } from "tss-react/mui";

interface StyleOptions {
  color?: string;
}

const useStyles = makeStyles<StyleOptions>()((theme: Theme, { color }) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  dot: {
    minWidth: "9px",
    minHeight: "9px",
    backgroundColor: color,
    borderRadius: "50%",
    marginRight: 6,
  },
  label: {
    color: color,
    fontSize: 12,
  },
}));

const DeclarationStatusChip: FC<{
  className?: string;
  declarationStatus: DeclarationStatus;
  color?: string;
}> = ({ className, declarationStatus, color }) => {
  const { classes, cx } = useStyles({
    color: color || DECLARATION_STATUSES[declarationStatus]?.color,
  });
  const { t } = useTranslation();

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.dot} />
      <Typography className={classes.label} variant="subtitle1">
        {t(`declaration_status:${declarationStatus}`)}
      </Typography>
    </div>
  );
};

export default DeclarationStatusChip;
