import { useState, useEffect, useCallback, useMemo } from "react";
import { FetchResult, useMutation } from "@apollo/client";
import { UPSERT_TABLE } from "graphql/mutations/page";
import { Document } from "pages/ValidationV2/useGetValidationV2Document";

type Coordinates = [number, number, number, number];

export interface LineItemCellData {
  value?: string;
  [key: string]: any;
}

export interface GridColumn {
  left: number;
  name?: string;
}

export interface GridRow {
  top: number;
  isHeader?: boolean;
  isJunk?: boolean;
  level?: number;
  cin?: number;
}

export interface LineItem {
  id: string;
  coordinates: Coordinates;
  columns: GridColumn[];
  rows: GridRow[];
}

export type Annotation = {
  type: string;
  value: string;
  dataPointId?: string;
  [key: string]: any;
};

interface IUseAnnotationQueryProps {
  pageId: string;
  extractTypeId: number;
  tables: any;
}

interface IUseAnnotationQueryReturnedActions {
  updateLineItems: (
    pageId: String,
    extractTypeId: Number,
    grids: LineItem[]
  ) => Promise<
    FetchResult<
      IUseAnnotationQueryProps,
      Record<string, any>,
      Record<string, any>
    >
  >;
}

type IUseAnnotationQueryReturn = [
  LineItem[],
  IUseAnnotationQueryReturnedActions
];

const useTableQuery = ({
  page_id,
  extract_type_id,
  document,
}: {
  page_id: string;
  extract_type_id: number;
  document: Document;
}): IUseAnnotationQueryReturn => {
  const [updateTable] = useMutation<IUseAnnotationQueryProps>(UPSERT_TABLE);
  const [lineItems, setLineItems] = useState<LineItem[]>([]);

  useEffect(() => {
    setLineItems(
      document.pages
        .find((page) => page.id === page_id)
        ?.pageExtracts.find((el) => el.extract_type_id === extract_type_id)
        ?.tables
    );
  }, [page_id, extract_type_id, document]);

  const updateLineItems = useCallback(
    (pageId, extractTypeId, updatedGrids: LineItem[]) => {
      setLineItems(updatedGrids);
      return updateTable({
        variables: {
          pageId,
          extractTypeId,
          tables: updatedGrids,
        },
      });
    },
    [page_id, extract_type_id, updateTable]
  );

  return useMemo<any>(
    () => [
      lineItems,
      {
        updateLineItems,
      },
    ],
    [lineItems, updateLineItems]
  );
};

export default useTableQuery;
