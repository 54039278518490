import React, { FC, forwardRef } from "react";
import { Box, BoxProps, combineSX } from "@periplus/ui-library";

interface NestedTableFormContainerProps extends BoxProps {}

const NestedTableFormContainer: FC<NestedTableFormContainerProps> = forwardRef(
  ({ sx, children }, ref) => {
    return (
      <Box
        ref={ref}
        sx={combineSX(
          {
            display: "grid",
            alignItems: "center",
            alignContent: "start",
            overflowY: "auto",
            p: 1,
            border: 1,
            borderColor: "#C9C9DE",
            borderRadius: 0.5,
            gap: 1,
          },
          sx
        )}
      >
        {children}
      </Box>
    );
  }
);

export default NestedTableFormContainer;
