import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useAditSubscribeDeclarationChangesSubscription } from "graphql/generated";

gql`
  subscription AditSubscribeDeclarationChanges($discussionId: bigint!) {
    declaration_message(
      where: { discussion_id: { _eq: $discussionId } }
      order_by: { msg_date: asc }
    ) {
      creation_user
      message
      msg_date
      read_date
      type
      user {
        tenant_id
        display_name
        first_name
        last_name
        email
        tenant {
          tenant_name
        }
      }
      declaration_discussion {
        discussion_theme
      }
    }
  }
`;

const useSubscribeDeclarationMessages = (discussionId?: number) => {
  const { data, loading } = useAditSubscribeDeclarationChangesSubscription({
    variables: {
      discussionId,
    },
    shouldResubscribe: true,
    skip: discussionId === -1,
  });

  return useMemo(
    () => ({
      loading,
      data: {
        messages:
          data?.declaration_message.map(
            ({ declaration_discussion: { discussion_theme }, ...rest }) => ({
              ...rest,
              discussion_theme,
            })
          ) || [],
      },
    }),
    [data, loading]
  );
};

export default useSubscribeDeclarationMessages;
