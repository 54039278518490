import React, { FC } from "react";
import {
  Search,
  ActionBar as LuiActionBar,
  ActionBarItem as LuiActionBarItem,
  Button,
  Theme,
  useMediaQuery,
} from "@periplus/ui-library";
import Add from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { makeStyles } from 'tss-react/mui';

interface ActionBarProps {
  search?: string;
  onSearchChange: (value: string) => void;
  onCreateClick: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginBottom: 12,
  },
  searchFilter: {
    marginRight: 12,
  },
  newButton: {
    [theme.breakpoints.up("sm")]: {
      minWidth: 155,
    },
  },
}));

const ActionBar: FC<ActionBarProps> = ({
  search,
  onSearchChange,
  onCreateClick,
}) => {
  const { classes } = useStyles();
  const isUpTablet = useMediaQuery(
    (theme: Theme) => theme.breakpoints.up("sm"),
    { noSsr: true }
  );
  const { t } = useTranslation();

  return (
    <LuiActionBar className={classes.root}>
      <LuiActionBarItem></LuiActionBarItem>
      <LuiActionBarItem>
        <Search
          className={classes.searchFilter}
          value={search}
          onChange={onSearchChange}
          style={{ maxWidth: 180 }}
          label={t("search")}
        />
        <Button
          className={classes.newButton}
          color="primary"
          endIcon={isUpTablet && <Add />}
          variant="contained"
          onClick={onCreateClick}
        >
          {isUpTablet ? `${t("create")} ${t("new")}` : <Add />}
        </Button>
      </LuiActionBarItem>
    </LuiActionBar>
  );
};

export default ActionBar;
