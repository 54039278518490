import React, {
  FC,
  HtmlHTMLAttributes,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";
import {
  SubTable as LuiSubTable,
  SubTableColumns,
  SubTableRows,
  ThreeDotsMenu,
  Typography,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useAuth } from "contexts/AuthContext";
import useGetFilePages from "graphql/hooks/useGetFilePages";
import { TextNaked, Text } from "components/Text";
import config from "config";
import { getDownloadUrlAsync } from "utils/azureBlobStorageUtils";
import { DocumentTypeFlag } from "domain/documentType/types";
import { Role } from "rbac-rules";
//import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { SCHEDULE_DOC_ANNOTATION_EXPORT } from "graphql/mutations/validation";
import { makeStyles } from "tss-react/mui";
import clsx from "clsx";

interface DocumentsSubTableProps extends HtmlHTMLAttributes<HTMLElement> {
  fileId: string;
  mrtTable?: boolean;
}

const useStyles = makeStyles<{ mrtTable?: boolean }>()(
  (theme, { mrtTable }) => ({
    root: {
      ...(mrtTable && {
        "& th:first-child": {
          paddingLeft: 0,
          paddingRight: 22,
        },
        "& td:first-child": {
          paddingLeft: 0,
          paddingRight: 29,
        },
      }),
    },
    link: {
      textDecoration: "none",
      width: "100%",
      display: "block",
      color: "#323232",
      "&:hover": {
        textDecoration: "none",
      },
    },
    columnCell: {
      wordBreak: "keep-all",
      whiteSpace: "nowrap",
    },
    documentColumn: {
      width: "1%",
    },
    sequenceColumn: {
      width: "1%",
    },
    numberOfPagesColumn: {
      width: "1%",
    },
    isValidatedColumn: {
      width: "1%",
    },
    statusColumn: {
      width: "1%",
    },
    lastExportDateColumn: {
      width: "1%",
    },
    lastUpdateColumn: {
      width: "1%",
    },
    userColumn: {
      width: "1%",
    },
    actionsColumn: {
      width: "99%",
      textAlign: "end",
      ...(mrtTable && {
        paddingRight: "23px!important",
      }),
    },
    noData: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

// const DocumentValidateV2 = ({
//   fileId,
//   document,
//   isInternalValidation,
//   isNanonet,
// }: {
//   fileId: string;
//   document: any;
//   isInternalValidation: boolean;
//   isNanonet: boolean;
//   isRossum: boolean;
// }) => {
//   const classes = useStyles();

//   if (isNanonet || isInternalValidation) {
//     return (
//       <Link
//         to={`/select/validationV2/${fileId}?documentId="${document.id}"`}
//         className={classes.link}
//       >
//         <Text>common:validateV2</Text>
//       </Link>
//     );
//   }

//   // if (document.rossumUrl && isRossum) {
//   //   return (
//   //     <a
//   //       href={document.rossumUrl}
//   //       target="_blank"
//   //       rel="noopener noreferrer"
//   //       className={classes.link}
//   //       onClick={(e) => e.stopPropagation()}
//   //     >
//   //       <Text>common:validate</Text>
//   //     </a>
//   //   );
//   // }
//   return null;
// };

// const DocumentValidate = ({
//   document,
//   isInternalValidation,
//   isNanonet,
//   isRossum,
// }: {
//   document: any;
//   isInternalValidation: boolean;
//   isNanonet: boolean;
//   isRossum: boolean;
// }) => {
//   const classes = useStyles();

//   if (isNanonet || isInternalValidation) {
//     return (
//       <Link to={`/select/validation/${document.id}`} className={classes.link}>
//         <Text>common:validate</Text>
//       </Link>
//     );
//   }

//   if (document.rossumUrl && isRossum) {
//     return (
//       <a
//         href={document.rossumUrl}
//         target="_blank"
//         rel="noopener noreferrer"
//         className={classes.link}
//         onClick={(e) => e.stopPropagation()}
//       >
//         <Text>common:validate</Text>
//       </a>
//     );
//   }
//   return null;
// };

const DocumentsSubTable: FC<DocumentsSubTableProps> = ({
  fileId,
  mrtTable,
}) => {
  const { classes } = useStyles({ mrtTable });
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { user } = useAuth();
  const isAlphaTester = user?.allowedRoles?.includes(Role.AlphaTester);
  const isUserAllowedToAutoClassification = !!isAlphaTester;
  const { data: documents } = useGetFilePages(fileId);

  const [scheduleDocAnnotationExport] = useMutation(
    SCHEDULE_DOC_ANNOTATION_EXPORT
  );

  const handleClickExport = useCallback(
    (row: any) => {
      scheduleDocAnnotationExport({
        variables: {
          documentID: row.data.id,
        },
      });
    },
    [scheduleDocAnnotationExport]
  );

  const columns = useMemo(
    () =>
      ({
        document_type: {
          header: t("document_type"),
          headerClassName: clsx(classes.documentColumn, classes.columnCell),
          cellClassName: classes.columnCell,
          cell: (row) => (
            <TextNaked>
              {row.data.documentType?.translations[language] ||
                row.data.documentType?.translations[config.defaultLanguage]}
            </TextNaked>
          ),
        },
        sequence: {
          header: t("sequence"),
          headerClassName: clsx(classes.sequenceColumn, classes.columnCell),
          cellClassName: classes.columnCell,
        },
        number_of_pages: {
          header: t("page"),
          headerClassName: clsx(
            classes.numberOfPagesColumn,
            classes.columnCell
          ),
          cellClassName: classes.columnCell,
        },
        is_validated: {
          header: t("is_validated"),
          headerClassName: clsx(classes.isValidatedColumn, classes.columnCell),
          cellClassName: classes.columnCell,
          cell: (row) =>
            t(
              row.data.meta?.is_validated
                ? "is_validated_true"
                : "is_validated_false"
            ),
        },
        document_status: {
          header: t("status"),
          headerClassName: clsx(classes.statusColumn, classes.columnCell),
          cellClassName: classes.columnCell,
          cell: (row) => t(`document_status:${row.data.document_status}`),
        },
        last_export_date: {
          header: t("last_export_date"),
          headerClassName: clsx(
            classes.lastExportDateColumn,
            classes.columnCell
          ),
          cellClassName: classes.columnCell,
          cell: (row) =>
            row.data.meta?.AnnotationExportedData
              ? dayjs(row.data.meta.AnnotationExportedData).format(
                  "DD.MM.YYYY HH:mm:ss"
                )
              : "",
        },
        last_modified_date: {
          header: t("lastUpdate"),
          headerClassName: clsx(classes.lastUpdateColumn, classes.columnCell),
          cellClassName: classes.columnCell,
          cell: (row) =>
            row.data.last_modified_date &&
            dayjs(row.data.last_modified_date).format("DD.MM.YYYY HH:mm:ss"),
        },
        user: {
          header: t("user"),
          headerClassName: clsx(classes.userColumn, classes.columnCell),
          cellClassName: classes.columnCell,
          cell: (row) =>
            t(`document_status:${row.data.created_by_user.display_name}`),
        },
        actions: {
          headerClassName: clsx(classes.actionsColumn, classes.columnCell),
          cellClassName: classes.actionsColumn,
          cell: (row) => {
            const { document_blobs, document_name } = row.data;
            const blobWithSameExtension = document_blobs?.slice(-1)?.[0];

            const options = [];
            if (blobWithSameExtension) {
              options.push({
                content: <Text>common:download</Text>,
                action: async (close: any) => {
                  window.open(
                    await getDownloadUrlAsync(
                      blobWithSameExtension?.url || "",
                      "application/pdf",
                      `inline; filename="${encodeURI(document_name)}.pdf"`
                    ),
                    "_blank"
                  );
                  close();
                },
              });
            }

            const is_validated = row.data.meta.is_validated;
            const documentFlags = Number(row.data.document_flags);

            const isInternalValidation =
              (documentFlags & DocumentTypeFlag.ocr_datapoint_completed) ===
              DocumentTypeFlag.ocr_datapoint_completed;

            // const isNanonetsRequired = false;

            // const isRossumExported = false;

            const isFounSrvCompleted =
              (documentFlags & DocumentTypeFlag.foun_srv_completed) ===
              DocumentTypeFlag.foun_srv_completed;

            if (isUserAllowedToAutoClassification) {
              if (isInternalValidation && isFounSrvCompleted) {
                // options.push({
                //   content: (
                //     <DocumentValidate
                //       document={row.data}
                //       isInternalValidation={isInternalValidation}
                //       isNanonet={isNanonetsRequired}
                //       isRossum={isRossumExported}
                //     />
                //   ),
                //   action: () => {},
                // });
                // options.push({
                //   content: (
                //     <DocumentValidateV2
                //       fileId={fileId}
                //       document={row.data}
                //       isInternalValidation={isInternalValidation}
                //       isNanonet={isNanonetsRequired}
                //       isRossum={isRossumExported}
                //     />
                //   ),
                //   action: () => {},
                // });
              }

              if (is_validated) {
                options.push({
                  content: (
                    <>
                      <Text>common:export</Text>
                    </>
                  ),
                  action: () => handleClickExport(row),
                });
              }
            }

            return options.length > 0 ? (
              <ThreeDotsMenu options={options.filter(Boolean)} />
            ) : null;
          },
        },
      } as SubTableColumns<any>),
    [fileId]
  );
  const [rows, setRows] = useState<SubTableRows<any>>({});

  useEffect(() => {
    setRows({
      ...documents.reduce((acc: any, el: any) => {
        acc[el.id] = {
          data: el,
        };
        return acc;
      }, {} as SubTableRows<any>),
    });
  }, [documents]);

  if (!documents.length)
    return (
      <div className={classes.noData}>
        <Typography color="textSecondary">{t("noData")}</Typography>
      </div>
    );

  return (
    <LuiSubTable<any>
      columns={columns}
      rows={rows}
      classes={{
        root: classes.root,
      }}
    />
  );
};

export default DocumentsSubTable;
