import React from "react";
import { useTranslation } from "react-i18next";
import NestedTable from "../components/NestedTable";
import {
  DECLARATION_FORM_DEBOUNCE,
  DeclarationPreviousDocumentForm,
  FieldsRefs,
  SetDeclarationFormFieldRefFunction,
} from "../Declaration";
import { LanguageType } from "i18n";
import {
  FormTextField,
  MRTable,
  Typography,
  useTheme,
} from "@periplus/ui-library";
import NestedTableFormId from "../components/NestedTable/NestedTableFormId";
import FormField from "../components/FormField/FormField";
import EdecDomainFormAutocomplete from "domain/edec/components/EdecDomainFormAutocomplete";
import NestedTableFormContainer from "../components/NestedTable/NestedTableFormContainer";
import { EdecData } from "domain/edec/useGetEdecMasterData";

interface Props {
  edecData: EdecData;
  onReturn: () => void;
  setFormFieldRef: SetDeclarationFormFieldRefFunction;
  onFormFieldFocus: (value: string) => void;
  onFormFieldBlur: () => void;
  pageContainerWidth: number;
  formFieldsRefs: React.MutableRefObject<FieldsRefs>;
  pageContainerHeight: number;
}

export default function ({
  edecData,
  onReturn,
  setFormFieldRef,
  onFormFieldFocus,
  onFormFieldBlur,
  pageContainerWidth,
  formFieldsRefs,
  pageContainerHeight,
}: Props) {
  const { t, i18n } = useTranslation("declarationNew");
  const language = i18n.language as LanguageType;
  const theme = useTheme();
  const isContainerUpMd =
    pageContainerWidth && pageContainerWidth > theme.breakpoints.values.md;

  const columns = React.useMemo<
    MRTable.MRT_ColumnDef<DeclarationPreviousDocumentForm>[]
  >(
    () => [
      {
        header: t("Type"),
        accessorKey: "previous_document_type",
      },
      {
        header: t("Description"),
        Cell: ({ row }) =>
          edecData.edec_domains["previousDocumentType"].find(
            (edec_domain) =>
              edec_domain.value === row.original.previous_document_type
          )?.[`meaning_${language}`],
      },
      {
        header: t("Number"),
        accessorKey: "previous_document_reference",
      },
      {
        header: t("Additional"),
        accessorKey: "additional_information",
      },
    ],
    [t, language, edecData.edec_domains]
  );

  return (
    <NestedTable
      name="previous_documents"
      label={t("Previous Documents")}
      columns={columns}
      defaultItem={{
        previous_document_type: null,
        previous_document_reference: null,
        additional_information: null,
      }}
      onReturn={onReturn}
      formFieldsRefs={formFieldsRefs}
      pageContainerHeight={pageContainerHeight}
      renderForm={({
        selectedItemRowNumber,
        getTableGeneralFormFieldProps,
        ref,
      }) => (
        <NestedTableFormContainer
          ref={ref}
          sx={(theme) => ({
            gridTemplateColumns: isContainerUpMd
              ? "auto 1fr auto 1fr"
              : "auto 1fr",
            alignItems: "start",
          })}
        >
          <Typography>{t("Row Nr.")}</Typography>
          <NestedTableFormId id={selectedItemRowNumber} />
          <FormField
            {...getTableGeneralFormFieldProps("previous_document_type")}
            label={t("Type")}
            onFocus={onFormFieldFocus}
            onBlur={onFormFieldBlur}
            setFormFieldRef={setFormFieldRef}
            sx={{
              gridColumn: "1",
            }}
            renderInput={({ inputRef, ...rest }) => (
              <EdecDomainFormAutocomplete
                {...rest}
                options={edecData.edec_domains["previousDocumentType"]}
                InputProps={{
                  inputRef,
                }}
              />
            )}
          />
          <FormField
            {...getTableGeneralFormFieldProps("previous_document_reference")}
            label={t("Number")}
            onFocus={onFormFieldFocus}
            onBlur={onFormFieldBlur}
            setFormFieldRef={setFormFieldRef}
            fillByClickMode
            renderInput={({ ...rest }) => (
              <FormTextField {...rest} debounce={DECLARATION_FORM_DEBOUNCE} />
            )}
          />
          <FormField
            {...getTableGeneralFormFieldProps("additional_information")}
            label={t("Additional")}
            onFocus={onFormFieldFocus}
            onBlur={onFormFieldBlur}
            setFormFieldRef={setFormFieldRef}
            fillByClickMode
            renderInput={({ ...rest }) => (
              <FormTextField
                {...rest}
                debounce={DECLARATION_FORM_DEBOUNCE}
                sx={{
                  gridColumn: "2/-1",
                }}
              />
            )}
          />
        </NestedTableFormContainer>
      )}
    />
  );
}
