import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { APPEND_META_DOCUMENT } from "graphql/mutations/document";
import useErrorHandling from "./useErrorHandling";

const useAppendDocumentMeta = () => {
  const [appendDocumentMeta] = useMutation(APPEND_META_DOCUMENT);
  const withErrorHandling = useErrorHandling();

  return useCallback(
    (docId: string, updated: { [key: string]: any }) => {
      return withErrorHandling(appendDocumentMeta, {
        variables: {
          id: docId,
          meta: updated,
        },
      });
    },
    [appendDocumentMeta]
  );
};

export default useAppendDocumentMeta;
