import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";

import useGetDocumentTypes from "domain/documentType/useGetDocumentTypes";
import { useAuth } from "contexts/AuthContext";

export type DocumentStats = {
  docs_qty: number;
  document_type: string;
  total_pages: number;
  users_qty: string;
};

type QueryResult = {
  pp_doc_stats: DocumentStats[];
};

export const GET_DOCUMENT_STATS = gql`
  query getDocumentStats(
    $started: timestamptz
    $ended: timestamptz
    $userIds: _uuid
    $orgIds: _int2
    $tenant: uuid
  ) {
    pp_doc_stats(
      args: {
        _started: $started
        _ended: $ended
        _orgids: $orgIds
        _crusers: $userIds
        _tenant: $tenant
      }
    ) {
      docs_qty
      document_type
      total_pages
      users_qty
    }
  }
`;

interface Variables {
  startDate?: string;
  endDate?: string;
  userIds?: string[];
  orgIds?: string[];
}

const useGetDocumentStats = ({
  startDate,
  endDate,
  userIds,
  orgIds,
}: Variables): any => {
  const { user } = useAuth();
  const variables = useMemo(
    () => ({
      ...(startDate
        ? { started: startDate }
        : { started: dayjs(0).utc().format() }),
      ...(endDate ? { ended: endDate } : { ended: dayjs().utc().format() }),
      userIds: userIds?.length ? `{${userIds?.join(",")}}` : undefined,
      orgIds: orgIds?.length ? `{${orgIds?.join(",")}}` : undefined,
      tenant: user?.tenantId,
    }),
    [endDate, orgIds, startDate, userIds]
  );

  const queryResult = useQuery<QueryResult>(GET_DOCUMENT_STATS, {
    variables,
    fetchPolicy: "network-only",
  });
  const docTypeQueryResult = useGetDocumentTypes();

  const preparedResult = useMemo(() => {
    const { data } = queryResult;
    const { data: docsType } = docTypeQueryResult;
    if (!data || !docsType) {
      return {
        ...queryResult,
        data: [],
      };
    }

    const result = data.pp_doc_stats
      .map((docStats) => {
        const docType = docsType.find(
          (docType) => docType.name === docStats.document_type
        );

        if (docType) {
          return {
            ...docStats,
            document_type: docType.translated_name,
            color: docType.meta?.color,
          };
        }

        return null;
      })
      .filter(Boolean);

    return { ...queryResult, data: result };
  }, [queryResult, docTypeQueryResult]);

  return preparedResult;
};

export default useGetDocumentStats;
