// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useState } from "react";
import { SasStore } from "utils/blockBlobClient";

const prepareData = (
  fileUrl: string,
  contentType?: string
): { sasContentType?: string; containerNameExtracted?: string } => {
  const containerNameExtracted = fileUrl.split("/")[3];

  /*
   * doc  application/msword
   * xls  application/vnd.ms-excel
   * docx application/vnd.openxmlformats-officedocument.wordprocessingml.document
   * xlsx application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
   * */

  const extension = fileUrl.split(".").pop();
  /* as browsers can't open non-PDF's ignoring this setting */
  const sasContentType = extension === "pdf" ? contentType : undefined;

  return {
    sasContentType,
    containerNameExtracted,
  };
};

export const useGetDownloadUrlFunction = () => {
  const [url, setUrl] = useState<string>();

  const getDownloadUrl = (
    fileUrl: string,
    contentType?: string,
    contentDisposition?: string
  ): {
    url?: string;
    loading: boolean;
  } => {
    if (!fileUrl) {
      return {
        url: fileUrl,
        loading: false,
      };
    }

    const { sasContentType, containerNameExtracted } = prepareData(
      fileUrl,
      contentType
    );

    if (!containerNameExtracted) {
      return {
        url: fileUrl,
        loading: false,
      };
    }

    const sasStore = new SasStore();

    const sasToken = sasStore.getValidSASForContainer(
      containerNameExtracted,
      sasContentType,
      contentDisposition
    );

    if (sasToken?.value) {
      return {
        url: `${fileUrl}?${sasToken.value}`,
        loading: false,
      };
    }

    (function fetchSas(...params: any) {
      sasStore.updateSASForContainer
        .apply(sasStore, params)
        .then(({ value, expiryDate }) => {
          setUrl && setUrl(`${fileUrl}?${value}`);
          if (expiryDate)
            setTimeout(() => {
              fetchSas(
                containerNameExtracted,
                sasContentType,
                contentDisposition
              );
            }, new Date(expiryDate).getTime() - new Date().getTime());
        });
    })(containerNameExtracted, sasContentType, contentDisposition);

    return {
      url,
      loading: !url,
    };
  };

  return getDownloadUrl;
};

export const useGetDownloadUrl = (
  fileUrl: string,
  contentType?: string,
  contentDisposition?: string
): {
  url?: string;
  loading: boolean;
} => {
  const [url, setUrl] = useState<string>();

  if (!fileUrl) {
    return {
      url: fileUrl,
      loading: false,
    };
  }

  const { sasContentType, containerNameExtracted } = prepareData(
    fileUrl,
    contentType
  );

  if (!containerNameExtracted) {
    return {
      url: fileUrl,
      loading: false,
    };
  }

  const sasStore = new SasStore();

  const sasToken = sasStore.getValidSASForContainer(
    containerNameExtracted,
    sasContentType,
    contentDisposition
  );

  if (sasToken?.value) {
    setTimeout(() => {
      fetchSas(containerNameExtracted, sasContentType, contentDisposition);
    }, new Date(sasToken.expiryDate).getTime() - new Date().getTime());

    return {
      url: `${fileUrl}?${sasToken.value}`,
      loading: false,
    };
  }

  function fetchSas(...params: any) {
    sasStore.updateSASForContainer
      .apply(sasStore, params)
      .then(({ value, expiryDate }) => {
        setUrl && setUrl(`${fileUrl}?${value}`);
        if (expiryDate)
          setTimeout(() => {
            fetchSas(
              containerNameExtracted,
              sasContentType,
              contentDisposition
            );
          }, new Date(expiryDate).getTime() - new Date().getTime());
      });
  }
  fetchSas(containerNameExtracted, sasContentType, contentDisposition);

  return {
    url,
    loading: !url,
  };
};

export const getDownloadUrlAsync = async (
  fileUrl: string,
  contentType?: string,
  contentDisposition?: string
) => {
  const { sasContentType, containerNameExtracted } = prepareData(
    fileUrl,
    contentType
  );

  if (!containerNameExtracted) {
    return fileUrl;
  }

  const sasStore = new SasStore();

  let sasToken = sasStore.getValidSASForContainer(
    containerNameExtracted,
    sasContentType,
    contentDisposition
  );
  if (
    !sasToken ||
    new Date(sasToken.expiryDate).getTime() >= new Date().getTime()
  ) {
    sasToken = await sasStore.updateSASForContainer(
      containerNameExtracted,
      sasContentType,
      contentDisposition
    );
  }

  return `${fileUrl}?${sasToken?.value || ""}`;
};

const getDownloadUrl = (fileUrl: string, contentType?: string): string => {
  const { sasContentType, containerNameExtracted } = prepareData(
    fileUrl,
    contentType
  );

  if (!containerNameExtracted) {
    return fileUrl;
  }

  const sasStore = new SasStore();

  const sasToken = sasStore.getValidSASForContainer(
    containerNameExtracted,
    sasContentType
  );

  return `${fileUrl}?${sasToken?.value || ""}`;
};

export default getDownloadUrl;
