import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { OrgId } from "./useGetOrgIds";

interface Variables {
  search?: string;
  limit?: number;
  offset?: number;
}

export const GET_ORG_IDS = gql`
  query GET_ORG_IDS($limit: Int, $offset: Int, $search: String, $id: smallint) {
    org_ids: pp_org_ids(
      args: { _limit: $limit, _offset: $offset, _search: $search, _id: $id }
    ) {
      org_id
      display_name
      org_id_description
      qty
      org_params
      addresses_desc
      org_ids_desc
    }
  }
`;

const useGetOrgIds = ({ limit, offset }: Variables = {}) => {
  const response = useQuery<{ org_ids: OrgId[] }>(GET_ORG_IDS, {
    variables: {
      limit,
      offset,
    },
    fetchPolicy: "no-cache",
  });

  const result = useMemo(() => {
    const { data, ...rest } = response;
    return {
      data: {
        org_ids: data?.org_ids || [],
        //@ts-ignore
        org_ids_total_count: (data?.org_ids[0]?.qty || 0) as number,
      },
      ...rest,
    };
  }, [response]);

  return result;
};

export default useGetOrgIds;
