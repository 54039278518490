import { gql } from "@apollo/client";
import { useAditSearchAddressLazyQuery } from "graphql/generated";

gql`
  query AditSearchAddress($search: String) {
    addresses: pp_addresses(args: { _search: $search, _limit: 10 }) {
      id
      company_name
      street
      street_number
      country
      zipcode
      city
      reference
      customs_account_number
      vat_account_number
    }
  }
`;

export default () => {
  const [query, options] = useAditSearchAddressLazyQuery({
    fetchPolicy: "no-cache",
  });

  return [
    query,
    {
      ...options,
      data: {
        addresses: options.data?.addresses ?? [],
      },
    },
  ] as const;
};
