import React, { useState } from "react";
import ModelRetrainTable from "./ModelRetrainsTable";
import { PageContainer } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import AiConfirmedDeclarationsTable from "./AiConfirmedDeclarationsTable/AiConfirmedDeclarationsTable";

function RetrainModel() {
  const { t } = useTranslation();

  const tabs = [t("Model Retrain Data"), t("Ai Confirmed Declarations")];
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <PageContainer
      title={t("Retrain Model Dashboard")}
      tabs={tabs}
      selectedTab={selectedTab}
      onTabChange={setSelectedTab}
    >
      {(() => {
        switch (selectedTab) {
          case 0:
            return <ModelRetrainTable />;
          case 1:
            return <AiConfirmedDeclarationsTable />;
        }
      })()}
    </PageContainer>
  );
}

export default RetrainModel;
