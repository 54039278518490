import React, { FC } from "react";
import { Typography, TypographyProps } from "@periplus/ui-library";

interface FormFieldLabelProps extends TypographyProps {}

const FormFieldLabel: FC<FormFieldLabelProps> = ({ ...rest }) => {
  return <Typography variant="body1" {...rest} />;
};

export default FormFieldLabel;
