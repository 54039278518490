import React, { HtmlHTMLAttributes } from "react";
import PDFPreview from "components/PDFPreview";
import { File } from "domain/file/types";
import useGetFilePages from "graphql/hooks/useGetFilePages";
import FileStatus from "domain/file/components/FileStatus";
import { useTranslation } from "react-i18next";

interface Props extends HtmlHTMLAttributes<HTMLElement> {
  file?: File;
}

export default function ({ file }: Props) {
  const { t } = useTranslation();
  const { data: fileDocuments, loading: loadingFileDocuments } =
    useGetFilePages(file?.id);

  const pages =
    fileDocuments?.reduce(
      // @ts-ignore
      (acc, doc) => {
        const allPages = doc.pages.reduce(
          // @ts-ignore
          (pageAggr, page) => pageAggr.concat(page),
          []
        );
        return acc.concat(allPages);
      },
      []
    ) || [];

  return (
    <PDFPreview
      finish
      file={
        file && {
          name: file.file_flags === 2 ? file.name : file.identification,
          creationDate: file.creation_date,
          org_id_description: file.org_id_description,
          extension: "",
          pages: pages,
          status: (
            <FileStatus
              file_status={file.file_status || "archived"}
              adoptTextColor
            />
          ),
        }
      }
      placeholder={pages.length === 0 && t("select:no_custom_relevant")}
      loading={loadingFileDocuments}
    />
  );
}
