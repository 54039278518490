import React, { FC } from "react";
import { Box, BoxProps, Typography, combineSX } from "@periplus/ui-library";

interface NestedTableFormIdProps extends Omit<BoxProps, "id"> {
  id?: number;
}

const NestedTableFormId: FC<NestedTableFormIdProps> = ({ id, sx, ...rest }) => {
  return (
    <Box
      {...rest}
      sx={combineSX(
        {
          minHeight: 30,
          minWidth: 30,
          maxHeight: 30,
          maxWidth: 30,
          backgroundColor: "#BDB5FC",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 0.5,
        },
        sx
      )}
    >
      <Typography variant="subtitle1" sx={{ color: "white" }}>
        {id ?? ""}
      </Typography>
    </Box>
  );
};

export default NestedTableFormId;
