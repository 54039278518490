import React, { FC } from "react";
import { SxProps, Theme } from "@periplus/ui-library";
import FormFieldLabel from "./FormFieldLabel";
import FormFieldInput, { FormFieldInputProps } from "./FormFieldInput";

export interface FormFieldProps extends Omit<FormFieldInputProps, "children"> {
  label?: string;
  sx?: SxProps<Theme>;
  renderInput: FormFieldInputProps["children"];
}

const FormField: FC<FormFieldProps> = ({
  label,
  sx,
  renderInput,
  ...inputProps
}) => {
  return (
    <>
      {label && <FormFieldLabel sx={sx}>{label}</FormFieldLabel>}
      <FormFieldInput {...inputProps} children={renderInput} />
    </>
  );
};

export default FormField;
