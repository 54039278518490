import { gql } from "@apollo/client";
import { useAditSubscribeToUnreadMessagesCountSubscription } from "graphql/generated";

gql`
  subscription AditSubscribeToUnreadMessagesCount($fileIds: [uuid!]) {
    declarationsDiscussions: declaration_discussion(
      where: { file_id: { _in: $fileIds } }
    ) {
      file_id
      messages: declaration_messages_aggregate(
        where: {
          read_date: { _is_null: true }
          user: { tenant_id: { _neq: "b4b61b73-4f38-45a0-b460-7e38b2777479" } }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export default useAditSubscribeToUnreadMessagesCountSubscription;
