import React, { FC, CSSProperties } from "react";
import { SvgIcon } from "@periplus/ui-library";

interface RussianFederationProp {
  style?: CSSProperties;
  [key: string]: any;
}

const RussianFederation: FC<RussianFederationProp> = ({ style, ...rest }) => {
  return (
    <SvgIcon
      viewBox="0 0 640 480"
      style={{ height: "18px", width: "24px", ...style }}
      {...rest}
    >
      <g fillRule="evenodd" strokeWidth="1pt">
        <path fill="#fff" d="M0 0h640v480H0z" />
        <path fill="#0039a6" d="M0 160h640v320H0z" />
        <path fill="#d52b1e" d="M0 320h640v160H0z" />
      </g>
    </SvgIcon>
  );
};

export default RussianFederation;
