import React from "react";
import { AutocompleteProps, Typography, Box } from "@periplus/ui-library";
import { useDebounceCallback } from "@react-hook/debounce";
import {
  CommodityCodeListEntity,
  useGetCommodityCodesListLazy,
} from "./useGetCommodityCodesList";

type CommodityCodeAutocompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> = AutocompleteProps<
  CommodityCodeListEntity,
  Multiple,
  DisableClearable,
  false
>;

export interface CommodityCodeAutocompleteHookProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends Omit<
    CommodityCodeAutocompleteProps<Multiple, DisableClearable>,
    "options"
  > {}

export default function <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>({
  InputProps,
  ...rest
}: CommodityCodeAutocompleteHookProps<
  Multiple,
  DisableClearable
>): CommodityCodeAutocompleteProps<Multiple, DisableClearable> {
  const [
    searchQuery,
    { data: { commodityCodes: options } = { commodityCodes: [] }, loading },
  ] = useGetCommodityCodesListLazy({
    fetchPolicy: "network-only",
  });

  const debouncedSearch = useDebounceCallback((search?: string) => {
    searchQuery({
      variables: {
        search,
        limit: 100,
      },
    });
  }, 500);

  return {
    options,
    isOptionEqualToValue: (option, value) =>
      option.commodityCode === value.commodityCode,
    getOptionLabel: (option) =>
      [option.commodityCode, option.descriptionDe]
        .filter((el) => el)
        .join("\n"),
    renderOption: (props, option) => (
      <Box
        {...props}
        component="li"
        sx={{
          gap: 1,
          opacity: "1!important",
        }}
      >
        <Typography variant="subtitle1" sx={{ minWidth: 70, maxWidth: 70 }}>
          {option.commodityCode}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            ...(!option.commodityCode && {
              fontWeight: "bold",
            }),
          }}
        >
          {option.hierarchyVisual + " " + option.descriptionDe}
        </Typography>
      </Box>
    ),
    getOptionDisabled: (option) => !option.commodityCode,
    loading,
    onOpen: () => {
      debouncedSearch();
    },
    onInputChange: (_, newInputValue, reason) => {
      if (reason === "input") debouncedSearch(newInputValue);
    },
    filterSelectedOptions: true,
    filterOptions: (x) => x,
    InputProps: {
      multiline: true,
      ...InputProps,
    },
    ...rest,
  };
}
