import React, { useMemo, useCallback, useState, FC } from "react";
import {
  Search,
  Button,
  Title,
  IconButton,
  AlertDialog,
  ActionBar,
  ActionBarItem,
  Grid,
  Box,
  useMediaQuery,
  Theme,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
} from "@periplus/ui-library";
import Table from "components/Table/Table";
import useGetUsers from "graphql/hooks/useGetUsersKcAdmin";
import UserFormDialog from "./UserFormDialog";
import Text from "components/Text/Text";
import { useTranslation } from "react-i18next";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { DELETE_USER } from "graphql/mutations/user";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import PersonAddIcon from "@mui/icons-material/PersonAddOutlined";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";

import useGetOrgIds from "graphql/hooks/useGetOrgIds";
import useGetTenantSettings from "graphql/hooks/useGetTenantSettings";
import { Tenant } from "domain/tenant/type";
import { makeStyles } from 'tss-react/mui';

interface UsersTabProps {
  tenant: Tenant;
}

const useStyles = makeStyles()((theme: Theme) => ({
  tableHeader: {
    fontWeight: 500,
    fontSize: 14,
  },
  tableCell: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: 14,
  },
  container: {
    "&:not(:last-child)": {
      marginBottom: 16,
    },
  },
}));

const UsersTab: FC<UsersTabProps> = ({ tenant }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const [deleteUser] = useMutation(DELETE_USER);

  const [selectedUser, setSelectedUser] = useState<any>();
  const [isOpenFormDialog, setIsOpenFormDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [search, setSearch] = useState("");

  const columnDefs = useMemo(
    () => [
      {
        Header: <Text className={classes.tableHeader}>common:firstName</Text>,
        accessor: "firstName",
        style: {
          minWidth: 150,
        },
        Cell: ({ cell }: any) => (
          <div className={classes.tableCell} title={cell.value}>
            {cell.value}
          </div>
        ),
      },
      {
        Header: <Text className={classes.tableHeader}>common:lastName</Text>,
        accessor: "lastName",

        Cell: ({ cell }: any) => (
          <div className={classes.tableCell} title={cell.value}>
            {cell.value}
          </div>
        ),
      },
      {
        Header: <Text className={classes.tableHeader}>common:location</Text>,
        accessor: "location",
      },
      {
        Header: <Text className={classes.tableHeader}>common:roles</Text>,
        accessor: "roles",
        Cell: ({ cell }: any) => (
          <div>
            {cell.value?.map((role: string) => t(`roles:${role}`)).join(", ")}
          </div>
        ),
      },
      {
        Header: <Text className={classes.tableHeader}>common:email</Text>,
        accessor: "email",

        Cell: ({ cell }: any) => (
          <div className={classes.tableCell} title={cell.value}>
            {cell.value}
          </div>
        ),
      },
      {
        Header: <Text className={classes.tableHeader}>common:actions</Text>,
        accessor: "actions",
        disableSortBy: true,
        style: {
          width: "115px",
        },
        Cell: ({ row: { original } }: { row: { original: any } }) => {
          return (
            <>
              <IconButton
                onClick={() => {
                  setSelectedUser(original);
                  setIsOpenFormDialog(true);
                }}
                title={t("common:edit")}
                size="large"
              >
                <CreateOutlinedIcon color="action" />
              </IconButton>
              <IconButton
                onClick={() => {
                  setSelectedUser(original);
                  setIsOpenDeleteDialog(true);
                }}
                title={t("common:delete")}
                size="large"
              >
                <DeleteOutlineIcon color="action" />
              </IconButton>
            </>
          );
        },
      },
    ],
    [classes.tableCell, classes.tableHeader, t]
  );

  const limit = useMemo(
    () =>
      Number(
        localStorage.getItem("userManagment_itemsPerPage") ||
          TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0]
      ),
    []
  );

  const onChangeItemsPerPage = (itemsPerPage: number) => {
    localStorage.setItem("userManagment_itemsPerPage", itemsPerPage.toString());
  };

  const {
    data: { users, total_rows: usersCount },
    loading,
    loadData,
    refetch,
  } = useGetUsers({ limit, tenants: `{${tenant.id}}` });
  const { data: tenantConfig } = useGetTenantSettings();

  const handleAdd = useCallback(() => {
    setIsOpenFormDialog(true);
  }, []);

  const handleCloseUserFormDialog = useCallback(() => {
    setSelectedUser(undefined);
    setIsOpenFormDialog(false);
  }, []);

  const handleConfirmUserFormDialog = useCallback(() => {
    refetch();
    setSelectedUser(undefined);
    setIsOpenFormDialog(false);
  }, [refetch]);

  const handleCloseDeleteDialog = useCallback(() => {
    setSelectedUser(undefined);
    setIsOpenDeleteDialog(false);
  }, []);

  const handleConfirmDeleteDialog = useCallback(() => {
    deleteUser({
      variables: {
        id: selectedUser && selectedUser.id,
      },
    })
      .then(() => refetch())
      .catch((e) => {
        enqueueSnackbar(t("common:serverError"), { variant: "error" });
      });
    setSelectedUser(undefined);
    setIsOpenDeleteDialog(false);
  }, [t, enqueueSnackbar, deleteUser, refetch, selectedUser]);

  const { data: orgIds } = useGetOrgIds(tenant.id);

  return (
    <Grid item container direction="column" className={classes.container}>
      {isOpenFormDialog && (
        <UserFormDialog
          tenantConfig={tenantConfig}
          orgIds={orgIds}
          user={selectedUser}
          tenant={tenant}
          onClose={handleCloseUserFormDialog}
          onConfirm={handleConfirmUserFormDialog}
        />
      )}
      {isOpenDeleteDialog && (
        <AlertDialog
          onClose={handleCloseDeleteDialog}
          onConfirm={handleConfirmDeleteDialog}
          variant="warning"
        >
          {t(`usersManagment:deleteDialogMessage`)}
        </AlertDialog>
      )}
      <ActionBar justifyContent="space-between">
        <ActionBarItem>
          <Title
            subTitle={t("tenant:usersPanelSubTitle")}
            Icon={SupervisedUserCircleOutlinedIcon}
          >
            {t("users")}
          </Title>
        </ActionBarItem>
        <ActionBarItem>
          <Search
            label={t("common:search") + "..."}
            variant="outlined"
            onChange={setSearch}
            style={{ width: 180 }}
          />
          {tenantConfig?.AllowAddUserInUserManagement?.value && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleAdd}
              endIcon={isTablet && <PersonAddIcon />}
              style={{
                marginLeft: 10,
              }}
            >
              {isTablet ? t("addUser") : <PersonAddIcon />}
            </Button>
          )}
        </ActionBarItem>
      </ActionBar>
      <Box
        style={{
          marginTop: 15,
          display: "flex",
          flexDirection: "column",
          gap: 16,
        }}
      >
        <Table
          columnDefs={columnDefs}
          rowData={users}
          itemCount={usersCount}
          initialItemsPerPage={limit}
          loadMore={loadData}
          loading={loading}
          onChangeItemsPerPage={onChangeItemsPerPage}
          search={search}
        />
      </Box>
    </Grid>
  );
};

export default UsersTab;
