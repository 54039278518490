import React, { useState, useMemo, useCallback } from "react";
import {
  AlertDialog,
  List,
  ListItemText,
  Collapse,
  IconButton,
  InputBase,
  ListItem,
  ListItemIcon,
} from "@periplus/ui-library";
import {
  Folder,
  ArrowRight,
  ArrowDropDown,
  AddCircleOutlineRounded,
  DeleteOutline,
} from "@mui/icons-material";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { noop } from "lodash";
import { DocumentTypeFlag } from "domain/documentType/types";
import { DocumentType } from "domain/documentType/types";
import { Document } from "hooks/useClassifier";
import FileItem from "./FileItem";
import { makeStyles } from "tss-react/mui";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export interface IFileTreeMNRProps {
  data?: any[];
  canAddFolder?: boolean;
  className?: any;
  emptyFolder?: boolean;
  activeFileNumber?: string;
  listRef?: any;
  addDocument?(
    document: {
      pages: string[];
      document_type: string;
      document_flags: number;
    },
    fileNumber: string
  ): void;
  removeDocument?(id: string, fileNumber: string): void;
  onAddClick?(node: any): void;
  onEdit: (fileNumber: string) => void;
  onDelete?(
    fileNumber: string,
    document?: {
      id: string;
      document_type: string;
    }
  ): void;
  onToggleCustoms?(doc: any, toggleStatus: boolean): void;
  onSelect?(activeFileNumber: string): void;
  onDocumentSelect?(activeDocument: string): void;
  selectedDocument?: string;
  withColor?: boolean;
  withoutFolder?: boolean;
  documentTypes: DocumentType[];
  onMoveDocumentBetweenFiles(
    doc: any,
    fromFileNumber: string,
    toFileNumber: string
  ): void;
  onToggleAIRequest?(doc: any, toggleStatus: boolean): void;
}
interface ICollapserProps {
  label: any;
  key: string;
  file: string;
  children?: any;
  open?: boolean;
  style?: any;
  onClick?: (event: React.MouseEvent) => void;
  onAddClick?: (event: React.MouseEvent) => void;
  onEdit(fileNumber: string): void;
  onDelete?: (fileNumber: string) => void;
  className?: any;
}

const useStyles = makeStyles()((theme: any) => ({
  fullWidth: {
    width: "100%",
  },

  fullHeight: {
    height: "100%",
    overflowY:"auto",

  },

  nested: {
    paddingLeft: theme.spacing(2),
  },

  folder: {
    "& ul": {
      paddingLeft: theme.spacing(0),
    },
  },

  smallWidth: {
    minWidth: 30,
  },

  icon: {
    color: "#9e9e9e",
    fontSize: 18,
  },

  pointer: {
    cursor: "pointer",
    outline: "none",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.05)",
    },
  },

  defaultCursor: {
    cursor: "default",
    outline: "none",
  },

  listItem: {
    paddingTop: theme.spacing(0.4),
    paddingBottom: theme.spacing(0.4),
    "&:hover": {
      backgroundColor: "#0001",
    },
  },

  listItemText: {
    marginLeft: theme.spacing(1),
  },

  listItemActive: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0001",
    },
  },

  fileDraggingOver: {
    background: "rgb(0 0 0 / 4%)",
  },

  listItemDisabled: {
    opacity: 0.5,
  },

  listItemIcon: {
    minWidth: 56,
  },
}));

function Collapser({
  label,
  file,
  children,
  style,
  open,
  onEdit,
  onDelete,
  onClick,
  className,
}: ICollapserProps) {
  const {
    classes: { pointer, icon, listItemIcon },
  } = useStyles();
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState(false);

  const isOpen = open !== undefined ? open : collapse; // when open prop is defined only on collapser could be open at one time

  const handleClick = useCallback(
    (e: any) => {
      onClick && onClick(e);
      setCollapse((prevCollapse) => !prevCollapse);
    },
    [onClick]
  );

  return (
    <>
      <ListItem
        onClick={handleClick}
        className={pointer}
        style={{ ...style, height: 50 }}
        tabIndex={0}
        sx={{ px: 1 }}
      >
        <ListItemIcon className={listItemIcon}>
          <>
            {isOpen ? <ArrowDropDown /> : <ArrowRight />}
            <Folder />
          </>
        </ListItemIcon>
        <ListItemText>
          <InputBase style={{ fontSize: 14 }} value={label} />
        </ListItemText>
        <IconButton
          tooltip={t("Edit")}
          onClick={(e) => {
            e.stopPropagation();
            onEdit(file);
          }}
          sx={{ ml: 0.5 }}
        >
          <EditOutlinedIcon className={icon} />
        </IconButton>
        {onDelete && (
          <IconButton
            className={icon}
            tooltip={t("Delete")}
            onClick={(e) => {
              e.stopPropagation();
              onDelete(file);
            }}
            sx={{ ml: 0.5 }}
          >
            <DeleteOutline className={icon} />
          </IconButton>
        )}
      </ListItem>
      {children && (
        <Collapse
          in={isOpen}
          timeout="auto"
          unmountOnExit
          className={className}
        >
          {children}
        </Collapse>
      )}
    </>
  );
}

function FileTree({
  data = [],
  canAddFolder = true,
  className,
  onAddClick,
  onEdit,
  onDelete,
  onSelect,
  onToggleCustoms,
  activeFileNumber,
  listRef,
  onDocumentSelect,
  selectedDocument,
  withColor = false,
  withoutFolder = false,
  documentTypes,
  onMoveDocumentBetweenFiles,
  onToggleAIRequest,
}: IFileTreeMNRProps) {
  const {
    classes: {
      fullHeight,
      fullWidth,
      folder,
      pointer,
      icon,
      defaultCursor,
      fileDraggingOver,
      listItemIcon,
    },
    cx,
  } = useStyles();
  const { t } = useTranslation();
  const [fileToDelete, setFileToDelete] = useState("");

  const triggerDelete = useCallback(
    (
      fileNumber: string,
      document?: {
        id: string;
        document_type: string;
      }
    ) => {
      onDelete && onDelete(fileNumber, document);
      setFileToDelete("");
    },
    [onDelete]
  );

  const handleActiveFolder = useCallback(
    (activeFileNumber: string) => {
      onSelect && onSelect(activeFileNumber);
    },
    [onSelect]
  );

  const handleFileName = useCallback(
    (fileNumber: string) => {
      if (!fileNumber.includes("newFileNumber")) {
        return fileNumber;
      }

      const checkNum = fileNumber.match(/\d+/g);
      const assignNum: string | null = checkNum && checkNum[0];
      const correctNumber = assignNum === null ? "" : parseFloat(assignNum) + 1;

      return `${t(`classify:newFileNumber`)} ${correctNumber}`;
    },
    [t]
  );

  const renderFiles = useCallback(
    (documents, fileNumber) => {
      const sequenceIndexes: any = {};
      return (
        <List key={fileNumber}>
          {documents.map((doc: any, index: any) => {
            const { id, document_type, pages = [] } = doc;
            const docType = documentTypes.find(
              (docType) => docType.name === document_type
            );
            const sequence = sequenceIndexes[document_type] || 0;
            sequenceIndexes[document_type] = sequence + 1;
            const sequenceIndex =
              doc.sequence || sequenceIndexes[document_type];
            const isSelected = selectedDocument === id;
            const withoutPages = pages.length === 0;

            let onItemClick = noop;
            if (onDocumentSelect && !canAddFolder && !withoutPages) {
              onItemClick = () => onDocumentSelect(doc);
            }
            const isCustomRelevant = [doc.flags, doc.document_flags].some(
              (flag) =>
                (flag & DocumentTypeFlag.custom_relevant) ===
                DocumentTypeFlag.custom_relevant
            );
            const isAIRequested = [doc.flags, doc.document_flags].some(
              (flag) =>
                (flag & DocumentTypeFlag.manual_annotation_required) ===
                DocumentTypeFlag.manual_annotation_required
            );
            if (withoutFolder) {
              return (
                <FileItem
                  key={id}
                  withColor={withColor}
                  document_type={document_type}
                  triggerDelete={triggerDelete}
                  fileNumber={fileNumber}
                  onDelete={onDelete}
                  isSelected={isSelected}
                  sequenceIndex={sequenceIndex}
                  docType={docType}
                  withoutPages={withoutPages}
                  provided={{}}
                  defaultCursor={defaultCursor}
                  id={id}
                  onItemClick={onItemClick}
                  onToggleCustoms={onToggleCustoms}
                  doc={doc}
                  isCustomRelevant={isCustomRelevant}
                  isAIRequested={isAIRequested}
                  onToggleAIRequest={onToggleAIRequest}
                />
              );
            }
            return (
              <Draggable key={id} draggableId={id} index={index}>
                {(provided, snapshot) => (
                  <FileItem
                    withColor={withColor}
                    document_type={document_type}
                    triggerDelete={triggerDelete}
                    fileNumber={fileNumber}
                    onDelete={onDelete}
                    isSelected={isSelected}
                    sequenceIndex={sequenceIndex}
                    docType={docType}
                    withoutPages={withoutPages}
                    provided={provided}
                    defaultCursor={defaultCursor}
                    id={id}
                    onItemClick={onItemClick}
                    onToggleCustoms={onToggleCustoms}
                    doc={doc}
                    isCustomRelevant={isCustomRelevant}
                    isAIRequested={isAIRequested}
                    onToggleAIRequest={onToggleAIRequest}
                  />
                )}
              </Draggable>
            );
          })}
        </List>
      );
    },
    [
      withoutFolder,
      documentTypes,
      selectedDocument,
      onDocumentSelect,
      canAddFolder,
      defaultCursor,
      onToggleCustoms,
      withColor,
      onDelete,
      triggerDelete,
    ]
  );

  const renderFolder = useCallback(
    (node) => {
      const { fileNumber } = node;
      const fileDisplayName = handleFileName(fileNumber);
      const isActive = activeFileNumber === fileNumber;
      return (
        <Droppable key={fileNumber} droppableId={fileNumber}>
          {(provided, snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                className={snapshot.isDraggingOver ? fileDraggingOver : ""}
              >
                <Collapser
                  open={isActive}
                  key={fileNumber}
                  label={fileDisplayName}
                  file={fileNumber}
                  onClick={() => handleActiveFolder(fileNumber)}
                  onAddClick={() => {
                    onAddClick && onAddClick(node);
                  }}
                  style={{
                    backgroundColor: isActive ? "rgba(0,0,0,.1)" : "initial",
                  }}
                  className={folder}
                  onEdit={onEdit}
                  {...(canAddFolder
                    ? {
                        onDelete: () => {
                          if (node.documents.length > 1) {
                            setFileToDelete(fileNumber);
                            return;
                          }
                          triggerDelete(fileNumber);
                        },
                      }
                    : {})}
                >
                  {renderFiles(node.documents || [], fileNumber)}
                  {provided.placeholder}
                </Collapser>
              </div>
            );
          }}
        </Droppable>
      );
    },
    [
      activeFileNumber,
      folder,
      fileDraggingOver,
      handleActiveFolder,
      handleFileName,
      onAddClick,
      renderFiles,
      triggerDelete,
    ]
  );

  const folders = useMemo(
    () =>
      data.map((data: any) => {
        if (withoutFolder) {
          return renderFiles(data.documents || [], data.fileNumber);
        }

        return renderFolder(data);
      }),
    [data, renderFiles, renderFolder, withoutFolder]
  );

  const onDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;
    // dropped inside the list
    if (!destination || source.droppableId === destination.droppableId) {
      return;
    }

    const file = data.find(
      (item: any) => item.fileNumber === source.droppableId
    );

    const document = file.documents.find(
      (item: Document) => item.id === draggableId
    );

    if (!document) {
      return;
    }

    onMoveDocumentBetweenFiles(
      document,
      source.droppableId,
      destination.droppableId
    );
  };

  return (
    <>
      {fileToDelete && (
        <AlertDialog
          variant="warning"
          onClose={() => setFileToDelete("")}
          onConfirm={() => {
            triggerDelete(fileToDelete);
          }}
        >
          {t("classify:deleteDialog")}
        </AlertDialog>
      )}
      <List
        dense
        disablePadding
        className={cx(fullWidth, fullHeight, className)}
        ref={listRef}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          {canAddFolder && (
            <Droppable key={"add"} droppableId={"add"}>
              {(provided, snapshot) => {
                return (
                  <div
                    ref={provided.innerRef}
                    className={snapshot.isDraggingOver ? fileDraggingOver : ""}
                  >
                    <ListItem
                      onClick={onAddClick}
                      style={{ opacity: 0.7, height: 50 }}
                      className={pointer}
                      sx={{ px: 1 }}
                    >
                      <ListItemIcon className={listItemIcon}>
                        <>
                          <ArrowRight />
                          <Folder />
                        </>
                      </ListItemIcon>
                      <ListItemText>{t("classify:nextFile")}</ListItemText>
                      <IconButton size="small">
                        <AddCircleOutlineRounded className={icon} />
                      </IconButton>
                    </ListItem>
                  </div>
                );
              }}
            </Droppable>
          )}

          {!activeFileNumber && (
            <ListItem
              className={pointer}
              style={{
                opacity: 0.7,
                backgroundColor: "rgba(0,0,0,.1)",
                height: 50,
              }}
              sx={{ px: 1 }}
            >
              <ListItemIcon className={listItemIcon}>
                <>
                  <ArrowRight />
                  <Folder />
                </>
              </ListItemIcon>
              <ListItemText>...</ListItemText>
            </ListItem>
          )}

          {folders}
        </DragDropContext>
      </List>
    </>
  );
}

export default FileTree;
