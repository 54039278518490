import React, { ReactNode, useMemo, useState } from "react";
import {
  MRTable,
  Typography,
  MuiTable,
  MuiTableHead,
  MuiTableRow,
  MuiTableCell,
  MuiTableBody,
  IconButton,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import useGetAiConfirmedDeclarations, {
  AiConfirmedDeclarationEntity,
} from "./useGetAiConfirmedDeclarations";
import { AIIgnoreRetrainingCheckbox } from "./AIIgnoreRetrainingCheckbox";
import { AIConfirmedCheckbox } from "./AIConfirmedCheckbox";
import PagesPreview from "./PagesPreview";
import { Link } from "react-router-dom";
import { Launch } from "@mui/icons-material";

export default () => {
  const { t } = useTranslation();

  const {
    data: { declarations },
    loading,
    refetch,
  } = useGetAiConfirmedDeclarations();

  const [columns, tableData] = useMemo<
    [
      MRTable.MRT_ColumnDef<AiConfirmedDeclarationEntity>[],
      AiConfirmedDeclarationEntity[]
    ]
  >(
    () => [
      [
        {
          header: t("Tenant Name"),
          id: "tenant_name",
          accessorFn: (originalRow) =>
            originalRow.declaration_request?.payer.tenant_name,
          filterVariant: "select",
        },
        {
          header: t("File ID"),
          id: "file_id",
          accessorFn: (originalRow) => originalRow.file_id,
        },
        {
          header: t("DR No"),
          id: "dr_no",
          accessorFn: (originalRow) => originalRow.dr_no,
        },
        {
          header: t("AI Confirmed"),
          id: "ai_confirmed",
          accessorFn: (originalRow) => originalRow.ai_confirmed,
          Cell: ({ row: { original: originalRow } }) => (
            <AIConfirmedCheckbox
              fileId={originalRow.file_id}
              drNo={originalRow.dr_no}
              initialChecked={originalRow.ai_confirmed!}
            />
          ),
        },
        {
          header: t("Supplier"),
          id: "supplier",
          accessorFn: (originalRow) =>
            originalRow.declaration_request?.declaration_request_addresses[0]
              ?.address?.company_name,
        },
      ],
      [...declarations],
    ],
    [t, declarations]
  );

  const [columnOrder, setColumnOrder] = useState<MRTable.MRT_ColumnOrderState>([
    "mrt-row-expand",
    ...columns.map((c) => c.id!),
  ]);

  return (
    <MRTable.Table
      columns={columns}
      data={tableData}
      state={{
        isLoading: loading,
        columnOrder,
      }}
      onRefresh={() => refetch()}
      onColumnOrderChange={setColumnOrder}
      renderTopToolbarCustomActions={() => (
        <Typography variant="h2" sx={{ alignSelf: "center" }}>
          {t("Ai Confirmed Declarations")}
        </Typography>
      )}
      muiTablePaperProps={{
        sx: { mt: 1.5 },
      }}
      muiTableContainerProps={{
        sx: {
          maxHeight: "calc(100vh - 270px)",
        },
      }}
      enableRowActions
      enableFacetedValues
      renderRowActions={({ row: { original } }) => (
        <Link
          to={`/select/declaration/${encodeURIComponent(original.file_id)}/${
            original.dr_no
          }`}
          target="_blank"
          style={{
            textDecoration: "none",
          }}
        >
          <IconButton color="primary">
            <Launch />
          </IconButton>
        </Link>
      )}
      enableColumnFilters
      renderDetailPanel={({ row: { original: originalRow } }) => (
        <MuiTable sx={{ width: "100%" }} size="small">
          <MuiTableHead>
            <MuiTableRow>
              <MuiTableCell sx={{ backgroundColor: "#F3F4FB" }}>
                {t("Page Id")}
              </MuiTableCell>
              <MuiTableCell sx={{ backgroundColor: "#F3F4FB" }}>
                {t("Thumbnail")}
              </MuiTableCell>
              <MuiTableCell sx={{ backgroundColor: "#F3F4FB" }}>
                {t("AI ignore")}
              </MuiTableCell>
            </MuiTableRow>
          </MuiTableHead>
          <MuiTableBody>
            {originalRow.declaration_request?.documents.reduce(
              (acc1, document) => [
                ...acc1,
                ...document.pages_m2m.reduce((acc2, { page }) => {
                  if (!page) return acc2;
                  return [
                    ...acc2,
                    <MuiTableRow
                      key={page.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <MuiTableCell component="th" scope="row">
                        {page.id}
                      </MuiTableCell>
                      <MuiTableCell>
                        <PagesPreview page={page} />
                      </MuiTableCell>
                      <MuiTableCell>
                        <AIIgnoreRetrainingCheckbox
                          id={page.id}
                          initialChecked={page.ai_ignore_retraining!}
                        />
                      </MuiTableCell>
                    </MuiTableRow>,
                  ];
                }, [] as ReactNode[]),
              ],
              [] as ReactNode[]
            )}
          </MuiTableBody>
        </MuiTable>
      )}
      muiDetailPanelProps={{
        sx: {
          padding: "0px!important",
          width: "100%",
          "& .MuiCollapse-root": {
            width: "100%",
          },
        },
      }}
      displayColumnDefOptions={{
        "mrt-row-actions": {
          muiTableBodyCellProps: {
            sx: {
              textAlign: "center",
            },
          },
        },
      }}
    />
  );
};
