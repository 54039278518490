import { useState, RefObject, useLayoutEffect } from "react";
import useResizeObserver from "@react-hook/resize-observer";

function useGetElementClientRect<T extends HTMLElement>(
  target: T | RefObject<T> | null
) {
  const [size, setSize] = useState<ClientRect>();

  useLayoutEffect(() => {
    if (!target) return;
    const node = "current" in target ? target.current : target;
    if (node) {
      setSize(node.getBoundingClientRect());
    }
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
}

export default useGetElementClientRect;
