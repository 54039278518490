import React from "react";
import { Grid, Typography, Theme } from "@periplus/ui-library";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getExtensionDescription } from "pages/Select/extensionDescription";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: "100%",
    width: 24,

    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shortest,
    }),

    [theme.breakpoints.up("md")]: {
      width: 198,
    },
  },
  titleContainer: {
    width: "100%",
  },
  details: {
    flexGrow: 1,
    "& > *": {
      marginBottom: 4,
    },

    [theme.breakpoints.up("xs")]: {
      visibility: "hidden",
    },

    [theme.breakpoints.up("md")]: {
      visibility: "visible",
    },
  },
  title: {
    color: "#604DFF",
    marginBottom: 12,
    transformOrigin: "top left",

    transition: theme.transitions.create(["transform", "width"], {
      duration: theme.transitions.duration.shortest,
    }),

    [theme.breakpoints.up("xs")]: {
      transform: "translate(29px, 0) rotate(90deg)",
      width: 194,
    },

    [theme.breakpoints.up("md")]: {
      transform: "initial",
      width: "auto",
    },
  },
  description: {
    fontSize: "0.6250rem",
    lineHeight: 1.5,
    textTransform: "uppercase",
    color: "#737A96",
  },
  value: {
    color: "#4F4F4F",
  },
  status: {
    width: "100%",
    display: "flex",

    "& #dot": {
      "& > span, & > p, & > div": {
        fontWeight: 500,
      },
    },

    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      "& #dot": {
        paddingLeft: 0,

        "& > span": {
          display: "none",
        },
      },
    },
  },
}));

const DocumentDetails = ({ details }: any) => {
  const { classes } = useStyles();
  const { name, creationDate, org_id_description, status, extension, pages } =
    details;

  const { t } = useTranslation();

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.titleContainer}>
        <Typography
          variant="h6"
          color="primary"
          noWrap
          className={classes.title}
          title={name}
        >
          {name}
        </Typography>
      </Grid>
      <Grid item className={classes.details}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="caption" className={classes.description}>
            {t("common:date")}
          </Typography>
          <Typography className={classes.value}>
            {creationDate && dayjs(creationDate).format("DD.MM.YYYY")}
          </Typography>
        </Grid>

        {org_id_description && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="caption" className={classes.description}>
              {t("common:orgId")}
            </Typography>
            <Typography className={classes.value}>
              {org_id_description}
            </Typography>
          </Grid>
        )}
        {pages && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="caption" className={classes.description}>
              {t("common:pages")}
            </Typography>
            <Typography className={classes.value}>
              {pages && pages.length}
            </Typography>
          </Grid>
        )}
        {extension && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="caption" className={classes.description}>
              {t("common:type")}
            </Typography>
            <Typography className={classes.value}>
              {getExtensionDescription(extension)}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item className={classes.status}>
        {status}
      </Grid>
    </Grid>
  );
};

export default DocumentDetails;
