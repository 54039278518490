import React from "react";
import {
  Typography,
  MuiTable,
  MuiTableHead,
  MuiTableRow,
  MuiTableCell,
  MuiTableBody,
  styled,
  ThreeDotsMenu,
  ListItemText,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import useGetDeclarationDocumentsTable from "./hooks/useGetDeclarationDocumentsTable";
import dayjs from "dayjs";
import Pages from "./Pages";
import { getDownloadUrlAsync } from "utils/azureBlobStorageUtils";

interface Props {
  fileId: string;
}

const TableHeaderCell = styled(MuiTableCell)({
  backgroundColor: "#F3F4FB",
  whiteSpace: "nowrap",
  width: "1%",
});
const TableCell = styled(MuiTableCell)({
  width: "1%",
  background: "white!important",
});

export default ({ fileId }: Props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { data: { documents } = { documents: [] }, loading } =
    useGetDeclarationDocumentsTable({
      fetchPolicy: "no-cache",
      variables: { fileId },
    });

  if (loading || !documents.length)
    return (
      <Typography
        color="textSecondary"
        sx={{
          p: 1.5,
        }}
      >
        {loading ? `${t("Loading")}...` : t("No documents")}
      </Typography>
    );

  return (
    <MuiTable
      sx={{
        width: "100%",
      }}
      size="small"
    >
      <MuiTableHead>
        <MuiTableRow>
          <TableHeaderCell>{t("Document Type")}</TableHeaderCell>
          <TableHeaderCell>{t("Sequence")}</TableHeaderCell>
          <TableHeaderCell>{t("Preview")}</TableHeaderCell>
          <TableHeaderCell>{t("Pages")}</TableHeaderCell>
          <TableHeaderCell>{t("Validated")}</TableHeaderCell>
          <TableHeaderCell>{t("Status")}</TableHeaderCell>
          <TableHeaderCell>{t("Last Update Date")}</TableHeaderCell>
          <TableHeaderCell sx={{ width: "auto" }}>{t("User")}</TableHeaderCell>
          <TableHeaderCell></TableHeaderCell>
        </MuiTableRow>
      </MuiTableHead>
      <MuiTableBody>
        {documents.map((document) => (
          <MuiTableRow>
            <TableCell>{document.documentTypeTranslations[language]}</TableCell>
            <TableCell>{document.sequence}</TableCell>
            <TableCell>
              <Pages document={document} />
            </TableCell>
            <TableCell>{document.pages.length}</TableCell>
            <TableCell>{}</TableCell>
            <TableCell>
              {t(`document_status:${document.documentStatus}`)}
            </TableCell>
            <TableCell>
              {document.lastModifiedDate
                ? dayjs(document.lastModifiedDate).format("DD.MM.YYYY HH:mm:ss")
                : ""}
            </TableCell>
            <TableCell sx={{ width: "auto" }}>
              {document.creationUser}
            </TableCell>
            <TableCell
              sx={{
                position: "sticky",
                right: 0,
                background: "white",
                minWidth: 63,
                maxWidth: 63,
              }}
            >
              {!!document.blobs?.length && (
                <ThreeDotsMenu
                  options={document.blobs?.map((blob) => ({
                    content: (
                      <ListItemText
                        primary={
                          t("Download") +
                          " " +
                          ({
                            doc: "doc",
                            docx: "doc",
                            xls: "excel",
                            xlsx: "excel",
                          }[blob.extension] ?? blob.extension)
                        }
                        sx={{
                          textTransform: "capitalize",
                        }}
                      />
                    ),
                    action: async (close) => {
                      window.open(
                        await getDownloadUrlAsync(
                          blob.url,
                          `application/${blob.extension}`,
                          `inline; filename="${encodeURI(
                            document.documentName
                          )}.${blob.extension}"`
                        ),
                        "_blank"
                      );
                      close();
                    },
                  }))}
                />
              )}
            </TableCell>
          </MuiTableRow>
        ))}
      </MuiTableBody>
    </MuiTable>
  );
};
