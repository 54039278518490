import React, { useCallback, useMemo, useState } from "react";
import {
  ThreeDotsMenu,
  ListItemIcon,
  ListItemText,
  Theme,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
} from "@periplus/ui-library";
import Table from "components/Table";
import SettingsIcon from "@mui/icons-material/Settings";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import useGetAddressType from "graphql/hooks/useGetAddressType";
import useGetAddress, { Address } from "graphql/hooks/useGetAddress";
import AddressDialog from "./AddressDialog";
import useDeleteAddress from "graphql/hooks/useDeleteAddress";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { TextNaked } from "components/Text";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
  },
  threeDotMenuIcon: {
    minWidth: "auto",
    paddingRight: theme.spacing(1),
  },
}));

const headerTranslation = (
  type: string,
  value: string,
  size: number = 16,
  weight: number = 400
) => {
  return (
    <TextNaked
      style={{ fontWeight: weight, fontSize: size, userSelect: "none" }}
    >
      {type}:{value}
    </TextNaked>
  );
};

const columns = (
  onEdit: (address: Address) => void,
  onDelete: (id: number) => void
) => [
  {
    Header: () => headerTranslation("common", "company"),
    accessor: "company_name",
  },
  {
    Header: () => headerTranslation("common", "address"),
    accessor: "address",
    disableSortBy: true,
    Cell: ({ row }: { row: { original: Address } }) => {
      const { street, street_number } = row.original;
      return `${street} ${street_number}`;
    },
  },
  {
    Header: () => headerTranslation("common", "zip"),
    accessor: "zipcode",
  },
  {
    Header: () => headerTranslation("common", "city"),
    accessor: "city",
  },
  {
    Header: () => headerTranslation("common", "country"),
    accessor: "country",
  },
  {
    Header: () => (
      <div>
        <SettingsIcon />{" "}
      </div>
    ),
    accessor: "actions",
    disableSortBy: true,
    Cell: ({ row }: { row: { original: Address } }) => {
      const { classes } = useStyles();
      const options = [
        {
          content: (
            <>
              <ListItemIcon className={classes.threeDotMenuIcon}>
                <EditOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </>
          ),
          onClick: () => {
            onEdit(row.original);
          },
        },
        {
          content: (
            <>
              <ListItemIcon className={classes.threeDotMenuIcon}>
                <DeleteOutlineOutlinedIcon color="error" />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </>
          ),
          className: classes.deleteButton,
          onClick: () => {
            onDelete(row.original.id);
          },
        },
      ];

      return <ThreeDotsMenu options={options} />;
    },
  },
];

type AddressManagementTableProps = {
  addressToEdit?: Address | null;
  selectAddressToEdit: (address?: Address | null) => void;
  page: number;
  search?: string;
  onChangePage: (newPage: number) => void;
};

const addressManagementItemsPerPage = "pp_address_management_items_per_page";

const AddressManagementTable = ({
  addressToEdit,
  selectAddressToEdit,
  page,
  search,
  onChangePage,
}: AddressManagementTableProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [selectedAddresses, setSelectedAddress] = useState<Address[]>([]);
  const itemsPerPage = Number(
    localStorage.getItem(addressManagementItemsPerPage) ||
      TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0]
  );

  const {
    data: { addresses, qty },
    loading,
    refetch,
  } = useGetAddress({
    search,
    offset: (page - 1) * itemsPerPage,
    limit: itemsPerPage,
  });

  const {
    data: { address_type },
  } = useGetAddressType();

  const deleteAddress = useDeleteAddress();

  const onChangeItemsPerPage = (items: number) => {
    localStorage.setItem(addressManagementItemsPerPage, items.toString());
  };

  const toggleSelect = useCallback(
    ({ selecting = [], deselecting = [] }) => {
      if (selecting.length === 0 && deselecting.length === 0) {
        return;
      }

      setSelectedAddress((previouslySelectedAddresses) => {
        const filteredAddresses = previouslySelectedAddresses.filter(
          (selectedAddress) =>
            !deselecting.some(
              (address: Address) => address.id === selectedAddress.id
            )
        );

        return [...filteredAddresses, ...selecting];
      });
    },
    [selectedAddresses]
  );

  const handleCloseDialog = () => {
    refetch({
      search,
      offset: (page - 1) * itemsPerPage,
      limit: itemsPerPage,
    });
    selectAddressToEdit(undefined);
  };

  const changePage = useCallback(
    (offset) => {
      onChangePage(offset / itemsPerPage + 1);
    },
    [itemsPerPage, onChangePage]
  );

  const handleDelete = useCallback(
    (id: number) => {
      deleteAddress(id)
        .then(() => {
          enqueueSnackbar(t("common:addressDeleted"), { variant: "success" });

          refetch({
            search,
            offset: 0,
            limit: itemsPerPage,
          });
        })
        .catch(() => {
          enqueueSnackbar(t("error:serverError"), { variant: "error" });
        });
    },
    [refetch, deleteAddress, itemsPerPage, search]
  );

  const tableColumns = useMemo(
    () => columns(selectAddressToEdit, handleDelete),
    [selectAddressToEdit, handleDelete]
  );

  return (
    <>
      {addressToEdit !== undefined && (
        <AddressDialog
          address={addressToEdit}
          addressTypes={address_type}
          onClose={handleCloseDialog}
          onConfirm={handleCloseDialog}
        />
      )}

      <Table
        initialPage={page}
        columnDefs={tableColumns}
        rowData={addresses}
        onToggle={toggleSelect}
        data-pp="documents-table"
        itemCount={qty}
        loadMore={changePage}
        loading={loading}
        selectedCount={selectedAddresses.length}
        initialItemsPerPage={itemsPerPage}
        onChangeItemsPerPage={onChangeItemsPerPage}
        isRowSelected={(rowData: { id: number }) =>
          selectedAddresses.some((address) => address.id === rowData.id)
        }
      />
    </>
  );
};

export default AddressManagementTable;
