import React, { FC, useMemo, useState, useCallback, useEffect } from "react";
import {
  PageContainer,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
  Typography,
} from "@periplus/ui-library";
import { SelectedDocumentType } from "pages/Select/types";
import dayjs from "dayjs";
import useGetFilesSubscription from "graphql/hooks/useGetFilesSubscription";
import { useAuth } from "contexts/AuthContext";
import { Role } from "rbac-rules";
import usePageLocalStorage from "hooks/usePageLocalStorage";
import DocumentsExpandedRowView from "./DocumentsExpandedRowView";
import FilesTable from "./FilesTable";
import useRossumValidatedDocId from "hooks/useRossumValidatedDocId";
import useUrlSearchParams from "hooks/useUrlSearchParams";
import { useTranslation } from "react-i18next";

interface FinishedProps {}

const Annotation: FC<FinishedProps> = () => {
  const { t } = useTranslation();
  const [selectedDocuments, setSelectedDocument] = useState<
    SelectedDocumentType[]
  >([]);

  const { user, tenantConfig } = useAuth();
  const isAlphaTester = user?.allowedRoles?.includes(Role.AlphaTester);
  useRossumValidatedDocId();
  const { pageLocalStorage, setPageLocalStorage } = usePageLocalStorage();

  const defaultFilters = useMemo(
    () => ({
      startDate: dayjs().subtract(2, "week").startOf("day").format(),
      endDate: dayjs().endOf("day").format(),
      ...(tenantConfig?.UseJourneys?.value && {
        files: true,
        journey: true,
      }),
      myFilesOnly: false,
      status: [],
      orgIds: [],
    }),
    [tenantConfig?.UseJourneys?.value]
  );

  const { urlSearchParams } = useUrlSearchParams({
    ...defaultFilters,
    ...pageLocalStorage.filters,
  });

  useEffect(() => {
    setPageLocalStorage({
      filters: {
        ...(urlSearchParams.startDate !== defaultFilters.startDate && {
          startDate: urlSearchParams.startDate,
        }),
        ...(urlSearchParams.endDate !== defaultFilters.endDate && {
          endDate: urlSearchParams.endDate,
        }),
        files: urlSearchParams.files,
        journey: urlSearchParams.journey,
        myFilesOnly: urlSearchParams.myFilesOnly,
        status: urlSearchParams.status,
        orgIds: urlSearchParams.orgIds,
      },
    });
  }, [urlSearchParams, defaultFilters]);

  const toggleSelect = useCallback(
    ({ selecting = [], deselecting = [] }) => {
      if (selecting.length === 0 && deselecting.length === 0) {
        return;
      }

      const filteredDocuments = selectedDocuments.filter(
        (document) =>
          !deselecting.some((doc: { id: string }) => doc.id === document.id)
      );
      setSelectedDocument([...filteredDocuments, ...selecting]);
    },
    [selectedDocuments]
  );

  const limit = Number(
    localStorage.getItem("finished") ||
      TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0]
  );
  const fileData = useMemo(() => {
    const {
      startDate,
      endDate,
      journey,
      files,
      search,
      myFilesOnly,
      orgIds,
      status,
    } = urlSearchParams;

    let fileFlags = [2];

    if (tenantConfig?.UseJourneys?.value) {
      fileFlags = [];
      journey && fileFlags.push(1);
      files && fileFlags.push(0);
    }

    return {
      offset: 0,
      limit,
      currUserId: user?.userId,
      fileFlags: `{${fileFlags.join(",")}}`,
      ...(startDate && { minDate: startDate }),
      ...(endDate && { maxDate: endDate }),
      myFilesOnly: myFilesOnly,
      search,
      orgIds: orgIds?.length ? `{${orgIds.join(",")}}` : undefined,
      fileStatuses: status?.length
        ? `{${(status as string[])
            .reduce((acc, el) => {
              switch (el) {
                case "archived":
                  acc.push("archived", "created");
                  break;
                case "processing":
                  acc.push(
                    "identification_requested",
                    "transmission_pending",
                    "transmission_generated",
                    "ready_for_archive",
                    "archived_pending",
                    "pending"
                  );
                  break;
                case "failed":
                  acc.push(
                    "archived_failed",
                    "transmission_failed",
                    "identification_request_failed"
                  );
                  break;
              }
              return acc;
            }, [] as string[])
            .join(",")}}`
        : undefined,
    };
  }, [limit, urlSearchParams, user, tenantConfig?.UseJourneys?.value]);

  const { data, error, loading, fetchMore, sort } =
    useGetFilesSubscription(fileData);

  const isUserAllowedToAutoClassification = !!isAlphaTester;

  return (
    <PageContainer title={t("navigation:annotations")}>
      {error && (
        <Typography color="error" data-pp="load-data-error">
          {error.message}
        </Typography>
      )}
      <FilesTable
        files={data.files}
        itemCount={data.filesCount}
        toggleSelect={toggleSelect}
        selectedDocuments={selectedDocuments}
        loadMore={fetchMore}
        loading={loading}
        sort={sort}
        itemsPerPage={limit}
        isUserAllowedToAutoClassification={isUserAllowedToAutoClassification}
        renderExpandRowView={(row) => {
          return (
            <DocumentsExpandedRowView
              isUserAllowedToAutoClassification={
                isUserAllowedToAutoClassification
              }
              fileId={row.original.id}
            />
          );
        }}
      />
    </PageContainer>
  );
};

export default Annotation;
