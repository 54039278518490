import { gql } from "@apollo/client";
import { useMemo } from "react";
import { useQuery } from "@apollo/client";

export type PartialAddress = {
  address_type: number;
  city: string;
  contact_email: string;
  contact_phone: string;
  contact_website: string;
  country: string;
  street: string;
  street_number: string;
  zipcode: string;
  company_name: string;
  reference: string;
};

export type Address = PartialAddress & {
  id: number;
  creation_date: string;
  creation_user: string;
  last_modified_date: string;
  last_modified_user: string;
  qty: number;
};

type Response = {
  pp_addresses?: Address[];
};

const ADIT_GET_PP_ADDRESS = gql`
  query ADIT_GET_PP_ADDRESS(
    $search: String
    $offset: Int
    $limit: Int
    $address_types: _int4
  ) {
    pp_addresses(
      args: {
        _search: $search
        _offset: $offset
        _limit: $limit
        _address_types: $address_types
      }
    ) {
      company_name
      address_type
      city
      contact_email
      contact_phone
      contact_website
      country
      id
      street
      street_number
      zipcode
      reference
      creation_date
      creation_user
      last_modified_date
      last_modified_user
      qty
    }
  }
`;

type UseGetAddress = {
  search?: string | null;
  offset?: number;
  limit?: number;
  address_types?: string;
};

const useGetAddress = (variables: UseGetAddress) => {
  const result = useQuery<Response>(ADIT_GET_PP_ADDRESS, {
    variables,
    fetchPolicy: "no-cache",
  });

  return useMemo(() => {
    const { data, ...rest } = result;

    const addresses = data?.pp_addresses || [];

    return {
      ...rest,
      data: {
        addresses,
        qty: addresses[0]?.qty ?? 0,
      },
    };
  }, [result]);
};

export default useGetAddress;
