import React, { FC, useMemo } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useUpdateTenant from "graphql/hooks/useUpdateTenant";
import restApi from "rest-api/aditApi";
import { useSnackbar } from "notistack";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { ADDRESS_TYPES } from "domain/address/types";
import { Tenant } from "domain/tenant/type";

interface TenantFormikProps {
  onSubmit?: () => void;
  tenant?: Tenant;
}

const TenantFormik: FC<TenantFormikProps> = ({
  children,
  onSubmit,
  tenant,
}) => {
  const { t } = useTranslation();

  const updateTenant = useUpdateTenant();
  const { enqueueSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        tenant_name: Yup.string().trim().required().max(50),
        addressType: Yup.object().nullable().required(),
        traderIdentificationNumber: Yup.string().required(),
        country: Yup.string().trim().required().nullable().max(50),
        city: Yup.string().trim().required().max(50),
        street: Yup.string().trim().required().max(50),
        street_number: Yup.string().trim().required().max(50),
        zipcode: Yup.string().trim().required().max(50),
      }),
    [t]
  );

  const initialValues = useMemo(() => {
    const defaultInitialValues = {
      tenant_name: "",
      addressType: null,
      traderIdentificationNumber: "",
      country: null,
      city: "",
      street: "",
      street_number: "",
      zipcode: "",
    };
    if (!tenant) return defaultInitialValues;

    const {
      tenant_name,
      trader_identification_number,
      address: { country, city, street, street_number, zipcode, address_type },
    } = tenant;

    return {
      tenant_name,
      country: country === "unknown" ? defaultInitialValues.country : country,
      city,
      street,
      street_number,
      zipcode,
      traderIdentificationNumber:
        trader_identification_number ||
        defaultInitialValues.traderIdentificationNumber,
      addressType: ADDRESS_TYPES.find((el) => el.id === address_type),
    };
  }, [tenant]);

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    const onError = (error: any) => {
      enqueueSnackbar(t("common:serverError"), { variant: "error" });
      setSubmitting(false);
    };
    const onSuccess = () => {
      setSubmitting(false);
      onSubmit && onSubmit();
    };
    const castedValues = validationSchema.cast(values);
    if (!tenant) {
      executeRecaptcha?.().then((token) => {
        restApi
          .post(
            "/tenant/registerClientCompany",
            {
              companyName: castedValues.tenant_name,
              addressType: castedValues.addressType?.id,
              traderIdentificationNumber:
                castedValues.traderIdentificationNumber,
              country: castedValues.country,
              city: castedValues.city,
              street: castedValues.street,
              streetNumber: castedValues.street_number,
              zipcode: castedValues.zipcode,
            },
            {
              headers: {
                contentType: "application/json",
                recaptcha: token,
              },
            }
          )
          .then(onSuccess)
          .catch(onError);
      });
    } else {
      updateTenant({
        variables: {
          tenant_id: tenant.id,
          address_id: tenant.address_id,
          ...castedValues,
          addressType: castedValues.addressType?.id,
        },
      })
        .then(onSuccess)
        .catch(onError);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {children}
    </Formik>
  );
};

export default TenantFormik;
