import { useMutation } from "@apollo/client";
import { DELETE_ADDRESS } from "graphql/mutations/address";
import useErrorHandling from "hooks/useErrorHandling";
import { useCallback } from "react";

const useDeleteAddress = () => {
  const withErrorHandling = useErrorHandling();
  const [deleteAddress] = useMutation(DELETE_ADDRESS);

  const handleDelete = useCallback(
    (id: number) => {
      return withErrorHandling(deleteAddress, {
        variables: { id },
      });
    },
    [deleteAddress, withErrorHandling]
  );

  return handleDelete;
};

export default useDeleteAddress;
