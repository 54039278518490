import gql from "graphql-tag";
import { useAditTriggerModelRetrainingMutation } from "graphql/generated";

gql`
  mutation AditTriggerModelRetraining($requestTime: timestamptz!) {
    insert_model_retrain_one(
      object: { request_time: $requestTime, status: "pending" }
    ) {
      id
    }
  }
`;

export default () => {
  const result = useAditTriggerModelRetrainingMutation({
    variables: {
      requestTime: new Date().toISOString(),
    },
  });

  return result;
};
