import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { get } from "lodash";
import { Page } from "./useGetDocumentPages";

import { mapPages } from "../helpers";
import { sortDocuments } from "utils/customRelevant";
import { updateFileName } from "utils/updateFileName";

export type Blob = {
  extension: string;
  initial: boolean;
  url: string;
};

export type BaseDocument = {
  id: string;
  document_type: string;
  documentType: {
    translations: {
      [key: string]: string;
    };
    meta: {
      color?: string;
      labels?: any[];
    };
  };
  sequence: number;
  document_flags: number;
  document_name: string;
  document_blobs: {
    blobs: Blob[];
  };
};

export interface Document extends BaseDocument {
  pages: Page[];
}

export interface ServerDocument extends BaseDocument {
  pages: {
    page: Page;
  }[];
}

export type File = {
  id: string;
  identification: string;
  name: string;
  type: number;
  documents: Document[];
  declarations?: {
    file_id: string;
    dr_no: number;
  }[];
};

export const GET_FILES = gql`
  query getFiles($fileNumber: String) {
    files: vw_finished_files(
      order_by: { last_modified_date: desc, id: asc }
      where: {
        _and: {
          identification: { _eq: $fileNumber }
          file_status: {
            _in: [
              "transmission_pending"
              "transmission_generated"
              "transmission_failed"
              "archived_pending"
              "archived_failed"
              "archived"
              "ready_for_archive"
              "pending"
            ]
          }
        }
      }
    ) {
      id
      identification
      name
      type: file_flags
      declarations {
        file_id
        dr_no
      }
      documents(
        order_by: { creation_date: desc }
        where: {
          document_type: { _neq: "customs_overview" }
          is_deleted: { _eq: false }
        }
      ) {
        id
        document_type
        documentType {
          translations
          document_type_tenant_settings {
            meta
          }
        }
        document_flags
        document_blobs
        document_name
        sequence
        pages: pages_m2m {
          page {
            id
            rotate
            thumbnailBigUrl: thumbnail_big_url
            meta
          }
          page_number
        }
      }
    }
  }
`;

const useGetFiles = (fileNumber?: string, skip?: boolean, options?: any) => {
  const { data, loading, error } = useQuery(GET_FILES, {
    variables: {
      fileNumber,
    },
    fetchPolicy: "no-cache",
    skip,
    ...options,
  });

  const returnValue = useMemo(() => {
    let files: File[] = [];

    if (data) {
      files = data.files;
    }

    const updatedFiles = updateFileName(files).map((file) => {
      let documents = sortDocuments(
        get(file, "documents", [])
      ) as ServerDocument[];

      documents = documents.map((d: any) => {
        const { documentType } = d;
        const { document_type_tenant_settings } = documentType as any;
        const [docTypeTenantSettings] = document_type_tenant_settings;
        delete documentType.document_type_tenant_settings;
        documentType.meta = docTypeTenantSettings.meta;
        return d;
      });

      return {
        ...file,
        documents: documents.map((document: ServerDocument) => ({
          ...document,
          pages: mapPages(document.pages),
        })),
      };
    });

    return {
      data: updatedFiles,
      loading,
      error,
    };
  }, [data, error, loading]);

  return returnValue;
};

export default useGetFiles;
