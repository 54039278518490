import { Theme } from "@periplus/ui-library";
import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme: Theme) => ({
  avatar: {
    color: "#ffffff",
    backgroundColor: "#FFB800",
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  bodyTitle: {
    color: "#333333",
  },
  label: {
    color: "#828282",
  },
}));
