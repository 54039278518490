export const TASKS_PRIORITIES: { priority: TaskPriority; color: string }[] = [
  {
    priority: "highest",
    color: "darkRed",
  },
  {
    priority: "high",
    color: "red",
  },
  {
    priority: "medium",
    color: "orange",
  },
  {
    priority: "low",
    color: "yellow",
  },
  {
    priority: "lowest",
    color: "green",
  },
];
export type TaskPriority = "highest" | "high" | "medium" | "low" | "lowest";

export const TASKS_TYPES: {
  id: TaskType;
  display_name: string;
}[] = [
  {
    id: 1,
    display_name: "MANUAL_ANNOTATION",
  },
  {
    id: 2,
    display_name: "CREATE_DECLARATION",
  },
];
export type TaskType = 1 | 2;

export const TASKS_CREATED: {
  id: TaskCreated;
  subtractedDays: number;
  display: string;
}[] = [
  {
    id: "today",
    subtractedDays: 0,
    display: "today",
  },
  {
    id: "lastWeek",
    subtractedDays: 7,
    display: "lastWeek",
  },
  {
    id: "last2Week",
    subtractedDays: 14,
    display: "lastTwoWeek",
  },
  {
    id: "lastMonth",
    subtractedDays: 30,
    display: "last30Days",
  },
  {
    id: "last3Months",
    subtractedDays: 90,
    display: "last90Days",
  },
];
export type TaskCreated =
  | "today"
  | "lastWeek"
  | "last2Week"
  | "lastMonth"
  | "last3Months";
