import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  ChatBox,
  ChatHeader,
  Avatar,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  Box,
  FormControlLabel,
} from "@periplus/ui-library";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import camelCase from "lodash/camelCase";
import Progress from "components/Progress";
import useSubscribeDeclarationMessages from "graphql/hooks/useSubscribeDeclarationMessages";
import useStartChat from "graphql/hooks/useStartChat";
import useSendMessage from "graphql/hooks/useSendMessage";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useAuth } from "contexts/AuthContext";
import useStyles from "./Chat.styles";
import useMarkDeclarationDiscussionAsRead from "domain/declarationMessage/useMarkDeclarationMessagesAsRead";
import { HARDCODED_TENANTS } from "domain/tenant/type";

type UserMessage = {
  author?: string;
  message: string;
  inner: boolean;
  date: string;
  remark?: boolean;
};

const NOTIFICATION_WIDGET_SIZE = 420;
const TOPICS = ["Missing Documents", "Missing Information", "Other"];
const DEFAULT_TOPIC = "Other";
const AditChat: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { classes } = useStyles();
  const [appState, appAction] = useApplicationContext();

  const {
    data: { messages },
    loading,
  } = useSubscribeDeclarationMessages(appState.chat.discussionId || -1);

  const startChat = useStartChat();
  const sendMessage = useSendMessage();
  const markDeclarationDiscussionAsRead = useMarkDeclarationDiscussionAsRead();

  const [userMessages, setUserMessages] = useState<UserMessage[]>([]);

  const topic = appState.chat.subTitle || DEFAULT_TOPIC;

  useEffect(() => {
    if (
      appState.chat.discussionId &&
      messages.some(
        (message) =>
          !message.read_date &&
          message.user?.tenant_id !== HARDCODED_TENANTS.mnr
      )
    ) {
      markDeclarationDiscussionAsRead({
        variables: {
          disc_id: appState.chat.discussionId as number,
          read_date: new Date(),
        },
      });
    }
  }, [markDeclarationDiscussionAsRead, appState.chat.discussionId, messages]);

  useEffect(() => {
    const serverMessages = messages.map((declaration_message) => {
      return {
        author:
          declaration_message.creation_user === user?.userId
            ? undefined
            : declaration_message.user?.display_name ||
              [
                declaration_message.user?.first_name,
                declaration_message.user?.last_name,
              ]
                .filter(Boolean)
                .join(" ") ||
              declaration_message.user?.email ||
              declaration_message.user?.tenant.tenant_name,
        message: declaration_message.message,
        inner: declaration_message.user?.tenant_id === user?.tenantId,
        date: declaration_message.msg_date,
        remark: declaration_message.type === "remark",
      };
    });

    if (serverMessages.length > 0) {
      setUserMessages(serverMessages);
      appAction.changeChatProperties({
        subTitle: messages[0].discussion_theme!,
      });
    }
  }, [appAction, messages, appState.chat.subTitle, t, user]);

  const handleNewMessage = useCallback(
    (message: string) => {
      if (appState.chat.discussionId) {
        sendMessage({
          discussionId: appState.chat.discussionId,
          message,
        });
      } else {
        startChat({
          fileId: appState.chat.fileId,
          theme: topic,
        }).then(({ data }) => {
          appAction.changeChatProperties({
            discussionId:
              data?.insert_declaration_discussion_one?.discussion_id,
          });
          sendMessage({
            discussionId:
              data?.insert_declaration_discussion_one?.discussion_id,
            message,
          });
        });
      }

      setUserMessages((prevMessages) =>
        prevMessages.concat({
          message,
          inner: true,
          date: dayjs().format(),
        })
      );
    },
    [
      appAction,
      sendMessage,
      startChat,
      appState.chat.discussionId,
      appState.chat.fileId,
      topic,
    ]
  );

  const handleChangeTopic = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      appAction.changeChatProperties({
        subTitle: value,
      });
    },
    [appAction]
  );

  let offset = { right: 0, ...appState.chat.options?.offset };
  if (appState.isNotificationsShown) {
    offset.right += NOTIFICATION_WIDGET_SIZE;
  }

  return (
    <ChatBox
      dialog
      Header={
        <ChatHeader
          title={appState.chat.title}
          subTitle={t(
            `chat:${camelCase(messages[0]?.discussion_theme || topic)}`
          )}
          AvatarComponent={<Avatar className={classes.avatar}>GM</Avatar>}
          classes={{
            container: classes.header,
          }}
        />
      }
      placeholder={t("chat:message")}
      onClose={appAction.closeChat}
      onHide={appState.chat.discussionId ? appAction.hideChat : undefined}
      position={appState.chat.options?.position}
      offset={offset}
      messages={userMessages}
      onSend={handleNewMessage}
      disableRemark
    >
      {loading && userMessages.length === 0 && (
        <Box position="relative" height="100%">
          <Box
            position="absolute"
            left="50%"
            top="50%"
            style={{
              transform: "translate(-50%,-50%)",
            }}
          >
            <Progress />
          </Box>
        </Box>
      )}
      {!appState.chat.discussionId && userMessages.length === 0 && (
        <Grid container direction="column" gap={1}>
          <Typography className={classes.bodyTitle}>
            {t("chat:selectSubject")}
          </Typography>
          <RadioGroup
            value={topic}
            onChange={handleChangeTopic}
            sx={{
              gap: 1,
            }}
          >
            {TOPICS.map((subject) => (
              <FormControlLabel
                control={<Radio key={subject} value={subject} />}
                label={t(`chat:${camelCase(subject)}`)}
              />
            ))}
          </RadioGroup>
        </Grid>
      )}
    </ChatBox>
  );
};

export default AditChat;
