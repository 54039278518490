import { gql } from "@apollo/client";
import {
  AditGetDeclarationDocumentsTableQuery,
  useAditGetDeclarationDocumentsTableQuery,
} from "graphql/generated";

export type DeclarationDocumentTableEntity =
  AditGetDeclarationDocumentsTableQuery["documents"][number];

gql`
  query AditGetDeclarationDocumentsTable(
    $fileId: UUID
    $documentStatus: [DocumentStatus!]
  ) {
    documents: getFileDocuments(
      fileId: $fileId
      documentStatus: $documentStatus
    ) {
      pages {
        creationDate
        creationUser
        id
        lastModifiedDate
        lastModifiedUser
        meta
        ocrText
        pageNumber
        pageStatus
        rotate
        thumbnailBigUrl
        thumbnailSmallUrl
      }
      blobs {
        url
        initial
        extension
      }
      creationUser
      documentExtension
      documentFlags
      documentName
      documentStatus
      documentType
      documentTypeTranslations
      id
      lastModifiedDate
      meta
      sequence
    }
  }
`;

export default useAditGetDeclarationDocumentsTableQuery;
