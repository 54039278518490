import { gql } from "@apollo/client";

export const UPSERT_TABLE = gql`
  mutation UPSERT_TABLE(
    $pageId: uuid!
    $extractTypeId: smallint!
    $tables: jsonb!
  ) {
    insert_page_extract_one(
      object: {
        page_id: $pageId
        extract_type_id: $extractTypeId
        tables: $tables
      }
      on_conflict: { constraint: page_extract_pk, update_columns: [tables] }
    ) {
      page_id
    }
  }
`;

export const UPDATE_ROTATION = gql`
  mutation updateRotation($objects: [page_insert_input!]!) {
    insert_page(
      objects: $objects
      on_conflict: { constraint: page_pkey, update_columns: rotate }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_DATAPOINT = gql`
  mutation update_page_clob(
    $pageId: uuid!
    $dataPointId: String!
    $extract_type_id: smallint!
  ) {
    update_page_extract(
      where: {
        _and: [
          { page_id: { _eq: $pageId } }
          { extract_type_id: { _eq: $extract_type_id } }
        ]
      }
      _delete_key: { value: $dataPointId }
    ) {
      returning {
        page_id
      }
    }
  }
`;

export const REPLACE_DATAPOINT = gql`
  mutation update_data_point(
    $dataPoint: jsonb!
    $pageId: uuid!
    $dataPointIndex: Int!
    $extract_type_id: smallint!
  ) {
    delete: update_page_extract_by_pk(
      pk_columns: { extract_type_id: $extract_type_id, page_id: $pageId }
      _delete_elem: { value: $dataPointIndex }
    ) {
      page_id
    }
    append: update_page_extract_by_pk(
      pk_columns: { extract_type_id: $extract_type_id, page_id: $pageId }
      _append: { value: $dataPoint }
    ) {
      page_id
    }
  }
`;

export const UPDATE_PAGE_EXTRACT_VALUE = gql`
  mutation UPDATE_PAGE_EXTRACT_VALUE(
    $value: page_extract_append_input!
    $pageId: uuid!
    $extract_type_id: smallint!
  ) {
    update_page_extract_by_pk(
      pk_columns: { extract_type_id: $extract_type_id, page_id: $pageId }
      _append: $value
    ) {
      value
    }
  }
`;

export const UPSERT_PAGE_EXTRACT_VALUE = gql`
  mutation UPSERT_PAGE_EXTRACT_VALUE(
    $pageId: uuid!
    $value: jsonb!
    $extract_type_id: smallint!
  ) {
    insert_page_extract(
      objects: {
        extract_type_id: $extract_type_id
        page_id: $pageId
        value: {}
      }
      on_conflict: { constraint: page_extract_pk, update_columns: [] }
    ) {
      returning {
        page_id
      }
      affected_rows
    }
    update_page_extract_by_pk(
      pk_columns: { extract_type_id: $extract_type_id, page_id: $pageId }
      _append: { value: $value }
    ) {
      page_id
    }
  }
`;

export const TOGGLE_AI_IGNORE_RETRAINING = gql`
  mutation UpdateAIIgnoreRetraining($id: uuid!, $value: Boolean!) {
    update_page_by_pk(
      pk_columns: { id: $id }
      _set: { ai_ignore_retraining: $value }
    ) {
      id
      ai_ignore_retraining
    }
  }
`;
