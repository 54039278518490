import React, { useEffect } from "react";
import { Loader } from "@periplus/ui-library";
import {
  Route,
  RouteProps,
  Redirect,
  RouteComponentProps,
  useLocation,
} from "react-router-dom";

import { useAuth } from "contexts/AuthContext";
import Can from "./Can";
import { Permission } from "rbac-rules";

interface IPrivateRouteProps extends RouteProps {
  /**
   * If you set this, the access to the route will be checked based on the user's role and the configured rules.
   */
  permission?: Permission;
  /**
   * Supply the component that should get rendered for this route
   */
  component?: React.ComponentType<any>;
}

type RenderComponent = (props: RouteComponentProps<any>) => React.ReactNode;

/**
 * Checks if the user is logged in. Also implements RBAC (if permission is passed as a prop)
 * @param param0
 */
const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  permission,
  component: Component,
  path,
  ...rest
}) => {
  const location = useLocation();
  location.state = { path };
  const { loading, isAuthenticated, loginWithRedirect, user } = useAuth();

  useEffect(() => {
    const fn = async () => {
      if (!loading && !isAuthenticated) {
        // await loginWithRedirect();
        return <Redirect to="/login" />;
      }
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  if (loading) return <Loader />;

  //if (!isAuthenticated) return null;
  if (!isAuthenticated && !loading) return <Redirect to="/login" />;

  // TODO(df): Somehow, we have to catch this, as otherwise typescript complains
  if (!Component) return null;

  // TODO(df): Need to find a smart way of handling the case, when the parent component actually calls PrivateRoute with a render function already. Then we need to "inject" can here!
  const render: RenderComponent = (props) =>
    user && permission ? (
      <Can
        roles={user.allowedRoles}
        perform={permission}
        yes={() => <Component {...props} />}
        no={() => <Redirect to="/404" />}
      />
    ) : (
      <Component {...props} />
    );

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
