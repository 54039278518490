import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

interface IVariables {
  id: number;
  _set: Partial<{
    assigned_to: string;
    creation_date: string;
    due_date: string;
    id: number;
    meta: string;
    object_id: string;
    task_status_id: number;
    task_type_id: string;
    title: string;
  }>;
}

interface IData {
  id: number;
}

const ADIT_UPDATE_DECLARATION_TASK_BY_PK = gql`
  mutation ADIT_UPDATE_DECLARATION_TASK_BY_PK(
    $id: Int!
    $_set: declaration_task_set_input
  ) {
    update_declaration_task_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

const useUpdateDeclarationTask = () => {
  const [update] = useMutation<IData, IVariables>(
    ADIT_UPDATE_DECLARATION_TASK_BY_PK
  );
  return update;
};

export default useUpdateDeclarationTask;
