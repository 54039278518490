import React, { FC } from "react";
import {
  MuiTable,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@periplus/ui-library";
import {
  useTable,
  useRowSelect,
  usePagination,
  useExpanded,
  useFilters,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { makeStyles } from 'tss-react/mui';

interface SubTableProps {
  data: any[];
  columns: any[];
  selectionType?: "single" | "multiple";
  onToggle?: (args: { selecting: any[]; deselecting: any[] }) => void;
  isRowSelected?: (data: any) => boolean;
  tableConfig?: {
    initialState?: any;
    [key: string]: any;
  };
  onDoubleClick?: (data: any) => void;
  getSelectionCellProps?: (args: object) => any;
  columnSizes?: string[];
  [key: string]: any;
}

const useStyles = makeStyles()({
  headerRowCell: {
    backgroundColor: "#F3F4FB",
  },
  bodyRowCell: {
    backgroundColor: "#FAFAFE",
  },
});

const SubTable: FC<SubTableProps> = ({
  data,
  columns,
  selectionType = "multiple",
  onToggle,
  isRowSelected,
  tableConfig = {},
  onDoubleClick,
  getSelectionCellProps,
  columnSizes,
  ...rest
}) => {
  const { classes } = useStyles();
  const {
    getTableProps,
    headerGroups,
    // @ts-ignore
    page,
    prepareRow,
    getTableBodyProps,
    // @ts-ignore
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      /*
        autoResetPage: false,
        autoResetSortBy: false,
        autoResetExpanded: false,
        manualPagination: !!loadMore,
        autoResetSelectedRows: !!loadMore,
        manualSortBy: !!sortBy,
        disableMultiSort,
        disableSortRemove: true,
      */
      ...tableConfig,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  return (
    <MuiTable
      {...getTableProps()}
      size="small"
      style={{ marginBottom: "-1px", minHeight: "110px", tableLayout: "fixed" }}
      {...rest}
    >
      {!!columnSizes?.length && (
        <colgroup>
          {columnSizes.map((columnSize, index) => (
            <col key={index} style={{ width: columnSize }}></col>
          ))}
        </colgroup>
      )}
      <TableHead>
        {headerGroups.map((headerGroup: any) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any, index: number) => {
              const { style = {}, ...rest } = column.getHeaderProps();
              return (
                <TableCell
                  {...rest}
                  className={classes.headerRowCell}
                  style={
                    index < [onToggle].filter(Boolean).length
                      ? {
                          width: "42px",
                          padding: "6px",
                          height: "61px",
                          ...style,
                        }
                      : {
                          ...style,
                          width: column.width,
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth,
                        }
                  }
                >
                  {column.render("Header")}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {page.map((row: any) => {
          prepareRow(row);
          const isDisabled =
            getSelectionCellProps &&
            getSelectionCellProps(row.original).disabled;
          const rowProps = row.getRowProps(
            isDisabled
              ? {}
              : {
                  onDoubleClick: () =>
                    onDoubleClick && onDoubleClick(row.original),
                  onClick: () => {
                    const isSelected =
                      isRowSelected && isRowSelected(row.original);

                    row.toggleRowSelected(!isSelected);

                    if (!onToggle) {
                      return;
                    }

                    const selectedRow = selectedFlatRows[0];

                    const rowData = [row.original];
                    const [selecting, deselecting]: [any[], any[]] = isSelected
                      ? [[], rowData]
                      : [rowData, []];

                    if (selectionType === "single" && selectedRow) {
                      selectedRow.toggleRowSelected(false);
                      deselecting.push(selectedRow.original);
                    }

                    onToggle({
                      selecting,
                      deselecting,
                    });
                  },
                }
          );
          return (
            <TableRow {...rowProps}>
              {row.cells.map((cell: any, index: number) => {
                const { style = {}, ...rest } = cell.getCellProps();
                return (
                  <TableCell
                    {...rest}
                    className={classes.bodyRowCell}
                    style={
                      index < [onToggle].filter(Boolean).length
                        ? {
                            width: "42px",
                            padding: "6px",
                            height: "61px",
                            ...style,
                          }
                        : {
                            ...style,
                            width: cell.column.width,
                            minWidth: cell.column.minWidth,
                            maxWidth: cell.column.maxWidth,
                          }
                    }
                  >
                    {cell.render("Cell")}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </MuiTable>
  );
};

export default SubTable;
