import get from "lodash/get";
import { SortDirection } from "@periplus/ui-library";

export const mapPages = (pages: any) => {
  const updatedPages = pages.reduce(
    // @ts-ignore
    (aggregatePages, { page, page_number }) =>
      aggregatePages.concat({
        ...page,
        page_number,
        rotate: get(page, "rotate", +get(page, "meta.Rotate", 0)),
      }),
    []
  );

  return updatedPages;
};

export interface Sorted {
  [key: string]: {
    dir: SortDirection;
    pos: number;
  };
}
