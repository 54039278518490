export function bitmaskToArray(bitmask: number) {
  if (!Number.isInteger(bitmask) || bitmask < 0) {
    throw new TypeError(
      `Bitmask needs to be a non-negative integer, got ${bitmask}`
    );
  }

  let array = [];
  let upperBoundOrder = 0;
  while (2 ** upperBoundOrder <= bitmask) {
    let upperBound = 2 ** upperBoundOrder;
    if (upperBound & bitmask) {
      array.push(upperBound);
    }
    upperBoundOrder++;
  }

  return array;
}
