import { Typography, styled } from "@periplus/ui-library";

const WaitText = styled(Typography)(({ theme }) => ({
  "@keyframes waitAnimation": {
    "0%": {
      content: '""',
    },
    "25%": {
      content: '"."',
    },
    "50%": {
      content: '".."',
    },
    "100%": {
      content: '"..."',
    },
  },
  "&::after": {
    content: '""',
    display: "inline-block",
    animationName: "waitAnimation",
    animationDuration: "2s",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
  },
}));

WaitText.defaultProps = {
  // @ts-ignore
  component: "span",
};

export default WaitText;
