import React, { FC, useCallback, useMemo } from "react";
import {
  Box,
  BoxProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Theme,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { Germany, GreatBritain } from "components/Icons/Flags";
import LanguageIcon from "@mui/icons-material/Language";
import { useApplicationContext } from "contexts/ApplicationContext";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeIcon from "@mui/icons-material/Home";
import { useHistory } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useAuth } from "contexts/AuthContext";
import NestedList from "./NestedList";

export type LanguageItem = {
  key?: string;
  element: string;
  abbreviation: string;
  Icon?: React.ElementType;
  action: () => void;
};

interface UserMenuContentProps extends BoxProps {
  close: () => void;
}

const UserMenuContent: FC<UserMenuContentProps> = ({ close }) => {
  const { t } = useTranslation();
  const [appState, appAction] = useApplicationContext();

  const history = useHistory();
  const { logout, updateUiSettings } = useAuth();
  const isUpLaptop = useMediaQuery(
    (theme: Theme) => theme.breakpoints.up("laptop"),
    { noSsr: true }
  );

  const changeLanguage = useCallback(
    (lng: string) => () => {
      updateUiSettings({ language: lng });
    },
    [updateUiSettings]
  );

  const languages = useMemo<LanguageItem[]>(
    () => [
      {
        abbreviation: "en",
        element: "English",
        Icon: GreatBritain,
        action: changeLanguage("en"),
      },
      {
        abbreviation: "de",
        element: "Deutsch",
        Icon: Germany,
        action: changeLanguage("de"),
      },
    ],
    [changeLanguage]
  );

  const menuItems = [
    {
      text: t("navigation:home"),
      action: () => {
        history.push("/");
      },
      icon: <HomeIcon />,
    },
    {
      text: t("common:Choose Language"),
      subElements: languages,
      icon: <LanguageIcon />,
    },
    {
      text: t("notifications:notifications"),
      action: () => appAction.toggleNotificationsShown(),
      icon: (
        <Box
          sx={{
            position: "relative",
          }}
        >
          {appState.isNewMessages && (
            <Box
              sx={{
                width: 10,
                height: 10,
                position: "absolute",
                right: 13,
                top: 13,
                backgroundColor: "#EC008C",
                borderRadius: "100%",
              }}
            />
          )}
          <NotificationsIcon />
        </Box>
      ),
    },
    {
      text: t("navigation:settings"),
      action: () => {
        history.push("/settings");
      },
      icon: <SettingsIcon />,
    },
    {
      text: t("common:logout"),
      action: () => logout(),
      icon: <ExitToAppIcon />,
    },
  ];

  return (
    <List sx={{ p: 0 }}>
      {menuItems.map(
        ({ text, icon, action, subElements, mobileOnly }: any, i) => (
          <React.Fragment key={text}>
            {subElements ? (
              mobileOnly ? (
                !isUpLaptop && (
                  <NestedList
                    label={text}
                    sectionIcon={icon}
                    items={subElements}
                  />
                )
              ) : (
                <NestedList
                  label={text}
                  sectionIcon={icon}
                  items={subElements}
                />
              )
            ) : (
              <ListItem
                button
                onClick={() => {
                  action();
                  close();
                }}
                sx={{
                  borderTop: i !== 0 ? 1 : 0,
                  borderColor: "grey4.main",
                }}
              >
                <ListItemIcon
                  sx={{
                    color: "text.secondary",
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  sx={{
                    color: "text.secondary",
                  }}
                />
              </ListItem>
            )}
          </React.Fragment>
        )
      )}
    </List>
  );
};

export default UserMenuContent;
