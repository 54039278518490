import React, {
  useLayoutEffect,
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import noop from "lodash/noop";
import partition from "lodash/partition";
import isEqual from "lodash/isEqual";

enum KeyboardKeys {
  A = 65,
  D = 68,
  Space = 32,
  Left = 37,
  Up = 38,
  Right = 39,
  Down = 40,
}

const knownInputTags = ["INPUT", "TEXTAREA", "SELECT", "OPTION"];

interface useElementSelectorArgs {
  pagesStructure: any;
  itemsPerLine?: number;
  container?: React.RefObject<any>;
  onSelect?: (index: string[]) => void;
  onDeselect?: (index: string[]) => void;
  onFocus?: (index: string) => void;
  disabledIndexes?: string[];
  excluded?: string[];
}

const convertArrayToPath = (arrayLength: any): string[] => {
  if (typeof arrayLength === "number") {
    return new Array(arrayLength).fill(null).map((_, index) => `${index}`);
  }

  return arrayLength.reduce((acc: string[], element: any, index: number) => {
    const arrayOfIndexes = convertArrayToPath(element);
    return acc.concat(arrayOfIndexes.map((el) => `${index}.${el}`));
  }, []);
};

const useElementSelector = ({
  pagesStructure,
  itemsPerLine = 1,
  container,
  onSelect = noop,
  onFocus = noop,
  onDeselect = noop,
  disabledIndexes = [],
  excluded = [],
}: useElementSelectorArgs) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = pagesStructure;
  });
  const dataIsChanged = isEqual(ref.current, pagesStructure);

  const allPosiblePaths = useMemo(
    () => convertArrayToPath(pagesStructure),
    // eslint-disable-next-line
    [dataIsChanged]
  );

  const [selected, setSelected] = useState<string[]>([]);
  const [disabled, setDisabled] = useState<string[]>(disabledIndexes);
  const [_excluded, _setExcluded] = useState<string[]>(excluded);
  const [focused, setFocused] = useState<string>(
    disabledIndexes.length === allPosiblePaths.length
      ? "-1"
      : allPosiblePaths[0]
  );

  const updateSelected = useCallback((indexes: string[]) => {
    setSelected((pSelected) => {
      const [selectedIndexes, unselectedIndexes] = partition(indexes, (index) =>
        pSelected.includes(index)
      );
      return pSelected
        .filter((index) => !selectedIndexes.includes(index))
        .concat(unselectedIndexes);
    });
  }, []);

  const steps: { [key: number]: number } = useMemo(
    () => ({
      [KeyboardKeys.Left]: -1,
      [KeyboardKeys.Up]: -itemsPerLine,
      [KeyboardKeys.Right]: +1,
      [KeyboardKeys.Down]: +itemsPerLine,
    }),
    [itemsPerLine]
  );

  const calculateIndexes = useCallback(
    (nextPath: string, previousPath: string) => {
      if (nextPath === "-1" || previousPath === "-1") {
        return [];
      }

      const nextIndex = allPosiblePaths.indexOf(nextPath);
      const previousIndex = allPosiblePaths.indexOf(previousPath);

      let additionalItem = 0;
      if (
        (selected.includes(nextPath) && selected.includes(previousPath)) ||
        (!selected.includes(nextPath) && !selected.includes(previousPath))
      ) {
        additionalItem += 1;
      }

      const [from, to] =
        nextIndex < previousIndex
          ? [nextIndex, previousIndex + additionalItem]
          : [previousIndex + 1 - additionalItem, nextIndex + 1];

      return allPosiblePaths
        .slice(from, to)
        .filter(
          (index) => !disabled.includes(index) && !_excluded.includes(index)
        );
    },
    [_excluded, allPosiblePaths, disabled, selected]
  );

  const runGreedySearch = useCallback(
    (index: number, step: number) => {
      if (disabled.length === 0 && _excluded.length === 0) {
        return index;
      }

      let foundIndex = index;
      while (foundIndex >= 0 && foundIndex < allPosiblePaths.length) {
        if (
          disabled.includes(allPosiblePaths[foundIndex]) ||
          _excluded.includes(allPosiblePaths[foundIndex])
        ) {
          foundIndex += step;
          continue;
        }

        break;
      }

      return foundIndex;
    },
    [_excluded, allPosiblePaths, disabled]
  );

  const getCorrectIndex = useCallback(
    (index: number, step: number) => {
      let unblockedIndex = runGreedySearch(index, step);

      if (unblockedIndex < 0 || unblockedIndex >= allPosiblePaths.length) {
        unblockedIndex = runGreedySearch(index, step * -1);
      }

      return unblockedIndex;
    },
    [allPosiblePaths.length, runGreedySearch]
  );

  const handleFocused = useCallback(
    (e: MouseEvent | KeyboardEvent, path: string, step: number) => {
      const index = allPosiblePaths.indexOf(path);
      const correctIndex = getCorrectIndex(index, step);
      const correctPath = allPosiblePaths[correctIndex];

      if (e.shiftKey) {
        const indexes = calculateIndexes(correctPath, focused);
        indexes && onSelect(indexes);

        updateSelected(indexes);
      }
      onFocus(correctPath);
      setFocused(correctPath);
    },
    [
      allPosiblePaths,
      getCorrectIndex,
      onFocus,
      calculateIndexes,
      focused,
      onSelect,
      updateSelected,
    ]
  );

  const handleSelect = useCallback(
    (path: string, focusOnly: boolean = false) => {
      if (disabled.includes(path) || _excluded.includes(path)) return noop;

      return (e: MouseEvent) => {
        setFocused(path);
        let indexesToAdd = [path];

        if (focusOnly) return;

        if (e.shiftKey) {
          indexesToAdd = calculateIndexes(path, focused);
        }
        onSelect(indexesToAdd);
        updateSelected(indexesToAdd);
      };
    },
    [_excluded, calculateIndexes, disabled, focused, onSelect, updateSelected]
  );

  const getAllPathsByPartial = useCallback(
    (partialPath: string, source: string[] = allPosiblePaths) => {
      return source.filter((dPath) => dPath.includes(partialPath));
    },
    [allPosiblePaths]
  );

  const onKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (
        e.target &&
        knownInputTags.includes((e.target as HTMLElement).tagName)
      )
        return;

      if (focused === "-1" || focused === undefined) return;

      const step = steps[e.keyCode];

      if (step == null) {
        return true;
      }

      const index = allPosiblePaths.indexOf(focused);
      let nextIndex = index + step;

      if (nextIndex < 0) {
        nextIndex = 0;
      }

      if (nextIndex >= allPosiblePaths.length) {
        nextIndex = allPosiblePaths.length - 1;
      }

      let nextFocused = allPosiblePaths[nextIndex];
      const focusedAsArray = focused.split(".");
      const nextFocusedAsArray = nextFocused.split(".");

      if (nextFocused === focused) {
        return;
      }

      const indexOfDifference = nextFocusedAsArray.findIndex(
        (part, index) => part !== focusedAsArray[index]
      );
      const isCorrectionNeeded =
        indexOfDifference < nextFocusedAsArray.length - 1;
      const isNextElementBlockSelected = Math.abs(
        parseInt(focusedAsArray[indexOfDifference]) -
          parseInt(nextFocusedAsArray[indexOfDifference])
      );
      let temp = nextFocusedAsArray.map((index) => +index);
      let updatedState = step;

      if (isCorrectionNeeded) {
        updatedState = step < 0 ? -1 : 1;

        if (isNextElementBlockSelected > 1) {
          temp = temp.map((_, index) => {
            if (index === indexOfDifference) {
              return parseInt(focusedAsArray[indexOfDifference]) + updatedState;
            }
            return index;
          });
        }

        temp = temp.map((value, index) => {
          if (index > indexOfDifference) {
            return updatedState > 0
              ? 0
              : allPosiblePaths.filter((path) => {
                  return path.includes(
                    temp.slice(0, index || 1).join(".") + "."
                  );
                }).length - 1;
          }

          return value;
        });
      }

      if (nextIndex >= 0 && nextIndex < allPosiblePaths.length) {
        handleFocused(e, temp.join("."), updatedState);
      }
    },
    [allPosiblePaths, focused, handleFocused, steps]
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (
        e.target &&
        knownInputTags.includes((e.target as HTMLElement).tagName)
      )
        return;

      if (focused === "-1" || focused === undefined) return;

      const { ctrlKey, shiftKey, keyCode } = e;

      if (Object.values(KeyboardKeys).includes(keyCode)) {
        e.preventDefault();
      }

      if (keyCode === KeyboardKeys.Space) {
        onSelect([focused]);
        updateSelected([focused]);
      }

      if (ctrlKey && keyCode === KeyboardKeys.A) {
        if (shiftKey) {
          const indexes = calculateIndexes(
            allPosiblePaths[0],
            allPosiblePaths[allPosiblePaths.length - 1]
          );
          onSelect(indexes);
          setSelected(indexes);
          return false;
        }

        const focusedAsArray = focused.split(".");
        const currentDocument = focusedAsArray
          .slice(0, focusedAsArray.length - 1)
          .join(".");
        const paths = getAllPathsByPartial(`${currentDocument}.`);
        const indexes = calculateIndexes(paths[0], paths[paths.length - 1]);
        onSelect(indexes);
        setSelected((pSelected) => {
          const uniqueIndexes = indexes.filter(
            (index) => !pSelected.includes(index)
          );
          return pSelected.concat(uniqueIndexes);
        });
        return false;
      }

      if (ctrlKey && keyCode === KeyboardKeys.D) {
        if (shiftKey) {
          const indexes = calculateIndexes(
            allPosiblePaths[0],
            allPosiblePaths[allPosiblePaths.length - 1]
          );
          onDeselect(indexes);
          setSelected([]);
          return false;
        }

        const focusedAsArray = focused.split(".");
        const currentDocument = focusedAsArray
          .slice(0, focusedAsArray.length - 1)
          .join(".");
        const paths = getAllPathsByPartial(`${currentDocument}.`, selected);
        const indexes = calculateIndexes(paths[0], paths[paths.length - 1]);
        onDeselect(indexes);
        setSelected((pSelected) =>
          pSelected.filter((ps) => !indexes.includes(ps))
        );
        return false;
      }
    },
    [
      calculateIndexes,
      allPosiblePaths,
      focused,
      getAllPathsByPartial,
      onDeselect,
      onSelect,
      selected,
      updateSelected,
    ]
  );

  const removeIndexes = useCallback(
    (path) => (pDisabled: string[]) => {
      let isPathRange = false;

      if (Array.isArray(path)) {
        isPathRange = path.length > 1;
        path = isPathRange ? path : path[0];
      }

      if (allPosiblePaths.length === 0) return [];

      const initialPathLength = allPosiblePaths[0].split(".").length;
      const pathLength = (isPathRange ? path[0] : path).split(".").length;
      const isFullPath = pathLength === initialPathLength;

      if (!isPathRange) {
        return pDisabled.filter(
          (pd: string) =>
            !pd.includes(isFullPath ? (path as string) : `${path}.`)
        );
      }

      const allPossiblePaths = isFullPath
        ? (path as string[])
        : (path as string[]).reduce(
            (acc: string[], p: string) =>
              acc.concat(getAllPathsByPartial(`${p}.`)),
            []
          );

      return pDisabled.filter((pd: string) => !allPossiblePaths.includes(pd));
    },
    [allPosiblePaths, getAllPathsByPartial]
  );

  const cancel = (path?: string | string[]) => {
    if (!path && selected) {
      setSelected([]);
      return;
    }

    if (!path) {
      setDisabled([]);
      return;
    }

    setDisabled(removeIndexes(path));
  };

  const addIndexes = useCallback(
    (path) => (prevValues: string[]) => {
      let isPathRange = false;

      if (Array.isArray(path)) {
        isPathRange = path.length > 1;
        path = isPathRange ? path : path[0];
      }

      if (allPosiblePaths.length === 0) return [];

      const initialPathLength = allPosiblePaths[0].split(".").length;
      const pathLength = (isPathRange ? path[0] : path).split(".").length;
      const isFullPath = pathLength === initialPathLength;

      if (isPathRange) {
        const allPossiblePaths = isFullPath
          ? (path as string[])
          : (path as string[]).reduce(
              (acc: string[], p: string) =>
                acc.concat(getAllPathsByPartial(`${p}.`)),
              []
            );
        const filteredPossiblePaths = allPossiblePaths.filter(
          (pPath: string) => !prevValues.includes(pPath)
        );

        return prevValues.concat(filteredPossiblePaths);
      }

      if (isFullPath) {
        return prevValues.includes(path) ? prevValues : prevValues.concat(path);
      }

      const filteredPaths = getAllPathsByPartial(`${path}.`).filter(
        (pp) => !prevValues.includes(pp)
      );
      return prevValues.concat(filteredPaths);
    },
    [allPosiblePaths, getAllPathsByPartial]
  );

  // FIXME: refactor this function
  const disable = useCallback(
    (path?: string | string[]) => {
      if (!path) {
        setDisabled((pDisabled) => {
          const uniqueIndexes = selected.filter(
            (index) => !pDisabled.includes(index)
          );
          return pDisabled.concat(uniqueIndexes);
        });
      } else {
        setDisabled(addIndexes(path));
      }
      setSelected([]);
    },
    [addIndexes, selected]
  );

  const exclude = useCallback(
    (path: string | string[]) => {
      _setExcluded(addIndexes(path));
    },
    [addIndexes]
  );

  const include = useCallback(
    (path: string | string[]) => {
      _setExcluded(removeIndexes(path));
    },
    [removeIndexes]
  );

  useLayoutEffect(() => {
    let element = window.document.body;
    if (container && container.current) {
      element = container.current;
    }

    element.addEventListener("keyup", onKeyUp);
    element.addEventListener("keydown", onKeyDown);
    return () => {
      element.removeEventListener("keyup", onKeyUp);
      element.removeEventListener("keydown", onKeyDown);
    };
  }, [container, onKeyDown, onKeyUp]);

  useEffect(() => {
    // Cleanup focus if allPosiblePaths was changed
    const indexOfPreviouslyFocused = allPosiblePaths.indexOf(focused);
    const newIndex = getCorrectIndex(
      indexOfPreviouslyFocused === -1 ? 0 : indexOfPreviouslyFocused,
      1
    );
    setFocused(
      disabled.length === allPosiblePaths.length
        ? "-1"
        : allPosiblePaths[newIndex]
    );
    // eslint-disable-next-line
  }, [allPosiblePaths, itemsPerLine, disabled, _excluded]);

  return {
    selected,
    disabled,
    focused,
    handleSelect,
    cancel,
    disable,
    exclude,
    include,
  };
};

export default useElementSelector;
