import { useMutation } from "@apollo/client";
import { INSERT_ADDRESS } from "graphql/mutations/address";
import useErrorHandling from "hooks/useErrorHandling";

import { PartialAddress } from "graphql/hooks/useGetAddress";

const useInsertAddress = () => {
  const withErrorHandling = useErrorHandling();
  const [insertAddress] = useMutation(INSERT_ADDRESS);

  const handleInsert = (objects: PartialAddress) => {
    return withErrorHandling(insertAddress, {
      variables: { objects },
    });
  };

  return handleInsert;
};

export default useInsertAddress;
