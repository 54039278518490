import React, { useMemo } from "react";
import {
  Dialog,
  FormTextField,
  FormAutocomplete,
  Grid,
  Theme,
  Typography,
  Checkbox,
  FormHelperText,
} from "@periplus/ui-library";
import * as Yup from "yup";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { useTranslation } from "react-i18next";
import Countries from "i18n-iso-countries";
import { Formik, Field, FieldProps } from "formik";
import { useSnackbar } from "notistack";
import { Address, PartialAddress } from "graphql/hooks/useGetAddress";
import { AddressType } from "graphql/hooks/useGetAddressType";
import useInsertAddress from "graphql/hooks/useInsertAddress";
import useUpdateAddress from "graphql/hooks/useUpdateAddress";
import { makeStyles } from 'tss-react/mui';

Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const useStyles = makeStyles()((theme: Theme) => ({
  contentContainer: {
    padding: `0 ${theme.spacing(3.25)} ${theme.spacing(1.5)}`,
  },
  groupTitle: {
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87)",
    padding: `${theme.spacing(1)} ${theme.spacing(0.5)}`,
  },
  checkboxText: {
    display: "flex",
    gap: theme.spacing(),
  },
}));

type AddressDialogProps = {
  address?: Address | PartialAddress | null;
  addressTypes: AddressType[];
  onClose?: () => void;
  onConfirm?: () => void;
};

enum AddressFields {
  Type = "address_type",
  Company = "company_name",
  Street = "street",
  Number = "street_number",
  Zip = "zipcode",
  City = "city",
  Country = "country",
  Reference = "reference",
  Phone = "contact_phone",
  Email = "contact_email",
  Website = "contact_website",
}

const initialAddress = {
  [AddressFields.Type]: 0,
  [AddressFields.Company]: "",
  [AddressFields.Street]: "",
  [AddressFields.Number]: "",
  [AddressFields.Zip]: "",
  [AddressFields.City]: "",
  [AddressFields.Country]: "",
  [AddressFields.Reference]: "",
  [AddressFields.Phone]: "",
  [AddressFields.Email]: "",
  [AddressFields.Website]: "",
};
const phoneRegExp =
  /^((\+?\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const websiteRegExp =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

type FieldsGroupProps = {
  title: string;
  children: React.ReactNode | React.ReactNode[];
};

const FieldsGroup = ({ title, children }: FieldsGroupProps) => {
  const { classes } = useStyles();
  return (
    <Grid item container direction="column">
      <Typography className={classes.groupTitle}>{title}</Typography>
      <Grid item container spacing={2}>
        {children}
      </Grid>
    </Grid>
  );
};

type _TypeGroup = {
  addressTypes: AddressType[];
} & FieldProps;

const TypeGroup = ({ field, form, addressTypes }: _TypeGroup) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { onChange, value, name, ...rest } = field;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    let typeId: number | null = parseInt(e.target.value) || 0;

    if (!isChecked && typeId === value) {
      typeId = null;
    }

    onChange({
      ...e,
      target: {
        ...e.target,
        name: AddressFields.Type,
        value: typeId,
      },
    });
  };

  return (
    <>
      {addressTypes.map((addressType) => (
        <Grid
          key={addressType.id}
          item
          container
          xs={6}
          className={classes.checkboxText}
        >
          <Checkbox
            onChange={handleChange}
            value={addressType.id}
            checked={value === addressType.id}
            {...rest}
            name={name}
          />
          <Typography>{t(`addressTypes:${addressType.name}`)}</Typography>
        </Grid>
      ))}
      {form.touched[name] && form.errors[name] && (
        <FormHelperText error>{form.errors[name]}</FormHelperText>
      )}
    </>
  );
};

const AddressDialog = ({
  address,
  addressTypes,
  onClose = () => {},
  onConfirm = () => {},
}: AddressDialogProps) => {
  const { classes } = useStyles();

  const createAddress = useInsertAddress();
  const updateAddress = useUpdateAddress();

  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = address || initialAddress;

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      [AddressFields.Type]: Yup.number().min(
        1,
        t("validation:atLeastOneSelect")
      ),
      [AddressFields.Company]: Yup.string()
        .trim()
        .required(t("validation:isRequired")),
      [AddressFields.Street]: Yup.string()
        .trim()
        .required(t("validation:isRequired")),
      [AddressFields.Number]: Yup.string()
        .trim()
        .required(t("validation:isRequired")),
      [AddressFields.Zip]: Yup.string()
        .trim()
        .required(t("validation:isRequired")),
      [AddressFields.City]: Yup.string()
        .trim()
        .required(t("validation:isRequired")),
      [AddressFields.Country]: Yup.string()
        .trim()
        .nullable()
        .required(t("validation:isRequired")),
      [AddressFields.Phone]: Yup.string()
        .trim()
        .matches(phoneRegExp, t("validation:phoneNumberNotValid"))
        .max(15, t("validation:maxLength", { maxLength: 15 })),
      [AddressFields.Email]: Yup.string()
        .email(t("validation:emailNotValid"))
        .trim()
        .required(t("validation:isRequired")),
      [AddressFields.Website]: Yup.string()
        .trim()
        .matches(websiteRegExp, t("validation:websiteNotValid")),
    });
  }, [i18n.language]);

  const handleFormikSubmit = async (
    values: Address | PartialAddress,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    const {
      id,
      creation_date,
      creation_user,
      last_modified_date,
      last_modified_user,
      qty,
      __typename,
      ...editAddress
    } = Object.assign(
      {
        id: -1,
        creation_date: "",
        creation_user: "",
        last_modified_date: "",
        last_modified_user: "",
        qty: 0,
        __typename: "",
      },
      values
    );

    try {
      let message = "";
      if (id !== -1) {
        await updateAddress(id, editAddress);
        message = t("common:addressUpdated");
      } else {
        await createAddress(editAddress);
        message = t("common:addressAdded");
      }

      enqueueSnackbar(message, { variant: "success" });
      onConfirm();
    } catch (_) {
      enqueueSnackbar(t("error:serverError"), { variant: "error" });
    }

    setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleFormikSubmit}
      validationSchema={validationSchema}
    >
      {(props) => (
        <Dialog
          fullWidth
          maxWidth="sm"
          mainTitle={t("common:company")}
          subTitle={t("common:companyInformation")}
          TitleIcon={PlaylistAddCheckIcon}
          onClose={onClose}
          onConfirm={props.submitForm}
          CancelButtonProps={{ label: t("common:cancel") }}
          ConfirmButtonProps={{
            label: t("common:save"),
            disabled: props.isSubmitting || !props.dirty || !props.isValid,
          }}
        >
          <form onSubmit={props.handleSubmit}>
            <Grid
              container
              direction="column"
              spacing={3}
              className={classes.contentContainer}
            >
              <FieldsGroup title={t("common:type")}>
                <Field
                  name={AddressFields.Type}
                  component={(props: FieldProps) => (
                    <TypeGroup {...props} addressTypes={addressTypes} />
                  )}
                />
              </FieldsGroup>
              <FieldsGroup title={t("common:company")}>
                <Grid item container xs={12}>
                  <FormTextField
                    required
                    name={AddressFields.Company}
                    fullWidth
                    label={t("common:name")}
                  />
                </Grid>
                <Grid item container xs={12}>
                  <FormTextField
                    name={AddressFields.Reference}
                    fullWidth
                    label={t("common:reference")}
                  />
                </Grid>
              </FieldsGroup>
              <FieldsGroup title={t("common:address")}>
                <Grid item xs={8}>
                  <FormTextField
                    required
                    name={AddressFields.Street}
                    fullWidth
                    label={t("common:street")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormTextField
                    required
                    name={AddressFields.Number}
                    fullWidth
                    label={t("common:streetNumber")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    required
                    name={AddressFields.Zip}
                    fullWidth
                    label={t("common:zip")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    required
                    name={AddressFields.City}
                    fullWidth
                    label={t("common:city")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormAutocomplete
                    name={AddressFields.Country}
                    freeSolo
                    autoSelect
                    options={Object.values(Countries.getNames("en")).map(
                      (country) =>
                        Array.isArray(country) ? country[0] : country
                    )}
                    InputProps={{
                      required: true,
                      label: t("common:location"),
                    }}
                  />
                </Grid>
              </FieldsGroup>
              <FieldsGroup title={t("common:contact")}>
                <Grid item xs={6}>
                  <FormTextField
                    name={AddressFields.Phone}
                    fullWidth
                    label={t("common:phone")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    required
                    name={AddressFields.Email}
                    fullWidth
                    label={t("common:email")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    name={AddressFields.Website}
                    fullWidth
                    label={t("common:website")}
                  />
                </Grid>
              </FieldsGroup>
            </Grid>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};

export default AddressDialog;
