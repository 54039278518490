import React, {
  FC,
  HtmlHTMLAttributes,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import {
  MRTable,
  Badge,
  IconButton,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
  Button,
} from "@periplus/ui-library";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import DeclarationStatusChip from "domain/declaration/components/DeclarationStatusChip";
import {
  MRT_ColumnOrderState,
  MRT_ColumnPinningState,
  MRT_ColumnSizingState,
  MRT_PaginationState,
  MRT_TableState,
  MRT_VisibilityState,
  MRT_SortingState,
  MRT_RowSelectionState,
} from "material-react-table";
import RowActions from "./RowActions";
import usePageSettings from "domain/user/usePageSettings";
import useGetDeclarationsTable, {
  DeclarationTableEntity,
  GetDeclarationsTableVariables,
} from "../hooks/useGetDeclarationsTable";
import {
  useApplicationState,
  useApplicationAction,
} from "contexts/ApplicationContext";
import DeclarationFiltersSidebar, {
  DeclarationFilters,
} from "pages/Select/WeDeclare/components/DeclarationFiltersSidebar";
import useUrlSearchParams, {
  TableUrlSearchParams,
} from "hooks/useUrlSearchParams";
import { useHistory } from "react-router-dom";
import { DeclarationStatus } from "graphql/generated";
import { Folder, ChatOutlined, ArrowForward } from "@mui/icons-material";
import OurReferenceCell from "../NotClassified/OurReferenceCell";
import CheckIcon from "@mui/icons-material/Check";
import useSubscribeToUnreadMessagesCount from "../hooks/useSubscribeToUnreadMessagesCount";
import SubTable from "./SubTable";
import { isEqual } from "lodash";

interface TableProps extends HtmlHTMLAttributes<HTMLElement> {
  classified: boolean;
}

const Table: FC<TableProps> = ({ classified }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const history = useHistory();
  const appAction = useApplicationAction();

  const { pageSettings, setPageSettings, setPageSettingsForMRTable } =
    usePageSettings<{
      itemsPerPage: number;
      filters?: DeclarationFilters;
      table?: Pick<
        MRT_TableState<DeclarationTableEntity>,
        | "columnSizing"
        | "columnPinning"
        | "columnVisibility"
        | "columnOrder"
        | "sorting"
      >;
    }>({
      itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
    });

  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams<
    TableUrlSearchParams<DeclarationFilters>
  >({
    page: 1,
    itemsPerPage: pageSettings.itemsPerPage,
    sorting: pageSettings.table?.sorting.length
      ? pageSettings.table?.sorting
      : undefined,
    ...(pageSettings.filters && {
      filters: {
        ...pageSettings.filters,
      },
    }),
  });

  const getDeclarationsQueryVariables =
    useMemo<GetDeclarationsTableVariables>(() => {
      return {
        ...urlSearchParams,
        nonclassifiedDocsExists: !classified,
        filters: urlSearchParams.filters,
      };
    }, [urlSearchParams, classified]);
  const {
    data: { declarations, isLastPage },
    loading: declarationsLoading,
    refetch,
  } = useGetDeclarationsTable(getDeclarationsQueryVariables);
  const {
    data: { declarationsDiscussions } = { declarationsDiscussions: [] },
  } = useSubscribeToUnreadMessagesCount({
    variables: {
      fileIds: declarations.map((el) => el.fileId),
    },
  });
  const { edecData } = useApplicationState();

  const isRowDisabled = useCallback(
    (declaration: DeclarationTableEntity) =>
      declaration.declarationStatus === DeclarationStatus.DeclarationCanceled,
    []
  );

  const isClassificationAvailable = (declaration: DeclarationTableEntity) => {
    return !!(
      declaration.nonclassifiedDocsExists &&
      declaration.declarationStatus !== DeclarationStatus.InformationMissing
    );
  };

  const navigateToClassification = (declaration: DeclarationTableEntity) => {
    if (!isClassificationAvailable(declaration)) return;

    sessionStorage.setItem(
      "classify_documents",
      JSON.stringify([declaration.fileId])
    );
    sessionStorage.setItem("classify_for", "declarations");
    history.push(`/select/classification`);
  };

  const isPreviewAvailable = (declaration: DeclarationTableEntity) => {
    return !!declaration?.identification;
  };

  const navigateToPreview = (declaration: DeclarationTableEntity) => {
    if (!isPreviewAvailable(declaration)) return;

    history.push(
      `/select/preview/${encodeURIComponent(declaration.identification!)}`
    );
  };

  const [columns, tableData] = React.useMemo<
    [MRTable.MRT_ColumnDef<DeclarationTableEntity>[], DeclarationTableEntity[]]
  >(
    () => [
      [
        {
          header: t("Jobreference"),
          id: "identification",
          minSize: 225,
          accessorFn: ({ identification }) => identification,
          Cell: ({ row: { original: originalRow } }) => {
            const disabled = isRowDisabled(originalRow);

            return (
              !disabled && (
                <OurReferenceCell
                  editable={!!classified}
                  file_id={originalRow.fileId}
                  identification={originalRow.identification}
                  onChange={refetch}
                />
              )
            );
          },
        },
        {
          header: t("Org Id"),
          id: "orgId",
          accessorFn: ({ orgId }) => orgId,
        },
        {
          header: t("References"),
          id: "addressReferences",
          accessorFn: (originalRow) => originalRow.addressReferences,
        },
        {
          header: t("Creation Date"),
          id: "creationDate",
          accessorFn: (originalRow) =>
            originalRow.creationDate &&
            dayjs(originalRow.creationDate).format("DD.MM.YYYY HH:mm"),
        },
        {
          header: t("Consignor"),
          id: "consignorName",
          accessorFn: (originalRow) => originalRow.consignorName,
        },
        {
          header: t("Consignee"),
          id: "consigneeName",
          accessorFn: (originalRow) => originalRow.consigneeName,
        },
        {
          header: t("Importer Ref"),
          id: "importerRef",
          accessorFn: (originalRow) => originalRow.importerRef,
        },
        {
          header: t("Total Colis"),
          id: "totalColis",
          accessorFn: (originalRow) => originalRow.totalColis?.toString(),
        },
        {
          header: t("Total Gross Weight"),
          id: "totalGrossWeight",
          accessorFn: (originalRow) => originalRow.totalGrossWeight?.toString(),
        },
        {
          header: t("Payer"),
          id: "payerName",
          accessorFn: (originalRow) => originalRow.payerName,
        },
        {
          header: t("Status"),
          id: "declarationStatus",
          accessorFn: (originalRow) => originalRow.declarationStatus,
          Cell: ({
            row: {
              original: { declarationStatus },
            },
          }) => <DeclarationStatusChip declarationStatus={declarationStatus} />,
        },
        {
          header: t("Consignor Ref"),
          id: "consignorRef",
          accessorFn: (originalRow) => originalRow.consignorRef,
        },
        {
          header: t("Consignee Ref"),
          id: "consigneeRef",
          accessorFn: (originalRow) => originalRow.consigneeRef,
        },
        {
          header: t("Payer Ref"),
          id: "payerRef",
          accessorFn: (originalRow) => originalRow.payerRef,
        },
        {
          header: t("Creation User"),
          id: "creationUser",
          accessorFn: (originalRow) => originalRow.creationUser,
        },
        {
          header: t("Toll Dept."),
          id: "customsOfficeNumber",
          accessorFn: (originalRow) => originalRow.customsOfficeNumber,
        },
        {
          header: t("EVVs"),
          id: "evvReceived",
          accessorFn: (originalRow) => originalRow.evvReceived,
          Cell: ({ row: { original: originalRow } }) =>
            originalRow.evvReceived && <CheckIcon color="primary" />,
        },
        ...((!classified
          ? [
              {
                header: t("Autoclassified"),
                id: "autoclassified",
                Cell: ({ row: { original: originalRow } }) =>
                  !!originalRow.autoclassifiedDocsQty && (
                    <CheckIcon color="primary" />
                  ),
                enableSorting: false,
              },
            ]
          : []) as MRTable.MRT_ColumnDef<DeclarationTableEntity>[]),
        {
          header: "",
          id: "message",
          Cell: ({ row: { original: originalRow } }) => {
            const disabled = isRowDisabled(originalRow);

            return (
              <IconButton
                onClick={(event: any) => {
                  event.stopPropagation();
                  appAction.showChat({
                    fileId: originalRow.fileId,
                    title: originalRow.identification || "",
                    discussionId: originalRow.discussionId,
                  });
                }}
                size="medium"
                disabled={disabled}
              >
                <Badge
                  badgeContent={
                    declarationsDiscussions.find(
                      (el) => el.file_id === originalRow.fileId
                    )?.messages.aggregate?.count
                  }
                  invisible={disabled}
                  color={"primary"}
                  sx={{
                    top: 3,
                  }}
                >
                  <ChatOutlined />
                </Badge>
              </IconButton>
            );
          },
          enableSorting: false,
          enableResizing: false,
          enableColumnActions: false,
          size: 50,
          maxSize: 50,
          muiTableHeadCellProps: {},
          muiTableBodyCellProps: {
            align: "center",
          },
        },
      ],
      [...declarations],
    ],
    [
      t,
      declarations,
      language,
      edecData,
      isRowDisabled,
      classified,
      refetch,
      declarationsDiscussions,
    ]
  );

  const pagination = useMemo<MRT_PaginationState>(
    () => ({
      pageIndex: urlSearchParams.page - 1,
      pageSize: urlSearchParams.itemsPerPage,
    }),
    [urlSearchParams.page, urlSearchParams.itemsPerPage]
  );
  const [sorting, setSorting] = useState<MRT_SortingState>(
    pageSettings.table?.sorting ?? []
  );
  const [columnSizing, setColumnSizing] = useState<MRT_ColumnSizingState>(
    pageSettings.table?.columnSizing ?? {}
  );
  const [columnPinning, setColumnPinning] = useState<MRT_ColumnPinningState>(
    pageSettings.table?.columnPinning ?? {
      right: ["message"],
    }
  );
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    pageSettings.table?.columnVisibility ?? {}
  );
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>(
    (() => {
      const defaultColumnsOrder = [
        "mrt-row-expand",
        ...columns.map((c) => c.id!),
      ];
      const cachedColumnsOrder = pageSettings.table?.columnOrder;
      if (
        cachedColumnsOrder &&
        isEqual([...defaultColumnsOrder].sort(), [...cachedColumnsOrder].sort())
      ) {
        return cachedColumnsOrder;
      }

      return defaultColumnsOrder;
    })()
  );
  const [selectedRow, setSelectedRow] = useState<DeclarationTableEntity>();
  const rowSelection = useMemo<MRT_RowSelectionState>(
    () => ({
      ...(selectedRow && {
        [selectedRow.fileId]: true,
      }),
    }),
    [selectedRow]
  );

  useEffect(
    () => {
      setPageSettingsForMRTable({
        itemsPerPage: urlSearchParams.itemsPerPage,
        table: {
          sorting,
          columnSizing,
          columnPinning,
          columnVisibility,
          columnOrder,
        },
      });
    },
    // eslint-disable-next-line
    [
      urlSearchParams.itemsPerPage,
      sorting,
      columnSizing,
      columnPinning,
      columnVisibility,
      columnOrder,
    ]
  );

  return (
    <>
      <DeclarationFiltersSidebar
        filters={urlSearchParams.filters}
        onChange={(newFilters) => {
          setUrlSearchParams({ filters: newFilters, page: 1 });
          setPageSettings({
            filters: newFilters,
          });
        }}
      />
      <MRTable.Table
        columns={columns}
        data={tableData}
        getRowId={(row) => row.fileId}
        state={{
          globalFilter: urlSearchParams.search,
          pagination,
          sorting,
          columnSizing,
          columnPinning,
          columnVisibility,
          columnOrder,
          isLoading: declarationsLoading,
          rowSelection,
        }}
        onRefresh={() => refetch()}
        getIsRowDisabled={(row) => isRowDisabled(row.original)}
        manualSorting
        enableRowActions
        onGlobalFilterChange={(updater) => {
          const newState =
            updater instanceof Function
              ? updater(urlSearchParams.search)
              : updater;
          setUrlSearchParams({ search: newState || undefined, page: 1 });
        }}
        manualPagination
        manualFiltering
        rowCount={Number.MAX_SAFE_INTEGER}
        onPaginationChange={(updater) => {
          const newState =
            updater instanceof Function ? updater(pagination) : updater;
          const newPage = newState.pageIndex + 1;
          if (urlSearchParams.page !== newPage)
            setUrlSearchParams({ page: newPage });

          const newItemsPerPage = newState.pageSize;
          if (urlSearchParams.itemsPerPage !== newState.pageSize)
            setUrlSearchParams({ itemsPerPage: newItemsPerPage, page: 1 });
        }}
        onColumnSizingChange={setColumnSizing}
        onColumnPinningChange={(updater) => {
          const newState =
            updater instanceof Function ? updater(columnPinning) : updater;

          setColumnPinning({
            ...newState,
            right: [
              ...(newState.right?.filter((el) => el !== "message") ?? []),
              "message",
            ],
          });
        }}
        onColumnVisibilityChange={setColumnVisibility}
        onColumnOrderChange={setColumnOrder}
        onSortingChange={(updater) => {
          const newState =
            updater instanceof Function ? updater(sorting) : updater;

          setUrlSearchParams({ sorting: newState });
          setSorting(newState);
        }}
        renderToolbarInternalActions={({ table }) => (
          <>
            <MRTable.MRT_ShowHideColumnsButton table={table} />
            <MRTable.MRT_ToggleFullScreenButton table={table} />
            <Button
              variant="outlined"
              color="primary"
              minimizeForMobiles
              disabled={
                !selectedRow ||
                isRowDisabled(selectedRow) ||
                !(classified
                  ? isPreviewAvailable(selectedRow)
                  : isClassificationAvailable(selectedRow))
              }
              endIcon={classified ? <Folder /> : <ArrowForward />}
              onClick={() =>
                classified
                  ? navigateToPreview(selectedRow!)
                  : navigateToClassification(selectedRow!)
              }
            >
              {t(classified ? "View File" : "Start")}
            </Button>
          </>
        )}
        positionToolbarAlertBanner="none"
        renderRowActions={({ row }) => (
          <RowActions declaration={row.original} />
        )}
        renderDetailPanel={({ row: { original: originalRow } }) => (
          <SubTable fileId={originalRow.fileId} />
        )}
        muiDetailPanelProps={{
          sx: {
            padding: "0px!important",
            width: "100%",
            "& .MuiCollapse-root": {
              width: "100%",
            },
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          ...(!isRowDisabled(row.original) && {
            onClick: (e) => setSelectedRow(row.original),
            onDoubleClick: (e) => {
              if (classified) {
                history.push(
                  `/select/declaration/${encodeURIComponent(
                    row.original.fileId!
                  )}/${row.original.drNo}`
                );
              } else {
                navigateToClassification(row.original);
              }
            },
            selected: selectedRow?.fileId === row.id,
          }),
          sx: {
            ...(!isRowDisabled(row.original) && {
              cursor: "pointer",
            }),
          },
        })}
        muiTableContainerProps={{
          sx: {
            maxHeight: "calc(100vh - var(--appbar-height) - 162px)",
          },
        }}
        muiTablePaperProps={({ table }) => ({
          sx: (theme) => ({
            minWidth: 0,
            width: "100%",
            padding: `${theme.spacing(
              table.getState().isFullScreen ? 1 : 2
            )}!important`,
          }),
        })}
        muiPaginationProps={{
          showFirstButton: true,
          showLastButton: false,
          hideNextButton: isLastPage,
        }}
        displayColumnDefOptions={{
          "mrt-row-expand": {
            muiTableHeadCellProps: {
              sx: {
                "& >*": {
                  visibility: "hidden",
                },
              },
            },
          },
        }}
      />
    </>
  );
};

export default Table;
