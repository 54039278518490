import React from "react";
import { SvgIcon, SvgIconProps } from "@periplus/ui-library";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  root: {
    height: 38,
    width: 131,
  },
});

const PeriPlus = ({ className, ...props }: SvgIconProps) => {
  const { classes, cx } = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 131 38"
      fill="none"
      className={cx(classes.root, className)}
      {...props}
    >
      <path
        d="M23.5422 9.42724C21.5597 9.42724 20.0109 10.4816 19.2674 12.1562C18.8338 13.0865 18.4001 13.5827 17.2849 13.5827C16.4176 13.5827 15.5502
        13.5827 15.5502 13.5827C15.0546 13.5827 14.6829 13.5827 14.6829 13.5827V9.55128V9.48926C14.6829 9.48926 14.7448 9.48926 15.86 8.99309C16.9752
        8.55894 17.9664 7.69064 18.4001 6.57426C19.7631 3.10107 17.0991 0 14.1873 0C11.5852 0 9.41688 2.0467 9.41688 4.6516C9.41688 6.6983 10.594 8.24883
        12.4526 8.99309C13.5058 9.42724 13.5058 9.42724 13.5058 9.42724V13.5206C13.5058 13.5206 13.1341 13.5206 12.7004 13.5206C12.7004 13.5206 9.9125
        13.5206 9.41688 13.5206C9.41688 13.5206 9.35492 13.2105 9.10711 12.4663C8.36367 10.4816 6.44313 9.17915 4.15086 9.42724C2.04445 9.67532 0.309767
        11.4119 0.0619546 13.5206C-0.185858 15.7534 1.11516 17.8001 3.09766 18.4823C3.84109 18.7304 4.02695 18.7925 4.02695 18.7925V26.9172C4.02695 27.4754
        3.65524 28.1577 3.0357 28.4058C0.929298 29.2741 0 30.8866 0 32.9333C0 35.1661 1.79664 37.5849 4.64648 37.5849C7.43438 37.5849 9.41688 35.2281 9.41688
        32.9953C9.41688 30.8866 8.42563 29.3361 6.38117 28.4678C5.82359 28.2197 5.26602 27.5375 5.26602 26.9793V18.8545C5.26602 18.8545 5.51383 18.7925 6.25727
        18.5444C7.55828 18.0482 8.54953 17.0559 9.04516 15.7534C9.29297 15.0092 9.35492 14.7611 9.35492 14.7611C9.9125 14.7611 12.6384 14.7611 12.6384 14.7611C13.0721
        14.7611 13.4438 14.7611 13.4438 14.7611V18.8545C13.4438 18.8545 13.1341 18.8545 12.3287 19.2266C10.4081 19.9709 9.10711 21.9555 9.41688 24.1883C9.66469
        26.297 11.3994 27.9716 13.5058 28.2197C16.3556 28.5298 18.7098 26.3591 18.7098 23.5681C18.7098 21.5834 17.4708 19.9088 15.7361 19.2266C14.9307 18.8545
        14.6829 18.8545 14.6829 18.8545V14.7611C14.6829 14.7611 15.0546 14.7611 15.5502 14.7611H17.2849C18.4001 14.7611 18.8338 15.1332 19.2674 16.0635C20.0109
        17.6761 21.6836 18.7925 23.5422 18.7925C26.1442 18.7925 28.1887 16.6837 28.1887 14.0168C28.1267 11.598 26.0823 9.42724 23.5422 9.42724ZM7.31047 32.2511C7.86805
        34.2358 6.13336 35.9724 4.21281 35.6623C3.09766 35.4762 2.16836 34.5459 1.9825 33.4295C1.67274 31.4448 3.40742 29.7702 5.38992 30.2664C6.31922 30.5145 7.06266
        31.3208 7.31047 32.2511ZM23.9759 16.8078C21.9934 17.1179 20.2587 15.3193 20.8163 13.3346C21.0641 12.4043 21.8075 11.66 22.7368 11.4739C24.7193 10.9778 26.454
        12.5903 26.1442 14.575C26.0203 15.6914 25.091 16.6217 23.9759 16.8078Z"
        fill="#737A96"
      />
      <path
        d="M41.7967 29.184V15.312C41.7967 14.752 41.7807 14.184 41.7487 13.608C41.7327 13.016 41.6847 12.44 41.6047 11.88H44.4367L44.7007 14.28L44.5327 14.424C44.7887
        13.528 45.2927 12.832 46.0447 12.336C46.7967 11.824 47.6847 11.568 48.7087 11.568C49.7647 11.568 50.6927 11.84 51.4927 12.384C52.3087 12.912 52.9487 13.656
        53.4127 14.616C53.8767 15.56 54.1087 16.672 54.1087 17.952C54.1087 19.216 53.8767 20.32 53.4127 21.264C52.9487 22.192 52.3167 22.912 51.5167 23.424C50.7167
        23.92 49.7807 24.168 48.7087 24.168C47.6847 24.168 46.7967 23.928 46.0447 23.448C45.2927 22.952 44.7887 22.256 44.5327 21.36L44.7967 20.976V29.184H41.7967ZM47.9407
        21.864C48.9327 21.864 49.7007 21.528 50.2447 20.856C50.7887 20.168 51.0607 19.2 51.0607 17.952C51.0607 16.672 50.7887 15.68 50.2447 14.976C49.7007 14.272 48.9327
        13.92 47.9407 13.92C46.9167 13.92 46.1327 14.264 45.5887 14.952C45.0607 15.624 44.7967 16.608 44.7967 17.904C44.7967 19.168 45.0607 20.144 45.5887 20.832C46.1327
        21.52 46.9167 21.864 47.9407 21.864ZM67.0955 22.656C66.5195 23.136 65.8075 23.512 64.9595 23.784C64.1275 24.04 63.2795 24.168 62.4155 24.168C61.1035 24.168 59.9675
        23.92 59.0075 23.424C58.0635 22.928 57.3355 22.208 56.8235 21.264C56.3115 20.32 56.0555 19.2 56.0555 17.904C56.0555 16.656 56.3035 15.56 56.7995 14.616C57.2955
        13.656 57.9835 12.912 58.8635 12.384C59.7435 11.84 60.7595 11.568 61.9115 11.568C63.0315 11.568 63.9915 11.816 64.7915 12.312C65.5915 12.792 66.2075 13.488
        66.6395 14.4C67.0875 15.312 67.3115 16.392 67.3115 17.64V18.096H58.6955L58.7195 16.488H65.5355L64.7195 17.304C64.7355 16.136 64.5035 15.248 64.0235 14.64C63.5595
        14.032 62.8795 13.728 61.9835 13.728C61.0235 13.728 60.2795 14.072 59.7515 14.76C59.2235 15.432 58.9595 16.4 58.9595 17.664C58.9595 19.104 59.2555 20.168 59.8475
        20.856C60.4395 21.528 61.3355 21.864 62.5355 21.864C63.1915 21.864 63.8315 21.76 64.4555 21.552C65.0955 21.328 65.6875 21.008 66.2315 20.592L67.0955 22.656ZM69.8279
        24V15.312C69.8279 14.752 69.8119 14.184 69.7799 13.608C69.7639 13.016 69.7159 12.44 69.6359 11.88H72.4679L72.8039 15L72.4679 14.832C72.6759 13.76 73.1479 12.952
        73.8839 12.408C74.6199 11.864 75.4519 11.592 76.3799 11.592C76.6199 11.592 76.8439 11.608 77.0519 11.64C77.2599 11.672 77.4439 11.72 77.6039 11.784L77.5559 14.52C77.0599
        14.312 76.5319 14.208 75.9719 14.208C75.2519 14.208 74.6599 14.352 74.1959 14.64C73.7479 14.928 73.4039 15.32 73.1639 15.816C72.9399 16.296 72.8279 16.84 72.8279
        17.448V24H69.8279ZM79.2264 24V11.88H82.2264V24H79.2264ZM79.0824 6.384H82.4184V9.336H79.0824V6.384ZM85.4608 29.184V15.312C85.4608 14.752 85.4448 14.184 85.4128
        13.608C85.3968 13.016 85.3488 12.44 85.2688 11.88H88.1008L88.3648 14.28L88.1968 14.424C88.4528 13.528 88.9568 12.832 89.7088 12.336C90.4608 11.824 91.3488 11.568
        92.3728 11.568C93.4288 11.568 94.3568 11.84 95.1568 12.384C95.9728 12.912 96.6128 13.656 97.0768 14.616C97.5408 15.56 97.7728 16.672 97.7728 17.952C97.7728 19.216
        97.5408 20.32 97.0768 21.264C96.6128 22.192 95.9808 22.912 95.1808 23.424C94.3808 23.92 93.4448 24.168 92.3728 24.168C91.3488 24.168 90.4608 23.928 89.7088 23.448C88.9568
        22.952 88.4528 22.256 88.1968 21.36L88.4608 20.976V29.184H85.4608ZM91.6048 21.864C92.5968 21.864 93.3648 21.528 93.9088 20.856C94.4528 20.168 94.7248 19.2 94.7248
        17.952C94.7248 16.672 94.4528 15.68 93.9088 14.976C93.3648 14.272 92.5968 13.92 91.6048 13.92C90.5808 13.92 89.7968 14.264 89.2528 14.952C88.7248 15.624 88.4608
        16.608 88.4608 17.904C88.4608 19.168 88.7248 20.144 89.2528 20.832C89.7968 21.52 90.5808 21.864 91.6048 21.864ZM100.344 24V6.336H103.344V24H100.344ZM110.97 24.168C109.482
        24.168 108.362 23.76 107.61 22.944C106.874 22.112 106.506 20.864 106.506 19.2V11.88H109.506V19.224C109.506 20.104 109.69 20.752 110.058 21.168C110.426 21.584
        110.978 21.792 111.714 21.792C112.594 21.792 113.298 21.504 113.826 20.928C114.354 20.352 114.618 19.584 114.618 18.624V11.88H117.642V24H114.714V21.504L115.026
        21.48C114.674 22.36 114.146 23.032 113.442 23.496C112.738 23.944 111.914 24.168 110.97 24.168ZM125.315 24.168C123.107 24.168 121.363 23.664 120.083 22.656L120.923
        20.544C121.563 21.024 122.259 21.384 123.011 21.624C123.763 21.864 124.547 21.984 125.363 21.984C126.099 21.984 126.659 21.864 127.043 21.624C127.427 21.384 127.619
        21.048 127.619 20.616C127.619 20.248 127.483 19.96 127.211 19.752C126.939 19.528 126.475 19.336 125.819 19.176L123.803 18.72C122.699 18.48 121.867 18.08 121.307
        17.52C120.763 16.944 120.491 16.216 120.491 15.336C120.491 14.6 120.699 13.952 121.115 13.392C121.531 12.816 122.115 12.368 122.867 12.048C123.619 11.728 124.491
        11.568 125.483 11.568C126.347 11.568 127.179 11.704 127.979 11.976C128.779 12.232 129.475 12.608 130.067 13.104L129.203 15.168C128.595 14.704 127.971 14.36 127.331
        14.136C126.707 13.912 126.083 13.8 125.459 13.8C124.771 13.8 124.235 13.928 123.851 14.184C123.467 14.44 123.275 14.792 123.275 15.24C123.275 15.592 123.387 15.872
        123.611 16.08C123.851 16.288 124.259 16.456 124.835 16.584L126.875 17.088C128.075 17.344 128.947 17.76 129.491 18.336C130.051 18.896 130.331 19.64 130.331 20.568C130.331
        21.672 129.883 22.552 128.987 23.208C128.091 23.848 126.867 24.168 125.315 24.168Z"
        fill="#737A96"
      />
    </SvgIcon>
  );
};

export default PeriPlus;
