import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useQuery, QueryHookOptions } from "@apollo/client";
import { Document } from "./useGetDocuments";
import { mapPages } from "../helpers";
import { GetDeclarationWithDocumentsQuery } from "graphql/generated";

type ClassifyDeclarationRequestEntityOriginal = NonNullable<
  GetDeclarationWithDocumentsQuery["declaration_request"]
>[number];
type ClassifyDeclarationRequestAddressEntityOriginal =
  ClassifyDeclarationRequestEntityOriginal["declaration_request_addresses"][number];
type ClassifyDeclarationRequestAddressAddressEntityOriginal =
  ClassifyDeclarationRequestAddressEntityOriginal["address"];

export type ClassifyDeclarationRequestEntity = Omit<
  ClassifyDeclarationRequestEntityOriginal,
  "declaration_request_addresses"
> & {
  declaration_request_addresses: (ClassifyDeclarationRequestAddressEntityOriginal & {
    address: Omit<
      ClassifyDeclarationRequestAddressAddressEntityOriginal,
      "is_locked" | "usage_frequency"
    > & {
      is_locked: NonNullable<
        NonNullable<ClassifyDeclarationRequestAddressAddressEntityOriginal>["is_locked"]
      >;
      usage_frequency: NonNullable<
        NonNullable<ClassifyDeclarationRequestAddressAddressEntityOriginal>["usage_frequency"]
      >;
    };
  })[];
};

export type File = {
  identification: string;
  documents: Document[];
};

export type ServerDeclaration = {
  file: File;
  reference: string;
  declaration_discussions: {
    discussion_id?: number;
    creation_user?: string;
  }[];
};

export type Declaration = {
  file: File;
  reference: string;
  discussion: {
    discussion_id?: number;
    creation_user?: string;
  };
  payer: {
    id: string;
  };
  declaration: any;
} & Pick<
  ClassifyDeclarationRequestEntity,
  "declaration_request_addresses" | "eta" | "toll_dep"
>;

export interface DeclarationRequest {
  declarations: Declaration[];
}

export const GET_DECLARATION_WITH_DOCUMENTS = gql`
  query getDeclarationWithDocuments($declarationId: _uuid) {
    declaration_request: pp_declaration_request(
      args: { _file_ids: $declarationId }
    ) {
      file {
        id
        identification
        documents(
          where: {
            pages_m2m: { page: { page_status: { _neq: "classified" } } }
            document_status: { _neq: "classified" }
            is_deleted: { _eq: false }
          }
        ) {
          id
          name: document_name
          meta
          document_status
          document_type
          pages: pages_m2m(
            order_by: { page_number: asc }
            where: { page: { page_status: { _neq: "classified" } } }
          ) {
            page {
              id
              thumbnailBigUrl: thumbnail_big_url
              page_status
              rotate
              meta
              creation_user
            }
            page_number
          }
          document_blobs
          document_flags
        }
      }
      declaration_discussions {
        discussion_id
        creation_user
      }
      payer {
        id
        tenantName: tenant_name
      }
      toll_dep
      eta
      declaration_request_addresses {
        address_id
        refs
        address_type
        address {
          id
          company_name
          company_name_2
          country
          street
          city
          zipcode
          reference
          street_number
          customs_account_number
          is_locked
          uid_number
          usage_frequency
          vat_account_number
          trader_identification_number
          is_platform_user
        }
      }
      declaration {
        total_gross_weight
        total_colis
        customs_office_number
      }
    }
  }
`;

const useGetDeclaration = (
  declarationId: string[],
  options: QueryHookOptions = {}
) => {
  const { data, loading, error, refetch } = useQuery(
    GET_DECLARATION_WITH_DOCUMENTS,
    {
      variables: {
        declarationId: declarationId?.length
          ? `{${declarationId.join(",")}}`
          : undefined,
      },
      ...options,
      fetchPolicy: "no-cache",
    }
  );

  const returnValue = useMemo(() => {
    const declarationRequest = (data?.declaration_request || []).map(
      (dRequest: ServerDeclaration) => ({
        ...dRequest,
        discussion: dRequest.declaration_discussions[0],

        file: {
          ...dRequest?.file,

          documents: dRequest?.file.documents.map((document) => ({
            ...document,
            pages: mapPages(document.pages),
          })),
        },
      })
    );

    return {
      data: {
        declarations: declarationRequest,
      } as DeclarationRequest,
      loading,
      error,
      refetch,
    };
  }, [data, loading, error, refetch]);

  return returnValue;
};

export default useGetDeclaration;
