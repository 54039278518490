import React from "react";

import Text from "components/Text/Text";

/**
 * A 404 (Not found) page
 */
const NotFoundPage: React.FC = () => {
  console.log("**************** not found triggered");
  return <Text variant="h1">404! $t(notFound)!</Text>;
};

export default NotFoundPage;
