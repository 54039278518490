import React from "react";
import { Grid } from "@periplus/ui-library";
import waves from "../images/waves.svg";
import adit from "../images/adit.svg";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  root: {
    height: "100%",
    maxWidth: 236,
    boxShadow: "5px 0px 15px rgb(34 30 205 / 10%)",
  },
  productLogoContainer: {
    padding: 38,
  },
  waves: {
    backgroundImage: `url(${waves})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right",
    minHeight: 230,
  },
});

const LeftPanel = () => {
  const { classes } = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.root}
    >
      <Grid
        container
        justifyContent="center"
        className={classes.productLogoContainer}
      >
        <img src={adit} alt="Product logo" />
      </Grid>
      <Grid item className={classes.waves}></Grid>
    </Grid>
  );
};

export default LeftPanel;
