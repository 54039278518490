import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { Tenant } from "./type";

const useGetTenant = (variables: { id: string }) => {
  const result = useQuery<{
    tenant: Tenant;
  }>(
    gql`
      query ADIT_GET_TENANT($id: uuid!) {
        tenant: tenant_by_pk(id: $id) {
          tenant_name
          id
          address_id
          address {
            country
            city
            street
            street_number
            zipcode
            address_type
          }
          trader_identification_number
        }
      }
    `,
    {
      variables,
      fetchPolicy: "no-cache",
    }
  );

  return result;
};

export default useGetTenant;
