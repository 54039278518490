import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";

export type HistoryFileType = {
  entityId: string;
  op_date: string;
  action_title: string;
  action_value: any;
  action_user: string;
  [key: string]: any;
};

export type HistoryDocumentType = {
  entityId: string;
  op_date: string;
  action_title: string;
  action_value: any;
  action_user: string;
  [key: string]: any;
};

export interface HistoryResult {
  file_history: HistoryFileType[];
  document_history: HistoryDocumentType[];
}

export const GET_FULL_FILE_HISTORY = gql`
  query GET_FULL_FILE_HISTORY($fileId: uuid, $documentIds: _uuid) {
    file_history: pp_view_file_history(
      args: { _file_id: $fileId }
      where: {
        action_title: {
          _nin: [
            "other"
            "file_transmission_generated"
            "document_added"
            "transmission_failed"
          ]
        }
      }
    ) {
      action_title
      action_value
      entityId: id
      op_date
      action_user
    }
    document_history: pp_view_document_history(
      args: { _ids: $documentIds }
      where: {
        action_title: {
          _nin: [
            "other"
            "document_flags_changed"
            "document_thumbnails_generated"
            "document_thumbnails_generated_frontend"
            "document_thumbnails_pending"
            "document_classified"
          ]
        }
      }
    ) {
      action_title
      action_value
      entityId: id
      op_date
      action_user
      document {
        sequence
        documentType {
          translations
        }
      }
    }
  }
`;

const useGetFullFileHistory = () => {
  const result = useLazyQuery<HistoryResult>(GET_FULL_FILE_HISTORY);

  return result;
};

export default useGetFullFileHistory;
