import { LanguageType } from "i18n";

const defaultLanguage = "en" as LanguageType;
const usedLanguages = ["en", "de"] as LanguageType[];

const development = {
  keycloak: {
    url: "https://authentication-staging.api.adit.periplus.ch/auth",
    realm: "adit",
    clientId: "adit-spa",
    onLoad: "login-required",
    redirectUrl: "",
  },
  hasura: "http://localhost:1010/v1/graphql",
  blobStorage: {
    storageAccountName: "aditdocuments",
  },
  prefixMRSNumber: "MRT",
  maxFileSize: process.env.REACT_APP_MAX_FILE_SIZE || 100,
  defaultLanguage,
  usedLanguages,
  wedclare: "http://staging.wedeclare.ch",
};

const test = {
  keycloak: {
    url: "https://authentication-staging.api.adit.periplus.ch/auth",
    realm: "adit",
    clientId: "adit-spa",
    onLoad: "login-required",
    redirectUrl: "",
  },
  hasura: "http://localhost:8080/v1/graphql",
  blobStorage: {
    storageAccountName: "aditdocuments",
  },
  prefixMRSNumber: "MRT",
  maxFileSize: process.env.REACT_APP_MAX_FILE_SIZE || 100,
  defaultLanguage,
  usedLanguages,
  wedclare: "http://staging.wedeclare.ch",
};

const demo = {
  keycloak: {
    url: "https://authentication-demo.api.adit.periplus.ch/auth",
    realm: "adit",
    clientId: "adit-spa",
    onLoad: "login-required",
    redirectUrl: "",
  },
  hasura: "https://demo.api.adit.periplus.ch/v1/graphql",
  blobStorage: {
    storageAccountName: "aditdocuments",
  },
  prefixMRSNumber: "MRT",
  maxFileSize: process.env.REACT_APP_MAX_FILE_SIZE || 100,
  defaultLanguage,
  usedLanguages,
  wedclare: "http://demo.wedeclare.ch",
};

const staging = {
  keycloak: {
    url: "https://authentication-staging.api.adit.periplus.ch/auth",
    realm: "adit",
    clientId: "adit-spa",
    onLoad: "login-required",
    redirectUrl: "",
  },
  hasura: "https://staging.api.adit.periplus.ch/v1/graphql",
  blobStorage: {
    storageAccountName: "aditdocuments",
  },
  prefixMRSNumber: "MRT",
  maxFileSize: process.env.REACT_APP_MAX_FILE_SIZE || 100,
  defaultLanguage,
  usedLanguages,
  wedclare: "http://staging.wedeclare.ch",
};

const production = {
  keycloak: {
    url: "https://authentication.api.adit.periplus.ch/auth",
    realm: "adit",
    clientId: "adit-spa",
    onLoad: "login-required",
    redirectUrl: ""
  },
  hasura: "https://production.api.adit.periplus.ch/v1/graphql",
  blobStorage: {
    storageAccountName: "aditdocumentsproduction",
  },
  prefixMRSNumber: "MRS",
  maxFileSize: process.env.REACT_APP_MAX_FILE_SIZE || 100,
  defaultLanguage,
  usedLanguages,
  wedclare: "https://portal.wedeclare.ch",
};

// Default to development if not set
function getConfig() {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "development":
      return development;
    case "test":
      return test;
    case "staging":
      return staging;
    case "production":
      return production;
    case "demo":
      return demo;
    default:
      return development;
  }
}

const isProdMode = process.env.REACT_APP_ENVIRONMENT === "production";

// Default to development if not set
const config = getConfig();

const configs = {
  // All "common", general config or initial values belong here...
  START_MESSAGE: "Hello World",
  graphQl: {
    url: config.hasura || "http://localhost:5000",
  },
  isProdMode,
  ...config,
};

export default configs;
