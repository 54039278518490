import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";

import useErrorHandling from "hooks/useErrorHandling";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export default () => {
  const withErrorHandling = useErrorHandling();
  const [mutation] = useMutation(gql`
    mutation AditExportDeclaration($fileId: String!, $drNo: Float!) {
      exportDeclaration(
        exportDeclarationInput: { fileId: $fileId, drNo: $drNo }
      )
    }
  `);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useCallback(
    async (fileId: string, drNo: number) => {
      await withErrorHandling(mutation, {
        variables: { fileId, drNo },
      });
      enqueueSnackbar(t("common:success"), { variant: "success" });
    },
    [mutation, withErrorHandling]
  );
};
