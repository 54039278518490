import { useState, useEffect } from "react";
import client from "graphql/client";
import { GET_SAS_TOKEN } from "graphql/queries/common";

type SasToken = { token: string; expiry: number };

const getSasTokenCache = (cacheKey: string): SasToken | undefined =>
  (
    JSON.parse(
      window.sessionStorage.getItem("sasTokensCache") || "{}"
    ) as Record<string, SasToken>
  )[cacheKey];
const setSasTokenCache = (cacheKey: string, newSasToken: SasToken) => {
  window.sessionStorage.setItem(
    "sasTokensCache",
    JSON.stringify({
      ...JSON.parse(window.sessionStorage.getItem("sasTokensCache") || "{}"),
      [cacheKey]: newSasToken,
    })
  );
};

const useSasToken = ({
  container,
  contentType,
  contentDisposition,
}: {
  container: string;
  contentType?: string;
  contentDisposition?: string;
}) => {
  const cacheKey = JSON.stringify({
    container,
    contentType,
    contentDisposition,
  });
  const [sasToken, setSasToken] = useState(getSasTokenCache(cacheKey)?.token);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const generateSasToken = async () => {
      const { data } = await client.query({
        query: GET_SAS_TOKEN,
        fetchPolicy: "no-cache",
        variables: {
          container,
          contentType,
          contentDisposition,
        },
      });
      const newSasToken = data.getSASToken.sasToken as string;
      const newExpiry =
        new Date(data.getSASToken.expiryDate).getTime() - 30 * 1000;

      setSasTokenCache(cacheKey, { token: newSasToken, expiry: newExpiry });
      setSasToken(newSasToken);
      timeoutId = setTimeout(() => {
        generateSasToken();
      }, newExpiry - Date.now());
    };

    const expiry = getSasTokenCache(cacheKey)?.expiry;
    timeoutId = setTimeout(
      () => {
        generateSasToken();
      },
      expiry ? expiry - Date.now() : 0
    );

    return () => {
      clearTimeout(timeoutId);
    };
  }, [cacheKey]);

  return sasToken;
};

export default useSasToken;
