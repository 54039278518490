import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import { DocumentTypeTenantSettingDB } from "./types";

const ADIT_UPSERT_DOCUMENT_TYPE_TENANT_SETTING = gql`
  mutation ADIT_UPSERT_DOCUMENT_TYPE_TENANT_SETTING(
    $document_type_tenant_setting: document_type_tenant_settings_insert_input!
  ) {
    insert_document_type_tenant_settings(
      on_conflict: {
        constraint: document_type_tenant_settings_pkey
        update_columns: [code, meta, default_flags]
      }
      objects: [$document_type_tenant_setting]
    ) {
      affected_rows
    }
  }
`;

const useUpsertDocumentTypeTenantSetting = () => {
  const [mutation] = useMutation<
    { affected_rows: number },
    { document_type_tenant_setting: DocumentTypeTenantSettingDB }
  >(ADIT_UPSERT_DOCUMENT_TYPE_TENANT_SETTING);

  return mutation;
};

export default useUpsertDocumentTypeTenantSetting;
