import React, { FC, ReactElement } from "react";
import { SetDeclarationFormFieldRefFunction } from "../../Declaration";

export interface FormFieldInputProps {
  name: string;
  setFormFieldRef: SetDeclarationFormFieldRefFunction;
  onFocus?: (name: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  fillByClickMode?: boolean;
  children: (params: {
    name: string;
    disabled?: boolean;
    onFocus: () => void;
    onBlur: () => void;
    size: "small" | "medium";
    inputRef: (instance: HTMLElement | null) => void;
  }) => ReactElement;
}

const FormFieldInput: FC<FormFieldInputProps> = ({
  name,
  disabled,
  setFormFieldRef,
  onFocus,
  onBlur,
  fillByClickMode,
  children,
}) => {
  const handleFocus = React.useCallback(() => {
    onFocus?.(name);
  }, [name, onFocus]);
  const handleBlur = React.useCallback(() => {
    onBlur?.();
  }, [onBlur]);

  const handleSetRef = React.useCallback(
    (instance: HTMLElement | null) => {
      setFormFieldRef(name, instance, fillByClickMode);
    },
    [fillByClickMode, name]
  );

  return children({
    name,
    disabled,
    onFocus: handleFocus,
    onBlur: handleBlur,
    size: "medium",
    inputRef: handleSetRef,
  });
};

export default FormFieldInput;
