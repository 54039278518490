import React, { useState, useContext} from "react";
import client from "../graphql/client";
import { GET_SAS_TOKEN } from "../graphql/queries/common";

export interface ISasContext {
  updateToken: CallableFunction;
  getDownloadUrl: CallableFunction;
}

const initialSasContext: ISasContext = {
  updateToken: () => console.info("Initializing..."),
  getDownloadUrl: () => console.info("Initializing...")
};

export const SasContext = React.createContext<ISasContext>(initialSasContext);

export const useSas = () => useContext(SasContext);

export const SasProvider = ({ children }: any) => {
  const [isExpired, setExpired] = useState<string>("");

  const updateToken = (): void => {
    const fetchData = async () => {
      const { data } = await client.query({
        query: GET_SAS_TOKEN,
        fetchPolicy: "no-cache",
        variables: {
          "container": "thumbs",
          "contentType": "application-pdf"
        },
      });

      if(data && isExpired !== "undefined") {
        const stringifyValue = JSON.stringify({
          value: data.getSASToken.sasToken,
          expiryDate: data.getSASToken.expiryDate,
        });
        window.sessionStorage.setItem("thumbs", stringifyValue);
        setExpired(new Date().getTime().toString());
      }
    }
    fetchData();
  };

  const getDownloadUrl = (fileUrl: string, contentType?: string): string => {
    const sasToken = window.sessionStorage.getItem("thumbs");
    const { value, expiryDate } = JSON.parse(sasToken || "{}");

    const tokenExpiryTimeInMilliseconds =
      //@ts-ignore
      new Date(expiryDate).getTime() - new Date().getTime();
    const tokenExpiryTimeInMinutes =
      tokenExpiryTimeInMilliseconds / 1000 / 60;

    if (tokenExpiryTimeInMinutes > 0) {
      return `${fileUrl}?${value || ""}`;
    }
    updateToken();  
    return "";
  };
  
  return (
    <SasContext.Provider
      value={{
        getDownloadUrl,
        updateToken
      }}
    >
      {children}
    </SasContext.Provider>
  );
};