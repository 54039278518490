import { Address, AddressType } from "domain/address/types";
import { File } from "domain/file/types";
import { AditGetDeclarationQuery } from "graphql/generated";

export type DeclarationFormDB =
  AditGetDeclarationQuery["declarationsForm"][number];
export type DeclarationAddressFormDB = DeclarationFormDB["addresses"][number];
export type DeclarationPreviousDocumentFormDB =
  DeclarationFormDB["previous_documents"][number];
export type DeclarationContainerFormDB =
  DeclarationFormDB["containers"][number];
export type DeclarationSpecialMentionFormDB =
  DeclarationFormDB["declaration_special_mentions"][number];
export type DeclarationGoodsItemFormDB =
  DeclarationFormDB["goods_items"][number];
export type DeclarationGoodsItemPackagingFormDB =
  DeclarationGoodsItemFormDB["packagings"][number];
export type DeclarationGoodsItemProducedDocumentFormDB =
  DeclarationGoodsItemFormDB["produced_documents"][number];

export interface DeclarationDiscussion {
  discussion_id: number;
  creation_user: string;
  declaration_messages_aggregate?: {
    aggregate?: {
      count?: number;
    };
  };
}

export enum ClearanceType {
  Transit = "transit",
  Temporary = "temporary",
  Definitive = "definitive",
}

export interface DrGoodItem {
  file_id: string;
  dr_no: number;
  trader_item_id: number;
  description: string | null;
  commodity_code: string | null;
  gross_mass: number | null;
  net_mass: number | null;
  statistic: {
    repair: number;
    commercialGood: number;
    statisticalValue: number;
    customsClearanceType: number;
  } | null;
  origin: {
    preference: number;
    originCountry: string;
  } | null;
  valuation: {
    VATCode: number;
    VATValue: number;
  } | null;
  amount: {
    currency: string;
    invoiceValue: number;
  } | null;
  traded_goods: boolean | null;
  customs_net_mass: number | null;
  key: number | null;
  additional_pcs: number | null;
  net_declaration: boolean | null;
  revision: boolean | null;
  packaging: DrGoodItemPackagingItem[];
  source: number;
  statistical_code: string | null;
}

export interface DrGoodItemPackagingItem {
  packagingType: string;
  quantity: number;
  packagingReferenceNumber: string;
}

export interface DeclarationRequest {
  file_id: string;
  file: File;
  dr_no: number;
  addresses: {
    address_id: number;
    address_type: AddressType;
    address: Address;
    refs: string[];
  }[];
  dr_goods_items: DrGoodItem[];
}

export const DECLARATION_STATUSES = {
  documents_recieved: {
    color: "#FFB800",
  },
  in_progress: {
    color: "#604DFF",
  },
  declaration_pending: {
    color: "#22AEF8",
  },
  information_missing: {
    color: "#EB5757",
  },
  declaration_exported: {
    color: "#32D376",
  },
  declaration_exported_reference_missing: {
    color: "#c7cc58",
  },
  declaration_canceled: {
    color: "#D73333",
  },
  declaration_revoked: {
    color: "#ff65c0",
  },
  arrival_confirmed: {
    color: "grey",
  },
};

export type DeclarationStatusType = keyof typeof DECLARATION_STATUSES;
