const typeMap: { [key: number]: string } = {
  0: "file",
  1: "journey",
  2: "folder",
};

interface IFile {
  type: number;
  name: string;
  identification: string;
}

export function updateFileName<T extends IFile>(files: T[]) {
  return files.map((file: T) => ({
    ...file,
    displayName: file.type === 2 ? file.name : file.identification,
    typeName: typeMap[file.type],
  }));
}
