import React, {
  memo,
  useRef,
  useEffect,
  forwardRef,
  ReactElement,
  useCallback,
  useLayoutEffect,
} from "react";
import { Box } from "@periplus/ui-library";
import Virtualization, { areEqual } from "components/Virtualization";

const INITIAL_IMAGE_WIDTH = 595;
const INITIAL_IMAGE_HEIGHT = 842;

const renderChild = memo(
  ({
    data: { data: pages, onFocus, renderItem },
    index,
    style,
  }: {
    data: any;
    index: number;
    style: any;
  }) => {
    return (
      <Box
        style={{ ...style, borderRadius: "5px", overflow: "hidden" }}
        onClickCapture={(e) => {
          if (onFocus) {
            e.stopPropagation();
            onFocus(pages[index], index);
          }
        }}
      >
        {renderItem({ itemData: pages[index], index })}
      </Box>
    );
  },
  areEqual
);
const getItemSize = (width: number): number => {
  return (width * INITIAL_IMAGE_HEIGHT) / INITIAL_IMAGE_WIDTH;
};

interface PdfViewerProps {
  pages: any[];
  width: number;
  height: number;
  children: (args: { itemData: any; index: number }) => ReactElement;
  scrollTo?: { index?: number; position?: "auto" | "start" | "end" };
  onPageScrolled?: (page: any, index: number) => void;
  onFocus?: (page: any, index: number) => void;
  currentItem?: number;
  additionalData?: {
    [key: string]: any;
  };
  [key: string]: any;
}

const PdfViewer = forwardRef(
  (
    {
      pages,
      children,
      scrollTo = {},
      onPageScrolled,
      onFocus,
      currentItem = 0,
      additionalData = {},
      width,
      height,
      ...rest
    }: PdfViewerProps,
    ref: any
  ) => {
    const imageRef = useRef(null);

    useEffect(() => {
      const image = imageRef.current;
      if (image && scrollTo.index != null) {
        // @ts-ignore
        image.scrollToItem.call(image, scrollTo.index, scrollTo.position);
      }
    }, [scrollTo]);

    useLayoutEffect(() => {
      if (ref) {
        ref.current = imageRef.current;
      }
    }, [ref]);

    const onScroll = useCallback(
      ({ scrollDirection, scrollOffset }) => {
        const listComponent = imageRef.current;

        if (!listComponent) {
          return;
        }

        const {
          // @ts-ignore
          props: { itemSize },
        } = listComponent;

        const currentPosition = scrollOffset;
        const itemPosition = currentPosition / itemSize;

        const intPart = Math.floor(itemPosition);
        const decimalPart = itemPosition - intPart;

        const setElementPosition = (elementPosition: number) => {
          if (currentItem !== elementPosition) {
            // eslint-disable-next-line
            currentItem = elementPosition;
            if (onPageScrolled) {
              onPageScrolled(pages[currentItem], currentItem);
            }
          }
        };

        // scroll top
        if (scrollDirection === "forward") {
          setElementPosition(decimalPart > 0.8 ? intPart + 1 : intPart);
        }

        // scroll bottom
        if (scrollDirection === "backward") {
          setElementPosition(decimalPart < 0.6 ? intPart : currentItem);
        }
      },
      [onPageScrolled, pages]
    );

    return (
      <Virtualization
        itemData={{
          data: pages,
          renderItem: children,
          onFocus,
          ...additionalData,
        }}
        itemSize={getItemSize(width - 32)}
        ref={imageRef}
        itemsSpacing={16}
        itemWidthSpacing={16}
        width={width}
        height={height}
        onScroll={onScroll}
        {...rest}
      >
        {renderChild}
      </Virtualization>
    );
  }
);

export default PdfViewer;
