import { gql } from "@apollo/client";

export const VALIDATE_FILE_NUMBER = gql`
  mutation ValidateFileNumber($number: String!) {
    validateFileNumber(number: $number) {
      error_code
      error_description
      data {
        orgId
        shipmentNumber
        traffic
        lines {
          grossWeight
          packaging
          quantity
        }
        consignee {
          adrID
          city
          consigneeAdrID
          country
          name
          postalCode
          state
          street
        }
        shipper {
          adrID
          city
          consigneeAdrID
          country
          name
          postalCode
          state
          street
        }
        orderedBy {
          adrID
          city
          consigneeAdrID
          country
          name
          postalCode
          state
          street
        }
      }
    }
  }
`;

export const TRANSMIT_FILE = gql`
  mutation transmitFile($data: TransmitFileInput!) {
    transmitFile(data: $data) {
      success
      error
      fileIds
    }
  }
`;

export const SCHEDULE_FILE = gql`
  mutation scheduleFiles($fileIds: [ID!]!) {
    scheduleFiles(fileIds: $fileIds)
  }
`;

export const SCHEDULE_DATA_EXTRACTION = gql`
  mutation ADIT_SCHEDULE_DATA_EXTRACTION($fileId: ID!, $drNo: Int!) {
    scheduleAldiSrvProcessing(fileId: $fileId, drNo: $drNo)
  }
`;
