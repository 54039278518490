import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import { DocumentTypeDB } from "domain/documentType/types";
import { gql } from "@apollo/client";
import TenantDocumentType from "./TenantDocumentType";
import { DocumentTypeFlagDB } from "domain/documentTypeFlag/DocumentTypeFlag";

const ADIT_GET_TENANT_DOCUMENT_TYPES = gql`
  query ADIT_GET_TENANT_DOCUMENT_TYPES(
    $tenantId: uuid!
    $limit: Int
    $offset: Int
    $where: document_type_bool_exp!
  ) {
    document_type(
      order_by: { creation_date: asc }
      where: $where
      limit: $limit
      offset: $offset
    ) {
      document_type_tenant_settings(where: { tenant: { _eq: $tenantId } }) {
        tenant
        code
        default_flags
        meta
        last_modified_date
      }
      name
      display_name
      translations
    }
    document_type_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    document_flag {
      id
      name
      translation
      color
    }
  }
`;

const useGetTenantDocumentTypes = ({
  tenantId,
  search,
  page,
  itemsPerPage,
}: {
  tenantId?: string;
  search?: string;
  page?: number;
  itemsPerPage?: number;
} = {}) => {
  const { user } = useAuth();

  const variables = {
    tenantId: (tenantId || user?.tenantId) as string,
    ...(page &&
      itemsPerPage && {
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
      }),
    where: {
      document_type_tenant_settings: { code: { _is_null: false } },
      ...(search && {
        name: { _like: `%${search}%` },
      }),
    },
  };
  const { data, ...rest } = useQuery<{
    document_type: DocumentTypeDB[];
    document_type_aggregate: {
      aggregate: {
        count: number;
      };
    };
    document_flag: DocumentTypeFlagDB[];
  }>(ADIT_GET_TENANT_DOCUMENT_TYPES, {
    variables,
  });

  const {
    i18n: { language },
  } = useTranslation();

  const { tenantDocumentTypes, tenantDocumentTypesTotalCount } = useMemo(() => {
    return {
      tenantDocumentTypes:
        data?.document_type?.map(
          (dbDocumentType) =>
            new TenantDocumentType(
              {
                tenant_id: variables.tenantId,
                document_type: dbDocumentType,
                document_type_flags: data.document_flag,
              },
              { language }
            )
        ) || [],
      tenantDocumentTypesTotalCount:
        data?.document_type_aggregate.aggregate.count || 0,
    };
  }, [data, language, variables.tenantId]);

  return {
    tenantDocumentTypes,
    tenantDocumentTypesTotalCount,
    ...rest,
  };
};

export default useGetTenantDocumentTypes;
