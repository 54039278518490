import React, { FC } from "react";
import { Loader } from "@periplus/ui-library";
import { Switch, Route, Redirect } from "react-router-dom";

import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import NotFoundPage from "../NotFoundPage";
import ForbiddenPage from "../ForbiddenPage";
import SelectRoutes from "pages/Select";
import SettingsRoute from "pages/Settings";
import { useAuth } from "contexts/AuthContext";

const AuthRoutes: FC = () => {
  const { tenantConfigLoading } = useAuth();
  if (tenantConfigLoading) {
    return <Loader />;
  }
  return (
    <>
      <Switch>
        <Route path="/" exact component={() => <Redirect to="/select" />} />
        <Route path="/login" exact render={() => <Redirect to="/select" />} />
        <PrivateRoute path="/select" component={SelectRoutes} />
        <PrivateRoute path="/settings" component={SettingsRoute} />
        <Route path="/403" exact component={ForbiddenPage} />
        <Route path="/404" exact component={NotFoundPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
};

export default AuthRoutes;
