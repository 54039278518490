import React, { useState } from "react";
import { BoxProps } from "@periplus/ui-library";
import SmallThumbnail from "./SmallThumbnail";
import BigThumbnail from "./BigThumbnail";
import { AiConfirmedDeclarationEntity } from "../useGetAiConfirmedDeclarations";

interface Props extends BoxProps {
  page: NonNullable<
    NonNullable<
      AiConfirmedDeclarationEntity["declaration_request"]
    >["documents"][number]["pages_m2m"][number]["page"]
  >;
}

export default ({ page }: Props) => {
  const [bigThumbnailOpen, setBbigThumbnailOpen] = useState(false);

  return (
    <>
      <SmallThumbnail page={page} onClick={() => setBbigThumbnailOpen(true)} />
      {bigThumbnailOpen && (
        <BigThumbnail page={page} onClose={() => setBbigThumbnailOpen(false)} />
      )}
    </>
  );
};
