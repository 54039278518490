import React, { FC } from "react";
import { Box, BoxProps, useTheme } from "@periplus/ui-library";
import Field from "./Field";
import Panel from "./Panel";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { LanguageType } from "i18n";
import { DeclarationForm } from "../../Declaration";
import { convertPrimitive } from "utils/common";
import dayjs from "dayjs";
import { EdecData } from "domain/edec/useGetEdecMasterData";

interface HeaderProps extends BoxProps {
  edecData: EdecData;
  pageContainerWidth: number;
}

const Header: FC<HeaderProps> = ({ edecData, pageContainerWidth }) => {
  const { t, i18n } = useTranslation("declarationNew");
  const language = i18n.language as LanguageType;

  const theme = useTheme();
  const {
    values: {
      send_status,
      declarant_number,
      trader_identification_number,
      send_date,
      receive_status,
      acceptance_date,
      customs_declaration_number,
      trader_reference,
      duty_total_amount,
      vat_total_amount,
      customs_declaration_version,
      material_check,
      release,
      goods_items,
    },
  } = useFormikContext<DeclarationForm>();

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexDirection:
          pageContainerWidth > theme.breakpoints.values.lg ? "row" : "column",
      }}
    >
      <Panel
        columns={(() => {
          if (pageContainerWidth > theme.breakpoints.values.md) return 6;

          return 3;
        })()}
        sx={{
          flexGrow: 1,
        }}
      >
        <Field
          label={t("Status of transmission")}
          value={send_status}
          color="green"
        />
        <Field
          label={t("Sent by")}
          value={`${declarant_number ?? ""} / ${
            trader_identification_number ?? ""
          }`}
          color="green"
        />
        <Field
          label={t("Date of submission")}
          value={send_date && dayjs(send_date).format("DD.MM.YYYY HH:mm")}
          color="green"
        />
        <Field
          label={t("State of receipt")}
          value={receive_status}
          color="green"
        />
        <Field
          label={t("Date of receipt")}
          value={
            acceptance_date && dayjs(acceptance_date).format("DD.MM.YYYY HH:mm")
          }
          color="green"
        />
        <Field
          label={t("Receipt #")}
          value={customs_declaration_number}
          color="green"
        />
        <Field
          label={t("Vehicle/Freight Forw/Direct")}
          LabelProps={{
            sx: {
              color: "orange",
            },
          }}
          value=""
          color="grey"
        />
        <Field label={t("File #")} value={trader_reference} color="grey" />
        <Field
          label={t("Total Packages/Total Weight")}
          LabelProps={{
            sx: {
              color: "orange",
            },
          }}
          value=""
          color="grey"
        />
        <Field
          label={t("Total VALUE / CHF")}
          LabelProps={{
            sx: {
              color: "orange",
            },
          }}
          value=""
          color="grey"
        />
        <Field
          label={t("Total of customs duties")}
          value={duty_total_amount}
          color="grey"
        />
        <Field
          label={t("Total of VAT")}
          value={vat_total_amount}
          color="grey"
        />
      </Panel>
      <Panel
        columns={2}
        sx={{
          flexGrow: 1,
        }}
        title={t("Customs selection results")}
      >
        <Field
          label={t("Version #")}
          value={customs_declaration_version}
          color="grey"
        />
        <Field
          label={t("Customs-intervention")}
          value={
            edecData.edec_domains["yesNoCode"].find(
              (el) =>
                el.value ===
                convertPrimitive(material_check, "number")?.toString()
            )?.[`meaning_${language}`]
          }
          color="grey"
        />
        <Field
          label={t("Clearing status")}
          value={
            edecData.edec_domains["yesNoCode"].find(
              (el) =>
                el.value === convertPrimitive(release, "number")?.toString()
            )?.[`meaning_${language}`]
          }
          color="grey"
        />
        <Field
          label={t("Intervention type")}
          value={(() => {
            const goodItemsArray = Object.values(goods_items);
            if (
              !goodItemsArray.length ||
              goodItemsArray.every((el) => el.selection_result === null)
            )
              return "";

            return goodItemsArray.reduce(
              (acc, { selection_result }) =>
                selection_result && selection_result > acc
                  ? selection_result
                  : acc,
              0
            );
          })()}
          color="grey"
        />
      </Panel>
    </Box>
  );
};

export default Header;
