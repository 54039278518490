import React, { useMemo, FC } from "react";
import {
  Button,
  FormAutocomplete,
  FormTextField,
  Grid,
  Typography,
} from "@periplus/ui-library";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Form, Formik } from "formik";
import Countries from "i18n-iso-countries";
import { ADDRESS_TYPES } from "domain/address/types";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { makeStyles } from "tss-react/mui";

Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

interface UserRegisterFormProps {
  onSuccessRegistration: () => void;
}

const useStyles = makeStyles()({
  fieldsTitle: {
    fontWeight: 600,
  },
  buttonsContainer: {
    "& span, & a": {
      fontFamily: "Rubik",
    },
  },
  returnBackLink: {
    color: "#604dff",
    letterSpacing: "0.75px",
  },
  confirmButton: {
    backgroundColor: "#604dff",
    color: "#fff",
    width: "100%",
    fontSize: "14px",
  },
  fieldsContainer: {
    maxHeight: "calc(100vh - 460px)",
    overflow: "auto",
    flexWrap: "nowrap",
  },
});

const UserRegisterForm: FC<UserRegisterFormProps> = ({
  onSuccessRegistration,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const schema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().required(t("validation:isRequired")),
        lastName: yup.string().required(t("validation:isRequired")),
        email: yup
          .string()
          .email(t("validation:emailNotValid"))
          .required(t("validation:isRequired")),
        companyName: yup.string().required(t("validation:isRequired")),
        addressType: yup
          .object()
          .nullable()
          .required(t("validation:isRequired")),
        traderIdentificationNumber: yup
          .string()
          .required(t("validation:isRequired")),
        country: yup.string().required(t("validation:isRequired")).nullable(),
        city: yup.string().required(t("validation:isRequired")),
        street: yup.string().required(t("validation:isRequired")),
        streetNumber: yup.string().required(t("validation:isRequired")),
        zipcode: yup.string().required(t("validation:isRequired")),
        password: yup
          .string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            t("validation:wrongPassword")
          )
          .required(t("validation:isRequired")),
        confirmPassword: yup
          .string()
          .test({
            name: "matchPassword",
            message: t("validation:matchPassword"),
            test: function (value) {
              return this.parent.password === value;
            },
          })
          .required(t("validation:isRequired")),
      }),
    [t, i18n.language]
  );

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    addressType: null,
    traderIdentificationNumber: "",
    country: null,
    city: "",
    street: "",
    streetNumber: "",
    zipcode: "",
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = (values: any, { setSubmitting, setValues }: any) => {
    const { confirmPassword, email, addressType, ...rest } = values;

    executeRecaptcha?.()
      .then((token) => {
        const headers = new Headers();
        headers.append("recaptcha", token);
        headers.append("Content-Type", "application/json");
        fetch(
          `${process.env.REACT_APP_ADIT_API_URL}/api/tenant/registerClientCompany`,
          {
            method: "POST",
            headers,
            body: JSON.stringify({
              ...rest,
              addressType: addressType.id,
              userName: email,
            }),
          }
        )
          .then(() => {
            setTimeout(() => history.push("/"), 3000);
            onSuccessRegistration();
          })
          .catch((error) => {
            if (error?.includes?.("409")) {
              enqueueSnackbar(t("error:userExist"), { variant: "error" });
            } else {
              enqueueSnackbar(t("common:serverError"), { variant: "error" });
            }
            setValues({ ...values, password: "", confirmPassword: "" });
          })
          .finally(() => {
            setSubmitting(false);
          });
      })
      .catch(() => {
        enqueueSnackbar(t("common:serverError"), { variant: "error" });
        setSubmitting(false);
      });
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={schema}
      validateOnMount
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <Grid container direction="column" spacing={6}>
            <Grid
              className={classes.fieldsContainer}
              item
              container
              direction="column"
              spacing={2}
            >
              <Grid item>
                <Typography className={classes.fieldsTitle}>
                  {t("registration:userInformation")}
                </Typography>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    label={t("common:firstName")}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="lastName"
                    variant="outlined"
                    fullWidth
                    label={t("common:lastName")}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <FormTextField
                    name="email"
                    variant="outlined"
                    fullWidth
                    label={t("common:email")}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Typography className={classes.fieldsTitle}>
                  {t("registration:informationAboutCompany")}
                </Typography>
              </Grid>
              <Grid item container>
                <Grid item xs={12}>
                  <FormTextField
                    name="companyName"
                    variant="outlined"
                    fullWidth
                    label={t("common:company")}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormAutocomplete
                    name="addressType"
                    fullWidth
                    options={ADDRESS_TYPES}
                    getOptionLabel={(option) => option.name}
                    InputProps={{
                      required: true,
                      variant: "outlined",
                      label: t("registration:companyType"),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="traderIdentificationNumber"
                    variant="outlined"
                    fullWidth
                    label={t("common:traderIdentificationNumber")}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormAutocomplete
                    name="country"
                    fullWidth
                    options={Object.values(Countries.getNames("en")).map(
                      (country) =>
                        Array.isArray(country) ? country[0] : country
                    )}
                    InputProps={{
                      required: true,
                      variant: "outlined",
                      label: t("common:country"),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="city"
                    variant="outlined"
                    fullWidth
                    label={t("common:city")}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="street"
                    variant="outlined"
                    fullWidth
                    label={t("common:street")}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="streetNumber"
                    variant="outlined"
                    fullWidth
                    label={t("common:streetNumber")}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <FormTextField
                    name="zipcode"
                    variant="outlined"
                    fullWidth
                    label={t("common:postcode")}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Typography className={classes.fieldsTitle}>
                  {t("registration:password")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  {t("registration:passwordInformation")}
                </Typography>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="password"
                    variant="outlined"
                    type="password"
                    autoComplete="new-password"
                    fullWidth
                    label={t("registration:password")}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="confirmPassword"
                    variant="outlined"
                    type="password"
                    autoComplete="new-password"
                    fullWidth
                    label={t("registration:confirmPassword")}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={2}
              alignItems="center"
              className={classes.buttonsContainer}
            >
              <Grid item xs={12} sm={6}>
                <Link to="/login" className={classes.returnBackLink}>
                  {`« ${t("registration:navigateBack")}`}
                </Link>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.confirmButton}
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  {t("registration:register")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default UserRegisterForm;
