import dayjs from "dayjs";
import { TaskPriority } from "pages/Select/Tasks/constants";

export function dueDateToPriority(dueDate: string) {
  const now = dayjs();
  const dueDateDayjs = dayjs(dueDate);
  const minutesLeft = dueDateDayjs.diff(now, "minutes");
  let priority: TaskPriority = "highest";
  if (minutesLeft > 15) {
    priority = "high";
  }
  if (minutesLeft >= 30) {
    priority = "medium";
  }
  if (minutesLeft >= 2 * 60) {
    priority = "low";
  }
  if (minutesLeft >= 6 * 60) {
    priority = "lowest";
  }
  return priority;
}

export function priorityToDateRange(priority?: TaskPriority) {
  const highest = dayjs().add(15, "minutes").toISOString();
  const high = dayjs().add(30, "minutes").toISOString();
  const medium = dayjs().add(2, "hours").toISOString();
  const low = dayjs().add(6, "hours").toISOString();
  if (priority === "highest") {
    return {
      minDate: undefined,
      maxDate: highest,
    };
  }
  if (priority === "high") {
    return {
      minDate: highest,
      maxDate: high,
    };
  }
  if (priority === "medium") {
    return {
      minDate: high,
      maxDate: medium,
    };
  }
  if (priority === "low") {
    return {
      minDate: medium,
      maxDate: low,
    };
  }
  if (priority === "lowest") {
    return {
      minDate: low,
      maxDate: undefined,
    };
  }
  return {
    minDate: undefined,
    maxDate: undefined,
  };
}
