import React, { FC, useMemo } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import {
  HighCustomFeesTrigger,
  ImportListTrigger,
  NOTIFICATION_TRIGGER_CONFIG_TYPES,
  TriggerTypesEnum,
  SpecificHsCodesTrigger,
  StripUnusedFields,
} from "../../../graphql/hooks/useGetImportListTriggers";
import useUpdateImportListTrigger from "../../../graphql/hooks/useUpdateImportListTrigger";
import useInsertImportListTrigger from "../../../graphql/hooks/useInsertImportListTrigger";

interface ImportListTriggerFormikProps {
  onSubmit?: () => void;
  importListTrigger?: ImportListTrigger | null;
}

const ImportListTriggerFormik: FC<ImportListTriggerFormikProps> = ({
  children,
  onSubmit,
  importListTrigger,
}) => {
  const { t } = useTranslation();

  const updateImportListTrigger = useUpdateImportListTrigger();
  const insertImportListTrigger = useInsertImportListTrigger();
  const { enqueueSnackbar } = useSnackbar();
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      display_name: Yup.string().trim().required().max(50),
      type: Yup.object().nullable().required(),
      recipients: Yup.string()
        .required()
        .matches(
          /^(\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,4}\s*?,?\s*?)+$/,
          "Must contain list of email addresses, comma separated"
        ),
      specificHsCodes: Yup.string().when("type", (type: any, schema: any) => {
        if (type?.id === TriggerTypesEnum.SpecificHsCodes) {
          return schema
            .required()
            .matches(
              /^[0-9]{8}(?:,[0-9]{8})*$/gm,
              "Must contain 8-digit values, comma separated"
            );
        } else {
          return schema.nullable();
        }
      }),
      countryOfOrigin: Yup.string().when("type", (type: any, schema: any) => {
        if (type?.id === TriggerTypesEnum.HighCustomFeesTrigger) {
          return schema.required();
        } else {
          return schema.nullable();
        }
      }),
      feeAmount: Yup.number().when("type", (type: any, schema: any) => {
        if (type?.id === TriggerTypesEnum.HighCustomFeesTrigger) {
          return schema.required().positive().min(1);
        } else {
          return schema.notRequired();
        }
      }),
    });
  }, [t]);

  const initialValues = useMemo(() => {
    if (!importListTrigger) {
      return {
        display_name: "",
        type: null,
        recipients: "",
        specificHsCodes: "",
        countryOfOrigin: "",
        feeAmount: 0,
      };
    }

    const [typeObj] = NOTIFICATION_TRIGGER_CONFIG_TYPES.filter(
      (d) => d.id === importListTrigger.type
    );

    const { display_name, config } = importListTrigger;

    const { recipients } = config;
    let feeAmount: number = 0;
    let specificHsCodes: string = "";
    let countryOfOrigin: string = "";
    switch (typeObj.id) {
      case TriggerTypesEnum.HighCustomFeesTrigger:
        feeAmount = (config as HighCustomFeesTrigger).feeAmount;
        countryOfOrigin = (config as HighCustomFeesTrigger).countryOfOrigin;
        break;
      case TriggerTypesEnum.SpecificHsCodes:
        let hsCodesRaw: any = (config as SpecificHsCodesTrigger)
          .specificHsCodes;
        specificHsCodes =
          hsCodesRaw instanceof Array ? hsCodesRaw.join(",") : "";
        break;
    }

    return {
      display_name,
      type: typeObj,
      recipients: recipients.join(","),
      specificHsCodes: specificHsCodes,
      countryOfOrigin,
      feeAmount,
    };
  }, [importListTrigger]);

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    const onError = (error: any) => {
      enqueueSnackbar(t("common:serverError"), { variant: "error" });
      setSubmitting(false);
    };
    const onSuccess = () => {
      setSubmitting(false);
      onSubmit && onSubmit();
    };
    const castedValues = validationSchema.cast(values);
    const {
      display_name,
      recipients,
      type,
      countryOfOrigin,
      feeAmount,
      specificHsCodes,
    } = castedValues;
    if (!importListTrigger) {
      insertImportListTrigger({
        variables: {
          object: {
            display_name,
            type: type?.id,
            config: StripUnusedFields(
              {
                recipients: recipients?.split(",").map((d) => d.trim()),
                countryOfOrigin,
                feeAmount,
                specificHsCodes: specificHsCodes
                  ?.split(",")
                  .map((d) => d.trim()),
              },
              type?.id
            ),
            meta: {},
          },
        },
      })
        .then(onSuccess)
        .catch(onError);
    } else {
      updateImportListTrigger({
        variables: {
          id: importListTrigger.id,
          set: {
            display_name,
            type: type?.id,
            config: StripUnusedFields(
              {
                recipients: recipients?.split(",").map((d) => d.trim()),
                countryOfOrigin,
                feeAmount,
                specificHsCodes: specificHsCodes
                  ?.split(",")
                  .map((d) => d.trim()),
              },
              type?.id
            ),
          },
          append: {},
        },
      })
        .then(onSuccess)
        .catch(onError);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {children}
    </Formik>
  );
};

export default ImportListTriggerFormik;
