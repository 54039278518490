import React, { FC, HtmlHTMLAttributes } from "react";
import {
  Dialog,
  Typography,
  FormTextField,
  FormCheckbox,
  Theme,
} from "@periplus/ui-library";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { GridRow } from "graphql/hooks/useTableQuery";
import * as Yup from "yup";
import { makeStyles } from 'tss-react/mui';

interface MagicRowDialogProps extends HtmlHTMLAttributes<HTMLElement> {
  row: GridRow;
  onConfirm: (row: GridRow) => void;
  onCancel: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  form: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(2),
  },
}));

const MagicRowDialog: FC<MagicRowDialogProps> = ({
  row,
  onConfirm,
  onCancel,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation("validation");

  return (
    <Formik
      initialValues={{
        ...row,
        level: row.level || 1,
        isJunk: row.isJunk || false,
      }}
      validationSchema={Yup.object().shape({
        level: Yup.number().min(1),
      })}
      onSubmit={(values) => {
        onConfirm(values);
      }}
    >
      {({ submitForm, isValid }) => (
        <Dialog
          mainTitle={`${t("Row")}:`}
          onClose={onCancel}
          onConfirm={submitForm}
          maxWidth="xs"
          ConfirmButtonProps={{
            disabled: !isValid,
          }}
        >
          <Form className={classes.form}>
            <Typography color="textSecondary">
              {t("Custom item number")}:
            </Typography>
            <FormTextField name="cin" type="number" />
            <Typography color="textSecondary">{t("Level")}:</Typography>
            <FormTextField name="level" type="number" />
            <Typography color="textSecondary">{t("Junk")}:</Typography>
            <FormCheckbox
              name="isJunk"
              style={{
                justifySelf: "center",
              }}
            />
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default MagicRowDialog;
