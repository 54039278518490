import { gql } from "@apollo/client";

export const GET_USER_BY_EMAIL = gql`
  query GET_USER_BY_EMAIL($email: String!) {
    user_pp(
      where: { _and: [{ email: { _eq: $email } }, { deleted: { _eq: false } }] }
    ) {
      email
    }
  }
`;
