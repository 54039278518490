import React, { HTMLAttributes, forwardRef } from "react";
import { Theme, LinearProgress } from "@periplus/ui-library";
import { DataPointCoordinates } from "graphql/hooks/useGetValidationDocument";
import { makeStyles } from 'tss-react/mui';

interface IDataPointRectProps extends HTMLAttributes<HTMLDivElement> {
  coordinates: DataPointCoordinates;
  scale: [number, number];
  loading?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  dataPoint: {
    position: "absolute",
  },
  dataPointContent: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 2,
    borderColor: theme.palette.primary.main,
    cursor: "pointer",
    opacity: 0,
    transition: "opacity 0.2s ease",
    zIndex: 0,
    "&:hover": {
      opacity: 1,
    },
  },
}));

const DataPointRect = forwardRef<HTMLDivElement, IDataPointRectProps>(
  ({ coordinates, scale, loading, children, className, ...props }, ref) => {
    const { classes, cx } = useStyles();
    const [x, y, w, h] = coordinates;
    const [scaleX, scaleY] = scale;

    if ([Infinity, NaN].includes(scaleX) || [Infinity, NaN].includes(scaleY))
      return null;

    return (
      <div
        ref={ref}
        style={{
          left: x * scaleX,
          top: y * scaleY,
          width: w * scaleX,
          height: h * scaleY,
        }}
        className={classes.dataPoint}
      >
        {children}
        <div className={cx(classes.dataPointContent, className)} {...props}>
          {loading && <LinearProgress />}
        </div>
      </div>
    );
  }
);

export default DataPointRect;
