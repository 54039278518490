import React from "react";
import {
  MuiTable as MaUTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  FormControl,
  InputBase,
  Theme,
} from "@periplus/ui-library";

import {
  Column,
  useBlockLayout,
  useResizeColumns,
  useTable,
} from "react-table";
import { UncontrolledInput } from "../Input";
import { makeStyles } from 'tss-react/mui';

interface Props {
  columns: Column[];
  data: { [key: string]: any }[];
  updateMyData: (rowIndex: number, key: string, value: any) => void;
  onFocus: (index: number, id: string) => void;
}

const EditableCell = ({
  value: initialValue = "",
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  onFocus,
}: any) => {
  return (
    <FormControl fullWidth>
      <UncontrolledInput defaultValue={initialValue}>
        <InputBase
          onFocus={() => {
            onFocus(index, id);
          }}
          onBlur={(e) => {
            updateMyData(index, id, e.target.value);
          }}
          fullWidth
        />
      </UncontrolledInput>
    </FormControl>
  );
};

const defaultColumn = {
  Cell: EditableCell,
  minWidth: 120,
  width: 150,
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    maxHeight: "100%",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    display: "flex",
    zIndex: theme.zIndex.appBar,
  },
  tableCell: {
    minWidth: 120,
    display: "inline-flex",
    padding: "0 16px",
    "&:last-child": {
      minWidth: 0,
    },
  },
  tableCellContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  tableCellContent: {
    width: "100%",
  },
  tableHeaderCellContent: {
    textTransform: "uppercase",
  },
  resizer: {
    width: 24,
    position: "absolute",
    top: 0,
    bottom: 0,
    right: -12,
    alignSelf: "stretch",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "col-resize",
    color: "rgba(224, 224, 224, 1)",
    zIndex: 100,
    "& svg": {
      userSelect: "none",
    },
    "&:hover": {
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
}));

function SimpleTable({ columns, data, updateMyData, onFocus }: Props) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        // @ts-ignore
        updateMyData,
        onFocus,
      },
      useBlockLayout,
      useResizeColumns
    );

  const { classes, cx } = useStyles();

  // Render the UI for your table
  return (
    <TableContainer className={classes.container}>
      <MaUTable stickyHeader size="small" {...getTableProps()}>
        <TableHead className={classes.stickyHeader}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps()}
                  className={classes.tableCell}
                >
                  <div className={classes.tableCellContainer}>
                    <div
                      className={cx(
                        classes.tableCellContent,
                        classes.tableHeaderCellContent
                      )}
                    >
                      {column.render("Header")}
                    </div>
                    <div
                      // @ts-ignore
                      {...column.getResizerProps()}
                      className={cx([
                        classes.resizer,
                        // @ts-ignore
                        column.isResizing && "isResizing",
                      ])}
                      style={{ opacity: 1 }}
                    >
                      <svg
                        className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M11 19V5h2v14z"></path>
                      </svg>
                    </div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              !(row.original as any).isJunk && (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              )
            );
          })}
        </TableBody>
      </MaUTable>
    </TableContainer>
  );
}

export default React.memo(SimpleTable);
