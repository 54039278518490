import React, { useCallback, useMemo, useState } from "react";
import { Button, PageContainer, Tooltip } from "@periplus/ui-library";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import TasksFilterActionBar from "./ActionBar";
import TasksStatusTabs from "./TasksStatusTabs";
import TasksTable from "./TasksTable";
import {
  useGetDeclarationTasks,
  IVariables,
} from "graphql/hooks/useGetDeclarationTasks";
import { priorityToDateRange } from "utils/dueDateToPriority";
import {
  TaskCreated,
  TaskPriority,
  TaskType,
  TASKS_CREATED,
} from "./constants";
import useUpdateDeclarationTask from "graphql/hooks/useUpdateDeclarationTask";
import { headerTranslation } from "components/Table";
import { DocumentTypeFlag } from "domain/documentType/types";
import { useTranslation } from "react-i18next";
import useUrlSearchParams from "hooks/useUrlSearchParams";
import usePageLocalStorage from "hooks/usePageLocalStorage";

interface Props {}

export interface Filters {
  who?: string;
  priority?: TaskPriority;
  type?: TaskType;
  created?: TaskCreated;
}

const defaultFilters: Filters = {
  who: undefined,
  priority: undefined,
  type: undefined,
  created: undefined,
};

const Tasks = (props: Props) => {
  const { getPageLocalStorage, setPageLocalStorage } = usePageLocalStorage<{
    filters?: Filters;
  }>();
  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams<
    Filters & {
      search?: string;
      status: "open" | "done";
    }
  >({
    ...getPageLocalStorage().filters,
    status: "open",
  });
  const castedUrlSearchParams = useMemo(
    () => ({
      ...urlSearchParams,
      type: (urlSearchParams.type && parseInt(urlSearchParams.type as any)) as
        | TaskType
        | undefined,
    }),
    [urlSearchParams]
  );
  const [offsetLimit, setOffsetLimit] = useState({
    offset: 0,
    limit: 6,
  });

  const history = useHistory();
  const { t } = useTranslation();

  const filterToVariables = useMemo(() => {
    const { minDate, maxDate } = priorityToDateRange(
      castedUrlSearchParams.priority
    );

    const variables: IVariables = {
      limit: offsetLimit.limit,
      offset: offsetLimit.offset,
      task_status_id: castedUrlSearchParams.status === "open" ? 0 : 1,
      min_due_date: minDate,
      max_due_date: maxDate,
      task_type_id: castedUrlSearchParams.type,
      assigned_to: castedUrlSearchParams.who,
      search: castedUrlSearchParams.search
        ? `%${castedUrlSearchParams.search}%`
        : undefined,
    };

    const creation = TASKS_CREATED.find(
      (created) => created.id === castedUrlSearchParams.created
    );
    if (creation) {
      const { subtractedDays } = creation;
      variables.creation_date = dayjs()
        .startOf("date")
        .subtract(subtractedDays, "days")
        .toISOString();
    }

    return variables;
  }, [castedUrlSearchParams, offsetLimit]);

  const {
    data,
    openCount = 0,
    finishedCount = 0,
    loading,
    refetch,
  } = useGetDeclarationTasks({
    variables: filterToVariables,
    fetchPolicy: "cache-and-network",
  });

  const updateDeclarationTask = useUpdateDeclarationTask();

  const loadMore = useCallback(
    (offset, limit) => {
      setOffsetLimit({
        limit,
        offset,
      });
    },
    [setUrlSearchParams, castedUrlSearchParams]
  );

  const handleAddToOpen = useCallback(
    async (id: number) => {
      await updateDeclarationTask({
        variables: {
          id,
          _set: {
            task_status_id: 0,
          },
        },
      });
      refetch();
    },
    [updateDeclarationTask, refetch]
  );

  const renderActionCell = useCallback(
    ({ cell, row }) => {
      const { id, object_id, task_type_id, file, task_status_id } =
        cell.row.original;
      if (task_status_id === 0) {
        const documentFlags = Number(row.original.document?.document_flags);

        const isInternalValidation =
          (documentFlags & DocumentTypeFlag.ocr_datapoint_completed) ===
          DocumentTypeFlag.ocr_datapoint_completed;

        const isNanonetsRequired = false;

        const isRossumExported = false;

        const isNanonetsCompleted = false;

        const isDisabled =
          task_type_id === 1 &&
          !(
            (isNanonetsCompleted && isNanonetsRequired) ||
            isInternalValidation ||
            isRossumExported
          );
        const button = (
          <Button
            variant="contained"
            color="primary"
            disabled={isDisabled}
            onClick={() => {
              if (task_type_id === 1) {
                window.open(`/select/validationV2/${object_id}`, "_blank");
              } else {
                sessionStorage.setItem(
                  "declaration_detail",
                  JSON.stringify({
                    fileId: file?.id,
                    id: file?.identification,
                  })
                );
                window.open(
                  `/select/declaration/${encodeURIComponent(file?.id)}/${1}`,
                  "_blank"
                );
              }
            }}
          >
            {headerTranslation("declaration", "start")}
          </Button>
        );

        return isDisabled ? (
          <Tooltip
            title={
              t(
                "tasks:This document is not yet available. Please try again later."
              ) as string
            }
          >
            <span>{button}</span>
          </Tooltip>
        ) : (
          button
        );
      }

      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleAddToOpen(id);
          }}
        >
          {headerTranslation("common", "undo")}
        </Button>
      );
    },
    [history, handleAddToOpen, headerTranslation, t]
  );
  return (
    <PageContainer
      title={t("navigation:tasks")}
      sx={{
        "& .LuiPageContainer-content": {
          display: "flex",
          flexDirection: "column",
          gap: 2,
        },
      }}
    >
      <TasksFilterActionBar
        filters={{
          who: castedUrlSearchParams.who,
          priority: castedUrlSearchParams.priority,
          created: castedUrlSearchParams.created,
          type: castedUrlSearchParams.type,
        }}
        onChangeFilters={(newFilters) => {
          setPageLocalStorage({ filters: newFilters });
          setUrlSearchParams({
            ...newFilters,
          });
          setOffsetLimit({
            ...offsetLimit,
            offset: 0,
          });
        }}
        onChangeSearch={(search) => {
          setUrlSearchParams({
            search,
          });
          setOffsetLimit({
            ...offsetLimit,
            offset: 0,
          });
        }}
        onClearFilters={() => {
          setPageLocalStorage({ filters: defaultFilters });
          setUrlSearchParams({
            ...defaultFilters,
          });
          setOffsetLimit({
            ...offsetLimit,
            offset: 0,
          });
        }}
      />

      <TasksStatusTabs
        status={castedUrlSearchParams.status}
        count={{ open: openCount, finished: finishedCount }}
        onChange={(status) => {
          setUrlSearchParams({
            status,
          });
          setOffsetLimit({
            ...offsetLimit,
            offset: 0,
          });
        }}
      />

      <TasksTable
        rowData={data || []}
        loading={loading}
        itemCount={
          (castedUrlSearchParams.status === "open"
            ? openCount
            : finishedCount) || 0
        }
        loadMore={loadMore}
        initialPage={Math.round(offsetLimit.offset / offsetLimit.limit) + 1}
        renderActionCell={renderActionCell}
      />
    </PageContainer>
  );
};

export default Tasks;
