import React, { HtmlHTMLAttributes } from "react";
import PDFPreview from "components/PDFPreview/PDFPreview";
import DocumentStatus from "domain/document/components/DocumentStatus";
import { useAuth } from "contexts/AuthContext";
import { EmailView } from "domain/file/types";

interface Props extends HtmlHTMLAttributes<HTMLElement> {
  email?: EmailView;
  loading: boolean;
  documentId?: string;
}

export default function ({ email, loading, documentId }: Props) {
  const { tenantConfig } = useAuth();

  const selected = (() => {
    const selectedDocument = email?.documents.find(
      (document) => document.id === documentId
    );
    if (selectedDocument) {
      return {
        creationDate: selectedDocument.creation_date,
        name: selectedDocument.document_name,
        extension: selectedDocument.document_extension,
        pages: selectedDocument.computedPagesM2M.map((page) => ({
          ...page,
          thumbnailBigUrl: page.thumbnail_big_url,
          thumbnailSmallUrl: page.thumbnail_small_url,
        })),
        status: (
          <DocumentStatus
            status={selectedDocument?.document_status}
            defaultFlags={
              tenantConfig?.UseAutoclassification
                ? selectedDocument?.document_flags
                : 0
            }
          />
        ),
      };
    }

    if (email)
      return {
        creationDate: email.creation_date,
        name: email.name,
        pages: email.documents.reduce(
          (acc, document) =>
            acc.concat(
              document.computedPagesM2M.map((page) => ({
                ...page,
                thumbnailBigUrl: page.thumbnail_big_url,
                thumbnailSmallUrl: page.thumbnail_small_url,
              }))
            ),
          [] as any
        ),
      };
  })();

  return <PDFPreview file={selected} loading={loading} />;
}
