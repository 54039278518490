import React, { FC, HtmlHTMLAttributes, useState } from "react";
import {
  FormTextField,
  Typography,
  Box,
  IconButton,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import { Formik } from "formik";
import useUpdateFileNumber from "domain/file/useUpdateFileNumber";
import { ApolloQueryResult } from "@apollo/client";
import * as yup from "yup";
import { useDebounceCallback } from "@react-hook/debounce";
import client from "graphql/client";
import { GET_FILE_BY_IDENTIFICATION } from "graphql/queries/files";

interface OurReferenceCellProps extends HtmlHTMLAttributes<HTMLElement> {
  editable: boolean;
  file_id: string;
  identification?: string | null;
  onChange: () => Promise<ApolloQueryResult<any>>;
}

const OurReferenceCell: FC<OurReferenceCellProps> = ({
  editable,
  file_id,
  identification,
  onChange,
}) => {
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [updateFileNumber] = useUpdateFileNumber();

  const identificationValidationDebounce = useDebounceCallback(
    (value, resolve) => {
      if (!value || value === identification) {
        resolve(true);
        return;
      }
      client
        .query({
          query: GET_FILE_BY_IDENTIFICATION,
          variables: {
            fileNumber: value,
          },
        })
        .then(({ data }) => resolve(!data.file.length));
    },
    300
  );

  const validationSchema = yup.object().shape({
    identification: yup
      .string()
      .required()
      .test("Duplicated", t("validation:Duplicated"), (value) => {
        return new Promise((resolve) =>
          identificationValidationDebounce(value, resolve)
        );
      })
      .test(
        "Only chars #,-_ allowed",
        t("validation:Only chars #,-_ allowed"),
        (value: string | undefined) => {
          if (!value) return false;

          return !/[`!@$%^&*()+=[\]{};':"\\|.<>/?~]/.test(value);
        }
      ),
  });

  return (
    <Box
      {...(editable && {
        onMouseEnter: () => setHovered(true),
        onMouseLeave: () => setHovered(false),
      })}
      sx={{
        width: "100%",
        display: "flex",
        alignItems: editMode ? "start" : "center",
        gap: 1,
      }}
    >
      {editMode ? (
        <Formik
          initialValues={{
            identification: identification || "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            updateFileNumber({
              variables: { file_id, file_number: values.identification },
            }).then(() => {
              onChange().then(() => {
                setSubmitting(false);
                setEditMode(false);
                setHovered(false);
              });
            });
          }}
        >
          {({ submitForm, isSubmitting, isValid }) => (
            <>
              <FormTextField
                autoFocus
                onDoubleClick={(e) => {
                  e.stopPropagation();
                }}
                name="identification"
                placeholder={t("declaration:New Reference")}
                sx={{
                  width: 150,
                }}
              />
              <IconButton
                disabled={!isValid}
                tooltip={t("save")}
                onClick={submitForm}
                color="primary"
                loading={isSubmitting}
                style={{
                  marginTop: "2px",
                }}
              >
                <SaveIcon />
              </IconButton>
              <IconButton
                tooltip={t("cancel")}
                onClick={() => {
                  setEditMode(false);
                  setHovered(false);
                }}
                disabled={isSubmitting}
                style={{
                  marginTop: "2px",
                }}
              >
                <ClearIcon />
              </IconButton>
            </>
          )}
        </Formik>
      ) : (
        <>
          <Typography noWrap tooltip>
            {identification}
          </Typography>
          {editable && (
            <IconButton
              tooltip={t("edit")}
              onClick={() => setEditMode(true)}
              color="primary"
              sx={{
                visibility: hovered || !identification ? "visible" : "hidden",
                ...(editable &&
                  !identification && {
                    marginLeft: "auto",
                  }),
              }}
            >
              <EditIcon />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};

export default OurReferenceCell;
