import React, { useState, useEffect, useRef, SyntheticEvent } from "react";
import { Theme, IconButton } from "@periplus/ui-library";
import { orderBy } from "lodash";
import { DeleteOutline, OpenWith } from "@mui/icons-material";
import { ResizableBox, ResizeCallbackData } from "react-resizable";
import Draggable, { DraggableEvent, DraggableData } from "react-draggable";
import { MagicLines } from "components/MagicGrid/MagicLines";
import { LineItem, GridColumn, GridRow } from "graphql/hooks/useTableQuery";
import ColumnNames from "./ColumnNames";
import useGetElementClientRect from "hooks/useGetElementClientRect";
import MagicRows from "./MagicRows";
import { makeStyles } from 'tss-react/mui';

interface IMagicGridProps {
  lineItemsSchema: string[];
  dataGrid: LineItem;
  width: number;
  height: number;
  scale: [number, number];
  onUpdateGrid: (updatedLineItem: LineItem) => void;
  onRemoveGrid: (removedLineItem: LineItem) => void;
}

const useStyles = makeStyles()((theme: Theme) =>
  ({
    grid: {
      position: "absolute",
      top: 0,
      left: 0,
      border: `2px solid ${theme.palette.primary.main}`,
      pointerEvents: "none",
      "& .react-resizable-handle": {
        pointerEvents: "auto",
      },
    },

    gridMoveIcon: {
      position: "absolute",
      top: -22,
      right: -22,
      width: 20,
      height: 20,
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
      "& .MuiSvgIcon-root": {
        fontSize: 16,
      },
      "&:hover": {
        background: theme.palette.primary.light,
      },
    },

    gridOffIcon: {
      position: "absolute",
      top: -44,
      right: -22,
      width: 20,
      height: 20,
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      "& .MuiSvgIcon-root": {
        fontSize: 16,
      },
      "&:hover": {
        background: theme.palette.primary.light,
      },
    },

    iconsContainer: {
      cursor: "pointer",
      position: "absolute",
      pointerEvents: "auto",
      right: -2,
      "&[x-out-of-boundaries]": {
        display: "none",
      },
    },

    iconsContainerLeft: {
      left: -26,
      right: "unset",
    },

    gridIconLeft: {
      left: 0,
    }
  }));

export interface IColumnNamesState {
  [key: string]: number;
}

const MagicGrid = ({
  dataGrid,
  scale,
  width,
  height,
  lineItemsSchema,
  onUpdateGrid,
  onRemoveGrid,
}: IMagicGridProps) => {
  const { classes, cx } = useStyles();
  const [columnNames, setColumnNames] = useState<IColumnNamesState>({});
  const gridRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const resizeBounds = useGetElementClientRect(gridRef);
  const [bounds, setCurrentBounds] = useState(resizeBounds);
  useEffect(() => {
    if (!isResizing) {
      setCurrentBounds(resizeBounds);
    }
  }, [isResizing, resizeBounds]);
  const [x, y, w, h] = dataGrid.coordinates;
  const [scaleX, scaleY] = scale;
  const { rows, columns } = dataGrid;

  useEffect(() => {
    setColumnNames(
      lineItemsSchema.reduce<IColumnNamesState>((a, lineItem) => {
        const index = dataGrid.columns.findIndex(
          (column) => column.name === lineItem
        );
        a[lineItem] = index;
        return a;
      }, {})
    );
  }, [dataGrid.columns, lineItemsSchema]);

  const sortRows = (prevRows: GridRow[]) => orderBy(prevRows, "top");
  const sortColumns = (prevColumns: GridColumn[]) =>
    orderBy(prevColumns, "left");

  const handleAddNewPosition = (type: "row" | "column") => (newPos: number) => {
    if (type === "row") {
      const updatedRows = sortRows([...rows, { top: newPos }]);
      onUpdateGrid({
        ...dataGrid,
        rows: updatedRows,
      });
    } else {
      const { columns } = dataGrid;
      const updatedColumns = sortColumns([...columns, { left: newPos }]);
      onUpdateGrid({
        ...dataGrid,
        columns: updatedColumns,
      });
    }
  };

  const handleRemovePosition = (type: "row" | "column") => (index: number) => {
    if (type === "row") {
      const removedRows = [...rows.slice(0, index), ...rows.slice(index + 1)];
      onUpdateGrid({
        ...dataGrid,
        rows: removedRows,
      });
    } else {
      const removedColumns = [
        ...columns.slice(0, index),
        ...columns.slice(index + 1),
      ];
      onUpdateGrid({
        ...dataGrid,
        columns: removedColumns,
      });
    }
  };

  const handleUpdatePosition =
    (type: "row" | "column") => (index: number, newPos: number) => {
      if (type === "row") {
        const updatedRows = sortRows([
          ...rows.slice(0, index),
          { ...rows[index], top: newPos },
          ...rows.slice(index + 1),
        ]);
        onUpdateGrid({ ...dataGrid, rows: updatedRows });
      } else {
        const { columns } = dataGrid;
        const updatedColumns = sortColumns([
          ...columns.slice(0, index),
          { ...columns[index], left: newPos },
          ...columns.slice(index + 1),
        ]);
        onUpdateGrid({ ...dataGrid, columns: updatedColumns });
      }
    };

  const handleUpdateColumnName = (index: number, name?: string) => {
    const updatedColumns = sortColumns([
      ...columns.slice(0, index),
      { ...columns[index], name },
      ...columns.slice(index + 1),
    ]);
    if (name && columnNames[name] !== -1) {
      updatedColumns[columnNames[name]].name = undefined;
    }
    onUpdateGrid({ ...dataGrid, columns: updatedColumns });
  };

  const handleChangeRow = (index: number, row: GridRow) => {
    const updatedRows = sortRows([
      ...rows.slice(0, index),
      { ...rows[index], ...row },
      ...rows.slice(index + 1),
    ]);
    onUpdateGrid({ ...dataGrid, rows: updatedRows });
  };

  const handleUpdateGridSize = (
    event: SyntheticEvent,
    data: ResizeCallbackData
  ) => {
    const { size } = data;
    // Update the coordinates of the grid,
    // Update each column's and rows' position so that only the last row or column get resized.
    onUpdateGrid({
      ...dataGrid,
      columns: dataGrid.columns.map((col) => ({
        ...col,
        left: ((w * scaleX) / size.width) * col.left,
      })),
      rows: dataGrid.rows.map((row) => ({
        ...row,
        top: ((h * scaleY) / size.height) * row.top,
      })),
      coordinates: [
        ...dataGrid.coordinates.slice(0, 2),
        size.width / scaleX,
        size.height / scaleY,
      ] as [number, number, number, number],
    });

    setIsResizing(false);
  };

  const handleUpdateGridPosition = (
    event: DraggableEvent,
    data: DraggableData
  ) => {
    onUpdateGrid({
      ...dataGrid,
      coordinates: [
        data.x / scaleX,
        data.y / scaleY,
        ...dataGrid.coordinates.slice(2),
      ] as [number, number, number, number],
    });
  };

  const showGridIconsLeft = width - (x + w) * scaleX <= 25;

  return (
    <Draggable
      handle={`.${classes.gridMoveIcon}`}
      bounds="parent"
      onStart={(e) => e.stopPropagation()}
      onStop={handleUpdateGridPosition}
      position={{
        x: x * scaleX,
        y: y * scaleY,
      }}
    >
      <div className={classes.grid} ref={gridRef}>
        <ResizableBox
          width={w * scaleX}
          height={h * scaleY}
          onResizeStart={() => setIsResizing(true)}
          onResizeStop={handleUpdateGridSize}
          minConstraints={[
            w * scaleX * (columns[columns.length - 1]?.left || 0) + 20,
            h * scaleY * (rows[rows.length - 1]?.top || 0) + 10,
          ]}
          maxConstraints={[width - x * scaleX, height - y * scaleY]}
          draggableOpts={{
            onMouseDown: (e: any) => e.stopPropagation(),
            grid: [5, 5],
          }}
        >
          {bounds && (
            <>
              <div
                className={cx(classes.iconsContainer, {
                  [classes.iconsContainerLeft]: showGridIconsLeft,
                })}
              >
                <IconButton
                  aria-label="delete"
                  size="small"
                  className={cx(classes.gridOffIcon, {
                    [classes.gridIconLeft]: showGridIconsLeft,
                  })}
                  onClick={() => onRemoveGrid(dataGrid)}
                >
                  <DeleteOutline />
                </IconButton>
                <IconButton
                  aria-label="move"
                  size="small"
                  className={cx(classes.gridMoveIcon, {
                    [classes.gridIconLeft]: showGridIconsLeft,
                  })}
                >
                  <OpenWith />
                </IconButton>
              </div>

              <MagicRows
                bounds={bounds}
                rows={rows}
                onChangeRow={handleChangeRow}
                hoverDisabled={isResizing}
              />

              <MagicLines
                direction="column"
                bounds={bounds}
                positions={rows.map((row) => row.top)}
                onAddNewPosition={handleAddNewPosition("row")}
                onRemovePosition={handleRemovePosition("row")}
                onUpdatePosition={handleUpdatePosition("row")}
              />

              <MagicLines
                bounds={bounds}
                positions={columns.map((col) => col.left)}
                onAddNewPosition={handleAddNewPosition("column")}
                onRemovePosition={handleRemovePosition("column")}
                onUpdatePosition={handleUpdatePosition("column")}
              />

              <ColumnNames
                columns={columns}
                onChangeColumnName={handleUpdateColumnName}
                bounds={bounds}
                columnNames={columnNames}
              />
            </>
          )}
        </ResizableBox>
      </div>
    </Draggable>
  );
};

export default React.memo(MagicGrid);
