import React, { useRef, useState } from "react";
import { Carousel as UilCarousel } from "@periplus/ui-library";
import Carousel, { CarouselProps } from "./Carousel";
const { CarouselDialog } = UilCarousel;

const getCursorXPosition = (e: React.TouchEvent | React.MouseEvent) => {
  //@ts-ignore
  return (e.changedTouches ? e.changedTouches[0] : e).clientX;
};

interface CarouselWithPreviewProps<T> extends CarouselProps<T> {
  renderPreview?: (arg0: {
    slide: T;
    index: number;
  }) => React.ElementType | JSX.Element;
}

const CarouselWithPreview = <T extends any>({
  slides,
  renderSlide,
  renderPreview,
  ...rest
}: CarouselWithPreviewProps<T>) => {
  const lockPosition = useRef(0);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleLock = (e: React.TouchEvent | React.MouseEvent) => {
    const cursorXPosition = getCursorXPosition(e);

    lockPosition.current = cursorXPosition;
  };

  const handleSelect =
    (index: number) => (e: React.TouchEvent | React.MouseEvent) => {
      // @ts-ignore
      if (e.button !== 0) {
        return;
      }
      const cursorXPosition = getCursorXPosition(e);
      if (Math.abs(lockPosition.current - cursorXPosition) < 15) {
        setSelectedIndex(index);
      }
    };

  const renderSlideWrapper = (data: { index: number; slide: T }) => {
    return (
      <div
        onMouseDown={handleLock}
        onTouchStart={handleLock}
        onMouseUp={handleSelect(data.index)}
        onTouchEnd={handleSelect(data.index)}
      >
        {renderSlide(data)}
      </div>
    );
  };

  if (slides.length === 0) {
    return null;
  }

  return (
    <>
      <Carousel
        slides={slides}
        renderSlide={renderPreview ? renderSlideWrapper : renderSlide}
        {...rest}
      />
      {!!renderPreview && selectedIndex !== -1 && (
        <CarouselDialog
          totalSlides={slides.length}
          currentSlide={selectedIndex + 1}
          onSlideChange={(newSlide) => setSelectedIndex(newSlide - 1)}
          onClose={() => setSelectedIndex(-1)}
        >
          {selectedIndex !== -1 &&
            renderPreview({
              slide: slides[selectedIndex],
              index: selectedIndex,
            })}
        </CarouselDialog>
      )}
    </>
  );
};

export default CarouselWithPreview;
