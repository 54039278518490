export const reorder = (array: Array<any>, startIndex: number, endIndex: number): Array<any> => {
    const result = Array.from(array);

    if (
        array.length === 0 ||
        startIndex === endIndex ||
        !Number.isInteger(startIndex) ||
        startIndex < 0 ||
        startIndex >= array.length ||
        !Number.isInteger(endIndex)
    )
        return result;

    if (endIndex < 0)
        endIndex = 0;
    if (endIndex >= array.length)
        endIndex = array.length - 1;

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}
