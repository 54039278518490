import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { Document } from "./types";
import { getRotate } from "./helpers";

interface Variables {
  id?: string;
}

const useGetDocument = ({ id }: Variables) => {
  const { data, ...rest } = useQuery<{ documents: Document[] }>(
    gql`
      query ADIT_GET_DOCUMENT($id: uuid!) {
        documents: document(where: { id: { _eq: $id } }) {
          id
          document_extension
          document_name
          creation_date
          creation_user
          pages_m2m(order_by: { page_number: asc }) {
            page {
              id
              thumbnail_small_url
              thumbnail_big_url
              page_status
              rotate
              meta
            }
          }
          org_id
          orgId {
            org_id_description
          }
          document_status
          document_flags
          document_blobs
        }
      }
    `,
    {
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
      skip: !id,
    }
  );

  const document = data?.documents[0];

  const result = {
    data: {
      document: document && {
        ...document,
        pages_m2m: document.pages_m2m.map((documentPage: any) => ({
          ...documentPage,
          page: {
            ...documentPage.page,
            rotate: getRotate(documentPage.page),
          },
        })),
      },
    },
    ...rest,
  };
  return result;
};

export default useGetDocument;
