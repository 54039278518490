import { gql } from "@apollo/client";

export const GET_FILE_BY_IDENTIFICATION = gql`
  query checkFileStatus($fileNumber: String!) {
    file(where: { identification: { _eq: $fileNumber } }) {
      id
      file_status
      identification
      file_flags
    }
  }
`;
