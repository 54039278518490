import React from "react";

import Text from "components/Text/Text";

/**
 * A 403 (Forbidden) page
 */
const ForbiddenPage: React.FC = () => (
  <Text variant="h1">403! $t(forbidden)!</Text>
);

export default ForbiddenPage;
