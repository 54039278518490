import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";

import useGetDocumentTypes from "domain/documentType/useGetDocumentTypes";

export type AiStatsByDay = {
  creation_date: string;
  docs_qty: number;
  document_type: string;
  pages_qty: number;
  success_ratio: number;
  total: number;
  types_match: number;
  unknowns: number;
  unknowns_pct: number;
  color: string;
};

type QueryResult = {
  pp_ai_stats_dt: AiStatsByDay[];
};

export const GET_AI_STATS_BY_DAY = gql`
  query getAiStatsByDay(
    $started: timestamptz
    $ended: timestamptz
    $onlyAiDoctypes: Boolean
    $userIds: _uuid
    $orgIds: _int2
  ) {
    pp_ai_stats_dt(
      args: {
        _started: $started
        _ended: $ended
        _only_ai_doctypes: $onlyAiDoctypes
        _orgids: $orgIds
        _crusers: $userIds
      }
    ) {
      creation_date
      docs_qty
      document_type
      pages_qty
      success_ratio
      types_match
      unknowns
      unknowns_pct
    }
  }
`;

interface Variables {
  startDate?: string;
  endDate?: string;
  onlyAiDoctypes?: boolean;
  userIds?: string[];
  orgIds?: string[];
}

const useGetAiStatsByDay = ({
  startDate,
  endDate,
  onlyAiDoctypes = true,
  userIds,
  orgIds,
}: Variables): any => {
  const variables = useMemo(
    () => ({
      ...(startDate
        ? { started: startDate }
        : { started: dayjs(0).utc().format() }),
      ...(endDate ? { ended: endDate } : { ended: dayjs().utc().format() }),
      onlyAiDoctypes,
      userIds: userIds?.length ? `{${userIds?.join(",")}}` : undefined,
      orgIds: orgIds?.length ? `{${orgIds?.join(",")}}` : undefined,
    }),
    [endDate, onlyAiDoctypes, orgIds, startDate, userIds]
  );

  const queryResult = useQuery<QueryResult>(GET_AI_STATS_BY_DAY, {
    variables,
    fetchPolicy: "network-only",
  });
  const docTypeQueryResult = useGetDocumentTypes();

  const preparedResult = useMemo(() => {
    const { data } = queryResult;
    const { data: docsType } = docTypeQueryResult;
    if (!data || !docsType) {
      return {
        ...queryResult,
        data: [],
      };
    }

    const result = data.pp_ai_stats_dt
      .map((aiStats) => {
        const docType = docsType.find(
          (docType) => docType.name === aiStats.document_type
        );

        if (docType) {
          return {
            ...aiStats,
            document_type: docType.translated_name,
            color: docType.meta?.color,
          };
        }

        return null;
      })
      .filter(Boolean);

    return { ...queryResult, data: result };
  }, [docTypeQueryResult, queryResult]);

  return preparedResult;
};

export default useGetAiStatsByDay;
