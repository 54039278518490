import { Autocomplete } from "@periplus/ui-library";
import useAutocomplete, {
  AddressAutocompleteHookProps as AutocompleteHookProps,
} from "./useAddressAutocomplete";
import React from "react";

interface Props<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends AutocompleteHookProps<Multiple, DisableClearable> {}

export default function <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined
>({ ...rest }: Props<Multiple, DisableClearable>) {
  const props = useAutocomplete(rest);

  return <Autocomplete {...props} />;
}
