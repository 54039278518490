import { useMemo } from "react";
import { gql } from "@apollo/client";
import {
  AditGetDeclarationsTableQuery,
  useAditGetDeclarationsTableQuery,
  AddressFilter,
} from "graphql/generated";
import { TableUrlSearchParams } from "hooks/useUrlSearchParams";
import {
  DeclarationFilters,
  DECLARATION_FILTER_RELATIVE_DATES,
} from "pages/Select/WeDeclare/components/DeclarationFiltersSidebar";

export type GetDeclarationsTableVariables =
  TableUrlSearchParams<DeclarationFilters> & {
    nonclassifiedDocsExists?: boolean;
  };

export type DeclarationTableEntity = NonNullable<
  AditGetDeclarationsTableQuery["declarations"]
>[number];

gql`
  query AditGetDeclarationsTable(
    $nonclassifiedDocsExists: Boolean
    $limit: Int
    $offset: Int
    $orderBy: [JSONObject!]
    $declarationStatus: [DeclarationStatus!]
    $search: String
    $addresses: [AddressFilter!]
    $dateMin: LocalDateTime
    $dateMax: LocalDateTime
    $maxTotalColis: Int
    $maxTotalGrossWeight: Float
    $minTotalColis: Int
    $minTotalGrossWeight: Float
    $orgIds: [Int!]
  ) {
    declarations: getDeclarationRequestsAdit(
      nonclassifiedDocsExists: $nonclassifiedDocsExists
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      declarationStatus: $declarationStatus
      search: $search
      addresses: $addresses
      dateMin: $dateMin
      dateMax: $dateMax
      maxTotalColis: $maxTotalColis
      maxTotalGrossWeight: $maxTotalGrossWeight
      minTotalColis: $minTotalColis
      minTotalGrossWeight: $minTotalGrossWeight
      orgIds: $orgIds
    ) {
      consigneeName
      autoclassifiedDocsQty
      consigneeRef
      consignorName
      consignorRef
      creationDate
      creationUser
      customsOfficeNumber
      declarationStatus
      drNo
      evvReceived
      fileId
      identification
      importerRef
      messageCount
      nonclassifiedDocsExists
      payerName
      payerRef
      totalColis
      totalGrossWeight
      discussionId
      orgId
      addressReferences
    }
  }
`;

export default ({
  nonclassifiedDocsExists,
  page,
  itemsPerPage,
  sorting,
  search,
  filters: {
    payer,
    consignor,
    consignee,
    declaration_statuses,
    relative_date,
    min_date,
    max_date,
    org_ids,
    minTotalColis,
    maxTotalColis,
    minTotalGrossWeight,
    maxTotalGrossWeight,
  } = {},
}: GetDeclarationsTableVariables) => {
  const result = useAditGetDeclarationsTableQuery({
    variables: {
      nonclassifiedDocsExists,
      limit: itemsPerPage + 1,
      offset: (page - 1) * itemsPerPage,
      orderBy: sorting?.length ? sorting : undefined,
      declarationStatus: declaration_statuses,
      search: search ? `%${search.trim()}%` : undefined,
      addresses: (() => {
        const addresses = [
          { addressType: "payer", addressIds: payer },
          { addressType: "consignor", addressIds: consignor },
          { addressType: "consignee", addressIds: consignee },
        ].filter((el) => el.addressIds?.length) as AddressFilter[];
        return addresses.length ? addresses : undefined;
      })(),
      dateMin:
        relative_date === null
          ? min_date
          : DECLARATION_FILTER_RELATIVE_DATES[relative_date ?? "last4weeks"]
              .min_date,
      dateMax:
        relative_date === null
          ? max_date
          : DECLARATION_FILTER_RELATIVE_DATES[relative_date ?? "last4weeks"]
              .max_date,
      orgIds: org_ids,
      minTotalColis,
      maxTotalColis,
      minTotalGrossWeight,
      maxTotalGrossWeight,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  return useMemo(() => {
    const declarations =
      (result.data ?? result.previousData)?.declarations ?? [];
    return {
      ...result,
      data: {
        declarations: declarations.slice(0, itemsPerPage),
        isLastPage: declarations.length <= itemsPerPage,
      },
    };
  }, [result, itemsPerPage]);
};
