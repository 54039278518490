import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { get } from "lodash";
import { Page } from "./useGetDocumentPages";

import { mapPages } from "../helpers";
import { sortDocuments } from "utils/customRelevant";

export type BasedDocument = {
  id: string;
  document_type: string;
  document_status: string;
  document_name: string;
  document_extension: string;
  last_modified_date: string;
  sequence: number;
  created_by_user: {
    display_name: string;
  };
  number_of_pages: number;
  document_flags: number;
  meta: any;
  document_blobs: any;
  documentType?: {
    translations: {
      en: string;
      de: string;
      ru: string;
    };
  };
};

export interface Document extends BasedDocument {
  pages: Page[];
}

export interface ServerDocument extends BasedDocument {
  pages: {
    page: Page;
  }[];
}

export type File = {
  documents: Document[];
};

export const GET_FILE_PAGES = gql`
  query getFilesPages($fileNumber: uuid!) {
    files: vw_finished_files(where: { id: { _eq: $fileNumber } }) {
      documents(
        limit: 30
        order_by: { creation_date: desc }
        where: {
          _and: {
            document_type: { _neq: "customs_overview" }
            document_status: { _neq: "classified" }
            is_deleted: { _eq: false }
          }
        }
      ) {
        id
        document_type
        document_status
        document_name
        document_flags
        last_modified_date
        sequence
        meta
        created_by_user {
          display_name
        }
        document_blobs(path: "blobs")
        documentType {
          translations
        }
        pages: pages_m2m(order_by: { page_number: asc }) {
          page {
            id
            rotate
            thumbnailBigUrl: thumbnail_big_url
            thumbnailSmallUrl: thumbnail_small_url
            page_status
            meta
          }
          page_number
        }
      }
    }
  }
`;

const useGetFilePages = (fileNumber?: string): any => {
  const { data, loading, error } = useQuery(GET_FILE_PAGES, {
    variables: {
      fileNumber: fileNumber,
    },
    fetchPolicy: "network-only",
    skip: !fileNumber,
  });

  //useSubscribeFileChanges(fileNumber, refetch);

  const returnValue = useMemo(() => {
    let file: File | Object = {};

    if (data) {
      file = !!fileNumber ? data.files[0] : {};
    }

    const documents: ServerDocument[] = sortDocuments(
      get(file, "documents", [])
    );

    const updatedData = documents.map((document: ServerDocument) => {
      let pages = mapPages(document.pages);

      if (document.document_type === "unknown") {
        pages = pages.filter((page: Page) => page.page_status !== "classified");
      }
      let number_of_pages = pages.length;

      return {
        ...document,
        number_of_pages,
        pages,
      };
    });

    return {
      data: updatedData,
      loading,
      error,
    };
  }, [data, error, loading]);

  return returnValue;
};

export default useGetFilePages;
