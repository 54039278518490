import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const INSERT_IMPORT_LIST_TRIGGER = gql`
  mutation INSERT_IMPORT_LIST_TRIGGER(
    $object: import_list_trigger_insert_input!
  ) {
    insert_import_list_trigger_one(object: $object) {
      id
      type
      config
      creation_user
      creation_date
      last_modified_user
      last_modified_date
      meta
    }
  }
`;

const useInsertImportListTrigger = () => {
  const [insert_import_list_trigger_one] = useMutation(
    INSERT_IMPORT_LIST_TRIGGER
  );

  return insert_import_list_trigger_one;
};

export default useInsertImportListTrigger;
