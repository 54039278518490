import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Theme,
  InputAdornment,
  Typography,
  RadioGroup,
  Radio,
  styled,
  Box,
  FormControlLabel,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import WarningComponent from "@mui/icons-material/ReportProblemRounded";
import config from "config";

import useValidateFile from "graphql/hooks/useValidateFile";
import ShipmentResponse from "./ShipmentResponse";
import { DeclarationState } from "./FileDialog";
import DeclarationValidation from "./DeclarationValidation";
import { makeStyles } from "tss-react/mui";
import { File } from "hooks/useClassifier";

type ActionType = "new" | "existing";

const { prefixMRSNumber } = config;
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },

  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  detailName: {
    fontSize: 14,
    fontWeight: "bold",
    marginRight: theme.spacing(1),
  },

  details: {
    fontSize: 14,
    width: "90%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textTransform: "none",
  },

  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(),
  },
}));

const RadioContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  flexGrow: 1,
  flexBasis: 0,
}));

export interface ValidationData {
  fileNumber?: string;
  orgId?: number;
  type?: number;
  fileId?: string;
  scheduleFileExport?: boolean;
}

interface IFileValidation {
  file?: File;
  validationData: (object: ValidationData) => void;
  checkForDuplicate: (fileNumber: string) => void;
  warning?: string | null;
  setDeclarationData: React.Dispatch<React.SetStateAction<DeclarationState>>;
  declarationData: DeclarationState;
  isCustomsBroker: boolean;
  declarationExpanded: boolean;
  onDeclarationExpandedChange: (newValue: boolean) => void;
}

const FileValidation: React.FC<IFileValidation> = ({
  file,
  validationData,
  checkForDuplicate,
  warning,
  declarationData,
  setDeclarationData,
  isCustomsBroker,
  declarationExpanded,
  onDeclarationExpandedChange,
}) => {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const [actionType, setActionType] = useState<ActionType>(
    file?.fileNumber.includes("newFileNumber") ? "new" : "existing"
  );

  const [fileNumber, setFileNumber] = useState<string>(
    (() => {
      if (!file?.fileNumber || file.fileNumber.includes("newFileNumber"))
        return "";

      return file.fileNumber.split("-")[1];
    })()
  );

  const {
    data: shipmentResponse,
    loading,
    error,
  } = useValidateFile(fileNumber, !!file);

  useEffect(() => {
    if (file) {
      return;
    }

    const declarationType = declarationExpanded ? 8 : 0;
    if (actionType === "existing") {
      if (error) {
        validationData({});
        return;
      }
      if (
        shipmentResponse &&
        shipmentResponse.data &&
        !shipmentResponse.error_code
      ) {
        const { shipmentNumber, orgId } = shipmentResponse.data;
        validationData({
          fileNumber: shipmentNumber,
          orgId: parseInt(orgId),
          fileId: shipmentResponse.fileId,
          type: declarationType,
        });
        return;
      }
      validationData({});
    } else if (actionType === "new") {
      validationData({
        fileNumber: "newFileNumber",
        orgId: 0,
        type: declarationType,
      });
    }
  }, [
    file,
    actionType,
    shipmentResponse.data,
    error,
    declarationExpanded,
    setDeclarationData,
  ]);

  const handleActionTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newActionType: string
  ) => {
    if (newActionType === "new") onDeclarationExpandedChange(true);
    setActionType(newActionType as ActionType);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numbersOnly = event.target.value.replace(/[^0-9]/g, "");
    setFileNumber(event.target.value);
    checkForDuplicate(numbersOnly);
  };

  return (
    <div className={classes.root}>
      <RadioGroup
        value={actionType}
        onChange={handleActionTypeChange}
        style={{
          gap: 16,
        }}
        row
      >
        <RadioContainer>
          <FormControlLabel
            control={<Radio value="existing" color="primary" />}
            label={t("classify:existingFileNumber")}
            disabled={!!file}
          />
          <TextField
            placeholder={t("classify:enterFileNumber")}
            id="fileNumberInput"
            value={fileNumber}
            onChange={handleFileChange}
            // eslint-disable-next-line
            inputProps={{
              maxLength: 7,
            }}
            // eslint-disable-next-line
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {prefixMRSNumber}-
                </InputAdornment>
              ),
            }}
            fullWidth
            disabled={!!file || actionType !== "existing"}
          />
        </RadioContainer>
        <RadioContainer>
          <FormControlLabel
            control={<Radio value="new" color="primary" />}
            label={t("classify:newFileNumber")}
            disabled={!!file}
          />
        </RadioContainer>
      </RadioGroup>
      {actionType === "existing" && (
        <>
          {!!error && (
            <Typography color="error">
              {i18n.exists(`error:${error}`)
                ? t(`error:${error}`)
                : t("error:566")}
            </Typography>
          )}
          {!!warning && (
            <Grid container direction="row" wrap="nowrap">
              <WarningComponent htmlColor="#F7D012" fontSize="large" />
              <Typography style={{ marginLeft: "10px" }}>
                {t(`warning:${warning}`)}
              </Typography>
            </Grid>
          )}

          <ShipmentResponse
            loading={loading}
            data={shipmentResponse}
            error={error}
          />
        </>
      )}
      {isCustomsBroker && (
        <DeclarationValidation
          file={file}
          declarationData={declarationData}
          setDeclarationData={setDeclarationData}
          declarationExpanded={declarationExpanded}
          onDeclarationExpandedChange={onDeclarationExpandedChange}
        />
      )}
    </div>
  );
};

export default FileValidation;
