import React, { HtmlHTMLAttributes, useState } from "react";
import { ThreeDotsMenu, Theme, ListItemIcon } from "@periplus/ui-library";
import { Add, Launch } from "@mui/icons-material";
import { Text } from "components/Text";
import { useAuth } from "contexts/AuthContext";
import { Role } from "rbac-rules";
import { useHistory } from "react-router-dom";
import config from "config";
import ExportToCevConfirmDialog from "domain/declaration/components/ExportToCevConfirmDialog";
import { makeStyles } from "tss-react/mui";
import { DeclarationTableEntity } from "../hooks/useGetDeclarationsTable";

interface Props extends HtmlHTMLAttributes<HTMLElement> {
  declaration: DeclarationTableEntity;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  listIcon: {
    minWidth: 28,
  },
}));

export default ({ declaration }: Props) => {
  const { classes } = useStyles();
  const history = useHistory();
  const { user } = useAuth();
  const isAlphaTester = user?.allowedRoles?.includes(Role.AlphaTester);
  const isUserAllowedToAutoClassification = !!isAlphaTester;
  const [isExportToCevConfirmDialogOpen, setIsExportToCevConfirmDialogOpen] =
    useState(false);

  return (
    <>
      <ThreeDotsMenu
        options={[
          ...(isUserAllowedToAutoClassification
            ? [
                {
                  content: (
                    <>
                      <ListItemIcon className={classes.listIcon}>
                        <Add />
                      </ListItemIcon>
                      <Text>Declaration</Text>
                    </>
                  ),
                  action: () => {
                    history.push(
                      `/select/declaration/${encodeURIComponent(
                        declaration.fileId
                      )}/${declaration.drNo}`
                    );
                  },
                },
              ]
            : []),
          {
            content: (
              <>
                <ListItemIcon className={classes.listIcon}>
                  <Launch />
                </ListItemIcon>
                <Text>common:details</Text>
              </>
            ),
            action: () => {
              window.open(
                `${config.wedclare}/declaration/${declaration.fileId}/${declaration.drNo}`,
                "_blank"
              );
            },
            disabled: !declaration.fileId,
          },
        ]}
        disabled={declaration.declarationStatus === "declaration_canceled"}
      />
      {isExportToCevConfirmDialogOpen && (
        <ExportToCevConfirmDialog
          file_id={declaration.fileId}
          dr_no={declaration.drNo}
          onClose={() => {
            setIsExportToCevConfirmDialogOpen(false);
          }}
        />
      )}
    </>
  );
};
