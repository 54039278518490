import React from "react";
import { Box, PageContainer } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import {
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import NotClassified from "./NotClassified";
import Classified from "./Classified";
import { Typography } from "@mui/material";
import AuthRoute from "App/Routes/Auth/AuthRoute";

export const WE_DECLARE_TABS = [
  {
    id: "unclassified",
    label: "Unclassified",
  },
  {
    id: "declarations",
    label: "Declarations",
  },
] as const;

export default () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch();
  const history = useHistory();

  return (
    <PageContainer
      title={t("WeDeclare")}
      tabs={WE_DECLARE_TABS.map(({ id, label }) => (
        <Box
          key={id}
          sx={{
            display: "flex",
          }}
        >
          <Typography>{t(label)}</Typography>
        </Box>
      ))}
      selectedTab={WE_DECLARE_TABS.findIndex(
        ({ id }) => id === pathname.split("/").pop()!
      )}
      onTabChange={(newTabIndex) =>
        history.push(`${url}/${WE_DECLARE_TABS[newTabIndex].id}`)
      }
      sx={{
        "& .LuiPageContainer-content": {
          display: "flex",
          flexDirection: "row",
          gap: 0,
          p: 0,
        },
      }}
    >
      <Switch>
        <AuthRoute path={`${path}/unclassified`}>
          <NotClassified />
        </AuthRoute>
        <AuthRoute path={`${path}/declarations`}>
          <Classified />
        </AuthRoute>
      </Switch>
    </PageContainer>
  );
};
