import React, {
  CSSProperties,
  ReactElement,
  FC,
  useRef,
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
} from "react";
import { Grid } from "@periplus/ui-library";
import ReactResizeDetector from "react-resize-detector";
import { scrollTo, ScrollToCustomOptions } from "scroll-js";
import { Document } from "graphql/hooks/useGetDocuments";
import DocumentPage from "./DocumentPages";
import getDocumentNameWithCorrectExtension from "utils/getDocumentNameWithCorrectExtension";
import { makeStyles } from 'tss-react/mui';

const INITIAL_IMAGE_WIDTH = 595;
const INITIAL_IMAGE_HEIGHT = 842;

const useStyles = makeStyles()({
  container: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    overflowY: "auto",
  },
});

export interface ScrollOptions extends ScrollToCustomOptions {
  offset?: {
    top: number;
    bottom: number;
  };
  alwaysToTop?: boolean;
  rowHeight?: number;
  row?: number;
}

const scrollIntoView = (
  container: HTMLElement,
  options: ScrollOptions = {}
) => {
  const { offset, row, rowHeight, ...otherOptions } = Object.assign(
    { offset: { top: 0, bottom: 0 }, row: 1, rowHeight: 0 },
    options
  );

  if (!container) {
    return;
  }

  const minView = container.scrollTop;
  const maxView = minView + container.clientHeight;

  const elementMin = row * rowHeight;
  const elementMax = elementMin + rowHeight;

  if (minView >= elementMin + offset.top) {
    scrollTo(container, {
      top: elementMin + offset.top,
    });
  } else if (maxView <= elementMax + offset.bottom) {
    let top = otherOptions.alwaysToTop
      ? elementMin + offset.top
      : elementMax + offset.bottom - container.clientHeight;
    scrollTo(container, {
      top,
    });
  }
};

interface DocumentListProps {
  documents: Document[];
  pagePerRow: number;
  children: (args: {
    style: CSSProperties;
    path: string;
  }) => ReactElement | null;
  toggleExpand: (document: Document, documentIndex: number) => void;
  expandedDocuments: {
    [key: string]: boolean;
  };
  focused: string;
  scrollOptions?: ScrollOptions;
  withoutBookmarks?: boolean;
  onScrolledItem?: (item: number) => void;
}

const DocumentList: FC<DocumentListProps> = ({
  documents,
  pagePerRow,
  children,
  toggleExpand,
  expandedDocuments,
  scrollOptions,
  focused,
  withoutBookmarks = false,
  onScrolledItem,
}) => {
  const ref = useRef<any>();
  const { classes } = useStyles();
  const [documentOffsetPerIndex, setDocumentOffset] = useState<any>([]);
  // @ts-ignore
  const { clientWidth, clientHeight } = ref.current || {
    clientWidth: 0,
    clientHeight: 0,
  };
  const [clientSize, setClientSize] = useState({
    width: clientWidth,
    height: clientHeight,
  });
  const [scrollTop, setScrollTop] = useState(0);

  const imgWidth = useMemo(
    () => clientSize.width / pagePerRow,
    [clientSize.width, pagePerRow]
  );
  const imgHeight = useMemo(
    () =>
      Math.floor((imgWidth * INITIAL_IMAGE_HEIGHT) / INITIAL_IMAGE_WIDTH - 5),
    [imgWidth]
  );

  useLayoutEffect(() => {
    onScrolledItem && onScrolledItem(0);

    if (ref.current) {
      const documentsPagesCount = documents.reduce(
        (acc, document, index) => [
          ...acc,
          (acc[index - 1] || 0) + document.pages.length * imgHeight,
        ],
        [] as number[]
      );

      let currentItem = 0;
      const element = ref.current;
      const onScroll = () => {
        // @ts-ignore
        setScrollTop(element.scrollTop);
        const scrolledToItem = documentsPagesCount.findIndex(
          (documentHeight, index) => {
            const beginHeight =
              index === 0 ? 0 : documentsPagesCount[index - 1];
            if (
              element.scrollTop >= beginHeight &&
              documentHeight > element.scrollTop
            ) {
              return true;
            }
            return false;
          }
        );
        if (scrolledToItem !== currentItem) {
          currentItem = scrolledToItem;

          onScrolledItem && onScrolledItem(scrolledToItem);
        }
      };
      element.addEventListener("scroll", onScroll);
      return () => {
        element.removeEventListener("scroll", onScroll);
      };
    }
  }, [setClientSize, imgHeight]);

  const height = useMemo(() => {
    const documentsHeight =
      documents.reduce((acc, document, index) => {
        // we count document + title(53px). Because document counts from 0, we add 1
        const titleSize = (index + 1) * 53;
        const rowCount = expandedDocuments[document.id]
          ? Math.ceil(document.pages.length / pagePerRow)
          : 0;

        setDocumentOffset((prevValues: any) => {
          prevValues[index] = acc * imgHeight + titleSize;
          return [...prevValues];
        });

        return acc + rowCount;
      }, 0) *
        imgHeight +
      documents.length * 53;
    return Math.max(documentsHeight, clientSize.height);
  }, [clientSize.height, documents, expandedDocuments, imgHeight, pagePerRow]);

  useEffect(() => {
    if (!focused) return;

    const focusedAsArray = focused.split(".").map(Number);

    if (focusedAsArray[0] === -1 || isNaN(focusedAsArray[0])) return;

    const prevIndexes = new Array(focusedAsArray[0])
      .fill(null)
      .reduce((acc, _, index) => {
        if (!expandedDocuments[documents[index].id]) {
          return acc;
        }

        const rowCount = Math.ceil(documents[index].pages.length / pagePerRow);
        return acc + rowCount;
      }, 0);

    let currentIndex = Math.floor(focusedAsArray[1] / pagePerRow);

    const offset = (focusedAsArray[0] + 1) * 53;
    scrollIntoView(ref.current, {
      row: prevIndexes + currentIndex,
      rowHeight: imgHeight,
      offset: {
        top: offset - 53,
        bottom: offset,
      },
      ...(scrollOptions || {}),
    });
  }, [documents, focused, imgHeight, pagePerRow, scrollOptions]);

  return (
    <>
      <ReactResizeDetector
        handleWidth
        handleHeight
        onResize={(width, height) =>
          setClientSize((prevValues) => {
            if (prevValues.width === width && prevValues.height === height) {
              return prevValues;
            }

            return { width, height };
          })
        }
      />
      <Grid className={classes.container} ref={ref}>
        {documents.map((document, dIndex) => {
          const updatedDocumentName =
            getDocumentNameWithCorrectExtension(document);
          const rowCount = Math.ceil(document.pages.length / pagePerRow);

          return (
            <DocumentPage
              key={`${document.id}.${dIndex}`}
              pagePerRow={pagePerRow}
              title={updatedDocumentName}
              rowCount={rowCount}
              parentIndex={dIndex}
              containerSizes={clientSize}
              scrollTop={scrollTop}
              imgSizes={{ height: imgHeight, width: imgWidth }}
              isExpanded={!!expandedDocuments[document.id]}
              toggleExpand={() => toggleExpand(document, dIndex)}
              height={height}
              documentOffset={documentOffsetPerIndex[dIndex]}
              scrollContainer={ref.current}
              documentsCount={documents.length}
              withoutBookmarks={documents.length === 1 || withoutBookmarks}
            >
              {children}
            </DocumentPage>
          );
        })}
      </Grid>
    </>
  );
};

export default DocumentList;
