import { gql } from "@apollo/client";

export const SCHEDULE_DOC_ANNOTATION_EXPORT = gql`
  mutation SCHEDULE_DOC_ANNOTATION_EXPORT($documentID: ID!) {
    scheduleDocAnnotationExport(id: $documentID)
  }
`;

export const FINISH_VALIDATION = gql`
  mutation FINISH_VALIDATION($id: ID!) {
    finishDocumentValidation(docId: $id)
  }
`;

export const ADD_MEASURE_TIME = gql`
  mutation ADD_MEASURE_TIME(
    $id: uuid!
    $document_type: String!
    $meta: jsonb!
  ) {
    update_document_by_pk(
      pk_columns: { document_type: $document_type, id: $id }
      _append: { meta: $meta }
    ) {
      id
    }
  }
`;
