import React, { useMemo } from "react";
import { Button, MRTable, Typography } from "@periplus/ui-library";
import useGetModelRetrains, { ModelRetrainEntity } from "./useGetModelRetrains";
import { useTranslation } from "react-i18next";
import useTriggerModelRetraining from "./useTriggerModelRetraining";

export default () => {
  const { t } = useTranslation();

  const {
    data: { model_retrains },
    loading,
    refetch,
  } = useGetModelRetrains();
  const [triggerModelRetraining, { loading: triggerModelRetrainingLoading }] =
    useTriggerModelRetraining();

  const [columns, tableData] = useMemo<
    [MRTable.MRT_ColumnDef<ModelRetrainEntity>[], ModelRetrainEntity[]]
  >(
    () => [
      [
        {
          header: t("ID"),
          id: "id",
          accessorFn: (originalRow) => originalRow.id,
        },
        {
          header: t("Request Time"),
          id: "request_time",
          accessorFn: (originalRow) => originalRow.request_time,
        },
        {
          header: t("Status"),
          id: "status",
          accessorFn: (originalRow) => originalRow.status,
        },
        {
          header: t("Start Time"),
          id: "start_time",
          accessorFn: (originalRow) => originalRow.start_time,
        },
        {
          header: t("End Time"),
          id: "end_time",
          accessorFn: (originalRow) => originalRow.end_time,
        },
        {
          header: t("Error Message"),
          id: "error_message",
          accessorFn: (originalRow) => originalRow.error_message,
        },
      ],
      [...model_retrains],
    ],
    [t, model_retrains]
  );

  return (
    <MRTable.Table
      columns={columns}
      data={tableData}
      state={{
        isLoading: loading,
      }}
      onRefresh={() => refetch()}
      enableColumnFilters
      renderTopToolbarCustomActions={() => (
        <Typography variant="h2" sx={{ alignSelf: "center" }}>
          {t("Model Retrain Data")}
        </Typography>
      )}
      renderToolbarInternalActions={({ table }) => (
        <>
          <MRTable.MRT_ToggleFiltersButton table={table} />
          <MRTable.MRT_ShowHideColumnsButton table={table} />
          <MRTable.MRT_ToggleFullScreenButton table={table} />
          <Button
            color="primary"
            variant="contained"
            onClick={async () => {
              await triggerModelRetraining();
              refetch();
            }}
            loading={triggerModelRetrainingLoading}
          >
            {t("Trigger Retraining")}
          </Button>
        </>
      )}
      muiTableContainerProps={{
        sx: {
          maxHeight: "calc(100vh - 270px)",
        },
      }}
    />
  );
};
