import React, { FC, CSSProperties } from "react";
import { SvgIcon } from "@periplus/ui-library";

interface GermanyProp {
  style?: CSSProperties;
  [key: string]: any;
}

const Germany: FC<GermanyProp> = ({ style, ...rest }) => {
  return (
    <SvgIcon
      viewBox="0 0 640 480"
      style={{ height: "18px", width: "24px", ...style }}
      {...rest}
    >
      <path fill="#ffce00" d="M0 320h640v160H0z" />
      <path d="M0 0h640v160H0z" />
      <path fill="#d00" d="M0 160h640v160H0z" />
    </SvgIcon>
  );
};

export default Germany;
