export const hexToRgbA = (hex: string, opacity: number = 1) => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ","
    )},${opacity})`;
  }
  return hex;
};

export const getRgbValues = (rgb: string) => {
  return (rgb.match(/(\d+)/g) || []).map((el) => Number(el));
};

export const changeRGBA = (
  rgba: string,
  color: { r?: number; g?: number; b?: number; a?: number }
) => {
  rgba.replace(
    /(\d+),\s*(\d+),\s*(\d+),\s*([\d.]*)/,
    (match, _r, _g, _b, _a) => {
      const { r = _r, g = _g, b = _b, a = _a } = color;
      return [r, g, b, a].join(", ");
    }
  );
};
