import React from "react";
import {
  TextField,
  Grid,
  Autocomplete,
  DatePicker,
  Theme,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";

import { useApplicationState } from "contexts/ApplicationContext";
import { EdecCustomsOffice } from "domain/edec/useGetEdecMasterData";
import { DeclarationState } from "./FileDialog";
import AddressAutocomplete from "domain/address/components/AddressAutocomplete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import { File } from "hooks/useClassifier";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
    "& .MuiAccordion-root": {
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
      "&.Mui-expanded": {
        margin: "auto",
      },
    },
    "& .MuiAccordionSummary-root": {
      "&.Mui-expanded": {
        minHeight: 48,
      },
    },
    "& .MuiAccordionDetails-root": {
      padding: theme.spacing(3),
      borderTop: `1px solid ${theme.palette.primary.dark}`,
    },
  },
}));

interface IDeclarationValidation {
  file?: File;
  setDeclarationData: React.Dispatch<React.SetStateAction<DeclarationState>>;
  declarationData: DeclarationState;
  declarationExpanded: boolean;
  onDeclarationExpandedChange: (newValue: boolean) => void;
}

const DeclarationValidation: React.FC<IDeclarationValidation> = ({
  file,
  setDeclarationData,
  declarationData,
  declarationExpanded,
  onDeclarationExpandedChange,
}) => {
  const { classes } = useStyles();
  const { edecData } = useApplicationState();
  const { t } = useTranslation();

  const edecCustomsOffices: EdecCustomsOffice[] = edecData.edec_customs_offices;

  return (
    <div className={classes.root}>
      <Accordion
        expanded={declarationExpanded}
        onChange={(e, newValue) => onDeclarationExpandedChange(newValue)}
      >
        <AccordionSummary
          disabled={!!file}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <Typography>{t("navigation:declaration")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="column" wrap="nowrap" spacing={2}>
            <Grid item className="grid-item">
              <AddressAutocomplete
                value={declarationData.payerAddress}
                onChange={(event, newValue) => {
                  setDeclarationData({
                    ...declarationData,
                    payerAddress: newValue,
                    payerName: newValue?.companyName || null,
                  });
                }}
                InputProps={{
                  label: `${t("classify:payer")} *`,
                }}
                loading={false}
                clearOnBlur={false}
              />
            </Grid>
            <Grid item className="grid-item">
              <TextField
                label={t("classify:payerReference")}
                value={declarationData.payerReference}
                onChange={(e: any) =>
                  setDeclarationData({
                    ...declarationData,
                    payerReference: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
            <Grid item className="grid-item">
              <Autocomplete
                InputProps={{
                  label: `${t("declarationNew:Customs Office Nr.")} *`,
                }}
                options={edecCustomsOffices}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                getOptionLabel={(option: any) =>
                  `${option.name} ${option.number}`
                }
                value={declarationData.customsOffice}
                onChange={(_: any, value: any) =>
                  setDeclarationData({
                    ...declarationData,
                    customsOffice: value,
                  })
                }
              />
            </Grid>
            <Grid item className="grid-item">
              <AddressAutocomplete
                value={declarationData.consignorAddress}
                onChange={(e: any, newValue: any) => {
                  setDeclarationData({
                    ...declarationData,
                    consignorAddress: newValue,
                  });
                }}
                InputProps={{
                  label: `${t("classify:consignor")}`,
                }}
                clearOnBlur={false}
              />
            </Grid>
            <Grid item className="grid-item">
              <AddressAutocomplete
                value={declarationData.consigneeAddress}
                onChange={(e: any, newValue: any) => {
                  setDeclarationData({
                    ...declarationData,
                    consigneeAddress: newValue,
                  });
                }}
                InputProps={{
                  label: `${t("classify:consignee")}`,
                }}
                clearOnBlur={false}
              />
            </Grid>
            <Grid item className="grid-item">
              <AddressAutocomplete
                value={declarationData.importerAddress}
                onChange={(e: any, newValue: any) => {
                  setDeclarationData({
                    ...declarationData,
                    importerAddress: newValue,
                  });
                }}
                InputProps={{
                  label: `${t("classify:importer")}`,
                }}
                clearOnBlur={false}
              />
            </Grid>
            <Grid item className="grid-item">
              <DatePicker
                label={t("classify:ETA")}
                value={declarationData.etaDate || null}
                onChange={(newDate: any) =>
                  setDeclarationData({
                    ...declarationData,
                    etaDate: newDate || null,
                  })
                }
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
              />
            </Grid>
            <Grid item className="grid-item">
              <TextField
                fullWidth
                placeholder={t("classify:totalGrossWeight")}
                value={declarationData.totalGrossWeight ?? ""}
                onChange={(e: any) =>
                  setDeclarationData({
                    ...declarationData,
                    totalGrossWeight: parseFloat(e.target.value) || null,
                  })
                }
                inputProps={{
                  maxLength: 20,
                  type: "number",
                }}
              />
            </Grid>
            <Grid item className="grid-item">
              <TextField
                fullWidth
                placeholder={t("classify:totalColis")}
                value={declarationData.totalColis ?? ""}
                onChange={(e: any) =>
                  setDeclarationData({
                    ...declarationData,
                    totalColis: parseInt(e.target.value) || null,
                  })
                }
                inputProps={{
                  maxLength: 20,
                  type: "number",
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DeclarationValidation;
