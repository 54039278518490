import React, { useState, useRef, useEffect, useMemo } from "react";
import { Theme, Popover } from "@periplus/ui-library";
import { DataPoint } from "graphql/hooks/useGetValidationDocument";
import { Annotation } from "graphql/hooks/useTableQuery";
import {
  ANNOTATION_TYPE_COLORS,
  LINE_ITEM_TYPE_COLORS,
} from "../AnnotationList/utils";
import DataPointPopover from "./DataPointPopover";
import { hexToRgbA } from "../../utils/colors";
import DataPointRect from "./DataPointRect";
import { makeStyles } from "tss-react/mui";

interface IDataPointBoxProps {
  dataPoint: DataPoint;
  annotationTypes: { name: string; group: string; assigned: boolean }[];
  lineItemTypes: string[];
  assignToLineItem?: boolean;
  annotation?: Annotation;
  activeAnnotation?: Annotation | null;
  scale: [number, number];
  type?: string;
  updateDataPointAndAssignType: (updated: {
    dataPoint: DataPoint;
    oldType?: string;
    dataPointsInside?: { [key: string]: DataPoint };
    clearAreaAnnotations?: boolean;
  }) => Promise<any>;
  listenOnClick?: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLDivElement>,
    newSelectedDataPoint: DataPoint
  ) => void;
  onRemove?: () => void;
  loading?: boolean;
  newlyCreated?: boolean;
  innerDataPoints?: DataPoint[];
  onClosePopover?(): void;
  selectedFormFieldRef?: React.MutableRefObject<{
    cur: string | null;
    prev: string | null;
  }>;
  prevSelectedDataPointType: {
    annotation?: string;
    lineItem?: string;
  };
}

const useStyles = makeStyles<{ borderColor: string }>()(
  (theme: Theme, props) => ({
    dataPointContent: {
      borderColor: props.borderColor || theme.palette.primary.main,
    },
    activeDataPoint: {
      borderWidth: 2,
      opacity: 1,
    },
    assignedDataPoint: {
      opacity: 1,
      borderWidth: 1,
      background: hexToRgbA(
        props.borderColor || theme.palette.primary.light,
        0.2
      ),
      zIndex: 0,
    },
    innerDataPoint: {
      zIndex: 1,
    },
    focusedDataPoint: {
      borderColor: "red",
      borderRadius: "0 0 1px 1px",
    },
    pendingDataPoint: {
      borderWidth: 2,
      opacity: 1,
    },
    aboveDataPointPortal: {
      zIndex: theme.zIndex.modal,
      "&[x-out-of-boundaries]": {
        visibility: "hidden",
      },
    },
    aboveDataPoint: {
      position: "absolute",
      height: 32,
      width: "100%",
      minWidth: 172,
      top: -36,
      right: -36,
      display: "flex",
    },
    rightDataPoint: {
      position: "absolute",
      width: 32,
      height: 32,
      right: -36,
    },
    iconButton: {
      width: 32,
      height: 32,
      borderWidth: 0.5,
      borderRadius: 1,
      borderStyle: "solid",
      borderColor: "red",
      color: theme.palette.primary.main,
      background: theme.palette.background.paper,
      zIndex: 1,
    },
    groupName: {
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    menuItem: {
      display: "flex",
      justifyContent: "space-between",
    },
    selectedMenuItem: {
      fontSize: 12,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    annotationTypeBox: {
      position: "absolute",
      bottom: "100%",
      left: 0,
      right: 0,
      fontSize: 10,
      border: `2px solid ${theme.palette.primary.main}`,
      borderBottom: 0,
      borderRadius: "5px 5px 0 0",
      padding: "0 4px",
      background: "#6462dc",
      color: "#fff",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      cursor: "pointer",
      display: "none",
    },
  })
);

const DataPointBox = ({
  dataPoint,
  annotationTypes,
  lineItemTypes,
  assignToLineItem,
  annotation,
  activeAnnotation,
  scale,
  updateDataPointAndAssignType,
  loading,
  listenOnClick,
  onClick,
  onRemove,
  newlyCreated,
  innerDataPoints,
  onClosePopover,
  selectedFormFieldRef,
  prevSelectedDataPointType,
}: IDataPointBoxProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const selectBoxRef = useRef<HTMLDivElement | null>(null);
  const [isFocusing, setIsFocusing] = useState<boolean>(false);
  const [showPopoverAbove, setShowPopoverAbove] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const annotationType =
    annotation && annotation.type ? annotation.type.toLocaleLowerCase() : "";
  const { classes, cx } = useStyles({
    borderColor: assignToLineItem
      ? LINE_ITEM_TYPE_COLORS[dataPoint.lineItemType || ""]
      : ANNOTATION_TYPE_COLORS[annotationType],
  });
  const [scaleX, scaleY] = scale;
  //TODO: @cborer remove || activeAnnotation?.dataPointId === dataPoint.id after refactoring annotation names
  const isActiveDataPoint = useMemo(
    () =>
      activeAnnotation?.id === dataPoint.id ||
      activeAnnotation?.dataPointId === dataPoint.id,
    [activeAnnotation, dataPoint.id]
  );

  useEffect(() => {
    setIsFocusing(isActiveDataPoint);
  }, [isActiveDataPoint]);

  useEffect(() => {
    if (ref.current && !listenOnClick && (newlyCreated || isActiveDataPoint)) {
      setAnchorEl(ref.current);
    }
  }, [newlyCreated, isActiveDataPoint, listenOnClick]);

  useEffect(() => {
    if (!ref.current) return;
    const clientRect = ref.current.getBoundingClientRect();
    setShowPopoverAbove(window.innerHeight - clientRect.bottom < 250); // the height of popover
  }, [anchorEl]);

  useEffect(() => {
    if (!anchorEl) return;
    const handleClickOutside = (event: any) => {
      if (!ref.current) return;
      if (ref.current.contains(event.target)) return;
      const element = document.querySelectorAll("[data-no-clickoutside]");
      if (element && Array.from(element).some((e) => e.contains(event.target)))
        return;
      if (selectBoxRef.current && selectBoxRef.current.contains(event.target))
        return;
      setAnchorEl(undefined);
      onClosePopover?.();
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [anchorEl]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (loading) return;
    if (listenOnClick || selectedFormFieldRef?.current.prev) {
      return onClick && onClick(e, dataPoint);
    }
    if (!annotation && activeAnnotation) {
      return updateDataPointAndAssignType({
        dataPoint: {
          ...dataPoint,
          type: activeAnnotation.type,
        },
      });
    }
    if (annotation && activeAnnotation && e.shiftKey) {
      return updateDataPointAndAssignType({
        dataPoint: {
          ...dataPoint,
          type: undefined,
        },
        oldType: annotation.type,
      });
    }
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    onClosePopover?.();
  };

  if ([Infinity, NaN].includes(scaleX) || [Infinity, NaN].includes(scaleY))
    return null;

  let assignedDataPoint: boolean | string | undefined;
  if (dataPoint.type || dataPoint.lineItemType) {
    let key;
    if (assignToLineItem) {
      key = "lineItemType";
    } else {
      key = "type";
    }
    if (dataPoint.dataPointsInside) {
      // @ts-ignore
      assignedDataPoint = dataPoint[key] && !!annotation;
    } else {
      // @ts-ignore
      assignedDataPoint = dataPoint[key] && !dataPoint.parentDataPointId;
    }
  }

  return (
    <DataPointRect
      ref={ref}
      coordinates={dataPoint.coordinates}
      scale={scale}
      className={cx(classes.dataPointContent, {
        [classes.activeDataPoint]: Boolean(anchorEl || loading),
        [classes.assignedDataPoint]: Boolean(assignedDataPoint),
        [classes.focusedDataPoint]: Boolean(!!annotation && isFocusing),
        [classes.pendingDataPoint]: Boolean(newlyCreated),
        [classes.innerDataPoint]: Boolean(
          dataPoint.parentDataPointId || dataPoint.dataPointsInside
        ),
      })}
      onClick={handleClick}
      onMouseEnter={() => setIsFocusing(true)}
      onMouseOut={() => !isActiveDataPoint && setIsFocusing(false)}
      loading={loading}
    >
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: showPopoverAbove ? "top" : "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: showPopoverAbove ? "bottom" : "top",
          horizontal: "center",
        }}
      >
        <DataPointPopover
          annotationTypes={annotationTypes}
          lineItemTypes={lineItemTypes}
          dataPoint={dataPoint}
          innerDataPoints={innerDataPoints}
          annotation={annotation}
          onClose={handleClose}
          onRemove={!newlyCreated ? onRemove : undefined}
          onSubmit={({
            value,
            annotationType,
            dataPointsInside,
            lineItemType,
            clearAreaAnnotations,
          }) => {
            if (assignToLineItem) {
              updateDataPointAndAssignType({
                dataPoint: {
                  ...dataPoint,
                  value,
                  lineItemType,
                },
                dataPointsInside,
                clearAreaAnnotations,
              });
            } else {
              updateDataPointAndAssignType({
                dataPoint: {
                  ...dataPoint,
                  type: annotationType,
                  value,
                  dataPointsInside:
                    dataPointsInside && Object.keys(dataPointsInside).join(","),
                  lineItemType,
                },
                oldType: annotation?.type,
                dataPointsInside,
                clearAreaAnnotations,
              });
            }
          }}
          assignToLineItem={assignToLineItem}
          newlyCreated={newlyCreated}
          prevSelectedDataPointType={prevSelectedDataPointType}
        />
      </Popover>
    </DataPointRect>
  );
};

export default React.memo(DataPointBox);
