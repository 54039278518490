import { Address } from "domain/address/types";

export interface Tenant {
  tenant_name: string;
  id: string;
  address_id: number;
  trader_identification_number?: string;
  address: Address;
}

export enum HARDCODED_TENANTS {
  Aldi = "1ce48bf2-5565-4c63-a22e-a3ade61aa8ee",
  mnr = "b4b61b73-4f38-45a0-b460-7e38b2777479",
  desi = "35ed2ccb-7b44-4d4e-b4eb-a1944208c3af",
}
