import React, { useState } from "react";
import { IconButton, Typography, Theme, Box } from "@periplus/ui-library";
import { GridRow } from "../../graphql/hooks/useTableQuery";
import MagicRowDialog from "./MagicRowDialog";
import SettingsIcon from "@mui/icons-material/Settings";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'columnMenu'>()((theme: Theme, _params, classes) => ({
  row: {
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
    position: "absolute",
    left: 0,
    right: 0,
    pointerEvents: "auto",
  },
  hoverRow: {
    [`&:hover .${classes.columnMenu}`]: {
      visibility: "visible",
    },
  },
  junkRow: {
    background:
      "repeating-linear-gradient(45deg,rgb(100 98 220 / .4), rgb(100 98 220 / .4) 10px, rgb(100 98 220 / .1) 10px, rgb(100 98 220 / .1) 20px)",
  },
  columnMenuContainer: {
    position: "absolute",
    right: "-22px",
    height: "100%",
  },
  columnMenu: {
    visibility: "hidden",
  },
  rowLevel: {
    color: theme.palette.secondary.main,
    fontSize: 16,
  },
}));

interface IMagicRowsProps {
  bounds: ClientRect;
  rows: GridRow[];
  onChangeRow: (index: number, row: GridRow) => void;
  hoverDisabled?: boolean;
}

const MagicRow = ({
  row,
  style,
  onChangeRow,
  hoverDisabled,
}: {
  row: GridRow;
  [key: string]: any;
}) => {
  const { classes, cx } = useStyles();
  const [isRowDialogOpen, setIsRowDialogOpen] = useState(false);

  return <>
    <div
      className={cx(
        classes.row,
        !hoverDisabled && classes.hoverRow,
        !hoverDisabled && row.isJunk && classes.junkRow
      )}
      style={style}
    >
      {!!row.cin && (
        <Box
          sx={{
            position: "absolute",
            top: -1,
            left: -28,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 26,
            backgroundColor: "#7B6CFA50",
            border: 1,
            borderRight: "none",
            borderColor: "#7B6CFA",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
            height: "calc(100% + 1px)",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: "white",
            }}
          >
            {row.cin}
          </Typography>
        </Box>
      )}
      <Typography className={classes.rowLevel}>{row.level}</Typography>
      <div className={classes.columnMenuContainer}>
        <IconButton
          className={classes.columnMenu}
          size="small"
          onClick={() => {
            setIsRowDialogOpen(true);
          }}
          color="primary"
          style={{}}
        >
          <SettingsIcon
            style={{
              fontSize: "16px",
            }}
          />
        </IconButton>
      </div>
    </div>
    {isRowDialogOpen && (
      <MagicRowDialog
        row={row}
        onConfirm={(newRow) => {
          setIsRowDialogOpen(false);
          onChangeRow(newRow);
        }}
        onCancel={() => setIsRowDialogOpen(false)}
      />
    )}
  </>;
};

const MagicRows = ({
  bounds,
  rows,
  onChangeRow,
  hoverDisabled,
}: IMagicRowsProps) => {
  return (
    <>
      {rows.map((row, index) => (
        <MagicRow
          key={row.top}
          row={row}
          style={{
            top: row.top * bounds.height,
            height: ((rows[index + 1]?.top || 1) - row.top) * bounds.height,
          }}
          onChangeRow={(newRow: GridRow) => onChangeRow(index, newRow)}
          hoverDisabled={hoverDisabled}
        />
      ))}
    </>
  );
};

export default MagicRows;
