import React, { FC } from "react";
import { FormAutocomplete } from "@periplus/ui-library";
import FormField from "../components/FormField";
import { AddressType } from "domain/address/types";
import { useTranslation } from "react-i18next";
import { FormFieldProps } from "../components/FormField/FormField";
import useSearchAddress from "domain/address/useSearchAddress";
import { useDebounceCallback } from "@react-hook/debounce";
import { useFormikContext } from "formik";
import { DeclarationForm } from "../Declaration";

interface FormAddressFieldProps
  extends Omit<FormFieldProps, "name" | "renderInput"> {
  addressType: AddressType;
  disableClearable?: boolean;
}

const FormAddressField: FC<FormAddressFieldProps> = ({
  addressType,
  label,
  disableClearable,
  ...rest
}) => {
  const { t } = useTranslation();
  const { values: formValues, setFieldValue } =
    useFormikContext<DeclarationForm>();

  const name = `addresses[${addressType}].address`;

  const [
    searchAddress,
    {
      data: { addresses },
    },
  ] = useSearchAddress();

  const debouncedSearch = useDebounceCallback((search: string) => {
    if (search) {
      searchAddress({
        variables: {
          search: search.trim(),
        },
      });
    }
  }, 100);

  return (
    <FormField
      {...rest}
      name={name}
      label={`${label}:`}
      sx={{
        mt: 1,
      }}
      renderInput={({ inputRef, ...rest }) => (
        <FormAutocomplete
          {...rest}
          options={addresses}
          onChange={(e, newValue) => {
            if (addressType === formValues.vat_account_address_type) {
              setFieldValue(
                "customs_account_number",
                newValue?.customs_account_number
              );
            }
            if (addressType === formValues.vat_account_address_type) {
              setFieldValue("vat_account_number", newValue?.vat_account_number);
            }
          }}
          getOptionLabel={(option) =>
            [
              option.company_name,
              `${option.country} ${option.city} ${option.street} ${option.zipcode}`,
              option.reference,
            ]
              .filter((el) => el)
              .join("\n")
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option) => (
            <li {...props}>
              <div>
                <div>{option.company_name}</div>
                <div>
                  {option.country} {option.city} {option.street}{" "}
                  {option.zipcode}
                </div>
                {!!option.reference && <div>{option.reference}</div>}
              </div>
            </li>
          )}
          filterOptions={(x) => x}
          autoComplete
          includeInputInList
          filterSelectedOptions
          onInputChange={(event, newInputValue) => {
            debouncedSearch(newInputValue);
          }}
          noOptionsText={t("common:Type for search...")}
          fullWidth
          disableClearable={disableClearable}
          InputProps={{
            inputRef,
            multiline: true,
            label: t("Address"),
          }}
        />
      )}
    />
  );
};

export default FormAddressField;
