import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";

export type OrgId = {
  org_id: number;
  display_name: string;
  org_id_description?: string;
  tenant_id?: string;
  addresses_desc?: string;
  org_ids_desc?: string;
  org_params?: {
    org_id: number;
    file_ids?: string[];
    addresses?: string;
    search?: string;
    org_ids?: number[];
  };
};

export const GET_ORGIDS = gql`
  query getOrgIds($tenantId: uuid) {
    org_id(
      where: {
        _or: [{ tenant_id: { _eq: $tenantId } }, { org_id: { _lt: 0 } }]
      }
    ) {
      org_id
      org_id_description
      tenant_id
    }
  }
`;

const useGetOrgIds = (tenantId?: string) => {
  const response = useQuery<{ org_id: OrgId[] }>(GET_ORGIDS, {
    variables: {
      tenantId,
    },
  });

  const result = useMemo(() => {
    const { data, ...rest } = response;
    return {
      data: data?.org_id,
      ...rest,
    };
  }, [response]);

  return result;
};

export default useGetOrgIds;
