import React, { FC } from "react";
import { useLayoutContext, Theme, Box, Grid } from "@periplus/ui-library";
import NavigationGroup from "./NavigationGroup";
import { NavigationGroup as NavigationGroupType } from "../Layout";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    whiteSpace: "nowrap",
    flexGrow: 1,
    flexWrap: "nowrap",
    height: "100%",
  },
  listItem: {
    padding: `${theme.spacing(0.5)} 24px`,
    color: theme.palette.grey[600],
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  link: {
    "&:hover *": {
      color: "#221ECD",
      fill: "#221ECD",
    },
  },
  activeLink: {
    "& *": {
      color: "#221ECD",
      fill: "#221ECD",
    },
  },
  actionsContainer: {
    width: "100%",
    overflow: "auto",
  },
}));

interface SidebarContentProps {
  navigation: NavigationGroupType[];
  renderActions?: (
    isSidebarOpen: boolean
  ) => React.ComponentType<any> | JSX.Element;
}

const SidebarContent: FC<SidebarContentProps> = ({
  navigation,
  renderActions,
}) => {
  const { classes } = useStyles();

  const { layoutState } = useLayoutContext();

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.root}
    >
      <Box width="100%" style={{ marginBottom: 16 }}>
        {navigation.map(
          ({ groupTitle, items, Icon, redirecting, indicator }, index) => (
            <NavigationGroup
              key={index}
              groupTitle={groupTitle}
              items={items}
              Icon={Icon}
              redirecting={redirecting}
              indicator={indicator}
            />
          )
        )}
      </Box>
      {renderActions && (
        <Box className={classes.actionsContainer}>
          {renderActions(layoutState.sidebarOpen)}
        </Box>
      )}
    </Grid>
  );
};

export default SidebarContent;
