import { useMemo } from "react";
import gql from "graphql-tag";
import {
  AditGetModelRetrainsQuery,
  useAditGetModelRetrainsQuery,
} from "graphql/generated";

export type ModelRetrainEntity =
  AditGetModelRetrainsQuery["model_retrains"][number];

gql`
  query AditGetModelRetrains {
    model_retrains: model_retrain {
      id
      request_time
      status
      start_time
      end_time
      error_message
    }
  }
`;

export default (
  baseOptions?: Parameters<typeof useAditGetModelRetrainsQuery>[0]
) => {
  const result = useAditGetModelRetrainsQuery({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    ...baseOptions,
  });

  return useMemo(
    () => ({
      ...result,
      data: {
        model_retrains: result.data?.model_retrains ?? [],
      },
    }),
    [result]
  );
};
