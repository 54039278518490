import { useCallback, useMemo } from "react";
import { gql, useMutation } from "@apollo/client";

import useErrorHandling from "hooks/useErrorHandling";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export default () => {
  const withErrorHandling = useErrorHandling();
  const [mutation, options] = useMutation(gql`
    mutation AditUpdateDeclarationAiConfirmed(
      $file_id: uuid!
      $dr_no: smallint!
      $ai_confirmed: Boolean!
    ) {
      update_declaration_by_pk(
        pk_columns: { file_id: $file_id, dr_no: $dr_no }
        _set: { ai_confirmed: $ai_confirmed }
      ) {
        file_id
        dr_no
      }
    }
  `);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const mutationWithErrorHandling = useCallback(
    async (file_id: string, dr_no: number, ai_confirmed: boolean) => {
      await withErrorHandling(mutation, {
        variables: { file_id, dr_no, ai_confirmed },
      });
      enqueueSnackbar(t("Saved"), { variant: "success" });
    },
    [mutation, withErrorHandling, t]
  );

  return useMemo(
    () => [mutationWithErrorHandling, options] as const,
    [mutationWithErrorHandling, options]
  );
};
