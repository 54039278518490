import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";

export type FileStat = {
  file_flags: string;
  file_flags_id: number;
  qty: number;
  users_qty: number;
};

type QueryResult = {
  pp_file_stats: FileStat[];
};

const idToStringMap = ["file", "journey", "folder"];

export const GET_FILE_STATS = gql`
  query getFileStats(
    $started: timestamptz
    $ended: timestamptz
    $userIds: _uuid
    $orgIds: _int2
  ) {
    pp_file_stats(
      args: {
        _started: $started
        _ended: $ended
        _orgids: $orgIds
        _crusers: $userIds
      }
    ) {
      file_flags
      file_flags_id
      qty
      users_qty
    }
  }
`;

interface Variables {
  startDate?: string;
  endDate?: string;
  userIds?: string[];
  orgIds?: string[];
}

const useGetFileStats = ({
  startDate,
  endDate,
  userIds,
  orgIds,
}: Variables): any => {
  const variables = useMemo(
    () => ({
      ...(startDate
        ? { started: startDate }
        : { started: dayjs(0).utc().format() }),
      ...(endDate ? { ended: endDate } : { ended: dayjs().utc().format() }),
      userIds: userIds?.length ? `{${userIds?.join(",")}}` : undefined,
      orgIds: orgIds?.length ? `{${orgIds?.join(",")}}` : undefined,
    }),
    [endDate, orgIds, startDate, userIds]
  );

  const queryResult = useQuery<QueryResult>(GET_FILE_STATS, {
    variables,
    fetchPolicy: "network-only",
  });

  const preparedResult = useMemo(() => {
    const { data } = queryResult;
    if (!data) {
      return {
        ...queryResult,
        data: {
          file: 0,
          journey: 0,
          folder: 0,
          activeUsers: 0,
        },
      };
    }

    const formedData = data.pp_file_stats.reduce(
      (acc, { file_flags_id, users_qty, qty }) => {
        const statTypeString = idToStringMap[file_flags_id];
        // @ts-ignore
        acc[statTypeString] = qty;
        acc.activeUsers += file_flags_id < 2 ? users_qty : 0;
        return acc;
      },
      {
        file: 0,
        journey: 0,
        folder: 0,
        activeUsers: 0,
      }
    );
    return { ...queryResult, data: formedData };
  }, [queryResult]);

  return preparedResult;
};

export default useGetFileStats;
