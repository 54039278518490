import React, { FC, useMemo, useState, useEffect } from "react";
import {
  PageContainer,
  Columns,
  Rows,
  Table,
  Search,
  Button,
  Tooltip,
  Theme,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
} from "@periplus/ui-library";
import dayjs from "dayjs";
import { useAuth } from "contexts/AuthContext";
import usePageLocalStorage, {
  ColumnsSettings,
} from "hooks/usePageLocalStorage";
import useRossumValidatedDocId from "hooks/useRossumValidatedDocId";
import useUrlSearchParams from "hooks/useUrlSearchParams";
import { Sorted } from "graphql/helpers";
import { useTranslation } from "react-i18next";
import { File, FileFlag } from "domain/file/types";
import {
  DescriptionOutlined,
  FolderOpenOutlined,
  Folder,
} from "@mui/icons-material";
import CopyText from "components/CopyText";
import WaitText from "components/WaitText";
import config from "config";
import Actions from "./Actions";
import useGetFiles from "domain/file/useGetFiles";
import FilterMenu from "./FilterMenu";
import PDFPreview from "./PDFPreview";
import FileStatus from "domain/file/components/FileStatus";
import { useHistory } from "react-router-dom";
import DocumentsSubTable from "domain/document/components/DocumentsSubTable";
import { makeStyles } from "tss-react/mui";

interface FinishedProps {}
export interface Filters {
  startDate?: string;
  endDate?: string;
  files?: boolean;
  journey?: boolean;
  myFilesOnly?: boolean;
  status?: string[];
  orgIds?: number[];
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  controlsContainer: {
    display: "flex",
    gap: theme.spacing(),
  },
  actionsColumn: {
    width: "0%",
    textAlign: "end",
  },
}));

const { prefixMRSNumber } = config;

const Finished: FC<FinishedProps> = () => {
  const { tenantConfig } = useAuth();
  const { t } = useTranslation("classify");
  const { classes } = useStyles();
  const history = useHistory();

  const defaultFilters = useMemo(
    () => ({
      startDate: undefined,
      endDate: undefined,
      ...(tenantConfig?.UseJourneys?.value && {
        files: true,
        journey: true,
      }),
      myFilesOnly: false,
      status: [],
      orgIds: [],
    }),
    [tenantConfig?.UseJourneys?.value]
  );

  const { pageLocalStorage, setPageLocalStorage } = usePageLocalStorage<{
    itemsPerPage: number;
    filters: Filters;
    columnsSettings?: ColumnsSettings;
  }>({
    itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
    filters: {
      ...defaultFilters,
    },
  });

  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams<
    {
      page: number;
      itemsPerPage: number;
      search?: string;
      sorted?: Sorted;
    } & Filters
  >({
    page: 1,
    itemsPerPage: pageLocalStorage.itemsPerPage,
    ...defaultFilters,
    ...pageLocalStorage.filters,
  });

  useRossumValidatedDocId();

  useEffect(() => {
    setPageLocalStorage({
      filters: {
        startDate: urlSearchParams.startDate,
        endDate: urlSearchParams.endDate,
        files: urlSearchParams.files,
        journey: urlSearchParams.journey,
        myFilesOnly: urlSearchParams.myFilesOnly,
        status: urlSearchParams.status,
        orgIds: urlSearchParams.orgIds,
      },
    });
  }, [urlSearchParams]);

  const {
    data: { files, isLastPage },
    loading,
    refetch,
  } = useGetFiles({
    ...urlSearchParams,
    startDate:
      urlSearchParams.startDate ||
      dayjs().subtract(2, "week").startOf("day").format(),
    endDate: urlSearchParams.endDate || dayjs().endOf("day").format(),
  });

  const [columns, setColumns] = useState({});

  useEffect(() => {
    setColumns(
      Object.entries({
        file_flags: {
          header: t("type"),
          cell: (row) => {
            const icon =
              row.data.file_flags === FileFlag.file ||
              row.data.file_flags === FileFlag.IS_DECLARATION ? (
                <DescriptionOutlined />
              ) : (
                <FolderOpenOutlined />
              );
            return (
              <Tooltip
                title={
                  t(
                    `${
                      row.data.file_flags === FileFlag.file ||
                      row.data.file_flags === FileFlag.IS_DECLARATION
                        ? FileFlag[FileFlag.file]
                        : FileFlag[row.data.file_flags]
                    }`
                  ) as string
                }
              >
                {icon}
              </Tooltip>
            );
          },
        },
        identification: {
          header: t("file"),
          cell: (row) => {
            const displayName =
              row.data.file_flags === 2
                ? row.data.name
                : row.data.identification;

            const link = row.data.identification
              ? `${window.location.host}/select/preview/${row.data.identification}`
              : undefined;
            return (
              <CopyText textToCopy={displayName} linkToCopy={link}>
                {displayName || <WaitText>{prefixMRSNumber}-</WaitText>}
              </CopyText>
            );
          },
        },
        org_id_description: {
          header: t("common:orgId"),
        },
        creation_date: {
          header: t("common:creationDate"),
          cell: (row) =>
            dayjs(row.data.creation_date).format("DD.MM.YYYY HH:mm:ss"),
        },
        last_modified_date: {
          header: t("common:lastUpdate"),
          cell: (row) =>
            row.data.last_modified_date &&
            dayjs(row.data.last_modified_date).format("DD.MM.YYYY HH:mm:ss"),
        },
        file_status: {
          header: t("common:status"),
          cell: (row) => (
            <div title={"Status: " + row.data.file_status}>
              <FileStatus file_status={row.data.file_status} />
            </div>
          ),
        },
        actions: {
          actionsColumn: true,
          sortable: false,
          customizable: false,
          headerClassName: classes.actionsColumn,
          cell: (row) => <Actions file={row.data} />,
        },
      } as Columns<File>).reduce((acc, [id, column]) => {
        acc[id] = {
          ...column,
          sort: urlSearchParams.sorted?.[id],
          ...pageLocalStorage.columnsSettings?.[id],
        };
        return acc;
      }, {} as Columns<File>)
    );
  }, [t]);

  const [rows, setRows] = useState<Rows<File>>({});

  useEffect(() => {
    setRows((prev) => ({
      ...Object.entries(prev).reduce((acc, [id, row]) => {
        acc[id] = {
          ...row,
          selected: false,
          activePos: undefined,
        };
        return acc;
      }, {} as Rows<File>),
      ...files.reduce((acc, el, i) => {
        acc[el.id] = {
          ...prev[el.id],
          data: el,
          activePos: i,
          selected: i === 0,
        };
        return acc;
      }, {} as Rows<File>),
    }));
  }, [files]);

  const filters = useMemo(() => {
    const { startDate, endDate, files, journey, myFilesOnly, status, orgIds } =
      urlSearchParams;
    return {
      startDate,
      endDate,
      files,
      journey,
      myFilesOnly,
      status,
      orgIds,
    };
  }, [urlSearchParams]);

  const [selected] = useMemo(
    () => [Object.values(rows).find((row) => row.selected)?.data],
    [rows]
  );

  return (
    <PageContainer title={t("navigation:files-voyages")}>
      <PDFPreview file={selected} />
      <Table<File>
        sx={(theme) => ({
          mt: 2,
          "& .LuiPanel-content": {
            [theme.breakpoints.up("sm")]: {
              maxHeight: "calc(100vh - 478px)",
            },
          },
        })}
        rows={rows}
        columns={columns}
        dataIdentifier="id"
        loading={loading}
        isLastPage={isLastPage}
        page={urlSearchParams.page}
        itemsPerPage={urlSearchParams.itemsPerPage}
        onChangePage={(newPage) => {
          setUrlSearchParams({ page: newPage });
        }}
        onChangeItemsPerPage={(newItemsPerPage) => {
          setPageLocalStorage({
            itemsPerPage: newItemsPerPage,
          });
          setUrlSearchParams({
            itemsPerPage: newItemsPerPage,
            page: 1,
          });
        }}
        onRefresh={() => refetch()}
        onSort={(newColumns) => {
          setColumns(newColumns);

          const newSorted = Object.entries(newColumns).reduce(
            (acc, [id, column]) => {
              if (column.sort)
                acc[id] = {
                  ...column.sort,
                };
              return acc;
            },
            {} as Sorted
          );
          setUrlSearchParams({
            sorted: Object.keys(newSorted).length ? newSorted : undefined,
            page: 1,
          });
        }}
        onSelect={(newRows, doubleClick) => {
          if (doubleClick) {
            const [, newSelectedRow] =
              Object.entries(newRows).find(([, row]) => row.selected) || [];
            if (
              newSelectedRow?.data.identification &&
              newSelectedRow.data.file_status.includes("archive")
            ) {
              history.push(
                `/select/preview/${newSelectedRow?.data.identification}`
              );
            }
          } else {
            setRows(newRows);
          }
        }}
        onColumnsSettingsChange={(newColumns) => {
          setColumns(newColumns);
          setPageLocalStorage({
            columnsSettings: Object.entries(newColumns).reduce(
              (acc, [id, column]) => {
                acc[id] = {
                  pos: column.pos as number,
                  visible: column.visible,
                };
                return acc;
              },
              {} as ColumnsSettings
            ),
          });
        }}
        filterMenu={
          <FilterMenu
            filters={filters}
            onChange={(newFilters) => {
              setUrlSearchParams({ ...newFilters, page: 1 });
              setPageLocalStorage({
                filters: newFilters,
              });
            }}
            onClear={() => {
              setUrlSearchParams({ ...defaultFilters, page: 1 });
              setPageLocalStorage({
                filters: defaultFilters,
              });
            }}
          />
        }
        controls={
          <div className={classes.controlsContainer}>
            <Search
              value={urlSearchParams.search}
              onChange={(newSearch) =>
                setUrlSearchParams({ search: newSearch, page: 1 })
              }
            />
            <Button
              variant="outlined"
              color="primary"
              minimizeForMobiles
              disabled={
                !(
                  selected?.identification &&
                  selected?.file_status.includes("archive")
                )
              }
              endIcon={<Folder />}
              onClick={() =>
                history.push(`/select/preview/${selected?.identification}`)
              }
            >
              {t("navigation:read-only")}
            </Button>
          </div>
        }
        renderExpandedRow={(row) => <DocumentsSubTable fileId={row.data.id} />}
      />
    </PageContainer>
  );
};

export default Finished;
