import { gql } from "@apollo/client";
import { useQuery, QueryHookOptions } from "@apollo/client";
import { User } from "./useGetUsers";
import { File } from "./useGetFiles";

export interface TaskType {
  id: number;
  display_name: string;
}

export interface IDeclarationTaskBase {
  id: number;
  title: string;
  assign_to: string;
  task_type_id: number;
  creation_date: string;
  object_id: string;
  task_status_id: number;
  due_date: string;
  meta: string;
}

export interface IDeclarationTask extends IDeclarationTaskBase {
  assigned_user?: User;
  task_type: TaskType;
  document?: {
    id: string;
    documentFile?: Pick<File, "id" | "identification">;
  };
  file?: Pick<File, "id" | "identification">;
}

interface IData {
  declaration_task: IDeclarationTask[];
  declaration_task_aggregate_open: {
    aggregate: {
      count: number;
    };
  };
  declaration_task_aggregate_finished: {
    aggregate: {
      count: number;
    };
  };
}

export interface IVariables {
  assigned_to?: string;
  min_due_date?: string;
  max_due_date?: string;
  task_type_id?: number;
  creation_date?: string;
  task_status_id?: number;
  search?: string;
  limit?: number;
  offset?: number;
}

export const ADIT_GET_DECLARATION_TASKS_V2 = gql`
  query ADIT_GET_DECLARATION_TASKS_V2(
    $assigned_to: uuid
    $min_due_date: timestamptz
    $max_due_date: timestamptz
    $task_type_id: Int
    $creation_date: timestamptz
    $search: String
    $limit: Int = 6
    $offset: Int = 0
    $task_status_id: Int = 0
    $orderby: String
  ) {
    declaration_task: pp_declaration_tasks(
      args: {
        _assigned_to: $assigned_to
        _min_due_date: $min_due_date
        _max_due_date: $max_due_date
        _task_type_id: $task_type_id
        _creation_date: $creation_date
        _search: $search
        _task_status_id: $task_status_id
        _limit: $limit
        _offset: $offset
        _orderby: $orderby
      }
    ) {
      id
      title
      assigned_to
      assigned_user {
        id
        display_name
        __typename
      }
      creation_date
      task_type_id
      due_date
      meta
      object_id
      task_status_id
      task_type {
        display_name
        __typename
      }
      document {
        document_flags
        documentFile {
          id
          identification
          __typename
        }
        documentType {
          translations
          __typename
        }
        __typename
      }
      file {
        id
        identification
        __typename
      }
      rn
      qty
    }
  }
`;

export const useGetDeclarationTasks = (
  options: QueryHookOptions<IData, IVariables>
) => {
  const { data, loading, error, refetch } = useQuery<IData, IVariables>(
    ADIT_GET_DECLARATION_TASKS_V2,
    options
  );
  return {
    data: data?.declaration_task,
    openCount: data?.declaration_task_aggregate_open?.aggregate?.count,
    finishedCount: data?.declaration_task_aggregate_finished?.aggregate?.count,
    loading,
    error,
    refetch,
  };
};
