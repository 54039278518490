import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { useMemo } from "react";
import { User } from "./useGetUsers";

const GET_TENANTS = gql`
  query ADIT_GET_TENANTS(
    $tenantId: uuid
    $limit: Int
    $offset: Int
    $search: String
  ) {
    pp_tenants(
      args: {
        _tenant_id: $tenantId
        _search: $search
        _limit: $limit
        _offset: $offset
        _orderby: "tenant_name asc"
      }
    ) {
      tenant_name
      id
      address_id
      address {
        country
        city
        street
        street_number
        zipcode
        address_type
      }
      creation_date
      last_modified_user
      last_modified_date
      creation_user
      creationUser {
        display_name
      }
      lastModifiedUser {
        display_name
        user_type
      }
      trader_identification_number
      qty
    }
  }
`;

export type Tenant = {
  tenant_name: string;
  id: string;
  creation_date: string;
  last_modified_user: string;
  last_modified_date: string;
  creation_user: string;
  creationUser: Pick<User, "display_name" | "user_type">;
  lastModifiedUser: Pick<User, "display_name" | "user_type">;
  address_id: number;
  trader_identification_number?: string;
  address: {
    id: number;
    country: string;
    city: string;
    street: string;
    street_number: string;
    zipcode: string;
    vat_number: string;
    address_type: any;
  };
};

const useGetTenants = (variables: {
  tenantId?: string;
  limit?: number;
  offset?: number;
  search?: string;
}) => {
  const { data, ...rest } = useQuery<{
    pp_tenants: Tenant[];
  }>(GET_TENANTS, {
    variables,
    fetchPolicy: "no-cache",
  });

  const { tenants, tenantsTotalCount } = useMemo(() => {
    const tenants = data?.pp_tenants || [];

    return {
      tenants,
      tenantsTotalCount: (tenants[0] as any)?.qty || 0,
    };
  }, [data]);

  return {
    tenants,
    tenantsTotalCount,
    ...rest,
  };
};

export default useGetTenants;
