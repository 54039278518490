import React, {
  useState,
  SyntheticEvent,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Theme } from "@periplus/ui-library";
import AutoSizer from "react-virtualized-auto-sizer";
import ReactResizeDetector from "react-resize-detector";
import PdfViewer from "components/PdfViewer";
import DataPointPage from "./DataPointPage";
import {
  DataPointDocumentViewerRef,
  IDataPointDocumentViewerProps,
} from "./types";
import { FixedSizeList } from "react-window";
import { useSas } from "contexts/SasContext";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    pageContainer: {
      position: "relative",
      userSelect: "none",
      border: "1px solid #ccc",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      overflow: "hidden",
    },

    pageImage: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },

    pdfViewer: {
      willChange: "auto !important",
    }
  }));

const DataPointDocumentViewer = forwardRef<
  DataPointDocumentViewerRef | undefined,
  IDataPointDocumentViewerProps
>(
  (
    {
      documentPages,
      onCreateDataPoint,
      grids,
      magnifierToggle,
      drawer,
      ...rest
    },
    ref
  ) => {
    const { classes } = useStyles();
    const [pageSize, setPageSize] = useState([0, 0]);
    const [pageWidth, pageHeight] = pageSize;
    const [originalSize, setOriginalSize] = useState([0, 0]);
    const pdfRef = useRef();
    const imgRef = useRef<HTMLImageElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        scrollToDataPoint: (dataPointId?: string) => {
          const pdfViewer = pdfRef.current;
          if (!pdfViewer || !dataPointId) return;
          const activePage = parseInt(dataPointId.split("-")[0]);
          if (
            !documentPages[activePage] ||
            !documentPages[activePage].pageExtracts
          )
            return;
          const activeDataPoint = documentPages[
            activePage
          ].pageExtracts[0]?.dataPoints?.find((dp) => dp.id === dataPointId);
          if (!activeDataPoint) return;
          const offset =
            pageHeight * activePage +
            (activeDataPoint.coordinates[1] * pageHeight * 0.5) /
              originalSize[1];
          (pdfViewer as FixedSizeList).scrollTo(offset);
        },
      }),
      [documentPages, originalSize, pageHeight]
    );

    //const test = useGetDownloadUrlNew("page.thumbnailBigUrl");
    const { getDownloadUrl } = useSas();

    const pages = documentPages.map((page) => ({
      ...page,
      thumbnailBigUrl: getDownloadUrl(page.thumbnailBigUrl),
    }));

    const firstPageNonRotate = pages.findIndex((page) => page.rotate === 0);
    const pageIndexToDetectSize =
      firstPageNonRotate === -1 ? 0 : firstPageNonRotate;

    const drawerSize = drawer ? 64 : 0;

    const onImageLoad = (event: SyntheticEvent<HTMLImageElement>) => {
      const { naturalWidth, naturalHeight, width, height } =
        event.target as HTMLImageElement;
      setPageSize([width, height]);
      setOriginalSize([naturalWidth, naturalHeight]);
    };

    const onPageResize = () => {
      const pageImage = imgRef.current;
      if (!pageImage) return;
      setPageSize([pageImage.width, pageImage.height]);
    };

    const renderPage = ({ itemData, index }: any) => {
      const [origWidth, origHeight] = originalSize;
      const [width, height] = pageSize;
      if (index === pageIndexToDetectSize) {
        return (
          <>
            <ReactResizeDetector
              handleWidth
              handleHeight
              onResize={onPageResize}
              refreshRate={500}
              refreshMode="throttle"
            />
            <DataPointPage
              ref={imgRef}
              index={index}
              size={{ width, height }}
              originalSize={{ width: origWidth, height: origHeight }}
              data={itemData}
              onImageLoad={onImageLoad}
              toggleMagnifier={magnifierToggle}
              {...rest}
            />
          </>
        );
      }

      return (
        <DataPointPage
          index={index}
          size={{ width: pageWidth, height: pageHeight }}
          originalSize={{ width: origWidth, height: origHeight }}
          data={itemData}
          toggleMagnifier={magnifierToggle}
          {...rest}
        />
      );
    };

    return (
      <AutoSizer>
        {({ width, height }) => (
          <PdfViewer
            withoutAutoSizer
            pages={pages}
            width={width}
            height={height - drawerSize}
            className={classes.pdfViewer}
            ref={pdfRef}
          >
            {renderPage}
          </PdfViewer>
        )}
      </AutoSizer>
    );
  }
);

export default React.memo(DataPointDocumentViewer);
