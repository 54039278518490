import React, { FC, useCallback, ReactNode, useState, useMemo } from "react";
import {
  Search,
  ThreeDotsMenu,
  ActionBar,
  ActionBarItem,
  Grid,
  Box,
  Tooltip,
  ListItemIcon,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { withRouter, RouteComponentProps } from "react-router-dom";
import dayjs from "dayjs";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";

import config from "config";

import { useGetDownloadUrl } from "utils/azureBlobStorageUtils";
import CopyText from "components/CopyText";
import { TextNaked, Text } from "components/Text";
import Table from "components/Table/Table";
import PDFPreview from "components/PDFPreview/PDFPreview";
import { File } from "graphql/hooks/useGetFilesSubscription";
import Dot from "components/Dot";
import WaitText from "components/WaitText";
import { SelectedDocumentType } from "pages/Select/types";
import useGetFilePages from "graphql/hooks/useGetFilePages";
import useUrlSearchParams from "hooks/useUrlSearchParams";
import { useMutation } from "@apollo/client";
import { SCHEDULE_FILE } from "graphql/mutations/file";
import AddIcon from "@mui/icons-material/Add";
import GetAppIcon from "@mui/icons-material/GetApp";
import useErrorHandling from "hooks/useErrorHandling";
import FilterMenu from "./FilterMenu";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() =>
  ({
    link: {
      textDecoration: "none",
      width: "100%",
      display: "flex",
      color: "#323232",
      "&:hover": {
        textDecoration: "none",
      },
    },

    listIcon: {
      minWidth: 28,
    }
  }));
const headerTranslation = (
  type: string,
  value: string,
  size: number = 16,
  weight: number = 400
) => {
  return (
    <TextNaked
      style={{ fontWeight: weight, fontSize: size, userSelect: "none" }}
    >
      {type}:{value}
    </TextNaked>
  );
};

const { prefixMRSNumber } = config;

export const statusDisplay = (
  value: string,
  adoptTextColor: boolean = false
) => {
  const getColorProp = (color: "green" | "red" | "orange" | "blue") =>
    adoptTextColor ? { color, adoptTextColor } : { bgColor: color };

  if (["annotated"].includes(value)) {
    return (
      <Dot {...getColorProp("green")}>
        <Text variant="caption">Annotated</Text>
      </Dot>
    );
  } else if (
    [
      "identification_requested",
      "transmission_pending",
      "transmission_generated",
      "ready_for_archive",
      "archived_pending",
      "archived",
      "pending",
    ].includes(value)
  ) {
    return (
      <Dot {...getColorProp("blue")}>
        <Text variant="caption">Ready for annotation</Text>
      </Dot>
    );
  } else {
    return (
      <Dot {...getColorProp("red")}>
        <Text variant="caption">failed</Text>
      </Dot>
    );
  }
};

interface IFilesTableProps extends RouteComponentProps {
  files: File[];
  itemCount?: number;
  loadMore?: (startIndex: number, stopIndex: number) => void;
  sort?: (orderBy: { key: string; direction: "ASC" | "DESC" }[]) => void;
  loading?: boolean;
  itemsPerPage: number;
  renderExpandRowView?: (row: any) => ReactNode;
  toggleSelect: (args: { selecting?: any[]; deselecting?: any[] }) => void;
  selectedDocuments: SelectedDocumentType[];
  isUserAllowedToAutoClassification: boolean;
}

type Close = () => void;

// Remove this component and re-use DocumentsTable component
const FilesTable: FC<IFilesTableProps> = ({
  files,
  itemCount,
  loadMore,
  sort,
  loading: filesLoading,
  history,
  itemsPerPage,
  renderExpandRowView,
  selectedDocuments,
  toggleSelect,
  isUserAllowedToAutoClassification,
}) => {
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [firstFile] = files;
  const fileToShow: any =
    selectedDocuments[selectedDocuments.length - 1] || firstFile;
  const { classes } = useStyles();

  const [scheduleFile] = useMutation(SCHEDULE_FILE);
  const withErrorHandling = useErrorHandling();
  const { t } = useTranslation();
  const { data, loading } = useGetFilePages(fileToShow && fileToShow.id);
  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams();

  const onDoubleClick = ({
    identification = 0,
    status = "",
  }: {
    identification: number;
    status: string;
  }) => {
    const isCorrectStatus = status.includes("archive");
    if (identification && isCorrectStatus) {
      history.push(`/select/preview/${identification}`);
    }
  };

  const handleChangeItemsPerPage = (items: number) => {
    localStorage.setItem("finished", items.toString());
  };

  const renderPreview = useCallback(() => {
    const { displayName, creationDate, status, org_id_description } =
      fileToShow || {
        displayName: "File name",
        creationDate: "",
        status: undefined,
        org_id_description: "",
      };

    const pages = (data || []).reduce(
      // @ts-ignore
      (acc, doc) => {
        const allPages = doc.pages.reduce(
          // @ts-ignore
          (pageAggr, page) => pageAggr.concat(page),
          []
        );
        return acc.concat(allPages);
      },
      []
    );

    return (
      <PDFPreview
        finish
        file={{
          name: displayName,
          creationDate,
          org_id_description,
          pages,
          extension: "",
          status: statusDisplay(status || "archived", true),
        }}
        placeholder={
          pages.length === 0 &&
          headerTranslation("select", "no_custom_relevant")
        }
        loading={loading}
      />
    );
  }, [data, fileToShow, loading]);

  const onToggle = useCallback(
    ({ selecting, deselecting }: { selecting: any[]; deselecting: any[] }) => {
      toggleSelect({
        selecting,
        deselecting: [...deselecting, ...selectedDocuments],
      });
    },
    [selectedDocuments, toggleSelect]
  );

  const onExpand = useCallback(
    ({ id }: { id?: string }) => {
      if (!id) {
        setExpandedRows({});
        return;
      }

      setExpandedRows({
        ...expandedRows,
        [id]: !expandedRows[id],
      });
    },
    [expandedRows]
  );

  const columnDefs = useMemo(
    () => [
      {
        Header: () => headerTranslation("classify", "type"),
        accessor: "type",
        Cell: ({ cell }: { cell: any }) => {
          const { typeName } = cell.row.original;
          const tooltipText = headerTranslation("classify", typeName, 10, 500);
          const icon =
            typeName === "file" ? (
              <DescriptionOutlinedIcon />
            ) : (
              <FolderOpenOutlinedIcon />
            );
          return (
            <Box style={{ display: "flex" }}>
              <Tooltip title={tooltipText}>{icon}</Tooltip>
            </Box>
          );
        },
      },
      {
        Header: () => headerTranslation("classify", "file"),
        accessor: "identification",
        Cell: ({ cell }: { cell: any }) => {
          const { displayName } = cell.row.original;

          const text = cell.value;
          const link = text
            ? `${window.location.host}/select/preview/${text}`
            : undefined;
          return (
            <CopyText
              textToCopy={displayName}
              linkToCopy={link}
              style={{ minWidth: "145px" }}
            >
              {displayName || <WaitText>{prefixMRSNumber}-</WaitText>}
            </CopyText>
          );
        },
      },
      {
        Header: () => headerTranslation("common", "orgId"),
        accessor: "org_id_description",
      },
      {
        Header: () => headerTranslation("common", "creationDate"),
        accessor: "creationDate",
        Cell: ({ cell }: { cell: { value: string } }) =>
          dayjs(cell.value).format("DD.MM.YYYY HH:mm:ss"),
      },
      {
        Header: () => headerTranslation("common", "lastUpdate"),
        accessor: "lastUpdate",
        Cell: ({ cell }: { cell: { value: string } }) =>
          cell.value ? dayjs(cell.value).format("DD.MM.YYYY HH:mm:ss") : "",
      },
      {
        Header: () => headerTranslation("common", "status"),
        accessor: "status",
        Cell: ({ cell }: { cell: { value: string } }) => (
          <Box title={"Status: " + cell.value}>{statusDisplay(cell.value)}</Box>
        ),
      },
      {
        Header: () => headerTranslation("common", "actions"),
        accessor: "actions",
        disableSortBy: true,
        Cell: ({ row: { original } }: any) => {
          const { fileUrl, identification, customs_relevant, status, id } =
            original;
          const { url, loading } = useGetDownloadUrl(
            fileUrl,
            "application/pdf",
            `inline; filename="${encodeURI(identification) || "MRS-"}.pdf"`
          );

          const options = [
            // {
            //   content: (
            //     <>
            //       <ListItemIcon className={classes.listIcon}>
            //         <HistoryIcon />
            //       </ListItemIcon>
            //       <Text>common:history</Text>
            //     </>
            //   ),
            //   action: () => {
            //     history.push(`/select/history/${identification}`);
            //   },
            //   disabled: !identification,
            // },
          ] as any[];

          if (isUserAllowedToAutoClassification) {
            options.push({
              content: (
                <>
                  <ListItemIcon className={classes.listIcon}>
                    <AddIcon />
                  </ListItemIcon>
                  <Text>common:declaration</Text>
                </>
              ),
              action: () => {
                sessionStorage.setItem("create_file", identification);
                history.push(`/select/declaration`);
              },
              disabled: !identification || !customs_relevant,
            });
          }

          options.push({
            content: (
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
                onClick={(e) => e.stopPropagation()}
              >
                <ListItemIcon className={classes.listIcon}>
                  <GetAppIcon />
                </ListItemIcon>
                <Text>{headerTranslation("common", "customsDocs", 13)}</Text>
              </a>
            ),
            action: () => {},
            disabled: !!(!fileUrl || loading),
          });

          if (
            [
              "archived_failed",
              "transmission_failed",
              "identification_request_failed",
              "ready_for_archive",
            ].includes(status)
          ) {
            options.push({
              content: (
                <>
                  <Text>common:reschedule</Text>
                </>
              ),
              action: async (close: Close) => {
                await withErrorHandling(scheduleFile, {
                  variables: {
                    fileIds: [id],
                  },
                });
                close();
              },
              disabled: false,
            });
          }
          return <ThreeDotsMenu options={options} />;
        },
      },
    ],
    [
      classes.link,
      history,
      isUserAllowedToAutoClassification,
      scheduleFile,
      withErrorHandling,
    ]
  );

  return (
    <Grid item container direction="column">
      <Grid item data-pp="document-preview" style={{ width: "100%" }}>
        {renderPreview()}
      </Grid>
      <Grid item>
        <ActionBar
          justifyContent="space-between"
          style={{
            marginTop: 15,
          }}
        >
          <ActionBarItem container wrap="nowrap">
            <FilterMenu />
          </ActionBarItem>
          <ActionBarItem>
            <Search
              value={urlSearchParams.search}
              label={t("common:search") + "..."}
              onChange={(value) => setUrlSearchParams({ search: value })}
              style={{ width: 180 }}
            />
          </ActionBarItem>
        </ActionBar>
      </Grid>
      <Grid item style={{ marginTop: 15, width: "100%" }}>
        <Table
          loading={filesLoading}
          columnDefs={columnDefs}
          rowData={files}
          onToggle={onToggle}
          onExpand={onExpand}
          data-pp="files-table"
          itemCount={itemCount || files.length}
          loadMore={loadMore}
          isRowSelected={(rowData: { id: string }) => {
            return selectedDocuments.some(
              (sDocument) => sDocument.id === rowData.id
            );
          }}
          isRowExpanded={(rowData: { id: string }) => {
            return !!expandedRows[rowData.id];
          }}
          initialItemsPerPage={itemsPerPage}
          onChangeItemsPerPage={handleChangeItemsPerPage}
          selectionType="single"
          onDoubleClick={onDoubleClick}
          getSelectionCellProps={(rowData: any) => {
            const isDisabled = [
              "identification_requested",
              "transmission_pending",
              "transmission_generated",
              "pending",
            ].includes(rowData.status);

            const title = isDisabled ? { title: t("file_processing") } : {};

            return {
              ...title,
              disabled: isDisabled,
            };
          }}
          tableConfig={{
            initialState: {
              sortBy: [
                {
                  id: "status",
                  desc: true,
                },
              ],
              expanded: {},
            },
            expandSubRows: false,
          }}
          sortBy={sort}
          renderExpandRowView={renderExpandRowView}
        />
      </Grid>
    </Grid>
  );
};

export default withRouter(FilesTable);
