import React, {
  FC,
  useCallback,
  useState,
  useMemo,
  memo,
  useEffect,
} from "react";
import {
  Switch,
  PaperProps,
  Box,
  Pagination,
  Autocomplete,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { DataPoint } from "graphql/hooks/useGetValidationDocument";
import { Document } from "pages/ValidationV2/useGetValidationV2Document";
import GridMenu from "./GridMenu";
import { ExtractType } from "domain/extractType/types";
import { LanguageType } from "i18n";
import {
  DataPointDocumentViewer,
  DataPointsLayer,
  IChildrenProps,
  IRenderDataPointProps,
  IRenderGridBoxProps,
  DataPointBox,
} from "components/DataPointDocumentViewerV2";
import useDataPointsMutations from "graphql/hooks/useDataPointsMutation";
import useAppendNewDataPoint from "components/DataPointDocumentViewerV2/hooks/useAppendNewDataPoint";
import useTableQuery, { LineItem } from "graphql/hooks/useTableQuery";
import MagicGrid from "components/MagicGrid";
import { DataPointCoordinates } from "pages/ValidationV2/useGetValidationV2Document";
import { orderBy } from "lodash";
import { v4 as uuid } from "uuid";
import { removeArrayIndexesFromPath } from "utils/string";
import { get } from "lodash";
import useConfirmAI from "domain/declaration/useConfirmAI";

interface DocumentDrawerProps extends PaperProps {
  file_id: string;
  dr_no: number;
  ai_confirmed?: boolean | null;
  documents: Document[];
  pageRef: React.RefObject<HTMLDivElement>;
  onDataPointClick: (newValue: string, shiftKey: boolean) => void;
  selectedFormFieldRef: React.MutableRefObject<{
    cur: string | null;
    prev: string | null;
  }>;
  selectedTab: number;
  onCreateNewDataPoint: () => void;
}

export const formToAnnotationMap = {
  lineItem: {
    traded_goods: "",
    preference: "",
    statisticalValue: "itemTotalPrice",
    customs_net_mass: "",
    customsClearanceType: "",
    originCountry: "itemCountryOfOrigin",
    currency: "",
    invoiceValue: "",
    net_mass: "itemNetWeight",
    commodity_code: "itemHsCode",
    key: "",
    additional_pcs: "",
    gross_mass: "itemGrossWeight",
    description: "itemDescription",
    net_declaration: "",
    revision: "",
    statistical_code: "",
    packaging: {
      packagingType: "itemPackagingType",
      quantity: "itemNumberOfPackages ",
      packagingReferenceNumber: "",
    },
  },
};

const DocumentDrawer: FC<DocumentDrawerProps> = ({
  file_id,
  dr_no,
  ai_confirmed,
  documents,
  pageRef,
  onDataPointClick,
  selectedFormFieldRef,
  selectedTab,
  onCreateNewDataPoint,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const systemLanguage = language as LanguageType;
  const [isMagnifierOn, setIsMagnifierOn] = useState(false);

  const { newDataPoint, setNewDataPoint } = useAppendNewDataPoint();
  const [prevSelectedDataPointType, setPrevSelectedDataPointType] = useState<{
    annotation?: string;
    lineItem?: string;
  }>({});

  const [selectedDocument, setSelectedDocument] = useState<Document>(
    documents[0]
  );
  useEffect(() => {
    setSelectedDocument((prev) => documents.find((el) => el.id === prev.id)!);
  }, [documents]);
  const [selectedExtractType, setSelectedExtractType] = useState<ExtractType>(
    selectedDocument?.pages?.[0]?.pageExtracts?.find(
      (el) => el.extract_type_id === 5
    )?.extract_type ||
      selectedDocument?.pages?.[0]?.pageExtracts[0]?.extract_type
  );
  React.useEffect(() => {
    setSelectedExtractType(
      selectedDocument?.pages?.[0]?.pageExtracts?.find(
        (el) => el.extract_type_id === 5
      )?.extract_type ||
        selectedDocument?.pages?.[0]?.pageExtracts[0]?.extract_type
    );
  }, [selectedDocument]);

  let ci_json = {
    groups: [],
    lineItems: [],
  };
  if (
    selectedDocument.annotationType &&
    selectedDocument.annotationType.length > 0
  ) {
    ci_json = selectedDocument.annotationType[0].annotation_schema;
  }

  let annotationTypeV2: any[] = [];
  ci_json.groups.forEach((g: any) => {
    const items = g.labels.map((l: any) => {
      return {
        name: `${g.name}${l.name}`.toLocaleLowerCase(),
        group: g.name.toLocaleLowerCase(),
        assigned: false,
      };
    });
    annotationTypeV2 = [...annotationTypeV2, ...items];
  });

  let lineItemsKeysV2: any[] = [];
  ci_json.lineItems.forEach((l: any) => {
    lineItemsKeysV2.push(l.name.toLocaleLowerCase());
  });

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const lineItemMode = selectedTab === 1;
  const [drawingGrid, setDrawingGrid] = useState(false);
  //const [extractTextFrontend, setExtractTextFrontend] = useState(false);

  const [confirmAI, { loading: confirmAILoading }] = useConfirmAI();
  let pageId = "";
  if (selectedDocument && selectedDocument["pages"]) {
    pageId = selectedDocument["pages"][currentPageIndex]["id"];
  }
  const [lineItems, actions] = useTableQuery({
    page_id: pageId,
    extract_type_id: selectedExtractType?.extract_type_id,
    document: selectedDocument,
  });

  const {
    dataPoints,
    extractDataPoint,
    removeDataPoint,
    updateDataPointAndInside,
  } = useDataPointsMutations({
    document: selectedDocument,
    //extractTextFrontend,
    extract_type_id: selectedExtractType?.extract_type_id,
  });

  const outerLabelsByDataPointId = useMemo(() => {
    if (!dataPoints || !dataPoints[currentPageIndex]) return {};

    let parents = dataPoints[currentPageIndex].filter(
      (dp: any) => dp.dataPointsInside
    );
    let singles = dataPoints[currentPageIndex].filter(
      (dp: any) =>
        !dp.parentDataPointId &&
        !dp.dataPointsInside &&
        !dp.lineItemType &&
        dp.type
    );

    const outerDataPoints = [...parents, ...singles].reduce(
      (a: any, x: any) => ({ ...a, [x.id]: x }),
      {}
    );
    return outerDataPoints;
  }, [dataPoints, currentPageIndex]);

  const handleUpdateDataPointAndInside = useCallback(
    (value: any) => {
      const { dataPoint, page, dataPointsInside, clearAreaAnnotations } = value;
      updateDataPointAndInside(
        [dataPoint],
        page,
        dataPointsInside,
        currentPageIndex,
        clearAreaAnnotations
      );
    },
    [actions, updateDataPointAndInside, currentPageIndex]
  );

  const handleCreateNewDataPoint = useCallback(
    async (rec: DataPointCoordinates, page: any, pageIndex: number) => {
      if (drawingGrid) {
        const updatedGrids = orderBy(
          [
            ...lineItems,
            {
              id: `${pageIndex}-${uuid()}`,
              coordinates: rec,
              //page: pageIndex,
              rows: [{ top: 0 }],
              columns: [{ left: 0 }],
            },
          ],
          "page"
        );

        await actions.updateLineItems(
          page["id"],
          selectedExtractType?.extract_type_id || 5,
          updatedGrids
        );
        setDrawingGrid(false);
        onCreateNewDataPoint();
        return;
      }

      if (!selectedDocument) return;
      const newDataPoint = await extractDataPoint(
        {
          id: `${pageIndex}-${uuid()}`,
          coordinates: rec,
          page: pageIndex + 1,
          value: "",
        },
        page
      );
      setNewDataPoint(newDataPoint);
      return newDataPoint;
    },
    [
      actions,
      extractDataPoint,
      selectedDocument,
      drawingGrid,
      lineItems,
      onCreateNewDataPoint,
      setDrawingGrid,
    ]
  );

  const handleUpdateLineItem = useCallback(
    async (updatedLineItem: LineItem) => {
      await actions.updateLineItems(
        pageId,
        selectedExtractType?.extract_type_id || 5,
        lineItems.map((lineItem) =>
          lineItem.id === updatedLineItem.id ? updatedLineItem : lineItem
        )
      );
      setDrawingGrid(false);
    },
    [actions, lineItems, selectedExtractType]
  );

  const handleRemoveGrid = useCallback(
    (deleteLineItem: LineItem) => {
      actions.updateLineItems(
        pageId,
        selectedExtractType?.extract_type_id || 5,
        lineItems.filter((lineItem) => lineItem.id !== deleteLineItem.id)
      );
    },
    [actions, lineItems]
  );

  const handleChangePage = useCallback(
    (page: number) => {
      setCurrentPageIndex(page - 1);
    },
    [currentPageIndex]
  );

  const handleDataPointClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>, newSelectedDataPoint: DataPoint) => {
      if (selectedFormFieldRef.current.prev) {
        const clearedPath = removeArrayIndexesFromPath(
          selectedFormFieldRef.current.prev
        );
        const newAnnotationType: string = (() => {
          if (lineItemMode) {
            return get(formToAnnotationMap.lineItem, clearedPath);
          } else {
            return formToAnnotationMap[clearedPath as never];
          }
        })();
        if (newAnnotationType) {
          const dataPointsForUpdate = [
            {
              ...newSelectedDataPoint,
              lineItemType: lineItemMode ? newAnnotationType : undefined,
              type: lineItemMode ? undefined : newAnnotationType,
            },
          ];
          if (!e.shiftKey) {
            dataPointsForUpdate.push(
              ...dataPoints[currentPageIndex]
                .filter(
                  (dataPoint) =>
                    dataPoint.id !== newSelectedDataPoint.id &&
                    (dataPoint.lineItemType === newAnnotationType ||
                      dataPoint.type === newAnnotationType)
                )
                .map((dataPoint) => ({
                  ...dataPoint,
                  lineItemType: undefined,
                  type: undefined,
                }))
            );
          }

          updateDataPointAndInside(
            dataPointsForUpdate,
            selectedDocument.pages[currentPageIndex],
            undefined,
            currentPageIndex
          );
        }
      }
      onDataPointClick(newSelectedDataPoint.value, e.shiftKey);
    },
    [
      updateDataPointAndInside,
      selectedDocument,
      currentPageIndex,
      onDataPointClick,
      dataPoints,
      lineItemMode,
    ]
  );

  const handleClosePopover = useCallback(() => {
    setNewDataPoint(undefined);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        py: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 1,
          mx: 2,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={!!ai_confirmed}
              disabled={confirmAILoading}
              onChange={(e, checked) => {
                confirmAI(file_id, dr_no, checked);
              }}
            />
          }
          label={t("AI Confirmed")}
        />
        <Box
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          <Autocomplete
            options={[...documents].sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
            value={selectedDocument}
            onChange={(e, value) => {
              handleChangePage(1);
              setSelectedDocument(value);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => {
              const documentType = option.documentType.translations[language];
              if (
                documents.some(
                  (document) =>
                    document.document_type === option.document_type &&
                    document.sequence > 1
                )
              ) {
                return `${documentType} ${option.sequence}`;
              }
              return documentType;
            }}
            disableClearable
            sx={{
              width: 190,
            }}
          />
          <Pagination
            count={selectedDocument.pages.length}
            color="primary"
            page={currentPageIndex + 1}
            onChange={(e, page) => handleChangePage(page)}
            sx={{
              boxShadow: "none",
              minWidth: 170,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
          mx: 2,
        }}
      >
        <Box display="flex" gap={2} alignItems="center">
          <Autocomplete
            value={selectedExtractType}
            options={selectedDocument.pages[0].pageExtracts.map(
              (el) => el.extract_type
            )}
            onChange={(event, value) => {
              setSelectedExtractType(value);
            }}
            isOptionEqualToValue={(option, value) =>
              option.extract_type_id === value.extract_type_id
            }
            getOptionLabel={(option) => option.translations[systemLanguage]}
            InputProps={{
              label: "AI Model",
            }}
            disableClearable
            sx={{ width: 120 }}
          />
          {lineItemMode && (
            <GridMenu
              onGridDrawModeToggle={() => setDrawingGrid((prev) => !prev)}
            />
          )}
          {/* <Switch
            label={t(
              extractTextFrontend
                ? "validation:extractFrontend"
                : "validation:extractBackend"
            )}
            checked={extractTextFrontend}
            onToggle={(event) => setExtractTextFrontend(event.target.checked)}
          /> */}
        </Box>
        <Box>
          <Switch
            label={t("declarationNew:enableMagnifier")}
            checked={isMagnifierOn}
            onToggle={() => {
              setIsMagnifierOn((prev) => {
                const newValue = !prev;
                localStorage.setItem(
                  "magnifierToggleState",
                  JSON.stringify(newValue)
                );
                return newValue;
              });
            }}
          />
        </Box>
      </Box>
      <Paper
        variant="outlined"
        sx={{
          flexGrow: 1,
          overflow: "hidden",
          mx: 2,
        }}
        ref={pageRef}
      >
        <DataPointDocumentViewer
          documentPages={[
            [...selectedDocument.pages].sort(
              (a, b) => a.page_number - b.page_number
            )[currentPageIndex],
          ]}
          magnifierToggle={isMagnifierOn}
        >
          {({ scale, page, size, originalSize }: IChildrenProps) => {
            const dataPointsInPage = dataPoints[currentPageIndex];
            const gridsInPage = lineItems;
            return (
              <DataPointsLayer
                page={page}
                size={size}
                originalSize={originalSize}
                scale={scale}
                pageIndex={currentPageIndex}
                drawingGrid={drawingGrid}
                dataPoints={dataPointsInPage}
                grids={gridsInPage}
                onCreateDataPoint={(rec) =>
                  handleCreateNewDataPoint(rec, page, currentPageIndex)
                }
                newDataPoint={
                  newDataPoint?.page === currentPageIndex + 1
                    ? newDataPoint
                    : undefined
                }
                renderDataPoint={({
                  dataPoint,
                  innerDataPoints,
                  ...props
                }: IRenderDataPointProps) => (
                  <DataPointBox
                    key={dataPoint.id}
                    dataPoint={dataPoint}
                    innerDataPoints={innerDataPoints}
                    {...props}
                    annotation={outerLabelsByDataPointId[dataPoint.id]}
                    annotationTypes={annotationTypeV2}
                    lineItemTypes={lineItemsKeysV2}
                    updateDataPointAndAssignType={async ({
                      dataPoint,
                      dataPointsInside,
                      clearAreaAnnotations,
                    }) => {
                      setPrevSelectedDataPointType((prev) => ({
                        ...prev,
                        ...(dataPoint.type && {
                          annotation: dataPoint.type,
                        }),
                        ...(dataPoint.lineItemType && {
                          lineItem: dataPoint.lineItemType,
                        }),
                      }));
                      return handleUpdateDataPointAndInside({
                        dataPoint,
                        page,
                        dataPointsInside,
                        clearAreaAnnotations,
                      });
                    }}
                    onRemove={() =>
                      removeDataPoint(dataPoint, page, currentPageIndex)
                    }
                    onClick={handleDataPointClick}
                    onClosePopover={handleClosePopover}
                    assignToLineItem={lineItemMode}
                    selectedFormFieldRef={selectedFormFieldRef}
                    prevSelectedDataPointType={prevSelectedDataPointType}
                  />
                )}
                renderGridBox={({ grid, scale, size }: IRenderGridBoxProps) =>
                  lineItemMode && (
                    <MagicGrid
                      key={grid.id}
                      dataGrid={grid}
                      scale={scale}
                      lineItemsSchema={lineItemsKeysV2}
                      width={size.width}
                      height={size.height}
                      onUpdateGrid={handleUpdateLineItem}
                      onRemoveGrid={handleRemoveGrid}
                    />
                  )
                }
              />
            );
          }}
        </DataPointDocumentViewer>
      </Paper>
    </Box>
  );
};

export default memo(
  ({
    documents,
    loading,
    ...rest
  }: Omit<DocumentDrawerProps, "documents"> & {
    loading: boolean;
    documents?: Document[];
  }) => {
    const { t } = useTranslation();

    const filteredDocuments = useMemo(
      () =>
        documents
          ?.filter((document) => {
            return (
              document.document_type !== "unknown" &&
              document.document_extension !== "xml"
            );
          })
          .sort((a, b) => a.sequence - b.sequence),
      [documents]
    );

    if (loading) return <Box />;
    if (!filteredDocuments?.length)
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" color="textSecondary" noWrap>
            {t("No Documents")}
          </Typography>
        </Box>
      );

    return <DocumentDrawer documents={filteredDocuments} {...rest} />;
  }
);
