import { gql } from "@apollo/client";
import {
  AditGetDeclarationsListQuery,
  useAditGetDeclarationsListQuery,
} from "graphql/generated";

export type DeclarationListEntity = NonNullable<
  AditGetDeclarationsListQuery["declarations"]
>[number];

gql`
  query AditGetDeclarationsList($_like: String) {
    declarations: declaration_request(
      where: { file: { identification: { _is_null: false, _ilike: $_like } } }
    ) {
      file_id
      dr_no
      file {
        identification
      }
    }
  }
`;

export default useAditGetDeclarationsListQuery;
