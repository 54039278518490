export enum DocumentTypeFlag {
  "none" = 0,
  "classification_relevant" = 1,
  "readonly_relevant" = 2,
  "custom_relevant" = 4,
  "we_declare_classification_relevant" = 8,
  "dms_exported" = 16,
  "ocr_needed" = 32,
  "ocr_datapoint_required" = 64,
  "autoclassification_required" = 128,
  "backoffice_relevant" = 256,
  "autoclassification_completed" = 1024,
  "ocr_datapoint_completed" = 2048,
  "autoclassification_scheduled" = 4096,
  "ocr_completed" = 8192,
  "annotations_export_completed"= 65536,
  "manual_annotation_required" = 262144,
  "edec_relevant"= 524288,
  "prepare_for_validation_screen"= 8388608,
  "auto_export_doc_annotation"= 16777216,
  "foun_srv_completed" = 33554432,
}

export interface DocumentTypeTenantSettingDB {
  tenant: string;
  document_type: string;
  creation_date?: Date;
  creation_user?: string;
  last_modified_date?: Date;
  last_modified_user?: string;
  code?: string;
  meta?: { color?: string; labels?: string[] };
  default_flags?: number;
}

export interface DocumentTypeDB {
  name: string;
  creation_date?: Date;
  creation_user?: string;
  last_modified_date?: Date;
  last_modified_user?: string;
  translations: { en?: string; de?: string; ru?: string };
  document_type_tenant_settings: DocumentTypeTenantSettingDB[];
}

export interface DocumentType {
  name: string;
  creation_date?: Date;
  creation_user?: string;
  last_modified_date?: Date;
  last_modified_user?: string;
  translations: { [key: string]: string };
  translated_name: string;
  default_flags: DocumentTypeFlag;
  code?: number;
  meta?: { color?: string; labels?: string[] };
}
