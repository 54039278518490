import React, { forwardRef } from "react";
import InfinityLoader from "react-window-infinite-loader";
import VirtualizationList, {
  IVirtualizationListProps
} from "./VirtualizationList";

interface ILazyLoaderProps extends IVirtualizationListProps {
  initiallyLoadedData: number;
  loadMore: (startIndex: number, itemCount: number) => Promise<any>;
}

const itemLoaded: boolean[] = [];
// let prevItemCount = 0;
const isItemLoaded = (index: number): boolean => itemLoaded[index];

const LazyLoader = forwardRef(
  (
    { loadMore, itemCount, initiallyLoadedData, ...rest }: ILazyLoaderProps,
    ref: any
  ) => {
    /* if (itemCount !== prevItemCount) {
      new Array(initiallyLoadedData).fill(null).forEach((_, i) => {
        itemLoaded[i] = true;
      });
      prevItemCount = itemCount;
    } */

    const loadMoreItems = async (startIndex: number, stopIndex: number) => {
      const itemsSize = stopIndex - startIndex + 2;

      /* for (let i = startIndex; i <= itemsSize; i++) {
        itemLoaded[i] = true;
      } */
      await loadMore(startIndex, itemsSize);
    };

    return (
      <InfinityLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
        ref={ref}
      >
        {({ onItemsRendered, ref }) => (
          <VirtualizationList
            onItemsRendered={onItemsRendered}
            ref={ref}
            itemCount={itemCount}
            {...rest}
          />
        )}
      </InfinityLoader>
    );
  }
);

export default LazyLoader;
