import React, { FC, HtmlHTMLAttributes } from "react";
import {
  Dialog,
  Typography,
  FormTextField,
  FormAutocomplete,
  Label,
  Theme,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import TenantDocumentType from "domain/documentType/tenantDocumentType/TenantDocumentType";
import useUpsertDocumentTypeTenantSetting from "domain/documentType/useUpsertDocumentTypeTenantSetting";
import useGetDocumentTypeFlags from "domain/documentTypeFlag/useGetDocumentTypeFlags";
import { DocumentTypeFlag } from "domain/documentTypeFlag/DocumentTypeFlag";
import { makeStyles } from 'tss-react/mui';

interface DocumentTypeFormDialogProps extends HtmlHTMLAttributes<HTMLElement> {
  tenantDocumentType: TenantDocumentType;
  onConfirm: () => void;
  onClose: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  form: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(2),
  },
  defaultFlags: {
    gridColumn: "1 / -1",
  },
  colorContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
  },
}));

const DocumentTypeFormDialog: FC<DocumentTypeFormDialogProps> = ({
  tenantDocumentType,
  onConfirm,
  onClose,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { documentTypeFlags } = useGetDocumentTypeFlags();
  const upsertTenantDocumentType = useUpsertDocumentTypeTenantSetting();

  return (
    <Formik
      initialValues={tenantDocumentType}
      onSubmit={(values) => {
        upsertTenantDocumentType({
          variables: {
            document_type_tenant_setting: values.toDBObject(),
          },
        }).then(() => onConfirm());
      }}
    >
      {({
        submitForm,
        isSubmitting,
        dirty,
        isValid,
        values,
        setFieldValue,
      }) => (
        <Dialog
          mainTitle={t("tenantDocumentType")}
          onClose={onClose}
          onConfirm={submitForm}
          CancelButtonProps={{ label: t("common:abort") }}
          ConfirmButtonProps={{
            label: t("common:save"),
            disabled: isSubmitting || !dirty || !isValid,
            loading: isSubmitting,
          }}
          maxWidth="xs"
        >
          <Form className={classes.form}>
            <FormTextField name="code" label={t("code")} />
            <div className={classes.colorContainer}>
              <Typography color="textSecondary">{t("color")}:</Typography>
              <input
                type="color"
                value={values.meta?.color}
                onChange={(e) => setFieldValue("meta.color", e.target.value)}
              />
            </div>
            <FormAutocomplete<DocumentTypeFlag, true>
              className={classes.defaultFlags}
              name="defaultFlags"
              InputProps={{
                label: t("flags"),
              }}
              multiple
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderTags={(documentTypeFlags) =>
                documentTypeFlags.map((documentTypeFlag) => (
                  <Label
                    color={documentTypeFlag.color}
                    key={documentTypeFlag.id}
                    style={{
                      margin: "2.5px",
                    }}
                  >
                    {documentTypeFlag.name}
                  </Label>
                ))
              }
              renderOption={(props, documentTypeFlag) => (
                <li {...props}>
                  <Label
                    color={documentTypeFlag.color}
                    key={documentTypeFlag.id}
                  >
                    {documentTypeFlag.name}
                  </Label>
                </li>
              )}
              options={documentTypeFlags}
            />
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default DocumentTypeFormDialog;
