import { LanguageType } from "i18n";
import Entity, { EntityDependencies } from "domain/Entity";

export interface DocumentTypeFlagDB {
  id: number;
  name?: string;
  translation?: {
    [key in LanguageType]?: string;
  };
  color?: string;
  display?: 0 | 1;
}

export class DocumentTypeFlag extends Entity {
  id: DocumentTypeFlagDB["id"];
  name: string;
  color: NonNullable<DocumentTypeFlagDB["color"]>;
  display: DocumentTypeFlagDB["display"];

  constructor(obj: DocumentTypeFlagDB, dep: EntityDependencies) {
    super(dep);

    this.id = obj.id;
    this.name = obj.translation?.[this._dep.language] || obj.name || "";
    this.color = obj.color || "#000000";
    this.display = obj.display;
  }

  toDBObject() {
    return this;
  }
}
