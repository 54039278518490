import { Box, styled } from "@periplus/ui-library";
import { hexToRgbA } from "utils/colors";

type CircleIconProps = {
  color: string;
  padding?: number;
  fontSize?: number;
  bgOpacity?: number;
  bgColor?: string;
  number?: number;
};

const CircleIcon = styled(Box)<CircleIconProps>(
  ({
    color,
    padding = 6,
    fontSize,
    bgOpacity = 0.2,
    bgColor = color,
    number,
  }) => ({
    padding: `${padding}px`,
    color: hexToRgbA(color),
    position: "relative",
    textAlign: "center",
    borderRadius: "50%",
    display: "inline-flex",
    backgroundColor: hexToRgbA(bgColor, bgOpacity),
    "& > svg": {
      fontSize: `${fontSize || 1.5}rem`,
    },
    ...(number != null && {
      padding: `${fontSize || 0.625}rem`,
      fontSize: `${fontSize || 0.625}rem`,
      "&:before": {
        content: `"${number}"`,
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      },
    }),
  })
);

export default CircleIcon;
