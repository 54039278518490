import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import useErrorHandling from "hooks/useErrorHandling";
import { gql } from "@apollo/client";

const useDeleteFile = () => {
  const [deleteFile] = useMutation(gql`
    mutation updateFile($id: [uuid!]!) {
      update_file(where: { id: { _in: $id } }, _set: { is_deleted: true }) {
        affected_rows
      }
    }
  `);
  const withErrorHandling = useErrorHandling();

  const removeFile = useCallback(
    async (fileIds: string | string[]) => {
      await withErrorHandling(deleteFile, {
        variables: {
          id: fileIds,
        },
      });
    },
    [deleteFile, withErrorHandling]
  );

  return removeFile;
};

export default useDeleteFile;
