import { gql } from "@apollo/client";
import {
  AditGetUserOrgIdListQuery,
  useAditGetUserOrgIdListQuery,
} from "graphql/generated";

export type UserOrgIdListEntity =
  AditGetUserOrgIdListQuery["userOrgIds"][number];

gql`
  query AditGetUserOrgIdList {
    userOrgIds: getUserOrgIds
  }
`;

export default useAditGetUserOrgIdListQuery;
