import React, { FC, useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Slide,
  Grid,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  Typography,
  Tooltip,
  Theme,
} from "@periplus/ui-library";
import dayjs from "dayjs";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import ClearAll from "@mui/icons-material/ClearAll";
import Fingerprint from "@mui/icons-material/Fingerprint";
import GetApp from "@mui/icons-material/GetAppOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import useGetNotifications from "graphql/hooks/useGetNotifications";
import { getExtensionDescriptionIcon } from "pages/Select/extensionDescription";
import { useApplicationContext } from "contexts/ApplicationContext";
import Dot from "components/Dot";
import Text from "components/Text/Text";
import { useTranslation } from "react-i18next";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    buttonInLink: {
      "&:hover": {
        textDecoration: "none",
      },
    },

    drawer: {
      [theme.breakpoints.up("xs")]: {
        width: "100%",
      },

      [theme.breakpoints.up("md")]: {
        width: 420,
      },
    },

    paper: {
      width: "100vw",
      border: "none",
      zIndex: 9999,
      [theme.breakpoints.up("md")]: {
        width: 420,
      },
    }
  }));

const getIcon = (notification: any) => {
  if (notification.error) {
    return <ErrorOutlineIcon width="30px" height="30px" color="error" />;
  }

  switch (notification.type) {
    case "Upload": {
      const Icon = getExtensionDescriptionIcon(notification.content.extension);
      return <Icon width="30px" height="30px" />;
    }
    case "Export": {
      return <GetApp style={{ transform: "rotate(180deg)" }} />;
    }
    case "Identification": {
      return <Fingerprint />;
    }
    case "Classify": {
      return <AccountTreeOutlinedIcon />;
    }
    default:
      return <></>;
  }
};

const generateMessage = (notification: any) => {
  let text = <></>;

  const { content, type, contentType, status, error } = notification;
  const { name, folderName, extension, isFolder } = content;

  if (error) {
    return <Text component="span">{error}</Text>;
  }

  const textInPast = (
    <Text component="span">{`past:${type.toLowerCase()}`}</Text>
  );

  const translatedContentType = (
    <Text component="span">{`common:${contentType.toLowerCase()}`}</Text>
  );

  const isContainsExtension =
    /\.[a-zA-Z]{2,4}$/.test(name) || /\.[a-zA-Z]{2,4}$/.test(folderName);
  let endName = isFolder ? folderName : name;
  if (isContainsExtension) {
    endName = endName.replace(/\.[a-zA-Z]{2,4}$/, `.${extension}`);
  }

  const firstPart = (
    <>
      {translatedContentType}{" "}
      <b style={{ wordBreak: "break-all" }}>{endName}</b>{" "}
    </>
  );

  if (status === "Success") {
    text = (
      <>
        {firstPart}
        <Text component="span">common:hasBeen</Text> {textInPast}
      </>
    );
  }

  if (status === "Error") {
    text = (
      <>
        {firstPart}
        <Text component="span">common:hasNotBeen</Text> {textInPast}
      </>
    );
  }

  if (["Processing", "Deferred"].includes(status)) {
    text = (
      <>
        {firstPart}
        <Text component="span">{`continuous:${type.toLowerCase()}`}</Text>
      </>
    );
  }

  return text;
};

const mapStatuses: Record<string, React.ReactElement> = {
  Success: <Text component="span">common:ready</Text>,
  Processing: <Text component="span">common:inProgress</Text>,
  Error: <Text component="span">common:failed</Text>,
  Deferred: <Text component="span">common:deferred</Text>,
};

const getStatus = (status: string) => {
  return mapStatuses[status] || status;
};

const getColor = (status: string) => {
  const mapStatuses: any = {
    Success: "green",
    Error: "red",
  };
  return mapStatuses[status] || "orange";
};

type NotificationData = {
  date: string;
  status: string;
  type: string;
  deleted: boolean;
  error?: string;
};

interface NotificationsProps {
  data: NotificationData;
  clearNotification: (args: any) => void;
  markNotification: (args: any) => void;
}

const Notification = ({
  data,
  clearNotification,
  markNotification,
}: NotificationsProps) => {
  const { date, status, type, deleted } = data;
  const [hovered, setHovered] = useState(false);

  const clear = () => {
    markNotification(data);
  };

  const message = generateMessage(data);
  const color = getColor(status);
  const uStatus = getStatus(status);
  return (
    <Slide
      direction="left"
      in={!deleted}
      mountOnEnter
      unmountOnExit
      onExited={() => clearNotification(data)}
    >
      <div>
        <ListItem
          onMouseOver={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <ListItemAvatar>{getIcon(data)}</ListItemAvatar>
          <ListItemText
            primary={
              <>
                <Text
                  color="textSecondary"
                  style={{ textTransform: "uppercase" }}
                >
                  {`present:${type.toLowerCase()}`}
                </Text>

                <Typography paragraph style={{ marginBottom: 0 }}>
                  {message}
                </Typography>
                <Text
                  variant="caption"
                  color="textSecondary"
                  style={{ fontStyle: "italic" }}
                >
                  {dayjs(date).fromNow()}
                </Text>
              </>
            }
          />
          <Box style={{ minWidth: 110, marginLeft: 15, textAlign: "right" }}>
            {hovered ? (
              <Button variant="text" onClick={clear} style={{ height: 60 }}>
                <Grid container direction="column" alignItems="center">
                  <DraftsOutlinedIcon />
                  <Text
                    variant="caption"
                    style={{ textTransform: "uppercase" }}
                  >
                    clear
                  </Text>
                </Grid>
              </Button>
            ) : (
              <Dot bgColor={color} adoptTextColor>
                <Text variant="caption">{uStatus}</Text>
              </Dot>
            )}
          </Box>
        </ListItem>
        <Divider flexItem={false} />
      </div>
    </Slide>
  );
};

interface NotificationsWidgetProps {}

const NotificationsWidget: FC<NotificationsWidgetProps> = () => {
  const [appState, appAction] = useApplicationContext();
  const [storedNotifications, setNotifications] = useState<any>([]);
  const { classes } = useStyles();
  const notifications = useGetNotifications();

  const { t } = useTranslation();

  useEffect(() => {
    if (notifications.length === 0) return;
    setNotifications((sNotifications: any) =>
      notifications.concat(sNotifications)
    );
    appAction.toggleNewMessages(true);
    // eslint-disable-next-line
  }, [notifications]);

  const clearNotification = (notification: any) => {
    setNotifications(
      storedNotifications.filter(
        (sNotification: any) => sNotification !== notification
      )
    );
  };

  const markNotification = (notification: any) => {
    setNotifications(
      storedNotifications.map((sNotification: any) => ({
        ...sNotification,
        deleted: sNotification === notification,
      }))
    );
  };

  const clearNotifications = () => {
    appAction.toggleNewMessages(false);
    setNotifications(
      storedNotifications.map((sNotification: any) => ({
        ...sNotification,
        deleted: true,
      }))
    );
  };

  return (
    <Drawer
      variant="persistent"
      open={appState.isNotificationsShown}
      onClose={() => appAction.toggleNotificationsShown(false)}
      anchor="right"
      className={classes.drawer}
      classes={{ paper: classes.paper }}
      PaperProps={{
        elevation: 3,
      }}
    >
      <Grid
        container
        direction="row"
        style={{
          padding: "30px 15px 24px",
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text variant="h5">notifications:notifications</Text>
        <IconButton
          onClick={() => {
            appAction.toggleNotificationsShown();
            appAction.toggleNewMessages(false);
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <List
        style={{
          height: "calc(100% - 37px)",
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        <Divider flexItem={false} />
        {storedNotifications.map((notification: any, index: number) => (
          <Notification
            key={`${notification.date}${notifications.status}${notification.content.name}`}
            data={notification}
            markNotification={markNotification}
            clearNotification={clearNotification}
          />
        ))}
      </List>
      <Box
        paddingY="34px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
      >
        {/*  <Link to="/notifications" className={classes.buttonInLink}> */}
        <Tooltip title="Not yet implemented">
          <span>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                appAction.toggleNotificationsShown();
              }}
              disabled
            >
              {t("notifications:viewAll")}
            </Button>
          </span>
        </Tooltip>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginLeft: "25px" }}
          onClick={clearNotifications}
          endIcon={<ClearAll />}
        >
          {t("clearAll")}
        </Button>
      </Box>
    </Drawer>
  );
};

export default NotificationsWidget;
