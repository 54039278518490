import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Button, Tooltip, Box, Theme, Typography } from "@periplus/ui-library";
import dayjs from "dayjs";
//import "moment-duration-format";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useTranslation } from "react-i18next";
//import updateDurationLabelsShort from "utils/dayjs-duration";
import { dueDateToPriority } from "utils/dueDateToPriority";
import { makeStyles } from 'tss-react/mui';

//updateDurationLabelsShort(dayjs);

interface Props {
  dueDate?: string;
}

const useStyles = makeStyles()((theme: Theme) =>
  ({
    priorityIcon: {
      fontSize: 14,
      marginRight: theme.spacing(0.5),
    },

    priorityText: {
      fontSize: 14,
    }
  }));

const PriorityDueDate = ({ dueDate }: Props) => {
  const { classes } = useStyles();
  const [now, setNow] = useState(dayjs());
  const dueDateDayjs = useMemo(() => dayjs(dueDate), [dueDate]);
  const { t } = useTranslation();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setNow(dayjs());
    }, 30 * 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const renderPriority = useCallback(() => {
    if (!dueDate) return;
    const minutesLeft = dueDateDayjs.diff(now, "minutes");
    const timeLeft = dayjs.duration(minutesLeft, "minutes").format("h _ mm _");
    const priority = dueDateToPriority(dueDate);
    const priorityToColor: Record<any, string> = {
      highest: "darkred",
      high: "red",
      medium: "orange",
      low: "gold",
      lowest: "green",
    };

    return (
      <Box display="flex" alignItems="center" color={priorityToColor[priority]}>
        <FiberManualRecordIcon className={classes.priorityIcon} />
        <Typography>{t("common:timeLeft", { time: timeLeft })}</Typography>
      </Box>
    );
  }, [dueDate, dueDateDayjs]);

  if (!dueDate) return null;
  return (
    <Tooltip title={dueDateDayjs.format("DD.MM.YYYY HH:mm:ss")} placement="top">
      <Button disableElevation disableRipple>
        {renderPriority()}
      </Button>
    </Tooltip>
  );
};

export default PriorityDueDate;
