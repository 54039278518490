import { gql } from "@apollo/client";
import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";
import { LanguageType } from "i18n";

type ServerAddressType = {
  id: number;
  name: string;
  translation: {
    en?: string;
    de?: string;
    ru?: string;
  };
};

export type AddressType = {
  id: number;
  name: string;
};

type AddressTypeResponse = {
  address_type: ServerAddressType[];
};

const GET_ADDRESS_TYPE = gql`
  query GET_ADDRESS_TYPE {
    address_type {
      id
      name
      translation
    }
  }
`;

const useGetAddressType = () => {
  const { i18n } = useTranslation();
  const result = useQuery<AddressTypeResponse>(GET_ADDRESS_TYPE, {
    fetchPolicy: "no-cache",
  });

  return useMemo(() => {
    const { data, ...rest } = result;

    const address_type = data?.address_type || [];

    const addressType = address_type.map(({ translation, name, ...rest }) => ({
      ...rest,
      name:
        translation[i18n.language as LanguageType] ??
        name.split("_").map(capitalize).join(" "),
    }));

    return {
      ...rest,
      data: {
        address_type: addressType,
      },
    };
  }, [result, i18n.language]);
};

export default useGetAddressType;
