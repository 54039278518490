import React from "react";
import { Theme } from "@periplus/ui-library";
import { GridColumn } from "graphql/hooks/useTableQuery";
import { TextNaked } from "components/Text";
import AssignColumnNameButton from "./AssignColumnNameButton";
import { IColumnNamesState } from "./MagicGrid";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    nameButton: {
      width: 32,
      height: 18,
      padding: 0,
      margin: 0,
      borderRadius: "4px 4px 0 0",
      border: `1px solid ${theme.palette.primary.main}`,
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
      position: "absolute",
      top: -19,
      pointerEvents: "auto",
    },

    columnName: {
      position: "absolute",
      top: -22,
      height: 20,
      border: `2px solid ${theme.palette.primary.main}`,
      borderBottom: 0,
      borderRadius: "5px 5px 0 0",
      padding: "0 4px",
      background: "#6462dc",
      color: "#fff",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontSize: 12,
      lineHeight: "20px",
      cursor: "pointer",
      pointerEvents: "auto",
    }
  }));

interface IColumnNamesProps {
  bounds: ClientRect;
  columns: GridColumn[];
  onChangeColumnName: (index: number, name?: string) => void;

  columnNames: IColumnNamesState;
}

const ColumnNames = ({
  columns,
  bounds,
  onChangeColumnName,
  columnNames,
}: IColumnNamesProps) => {
  const { classes } = useStyles();
  const borderWidth = 2;
  return (
    <>
      {columns.map((column, index) => {
        const right =
          index === columns.length - 1 ? 1 : columns[index + 1].left;
        return column.name ? (
          <div
            key={index}
            className={classes.columnName}
            style={{
              left: column.left * bounds.width - borderWidth,
              width: (right - column.left) * bounds.width + borderWidth,
            }}
            onClick={() => {
              onChangeColumnName(index, undefined);
            }}
          >
            <TextNaked>specificTerms:{column.name}</TextNaked>
          </div>
        ) : (
          <AssignColumnNameButton
            key={index}
            className={classes.nameButton}
            style={{
              left: ((column.left + right) / 2) * bounds.width - 16,
            }}
            columnNames={columnNames}
            assignType={(lineItem) => {
              onChangeColumnName(index, lineItem);
            }}
          />
        );
      })}
    </>
  );
};

export default ColumnNames;
