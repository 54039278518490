import { gql } from "@apollo/client";
import { useAditUpdateFileNumberMutation } from "graphql/generated";

gql`
  mutation ADITUpdateFileNumber($file_id: ID!, $file_number: String!) {
    updateFileNumber(file_id: $file_id, file_number: $file_number) {
      file_id
    }
  }
`;

export default useAditUpdateFileNumberMutation;
