import { InMemoryCache } from "@apollo/client/cache";
import {
  ApolloClient,
  ApolloLink,
  Observable,
  split,
  HttpLink,
  ServerError,
  ServerParseError,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import config from "config";
import Keycloak from "keycloak-js";
import { KeycloakInstance } from "keycloak-js";
import * as Sentry from "@sentry/react";
import { GraphQLError } from "graphql";

export const keycloak: KeycloakInstance = Keycloak({
  url: config.keycloak.url,
  realm: config.keycloak.realm,
  clientId: config.keycloak.clientId,
  // onLoad: "login-required",
  // flow: "implicit",
  // responseType: "id_token token"
});

/**
 * Cache. Could set redirects etc
 */
const cache = new InMemoryCache({
  addTypename: false,
});

/**
 * Handles GraphQL error. Note that you can not use async/await... https://github.com/apollographql/apollo-link/issues/646#issuecomment-423279220
 * @param {} graphQLErrors
 */
function handleGraphQLError(graphQLErrors: readonly GraphQLError[]) {
  graphQLErrors.forEach((error) =>
    Sentry.captureException(new Error(error.message), {
      extra: JSON.parse(JSON.stringify(error)),
    })
  );
}

/**
 * Handles Network errors such as token expired, invalid etc...
 * @param {} networkError
 */
function handleNetworkError(
  networkError: Error | ServerError | ServerParseError
) {
  Sentry.captureException(networkError);
  refreshToken(25);
}

/**
 * Set token on each request
 */
const request = (operation: any) => {
  operation.setContext({
    headers: {
      //@ts-ignore
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  });
};

const requestLink = new ApolloLink(
  (operation: any, forward: any) =>
    new Observable((observer: any) => {
      let handle: any;
      Promise.resolve(operation)
        .then(request)
        .then(() => {
          handle = forward(operation).subscribe({
            complete: observer.complete.bind(observer),
            error: () => {},
            next: observer.next.bind(observer),
          });
          return handle;
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    })
);

function getWsUri(uri = config.graphQl.url) {
  if (uri.includes("https://")) {
    return uri.replace("https://", "wss://");
  }
  return uri.replace("http://", "ws://");
}

// Create an http link:
const httpLink = new HttpLink({
  uri: config.graphQl.url,
});

function refreshToken(minValidity: number) {
  return new Promise((resolve, reject) => {
    keycloak.updateToken(minValidity).then(resolve).catch(reject);
    // keycloak
    //   .updateToken(minValidity)
    //   //@ts-ignore
    //   .success(resolve)
    //   //@ts-ignore
    //   .error(reject);
  });
}

async function getAsyncConnectionParams() {
  await refreshToken(25);
  return {
    headers: {
      Authorization: `Bearer ${keycloak.idToken}`,
    },
  };
}

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  options: {
    connectionParams: getAsyncConnectionParams,
    lazy: true,
    reconnect: true,
    timeout: 30000,
  },
  uri: getWsUri(),
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }: any) => {
    // @ts-ignore
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      const isJwtExpirationError = graphQLErrors.some((err) =>
        err.message.includes("JWTExpired")
      );
      if (process.env.NODE_ENV !== "production") {
        console.log("graphQLErrors");
        console.log(graphQLErrors);
      }
      if (isJwtExpirationError) {
        return new Observable((observer) => {
          if (process.env.NODE_ENV !== "production") {
            console.log("Observable: retry");
          }
          refreshToken(5)
            .then(() => {
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${keycloak.idToken}`,
                },
              });
              // Retry the operation
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };
              forward(operation).subscribe(subscriber);
            })
            .catch((error) => {
              observer.error(error);
            });
        });
      } else {
        handleGraphQLError(graphQLErrors);
      }
    }
    if (networkError) {
      handleNetworkError(networkError);
    }
    if (!graphQLErrors && !networkError) {
      console.log("Error in ApolloClient");
    }
  }
);

const isProduction = process.env.NODE_ENV === "production";
/**
 * Initialize Apollo Client
 */
const client = new ApolloClient({
  cache,
  link: ApolloLink.from([requestLink, errorLink, link]),
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
  },
  connectToDevTools: !isProduction,
});

export const clientWithCustomErrors = ({ enqueueSnackbar, t }: any) => {
  const errorLinkWithError = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        const isJwtExpirationError = graphQLErrors.some((err) =>
          err.message.includes("JWTExpired")
        );
        if (process.env.NODE_ENV !== "production") {
          console.log("graphQLErrors");
          console.log(graphQLErrors);
        }
        if (isJwtExpirationError) {
          return new Observable((observer) => {
            if (process.env.NODE_ENV !== "production") {
              console.log("Observable: retry");
            }
            refreshToken(5)
              .then(() => {
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${keycloak.idToken}`,
                  },
                });
                // Retry the operation
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };
                forward(operation).subscribe(subscriber);
              })
              .catch((error) => {
                observer.error(error);
              });
          });
        } else {
          handleGraphQLError(graphQLErrors);
        }
      }
      if (networkError && !networkError.message.includes("504")) {
        handleNetworkError(networkError);
        enqueueSnackbar(t("error:566"), {
          preventDuplicate: true,
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      }
      if (!graphQLErrors && !networkError) {
        console.log("Error in ApolloClient");
        Sentry.captureMessage("Error in ApolloClient");
      }
    }
  );

  return new ApolloClient({
    cache,
    link: ApolloLink.from([requestLink, errorLinkWithError, link]),
    defaultOptions: {
      watchQuery: {
        errorPolicy: "all",
      },
      query: {
        errorPolicy: "all",
      },
    },
    connectToDevTools: !isProduction,
  });
};

export default client;
