import React, { useReducer, useMemo, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

import { DocumentTypeFlag } from "domain/documentType/types";
import { DeclarationState } from "pages/Select/Classify/components/FileDialog";

export interface Document {
  id?: string;
  document_type: string;
  pages: string[];
  document_flags: number;
  flags?: number;
  sequenceIndex?: number;
}

export type File = {
  fileNumber: string;
  type: number;
  orgId: number;
  documents: string[];
  declarationData: DeclarationState | null;
};

export type Page = {
  id: string;
  rotate: number;
  isSelected: boolean;
  [key: string]: any;
};

enum SelectingTypes {
  File = "file",
  Document = "document",
}

export interface DocumentState {
  files: {
    [key: string]: File;
  };
  filesArray: Array<string>;
  documents: {
    [key: string]: Document;
  };
  documentsArray: Array<string>;
  selected: {
    type: SelectingTypes;
    id?: string;
  };
}

enum ActionType {
  AddFile = "addFile",
  EditFile = "editFile",
  RemoveFile = "removeFile",
  AddDocument = "addDocument",
  RemoveDocument = "removeDocument",
  ChangeCustomsRelevancy = "changeCustomsRelevancy",
  RequireManualAnnotation = "requireManualAnnotation",
  SelectFile = "selectFile",
  SelectDocument = "selectDocument",
  AddOrgId = "addOrgId",
  AddDeclarationData = "addDeclarationData",
}

type AddFile = {
  fileNumber: string;
  orgId?: number;
  type?: number;
  documents?: Document[];
  fileId?: string;
  declarationData?: DeclarationState | null;
};
type EditFile = {
  fileNumber: string;
  orgId?: number;
  type?: number;
  documents?: Document[];
  fileId?: string;
  declarationData?: DeclarationState | null;
};
type RemoveFile = { fileNumber: string };
type AddDocument = {
  fileNumber?: string;
  document: Document;
};
type RemoveDocument = { fileNumber: string; documentId: string };
type ChangeCustomsRelevancy = { documentId: string };
type SelectFile = { fileNumber: string };
type SelectDocument = { documentId: string };
type AddOrgId = { fileNumber: string; orgId: number };
type AddDeclarationData = {
  fileNumber: string;
  declarationData: DeclarationState | null;
};

interface Action {
  type: ActionType;
  payload:
    | AddFile
    | EditFile
    | RemoveFile
    | AddDocument
    | RemoveDocument
    | ChangeCustomsRelevancy
    | SelectFile
    | SelectDocument
    | AddOrgId
    | AddDeclarationData;
}

interface Actions {
  addFile(file: AddFile): void;
  editFile(file: EditFile): void;
  removeFile(fileNumber: string): void;
  addDocument(document: Document, fileNumber?: string): void;
  removeDocument(id: string, fileNumber?: string): void;
  changeCustomsRelevancy(documentId: string): void;
  requireManualAnnotation(documentId: string): void;
  selectFile(fileNumber: string): void;
  selectDocument(documentId: string): void;
  addOrgId(fileNumber: string, orgId: number): void;
  addDeclarationData(
    fileNumber: string,
    declarationData: DeclarationState | null
  ): void;
}

type NestedData = {
  fileNumber: string;
  type: number;
  orgId: number;
  documents: Document[];
};

interface Getters {
  getClassifiedPages(): string[];
  getPageDocument(pageId: string): Document | undefined;
  getDataAsNested(): NestedData[];
}

const documentReducer: React.Reducer<DocumentState, Action> = (
  state,
  action
) => {
  const newState = { ...state };

  const removeDocument = (documentId: string) => {
    const { fileNumber } = action.payload as RemoveDocument;
    const { files, documents, documentsArray, selected } = newState;

    const file = files[fileNumber];

    const { document_type } = documents[documentId];
    delete documents[documentId];

    const updatedFileDocuments = file.documents.filter(
      (document) => document !== documentId
    );
    const updatedDocuments = documentsArray.filter(
      (document) => document !== documentId
    );

    file.documents = updatedFileDocuments;
    newState.documentsArray = updatedDocuments;

    let count = 0;
    updatedFileDocuments.forEach((_documentId) => {
      const document = documents[_documentId];
      if (document.document_type === document_type) {
        newState.documents[_documentId].sequenceIndex = count++;
      }
    });

    if (selected.id === documentId) {
      newState.selected = {
        type: SelectingTypes.File,
        id: file.fileNumber,
      };
    }

    return newState;
  };

  switch (action.type) {
    case ActionType.AddFile: {
      const { fileNumber, documents = [] } = action.payload as AddFile;
      if (!(fileNumber in state.files)) {
        // @ts-ignore
        newState.files[fileNumber] = {
          ...action.payload,
          documents: documents.map((document) => document.id as string),
        };
        if (!newState.filesArray.includes(fileNumber)) {
          newState.filesArray.push(fileNumber);
        }
      }
      newState.selected = {
        type: SelectingTypes.File,
        id: fileNumber,
      };

      return newState;
    }
    case ActionType.EditFile: {
      const payload = action.payload as EditFile;
      // @ts-ignore
      newState.files[payload.fileNumber] = {
        ...newState.files[payload.fileNumber],
        declarationData: payload.declarationData ?? null,
      };

      return newState;
    }
    case ActionType.RemoveFile: {
      const { fileNumber } = action.payload as RemoveFile;
      const { documents } = newState.files[fileNumber];

      documents.forEach(removeDocument);

      delete newState.files[fileNumber];
      newState.filesArray = newState.filesArray.filter(
        (fileId) => fileId !== fileNumber
      );

      if (newState.selected.id === fileNumber) {
        newState.selected = {
          type: SelectingTypes.File,
          id: undefined,
        };
      }

      return newState;
    }
    case ActionType.AddDocument: {
      const { fileNumber, document } = action.payload as AddDocument;
      const { files, documents, documentsArray } = newState;

      // FIXME: Delete uuidv4 in future
      const { id = uuidv4(), document_type } = document;
      const isDocumentAlreadyAdded = documentsArray.includes(id);

      let sequenceIndex = 0;
      if (fileNumber) {
        const file = files[fileNumber];
        const fileDocuments = file.documents;

        sequenceIndex = fileDocuments.filter((documentId) => {
          const document = documents[documentId];
          return document.document_type === document_type;
        }).length;

        if (!isDocumentAlreadyAdded) {
          fileDocuments.push(id);
        }
      } else {
        sequenceIndex = documentsArray.filter((documentId) => {
          const document = documents[documentId];
          return document.document_type === document_type;
        }).length;

        if (!isDocumentAlreadyAdded) {
          documentsArray.push(id);
        }
      }

      documents[id] = {
        id,
        sequenceIndex,
        ...document,
      };

      return newState;
    }
    case ActionType.RemoveDocument: {
      const { documentId } = action.payload as RemoveDocument;
      return removeDocument(documentId);
    }
    case ActionType.ChangeCustomsRelevancy: {
      const { documentId } = action.payload as ChangeCustomsRelevancy;
      const document = newState.documents[documentId];
      const { flags = 0, document_flags = 0 } = document;
      if (
        (DocumentTypeFlag.custom_relevant & (document_flags || 0)) ===
        DocumentTypeFlag.custom_relevant
      ) {
        return newState;
      }

      newState.documents[documentId].flags =
        DocumentTypeFlag.custom_relevant & flags
          ? DocumentTypeFlag.custom_relevant & ~flags
          : DocumentTypeFlag.custom_relevant | flags;
      return newState;
    }
    case ActionType.RequireManualAnnotation: {
      const { documentId } = action.payload as ChangeCustomsRelevancy;
      const doc = newState.documents[documentId];
      const { flags = 0, document_flags = 0 } = doc;
      if (
        (DocumentTypeFlag.manual_annotation_required &
          (document_flags || 0)) ===
        DocumentTypeFlag.manual_annotation_required
      ) {
        return newState;
      }

      newState.documents[documentId].flags =
        DocumentTypeFlag.manual_annotation_required & flags
          ? DocumentTypeFlag.manual_annotation_required & ~flags
          : DocumentTypeFlag.manual_annotation_required | flags;
      return newState;
    }
    case ActionType.SelectFile: {
      const { fileNumber } = action.payload as SelectFile;
      newState.selected = {
        type: SelectingTypes.File,
        id: fileNumber,
      };
      return newState;
    }
    case ActionType.SelectDocument: {
      const { documentId } = action.payload as SelectDocument;
      newState.selected = {
        type: SelectingTypes.Document,
        id: documentId,
      };
      return newState;
    }
    case ActionType.AddOrgId: {
      const { fileNumber, orgId } = action.payload as AddOrgId;
      if (orgId) {
        newState.files[fileNumber].orgId = orgId;
      }
      return newState;
    }
    case ActionType.AddDeclarationData: {
      const { fileNumber, declarationData } =
        action.payload as AddDeclarationData;
      if (declarationData) {
        newState.files[fileNumber].declarationData = declarationData;
      }
      return newState;
    }
    default:
      return state;
  }
};

const useClassifier = (
  initialState: Partial<DocumentState> = {}
): [DocumentState, Actions, Getters] => {
  const reducerInitialState = {
    documents: {},
    documentsArray: [],
    files: {},
    filesArray: [],
    selected: {
      type: SelectingTypes.File,
      id: undefined,
    },
    ...initialState,
  };
  const [state, dispatch] = useReducer(documentReducer, reducerInitialState);

  const actions: Actions = useMemo(
    () => ({
      addFile: (file) => {
        dispatch({
          type: ActionType.AddFile,
          payload: file,
        });
      },
      editFile: (file) => {
        dispatch({
          type: ActionType.EditFile,
          payload: file,
        });
      },
      removeFile: (fileNumber) => {
        dispatch({
          type: ActionType.RemoveFile,
          payload: {
            fileNumber,
          },
        });
      },
      addDocument: (document, fileNumber) => {
        dispatch({
          type: ActionType.AddDocument,
          payload: {
            fileNumber,
            document,
          },
        });
      },
      removeDocument: (id, fileNumber) => {
        dispatch({
          type: ActionType.RemoveDocument,
          payload: {
            fileNumber,
            documentId: id,
          },
        });
      },
      changeCustomsRelevancy: (id) => {
        dispatch({
          type: ActionType.ChangeCustomsRelevancy,
          payload: {
            documentId: id,
          },
        });
      },
      requireManualAnnotation: (id) => {
        dispatch({
          type: ActionType.RequireManualAnnotation,
          payload: {
            documentId: id,
          },
        });
      },
      selectFile: (fileNumber) => {
        dispatch({
          type: ActionType.SelectFile,
          payload: {
            fileNumber,
          },
        });
      },
      selectDocument: (id) => {
        dispatch({
          type: ActionType.SelectDocument,
          payload: {
            documentId: id,
          },
        });
      },
      addOrgId: (fileNumber, orgId) => {
        dispatch({
          type: ActionType.AddOrgId,
          payload: {
            fileNumber,
            orgId,
          },
        });
      },
      addDeclarationData: (fileNumber, declarationData) => {
        dispatch({
          type: ActionType.AddDeclarationData,
          payload: {
            fileNumber,
            declarationData,
          },
        });
      },
    }),
    []
  );

  const _getAllDocuments = useCallback(() => {
    const fileDocuments = state.filesArray.reduce<string[]>((acc, fileId) => {
      const file = state.files[fileId];
      return acc.concat(file.documents);
    }, []);
    return state.documentsArray.concat(fileDocuments);
  }, [state.documentsArray, state.files, state.filesArray]);

  const getClassifiedPages = useCallback(() => {
    const documents = _getAllDocuments();
    return documents.reduce<string[]>((acc, documentId) => {
      const document = state.documents[documentId];
      return acc.concat(document.pages);
    }, []);
  }, [_getAllDocuments, state.documents]);

  const getPageDocument = useCallback(
    (pageId: string) => {
      const { type, id } = state.selected;

      if (id === undefined) {
        return;
      }

      let documents = [id];
      if (type === SelectingTypes.File) {
        documents = state.files[id] ? state.files[id].documents : [];
      }

      let document = undefined;
      documents.forEach((documentId) => {
        const _document = state.documents[documentId];
        if (_document.pages.includes(pageId)) {
          document = _document;
        }
      });

      return document;
    },
    [state.documents, state.files, state.selected]
  );

  const getDataAsNested = useCallback(() => {
    return state.filesArray.map((fileId) => {
      const file = state.files[fileId];

      return {
        ...file,
        documents: file.documents.map(
          (documentId) => state.documents[documentId]
        ),
      };
    });
  }, [state.documents, state.files, state.filesArray]);

  return [
    state,
    actions,
    {
      getClassifiedPages,
      getPageDocument,
      getDataAsNested,
    },
  ];
};

export default useClassifier;
