import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const UPDATE_IMPORT_LIST_TRIGGER = gql`
  mutation UPDATE_IMPORT_LIST_TRIGGER(
    $id: Int!
    $set: import_list_trigger_set_input!
    $append: import_list_trigger_append_input!
  ) {
    update_import_list_trigger_by_pk(
      pk_columns: { id: $id }
      _set: $set
      _append: $append
    ) {
      id
      type
      config
      creation_user
      creation_date
      last_modified_user
      last_modified_date
      meta
    }
  }
`;

const useUpdateImportListTrigger = () => {
  const [updateTenant] = useMutation(UPDATE_IMPORT_LIST_TRIGGER);

  return updateTenant;
};

export default useUpdateImportListTrigger;
