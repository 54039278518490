import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useAppendDocumentMeta from "./useAppendDocumentMeta";

const parseSearchParams = (search: string) => {
  const result: { [key: string]: string } = {};
  search
    .substring(1)
    .split("&")
    .forEach((param) => {
      const [key, value] = param.split("=");
      if (!key) return;

      result[decodeURIComponent(key)] = decodeURIComponent(value);
    });

  return result;
};

const useRossumValidatedDocId = () => {
  const { hash } = useLocation();
  const updateDocument = useAppendDocumentMeta();

  useEffect(() => {
    const { docId } = parseSearchParams(hash);

    if (!docId) return;
    updateDocument(docId, { is_validated: true });
  }, [hash, updateDocument]);
};

export default useRossumValidatedDocId;
