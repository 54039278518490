import { gql } from "@apollo/client";

export const INSERT_ADDRESS = gql`
  mutation INSERT_ADDRESS($objects: [address_insert_input!]!) {
    insert_address(objects: $objects) {
      affected_rows
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation UPDATE_ADDRESS($id: Int, $values: address_set_input) {
    update_address(where: { id: { _eq: $id } }, _set: $values) {
      affected_rows
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation DELETE_ADDRESS($id: Int) {
    delete_address(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
