import React, { FC, useState } from "react";
import { AlertDialog } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import useExportDeclaration from "graphql/hooks/useExportDeclaration";

interface ExportToCevConfirmDialogProps {
  file_id: string;
  dr_no: number;
  onClose: () => void;
}

const ExportToCevConfirmDialog: FC<ExportToCevConfirmDialogProps> = ({
  file_id,
  dr_no,
  onClose,
}) => {
  const { t } = useTranslation("declarationNew");
  const [isBusy, setIsBusy] = useState(false);

  const exportDeclaration = useExportDeclaration();

  const handleConfirm = () => {
    setIsBusy(true);
    exportDeclaration(file_id, dr_no).then(() => {
      setIsBusy(false);
      onClose();
    });
  };

  return (
    <AlertDialog
      variant="warning"
      onClose={onClose}
      onConfirm={handleConfirm}
      ConfirmButtonProps={{
        loading: isBusy,
      }}
    >
      {t("Are you sure that you want to trigger the export to CeV?")}
    </AlertDialog>
  );
};

export default ExportToCevConfirmDialog;
