import gql from "graphql-tag";

export const GET_ADDRESSES_QUERY = gql`
  query AditGetAddresses($search: String, $limit: Int = 10, $ids: [Int!]) {
    addresses: getAddressesByFrequency(
      search: $search
      limit: $limit
      ids: $ids
    ) {
      id
      companyName
      companyName2
      country
      city
      street
      zipcode
      uidNumber
      isLocked
      usageFrequency
      customsAccountNumber
      traderIdentificationNumber
      vatAccountNumber
      uidNumber
      isLocked
      reference
      isPlatformUser
    }
  }
`;
