import React, { FC } from "react";
import {
  AlertDialog,
  Box,
  Button,
  IconButton,
  IconButtonProps,
  Link,
  Popover,
  Typography,
} from "@periplus/ui-library";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { useTranslation } from "react-i18next";
import gql from "graphql-tag";
import { useAditCopyDeclarationAndGoodsItemsMutation } from "graphql/generated";

gql`
  mutation AditCopyDeclarationAndGoodsItems(
    $sourceFileId: String!
    $sourceDrNo: Int!
    $targetFileId: String!
    $targetDrNo: Int!
  ) {
    copyDeclarationAndGoodsItems(
      copyDeclarationInput: {
        sourceFileId: $sourceFileId
        sourceDrNo: $sourceDrNo
        targetFileId: $targetFileId
        targetDrNo: $targetDrNo
      }
    )
  }
`;

interface SimilarityIconButtonProps extends IconButtonProps {
  file_id: string;
  dr_no: number;
  ai_results: {
    file_id: string;
    dr_no: number;
    mrs_number: string;
    similarity: number;
  }[];
  onCopy: () => Promise<any>;
}

const SimilarityIconButton: FC<SimilarityIconButtonProps> = ({
  file_id,
  dr_no,
  ai_results,
  onCopy,
}) => {
  const { t } = useTranslation("declarationNew");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [sourceDeclaration, setSourceDeclaration] = React.useState<{
    file_id: string;
    dr_no: number;
  } | null>(null);

  const [copyDeclaration, { loading }] =
    useAditCopyDeclarationAndGoodsItemsMutation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        onClick={handleClick}
        tooltip={t("List of declarations with high similarity")}
      >
        <ManageSearchIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            px: 1.5,
            py: 1,
          },
        }}
      >
        {!ai_results.length ? (
          <Typography sx={{ p: 1 }}>{t("common:No results")}</Typography>
        ) : (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto auto auto",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography variant="subtitle1">{t("Declaration")}</Typography>
            <Typography variant="subtitle1">{t("Similarity")}</Typography>
            <Box></Box>
            {ai_results.map((el) => (
              <React.Fragment key={`${el.file_id} ${el.dr_no}`}>
                <Link
                  href={`/select/declaration/${el.file_id}/${el.dr_no}`}
                  underline="none"
                  target="_blank"
                >
                  {el.mrs_number}
                </Link>
                <Typography sx={{ justifySelf: "center" }}>
                  {(el.similarity * 100).toFixed(1)}%
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    setSourceDeclaration({
                      file_id: el.file_id,
                      dr_no: el.dr_no,
                    })
                  }
                >
                  {t("common:Copy")}
                </Button>
              </React.Fragment>
            ))}
          </Box>
        )}
      </Popover>
      {sourceDeclaration && (
        <AlertDialog
          onClose={() => setSourceDeclaration(null)}
          variant={"warning"}
          onConfirm={async () => {
            await copyDeclaration({
              variables: {
                sourceFileId: sourceDeclaration.file_id,
                sourceDrNo: sourceDeclaration.dr_no,
                targetFileId: file_id,
                targetDrNo: dr_no,
              },
            });
            await onCopy();
            setSourceDeclaration(null);
            setAnchorEl(null);
          }}
          ConfirmButtonProps={{
            loading,
          }}
        >
          <Typography variant="subtitle1">
            {t(
              "Data of this declaration will be copied to current declaration. All unsaved data will be lost"
            )}
          </Typography>
          <Typography variant="h6">
            {t("Are you sure you want to continue?")}
          </Typography>
        </AlertDialog>
      )}
    </>
  );
};

export default SimilarityIconButton;
