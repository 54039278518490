import React from "react";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { AuthProvider } from "contexts/AuthContext";
import { SasProvider } from "contexts/SasContext";
import Routes from "./Routes";
import { useSnackbar } from "notistack";
import { ApolloProvider } from "@apollo/client";
import { clientWithCustomErrors } from "graphql/client";
import { LayoutProvider } from "@periplus/ui-library";
import { ApplicationProvider } from "contexts/ApplicationContext";
import { useTranslation } from "react-i18next";
import { UiLibraryProvider, theme, CssBaseline } from "@periplus/ui-library";
import { ThemeProvider } from "@mui/material";
import { LanguageType } from "../i18n";

const App: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <ApplicationProvider>
      <ApolloProvider client={clientWithCustomErrors({ enqueueSnackbar, t })}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <UiLibraryProvider language={language as LanguageType}>
            <AuthProvider>
              <SasProvider>
                <BrowserRouter>
                  <LayoutProvider>
                    <Routes />
                  </LayoutProvider>
                </BrowserRouter>
              </SasProvider>
            </AuthProvider>
          </UiLibraryProvider>
        </ThemeProvider>
      </ApolloProvider>
    </ApplicationProvider>
  );
};

export default Sentry.withProfiler(App);
