import React, { useEffect, useMemo } from "react";
import { Theme } from "@periplus/ui-library";
import { useAuth } from "contexts/AuthContext";
import Progress from "components/Progress";
import { Text } from "../../components/Text";
import { Button } from "@periplus/ui-library";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    container: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    logout: {
      marginTop: theme.spacing(1),
    }
  }));

const Login = () => {
  const { classes } = useStyles();
  const { loginWithRedirect, isAuthenticated, isAuthorized, loading, logout } =
    useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: { targetUrl: "/" },
      });
    }
  }, [loginWithRedirect, isAuthenticated]);

  const renderContent = useMemo(() => {
    if (loading) return <Progress />;
    if (isAuthenticated && !isAuthorized) {
      return (
        <div>
          <Text variant="h3">error:unauthorizedTitle</Text>
          <Text>error:unauthorizedDescription1</Text>
          <Text>error:unauthorizedDescription2</Text>
          <Button
            color="primary"
            variant="contained"
            className={classes.logout}
            onClick={() => logout()}
          >
            <Text>common:logout</Text>
          </Button>
        </div>
      );
    }
    return null;
  }, [loading, isAuthenticated, isAuthorized]);

  return <div className={classes.container}>{renderContent}</div>;
};

export default Login;
