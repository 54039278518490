import React, { useCallback, useState } from "react";
import { Typography, Grid, useMediaQuery, Theme } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import PeriPlus from "components/Icons/PeriPlus";
import ProductLogo from "./components/ProductLogo";
import UserRegisterForm from "./components/UserRegisterForm";
import NativeLanguageSelector from "./components/NativeLanguageSelector";
import bubble from "./images/bubble.svg";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  pageContainer: {
    padding: 18,
    backgroundColor: "#f3f4fb",
    height: "100vh",
  },
  contentContainer: {
    boxShadow: "0px 4px 15px rgb(34 30 205 / 10%)",
    borderRadius: 6,
    backgroundColor: "#fff",
    overflow: "hidden",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      minWidth: 350,
    },

    [theme.breakpoints.up("md")]: {
      maxWidth: 830,
    },
  },
  rightColumn: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 236px)",
    },
    paddingTop: 48,
    paddingLeft: 48,
    paddingRight: 48,
    paddingBottom: 96,
    backgroundImage: `url(${bubble})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom right",

    "& *": {
      fontFamily: '"Open Sans"',
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    color: "#ff5d79",
    mixBlendMode: "normal",
    marginTop: 16,
    fontSize: "1.625rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.125rem",
    },
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    color: "#737a96",
    mixBlendMode: "normal",
    marginBottom: 42,
  },
  companyLogoContainer: {
    textAlign: "center",
    marginTop: 20,

    "& svg": {
      height: 30,
    },
  },
  registrationSuccessTitlesContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  registrationSuccessTitle: {
    fontSize: 24,
  },
  registrationSuccessSubtitle: {
    fontSize: 18,
    color: "#737A96",
  },
}));

const Register = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const [registraionSuccess, setRegistrationSuccess] = useState(false);

  const handleRegistrationSuccess = useCallback(() => {
    setRegistrationSuccess(true);
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LeG1MgfAAAAANEaL43wvqg43GZ3wLZJKwcQ0WS7">
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        className={classes.pageContainer}
        wrap="nowrap"
      >
        <Grid container className={classes.contentContainer}>
          {isDesktop && <ProductLogo />}
          <Grid container direction="column" className={classes.rightColumn}>
            {registraionSuccess ? (
              <div className={classes.registrationSuccessTitlesContainer}>
                <Typography
                  className={classes.registrationSuccessTitle}
                  variant="h4"
                  color="primary"
                >
                  {t("registration:You have successfully registered!")}
                </Typography>
                <Typography
                  className={classes.registrationSuccessSubtitle}
                  variant="h5"
                >
                  {t(
                    "registration:Soon you will be redirected to the login page..."
                  )}
                </Typography>
              </div>
            ) : (
              <>
                <Grid container justifyContent="flex-end">
                  <NativeLanguageSelector />
                </Grid>
                <Typography variant="h4" className={classes.title}>
                  {t("registration:contact")}
                </Typography>
                <Typography variant="h6" className={classes.subTitle}>
                  {t("registration:details")}
                </Typography>
                <Grid item>
                  <UserRegisterForm
                    onSuccessRegistration={handleRegistrationSuccess}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item className={classes.companyLogoContainer}>
          <PeriPlus />
        </Grid>
      </Grid>
    </GoogleReCaptchaProvider>
  );
};

export default Register;
