import React, { ReactElement } from "react";
import {
  TableContainer,
  Theme,
  MuiTable,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  IconButton,
} from "@periplus/ui-library";
import ReactDataSheet from "react-datasheet";
// Be sure to include styles at some point, probably during your bootstrapping
import "react-datasheet/lib/react-datasheet.css";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    maxHeight: "100%",
  },
  tableContainer: {
    width: "auto",
    "& td.cell > .data-editor": {
      height: "100%",
      width: "100%",
    },
    "& .cell > .value-viewer": {
      maxHeight: 100,
      overflow: "auto",
    },
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    display: "flex",
    zIndex: theme.zIndex.appBar,
  },
  tableCell: {
    minWidth: 120,
    display: "inline-flex",
    padding: "0 16px",
    "&:last-child": {
      minWidth: 0,
    },
  },
  tableCellContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  tableCellContent: {
    width: "100%",
  },
  resizer: {
    width: 24,
    position: "absolute",
    top: 0,
    bottom: 0,
    right: -12,
    alignSelf: "stretch",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "col-resize",
    color: "rgba(224, 224, 224, 1)",
    zIndex: 100,
    "& svg": {
      userSelect: "none",
    },
    "&:hover": {
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
}));

class MyReactDataSheet<T, V> extends ReactDataSheet<T, V> {}

interface Props<T, V> extends ReactDataSheet.DataSheetProps<T, V> {
  columns: { key: string; translationKey: string }[];
  renderAction?(row: number): ReactElement;
  /**
   * @deprecated Use renderAction instead
   * @param row
   */
  onRemoveRow?(row: number): void;
}

const DataSheetTable = <T, V>({
  columns,
  onRemoveRow,
  renderAction,
  ...props
}: Props<T, V>) => {
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  return (
    <TableContainer className={classes.container}>
      <MyReactDataSheet
        sheetRenderer={(props) => (
          <MuiTable
            className={cx([props.className, classes.tableContainer])}
            stickyHeader
            size="small"
          >
            <TableHead>
              <TableRow>
                {columns.map((col) => (
                  <TableCell key={col.key}>{t(col.translationKey)}</TableCell>
                ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{props.children}</TableBody>
          </MuiTable>
        )}
        rowRenderer={(props) => {
          return (
            <tr>
              {props.children}
              {!!renderAction && (
                <td className="cell action-cell">{renderAction(props.row)}</td>
              )}
              {!!onRemoveRow && (
                <td className="cell action-cell">
                  <IconButton
                    size="small"
                    onClick={() => {
                      onRemoveRow(props.row);
                    }}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </td>
              )}
            </tr>
          );
        }}
        {...props}
      />
    </TableContainer>
  );
};

export default DataSheetTable;
