import React, { FC } from "react";
import Table from "../components/Table";

interface ClassifiedProps {}

const Classified: FC<ClassifiedProps> = () => {
  return <Table classified />;
};

export default Classified;
