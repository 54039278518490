import React, { useCallback, useState } from "react";
import {
  ActionBar,
  ActionBarItem,
  Button,
  Search,
  PageContainer,
  Theme,
} from "@periplus/ui-library";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useTranslation } from "react-i18next";
import { Address } from "graphql/hooks/useGetAddress";
import AddressManagementTable from "./components/AddressManagementTable";
import useUrlSearchParams from "hooks/useUrlSearchParams";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  page: {
    maxWidth: 1200,
    margin: "0 auto",
  },
  searchBox: {
    maxWidth: 140,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(1.5),
  },
}));

type AddressManagementProps = {};

const AddressManagement = (props: AddressManagementProps) => {
  const [addressToEdit, setAddressToEdit] = useState<
    Address | undefined | null
  >();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams<{
    page: number;
    search?: string;
  }>({
    page: 1,
  });

  const handleEdit = useCallback(
    (addressToEdit?: Address | null) => {
      setAddressToEdit(addressToEdit);
    },
    [setAddressToEdit]
  );

  const handleCreate = () => {
    setAddressToEdit(null);
  };

  const onChangePage = useCallback(
    (newPage) => {
      setUrlSearchParams({
        page: newPage,
        search: urlSearchParams.search,
      });
    },
    [urlSearchParams.search]
  );

  return (
    <PageContainer title={t("navigation:address-management")}>
      <ActionBar
        justifyContent="flex-end"
        sx={{
          mb: 2,
        }}
      >
        <ActionBarItem>
          <Search
            value={urlSearchParams.search}
            onChange={(newSearch) =>
              setUrlSearchParams({ search: newSearch, page: 1 })
            }
            className={classes.searchBox}
          />
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowRightAltIcon />}
            className={classes.button}
            onClick={handleCreate}
          >
            {t("common:createNew")}
          </Button>
        </ActionBarItem>
      </ActionBar>
      <AddressManagementTable
        addressToEdit={addressToEdit}
        selectAddressToEdit={handleEdit}
        page={urlSearchParams.page}
        search={urlSearchParams.search}
        onChangePage={onChangePage}
      />
    </PageContainer>
  );
};

export default AddressManagement;
