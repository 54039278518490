import { Document } from "domain/document/types";

export type File = {
  id: string;
  identification: string;
  creation_user: string;
  meta: string[];
  name: string;
  file_status: string;
  file_flags: number;
  creation_date: string;
  file_url: string;
  org_id?: number;
  org_id_description?: string;
  documents: Document[];
  displayName: string;
  typeName: string;
  last_modified_date: string;
};

export type EmailView = {
  id: string;
  meta: any;
  name: string;
  file_status: string;
  creation_date: string;
  file_url: string;
  creation_user: string;
  company: string;
  sender: string;
  total_rows: number;
  documents: Document[];
};

export enum FileFlag {
  "file" = 0,
  "journey" = 1,
  "folder" = 2,
  "IS_EMAIL" = 4,
  "IS_DECLARATION" = 8,
}
