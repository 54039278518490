import gql from "graphql-tag";

export const TOGGLE_AI_CONFIRMED = gql`
  mutation ToggleAIConfirmed(
    $file_id: uuid!
    $dr_no: smallint!
    $value: Boolean!
  ) {
    update_declaration(
      where: { file_id: { _eq: $file_id }, dr_no: { _eq: $dr_no } }
      _set: { ai_confirmed: $value }
    ) {
      affected_rows
    }
  }
`;
