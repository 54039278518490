import React, { FC } from "react";
import {
  Panel,
  Theme,
  useMediaQuery,
  Typography,
  FormTextField,
  Button,
  FormAutocomplete,
} from "@periplus/ui-library";
import { Form } from "formik";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useTranslation } from "react-i18next";
import TenantFormik from "../TenantFormik";
import { ADDRESS_TYPES } from "domain/address/types";
import countries from "assets/countries";
import { Tenant } from "domain/tenant/type";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  panel: {
    width: "100%",
    maxWidth: 1200,
    "&:not(:last-child)": {
      marginBottom: 16,
    },
  },
  form: {
    display: "grid",
    gap: 12,
    gridTemplate: `
      "company"
      "companyGrid"
      "address"
      "addressGrid"
    `,
    [theme.breakpoints.up("sm")]: {
      gridTemplate: `
        "company  companyGrid"
        "address  addressGrid"
        /4fr      6fr
      `,
    },
    [theme.breakpoints.up("md")]: {
      gridTemplate: `
        "company  companyGrid"
        "address  addressGrid"
        /4fr      8fr
      `,
    },
  },
  company: { gridArea: "company" },
  companyGrid: {
    gridArea: "companyGrid",
    display: "grid",
    gap: 12,
    gridTemplate: `
        "name"
        "type"
        "traderIdentificationNumber"
    `,
    [theme.breakpoints.up("md")]: {
      gridTemplate: `
        "name ."
        "type traderIdentificationNumber"
    `,
    },
  },
  name: { gridArea: "name" },
  type: { gridArea: "type" },
  traderIdentificationNumber: { gridArea: "traderIdentificationNumber" },
  address: { gridArea: "address" },
  addressGrid: {
    gridArea: "addressGrid",
    display: "grid",
    gap: 12,
    gridTemplate: `
        "countryInput countryInput  countryInput  countryInput  countryInput      countryInput"
        "streetInput  streetInput   streetInput   streetInput   houseNumberInput  houseNumberInput"
        "zipCodeInput zipCodeInput  zipCodeInput  ortInput      ortInput          ortInput"
    `,
    gridTemplateColumns: "repeat(6, 1fr)",
    [theme.breakpoints.up("md")]: {
      gridTemplate: `
        "countryInput countryInput      .             ."
        "streetInput  houseNumberInput  zipCodeInput  ortInput"
        /4fr          2fr               3fr           3fr
      `,
    },
  },
  countryInput: { gridArea: "countryInput" },
  streetInput: { gridArea: "streetInput" },
  houseNumberInput: { gridArea: "houseNumberInput" },
  zipCodeInput: { gridArea: "zipCodeInput" },
  ortInput: { gridArea: "ortInput" },
}));

const GeneralTab: FC<{
  tenant: Tenant;
  onSave: () => void;
}> = ({ tenant, onSave }) => {
  const { classes } = useStyles();
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const { t } = useTranslation();

  return (
    <TenantFormik tenant={tenant} onSubmit={onSave}>
      {({ submitForm, isSubmitting, isValid, dirty, errors }: any) => (
        <Panel
          className={classes.panel}
          title={t("company")}
          controls={
            <Button
              variant="contained"
              color="primary"
              onClick={submitForm}
              disabled={isSubmitting || !dirty || !isValid}
              loading={isSubmitting}
              endIcon={isTablet && <SaveOutlinedIcon />}
            >
              {isTablet ? t("save") : <SaveOutlinedIcon />}
            </Button>
          }
        >
          <Form className={classes.form}>
            <Typography
              className={classes.company}
              variant="subtitle1"
              color="textSecondary"
            >
              {t("Company")}
            </Typography>
            <div className={classes.companyGrid}>
              <FormTextField
                className={classes.name}
                name="tenant_name"
                label={t("Name")}
                required
              />
              <FormAutocomplete
                className={classes.type}
                name="addressType"
                fullWidth
                options={ADDRESS_TYPES}
                getOptionLabel={(option) => option.name}
                InputProps={{
                  required: true,
                  variant: "outlined",
                  label: t("CompanyType"),
                }}
              />
              <FormTextField
                className={classes.traderIdentificationNumber}
                name="traderIdentificationNumber"
                variant="outlined"
                fullWidth
                label={t("Trader Identification Number")}
                required
              />
            </div>
            <Typography
              className={classes.address}
              variant="subtitle1"
              color="textSecondary"
            >
              {t("address")}
            </Typography>
            <div className={classes.addressGrid}>
              <FormAutocomplete
                className={classes.countryInput}
                name="country"
                options={countries}
                InputProps={{
                  required: true,
                  variant: "outlined",
                  label: t("country"),
                }}
              />
              <FormTextField
                className={classes.streetInput}
                name="street"
                label={t("address")}
                required
              />
              <FormTextField
                name="street_number"
                className={classes.houseNumberInput}
                label={t("tenant:nr.")}
                required
              />
              <FormTextField
                className={classes.zipCodeInput}
                name="zipcode"
                label={t("tenant:postcode")}
                required
              />
              <FormTextField
                className={classes.ortInput}
                name="city"
                label={t("tenant:city")}
                required
              />
            </div>
          </Form>
        </Panel>
      )}
    </TenantFormik>
  );
};

export default GeneralTab;
