import React, { FC, useState } from "react";
import { Loader, Tabs, PageContainer } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import GeneralTab from "./GeneralTab";
import UsersTab from "./UsersTab";
import DocumentTypesTab from "./DocumentTypesTab";
import useGetTenant from "domain/tenant/useGetTenant";

interface TenantProps {}
interface UrlParams {
  id: string;
}

const Tenant: FC<TenantProps> = () => {
  const { t } = useTranslation();
  const { id } = useParams<UrlParams>();
  const [selectedTab, setSelectedTab] = useState("general");

  const tabs = [
    { value: "general", label: t("general") },
    { value: "users", label: t("users") },
    { value: "document-types", label: t("documentTypes") },
  ];

  const { data: { tenant } = {}, loading, refetch } = useGetTenant({ id });

  return (
    <PageContainer title={tenant?.tenant_name} loading={loading}>
      <Tabs
        tabs={tabs}
        selected={selectedTab}
        onChange={(newTab) => setSelectedTab(newTab)}
        sx={{
          mb: 2,
        }}
      />
      {!tenant ? (
        <Loader />
      ) : (
        (() => {
          switch (selectedTab) {
            case "general":
            default:
              return <GeneralTab tenant={tenant} onSave={() => refetch()} />;
            case "users":
              return <UsersTab tenant={tenant} />;
            case "document-types":
              return <DocumentTypesTab tenant={tenant} />;
          }
        })()
      )}
    </PageContainer>
  );
};

export default Tenant;
