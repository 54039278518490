import React, { FC } from "react";
import { Box, BoxProps, Paper, Theme, combineSX } from "@periplus/ui-library";

interface PanelProps extends BoxProps {
  title?: string;
  columns: number;
}

const Panel: FC<PanelProps> = ({ children, title, columns, sx }) => {
  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={combineSX(
        (theme: Theme) => ({
          display: "grid",
          gridTemplateColumns: `repeat(${columns}, auto)`,
          alignItems: "end",
          gap: 1,
          backgroundColor: theme.palette.grey1.main,
          p: 1,
          position: "relative",
        }),
        sx
      )}
    >
      {title && (
        <Box
          sx={{
            position: "absolute",
            top: -6,
            left: "50%",
            transform: "translate(-50%, 0)",
            fontWeight: 500,
            fontSize: "10px",
            lineHeight: "10px",
            letterSpacing: "0.25px",
            zIndex: 1,
            whiteSpace: "nowrap",
          }}
        >
          <Box
            sx={(theme) => ({
              position: "absolute",
              top: "50%",
              left: "-4px",
              height: "1px",
              width: "calc(100% + 8px)",
              backgroundColor: theme.palette.grey1.main,
              zIndex: -1,
            })}
          />
          {title}
        </Box>
      )}
      {children}
    </Paper>
  );
};

export default Panel;
