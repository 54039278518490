import React, { FC } from "react";
import { Typography } from "@periplus/ui-library";
import { makeStyles } from "tss-react/mui";

interface IColoredProps extends React.PropsWithChildren {
  color: string;
  size?: number;
}

const useStyles = makeStyles<IColoredProps>()((theme, { size, color }) => ({
  dot: {
    width: size,
    height: size,
    minWidth: size,
    minHeight: size,
    position: "relative",
    textAlign: "center",
    padding: theme.spacing(0.5),
    backgroundColor: color,
    borderRadius: "50%",
    display: "inline-block",
  },
  number: {
    backgroundColor: "inherit",
    fontWeight: 500,
    color: "transparent",
    WebkitBackgroundClip: "text",
    filter: `invert(1) grayscale(10) contrast(10) saturate(10)`,
  },
}));

const Colored: FC<IColoredProps> = ({ color, size = 26, children }) => {
  const { classes } = useStyles({ color, size });

  return (
    <div className={classes.dot}>
      {children && (
        <Typography className={classes.number}>{children}</Typography>
      )}
    </div>
  );
};

export default Colored;
