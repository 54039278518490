import { useCallback } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ApolloQueryResult } from "@apollo/client";

const useErrorHandling = <T = any>() => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation() as {
    t: any;
  };
  const withErrorHandling = useCallback(
    async (callback, ...args) => {
      try {
        return (await callback.apply(callback, args)) as ApolloQueryResult<T>;
      } catch (e) {
        enqueueSnackbar(t("common:serverError"), { variant: "error" });
        return Promise.reject(e);
      }
    },
    [enqueueSnackbar, t]
  );

  return withErrorHandling;
};

export default useErrorHandling;
