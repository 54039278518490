import React, { FC, HtmlHTMLAttributes, useState, useEffect } from "react";
import {
  FilterMenu as LuiFilterMenu,
  Autocomplete,
  Theme,
} from "@periplus/ui-library";
import UserAutocomplete from "domain/user/components/UserAutocomplete";
import { useTranslation } from "react-i18next";
import { TASKS_PRIORITIES, TASKS_TYPES, TASKS_CREATED } from "../constants";
import { User } from "graphql/hooks/useGetUsers";
import { Filters } from "../Tasks";
import { makeStyles } from 'tss-react/mui';

interface FilterMenuProps extends HtmlHTMLAttributes<HTMLElement> {
  filters: Filters;
  onChangeFilters: (newFilters: Filters) => void;
  onClearFilters: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  popoverContent: {
    display: "grid",
    gap: 16,
    gridTemplateColumns: "210px",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(2, 210px)",
    },
  },
  userFilter: {
    [theme.breakpoints.up("sm")]: {
      gridColumn: "1/3",
    },
  },
}));

const FilterMenu: FC<FilterMenuProps> = ({
  filters,
  onChangeFilters,
  onClearFilters,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [state, setState] = useState(filters);

  useEffect(() => setState(filters), [filters]);

  const handleConfirm = () => {
    onChangeFilters(state);
  };

  const handleCancel = () => setState(filters);

  const handleClear = () => {
    onClearFilters();
  };

  return (
    <LuiFilterMenu
      activeCount={Object.values(state).reduce(
        (acc, el) => (el ? acc + 1 : acc),
        0
      )}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      onClear={handleClear}
      classes={{ popoverContent: classes.popoverContent }}
    >
      <UserAutocomplete
        className={classes.userFilter}
        userId={state.who}
        onChange={(event, value) =>
          setState({
            ...state,
            who: ((value as User) || null)?.id,
          })
        }
        InputProps={{ label: t("common:who") }}
      />
      <Autocomplete
        value={
          TASKS_PRIORITIES.find((el) => el.priority === state.priority) || null
        }
        options={TASKS_PRIORITIES}
        fullWidth
        getOptionLabel={(option) => t(`tasks:${option.priority}`)}
        isOptionEqualToValue={(option, value) =>
          option.priority === value.priority
        }
        onChange={(event, value) =>
          setState({
            ...state,
            priority: value?.priority,
          })
        }
        InputProps={{ label: t("common:priority") }}
      />
      <Autocomplete
        value={TASKS_TYPES.find((el) => el.id === state.type) || null}
        options={TASKS_TYPES}
        fullWidth
        getOptionLabel={(option) => t(`tasks:${option.display_name}`)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event, value) =>
          setState({
            ...state,
            type: value?.id,
          })
        }
        InputProps={{ label: t("common:type") }}
      />
      <Autocomplete
        value={TASKS_CREATED.find((el) => el.id === state.created) || null}
        options={TASKS_CREATED}
        fullWidth
        getOptionLabel={(option) => t(`common:${option.display}`)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event, value) =>
          setState({
            ...state,
            created: value?.id,
          })
        }
        InputProps={{ label: t("common:created") }}
      />
    </LuiFilterMenu>
  );
};

export default FilterMenu;
