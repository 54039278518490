import React, { FC, useMemo, useState } from "react";
import {
  DatePickerRange,
  ActionBar,
  ActionBarItem,
  TextField,
  Loader,
  Autocomplete,
  Grid,
  Paper,
  Box,
  Typography,
  Theme,
  InputAdornment,
} from "@periplus/ui-library";
import { useParams } from "react-router-dom";
import useGetFiles from "graphql/hooks/useGetFiles";
import { useTranslation } from "react-i18next";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FolderOpenTwoToneIcon from "@mui/icons-material/FolderOpenTwoTone";
import useGetUsers, { User } from "graphql/hooks/useGetUsers";
import SearchIcon from "@mui/icons-material/Search";
import dayjs, { Dayjs } from "dayjs";
import { useDebounce } from "@react-hook/debounce";
import useGetFullFileHistory from "graphql/hooks/useGetFullFileHistory";
import config from "config";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  historyItemColumn: {
    padding: "0px 15px",
  },

  noBorder: {
    "& fieldset": {
      border: "none",
    },
  },

  historyIcon: {
    fontSize: 24,
  },
}));

const FileHistory: FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { classes } = useStyles();

  const [filteredUser, setFilteredUser] = useState<User | null>(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [filter, setFilterDebounce] = useDebounce("", 500);

  const [getHistory, { data: historyData, loading: historyLoading }] =
    useGetFullFileHistory();

  const {
    data: [file],
    loading: fileLoading,
  } = useGetFiles(id, false, {
    onCompleted: () => {
      const _docIds = (file?.documents || [])
        .map((document: any) => document.id)
        .join(",");
      getHistory({
        variables: {
          fileId: file.id,
          documentIds: `{${_docIds}}`,
        },
      });
    },
  });
  const {
    data: { users },
    loading: usersLoading,
  } = useGetUsers({ limit: 1000 });

  const filteredUsers = useMemo(() => {
    return users.filter(
      (user) =>
        (historyData &&
          historyData.document_history.some(
            (document_history) => document_history.action_user === user.id
          )) ||
        (historyData &&
          historyData.file_history.some(
            (file_history) => file_history.action_user === user.id
          ))
    );
  }, [historyData, users]);

  const history = useMemo(() => {
    if (!historyData) return [];

    const { file_history, document_history } = historyData;
    const combined = [
      ...file_history.map((el) => ({ ...el, entity: "file" })),
      ...document_history.map((el) => ({ ...el, entity: "document" })),
    ] as any[];
    const sorted = combined.sort(
      (a, b) => new Date(b.op_date).getTime() - new Date(a.op_date).getTime()
    );

    const filtered = sorted.filter((el) => {
      const userCondition = filteredUser
        ? el.action_user === filteredUser.id
        : true;
      const startDateCondition = startDate
        ? dayjs(el.op_date) >= startDate
        : true;
      const endDateCondition = endDate ? dayjs(el.op_date) <= endDate : true;
      const filterCondition = filter
        ? t(`historyActionTitles:${el.action_title}`)
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase())
        : true;
      return (
        userCondition &&
        startDateCondition &&
        endDateCondition &&
        filterCondition
      );
    });
    return filtered;
  }, [endDate, filter, filteredUser, historyData, startDate, t]);

  return (
    <Box>
      <ActionBar>
        <ActionBarItem>
          <Grid container alignItems="center">
            <Typography>{t("common:filterBy")}:</Typography>
            <Autocomplete
              value={filteredUser}
              onChange={(event: any, value: User | null) => {
                setFilteredUser(value);
              }}
              InputProps={{
                label: t("common:user"),
              }}
              options={filteredUsers}
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography noWrap>{option.display_name}</Typography>
                </li>
              )}
              getOptionLabel={(option) => option.display_name}
              style={{
                marginLeft: 15,
                width: 180,
              }}
            />
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              onChange={({ startDate, endDate }) => {
                setStartDate(startDate);
                setEndDate(endDate);
              }}
              sx={{ marginLeft: 15 }}
            />
          </Grid>
        </ActionBarItem>
        <ActionBarItem>
          <TextField
            label={t("common:search") + "..."}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon style={{ color: "#00527e" }} />
                </InputAdornment>
              ),
            }}
            onChange={(e: any) => {
              const search = e.target.value;
              setFilterDebounce(search);
            }}
            style={{
              width: 180,
            }}
          />
        </ActionBarItem>
      </ActionBar>
      <Box
        style={{
          marginTop: 15,
        }}
      >
        {fileLoading || historyLoading || usersLoading ? (
          <Loader />
        ) : (
          history.map((hisotoryItem, i) => {
            const historyUser = users.find(
              (el) => el.id === hisotoryItem.action_user
            );
            return (
              <Box
                style={{ marginTop: i && 15 }}
                key={
                  hisotoryItem.entityId +
                  hisotoryItem.entity +
                  hisotoryItem.action_title +
                  hisotoryItem.op_date
                }
              >
                <Paper variant="outlined">
                  <Grid
                    container
                    alignItems="center"
                    style={{ padding: "5px 15px" }}
                  >
                    <Grid
                      className={classes.historyItemColumn}
                      item
                      style={{ display: "flex" }}
                    >
                      {hisotoryItem.entity === "file" ? (
                        <FolderOpenTwoToneIcon
                          className={classes.historyIcon}
                          color="primary"
                          titleAccess={t("common:file")}
                        />
                      ) : (
                        <DescriptionOutlinedIcon
                          className={classes.historyIcon}
                          color="primary"
                          titleAccess={t("common:document")}
                        />
                      )}
                    </Grid>
                    <Grid className={classes.historyItemColumn} item xs>
                      <Typography>
                        {t(`historyActionTitles:${hisotoryItem.action_title}`)}
                      </Typography>
                      <Typography color="textSecondary">
                        {hisotoryItem.entity === "document"
                          ? `${
                              hisotoryItem.document.documentType.translations[
                                language
                              ] ||
                              hisotoryItem.document.documentType.translations[
                                config.defaultLanguage
                              ]
                            } ${hisotoryItem.document.sequence}`
                          : ""}
                      </Typography>
                    </Grid>
                    {historyUser && (
                      <Grid className={classes.historyItemColumn} item>
                        <Typography variant="overline" color="textSecondary">
                          {t(`common:byUser`)}
                        </Typography>
                        <Typography>{historyUser.display_name}</Typography>
                      </Grid>
                    )}
                    <Grid
                      className={classes.historyItemColumn}
                      item
                      style={{
                        width: 215,
                      }}
                    >
                      <Typography variant="overline" color="textSecondary">
                        {t(`common:timestamp`)}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <AccessTimeIcon />
                        <Typography
                          style={{
                            fontStyle: "italic",
                            marginLeft: 10,
                          }}
                        >
                          {`${dayjs(hisotoryItem.op_date).format(
                            "DD.MM.YYYY"
                          )} ${t("common:at")} ${dayjs(
                            hisotoryItem.op_date
                          ).format("HH:mm:ss")}`}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            );
          })
        )}
      </Box>
    </Box>
  );
};

export default FileHistory;
