import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  RefObject,
} from "react";
import { Grid, TextField, Theme, Typography } from "@periplus/ui-library";
import Text from "../../../../../components/Text/Text";
import { Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import noop from "lodash/noop";
import partition from "lodash/partition";
import Colored from "components/Colored";
import useGetSettingValue, {
  Setting,
  ReferenceType,
} from "graphql/hooks/useGetSettingValue";
import { get } from "lodash";
import useGetDocumentTypes from "domain/documentType/useGetDocumentTypes";
import { DocumentType } from "domain/documentType/types";
import { makeStyles } from "tss-react/mui";

interface IClassifierProps {
  disableKeyboard?: boolean;
  selectedPagesAmount?: number;
  listRef?: any;
  onSelect(docType: string): any;
  enabledDocType?: string;
  className?: string;
  searchRef: RefObject<HTMLInputElement>;
}

const useStyles = makeStyles()((theme: Theme) => ({
  fullHeight: {
    height: "100%",
  },

  list: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100% - 160px)",
  },

  widget: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    width: "100%",
  },

  widgetItems: {
    marginBottom: theme.spacing(3),
  },

  listItem: {
    display: "flex",
    paddingTop: theme.spacing(0.4),
    paddingBottom: theme.spacing(0.4),
    alignItems: "center",
  },

  listItemActive: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0001",
    },
  },

  listItemDisabled: {
    opacity: 0.5,
  },

  listItemText: {
    flex: 1,
    marginLeft: theme.spacing(2),
  },

  documentTypeInputDisabled: {
    backgroundColor: "unset",
  },
}));

function Classifier({
  selectedPagesAmount = 0,
  onSelect,
  listRef,
  enabledDocType,
  className,
  searchRef,
}: IClassifierProps) {
  const { data: documentTypesData } = useGetDocumentTypes({ flag: 1 });
  const { classes, cx } = useStyles();
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();

  const { data } = useGetSettingValue({
    setting: Setting.DocumentTypesTemplates,
    reference_type_id: ReferenceType.UserId,
  });

  useEffect(() => {
    if (!documentTypesData) return;

    const [template_settings, user_settings] = partition(data, "is_template");
    const documentTypeTemplateId = get(user_settings, "0.value");
    const userDocumentTypeTemplate = template_settings.find(
      (st: any) => st.id === documentTypeTemplateId
    );
    const userDocumentTypeTemplateItems = get(
      userDocumentTypeTemplate,
      "value",
      []
    );
    const newDocumentTypes = documentTypesData
      .filter((dtd) => {
        const userDocumentTypeSetting = userDocumentTypeTemplateItems.find(
          (udts: any) => dtd.name === udts.name
        );
        return userDocumentTypeSetting
          ? userDocumentTypeSetting.visibility
          : true;
      })
      .sort((dtd1: any, dtd2: any) => {
        const { pos: userDocumentTypeSettingPos1 = undefined } =
          userDocumentTypeTemplateItems.find(
            (udts: any) => dtd1.name === udts.name
          ) || {};
        const { pos: userDocumentTypeSettingPos2 = undefined } =
          userDocumentTypeTemplateItems.find(
            (udts: any) => dtd2.name === udts.name
          ) || {};
        return (
          //@ts-ignore
          (userDocumentTypeSettingPos1 === undefined) -
            //@ts-ignore
            (userDocumentTypeSettingPos2 === undefined) ||
          +(userDocumentTypeSettingPos1 > userDocumentTypeSettingPos2) ||
          -(userDocumentTypeSettingPos1 < userDocumentTypeSettingPos2)
        );
      });
    setDocumentTypes(newDocumentTypes);
  }, [documentTypesData, data]);

  const filteredDocumentTypes = useMemo(() => {
    const clearValue = searchValue.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const searchRegexp = new RegExp(clearValue, "i");

    const newFilteredDocumentTypes = documentTypes.filter((documentType, i) => {
      return (
        searchRegexp.test(documentType.translated_name) ||
        searchRegexp.test(`${i + 1}`)
      );
    });
    return newFilteredDocumentTypes;
  }, [searchValue, documentTypes]);

  const classify = useCallback(
    (docType) => {
      onSelect(docType);
      setSearchValue("");
    },
    [onSelect]
  );

  const handleSearch = useCallback(
    ({ target }: { target: { value: string } }) => {
      setSearchValue(target.value);
    },
    []
  );

  const handleEnter = useCallback(
    ({ code }: KeyboardEvent) => {
      if (
        !!searchValue &&
        ["Enter", "NumpadEnter"].includes(code) &&
        filteredDocumentTypes.length > 0 &&
        (!enabledDocType || enabledDocType === filteredDocumentTypes[0].name)
      ) {
        classify(filteredDocumentTypes[0]);
      }
    },
    [searchValue, filteredDocumentTypes, enabledDocType, classify]
  );

  useEffect(() => {
    // save it to variable to have the same link to the variable in both cases
    let ref: any = null;
    if (searchRef.current) {
      ref = searchRef.current;
    }

    if (ref) {
      ref.addEventListener("keydown", handleEnter);
    }
    return () => {
      if (ref) {
        ref.removeEventListener("keydown", handleEnter);
      }
    };
  }, [handleEnter]);

  return (
    <Grid
      item
      className={cx(classes.fullHeight, className)}
      sx={{
        ml: 2,
      }}
    >
      <Grid item className={cx(classes.widget, classes.fullHeight)}>
        <Grid item className={classes.widgetItems}>
          <Text align="left" variant="h5" color="primary">
            classify:classifier
          </Text>
          <div style={{ display: "flex" }}>
            <Typography color="textSecondary" style={{ marginRight: 5 }}>
              {selectedPagesAmount}{" "}
            </Typography>
            <Text align="left" color="textSecondary">
              classify:selected
            </Text>
          </div>
        </Grid>
        <Grid item className={classes.widgetItems}>
          <TextField
            inputRef={searchRef}
            id="outlined-password-input"
            label={t("common:documentType")}
            helperText={t("classify:documentTypeHelper")}
            variant="outlined"
            fullWidth
            value={searchValue}
            disabled={selectedPagesAmount === 0}
            onChange={handleSearch}
            style={{
              padding: 0,
            }}
            FormHelperTextProps={{
              style: {
                marginLeft: 8,
                marginRight: 8,
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: 14,
              },
            }}
            InputProps={{
              style: {
                fontSize: 14,
              },
              endAdornment: <Search />,
              classes: {
                disabled: classes.documentTypeInputDisabled,
              },
            }}
          />
        </Grid>

        <Grid item className={classes.list} ref={listRef}>
          {filteredDocumentTypes &&
            filteredDocumentTypes.map((documentType: any) => {
              const disabled =
                (enabledDocType != null &&
                  enabledDocType !== documentType.name) ||
                selectedPagesAmount === 0;
              const [className, action] = disabled
                ? [classes.listItemDisabled, noop]
                : [classes.listItemActive, () => classify(documentType)];
              return (
                <div
                  className={`${classes.listItem} ${className}`}
                  key={documentType.name}
                  onClick={action}
                >
                  <Colored color={documentType.meta.color}>
                    {documentTypes.findIndex(
                      (el) => el.name === documentType.name
                    ) + 1}
                  </Colored>
                  <Typography
                    align="left"
                    color="textSecondary"
                    className={classes.listItemText}
                  >
                    {documentType.translated_name}
                  </Typography>
                </div>
              );
            })}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Classifier;
