import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import useErrorHandling from "hooks/useErrorHandling";
import { SCHEDULE_DATA_EXTRACTION } from "../../graphql/mutations/file";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const useScheduleDataExtraction = () => {
  const withErrorHandling = useErrorHandling();
  const [scheduleExtraction] = useMutation(SCHEDULE_DATA_EXTRACTION);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const scheduleDataExtraction = useCallback(
    async (fileId: string, drNo: number) => {
      await withErrorHandling(scheduleExtraction, {
        variables: { fileId, drNo },
      });
      enqueueSnackbar(t("common:success"), { variant: "success" });
    },
    [scheduleExtraction, withErrorHandling]
  );

  return scheduleDataExtraction;
};

export default useScheduleDataExtraction;
