import React, { useState } from "react";
import { AlertDialog, Autocomplete } from "@periplus/ui-library";
import Close from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

interface ITemplateSelectProps {
  className?: any;
  options: any[];
  value: any;
  onChange(value: any): void;
  onDelete(value: any): void;
  disabled?: boolean;
  [key: string]: any;
}

const TemplateSelect: React.FC<ITemplateSelectProps> = ({
  className,
  options,
  value,
  onChange,
  onDelete,
  disabled,
}) => {
  const [documentTypeTemplateForDelete, setDocumntTypeTemplateForDelete] =
    useState();
  const { t } = useTranslation();
  return (
    <>
      {documentTypeTemplateForDelete && (
        <AlertDialog
          variant="warning"
          onClose={() => setDocumntTypeTemplateForDelete(undefined)}
          onConfirm={() => {
            onDelete(documentTypeTemplateForDelete);
            setDocumntTypeTemplateForDelete(undefined);
          }}
        >
          {t(`document_type_templates:deleteDialog`)}
        </AlertDialog>
      )}
      <Autocomplete
        className={className}
        options={options}
        value={value || null}
        getOptionLabel={(option) => option.display_name}
        isOptionEqualToValue={(option, value) => {
          return option.id === (value && value.id);
        }}
        noOptionsText={t("common:no_options")}
        InputProps={{
          label: t("common:select_template"),
        }}
        renderOption={(props, option) => (
          <li {...props}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                title={option.display_name}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {option.display_name}
              </div>
              <Close
                color="error"
                fontSize="small"
                style={{ marginLeft: "6px", marginTop: "3px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setDocumntTypeTemplateForDelete(option.id);
                }}
              />
            </div>
          </li>
        )}
        onChange={(event: any, newValue: any) => {
          onChange(newValue);
        }}
        disabled={disabled}
        style={{
          minWidth: 165,
        }}
      />
    </>
  );
};

export default TemplateSelect;
