import useErrorHandling from "hooks/useErrorHandling";
import { gql } from "@apollo/client";
import client from "graphql/client";

const DELETE_ORG_ID = gql`
  query DELETE_ORG_ID($org_id: smallint!) {
    pp_org_id_delete(args: { _org_id: $org_id, _force: false }) {
      json_value
    }
  }
`;

const useDeleteOrgId = () => {
  const withErrorHandling = useErrorHandling();

  const query = (variables: { org_id: number }) => {
    return withErrorHandling(client.query, {
      query: DELETE_ORG_ID,
      fetchPolicy: "no-cache",
      variables,
    });
  };

  return query;
};

export default useDeleteOrgId;
