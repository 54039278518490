import { useMemo, useCallback } from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

export type UserType = {
  id: string;
  firstName: string;
  lastName: string;
  location: string;
  email: string;
  orgIds: number[];
  roles: string[];
  liberRoles: string[];
  aditRoles: string[];
  defaultRole: string;
  permissions: number;
  code?: string;
};

interface IQueryResult {
  pp_kc_users: UserType & { qty: number }[];
}

export interface IVariables {
  limit: number;
  offset?: number;
  search?: string;
  tenants?: string;
}

export const GET_KC_ADMIN_USERS = gql`
  query ADIT_GET_KC_ADMIN_USERS(
    $limit: Int
    $offset: Int
    $search: String
    $tenants: _uuid
  ) {
    pp_kc_users(
      args: {
        _limit: $limit
        _offset: $offset
        _search: $search
        _tenants: $tenants
      }
    ) {
      id
      firstName: first_name
      lastName: last_name
      location
      email
      aditRoles: adit_roles
      liberRoles: liber_roles
      permissions: adit_permissions
      defaultRole: default_role
      orgIds: org_ids
      tenant_id
      qty
    }
  }
`;

const useGetUsers = ({
  limit,
  offset = 0,
  search = "",
  tenants,
}: IVariables) => {
  const { data, loading, error, refetch } = useQuery<IQueryResult>(
    GET_KC_ADMIN_USERS,
    {
      variables: { limit, offset, search, tenants },
      fetchPolicy: "no-cache",
    }
  );

  const loadData = useCallback(
    (offset: number, limit: number, search?: string) => {
      refetch({
        search,
        offset,
        limit,
        tenants,
      });
    },
    [refetch, tenants]
  );

  const filter = useCallback(
    (filterBy: string) => {
      refetch({
        limit,
        offset: 0,
        search: filterBy,
        tenants,
      });
    },
    [limit, refetch, tenants]
  );

  const returnValue = useMemo(() => {
    const user_search = {
      users: data?.pp_kc_users || [],
      total_rows: data?.pp_kc_users[0]?.qty || 0,
    };
    return {
      data: user_search,
      loadData,
      loading,
      error,
      filter,
      refetch,
    };
  }, [data, loadData, loading, error, filter, refetch]);

  return returnValue;
};

export default useGetUsers;
