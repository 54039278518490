import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import config from "config";

import { VALIDATE_FILE_NUMBER } from "graphql/mutations/file";
import { GET_FILE_BY_IDENTIFICATION } from "graphql/queries/files";

export type IValidatedFileData = {
  validateFileNumber: Data;
};

export type Data = {
  error_code: string;
  error_description: string;
  data: ResponseData;
};

export type ResponseData = {
  orgId: string;
  shipmentNumber: string;
  traffic: string;
  lines: Lines[];
  consignee: ShippingInfo;
  shipper: ShippingInfo;
  orderedBy: ShippingInfo;
  fileId: string;
};

type Lines = {
  grossWeight: string;
  packaging: string;
  quantity: string;
};

type ShippingInfo = {
  adrID: string;
  city: string;
  consigneeAdrID: string;
  country: string;
  name: string;
  postalCode: string;
  state: string;
  street: string;
};

export type IFileStatus = {
  file: File[];
};

export type File = {
  id: string;
  identification: string;
  file_status: string;
  file_flags: number;
};

const { prefixMRSNumber } = config;

const useValidateFile = (fileNumber: string, skip?: boolean) => {
  let fileEditable = true;
  let errorCode: number = 0;
  const isFileNumberWithPrefix = fileNumber.includes("-");

  const [requestDataResult, setRequestDataResult] = useState<Partial<Data>>({});

  const { data, loading, error, refetch } = useQuery<IFileStatus>(
    GET_FILE_BY_IDENTIFICATION,
    {
      skip,
      fetchPolicy: "network-only",
      variables: {
        fileNumber: isFileNumberWithPrefix
          ? fileNumber
          : `${prefixMRSNumber}-${fileNumber}-${prefixMRSNumber}-1`,
      },
    }
  );

  useEffect(() => {
    setRequestDataResult({});

    if (data && data.file.length) {
      refetch();
    }
  }, [data, refetch]);

  const [
    validateFileNumber,
    { data: requestData, loading: requestLoading, error: requestError },
  ] = useMutation<IValidatedFileData>(VALIDATE_FILE_NUMBER);

  useEffect(() => {
    if (fileEditable && !loading && !skip) {
      validateFileNumber({
        variables: {
          number: isFileNumberWithPrefix
            ? fileNumber
            : `${prefixMRSNumber}-${fileNumber}-${prefixMRSNumber}-1`,
        },
      });
    }
  }, [fileEditable, loading, validateFileNumber, fileNumber, skip]);

  useEffect(() => {
    if (requestData) {
      setRequestDataResult(requestData.validateFileNumber);
    }
  }, [requestData]);

  if (fileNumber.length < 7) {
    errorCode = 0;
    fileEditable = false;

    if (Object.keys(requestDataResult).length) {
      setRequestDataResult({});
    }
  }

  if (
    requestDataResult &&
    requestData &&
    requestData.validateFileNumber.error_code
  ) {
    errorCode = parseInt(requestData.validateFileNumber.error_code);
  }

  const returnValue = {
    data: { ...requestDataResult, fileId: data?.file?.[0]?.id || "" },
    loading: requestLoading,
    loadingStatus: loading,
    error: error || requestError || errorCode,
  };

  return returnValue;
};

export default useValidateFile;
