import React, { FC } from "react";
import Table from "../components/Table";

interface NotClassifiedProps {}

const NotClassified: FC<NotClassifiedProps> = () => {
  return <Table classified={false} />;
};

export default NotClassified;
