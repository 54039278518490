import React from "react";
import { FormTextField } from "@periplus/ui-library";
import FormField, { FormFieldProps } from "../components/FormField/FormField";
import { DECLARATION_FORM_DEBOUNCE } from "../Declaration";

interface Props extends Omit<FormFieldProps, "name" | "renderInput"> {
  name: "customs_account_number" | "vat_account_number";
}

export default ({ name, label, ...rest }: Props) => {
  return (
    <FormField
      name={name}
      {...rest}
      fillByClickMode
      renderInput={({ ...rest }) => (
        <FormTextField
          {...rest}
          label={label}
          debounce={DECLARATION_FORM_DEBOUNCE}
          type="number"
          sx={{
            gridColumn: "2",
          }}
        />
      )}
    />
  );
};
