import { useState, useEffect, useCallback, useMemo } from "react";
import { DataPoint } from "graphql/hooks/useGetValidationDocument";
import { v4 as uuid } from "uuid";

export default function useAppendNewDataPoint() {
  const [newDataPoint, setNewDataPoint] = useState<DataPoint>();
  const [shiftKey, setShiftKey] = useState<boolean>(false);

  useEffect(() => {
    const handleShiftKey = (e: KeyboardEvent) => {
      setShiftKey(e.key === "Shift" && e.shiftKey);
    };
    window.document.addEventListener("keydown", handleShiftKey);
    window.document.addEventListener("keyup", handleShiftKey);

    return () => {
      window.document.removeEventListener("keydown", handleShiftKey);
      window.document.removeEventListener("keyup", handleShiftKey);
    };
  }, []);

  const appendToNewDataPoint = useCallback((dataPoint: DataPoint) => {
    setNewDataPoint((prevDb) => {
      const page = Number(dataPoint.id.split("-")[0]);
      const newDataPoint: DataPoint = {
        id: [page, uuid()].join("-"),
        coordinates: dataPoint.coordinates,
        value: dataPoint.value,
        page: page + 1,
      };
      if (!prevDb || prevDb.page !== page + 1) {
        newDataPoint.dataPointsInside = dataPoint.id;
        return newDataPoint;
      }

      const leftMost = Math.min(
        Number(prevDb.coordinates[0]),
        Number(dataPoint.coordinates[0])
      );
      const topMost = Math.min(
        Number(prevDb.coordinates[1]),
        Number(dataPoint.coordinates[1])
      );
      const rightMost = Math.max(
        Number(prevDb.coordinates[0]) + Number(prevDb.coordinates[2]),
        Number(dataPoint.coordinates[0]) + Number(dataPoint.coordinates[2])
      );
      const bottomMost = Math.max(
        Number(prevDb.coordinates[1]) + Number(prevDb.coordinates[3]),
        Number(dataPoint.coordinates[1]) + Number(dataPoint.coordinates[3])
      );

      newDataPoint.coordinates = [
        leftMost,
        topMost,
        rightMost - leftMost,
        bottomMost - topMost,
      ];
      newDataPoint.dataPointsInside = [
        prevDb.dataPointsInside || "",
        dataPoint.id,
      ].join(",");
      newDataPoint.value += " " + dataPoint.value;
      return newDataPoint;
    });
  }, []);

  return useMemo(
    () => ({
      newDataPoint,
      shiftKey,
      setNewDataPoint,
      appendToNewDataPoint,
    }),
    [newDataPoint, setNewDataPoint, shiftKey, appendToNewDataPoint]
  );
}
