import React, { useState, useMemo, useEffect } from "react";
import {
  Table,
  ThreeDotsMenu,
  Typography,
  Search,
  Button,
  Columns,
  Rows,
  SubTableRows,
  PageContainer,
  CircularProgress,
  ListItemIcon,
  Theme,
  TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS,
} from "@periplus/ui-library";
import usePageLocalStorage, {
  ColumnsSettings,
} from "hooks/usePageLocalStorage";
import useUrlSearchParams from "hooks/useUrlSearchParams";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@mui/icons-material";
import dayjs from "dayjs";
import useGetEmailsSubscription from "domain/file/useGetEmailsSubscription";
import { EmailView } from "domain/file/types";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { Sorted } from "graphql/helpers";
import SubTable from "./SubTable";
import { Document } from "domain/document/types";
import { useHistory } from "react-router-dom";
import PDFPreview from "./PDFPreview";
import useDeleteFile from "domain/file/useDeleteFile";
import useGetEmail from "domain/file/useGetEmail";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  controlsContainer: {
    display: "flex",
    gap: theme.spacing(),
  },
  actionsColumn: {
    width: "0%",
    textAlign: "end",
  },
}));

const Emails = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { pageLocalStorage, setPageLocalStorage } = usePageLocalStorage<{
    itemsPerPage: number;
    columnsSettings?: ColumnsSettings;
  }>({
    itemsPerPage: TABLE_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0],
  });

  const { urlSearchParams, setUrlSearchParams } = useUrlSearchParams<{
    page: number;
    itemsPerPage: number;
    search?: string;
    sorted?: Sorted;
  }>({
    page: 1,
    itemsPerPage: pageLocalStorage.itemsPerPage,
  });

  const deleteFile = useDeleteFile();
  const [deleting, setDeleting] = useState(false);

  const {
    data: { files, isLastPage },
    loading,
    refetch,
  } = useGetEmailsSubscription({
    ...urlSearchParams,
    onSubscriptionData: () => {
      refetchEmail();
    },
  });

  const [columns, setColumns] = useState({});

  useEffect(() => {
    setColumns(
      Object.entries({
        name: {
          header: t("topic"),
          sortable: true,
          cell: (row) => row.data.name.split(".").slice(0, -1).join("."),
        },
        sender: {
          header: t("sender"),
          sortable: true,
        },
        company: {
          header: t("company"),
          sortable: true,
        },
        creation_date: {
          header: t("creationDate"),
          sortable: true,
          cell: (row) =>
            dayjs(row.data.creation_date).format("DD.MM.YYYY HH:mm:ss"),
        },
        file_status: {
          header: t("documentsReady"),
          cell: (row) => {
            const documents = row.data.documents || [];
            const readyDocumentsCount = documents.filter(
              (document) => document.document_status === "thumbnails_generated"
            ).length;
            return `${readyDocumentsCount}/${row.data.documents.length}`;
          },
        },
        actions: {
          actionsColumn: true,
          cellClassName: classes.actionsColumn,
          sortable: false,
          customizable: false,
          cell: (row) => {
            return (
              <ThreeDotsMenu
                options={[
                  {
                    content: (
                      <>
                        <ListItemIcon style={{ minWidth: 28 }}>
                          {deleting ? (
                            <CircularProgress size={24} thickness={4.5} />
                          ) : (
                            <DeleteOutlined fontSize="small" />
                          )}
                        </ListItemIcon>
                        <Typography>{t("delete")}</Typography>
                      </>
                    ),
                    action: (close) => {
                      setDeleting(true);
                      deleteFile(row.data.id).then(() => {
                        setDeleting(false);
                        setRows((prevRows) => {
                          return {
                            ...prevRows,
                            [row.data.id]: {
                              ...prevRows[row.data.id],
                              selected: false,
                              multiSelected: false,
                              subTableRows: Object.entries(
                                prevRows[row.data.id]
                                  .subTableRows as SubTableRows<Document>
                              ).reduce((acc, [subRowId, subRow]) => {
                                acc[subRowId] = {
                                  ...subRow,
                                  selected: false,
                                  multiSelected: false,
                                };
                                return acc;
                              }, {} as SubTableRows<Document>),
                            },
                          };
                        });
                        refetch();
                        close();
                      });
                    },
                  },
                ]}
              />
            );
          },
        },
      } as Columns<EmailView>).reduce((acc, [id, column]) => {
        acc[id] = {
          ...column,
          sort: urlSearchParams.sorted?.[id],
          ...pageLocalStorage.columnsSettings?.[id],
        };
        return acc;
      }, {} as Columns<EmailView>)
    );
  }, [t, refetch]);

  const [rows, setRows] = useState<Rows<EmailView>>({});

  useEffect(() => {
    setRows((prev) => ({
      ...Object.entries(prev).reduce((newRows, [id, row]) => {
        newRows[id] = {
          ...row,
          selected: false,
          activePos: undefined,
          subTableRows: Object.entries(
            row.subTableRows as SubTableRows<Document>
          ).reduce((newSubRows, [prevSubRowId, prevSubRow]) => {
            newSubRows[prevSubRowId] = {
              ...prevSubRow,
              selected: false,
            };
            return newSubRows;
          }, {} as SubTableRows<Document>),
        };
        return newRows;
      }, {} as Rows<EmailView>),
      ...files.reduce((newRows, file, i) => {
        newRows[file.id] = {
          ...prev[file.id],
          data: file,
          activePos: i,
          multiSelectable: file.documents.some(
            (document) => document.pages_m2m_aggregate.aggregate.count
          ),
          selected: i === 0,
          subTableRows: file.documents.reduce((newSubRows, document) => {
            newSubRows[document.id] = {
              ...prev[file.id]?.subTableRows?.[document.id],
              data: document,
            };
            return newSubRows;
          }, {} as SubTableRows<Document>),
        };
        return newRows;
      }, {} as Rows<EmailView>),
    }));
  }, [files]);

  const [selectedEmailId, selectedDocumentId, multiSelectedDocuments] = useMemo(
    () => [
      Object.values(rows).find(
        (row) =>
          row.selected ||
          Object.values(row.subTableRows as SubTableRows<Document>).some(
            (subRow) => subRow.selected
          )
      )?.data.id,
      (() => {
        const selectedDocumentEmail = Object.values(rows).find((row) =>
          Object.values(row.subTableRows as SubTableRows<Document>).some(
            (subRow) => subRow.selected
          )
        );
        const selectedDocument =
          selectedDocumentEmail &&
          (Object.values(
            selectedDocumentEmail.subTableRows as SubTableRows<Document>
          ).find((subRow) => subRow.selected)?.data as Document);
        return selectedDocument?.id;
      })(),
      Object.values(rows).reduce(
        (acc, row) =>
          acc.concat(
            Object.values(row.subTableRows as SubTableRows<Document>).reduce(
              (acc, subTableRow) => {
                if (subTableRow.multiSelected) acc.push(subTableRow.data);
                return acc;
              },
              [] as Document[]
            )
          ),
        [] as Document[]
      ),
    ],
    [rows]
  );

  const navigateToClassification = (
    documentsToClassify: string[] | number[]
  ) => {
    sessionStorage.setItem(
      "classify_documents",
      JSON.stringify(documentsToClassify)
    );
    sessionStorage.setItem("classify_for", "documents");
    history.push({
      pathname: `/select/classification`,
    });
  };

  const {
    data: { emails } = {},
    loading: loadingEmail,
    refetch: refetchEmail,
  } = useGetEmail({ id: selectedEmailId, ...urlSearchParams });

  return (
    <PageContainer title={t("navigation:emails")}>
      <PDFPreview
        email={emails?.[0]}
        loading={loadingEmail}
        documentId={selectedDocumentId}
      />
      <Table<EmailView>
        sx={(theme) => ({
          mt: 2,
          "& .LuiPanel-content": {
            [theme.breakpoints.up("sm")]: {
              maxHeight: "calc(100vh - 478px)",
            },
          },
        })}
        rows={rows}
        columns={columns}
        dataIdentifier="id"
        loading={loading}
        isLastPage={isLastPage}
        page={urlSearchParams.page}
        itemsPerPage={urlSearchParams.itemsPerPage}
        onChangePage={(newPage) => {
          setUrlSearchParams({ page: newPage });
        }}
        onChangeItemsPerPage={(newItemsPerPage) => {
          setPageLocalStorage({
            itemsPerPage: newItemsPerPage,
          });
          setUrlSearchParams({
            itemsPerPage: newItemsPerPage,
            page: 1,
          });
        }}
        onSort={(newColumns) => {
          setColumns(newColumns);

          const newSorted = Object.entries(newColumns).reduce(
            (acc, [id, column]) => {
              if (column.sort)
                acc[id] = {
                  ...column.sort,
                };
              return acc;
            },
            {} as Sorted
          );
          setUrlSearchParams({
            sorted: Object.keys(newSorted).length ? newSorted : undefined,
            page: 1,
          });
        }}
        onSelect={(newRows, doubleClick) => {
          setRows(newRows);
        }}
        onMultiSelect={(newRows, toggledRow) => {
          setRows(newRows);
        }}
        onColumnsSettingsChange={(newColumns) => {
          setColumns(newColumns);
          setPageLocalStorage({
            columnsSettings: Object.entries(newColumns).reduce(
              (acc, [id, column]) => {
                acc[id] = {
                  pos: column.pos as number,
                  visible: column.visible,
                };
                return acc;
              },
              {} as ColumnsSettings
            ),
          });
        }}
        controls={
          <div className={classes.controlsContainer}>
            <Search
              value={urlSearchParams.search}
              onChange={(newSearch) =>
                setUrlSearchParams({ search: newSearch, page: 1 })
              }
            />
            <Button
              variant="outlined"
              color="primary"
              disabled={!multiSelectedDocuments.length}
              minimizeForMobiles
              endIcon={<ArrowForward fontSize="small" />}
              onClick={() => {
                navigateToClassification(
                  multiSelectedDocuments.map((el) => el.id)
                );
              }}
            >
              {t("select:start_classification")}
            </Button>
          </div>
        }
        renderExpandedRow={(row) => (
          <SubTable
            rows={row.subTableRows!}
            onSelect={(newSubRows) => {
              setRows((prevRows) =>
                Object.entries(prevRows).reduce(
                  (rowsAcc, [prevRowId, prevRow]) => {
                    rowsAcc[prevRowId] = {
                      ...prevRow,
                      selected: false,
                      subTableRows:
                        row.data.id === prevRow.data.id
                          ? newSubRows
                          : prevRow.subTableRows &&
                            Object.entries(
                              prevRow.subTableRows as SubTableRows<Document>
                            ).reduce(
                              (subRowsAcc, [subTableRowId, subTableRow]) => {
                                subRowsAcc[subTableRowId] = {
                                  ...subTableRow,
                                  selected: false,
                                };
                                return subRowsAcc;
                              },
                              {} as SubTableRows<Document>
                            ),
                    };
                    return rowsAcc;
                  },
                  {} as Rows<EmailView>
                )
              );
            }}
            onMultiSelect={(newSubRows, toggledSubTableRow) => {
              setRows((prev) => ({
                ...prev,
                [row.data.id]: {
                  ...prev[row.data.id],
                  multiSelected: Object.values(newSubRows).every(
                    (newSubRow) => newSubRow.multiSelected
                  ),
                  subTableRows: newSubRows,
                },
              }));
            }}
            onDelete={(deletedDocumentId) => {
              setRows((prevRows) => ({
                ...prevRows,
                [row.data.id]: {
                  ...prevRows[row.data.id],
                  multiSelected: Object.values(
                    prevRows[row.data.id].subTableRows as SubTableRows<Document>
                  ).every(
                    (subRow) =>
                      subRow.multiSelected &&
                      subRow.data.id !== deletedDocumentId
                  ),
                  subTableRows: (() => {
                    const newSubTableRows = {
                      ...prevRows[row.data.id].subTableRows,
                    };
                    delete newSubTableRows[
                      deletedDocumentId as keyof typeof newSubTableRows
                    ];
                    return newSubTableRows;
                  })(),
                },
              }));
            }}
          />
        )}
      />
    </PageContainer>
  );
};

export default Emails;
