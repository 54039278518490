import React from "react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import "react-resizable/css/styles.css";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import { keycloak } from "graphql/client";
import "./i18n";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { KeycloakInitOptions } from "keycloak-js";
import Cookies from "js-cookie";
import packageJson from "../package.json";
import { SnackbarProvider } from "notistack";
import "typeface-rubik";
import "typeface-open-sans";
import { createRoot } from "react-dom/client";

//localStorage version
if (localStorage.getItem("version") !== "10.13.2023") {
  localStorage.clear();
  localStorage.setItem("version", "10.13.2023");
}

// https://github.com/panz3r/react-keycloak/issues/11
// https://www.keycloak.org/docs/latest/securing_apps/index.html
const onEvent = (event: any, error: any) => {
  if (typeof error !== undefined) {
    switch (event) {
      case "onAuthSuccess":
        localStorage.setItem(
          "kc_idToken",
          //@ts-ignore
          keycloak.idToken
        );
        Cookies.set(
          "refreshToken",
          //@ts-ignore
          keycloak.refreshToken,
          { expires: 7, sameSite: "strict", secure: true }
        );
        Cookies.set(
          "idToken",
          //@ts-ignore
          keycloak.idToken,
          { expires: 7, sameSite: "strict", secure: true }
        );
        Cookies.set(
          "token",
          //@ts-ignore
          keycloak.token,
          { expires: 7, sameSite: "strict", secure: true }
        );
        break;
      case "onAuthRefreshSuccess":
        localStorage.setItem(
          "kc_idToken",
          //@ts-ignore
          keycloak.idToken
        );
        Cookies.set(
          "refreshToken",
          //@ts-ignore
          keycloak.refreshToken,
          { expires: 7, sameSite: "strict", secure: true }
        );
        Cookies.set(
          "idToken",
          //@ts-ignore
          keycloak.idToken,
          { expires: 7, sameSite: "strict", secure: true }
        );
        Cookies.set(
          "token",
          //@ts-ignore
          keycloak.token,
          { expires: 7, sameSite: "strict", secure: true }
        );
        break;
      case "onAuthRefreshError":
        console.log("onAuthRefreshError");
        break;
      case "onAuthError":
        console.log("onAuthError");
        break;
      default:
        break;
    }
  }
};

const refreshToken = Cookies.get("refreshToken");
const token = Cookies.get("token");
const idToken = Cookies.get("idToken");

const keycloakProviderInitConfig: KeycloakInitOptions = {
  onLoad: "check-sso",
  checkLoginIframe: false,
  //@ts-ignore
  promiseType: "legacy",
  // @ts-ignore
  token: token,
  // @ts-ignore
  refreshToken: refreshToken,
  // @ts-ignore
  idToken: idToken,
};

const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={keycloakProviderInitConfig}
    onEvent={onEvent}
  >
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <App />
    </SnackbarProvider>
  </ReactKeycloakProvider>
);

// Initialize Sentry, but only for production!
if ("production" === process.env.REACT_APP_ENVIRONMENT) {
  Sentry.init({
    dsn:
      process.env.REACT_APP_SENTRY_URL ||
      "https://c3ca4bfabed94e659eb208fecc48e19d@sentry.io/1800819",
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: `${packageJson.name}@${packageJson.version}`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
  });
}

//  If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
