import React, {
  ComponentType,
  CSSProperties,
  useCallback,
  forwardRef,
  useMemo,
} from "react";
import { ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { LazyLoader, VirtualizationList } from "./Components";

type ItemData = {
  data: any[];
  [key: string]: any;
};

interface IVirtualizationProps {
  /**
   * function for loading data. Will turn on lazy loading automatically
   */
  loadMore?: (startIndex: number, stopIndex: number) => Promise<any>;
  width?: number | string;
  height?: number | string;
  /**
   * functional component that will represent one item
   */
  children: ComponentType<ListChildComponentProps>;
  /**
   * the data that would be passed to each list item
   */
  itemData: ItemData | any[];
  /**
   * the amount of data that list should show
   */
  itemCount?: number;
  style?: CSSProperties;
  /**
   * height for vertical and width for horizontal size of item
   */
  itemSize: number;
  /**
   * top/bottom for vertical and left/right for horizontal spacing between items
   */
  itemsSpacing?: number;
  /**
   * top/bottom for vertical and left/right for horizontal spacing of item
   */
  itemWidthSpacing?: number;
  /**
   * top/bottom for vertical and left/right for horizontal spacing of items container
   */
  containerSpacing?: number;
  /**
   * remove autosizer, needed for some cases when autosizer breaks on calculation the parent element
   */
  withoutAutoSizer?: boolean;
  [key: string]: any;
}

const Virtualization = forwardRef(
  (
    {
      withoutAutoSizer = false,
      width,
      height,
      loadMore,
      style,
      itemSize,
      itemsSpacing = 0,
      itemWidthSpacing = 0,
      containerSpacing = 0,
      ...rest
    }: IVirtualizationProps,
    ref: any
  ) => {
    const itemData = Array.isArray(rest.itemData)
      ? rest.itemData
      : rest.itemData.data;

    const itemsLength = itemData.length;

    const props = useMemo(
      () => ({
        ...rest,
        containerSpacing,
        itemsSpacing,
        itemWidthSpacing,
        itemSize: itemSize + itemsSpacing,
        itemCount: rest.itemCount || itemsLength,
      }),
      [
        containerSpacing,
        itemSize,
        itemWidthSpacing,
        itemsLength,
        itemsSpacing,
        rest,
      ]
    );

    const renderChild = useCallback(
      (providedWidth, providedHeight) => {
        if (loadMore && typeof loadMore === "function") {
          return (
            <LazyLoader
              width={providedWidth}
              height={providedHeight}
              loadMore={loadMore}
              initiallyLoadedData={itemsLength}
              ref={ref}
              {...props}
            />
          );
        }
        return (
          <VirtualizationList
            width={providedWidth}
            height={providedHeight}
            ref={ref}
            {...props}
          />
        );
      },
      [loadMore, ref, props, itemsLength]
    );

    if (withoutAutoSizer) {
      return renderChild(width, height);
    }

    return (
      <AutoSizer>
        {({ width: containerWidth, height: containerHeight }) => {
          const providedWidth = width || containerWidth;
          const providedHeight = height || containerHeight;
          return renderChild(providedWidth, providedHeight);
        }}
      </AutoSizer>
    );
  }
);

export default Virtualization;
