import React, { Fragment, useState } from "react";
import { Theme, IconButton } from "@periplus/ui-library";
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close";
import NewLineIndicator from "./NewLineIndicator";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    line: {
      position: "absolute",
      border: `1px solid ${theme.palette.primary.main}`,
      pointerEvents: "auto",
    },

    verticalLine: {
      height: "100%",
      cursor: "col-resize",
    },

    horizontalLine: {
      width: "100%",
      cursor: "row-resize",
    },

    activeLine: {
      border: `1px solid red`,
    },

    barMarker: {
      position: "absolute",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      opacity: 0,
      pointerEvents: "auto",
    },

    verticalBarMarker: {
      top: -16,
      height: 32,
      width: 18,
    },

    horizontalBarMarker: {
      left: -16,
      width: 32,
      height: 18,
    },

    barMarkerActive: {
      opacity: 1,
    },

    removeButton: {
      width: 18,
      height: 18,
      padding: 0,
      color: theme.palette.common.white,
      background: theme.palette.error.main,
      borderRadius: "50%",
      border: "none",
      pointerEvents: "auto",
      "&:hover": {
        background: theme.palette.error.main,
      },
    }
  }));

interface IMagicLinesProps {
  direction?: "row" | "column";
  bounds: ClientRect;
  positions: number[];
  onAddNewPosition: (newPos: number) => void;
  onRemovePosition: (index: number) => void;
  onUpdatePosition: (index: number, newPos: number) => void;
}

const MagicLines = ({
  direction = "row",
  bounds,
  positions,
  onUpdatePosition,
  onRemovePosition,
  onAddNewPosition,
}: IMagicLinesProps) => {
  const { classes, cx } = useStyles();
  const [activeLineIndex, setActiveLineIndex] = useState(0);
  const isRowDirection = direction === "row";
  const activeAxis = isRowDirection ? "x" : "y";
  const activeAnchor = isRowDirection ? "left" : "top";
  const activeDirection = isRowDirection ? "width" : "height";

  return <>
    <NewLineIndicator
      direction={direction}
      onAddNewPosition={(newPos) =>
        onAddNewPosition(newPos / bounds[activeDirection])
      }
    />
    {positions.map((pos, index) => {
      const position = pos * bounds[activeDirection];
      const isActive = activeLineIndex === index;
      if (index === 0) return null;

      return (
        <Fragment key={index}>
          <Draggable
            axis={activeAxis}
            bounds="parent"
            position={{ x: 0, y: 0, [activeAxis]: position - 1 }}
            onStart={(e) => e.stopPropagation()}
            onStop={(event, data) => {
              onUpdatePosition(
                index,
                data[activeAxis] / bounds[activeDirection]
              );
            }}
          >
            <div
              className={cx(
                classes.line,
                isRowDirection
                  ? classes.verticalLine
                  : classes.horizontalLine,
                {
                  [classes.activeLine]: isActive,
                }
              )}
            ></div>
          </Draggable>
          <div
            className={cx(
              classes.barMarker,
              isRowDirection
                ? classes.verticalBarMarker
                : classes.horizontalBarMarker,
              {
                [classes.barMarkerActive]: isActive,
              }
            )}
            style={{
              [activeAnchor]: position - 9,
            }}
            onMouseOver={(e) => {
              setActiveLineIndex(index);
            }}
            onMouseLeave={(e) => {
              setActiveLineIndex(0);
            }}
          >
            {isActive && (
              <IconButton
                className={classes.removeButton}
                onClick={() => onRemovePosition(index)}
                size="large"
              >
                <CloseIcon style={{ fontSize: 14 }} />
              </IconButton>
            )}
          </div>
        </Fragment>
      );
    })}
  </>;
};

export default MagicLines;
