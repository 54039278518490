import rules, { Role, Permission } from "rbac-rules";
import { useAuth } from "contexts/AuthContext";

export const accessCheck = (roles: Role[], action: Permission, data?: any) => {
  const permissions = roles.reduce<{
    static: Permission[];
    dynamic: { [key: string]: Function };
  }>(
    (acc, role) => {
      return {
        static: [...acc.static, ...((rules[role] || {}).static || [])],
        dynamic: { ...acc.dynamic, ...((rules[role] || {}).dynamic || {}) },
      };
    },
    { static: [], dynamic: {} }
  );

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }
    return permissionCondition(data);
  }
  return false;
};

interface CanProps {
  /**
   * Set a role that you want to check the permission for. By default, this is the currentRole of the user!
   */
  roles?: Role[];
  perform: Permission;
  data?: any;
  yes: CallableFunction;
  no: CallableFunction;
}

const Can = ({ roles, perform, data, yes, no }: CanProps) => {
  const { user } = useAuth();
  if (!roles && !user) return null;

  return accessCheck(
    roles || (user && user.allowedRoles) || [Role.Default],
    perform,
    data
  )
    ? yes()
    : no();
};

export default Can;
