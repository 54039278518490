import { useState, useCallback } from "react";

/**
 * A hook to handle toggling a state variable between true and false.
 *
 * state - set to any to have an ability to use it where ever you need, but we allow only boolean to set it as the new state
 */
const useToggle = (
  initial: boolean = false
): [boolean, (state?: any) => void] => {
  const [on, setOn] = useState(initial);

  const toggle = useCallback(
    state => {
      if (typeof state === "boolean") {
        setOn(state);
      } else {
        setOn(prevState => !prevState);
      }
    },
    [setOn]
  );

  return [on, toggle];
};

export default useToggle;
