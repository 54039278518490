import { gql } from "@apollo/client";

export const UPDATE_SETTING_VALUE_BY_ID = gql`
  mutation UPDATE_SETTING_VALUE_BY_ID(
    $id: uuid
    $reference_id: String
    $reference_type_id: String
    $setting_id: uuid
    $value: jsonb
    $is_template: Boolean
  ) {
    update_setting_value(
      where: {
        id: { _eq: $id }
        reference_id: { _eq: $reference_id }
        reference_type_id: { _eq: $reference_type_id }
        setting_id: { _eq: $setting_id }
        is_template: { _eq: $is_template }
      }
      _set: { value: $value }
    ) {
      returning {
        id
        value
        display_name
      }
    }
  }
`;

export const UPDATE_SETTING_VALUE = gql`
  mutation UPDATE_SETTING_VALUE(
    $reference_id: String
    $reference_type_id: String
    $setting_id: uuid
    $value: jsonb
    $is_template: Boolean
  ) {
    update_setting_value(
      where: {
        reference_id: { _eq: $reference_id }
        reference_type_id: { _eq: $reference_type_id }
        setting_id: { _eq: $setting_id }
        is_template: { _eq: $is_template }
      }
      _set: { value: $value }
    ) {
      returning {
        id
        value
        display_name
      }
    }
  }
`;

export const CREATE_SETTING_VALUE = gql`
  mutation CREATE_SETTING_VALUE(
    $setting_value: [setting_value_insert_input!]!
  ) {
    insert_setting_value(objects: $setting_value) {
      returning {
        id
        display_name
        value
      }
    }
  }
`;

export const DELETE_SETTING_VALUE = gql`
  mutation DELETE_SETTING_VALUE($id: uuid!) {
    delete_setting_value(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;
