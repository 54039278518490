import React, { FC, HtmlHTMLAttributes } from "react";
import Dot from "components/Dot";
import { Text } from "components/Text";

interface FileStatusProps extends HtmlHTMLAttributes<HTMLElement> {
  file_status: string;
  adoptTextColor?: boolean;
}

const FileStatus: FC<FileStatusProps> = ({ file_status, adoptTextColor }) => {
  const getColorProp = (color: "green" | "red" | "orange" | "blue") =>
    adoptTextColor ? { color, adoptTextColor } : { bgColor: color };

  if (["archived", "created"].includes(file_status)) {
    return (
      <Dot {...getColorProp("green")}>
        <Text variant="caption">archived</Text>
      </Dot>
    );
  } else if (
    [
      "identification_requested",
      "transmission_pending",
      "transmission_generated",
      "ready_for_archive",
      "archived_pending",
      "pending",
    ].includes(file_status)
  ) {
    return (
      <Dot {...getColorProp("orange")}>
        <Text variant="caption">processing</Text>
      </Dot>
    );
  } else {
    return (
      <Dot {...getColorProp("red")}>
        <Text variant="caption">failed</Text>
      </Dot>
    );
  }
};

export default FileStatus;
