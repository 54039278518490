import React, { FC } from "react";
import {
  Box,
  BoxProps,
  FormAutocomplete,
  FormTextField,
  IconButton,
  MRTable,
  TextField,
  Typography,
} from "@periplus/ui-library";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import { LanguageType } from "i18n";
import { useFormikContext } from "formik";
import {
  DECLARATION_FORM_DEBOUNCE,
  DeclarationContainerForm,
  DeclarationForm,
  DeclarationPreviousDocumentForm,
  DeclarationSpecialMentionForm,
  FieldsRefs,
  SetDeclarationFormFieldRefFunction,
} from "../Declaration";
import EdecDomainFormAutocomplete from "domain/edec/components/EdecDomainFormAutocomplete";
import EdecCountriesFormAutocomplete from "domain/edec/components/EdecCountriesFormAutocomplete";
import FormField from "../components/FormField";
import FormConfirmationField from "../components/FormField/FormConfirmationField";
import FormAddressField from "./FormAddressField";
import { AddressType } from "domain/address/types";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PreviousDocumentsTable from "./PreviousDocumentsTable";
import ContainersTable from "./ContainersTable";
import DeclarationSpecialMentionsTable from "./DeclarationSpecialMentionsTable";
import AccountNumberField from "./AccountNumberField";
import { useApplicationState } from "contexts/ApplicationContext";
import AccountAddressTypeField from "./AccountAddressTypeField";

interface DeclarationsTabProps extends BoxProps {
  pageContainerWidth: number;
  pageContainerHeight: number;
  setFormFieldRef: SetDeclarationFormFieldRefFunction;
  onFormFieldFocus: (value: string) => void;
  onFormFieldBlur: () => void;
  formFieldsRefs: React.MutableRefObject<FieldsRefs>;
}

const DeclarationTab: FC<DeclarationsTabProps> = ({
  pageContainerWidth,
  pageContainerHeight,
  setFormFieldRef,
  onFormFieldFocus,
  onFormFieldBlur,
  formFieldsRefs,
}) => {
  const { edecData } = useApplicationState();
  const { t, i18n } = useTranslation("declarationNew");
  const language = i18n.language as LanguageType;
  const { values } = useFormikContext<DeclarationForm>();
  const [selectedSubTable, setSelectedSubTable] = React.useState<
    "previous_documents" | "containers" | "declaration_special_mentions" | null
  >(null);

  const generalFormFieldsProps = {
    onFocus: onFormFieldFocus,
    onBlur: onFormFieldBlur,
    setFormFieldRef: setFormFieldRef,
  };

  const previousDocumentsColumns = React.useMemo<
    MRTable.MRT_ColumnDef<DeclarationPreviousDocumentForm>[]
  >(
    () => [
      {
        header: t("Previous Document Type"),
        accessorKey: "previous_document_type",
        muiTableHeadCellProps: {
          sx: {
            "& .Mui-TableHeadCell-Content-Wrapper": {
              whiteSpace: "nowrap",
            },
          },
        },
      },
      {
        header: t("Description"),
        Cell: ({ row }) =>
          edecData.edec_domains["previousDocumentType"].find(
            (edec_domain) =>
              edec_domain.value === row.original.previous_document_type
          )?.[`meaning_${language}`],
      },
      {
        header: t("Number"),
        accessorKey: "previous_document_reference",
      },
      {
        header: t("Additional"),
        accessorKey: "additional_information",
      },
    ],
    [t, language, edecData.edec_domains]
  );

  const containersColumns = React.useMemo<
    MRTable.MRT_ColumnDef<DeclarationContainerForm>[]
  >(
    () => [
      {
        header: t("Container Nr"),
        accessorKey: "container_number",
      },
    ],
    [t]
  );

  const specialMentionsColumns = React.useMemo<
    MRTable.MRT_ColumnDef<DeclarationSpecialMentionForm>[]
  >(
    () => [
      {
        header: t("Comments"),
        accessorKey: "text",
      },
    ],
    [t]
  );

  if (selectedSubTable) {
    const SubTable = {
      previous_documents: PreviousDocumentsTable,
      containers: ContainersTable,
      declaration_special_mentions: DeclarationSpecialMentionsTable,
    }[selectedSubTable];
    return (
      <SubTable
        edecData={edecData}
        onReturn={() => setSelectedSubTable(null)}
        setFormFieldRef={setFormFieldRef}
        onFormFieldFocus={onFormFieldFocus}
        onFormFieldBlur={onFormFieldBlur}
        pageContainerWidth={pageContainerWidth}
        pageContainerHeight={pageContainerHeight}
        formFieldsRefs={formFieldsRefs}
      />
    );
  }

  return (
    <Box>
      <Header pageContainerWidth={pageContainerWidth} edecData={edecData} />
      <Box
        sx={(theme) => ({
          mt: 2,
          display: "grid",
          gridTemplateColumns: (() => {
            if (pageContainerWidth > 800)
              return "minmax(250px, 1fr) minmax(250px, 1fr) minmax(250px, 1fr)";

            return "1fr";
          })(),
          alignItems: "start",
          gap: 2,
        })}
      >
        <Box
          sx={{
            display: "grid",
            alignItems: "center",
            rowGap: 1.25,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              mb: 1,
            }}
          >
            <FormField
              name="gross_mass"
              {...generalFormFieldsProps}
              fillByClickMode
              renderInput={({ ...rest }) => (
                <FormTextField
                  {...rest}
                  debounce={DECLARATION_FORM_DEBOUNCE}
                  type="number"
                  label={t("Gross Mass")}
                  sx={{
                    minWidth: 50,
                  }}
                />
              )}
            />
            {" / "}
            <FormField
              name="net_mass"
              {...generalFormFieldsProps}
              fillByClickMode
              renderInput={({ ...rest }) => (
                <FormTextField
                  {...rest}
                  debounce={DECLARATION_FORM_DEBOUNCE}
                  type="number"
                  label={t("Net Mass")}
                  sx={{
                    minWidth: 50,
                  }}
                />
              )}
            />
            {"="}
            {(() => {
              const tareDistribution = (
                !!values.gross_mass && !!values.net_mass
                  ? values.gross_mass / values.net_mass
                  : 0
              ).toFixed(4);
              return (
                <Typography
                  tooltip
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    minWidth: 70,
                  }}
                >
                  {tareDistribution}
                </Typography>
              );
            })()}
          </Box>
          <FormField
            name="transport_mode"
            {...generalFormFieldsProps}
            renderInput={({ inputRef, ...rest }) => (
              <EdecDomainFormAutocomplete
                {...rest}
                options={edecData.edec_domains["transportMode"]}
                InputProps={{
                  inputRef,
                  label: t("Mode of transport"),
                }}
                sx={{
                  gridColumn: "1/-1",
                }}
              />
            )}
          />
          <FormField
            name="transport_type"
            {...generalFormFieldsProps}
            renderInput={({ inputRef, ...rest }) => (
              <EdecDomainFormAutocomplete
                {...rest}
                options={edecData.edec_domains["transportationType"]}
                InputProps={{
                  inputRef,
                  label: t("transport_type"),
                }}
                sx={{
                  gridColumn: "1/-1",
                }}
              />
            )}
          />
          <FormField
            name="transport_country"
            {...generalFormFieldsProps}
            renderInput={({ inputRef, ...rest }) => (
              <EdecCountriesFormAutocomplete
                {...rest}
                options={edecData.edec_countries}
                InputProps={{
                  inputRef,
                  label: t("C. registration CTRY"),
                }}
                sx={{
                  gridColumn: "1/-1",
                }}
              />
            )}
          />
          <FormField
            name="transport_number"
            {...generalFormFieldsProps}
            fillByClickMode
            renderInput={({ ...rest }) => (
              <FormTextField
                {...rest}
                debounce={DECLARATION_FORM_DEBOUNCE}
                label={t("Carrier ID")}
                sx={{
                  gridColumn: "1/-1",
                }}
              />
            )}
          />
          <FormField
            name="service_type"
            {...generalFormFieldsProps}
            renderInput={({ inputRef, ...rest }) => (
              <EdecDomainFormAutocomplete
                {...rest}
                options={edecData.edec_domains["serviceType"]}
                InputProps={{
                  inputRef,
                  label: t("Service Type"),
                }}
                sx={{
                  gridColumn: "1/-1",
                }}
              />
            )}
          />
          <FormField
            name="invoice_currency_type"
            {...generalFormFieldsProps}
            renderInput={({ inputRef, ...rest }) => (
              <EdecDomainFormAutocomplete
                {...rest}
                options={edecData.edec_domains["invoiceCurrencyType"]}
                InputProps={{
                  inputRef,
                  label: t("Inv. Currency Type"),
                }}
                sx={{
                  gridColumn: "1/-1",
                }}
              />
            )}
          />
          <FormField
            name="clearance_location"
            {...generalFormFieldsProps}
            renderInput={({ inputRef, ...rest }) => (
              <EdecDomainFormAutocomplete
                {...rest}
                options={edecData.edec_domains["clearanceLocation"]}
                InputProps={{
                  inputRef,
                  label: t("Clearance Location"),
                }}
                sx={{
                  gridColumn: "1/-1",
                }}
              />
            )}
          />
          <TextField
            size="medium"
            label={t("ZO-Code als Text")}
            sx={{
              gridColumn: "1/-1",
            }}
          />
          <FormField
            name="place_of_unloading"
            {...generalFormFieldsProps}
            renderInput={({ ...rest }) => (
              <FormTextField
                {...rest}
                label={t("Place of unloading")}
                sx={{
                  gridColumn: "1/-1",
                }}
              />
            )}
          />
          <FormField
            name="declaration_time"
            {...generalFormFieldsProps}
            renderInput={({ inputRef, ...rest }) => (
              <EdecDomainFormAutocomplete
                {...rest}
                options={edecData.edec_domains["declarationTime"]}
                InputProps={{
                  inputRef,
                  label: t("Declaration Time"),
                }}
                sx={{
                  gridColumn: "1/-1",
                }}
              />
            )}
          />
          <FormField
            name="declaration_type"
            {...generalFormFieldsProps}
            renderInput={({ inputRef, ...rest }) => (
              <EdecDomainFormAutocomplete
                {...rest}
                options={edecData.edec_domains["declarationType"]}
                InputProps={{
                  inputRef,
                  label: t("Declaration Type"),
                }}
                sx={{
                  gridColumn: "1/-1",
                }}
              />
            )}
          />
          <FormField
            name="reason"
            {...generalFormFieldsProps}
            renderInput={({ inputRef, ...rest }) => (
              <EdecDomainFormAutocomplete
                {...rest}
                options={edecData.edec_domains["reason"]}
                InputProps={{
                  inputRef,
                  label: t("Rsn for prov. Clear."),
                }}
                sx={{
                  gridColumn: "1/-1",
                }}
              />
            )}
          />
          <FormField
            name="lang"
            {...generalFormFieldsProps}
            renderInput={({ inputRef, ...rest }) => (
              <EdecDomainFormAutocomplete
                {...rest}
                options={edecData.edec_domains["language"]}
                InputProps={{
                  inputRef,
                  label: t("Language"),
                }}
                sx={{
                  gridColumn: "1/-1",
                }}
              />
            )}
          />
          <FormField
            name="customs_office_number"
            {...generalFormFieldsProps}
            renderInput={({ inputRef, ...rest }) => (
              <FormAutocomplete
                {...rest}
                options={edecData.edec_customs_offices.map((el) => el.number!)}
                getOptionLabel={(option) => {
                  const customsOffice = edecData.edec_customs_offices.find(
                    (el) => el.number === option
                  )!;
                  return `${customsOffice.number} ${customsOffice.name}`;
                }}
                InputProps={{
                  inputRef,
                  label: t("Customs Office Nr."),
                }}
                sx={{
                  gridColumn: "1/-1",
                }}
              />
            )}
          />
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <FormField
              name="dispatch_country"
              {...generalFormFieldsProps}
              renderInput={({ inputRef, ...rest }) => (
                <EdecCountriesFormAutocomplete
                  {...rest}
                  options={edecData.edec_countries}
                  InputProps={{
                    inputRef,
                    label: t("Dispatch Country"),
                  }}
                  fullWidth
                />
              )}
            />
            <FormConfirmationField
              name="dispatch_country_confirmation"
              {...generalFormFieldsProps}
            />
          </Box>
          <FormField
            name="correction_code"
            {...generalFormFieldsProps}
            renderInput={({ inputRef, ...rest }) => (
              <EdecDomainFormAutocomplete
                {...rest}
                options={edecData.edec_domains["correctionCode"]}
                InputProps={{
                  inputRef,
                  label: t("Correction Code"),
                }}
                sx={{
                  gridColumn: "1/-1",
                }}
              />
            )}
          />
          <FormField
            name="correction_reason"
            {...generalFormFieldsProps}
            renderInput={({ inputRef, ...rest }) => (
              <EdecDomainFormAutocomplete
                {...rest}
                options={edecData.edec_domains["correctionReason"]}
                InputProps={{
                  inputRef,
                  label: t("Correction Reason"),
                }}
                sx={{
                  gridColumn: "1/-1",
                }}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            alignItems: "center",
            rowGap: 1,
            columnGap: 1,
          }}
        >
          <FormAddressField
            label={t("Payer")}
            addressType={AddressType.payer}
            {...generalFormFieldsProps}
            disableClearable
          />
          <FormField
            name={`addresses[${AddressType.payer}].refs`}
            {...generalFormFieldsProps}
            fillByClickMode
            renderInput={({ ...rest }) => (
              <FormTextField
                {...rest}
                label={t("Reference")}
                debounce={DECLARATION_FORM_DEBOUNCE}
              />
            )}
          />
          <FormAddressField
            label={t("Consignor")}
            addressType={AddressType.consignor}
            {...generalFormFieldsProps}
          />
          <FormField
            name={`addresses[${AddressType.consignor}].refs`}
            {...generalFormFieldsProps}
            fillByClickMode
            renderInput={({ ...rest }) => (
              <FormTextField
                {...rest}
                label={t("Reference")}
                debounce={DECLARATION_FORM_DEBOUNCE}
              />
            )}
          />
          <FormAddressField
            label={t("Consignee")}
            addressType={AddressType.consignee}
            {...generalFormFieldsProps}
          />
          <FormField
            name={`addresses[${AddressType.consignee}].refs`}
            {...generalFormFieldsProps}
            fillByClickMode
            renderInput={({ ...rest }) => (
              <FormTextField
                {...rest}
                label={t("Reference")}
                debounce={DECLARATION_FORM_DEBOUNCE}
              />
            )}
          />
          <FormAddressField
            label={t("Importer")}
            addressType={AddressType.importer}
            {...generalFormFieldsProps}
          />
          <FormField
            name={`addresses[${AddressType.importer}].refs`}
            {...generalFormFieldsProps}
            fillByClickMode
            renderInput={({ ...rest }) => (
              <FormTextField
                {...rest}
                label={t("Reference")}
                debounce={DECLARATION_FORM_DEBOUNCE}
              />
            )}
          />
          <FormAddressField
            label={t("Auth. Consignee")}
            addressType={AddressType.authorized_consignee}
            {...generalFormFieldsProps}
          />
          <FormField
            name={`addresses[${AddressType.authorized_consignee}].refs`}
            {...generalFormFieldsProps}
            fillByClickMode
            renderInput={({ ...rest }) => (
              <FormTextField
                {...rest}
                label={t("Reference")}
                debounce={DECLARATION_FORM_DEBOUNCE}
              />
            )}
          />
          <FormAddressField
            label={t("Carrier")}
            addressType={AddressType.carrier}
            {...generalFormFieldsProps}
          />
          <FormField
            name={`addresses[${AddressType.carrier}].refs`}
            {...generalFormFieldsProps}
            fillByClickMode
            renderInput={({ ...rest }) => (
              <FormTextField
                {...rest}
                placeholder={t("Reference")}
                debounce={DECLARATION_FORM_DEBOUNCE}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr 1fr",
            alignItems: "center",
            rowGap: 1,
            columnGap: 1,
          }}
        >
          <IconButton
            onClick={() => setSelectedSubTable("previous_documents")}
            size="small"
            sx={{
              alignSelf: "start",
            }}
          >
            <MoreHorizIcon />
          </IconButton>
          <MRTable.Table
            columns={previousDocumentsColumns}
            data={Object.values(values.previous_documents)}
            enablePagination={false}
            enableTopToolbar={false}
            enableBottomToolbar={false}
            enableSorting={false}
            enableColumnActions={false}
            enableColumnOrdering={false}
            muiTablePaperProps={{
              sx: {
                gridColumn: "2/-1",
              },
            }}
            muiTableContainerProps={{
              sx: {
                maxHeight: "80px",
              },
            }}
          />
          <FormField
            name="transport_in_container"
            {...generalFormFieldsProps}
            renderInput={({ inputRef, ...rest }) => (
              <EdecDomainFormAutocomplete
                {...rest}
                options={edecData.edec_domains["yesNoCode"]}
                InputProps={{
                  inputRef,
                  label: t("Container"),
                }}
                sx={{ gridColumn: "2", mt: 0.5 }}
              />
            )}
          />
          <IconButton
            onClick={() => setSelectedSubTable("containers")}
            size="small"
            sx={{
              alignSelf: "start",
              gridColumn: "1",
            }}
          >
            <MoreHorizIcon />
          </IconButton>
          <MRTable.Table
            columns={containersColumns}
            data={Object.values(values.containers)}
            enablePagination={false}
            enableTopToolbar={false}
            enableBottomToolbar={false}
            enableSorting={false}
            enableColumnActions={false}
            enableColumnOrdering={false}
            muiTablePaperProps={{
              sx: {
                gridColumn: "2/-1",
              },
            }}
            muiTableContainerProps={{
              sx: {
                maxHeight: "80px",
              },
            }}
          />
          <IconButton
            onClick={() => setSelectedSubTable("declaration_special_mentions")}
            size="small"
            sx={{
              alignSelf: "start",
            }}
          >
            <MoreHorizIcon />
          </IconButton>
          <MRTable.Table
            columns={specialMentionsColumns}
            data={Object.values(values.declaration_special_mentions)}
            enablePagination={false}
            enableTopToolbar={false}
            enableBottomToolbar={false}
            enableSorting={false}
            enableColumnActions={false}
            enableColumnOrdering={false}
            muiTablePaperProps={{
              sx: {
                gridColumn: "2/-1",
              },
            }}
            muiTableContainerProps={{
              sx: {
                maxHeight: "80px",
              },
            }}
          />
          <Box
            sx={{
              gridColumn: "2/-1",
              display: "grid",
              alignItems: "start",
              rowGap: 1,
              columnGap: 1,
              mt: 2,
            }}
          >
            <FormField
              name="incoterms"
              {...generalFormFieldsProps}
              sx={{
                justifySelf: "end",
              }}
              renderInput={({ inputRef, ...rest }) => (
                <EdecDomainFormAutocomplete
                  {...rest}
                  options={edecData.edec_domains["incoterms"]}
                  disableClearable
                  InputProps={{
                    inputRef,
                    label: t("Incoterms"),
                  }}
                />
              )}
            />
            <AccountAddressTypeField
              type="customs"
              label={t("Customs address type")}
              {...generalFormFieldsProps}
            />
            <AccountNumberField
              name="customs_account_number"
              label={t("Customs account")}
              {...generalFormFieldsProps}
            />
            <AccountAddressTypeField
              type="vat"
              label={t("VAT address type")}
              {...generalFormFieldsProps}
            />
            <AccountNumberField
              name="vat_account_number"
              label={t("VAT account")}
              {...generalFormFieldsProps}
            />
            <FormField
              name="vat_number"
              {...generalFormFieldsProps}
              fillByClickMode
              sx={{
                justifySelf: "end",
              }}
              renderInput={({ ...rest }) => (
                <FormTextField
                  {...rest}
                  debounce={DECLARATION_FORM_DEBOUNCE}
                  label={t("VAT Number")}
                />
              )}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeclarationTab;
