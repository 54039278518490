import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Dialog,
  TextField,
  Radio,
  RadioGroup,
  Theme,
  FormControlLabel,
} from "@periplus/ui-library";
import { RouteProps } from "react-router";
import TemplateSelect from "./TemplateSelect";
import { useTranslation } from "react-i18next";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) =>
  ({
    radioPaddingTop: {
      paddingTop: 10,
    },

    input: {
      marginLeft: theme.spacing(3),
      width: 260,
    },

    label: {
      fontSize: 14,
      fontWeight: 500,
    }
  }));

interface ISaveTemplateDialogProps extends RouteProps {
  onClose(): void;
  onSave(createMode: boolean, value: any): void;
  onDelete(id: string): void;
  templates: any[];
  selectedTemplate: any;
}

const SaveTemplateDialog: React.FC<ISaveTemplateDialogProps> = ({
  onClose,
  onSave,
  onDelete,
  templates,
  selectedTemplate,
}) => {
  const [createMode, setCreateMode] = useState(!selectedTemplate);
  const [templateToUpdate, setTemplateToUpdate] = useState(selectedTemplate);
  const [newTemplateDisplayName, setNewTemplateDisplayName] = useState("");

  const { classes } = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    setTemplateToUpdate(
      (prev: any) =>
        prev && templates.find((option: any) => option.id === prev.id)
    );
  }, [templates]);

  const handleSave = useCallback(() => {
    const value = createMode
      ? newTemplateDisplayName.trim()
      : templateToUpdate && templateToUpdate.id;
    if (!value) return;

    onSave(createMode, value);
  }, [createMode, newTemplateDisplayName, onSave, templateToUpdate]);

  const isTemplateNamePresent = useMemo(() => {
    const isNameInput =
      createMode && newTemplateDisplayName.trim().length !== 0;
    const isNameChosen = !createMode && !!templateToUpdate;
    return isNameInput || isNameChosen;
  }, [createMode, newTemplateDisplayName, templateToUpdate]);

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="xs"
      onAbort={onClose}
      onConfirm={handleSave}
      ConfirmButtonProps={{
        disabled: !isTemplateNamePresent,
      }}
    >
      <RadioGroup
        name="save_mode"
        value={createMode}
        onChange={(event: any) => {
          setCreateMode(event.target.value === "true");
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
        }}
      >
        <FormControlLabel
          control={<Radio value={false} />}
          label={t("common:replace")}
        />
        <TemplateSelect
          className={classes.input}
          options={templates}
          value={templateToUpdate}
          onChange={(option: any) => setTemplateToUpdate(option)}
          onDelete={onDelete}
          disabled={createMode}
        />
        <FormControlLabel
          control={<Radio value={true} />}
          label={t("common:new")}
        />
        <TextField
          className={classes.input}
          label={t("common:name")}
          disabled={!createMode}
          inputProps={{
            maxLength: 50,
          }}
          onChange={(e: any) => setNewTemplateDisplayName(e.target.value)}
        />
      </RadioGroup>
    </Dialog>
  );
};

export default SaveTemplateDialog;
