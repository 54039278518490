import React from "react";
import {
  TextFieldProps,
  CircularProgress,
  Autocomplete,
  AutocompleteProps,
} from "@periplus/ui-library";
import useGetUsers from "graphql/hooks/useGetUsers";
import { User } from "graphql/hooks/useGetUsers";
import { useAuth } from "contexts/AuthContext";

interface Props<Multiple extends boolean | undefined>
  extends Omit<
    AutocompleteProps<User, Multiple, false, false>,
    "renderInput" | "options" | "value"
  > {
  userId?: string;
  multiple?: Multiple;
  label?: string;
  textFieldProps?: Partial<TextFieldProps>;
  InputProps?: any;
}

const UserAutocomplete = ({
  userId,
  multiple = false,
  onChange,
  label,
  textFieldProps = {},
  InputProps,
  ...props
}: Props<boolean>) => {
  const { user } = useAuth();
  const { data, loading, refetch } = useGetUsers({ tenantId: user?.tenantId });

  return (
    <Autocomplete
      value={data.users.find((el) => el.id === userId)}
      onChange={onChange}
      multiple={multiple}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.display_name}
      options={data.users}
      loading={loading}
      onInputChange={(e, value) =>
        refetch({
          search: `%${value}%`,
        })
      }
      fullWidth
      limitTags={3}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <React.Fragment>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
          </React.Fragment>
        ),
      }}
      {...props}
    />
  );
};

export default UserAutocomplete;
