import React, { FC } from "react";
import { Autocomplete, InputAdornment } from "@periplus/ui-library";
import { useDebounceCallback } from "@react-hook/debounce";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import useGetDeclarationsList, {
  DeclarationListEntity,
} from "./useGetDeclarationsList";

interface DeclarationIdentification {
  declaration?: DeclarationListEntity;
}

const MRTAutocomplete: FC<DeclarationIdentification> = ({ declaration }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [value, setValue] = React.useState<DeclarationListEntity | null>(null);
  React.useEffect(() => {
    if (declaration) setValue(declaration);
  }, [declaration]);

  const { data, loading, refetch } = useGetDeclarationsList({
    variables: {
      _like: `%${declaration?.file.identification ?? ""}%`,
    },
    fetchPolicy: "no-cache",
  });

  const debouncedSearch = useDebounceCallback((search: string) => {
    if (search) {
      refetch({
        _like: `%${search}%`,
      });
    }
  }, 100);

  return (
    <Autocomplete
      disableClearable
      freeSolo
      loading={loading}
      sx={{ width: 230, minWidth: 140 }}
      options={data?.declarations ? [value, ...data.declarations] : [value]}
      value={value!}
      filterSelectedOptions
      isOptionEqualToValue={(option, selected) =>
        option?.file_id === selected?.file_id &&
        option?.dr_no === selected?.dr_no
      }
      renderOption={(props, option) => (
        <li {...props}>{option?.file.identification}</li>
      )}
      getOptionLabel={(option) => {
        return (option as DeclarationListEntity)?.file.identification ?? "";
      }}
      onChange={(event, newValue) => {
        const castedNewValue = newValue as DeclarationListEntity;
        setValue(castedNewValue);
        history.push(
          `/select/declaration/${encodeURIComponent(castedNewValue.file_id)}/${
            castedNewValue.dr_no
          }`
        );
      }}
      onInputChange={(event, newInputValue) => {
        debouncedSearch(newInputValue);
      }}
      InputProps={{
        InputProps: {
          placeholder: `${t("Search")}...`,
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        },
      }}
    />
  );
};

export default MRTAutocomplete;
