import { TextNaked } from "components/Text";
import React from "react";

export default (type: string, value: string, size: number = 14) => {
  return (
    <TextNaked style={{ fontWeight: 400, fontSize: size }}>
      {type}:{value}
    </TextNaked>
  );
};
