import React, { FC } from "react";
import {
  Box,
  BoxProps,
  FormAutocomplete,
  FormTextField,
  IconButton,
  MRTable,
  TextField,
  Theme,
  Typography,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { LanguageType } from "i18n";
import {
  DECLARATION_FORM_DEBOUNCE,
  DeclarationForm,
  DeclarationGoodsItemForm,
  FieldsRefs,
  SetDeclarationFormFieldRefFunction,
} from "../Declaration";
import { useFormikContext } from "formik";
import EdecDomainFormAutocomplete from "domain/edec/components/EdecDomainFormAutocomplete";
import EdecCountriesFormAutocomplete from "domain/edec/components/EdecCountriesFormAutocomplete";
import FormConfirmationField from "../components/FormField/FormConfirmationField";
import FormField from "../components/FormField";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PackagingsTable from "./PackagingsTable";
import ProducedDocumentsTable from "./ProducedDocumentsTable";
import NestedTableFormContainer from "../components/NestedTable/NestedTableFormContainer";
import NestedTable from "../components/NestedTable/NestedTable";
import NestedTableFormId from "../components/NestedTable/NestedTableFormId";
import { EdecData } from "domain/edec/useGetEdecMasterData";
import CommodityCodeFormAutocomplete from "domain/declaration/components/CommodityCodeAutocomplete/CommodityCodeFormAutocomplete";

interface PositionsTabProps extends BoxProps {
  edecData: EdecData;
  pageContainerWidth: number;
  pageContainerHeight: number;
  setFormFieldRef: SetDeclarationFormFieldRefFunction;
  onFormFieldFocus: (value: string) => void;
  onFormFieldBlur: () => void;
  formFieldsRefs: React.MutableRefObject<FieldsRefs>;
}

const BREAK_POINT = 800;

const PositionsTab: FC<PositionsTabProps> = ({
  edecData,
  pageContainerWidth,
  pageContainerHeight,
  setFormFieldRef,
  onFormFieldFocus,
  onFormFieldBlur,
  formFieldsRefs,
}) => {
  const { t, i18n } = useTranslation("declarationNew");
  const language = i18n.language as LanguageType;

  const generalFormFieldsProps = {
    onFocus: onFormFieldFocus,
    onBlur: onFormFieldBlur,
    setFormFieldRef: setFormFieldRef,
  };

  const columns = React.useMemo<
    MRTable.MRT_ColumnDef<DeclarationGoodsItemForm>[]
  >(
    () => [
      {
        header: t("Position Nr."),
        id: "customs_item_number",
        accessorFn: (originalRow) => originalRow.customs_item_number,
        Cell: ({
          row: {
            original: { customs_item_number },
          },
        }) => (
          <NestedTableFormId
            id={customs_item_number}
            sx={{ minHeight: 18, minWidth: 18, maxHeight: 18 }}
          />
        ),
      },
      {
        header: t("Commercial Good"),
        id: "commercial_good",
        accessorFn: (originalRow) => {
          console.log(
            originalRow.commercial_good,
            edecData.edec_domains.commercialGood
          );
          return edecData.edec_domains.commercialGood.find(
            (el) => el.value === originalRow.commercial_good
          )?.[`meaning_${language}`];
        },
      },
      {
        header: t("Clearance Type"),
        id: "clearance_type",
        accessorFn: (originalRow) =>
          edecData.edec_domains.customsClearanceType.find(
            (el) => el.value === originalRow.clearance_type?.toString()
          )?.[`meaning_${language}`],
      },
      {
        header: t("Country of Origin"),
        id: "origin_country",
        accessorFn: (originalRow) => {
          const country = edecData.edec_countries.find(
            (el) => el.iso_code === originalRow.origin_country
          );
          return country
            ? `${country.iso_code} - ${country[`name_${language}`]}`
            : "";
        },
      },
      {
        header: t("Commodity Code"),
        id: "commodity_code",
        accessorFn: (originalRow) => originalRow.commodity_code?.commodityCode,
      },
      {
        header: t("Tariff-Additon"),
        id: "statistical_code",
        accessorFn: (originalRow) => originalRow.statistical_code,
      },
      {
        header: t("Description"),
        id: "description",
        accessorFn: (originalRow) => originalRow.description,
      },
      {
        header: t("Net Weight"),
        id: "net_mass",
        accessorFn: (originalRow) => originalRow.net_mass,
      },
      {
        header: t("Gross Mass"),
        id: "gross_mass",
        accessorFn: (originalRow) => originalRow.gross_mass,
      },
      {
        header: t("Cstms-Net Weight"),
        id: "customs_net_weight",
        accessorFn: (originalRow) => originalRow.customs_net_weight,
      },
      {
        header: t("Additional Unit"),
        id: "additional_unit",
        accessorFn: (originalRow) => originalRow.additional_unit,
      },
      {
        header: t("Stat Value"),
        id: "statistical_value",
        accessorFn: (originalRow) => originalRow.statistical_value,
      },
      {
        header: t("Vat Value"),
        id: "vat_value",
        accessorFn: (originalRow) => originalRow.vat_value,
      },
      {
        header: t("Preference"),
        id: "origin_preference",
        accessorFn: (originalRow) =>
          edecData.edec_domains.yesNoCode.find(
            (el) => el.value === originalRow.origin_preference
          )?.[`meaning_${language}`]!,
      },
    ],
    [t, language, edecData.edec_domains]
  );

  const {
    values: { goods_items },
  } = useFormikContext<DeclarationForm>();
  const [selectedSubTable, setSelectedSubTable] = React.useState<{
    name: "packagings" | "produced_documents";
    customs_item_number: number;
  } | null>(null);

  if (selectedSubTable) {
    const SubTable = {
      packagings: PackagingsTable,
      produced_documents: ProducedDocumentsTable,
    }[selectedSubTable.name];
    return (
      <SubTable
        name={`goods_items[${selectedSubTable.customs_item_number}].${selectedSubTable.name}`}
        edecData={edecData}
        onReturn={() => setSelectedSubTable(null)}
        setFormFieldRef={setFormFieldRef}
        onFormFieldFocus={onFormFieldFocus}
        onFormFieldBlur={onFormFieldBlur}
        pageContainerWidth={pageContainerWidth}
        pageContainerHeight={pageContainerHeight}
        formFieldsRefs={formFieldsRefs}
      />
    );
  }

  return (
    <NestedTable
      name="goods_items"
      identifier="customs_item_number"
      columns={columns}
      defaultItem={{
        selection_result: null,
        commodity_code: null,
        commodity_code_confirmation: null,
        statistical_code: null,
        duty_rate: null,
        rate_confirmation: null,
        description: null,
        net_duty: null,
        net_mass: null,
        net_mass_confirmation: null,
        tare_supplement: null,
        tare_supplement_confirmation: null,
        gross_mass: null,
        gross_mass_confirmation: null,
        customs_net_weight: null,
        additional_unit: null,
        additional_unit_confirmation: null,
        commercial_good: null,
        clearance_type: null,
        customs_favour_code: null,
        origin_country: null,
        origin_preference: null,
        preference_confirmation: null,
        vat_code: null,
        vat_code_confirmation: null,
        statistical_value: null,
        statistical_value_confirmation: null,
        vat_value: null,
        vat_value_confirmation: null,
        repair: null,
        packagings: [],
        produced_documents: [],
      }}
      formFieldsRefs={formFieldsRefs}
      pageContainerHeight={pageContainerHeight}
      renderForm={({
        selectedItemRowNumber,
        getTableGeneralFormFieldProps,
        ref,
      }) => (
        <NestedTableFormContainer
          ref={ref}
          sx={(theme: Theme) => ({
            display: "grid",
            alignItems: "start",
            gridTemplateColumns:
              pageContainerWidth > BREAK_POINT ? "1fr 1fr" : "1fr",
            gap: 3,
            overflowY: "scroll",
            p: 1,
            border: 1,
            borderColor: "#C9C9DE",
            borderRadius: 0.5,
          })}
        >
          <Box
            sx={(theme) => ({
              display: "grid",
              gridTemplateColumns: "auto 1fr auto 16px 1fr auto",
              alignItems: "center",
              columnGap: 1,
              rowGap: 1.25,
            })}
          >
            <Box
              sx={{
                display: "flex",
                gridColumn: "1/-1",
                alignItems: "center",
              }}
            >
              <Typography>{t("Row Nr.")}</Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  ml: 1,
                  flexGrow: 1,
                }}
              >
                <NestedTableFormId id={selectedItemRowNumber} />
                <TextField size="small" disabled fullWidth />
              </Box>
            </Box>
            <IconButton
              onClick={() =>
                setSelectedSubTable({
                  name: "packagings",
                  customs_item_number: selectedItemRowNumber!,
                })
              }
              size="small"
              disabled={!selectedItemRowNumber}
              sx={{
                alignSelf: "start",
              }}
            >
              <MoreHorizIcon />
            </IconButton>
            <MRTable.Table
              columns={[
                {
                  header: t("Packaging Type"),
                  accessorKey: "packaging_type",
                  muiTableHeadCellProps: {
                    sx: {
                      "& .Mui-TableHeadCell-Content-Wrapper": {
                        whiteSpace: "nowrap",
                      },
                    },
                  },
                },
                {
                  header: t("Desc"),
                  Cell: ({ row }) =>
                    edecData.edec_domains["packagingType"].find(
                      (el) => el.value === row.original.packaging_type
                    )?.[`meaning_${language}`],
                },
                {
                  header: t("Quantity"),
                  accessorKey: "packaging_quantity",
                },
                {
                  header: t("Pck.Ref. Nr"),
                  accessorKey: "packaging_reference_number",
                },
              ]}
              data={
                selectedItemRowNumber
                  ? goods_items[selectedItemRowNumber].packagings
                  : []
              }
              enablePagination={false}
              enableTopToolbar={false}
              enableBottomToolbar={false}
              enableSorting={false}
              enableColumnActions={false}
              enableColumnOrdering={false}
              muiTableContainerProps={{
                sx: {
                  maxHeight: "80px",
                },
              }}
              muiTablePaperProps={{
                sx: {
                  gridColumn: "2/-1",
                },
              }}
            />
            <IconButton
              onClick={() =>
                setSelectedSubTable({
                  name: "produced_documents",
                  customs_item_number: selectedItemRowNumber!,
                })
              }
              size="small"
              disabled={!selectedItemRowNumber}
              sx={{
                alignSelf: "start",
              }}
            >
              <MoreHorizIcon />
            </IconButton>
            <MRTable.Table
              columns={[
                {
                  header: t("Document/Attestation Type"),
                  accessorKey: "produced_document_type",
                  muiTableHeadCellProps: {
                    sx: {
                      "& .Mui-TableHeadCell-Content-Wrapper": {
                        whiteSpace: "nowrap",
                      },
                    },
                  },
                },
                {
                  header: t("Designation"),
                  Cell: ({ row }) =>
                    edecData.edec_domains["documentType"].find(
                      (el) => el.value === row.original.produced_document_type
                    )?.[`meaning_${language}`],
                },
                {
                  header: t("Number"),
                  accessorKey: "produced_document_reference_number",
                },
                {
                  header: t("Date"),
                  accessorKey: "produced_document_issue_date",
                  Cell: ({ row }) =>
                    row.original.produced_document_issue_date?.format(
                      "DD.MM.YYYY"
                    ),
                },
                {
                  header: t("Addition"),
                  accessorKey: "additional_information",
                },
              ]}
              data={
                selectedItemRowNumber
                  ? goods_items[selectedItemRowNumber].produced_documents
                  : []
              }
              enablePagination={false}
              enableTopToolbar={false}
              enableBottomToolbar={false}
              enableSorting={false}
              enableColumnActions={false}
              enableColumnOrdering={false}
              muiTableContainerProps={{
                sx: {
                  maxHeight: "80px",
                },
              }}
              muiTablePaperProps={{
                sx: {
                  gridColumn: "2/-1",
                },
              }}
            />
            <FormField
              {...getTableGeneralFormFieldProps("commodity_code")}
              {...generalFormFieldsProps}
              renderInput={({ inputRef, ...rest }) => (
                <CommodityCodeFormAutocomplete
                  {...rest}
                  InputProps={{
                    inputRef,
                    label: t("Commodity Code"),
                  }}
                  fullWidth
                  sx={{
                    gridColumn: "span 5",
                  }}
                />
              )}
            />
            <FormConfirmationField
              {...getTableGeneralFormFieldProps("commodity_code_confirmation")}
              {...generalFormFieldsProps}
            />
            <FormField
              {...getTableGeneralFormFieldProps("statistical_code")}
              {...generalFormFieldsProps}
              renderInput={({ inputRef, ...rest }) => (
                <FormAutocomplete
                  {...rest}
                  options={edecData.edec_tariff_statistical_code.map(
                    (el) => el.value
                  )}
                  getOptionLabel={(option) => option.toString()}
                  fullWidth
                  InputProps={{
                    inputRef,
                    label: t("Tariff-Additon"),
                  }}
                  sx={(theme) => ({
                    gridColumn: "span 3",
                  })}
                />
              )}
            />
            <FormField
              {...getTableGeneralFormFieldProps("duty_rate")}
              {...generalFormFieldsProps}
              fillByClickMode
              renderInput={({ ...rest }) => (
                <FormTextField
                  {...rest}
                  debounce={DECLARATION_FORM_DEBOUNCE}
                  type="number"
                  fullWidth
                  label={t("Rate")}
                  sx={{
                    gridColumn: "span 2",
                  }}
                />
              )}
            />
            <FormConfirmationField
              {...getTableGeneralFormFieldProps("rate_confirmation")}
              {...generalFormFieldsProps}
            />
            <FormField
              {...getTableGeneralFormFieldProps("description")}
              {...generalFormFieldsProps}
              fillByClickMode
              renderInput={({ ...rest }) => (
                <FormTextField
                  {...rest}
                  placeholder={t("Description of Goods")}
                  multiline
                  rows={2}
                  debounce={DECLARATION_FORM_DEBOUNCE}
                  sx={{
                    gridColumn: "1/-1",
                  }}
                />
              )}
            />
            <FormField
              {...getTableGeneralFormFieldProps("net_duty")}
              {...generalFormFieldsProps}
              renderInput={({ inputRef, ...rest }) => (
                <EdecDomainFormAutocomplete
                  {...rest}
                  options={edecData.edec_domains["yesNoCode"]}
                  InputProps={{
                    inputRef,
                    label: t("Net Duty"),
                  }}
                  sx={{
                    gridColumn: "span 3",
                  }}
                />
              )}
            />
            <FormField
              {...getTableGeneralFormFieldProps("net_mass")}
              {...generalFormFieldsProps}
              fillByClickMode
              renderInput={({ ...rest }) => (
                <FormTextField
                  {...rest}
                  debounce={DECLARATION_FORM_DEBOUNCE}
                  type="number"
                  label={t("Net Weight")}
                  sx={(theme) => ({
                    gridColumn: "5",
                  })}
                />
              )}
            />
            <FormConfirmationField
              {...getTableGeneralFormFieldProps("net_mass_confirmation")}
              {...generalFormFieldsProps}
            />
            <FormField
              {...getTableGeneralFormFieldProps("tare_supplement")}
              {...generalFormFieldsProps}
              fillByClickMode
              renderInput={({ ...rest }) => (
                <FormTextField
                  {...rest}
                  fullWidth
                  debounce={DECLARATION_FORM_DEBOUNCE}
                  type="number"
                  label={t("Tare Supplement (%)")}
                  sx={{
                    gridColumn: "span 2",
                  }}
                />
              )}
            />
            <FormConfirmationField
              {...getTableGeneralFormFieldProps("tare_supplement_confirmation")}
              {...generalFormFieldsProps}
            />
            <FormField
              {...getTableGeneralFormFieldProps("gross_mass")}
              {...generalFormFieldsProps}
              fillByClickMode
              renderInput={({ ...rest }) => (
                <FormTextField
                  {...rest}
                  debounce={DECLARATION_FORM_DEBOUNCE}
                  type="number"
                  label={t("Gross Mass")}
                  sx={(theme) => ({
                    gridColumn: "5",
                  })}
                />
              )}
            />
            <FormConfirmationField
              {...getTableGeneralFormFieldProps("gross_mass_confirmation")}
              {...generalFormFieldsProps}
            />
            <Typography
              sx={{
                gridColumn: "span 2",
              }}
            >
              {t("Calculated Weight")}
            </Typography>
            <Typography
              tooltip
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                minWidth: 70,
              }}
            >
              {(() => {
                if (!selectedItemRowNumber) return;

                const good_item = goods_items[selectedItemRowNumber];
                const mass = Number(good_item.net_duty)
                  ? good_item.net_mass
                  : good_item.gross_mass;

                return (
                  mass +
                  Math.floor(
                    Number((good_item.tare_supplement * mass).toFixed(4)) * 10
                  ) /
                    1000
                ).toFixed(4);
              })()}
            </Typography>
            <FormField
              {...getTableGeneralFormFieldProps("customs_net_weight")}
              {...generalFormFieldsProps}
              fillByClickMode
              renderInput={({ ...rest }) => (
                <FormTextField
                  {...rest}
                  debounce={DECLARATION_FORM_DEBOUNCE}
                  type="number"
                  label={t("Cstms-Net Weight")}
                  sx={(theme) => ({
                    gridColumn: "5/-1",
                  })}
                />
              )}
            />
            <FormField
              {...getTableGeneralFormFieldProps("additional_unit")}
              {...generalFormFieldsProps}
              fillByClickMode
              renderInput={({ ...rest }) => (
                <FormTextField
                  {...rest}
                  debounce={DECLARATION_FORM_DEBOUNCE}
                  label={t("Additional Unit")}
                  sx={(theme) => ({
                    gridColumn: "5",
                  })}
                />
              )}
            />
            <FormConfirmationField
              {...getTableGeneralFormFieldProps("additional_unit_confirmation")}
              {...generalFormFieldsProps}
            />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              alignItems: "center",
              columnGap: 1,
              rowGap: 1.25,
            }}
          >
            <FormField
              {...getTableGeneralFormFieldProps("commercial_good")}
              {...generalFormFieldsProps}
              renderInput={({ inputRef, ...rest }) => (
                <EdecDomainFormAutocomplete
                  {...rest}
                  options={edecData.edec_domains["commercialGood"]}
                  InputProps={{
                    inputRef,
                    label: t("Commercial Good"),
                  }}
                  sx={{
                    gridColumn: "1/-1",
                  }}
                />
              )}
            />
            <FormField
              {...getTableGeneralFormFieldProps("customs_favour_code")}
              {...generalFormFieldsProps}
              fillByClickMode
              renderInput={({ ...rest }) => (
                <FormTextField
                  {...rest}
                  debounce={DECLARATION_FORM_DEBOUNCE}
                  type="number"
                  label={t("Cstms Favour C.")}
                  sx={{
                    gridColumn: "1/-1",
                  }}
                />
              )}
            />
            <FormField
              {...getTableGeneralFormFieldProps("clearance_type")}
              {...generalFormFieldsProps}
              renderInput={({ inputRef, ...rest }) => (
                <EdecDomainFormAutocomplete
                  {...rest}
                  options={edecData.edec_domains["customsClearanceType"]}
                  InputProps={{
                    inputRef,
                    label: t("Clearance Type"),
                  }}
                  sx={{
                    gridColumn: "1/-1",
                  }}
                />
              )}
            />

            <FormField
              {...getTableGeneralFormFieldProps("origin_country")}
              {...generalFormFieldsProps}
              renderInput={({ inputRef, ...rest }) => (
                <EdecCountriesFormAutocomplete
                  {...rest}
                  options={edecData.edec_countries}
                  fullWidth
                  InputProps={{
                    inputRef,
                    label: t("Country of Origin"),
                  }}
                  sx={{
                    gridColumn: "1/-1",
                  }}
                />
              )}
            />
            <FormField
              {...getTableGeneralFormFieldProps("origin_preference")}
              {...generalFormFieldsProps}
              sx={{ gridColumn: "1" }}
              renderInput={({ inputRef, ...rest }) => (
                <EdecDomainFormAutocomplete
                  {...rest}
                  options={edecData.edec_domains["yesNoCode"]}
                  InputProps={{
                    inputRef,
                    label: t("Preference"),
                  }}
                />
              )}
            />
            <FormConfirmationField
              {...getTableGeneralFormFieldProps("preference_confirmation")}
              {...generalFormFieldsProps}
            />
            <Box sx={{ gridColumn: "1/-1", height: 16 }} />
            <FormField
              {...getTableGeneralFormFieldProps("vat_code")}
              {...generalFormFieldsProps}
              renderInput={({ inputRef, ...rest }) => (
                <EdecDomainFormAutocomplete
                  {...rest}
                  options={edecData.edec_domains["VATCode"]}
                  InputProps={{
                    inputRef,
                    label: t("MwSt. Code"),
                  }}
                />
              )}
            />
            <FormConfirmationField
              {...getTableGeneralFormFieldProps("vat_code_confirmation")}
              {...generalFormFieldsProps}
            />
            <Box sx={{ gridColumn: "1/-1", height: 16 }} />
            <FormField
              {...getTableGeneralFormFieldProps("statistical_value")}
              {...generalFormFieldsProps}
              fillByClickMode
              renderInput={({ ...rest }) => (
                <FormTextField
                  {...rest}
                  debounce={DECLARATION_FORM_DEBOUNCE}
                  type="number"
                  label={t("Stat Value")}
                />
              )}
            />
            <FormConfirmationField
              {...getTableGeneralFormFieldProps(
                "statistical_value_confirmation"
              )}
              {...generalFormFieldsProps}
            />
            <FormField
              {...getTableGeneralFormFieldProps("vat_value")}
              {...generalFormFieldsProps}
              fillByClickMode
              renderInput={({ ...rest }) => (
                <FormTextField
                  {...rest}
                  debounce={DECLARATION_FORM_DEBOUNCE}
                  type="number"
                  label={t("Vat Value")}
                />
              )}
            />
            <FormConfirmationField
              {...getTableGeneralFormFieldProps("vat_value_confirmation")}
              {...generalFormFieldsProps}
            />
            <FormField
              {...getTableGeneralFormFieldProps("repair")}
              {...generalFormFieldsProps}
              renderInput={({ inputRef, ...rest }) => (
                <EdecDomainFormAutocomplete
                  {...rest}
                  options={edecData.edec_domains["yesNoCode"]}
                  InputProps={{
                    inputRef,
                    label: t("Repair"),
                  }}
                  sx={{
                    gridColumn: "1/-1",
                  }}
                />
              )}
            />
            <Typography sx={{ gridColumn: "1/-1", justifySelf: "end" }}>
              {`${t("Differenz")}: ${t("TA - Edec")}`}
            </Typography>
            <TextField
              size="small"
              disabled
              label={t("Anzahl Colis")}
              sx={{
                gridColumn: "1/-1",
                "& .MuiInputLabel-root": {
                  color: "orange!important",
                },
              }}
            />
            <TextField
              size="small"
              disabled
              label={t("Bruttogewicht")}
              sx={{
                gridColumn: "1/-1",
                "& .MuiInputLabel-root": {
                  color: "orange!important",
                },
              }}
            />
            <TextField
              size="small"
              disabled
              label={t("Mwst Wert")}
              sx={{
                gridColumn: "1/-1",
                "& .MuiInputLabel-root": {
                  color: "orange!important",
                },
              }}
            />
          </Box>
        </NestedTableFormContainer>
      )}
    />
  );
};

export default PositionsTab;
