import React, { useMemo, useState } from "react";
import {
  ActionBar,
  ActionBarItem,
  Theme,
  Button,
  Grid,
  PageContainer,
} from "@periplus/ui-library";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LaunchIcon from "@mui/icons-material/Launch";
import { sortDocuments } from "utils/customRelevant";
import CopyToClipboard from "components/CopyToClipboard";
import { DocumentTypeFlag } from "domain/documentType/types";
import Reference from "./components/Reference";
import DocumentTypes from "./components/DocumentTypes";
import DocumentPreviewer from "./components/DocumentPreviewer";
import useGetFiles, {
  Document,
  ServerDocument,
} from "graphql/hooks/useGetFiles";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  page: {
    maxWidth: 1200,
    margin: "0 auto",
  },
  fullHeight: {
    height: "100%",
  },
  heightWithoutActionBar: {
    // can be fixed more correctly if we will change
    // calc(100vh - 200px) to calc(100% - 85px) (85px is the height of action bar with share button)
    // and also we will add `height: 100vh` in ui-library for navigation content.
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 200px)",
    },
  },
  documentListContainer: {
    paddingBottom: 0,
    height: "100%",

    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },
  button: {
    textTransform: "none",
    fontWeight: "normal",
  },
}));

const Preview = () => {
  const { classes, cx } = useStyles();
  const { t, i18n } = useTranslation();
  const { id: encodedIdentification } = useParams<{ id: string }>();
  const identification = decodeURIComponent(encodedIdentification);
  const [focused, onFocus] = useState({
    focusedIndex: "0.0",
    userTriggers: false,
  });

  const {
    data: [file],
    loading: fileLoading,
  } = useGetFiles(identification);

  const handleFocusDocumentType =
    (isDocTypeSwitched: boolean) => (index: number) => {
      onFocus({
        focusedIndex: `${index}.0`,
        userTriggers: isDocTypeSwitched,
      });
    };

  const fileDocuments: Document[] =
    file?.documents?.filter(
      (dt) => dt.document_flags & DocumentTypeFlag.readonly_relevant
    ) || [];

  const documents = useMemo<Document[]>(() => {
    const sortedDocuments: ServerDocument[] = sortDocuments(fileDocuments);

    return sortedDocuments.reduce(
      // @ts-ignore
      (acc, doc) => {
        const { documentType } = doc;

        const allPages = doc.pages.reduce(
          // @ts-ignore
          (pageAggr, page) => pageAggr.concat(page),
          []
        );

        if (!documentType) {
          return acc.concat({
            ...doc,
            document_name: `${doc.document_type} ${doc.sequence || 1}`,
            pages: allPages,
          });
        }

        const { translations } = documentType;
        const documentTypeTranslated =
          translations[i18n.language] || translations.en;

        return acc.concat({
          ...doc,
          document_name: `${documentTypeTranslated} ${doc.sequence || 1}`,
          pages: allPages,
        });
      },
      [] as any[]
    );
  }, [fileDocuments, i18n.language]);

  return (
    <PageContainer title={identification}>
      <Grid
        container
        spacing={2}
        direction="column"
        className={cx(classes.page, classes.fullHeight)}
      >
        <Grid item container>
          <ActionBar justifyContent="flex-end">
            <ActionBarItem>
              {/* eslint-disable-next-line */}
              <CopyToClipboard text={location.href}>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<LaunchIcon />}
                  className={classes.button}
                >
                  {t("common:share")}
                </Button>
              </CopyToClipboard>
            </ActionBarItem>
          </ActionBar>
        </Grid>
        <Grid item container className={classes.heightWithoutActionBar}>
          <Grid
            container
            direction="row"
            spacing={2}
            className={classes.fullHeight}
          >
            <Grid item container md={4} sm={12}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Reference
                    identification={identification}
                    declarations={file?.declarations}
                  />
                </Grid>
                <Grid item>
                  <DocumentTypes
                    fileId={file?.id}
                    fileIdentification={identification}
                    documents={documents}
                    focusedDocumentType={focused.focusedIndex}
                    onDocumentTypeClick={handleFocusDocumentType(true)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              md={8}
              sm={12}
              className={classes.documentListContainer}
            >
              <DocumentPreviewer
                loading={fileLoading}
                documents={documents}
                focusedDocument={focused}
                onScrollDocument={handleFocusDocumentType(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Preview;
