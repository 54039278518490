import React from "react";
import { Typography, TypographyProps } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import Children from "react-children-utilities";

// Note: This type is not exported by default. With this, we can actually import and use it!
type TOptions = import("i18next").default.TOptions;

interface IText extends Omit<TypographyProps, "translate"> {
  /**
   * If set as false, then the content (children) passed along will not be attempted to be translated!
   */
  translate?: boolean;
  translationOptions?: TOptions;
  textToTranslate?: string;
  component?: any;
  [key: string]: any;
}

const Text: React.FC<IText> = ({
  children,
  className,
  translate = true,
  translationOptions = {},
  textToTranslate = "",
  ...otherProps
}) => {
  const { t } = useTranslation();

  let text = Children.onlyText(children);
  if (translate) {
    text = textToTranslate
      ? t(textToTranslate, translationOptions) + text
      : t(text, translationOptions);
  }

  return (
    <Typography {...otherProps} className={className}>
      {text}
    </Typography>
  );
};

export default Text;
