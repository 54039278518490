import React, { FC, useCallback, useState } from "react";
import {
  Button,
  Panel,
  Typography,
  Loader,
  Theme,
  useMediaQuery,
  PageContainer,
  //IconButton
} from "@periplus/ui-library";
import AccessTokenDialog from "./AccessTokenDialog";
import { useTranslation } from "react-i18next";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
// import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import useGetApiKeys from "graphql/hooks/useGetApiKeys";
import dayjs from "dayjs";
import NewApiKeyPopup from "./NewApiKeyPopup";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  createButton: {
    textTransform: "capitalize",
  },
  table: {
    width: "100%",
  },
  tableHeaderRow: {
    height: 27,
  },
  tableBodyRow: {
    height: 37,
    "&:not(:last-child)": {
      "& td": {
        borderBottom: "1px solid #eeeeee",
      },
    },
  },
  tableColumn: {
    textAlign: "left",
    borderBottom: "1px solid #eeeeee",
  },
  noData: {
    textAlign: "center",
  },
}));

const AccessTokens: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const isUpTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm")
  );
  const { data, loading, refetch } = useGetApiKeys();

  const [selectedAccessToken, setSelectedAccessToken] = useState<number>();
  const [isAccessTokenDialogOpen, setIsAccessTokenDialogOpen] = useState(false);
  const [latestCreatedApiKey, setLatestCreatedApiKey] = useState<string>();

  const handleCloseAccessTokenDialog = useCallback(() => {
    setIsAccessTokenDialogOpen(false);
    setSelectedAccessToken(undefined);
  }, []);

  const handleSubmitAccessTokenDialog = useCallback(async (newApiKey) => {
    await refetch();
    setIsAccessTokenDialogOpen(false);
    setSelectedAccessToken(undefined);
    if (newApiKey) {
      setLatestCreatedApiKey(newApiKey);
    }
  }, []);

  return (
    <PageContainer title={t("navigation:access-tokens")}>
      {!loading && data?.accessTokens ? (
        <>
          <Panel
            sx={{
              "& .LuiPanel-content": {
                paddingBottom: 0,
                overflowX: "auto",
              },
            }}
            controls={
              <Button
                className={classes.createButton}
                variant="contained"
                color="primary"
                onClick={() => setIsAccessTokenDialogOpen(true)}
                endIcon={isUpTablet && <AddOutlinedIcon />}
              >
                {isUpTablet ? t("new") : <AddOutlinedIcon />}
              </Button>
            }
          >
            {data.accessTokens.length ? (
              <table className={classes.table}>
                <thead>
                  <tr className={classes.tableHeaderRow}>
                    <th className={classes.tableColumn}>
                      <Typography variant="overline" color="textSecondary">
                        {t("name")}
                      </Typography>
                    </th>
                    <th className={classes.tableColumn}>
                      <Typography variant="overline" color="textSecondary">
                        {t("settingsAccessTokens:expiresAt")}
                      </Typography>
                    </th>
                    <th className={classes.tableColumn}>
                      <Typography variant="overline" color="textSecondary">
                        {t("settingsAccessTokens:scopes")}
                      </Typography>
                    </th>
                    {/* <th
                    className={classes.tableColumn}
                    style={{ width: "10%" }}
                  /> */}
                  </tr>
                </thead>
                <tbody>
                  {data.accessTokens.map((accessToken) => (
                    <tr className={classes.tableBodyRow} key={accessToken.id}>
                      <td>
                        <Typography color="textSecondary">
                          {accessToken.display_name}
                        </Typography>
                      </td>
                      <td>
                        <Typography color="textSecondary">
                          {dayjs(accessToken.meta.expiresAt).format(
                            "DD.MM.YYYY"
                          )}
                        </Typography>
                      </td>
                      <td>
                        {accessToken.meta.scopes.split(" ").map((scope) => (
                          <Typography color="textSecondary" key={scope}>
                            {scope}
                          </Typography>
                        ))}
                      </td>
                      {/* <td style={{ width: "10%", textAlign: "right" }}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedAccessToken(accessToken.id);
                          setIsAccessTokenDialogOpen(true);
                        }}
                      >
                        <CreateOutlinedIcon />
                      </IconButton>
                      <IconButton size="small">
                        <DeleteOutlineIcon />
                      </IconButton>
                    </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className={classes.noData}>{t("noData")}</div>
            )}
          </Panel>
          {isAccessTokenDialogOpen && (
            <AccessTokenDialog
              accessTokens={data.accessTokens}
              accessToken={data.accessTokens.find(
                (accessToken) => accessToken.id === selectedAccessToken
              )}
              onClose={handleCloseAccessTokenDialog}
              onSubmit={handleSubmitAccessTokenDialog}
            />
          )}
          {latestCreatedApiKey && (
            <NewApiKeyPopup
              apiKey={latestCreatedApiKey}
              onClose={() => setLatestCreatedApiKey(undefined)}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </PageContainer>
  );
};

export default AccessTokens;
