import React, {
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import {
  Select,
  Dialog,
  Checkbox,
  Grid,
  Typography,
  Theme,
  MenuItem,
  Box,
  Autocomplete,
} from "@periplus/ui-library";
import { RouteProps } from "react-router";
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import get from "lodash/get";

import DescriptionIcon from "@mui/icons-material/Description";
import { GET_USERS_ORGIDS, IGetOrgIds } from "graphql/queries/common";
import Text from "components/Text/Text";
import useTransmitFile, { FileType } from "graphql/hooks/useTransmitFile";
import { UPDATE_ROTATION } from "graphql/mutations/page";
import { AuthContext } from "contexts/AuthContext";
import useErrorHandling from "hooks/useErrorHandling";
import UserAutocomplete from "domain/user/components/UserAutocomplete";
import { DocumentTypeFlag } from "domain/documentType/types";
import { Role } from "rbac-rules";
import { User } from "graphql/hooks/useGetUsers";
import { makeStyles } from "tss-react/mui";

type Document = {
  id: string;
  name: string;
  isSomeSelected: boolean;
  isAllSelected: boolean;
};

interface IFinishDialogProps extends RouteProps {
  onClose: () => void;
  data: any;
  rotationMutation: any;
  onSubmit: () => void;
  setOrgId?: (fileNumber: string, orgId: any) => void;
  documents?: Document[];
  selectedIds?: string[];
  orgId?: string;
}

interface IFilesData {
  documents?: any[];
  orgId?: number;
  fileNumber: string;
  isSelected?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  files: {
    marginBottom: 5,
  },

  link: {
    color: "inherit",
    textDecoration: "none",
    padding: "6px 8px",
    borderRadius: theme.shape.borderRadius,
  },

  fileNumberBox: {
    background: "#f3f3f3",
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
  },

  fieldSelect: {
    marginTop: theme.spacing(2),
  },

  sectionTitle: {
    fontSize: 16,
    fontWeight: 500,
  },

  dialogTitleRoot: {},
  whiteText: {},
}));

const FinishDialog: React.FC<IFinishDialogProps> = ({
  onClose,
  data,
  onSubmit,
  rotationMutation,
  orgId,
  setOrgId,
  documents,
}) => {
  const classifiedDocuments = useRef({});
  const { t } = useTranslation();
  const { classes } = useStyles();
  const finishedData = useTransmitFile();
  const { user } = useContext(AuthContext);
  const [isClassificationPending, setIsClassificationPending] = useState(false);

  const [keep, setKeep] = useState<{ [key: string]: boolean }>({});
  const [classificationConfirmed, setClassificationConfirmed] = useState(false);
  
  const [manualAnnotationDueDate, setManualAnnotationDueDate] =
    useState<number>(1440);
  const [notifiedUsers, setNotifiedUsers] = useState<User[]>([]);

  const userId = user && user.userId;

  const { data: userData } = useQuery<IGetOrgIds>(GET_USERS_ORGIDS, {
    variables: { userId: `{${userId}}` },
  });

  const firstOrgId = get(userData, "orgIds.0.org_id");

  const handleKeep = (id: string) => (_: any, checked: boolean) => {
    setKeep((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  useEffect(() => {
    documents?.forEach((document) => {
      if (!document.isSomeSelected) {
        setKeep((prev) => ({
          ...prev,
          [document.id]: true,
        }));
      }
    });
  }, []);

  const [updateRotation] = useMutation(UPDATE_ROTATION);
  const withErrorHandling = useErrorHandling();

  const values = useMemo<{ [key: string]: number }>(
    () =>
      data.reduce(
        (acc: any, { fileNumber, orgId }: any) => ({
          ...acc,
          [fileNumber]: orgId,
        }),
        {}
      ),
    [data]
  );

  const handleRotationUpdate = () => {
    const objects: any = Object.values(rotationMutation).map(
      ({
        id,
        page_status,
        rotate,
        thumbnailBigUrl,
        thumbnailSmallUrl,
        creation_user,
      }: any) => ({
        id,
        page_status,
        rotate,
        thumbnail_big_url: thumbnailBigUrl,
        thumbnail_small_url: thumbnailSmallUrl,
        creation_user,
      })
    );

    if (Object.keys(objects).length) {
      withErrorHandling(updateRotation, {
        variables: { objects },
      });
    }
  };

  const handleSave = () => {
    setIsClassificationPending(true);

    const documentsToDelete = Object.entries(keep)
      .filter(([, keepState]) => !keepState)
      .map(([fileId]) => fileId);

    const mergedDataOrgId = data.map((file: any) => {
      if (file.fileNumber.includes("newFileNumber")) {
        file.orgId = values[file.fileNumber];
        file.fileNumber = "";
      }
      if (!setOrgId) {
        file.orgId = orgId || firstOrgId;
      }
      if (file.type !== FileType.Declaration) {
        file.fileId = null;
      }
      if (manualAnnotationDueDate) {
        const dueDate = new Date();
        dueDate.setMinutes(dueDate.getMinutes() + manualAnnotationDueDate);
        file.documents = file.documents?.map((doc: any) => ({
          ...doc,
          manual_annotation_due_date: dueDate.toISOString(),
        }));
      }
      return {
        ...file,
        declaration_creation_assigned_to: user?.userId,
        notify_after_extraction: notifiedUsers.map((user) => user.id),
      };
    });

    handleRotationUpdate();
    finishedData(
      mergedDataOrgId,
      Object.keys(keep).filter((fileId) => keep[fileId]),
      (documents && documents.map(({ id }) => id)) || [],
      documentsToDelete || [],
      classificationConfirmed
    ).then(() => {
      setIsClassificationPending(false);
      sessionStorage.setItem("classify_documents", "");
      onSubmit();
    });
  };

  const renderOrgIdForm = useCallback(
    (files) => {
      if (!setOrgId || !userData) return null;

      return (
        <Autocomplete
          value={
            (userData.orgIds.some(
              (orgId) =>
                orgId.org_id === values[files.fileNumber] && orgId.orgId.active
            ) &&
              values[files.fileNumber]) ||
            null
          }
          getOptionLabel={(option) =>
            userData.orgIds.find((el) => el.org_id === option)!.orgId
              .org_id_description
          }
          options={
            userData.orgIds
              .filter((orgId) => orgId.orgId.active)
              .sort((a, b) => a.org_id - b.org_id)
              .map((el) => el.org_id) || []
          }
          onChange={(e, newOrgId) => setOrgId(files.fileNumber, newOrgId)}
          disabled={
            !!(!files.fileNumber.includes("newFileNumber") && files.orgId)
          }
          InputProps={{
            label: "Org ID",
          }}
        />
      );
    },
    [classes, setOrgId, userData, values]
  );

  const renderFileName = useCallback(
    (files: IFilesData, i: number) => {
      const handleFileName = (fileNumber: string) => {
        if (fileNumber.includes("newFileNumber")) {
          const checkNum = fileNumber.match(/\d/g);
          const assignNum: any = checkNum && checkNum[0];
          const correctNumber =
            checkNum === null ? "" : parseFloat(assignNum) + 1;
          const fileName = fileNumber.replace(/[0-9]/g, "");
          const fileDisplayName =
            t(`classify:${fileName}`) + " " + correctNumber;
          return fileDisplayName;
        } else {
          return fileNumber;
        }
      };

      return (
        <Box className={classes.fileNumberBox} key={i}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className={classes.files}
          >
            {handleFileName(files.fileNumber)}
          </Typography>
          {renderOrgIdForm(files)}
        </Box>
      );
    },
    [classes.fileNumberBox, classes.files, renderOrgIdForm, t]
  );

  const keepRemaining = useMemo(() => {
    let classifiedDocumentsCount = 0;

    const keptRemainingDocuments = documents ? (
      documents.map((document) => {
        const { id, name, isAllSelected } = document;

        if (isAllSelected) {
          classifiedDocumentsCount += 1;
        }

        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={1}
            key={id}
          >
            <Checkbox
              disabled={isAllSelected}
              checked={!!keep[id]}
              onChange={handleKeep(id)}
            />
            <Typography
              variant="subtitle2"
              color="textSecondary"
              title={name}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {t("classify:keep")} {name}
            </Typography>
          </Box>
        );
      })
    ) : (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Checkbox />
        <Text variant="subtitle2" color="textSecondary">
          classify:keep
        </Text>
      </Box>
    );

    if (
      classifiedDocumentsCount === (documents?.length || 0) &&
      data[0].type === FileType.Declaration
    ) {
      classifiedDocuments.current = data[0].fileId;
    }

    return keptRemainingDocuments;
  }, [documents, keep, t]);

  const renderExtractionForm = useCallback(() => {
    const shouldShowExtractionForm = data.some((file: any) =>
      file.documents.some((doc: any) =>
        [doc.flags, doc.document_flags].some(
          (flag) =>
            (flag & DocumentTypeFlag.manual_annotation_required) ===
            DocumentTypeFlag.manual_annotation_required
        )
      )
    );
    if (!shouldShowExtractionForm) return null;
    const TIME_FRAMES = [
      {
        label: t("common:minuteWithCount", { count: 15 }),
        value: 15,
      },
      {
        label: t("common:minuteWithCount", { count: 30 }),
        value: 30,
      },
      {
        label: t("common:hourWithCount", { count: 1 }),
        value: 60,
      },
      {
        label: t("common:hourWithCount", { count: 6 }),
        value: 360,
      },
      {
        label: t("common:hourWithCount", { count: 24 }),
        value: 1440,
      },
    ];

    return (
      <Box>
        {user?.allowedRoles?.includes?.(Role.CustomsBroker) && (
          <>
            <Text variant="h5" className={classes.sectionTitle}>
              classify:extraction
            </Text>
            <Select
              label={t("classify:timeframes")}
              value={manualAnnotationDueDate}
              onChange={(value) => {
                setManualAnnotationDueDate(value);
              }}
              variant="outlined"
              fullWidth
              className={classes.fieldSelect}
            >
              {TIME_FRAMES.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        <Text variant="caption">classify:timeframesDescription</Text>
        <UserAutocomplete
          label={t("classify:notifyUsers")}
          multiple
          onChange={(e, value) => setNotifiedUsers(value as User[])}
          className={classes.fieldSelect}
        />
        <Text variant="caption">classify:notifyUserDescription</Text>
      </Box>
    );
  }, [data, manualAnnotationDueDate]);

  return (
    <Dialog
      open
      mainTitle={t(`classify:finishClassification`)}
      subTitle={t(
        setOrgId
          ? "classify:finishDialogDescription"
          : "classify:freeFinishDialogDescription"
      )}
      TitleIcon={DescriptionIcon}
      onClose={() => !isClassificationPending && onClose()}
      onConfirm={handleSave}
      ConfirmButtonProps={{
        disabled: setOrgId
          ? !Object.values(values).every(Boolean)
          : !(orgId || firstOrgId),
        loading: isClassificationPending,
      }}
      DialogTitleProps={{
        classes: {
          root: classes.dialogTitleRoot,
          title: classes.whiteText,
          subTitle: classes.whiteText,
          closeButton: classes.whiteText,
        },
      }}
    >
      <Grid container={true} justifyContent="space-between" spacing={2}>
        <Grid item sm={12}>
          <Text>classify:classifiedFiles</Text>
          {data.map(renderFileName)}
        </Grid>
        <Grid item sm={6}>
          <Box mb={1} display="flex" flexDirection="column" gap={1}>
            <Text variant="h5" className={classes.sectionTitle}>
              classify:remainingFiles
            </Text>
            <Box display="flex" flexDirection="column" gap={1}>
              {keepRemaining}
            </Box>
            <Text variant="caption">classify:remainingFilesDescription</Text>
          </Box>
          {renderExtractionForm()}
        </Grid>
        <Grid item sm={6}>
          <Box mb={1} display="flex" flexDirection="column" gap={1}>
            <Text variant="h5" className={classes.sectionTitle}>
              classify:confirmClassificationTitle
            </Text>
            <Box display="flex" flexDirection="column" gap={1}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
                key="confirmClassification"
                title="classify:confirmClassificationTitle"
              >
                <Checkbox
                  onChange={() => {
                    setClassificationConfirmed(!classificationConfirmed);
                  }}
                />
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  title="confirm"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {t("classify:confirm")}
                </Typography>
              </Box>
            </Box>
            <Text variant="caption">
              classify:confirmClassificationDescription
            </Text>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default FinishDialog;
