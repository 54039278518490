import React, { useState } from "react";
import {
  IconButton,
  Typography,
  Tooltip,
  Box,
  BoxProps,
  Grid,
} from "@periplus/ui-library";
import FileCopy from "@mui/icons-material/FileCopy";
import Link from "@mui/icons-material/Link";
import CopyToClipboard from "components/CopyToClipboard";
import { makeStyles } from "tss-react/mui";

const ICON_WIDTH = 20;
const useStyles = makeStyles<{ itemsCount: number }>()(
  (theme, { itemsCount }) => ({
    root: {
      cursor: "pointer",
      position: "relative",
      width: "fit-content",
    },
    textContainer: {
      fontSize: "0.875rem",
      paddingRight: itemsCount * ICON_WIDTH,
    },
    iconContainer: {
      position: "absolute",
      left: `calc(100% - ${itemsCount * ICON_WIDTH}px)`,
      top: "50%",
      transform: "translate(0, -50%)",
    },
    linkIcon: { marginLeft: "-10px" },
  })
);

type CopyTextProps = BoxProps & {
  textToCopy?: string;
  linkToCopy?: string;
  title?: string;
  classes?: {
    root?: string;
    textContainer?: string;
    textIcon?: string;
    linkIcon?: string;
    iconContainer?: string;
  };
  [key: string]: any;
};

// TODO: find a better name and do more obvious API
/*
  Example of API to implement:

  <CopyText>{text}</CopyText> - this will show default copy icon which will copy selected text
  <CopyText textToCopy={oneText}>{anotherText}</CopyText> - this will show default copy icon which will copy text provided to `textToCopy` when shown text could be some other
  idea:
  <CopyText
    icons={[
      {
        title: "copy to clipboard",
        Icon: FileCopy,
        textToCopy: "some text here"
      },
      {
        title: "copy link to clipboard",
        Icon: Link,
        textToCopy: "/some/link/here"
      },
      ...
    ]}
  >{text}</CopyText> - this will show some text with 2 icons which user can define
*/
const CopyText = ({
  children,
  textToCopy,
  linkToCopy,
  title,
  className,
  classes,
  ...rest
}: CopyTextProps) => {
  const itemsCount = [textToCopy, linkToCopy].filter(Boolean).length;
  const { classes: innerClasses, cx } = useStyles({ itemsCount });
  const [isHovered, setHovered] = useState(false);
  // Set to any to skip ts check
  const [TooltipComponent, titleProp]: [any, { title?: string }] = title
    ? [Tooltip, { title }]
    : [React.Fragment, {}];

  return (
    <Box
      className={cx(innerClasses.root, className)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...rest}
    >
      <TooltipComponent {...titleProp}>
        <Typography
          noWrap
          className={cx(innerClasses.textContainer, classes?.textContainer)}
        >
          {children}
        </Typography>
      </TooltipComponent>
      {isHovered && (
        <Grid
          container
          item
          direction="row"
          onClick={(e) => e.stopPropagation()}
          className={cx(innerClasses.iconContainer, classes?.iconContainer)}
          style={{
            marginLeft: 8,
            gap: 8,
          }}
        >
          {textToCopy && (
            <CopyToClipboard text={textToCopy}>
              <IconButton className={classes?.textIcon} size="large">
                <FileCopy fontSize="small" />
              </IconButton>
            </CopyToClipboard>
          )}
          {linkToCopy && (
            <CopyToClipboard text={linkToCopy}>
              <IconButton
                className={cx(innerClasses.linkIcon, classes?.linkIcon)}
                size="large"
              >
                <Link fontSize="small" />
              </IconButton>
            </CopyToClipboard>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default CopyText;
