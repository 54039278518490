import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import {
  PageContainer,
  Button,
  Box,
  useTheme,
  Typography,
  FormSubmitButton,
  IconButton,
  AlertDialog,
  PageContainerSkeleton,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { Prompt, useParams } from "react-router-dom";
import PositionsTab from "./PositionsTab";
import ExportToCevConfirmDialog from "domain/declaration/components/ExportToCevConfirmDialog";
import MRTAutocomplete from "./MRTAutocomplete";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import useGetValidationV2Document from "pages/ValidationV2/useGetValidationV2Document";
import DocumentDrawer from "./DocumentDrawer";
import usePageLocalStorage from "hooks/usePageLocalStorage";
import useScheduleDataExtraction from "domain/file/useScheduleDataExtraction";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import DeclarationTab from "./DeclarationTab";
import { useResizeDetector } from "react-resize-detector";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import useGetDeclaration, {
  DeclarationListEntity,
} from "domain/declaration/useGetDeclaration";
import useUpsertDeclaration from "domain/declaration/useUpsertDeclaration";
import { useFormik, FormikProvider, Form, FormikHelpers } from "formik";
import { convertPrimitive } from "utils/common";
import {
  DeclarationAddressFormDB,
  DeclarationContainerFormDB,
  DeclarationFormDB,
  DeclarationGoodsItemFormDB,
  DeclarationGoodsItemPackagingFormDB,
  DeclarationGoodsItemProducedDocumentFormDB,
  DeclarationPreviousDocumentFormDB,
  DeclarationSpecialMentionFormDB,
} from "domain/declaration/types";
import { debounce, get } from "lodash";
import dayjs, { Dayjs } from "dayjs";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useSnackbar } from "notistack";
import SimilarityIconButton from "./SimilarityIconButton";
import { useApplicationState } from "contexts/ApplicationContext";
import * as yup from "yup";
import { AddressType } from "domain/address/types";
import useGetCommodityCodesList, {
  CommodityCodeListEntity,
} from "domain/declaration/components/CommodityCodeAutocomplete/useGetCommodityCodesList";

export interface FieldsRefs {
  [key: string]: {
    input: HTMLElement | null;
    fillByClickMode?: boolean;
  };
}
export type SetDeclarationFormFieldRefFunction = (
  name: string,
  instance: HTMLElement | null,
  fillByClickMode?: boolean
) => void;

export interface DeclarationAddressForm
  extends Omit<DeclarationAddressFormDB, "refs"> {
  refs: string;
}

export interface DeclarationPreviousDocumentForm
  extends DeclarationPreviousDocumentFormDB {}

export interface DeclarationContainerForm extends DeclarationContainerFormDB {}

export interface DeclarationSpecialMentionForm
  extends DeclarationSpecialMentionFormDB {}

export interface DeclarationGoodsItemPackagingForm
  extends DeclarationGoodsItemPackagingFormDB {}

export interface DeclarationGoodsItemProducedDocumentForm
  extends Omit<
    DeclarationGoodsItemProducedDocumentFormDB,
    "produced_document_issue_date"
  > {
  produced_document_issue_date: Dayjs | null;
}

export interface DeclarationGoodsItemForm
  extends Omit<
    DeclarationGoodsItemFormDB,
    | "packagings"
    | "produced_documents"
    | "commodity_code"
    | "net_duty"
    | "commercial_good"
    | "clearance_type"
    | "origin_preference"
    | "vat_code"
    | "repair"
  > {
  packagings: DeclarationGoodsItemPackagingForm[];
  produced_documents: DeclarationGoodsItemProducedDocumentForm[];
  commodity_code: CommodityCodeListEntity | null;
  net_duty: string | null;
  commercial_good: string | null;
  clearance_type: string | null;
  origin_preference: string | null;
  vat_code: string | null;
  repair: string | null;
}

export interface DeclarationForm
  extends Omit<
    DeclarationFormDB,
    | "transport_mode"
    | "clearance_location"
    | "declaration_time"
    | "correction_code"
    | "transport_in_container"
    | "incoterms"
    | "customs_account_address_type"
    | "customs_account_number"
    | "vat_account_address_type"
    | "vat_account_number"
    | "addresses"
    | "previous_documents"
    | "containers"
    | "declaration_special_mentions"
    | "goods_items"
  > {
  transport_mode: string | null;
  clearance_location: string | null;
  declaration_time: string | null;
  correction_code: string | null;
  transport_in_container: string | null;
  incoterms: NonNullable<DeclarationFormDB["incoterms"]>;
  customs_account_address_type: NonNullable<
    DeclarationFormDB["customs_account_address_type"]
  >;
  customs_account_number: string;
  vat_account_address_type: NonNullable<
    DeclarationFormDB["vat_account_address_type"]
  >;
  vat_account_number: string;
  addresses: Record<number, DeclarationAddressForm>;
  previous_documents: DeclarationPreviousDocumentForm[];
  containers: DeclarationContainerForm[];
  declaration_special_mentions: DeclarationSpecialMentionForm[];
  goods_items: Record<number, DeclarationGoodsItemForm>;
}

export const DECLARATION_FORM_DEBOUNCE = 50;

export const Declaration = ({
  preloadedInitialValues: { declaration, declarationForm, commodityCodes },
  refetchPreloadedInitialValues,
}: {
  preloadedInitialValues: {
    declaration: DeclarationListEntity;
    declarationForm: DeclarationFormDB;
    commodityCodes: CommodityCodeListEntity[];
  };
  refetchPreloadedInitialValues: () => Promise<any>;
}) => {
  const { t } = useTranslation("declarationNew");
  const { pageLocalStorage, setPageLocalStorage } = usePageLocalStorage({
    sideDrawerWidth: 0,
  });
  const params = useParams<{
    file_id: string;
    dr_no: string;
  }>();
  const file_id = params.file_id;
  const dr_no = Number(params.dr_no);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const {
    width: pageContainerWidth,
    height: pageContainerHeight,
    ref: pageContainerRef,
  } = useResizeDetector({
    refreshMode: "debounce",
    refreshRate: 100,
  });
  const isContainerUpMd =
    pageContainerWidth && pageContainerWidth > theme.breakpoints.values.md;
  const [isExportToCevConfirmDialogOpen, setIsExportToCevConfirmDialogOpen] =
    useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isExtractionLoading, setIsExtractionLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [unsavedChangesAlert, setUnsavedChangesAlert] = useState<
    (() => void) | null
  >(null);

  const declarationPageRef = useRef<HTMLDivElement>(null);
  const formFieldsRefs = useRef<FieldsRefs>({});
  const selectedFormFieldRef = useRef<{
    cur: string | null;
    prev: string | null;
  }>({
    cur: null,
    prev: null,
  });
  const setFormFieldRef = useCallback<SetDeclarationFormFieldRefFunction>(
    (name, instance, fillByClickMode) => {
      if (instance) {
        formFieldsRefs.current[name] = {
          input: instance,
          fillByClickMode,
        };
      } else {
        delete formFieldsRefs.current[name];
      }
    },
    []
  );

  const { edecData } = useApplicationState();

  const {
    data: file,
    loading: docsLoading,
    refetch: refetchValidationV2Document,
  } = useGetValidationV2Document({
    variables: {
      id: file_id,
    },
    skip: !declarationForm,
  });

  const upsertDeclaration = useUpsertDeclaration();
  const scheduleExtraction = useScheduleDataExtraction();

  const initialValues = useMemo<DeclarationForm>(() => {
    const {
      transport_mode,
      clearance_location,
      declaration_time,
      correction_code,
      transport_in_container,
      incoterms,
      customs_account_address_type,
      customs_account_number,
      vat_account_address_type,
      vat_account_number,
      addresses,
      previous_documents,
      containers,
      declaration_special_mentions,
      goods_items,
      ...restDeclarationFields
    } = declarationForm;

    return {
      ...restDeclarationFields,
      transport_mode: transport_mode?.toString() ?? null,
      clearance_location: clearance_location?.toString() ?? null,
      declaration_time: declaration_time?.toString() ?? null,
      correction_code: correction_code?.toString() ?? null,
      transport_in_container:
        convertPrimitive(transport_in_container, "number")?.toString() ?? null,
      incoterms: incoterms ?? "DAP",
      customs_account_address_type:
        customs_account_address_type ?? AddressType.importer,
      customs_account_number: customs_account_number?.toString() ?? null,
      vat_account_address_type:
        vat_account_address_type ?? AddressType.importer,
      vat_account_number: vat_account_number?.toString() ?? null,
      addresses: addresses.reduce<DeclarationForm["addresses"]>(
        (acc, address) => ({
          ...acc,
          [address.address_type]: { ...address, refs: address.refs[0] ?? "" },
        }),
        {}
      ),
      previous_documents:
        previous_documents.map<DeclarationPreviousDocumentForm>((el) => ({
          ...el,
        })),
      containers: containers.map<DeclarationContainerForm>((el) => ({
        ...el,
      })),
      declaration_special_mentions:
        declaration_special_mentions.map<DeclarationSpecialMentionForm>(
          (el) => ({
            ...el,
          })
        ),
      goods_items: goods_items.reduce<DeclarationForm["goods_items"]>(
        (
          acc,
          {
            packagings,
            produced_documents,
            commodity_code,
            net_duty,
            commercial_good,
            clearance_type,
            origin_preference,
            vat_code,
            repair,
            ...restGoodItemFeilds
          }
        ) => {
          return {
            ...acc,
            [restGoodItemFeilds.customs_item_number]: {
              ...restGoodItemFeilds,
              packagings: packagings.map<DeclarationGoodsItemPackagingForm>(
                (el) => ({ ...el })
              ),
              produced_documents:
                produced_documents.map<DeclarationGoodsItemProducedDocumentForm>(
                  (el) => ({
                    ...el,
                    produced_document_issue_date:
                      el.produced_document_issue_date &&
                      dayjs(el.produced_document_issue_date),
                  })
                ),
              commodity_code: commodity_code
                ? commodityCodes.find(
                    (el) => el.commodityCode === commodity_code
                  ) ?? {
                    id: 0,
                    commodityCode: commodity_code,
                    descriptionDe: "",
                    hierarchyVisual: null,
                  }
                : null,
              net_duty:
                convertPrimitive(net_duty, "number")?.toString() ?? null,
              commercial_good:
                { true: "1", false: "2" }[String(commercial_good)] ?? null,
              clearance_type: clearance_type?.toString() ?? null,
              origin_preference:
                convertPrimitive(origin_preference, "number")?.toString() ??
                null,
              vat_code: vat_code?.toString() ?? null,
              repair: convertPrimitive(repair, "number")?.toString() ?? null,
            },
          };
        },
        {}
      ),
    };
  }, [declaration, declarationForm, edecData, commodityCodes]);

  const handleSubmit = useCallback<
    (
      values: DeclarationForm,
      formikHelpers: FormikHelpers<DeclarationForm>
    ) => void | Promise<any>
  >(
    async ({
      transport_mode,
      clearance_location,
      declaration_time,
      correction_code,
      transport_in_container,
      customs_account_number,
      vat_account_number,
      addresses,
      goods_items,
      ...restDeclarationFields
    }) => {
      if (!declarationForm) return;

      await upsertDeclaration(
        {
          ...restDeclarationFields,
          transport_mode: convertPrimitive(transport_mode, "number"),
          clearance_location: convertPrimitive(clearance_location, "number"),
          declaration_time: convertPrimitive(declaration_time, "number"),
          correction_code: convertPrimitive(correction_code, "number"),
          transport_in_container: convertPrimitive(
            transport_in_container,
            "boolean"
          ),
          customs_account_number: convertPrimitive(
            customs_account_number,
            "number"
          ),
          vat_account_number: convertPrimitive(vat_account_number, "number"),
          addresses: Object.entries(addresses).map(
            ([address_type, address]) => ({
              ...address,
              address_type: Number(address_type),
              refs: `{${address.refs ?? ""}}`,
            })
          ),
          goods_items: Object.values(goods_items).map(
            ({
              produced_documents,
              commodity_code,
              net_duty,
              commercial_good,
              clearance_type,
              origin_preference,
              vat_code,
              repair,
              ...restGoodItemFields
            }) => ({
              ...restGoodItemFields,
              produced_documents: produced_documents.map((el) => ({
                ...el,
                produced_document_issue_date:
                  el.produced_document_issue_date?.format("YYYY-MM-DD"),
              })),
              commodity_code: commodity_code?.commodityCode ?? null,
              net_duty: convertPrimitive(net_duty, "boolean"),
              commercial_good:
                { "1": true, "2": false }[String(commercial_good)] ?? null,
              clearance_type: convertPrimitive(clearance_type, "number"),
              origin_preference: convertPrimitive(origin_preference, "boolean"),
              vat_code: convertPrimitive(vat_code, "number"),
              repair: convertPrimitive(repair, "boolean"),
            })
          ),
        },
        declarationForm
      );
      await refetchPreloadedInitialValues();
      enqueueSnackbar(t("common:Saved"), { variant: "success" });
    },
    [
      upsertDeclaration,
      declarationForm,
      refetchPreloadedInitialValues,
      enqueueSnackbar,
      t,
    ]
  );

  const validationSchema = useMemo(
    () =>
      yup.object({
        customs_account_number: yup.number().integer().nullable(),
        vat_account_number: yup.number().integer().nullable(),
        goods_items: yup.lazy((map) =>
          yup.object(
            Object.keys(map).reduce(
              (newMap, key) => ({
                ...newMap,
                [key]: yup.object({
                  customs_favour_code: yup.number().integer().nullable(),
                  statistical_value: yup.number().integer().nullable(),
                  packagings: yup.array().of(
                    yup.object({
                      packaging_quantity: yup.number().integer().nullable(),
                    })
                  ),
                }),
              }),
              {}
            ) as any
          )
        ) as unknown as yup.AnyObjectSchema,
      }),
    [t]
  );

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema,
  });

  const handleDataPointsSelect = useCallback(
    (newValue: string, shiftKey: boolean) => {
      if (
        selectedFormFieldRef.current.prev &&
        formFieldsRefs.current[selectedFormFieldRef.current.prev]
          .fillByClickMode
      ) {
        const mergedValue: string[] = [
          ...(shiftKey
            ? [get(formik.values, selectedFormFieldRef.current.prev)]
            : []),
          newValue,
        ];
        formik.setFieldValue(
          selectedFormFieldRef.current.prev,
          formFieldsRefs.current[
            selectedFormFieldRef.current.prev
          ].input!.getAttribute("type") === "number"
            ? convertPrimitive(mergedValue.join(""), "number")
            : mergedValue.join(" ")
        );
      }
    },
    [formik.values, formik.setFieldValue]
  );

  const handleFormFieldFocus = React.useCallback((value: string) => {
    selectedFormFieldRef.current.prev = selectedFormFieldRef.current.cur;
    selectedFormFieldRef.current.cur = value;
  }, []);
  const handleFormFieldBlur = React.useCallback(() => {
    selectedFormFieldRef.current.prev = selectedFormFieldRef.current.cur;
    selectedFormFieldRef.current.cur = null;
  }, []);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (!selectedFormFieldRef.current.prev) return;
      if (declarationPageRef.current?.contains(e.target)) {
        formFieldsRefs.current[
          selectedFormFieldRef.current.prev
        ]?.input?.focus();
      } else {
        selectedFormFieldRef.current.prev = null;
      }
    };

    const deboucedTabNavigation = debounce((shiftKey: boolean) => {
      const inputRefsArray = Object.entries(formFieldsRefs.current).map(
        (el) => el[0]
      );
      const selectedIndex = inputRefsArray.findIndex(
        (field) => field === selectedFormFieldRef.current.cur
      );
      let newSelected: string | null = null;
      if (shiftKey) {
        if (selectedIndex === 0) {
          newSelected = inputRefsArray[inputRefsArray.length - 1];
        } else {
          newSelected = inputRefsArray[selectedIndex - 1];
        }
      } else {
        if (selectedIndex === inputRefsArray.length - 1) {
          newSelected = inputRefsArray[0];
        } else {
          newSelected = inputRefsArray[selectedIndex + 1];
        }
      }
      selectedFormFieldRef.current.cur = newSelected;
      formFieldsRefs.current[newSelected]?.input?.focus();
    }, 0);
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key !== "Tab") {
        return;
      }

      e.preventDefault();
      deboucedTabNavigation(e.shiftKey);
    };

    document.addEventListener("click", handleClick);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <FormikProvider value={formik}>
      <PageContainer
        title={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: 0,
              gap: 1,
            }}
          >
            <Typography
              variant="h1"
              noWrap
              sx={{
                color: "#474A57",
              }}
            >
              {t("Declaration")}:
            </Typography>
            <MRTAutocomplete declaration={declaration} />
            {declarationForm && (
              <>
                <IconButton
                  tooltip={t("Refresh data")}
                  onClick={() => {
                    const action = async () => {
                      setRefreshLoading(true);
                      await refetchPreloadedInitialValues();
                      formik.resetForm();
                      await refetchValidationV2Document();
                      setRefreshLoading(false);
                    };
                    if (formik.dirty) {
                      setUnsavedChangesAlert(() => action);
                    } else {
                      action();
                    }
                  }}
                  loading={refreshLoading}
                >
                  <RefreshIcon />
                </IconButton>
                <SimilarityIconButton
                  file_id={file_id}
                  dr_no={dr_no}
                  ai_results={declaration?.ai_results}
                  onCopy={async () => await refetchPreloadedInitialValues()}
                />
              </>
            )}
          </Box>
        }
        sideDrawerContent={
          <DocumentDrawer
            file_id={file_id}
            dr_no={dr_no}
            ai_confirmed={declaration?.ai_confirmed}
            documents={file?.documents}
            loading={docsLoading}
            pageRef={declarationPageRef}
            onDataPointClick={handleDataPointsSelect}
            selectedFormFieldRef={selectedFormFieldRef}
            selectedTab={selectedTab}
            onCreateNewDataPoint={refetchValidationV2Document}
          />
        }
        sideDrawerWidth={pageLocalStorage.sideDrawerWidth}
        sideDrawerMaxWidth={800}
        onSideDrawerResize={(newWidth) =>
          setPageLocalStorage({ sideDrawerWidth: newWidth })
        }
        tabs={[t("Declarations"), t("Positions")]}
        selectedTab={selectedTab}
        onTabChange={(newSelectedTab) => {
          setSelectedTab(newSelectedTab);
          formFieldsRefs.current = {};
        }}
        controls={
          <Box
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            {file_id && (
              <Button
                onClick={() => {
                  const action = async () => {
                    setIsExtractionLoading(true);
                    await scheduleExtraction(file_id, dr_no);
                    await refetchPreloadedInitialValues();
                    formik.resetForm();
                    setIsExtractionLoading(false);
                  };
                  if (formik.dirty) {
                    setUnsavedChangesAlert(() => action);
                  } else {
                    action();
                  }
                }}
                loading={isExtractionLoading}
                variant="outlined"
                endIcon={isContainerUpMd && <GetAppOutlinedIcon />}
                sx={{
                  background: "white",
                }}
              >
                {t("Schedule extraction")}
              </Button>
            )}
            {declarationForm && (
              <>
                {(() => {
                  const exportToCargoDisabled =
                    declaration?.declaration_request?.cev_status !==
                    "cev_in_progress";
                  return (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setIsExportToCevConfirmDialogOpen(true);
                      }}
                      endIcon={isContainerUpMd && <ArrowRightAltIcon />}
                      disabled={exportToCargoDisabled}
                      {...(exportToCargoDisabled && {
                        tooltip: t(
                          "Cannot be exported while CEV is not in progress"
                        ),
                      })}
                      sx={{
                        marginLeft: "auto",
                        pointerEvents: "all",
                      }}
                    >
                      {t(isContainerUpMd ? "Export to Cargo" : "Export")}
                    </Button>
                  );
                })()}
                <FormSubmitButton
                  variant="outlined"
                  endIcon={isContainerUpMd && <SaveOutlinedIcon />}
                  notifyOnError
                >
                  {isContainerUpMd ? t("Save") : <SaveOutlinedIcon />}
                </FormSubmitButton>
              </>
            )}
          </Box>
        }
        ContentContainerProps={{
          ref: pageContainerRef,
          sx: {
            display: "flex",
            flexDirection: "column",
            ...(!Boolean(declarationForm) && {
              alignItems: "center",
              justifyContent: "center",
            }),
          },
        }}
      >
        {(() => {
          if (!declarationForm)
            return <Typography variant="h6">{t("No Declaration")}</Typography>;

          return (
            <Form>
              {(() => {
                const generalTabsProps = {
                  edecData,
                  pageContainerWidth: pageContainerWidth!,
                  pageContainerHeight: pageContainerHeight!,
                  setFormFieldRef,
                  onFormFieldFocus: handleFormFieldFocus,
                  onFormFieldBlur: handleFormFieldBlur,
                  formFieldsRefs,
                };
                switch (selectedTab) {
                  case 0:
                    return <DeclarationTab {...generalTabsProps} />;
                  case 1:
                    return <PositionsTab {...generalTabsProps} />;
                }
              })()}
            </Form>
          );
        })()}
      </PageContainer>
      <Prompt when={formik.dirty} message={t("alerts:unsavedChanges")} />
      {unsavedChangesAlert && (
        <AlertDialog
          onClose={() => setUnsavedChangesAlert(null)}
          variant={"warning"}
          onConfirm={() => {
            unsavedChangesAlert();
            setUnsavedChangesAlert(null);
          }}
        >
          <Typography variant="subtitle1">
            {t(
              "You have unsaved changes, this action will cause them to be lost."
            )}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>
            {t("Are you sure you want to continue?")}
          </Typography>
        </AlertDialog>
      )}
      {isExportToCevConfirmDialogOpen && (
        <ExportToCevConfirmDialog
          file_id={file_id}
          dr_no={dr_no}
          onClose={() => {
            setIsExportToCevConfirmDialogOpen(false);
          }}
        />
      )}
    </FormikProvider>
  );
};

export default () => {
  const params = useParams<{
    file_id: string;
    dr_no: string;
  }>();
  const file_id = params.file_id;
  const dr_no = Number(params.dr_no);
  const initLoad = useRef(true);

  const {
    data: { declaration, declarationForm },
    refetch: refetchDeclaration,
    loading: declarationLoading,
  } = useGetDeclaration({
    file_id,
    dr_no,
  });

  const {
    data: { commodityCodes } = { commodityCodes: [] },
    loading: initialCommodityCodesLoading,
  } = useGetCommodityCodesList({
    variables: {
      ids:
        declarationForm?.goods_items
          .filter((el) => el.commodity_code)
          .map((el) => el.commodity_code!) ?? [],
    },
    skip: !declarationForm,
  });

  if (initLoad.current && (declarationLoading || initialCommodityCodesLoading))
    return <PageContainerSkeleton />;

  initLoad.current = false;

  return (
    <Declaration
      preloadedInitialValues={{
        declaration: declaration!,
        declarationForm: declarationForm!,
        commodityCodes,
      }}
      refetchPreloadedInitialValues={refetchDeclaration}
    />
  );
};
