import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  ReactElement,
} from "react";
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  MRTable,
  MenuItem,
  Typography,
} from "@periplus/ui-library";
import {
  DeclarationForm,
  DeclarationPreviousDocumentForm,
  DeclarationContainerForm,
  DeclarationGoodsItemForm,
  DeclarationSpecialMentionForm,
  DeclarationGoodsItemPackagingForm,
  DeclarationGoodsItemProducedDocumentForm,
  FieldsRefs,
} from "../../Declaration";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import AddIcon from "@mui/icons-material/Add";
import { get } from "lodash";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useResizeDetector } from "react-resize-detector";

interface Props<
  T extends
    | DeclarationPreviousDocumentForm
    | DeclarationContainerForm
    | DeclarationSpecialMentionForm
    | DeclarationGoodsItemPackagingForm
    | DeclarationGoodsItemProducedDocumentForm
    | DeclarationGoodsItemForm
> {
  name: string;
  label?: string;
  identifier?: keyof T;
  columns: MRTable.MRT_ColumnDef<T>[];
  defaultItem: Partial<T>;
  onReturn?: () => void;
  renderForm: (props: {
    selectedItemRowNumber?: number;
    getTableGeneralFormFieldProps: (fieldName: keyof T) => {
      name: string;
      disabled: boolean;
    };
    ref: React.MutableRefObject<HTMLDivElement | null>;
  }) => ReactElement;
  formFieldsRefs: React.MutableRefObject<FieldsRefs>;
  pageContainerHeight: number;
}

export default function <
  T extends
    | DeclarationPreviousDocumentForm
    | DeclarationContainerForm
    | DeclarationSpecialMentionForm
    | DeclarationGoodsItemPackagingForm
    | DeclarationGoodsItemProducedDocumentForm
    | DeclarationGoodsItemForm
>({
  name,
  label,
  identifier,
  columns,
  defaultItem,
  onReturn,
  renderForm,
  formFieldsRefs,
  pageContainerHeight,
}: Props<T>) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<DeclarationForm>();
  const items: Record<number, T> | T[] = get(values, name);
  const navigationContainerRef = useRef<HTMLDivElement | null>(null);
  const { ref: rdTableFormRef } = useResizeDetector({
    refreshMode: "debounce",
    refreshRate: 400,
  });
  const tableFormRef: React.MutableRefObject<HTMLDivElement | null> =
    rdTableFormRef;
  const tableBodyRef = useRef<HTMLTableSectionElement | null>(null);

  const itemsArray = useMemo(() => Object.values(items), [items]);

  const getRowId = useCallback(
    (obj: T) =>
      identifier ? (obj[identifier] as number) : itemsArray.indexOf(obj),
    [identifier, itemsArray]
  );

  const [singleSelectedRow, setSingleSelectedRow] = useState(
    itemsArray[0] ? getRowId(itemsArray[0]) : undefined
  );
  const [rowSelection, setRowSelection] =
    useState<MRTable.MRT_RowSelectionState>({});
  const [columnOrder, setColumnOrder] = useState<MRTable.MRT_ColumnOrderState>([
    "mrt-row-select",
    ...columns.map((c) => c.id!),
  ]);

  React.useEffect(() => {
    if (!onReturn) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key !== "Escape") {
        return;
      }

      onReturn();
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onReturn]);

  const getTableGeneralFormFieldProps = useCallback(
    (fieldName: keyof T) => ({
      name: `${name}[${singleSelectedRow}].${fieldName.toString()}`,
      disabled: singleSelectedRow === undefined,
    }),
    [singleSelectedRow, name]
  );

  const handleAddItem = useCallback(() => {
    let newId;
    if (identifier) {
      newId =
        itemsArray.reduce((acc, el) => {
          const id = getRowId(el);
          return id > acc ? id : acc;
        }, 0) + 1;
      setFieldValue(`${name}[${newId}]`, {
        [identifier]: newId,
        ...defaultItem,
      });
    } else {
      newId = itemsArray.length;
      setFieldValue(`${name}[${newId}]`, defaultItem);
    }
    setSingleSelectedRow(newId);
    setTimeout(() => {
      tableBodyRef.current?.lastElementChild?.scrollIntoView();
      Object.values(formFieldsRefs.current)[0].input?.focus();
    }, 300);
  }, [itemsArray, setFieldValue, getRowId, identifier, name, defaultItem]);

  const handleDeleteItems = useCallback(
    (ids: number[]) => {
      setSingleSelectedRow((prevSingleSelected) =>
        ids.some((id) => id === prevSingleSelected)
          ? undefined
          : prevSingleSelected
      );
      setRowSelection((prev) => {
        const newRowSelection = { ...prev };
        for (const id of ids) {
          delete newRowSelection[id];
        }
        return newRowSelection;
      });
      if (identifier) {
        const newItems = { ...(items as Record<number, T>) };
        for (const id of ids) {
          delete newItems[id];
        }
        setFieldValue(name, newItems);
      } else {
        setFieldValue(
          name,
          (items as T[]).filter((_, index) => !ids.includes(index))
        );
      }
    },
    [items, identifier, name]
  );

  return (
    <Box
      sx={{
        display: "grid",
        gap: 1,
        gridTemplateRows: onReturn ? "auto 1fr auto" : "1fr auto",
        maxHeight: "calc(100vh - var(--appbar-height) - 33px)",
      }}
    >
      {onReturn && (
        <Box
          ref={navigationContainerRef}
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            mb: 1,
          }}
        >
          <IconButton onClick={onReturn}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4">{label}:</Typography>
        </Box>
      )}
      {renderForm({
        ...(singleSelectedRow !== undefined && {
          selectedItemRowNumber: identifier
            ? singleSelectedRow
            : singleSelectedRow + 1,
        }),
        getTableGeneralFormFieldProps,
        ref: tableFormRef,
      })}
      <MRTable.Table
        columns={columns}
        data={itemsArray}
        getRowId={(row) => getRowId(row).toString()}
        positionToolbarAlertBanner="none"
        enablePagination={false}
        enableBottomToolbar={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableRowNumbers={!identifier}
        muiTablePaperProps={{
          sx: {
            overflowY: "auto",
          },
        }}
        muiTopToolbarProps={{
          sx: {
            minHeight: "unset",
          },
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: (() => {
              if (!tableFormRef.current) return;

              const minHeight = 154;
              const availableHeight =
                pageContainerHeight -
                tableFormRef.current.scrollHeight -
                64 -
                (navigationContainerRef.current
                  ? navigationContainerRef.current.offsetHeight + 10
                  : 0);

              return availableHeight > minHeight ? availableHeight : minHeight;
            })(),
          },
        }}
        enableRowActions
        muiTableBodyProps={{
          ref: tableBodyRef,
        }}
        muiTableBodyRowProps={({ row }) => {
          return {
            onClick: () => setSingleSelectedRow(Number(row.id)),
            sx: {
              cursor: "pointer",
              ...(row.id === singleSelectedRow?.toString() && {
                backgroundColor: "#604dff14",
              }),
            },
          };
        }}
        enableRowSelection
        state={{ rowSelection, columnOrder }}
        onColumnOrderChange={setColumnOrder}
        onRowSelectionChange={setRowSelection}
        renderTopToolbarCustomActions={() => (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="outlined"
              endIcon={<AddIcon />}
              onClick={handleAddItem}
            >
              {t("New Position")}
            </Button>
            {!!Object.keys(rowSelection).length && (
              <Button
                variant="outlined"
                endIcon={<DeleteOutlineIcon />}
                onClick={() =>
                  handleDeleteItems(
                    Object.keys(rowSelection).map((el) => Number(el))
                  )
                }
                color="error"
              >
                {t("Delete")}
              </Button>
            )}
          </Box>
        )}
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <MenuItem
            key="delete"
            onClick={() => {
              handleDeleteItems([getRowId(row.original)]);
              closeMenu();
            }}
          >
            <ListItemIcon>
              <DeleteOutlineIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={t(`Delete`)}
              primaryTypographyProps={{ color: "error" }}
            />
          </MenuItem>,
        ]}
      />
    </Box>
  );
}
