import { BaseRequestPolicy, HttpOperationResponse } from "@azure/storage-blob";
import client from "../graphql/client";
import { GET_SAS_TOKEN } from "../graphql/queries/common";
import * as Cookies from "js-cookie";

class SasStore {
  // Get a valid SAS for blob
  public getValidSASForContainer(
    container: string,
    contentType?: string,
    contentDisposition?: string
  ) {
    // @ts-ignore
    // if (this.sasCache[container] && this.needsUpdate(this.sasCache[container])) {
    //   // @ts-ignore
    //   return this.sasCache[container];
    // } else {
    //   // @ts-ignore
    //   return (this.sasCache[container] = await this.getNewSasForContainer(container, contentType, contentDisposition));
    // }
    let key = `${container}-sasToken${contentType ? `-${contentType}` : ""}`;
    let sasToken;

    if (contentDisposition) {
      key = key + "-" + contentDisposition;
      sasToken = window.sessionStorage.getItem(key);
    } else {
      sasToken = Cookies.get(key);
    }

    const { value, expiryDate } = JSON.parse(sasToken || "{}");

    const tokenExpiryTimeInMilliseconds =
      new Date(expiryDate).getTime() - new Date().getTime();
    const tokenExpiryTimeInSeconds =
      tokenExpiryTimeInMilliseconds / 1000;

    if (tokenExpiryTimeInSeconds > 30) {
      return { value, expiryDate };
    } else {

      if (contentDisposition) {
        window.sessionStorage.removeItem(key);
        // else clause with remove from cookies not needed since cookie expires automatically
      }

      return undefined;

    }

    // return JSON.parse(Cookies.get(key) || "{}");
  }

  // Get a new SAS for blob, we assume a SAS starts with a "?"
  public async updateSASForContainer(
    container: string,
    contentType?: string,
    contentDisposition?: string
  ): Promise<{ value: string; expiryDate: string }> {
    const { data } = await client.query({
      query: GET_SAS_TOKEN,
      fetchPolicy: "no-cache",
      variables: {
        container,
        contentType,
        contentDisposition,
      },
    });

    if (data) {
      let key = `${container}-sasToken${contentType ? `-${contentType}` : ""}`;
      const value = data.getSASToken.sasToken;

      const stringifyValue = JSON.stringify({
        value,
        expiryDate: data.getSASToken.expiryDate,
      });

      if (contentDisposition) {
        key = key + "-" + contentDisposition;
        window.sessionStorage.setItem(key, stringifyValue);
      } else {
        Cookies.set(key, stringifyValue, {
          expires: new Date(data.getSASToken.expiryDate),
        });
      }
      return await {
        value: data.getSASToken.sasToken,
        expiryDate: data.getSASToken.expiryDate,
      };
    }

    return await {
      value: "",
      expiryDate: new Date().toDateString(),
    };
  }

  // Return true if "se" section in SAS is still valid in next 2 mins
  // private needsUpdate(sas: any): boolean {
  //   const expiryStringInSas = new URL(`http://hostname${ sas }`).searchParams.get("se");
  //   // @ts-ignore
  //   return new Date(expiryStringInSas) - new Date() >= 2 * 60 * 1000;
  // }
}

class SasUpdatePolicyFactory {
  private readonly sasStore: any;

  constructor(sasStore: any) {
    this.sasStore = sasStore;
  }

  create(nextPolicy: any, options: any) {
    return new SasUpdatePolicy(nextPolicy, options, this.sasStore);
  }
}

class SasUpdatePolicy extends BaseRequestPolicy {
  private sasStore: any;

  constructor(nextPolicy: any, options: any, sasStore: any) {
    super(nextPolicy, options);
    this.sasStore = sasStore;
  }

  async sendRequest(request: any): Promise<HttpOperationResponse> {
    const urlObj = new URL(request.url);
    const sas = await this.sasStore.getValidSASForContainer(
      `${urlObj.origin}${urlObj.pathname}`
    ).value;
    new URL(`http://hostname${sas}`).searchParams.forEach((value, key) => {
      urlObj.searchParams.set(key, value);
    });

    // Update request URL with latest SAS
    request.url = urlObj.toString();

    return this._nextPolicy.sendRequest(request);
  }
}

export { SasUpdatePolicy, SasUpdatePolicyFactory, SasStore };
