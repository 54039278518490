import React, { FC } from "react";
import { Box, FormCheckbox } from "@periplus/ui-library";
import { SetDeclarationFormFieldRefFunction } from "../../Declaration";
import FormFieldLabel from "./FormFieldLabel";
import FormFieldInput from "./FormFieldInput";
import { useTranslation } from "react-i18next";

interface FormFieldProps {
  name: string;
  disabled?: boolean;
  setFormFieldRef: SetDeclarationFormFieldRefFunction;
  onFocus?: (name: string) => void;
  onBlur?: () => void;
}

const FormConfirmationField: FC<FormFieldProps> = (props) => {
  const { t } = useTranslation("declaration");
  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <FormFieldInput {...props}>
        {({ size, ...rest }) => <FormCheckbox {...rest} />}
      </FormFieldInput>
      <FormFieldLabel>{t("CC")}</FormFieldLabel>
    </Box>
  );
};

export default FormConfirmationField;
