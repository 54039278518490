import React, { FC } from "react";
import { Typography, Button, Theme, Paper } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  HighCustomFeesTrigger,
  ImportListTrigger,
  TriggerTypesEnum,
  SpecificHsCodesTrigger,
} from "../../../../graphql/hooks/useGetImportListTriggers";
import { makeStyles } from 'tss-react/mui';

const colors = [
  "#2EE7AF",
  "#3971FF",
  "#FF65C0",
  "#FFB800",
  "#A258FF",
  "#EB5757",
];

interface ImportListTriggerCardProps {
  trigger: ImportListTrigger;
  index: number;
  onEditClick: (trigger: ImportListTrigger) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "grid",
    gridTemplate: `
        "title    controls"
        "divider  divider"
        "fields   fields"
        /1fr      auto
    `,
    "&:not(:last-child)": {
      marginBottom: 12,
    },
    [theme.breakpoints.up("md")]: {
      padding: 10,
      columnGap: 24,
      gridTemplate: `
            "title  divider  fields  controls"
            /175px  auto     1fr     auto
        `,
      alignItems: "center",
    },
  },
  title: {
    gridArea: "title",
    display: "flex",
    alignItems: "center",
    padding: "16px 4px 16px 16px",
    [theme.breakpoints.up("md")]: {
      padding: "unset",
    },
  },
  divider: {
    gridArea: "divider",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      borderTop: "unset",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    },
  },
  linkButton: {
    backgroundColor: "#FAFAFE",

    "& .MuiButton-startIcon": {
      margin: 0,

      [theme.breakpoints.up("md")]: {
        marginRight: 8,
        marginLeft: -4,
      },
    },
  },
  controls: {
    gridArea: "controls",
    padding: "16px 16px 16px 4px",
    display: "flex",
    alignItems: "center",
    justifySelf: "right",
    [theme.breakpoints.up("md")]: {
      padding: "unset",
    },
  },
  fields: {
    gridArea: "fields",
    padding: 16,
    display: "grid",
    alignItems: "center",
    gap: "10px",
    gridTemplateColumns: "repeat(2, auto)",
    [theme.breakpoints.up("sm")]: {
      gap: "10px 32px",
      gridTemplateColumns: "unset",
      gridTemplateRows: "repeat(2, 1fr)",
      gridAutoFlow: "column",
    },
    [theme.breakpoints.up("md")]: {
      padding: "unset",
    },
  },
  fieldValue: {
    minHeight: 18,
    textAlign: "right",
    color: "#4F4F4F",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("sm")]: {
      textAlign: "unset",
    },
  },
  importListTriggerIcon: {
    width: 36,
    height: 36,
    minWidth: 36,
    minHeight: 36,
    borderRadius: "50%",
    backgroundColor: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 16,
  },
}));

const ImportListTriggerCard: FC<ImportListTriggerCardProps> = ({
  trigger,
  index,
  onEditClick,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const triggerNameAbbreviation = trigger.display_name
    .split(" ")
    .slice(0, 2)
    .reduce((acc: any, el: any) => acc + (el[0]?.toUpperCase() || ""), "");
  const { type } = trigger;
  const { config } = trigger;
  const { recipients } = config;
  let feeAmount: number = 0;
  let specificHsCodes: string[] = [];
  let countryOfOrigin: string = "";
  switch (type) {
    case TriggerTypesEnum.HighCustomFeesTrigger:
      feeAmount = (config as HighCustomFeesTrigger).feeAmount;
      countryOfOrigin = (config as HighCustomFeesTrigger).countryOfOrigin;
      break;
    case TriggerTypesEnum.SpecificHsCodes:
      specificHsCodes = (config as SpecificHsCodesTrigger).specificHsCodes;
      break;
  }
  const color = colors[index % 6];

  return (
    <>
      <Paper className={classes.root} square variant="outlined">
        <div className={classes.title}>
          <div
            className={classes.importListTriggerIcon}
            style={{ backgroundColor: `${color}50` }}
          >
            <Typography variant="subtitle1" style={{ color }}>
              {triggerNameAbbreviation}
            </Typography>
          </div>
          <Typography variant="subtitle1" color="textPrimary">
            {trigger.display_name}
          </Typography>
        </div>
        <div className={classes.controls}>
          <div style={{ marginRight: 8 }}>
            <Button
              className={classes.linkButton}
              variant="outlined"
              color="primary"
              // @ts-ignore
              onClick={() => onEditClick(trigger)}
              startIcon={<SettingsOutlinedIcon />}
              minimizeForMobiles
            >
              {t("common:edit")}
            </Button>
          </div>
        </div>
        <div className={classes.divider} />
        <div className={classes.fields}>
          {[
            {
              label: t("common:type"),
              text: type,
            },
            ...(feeAmount
              ? [
                  {
                    label: t("importListTrigger:feeAmount"),
                    text: feeAmount,
                  },
                ]
              : []),
            {
              label: t("importListTrigger:recipients"),
              text: recipients,
            },
            ...(specificHsCodes
              ? [
                  {
                    label: t("importListTrigger:specificHsCodes"),
                    text: specificHsCodes,
                  },
                ]
              : []),
            ...(countryOfOrigin
              ? [
                  {
                    label: t("importListTrigger:countryOfOrigin"),
                    text: countryOfOrigin,
                  },
                ]
              : []),
            {
              label: t("importListTrigger:creationDate"),
              text: dayjs(trigger.creation_date).format("DD.MM.YYYY"),
            },
            {
              label: t("importListTrigger:createdBy"),
              text: trigger.creationUser.display_name,
            },
          ].map(({ label, text }) => (
            <React.Fragment key={label}>
              <Typography
                noWrap
                variant="overline"
                color="textSecondary"
                title={label}
              >
                {label}
              </Typography>
              <Typography
                noWrap
                color="textSecondary"
                title={text}
                className={classes.fieldValue}
              >
                {text}
              </Typography>
            </React.Fragment>
          ))}
        </div>
      </Paper>
    </>
  );
};

export default ImportListTriggerCard;
