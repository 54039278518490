import React, {
  FC,
  useState,
  useLayoutEffect,
  useCallback,
  useMemo,
} from "react";
import { Grid, ListSubheader, Theme } from "@periplus/ui-library";
//@ts-ignore
import { List } from "@enykeev/react-virtualized";
import Collapse from "./Collapse";
import Bookmark from "./Bookmark";
import { makeStyles } from 'tss-react/mui';

interface DocumentPagesProps {
  isExpanded: boolean;
  toggleExpand: () => void;
  title: string;
  rowCount: number;

  scrollTop: number;
  parentIndex: number;
  imgSizes: {
    height: number;
    width: number;
  };
  containerSizes: {
    height: number;
    width: number;
  };
  pagePerRow: number;
  height: number;
  children: any;
  scrollContainer: HTMLElement;
  documentOffset: number;
  documentsCount: number;
  withoutBookmarks?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  collapserTitleColor: {
    color: theme.palette.primary.main,
  },
}));

const DocumentPages: FC<DocumentPagesProps> = ({
  isExpanded,
  toggleExpand,
  scrollTop,
  parentIndex,
  title,
  rowCount,

  imgSizes,
  containerSizes,
  children,
  pagePerRow,
  height,
  scrollContainer,
  documentOffset,
  documentsCount,
  withoutBookmarks = false,
}) => {
  const { classes } = useStyles();

  const [offset, setOffset] = useState(0);
  const [bookmarkPosition, setBookmarkPosition] = useState({
    left: 0,
    top: 0,
  });
  const [backgroundColor] = useState(
    "#" + Math.random().toString(16).substr(-6)
  );

  const columns = new Array(pagePerRow).fill(null);

  useLayoutEffect(() => {
    const top = containerSizes.height * (documentOffset / height);

    const scrollContainerRect = scrollContainer?.getBoundingClientRect() || {
      right: 0,
      top: 0,
    };

    let offset = -15;

    if (parentIndex > 0 && parentIndex < documentsCount - 1) {
      offset = -(documentsCount - parentIndex) * 15;
    }

    if (top + offset < documentsCount * 15) {
      offset = parentIndex * 15;
    } else if (top + offset > containerSizes.height - documentsCount * 15) {
      offset = -(documentsCount - parentIndex) * 15;
    }

    setOffset(documentOffset);
    setBookmarkPosition({
      top: scrollContainerRect.top + (top < 0 ? 0 : top) + offset,
      left: scrollContainerRect.right,
    });
  }, [
    containerSizes.height,
    height,
    imgSizes.height,
    isExpanded,
    documentOffset,
    scrollContainer,
    parentIndex,
    documentsCount,
    offset,
  ]);

  const contentFitContainer = height <= containerSizes.height;

  const scrollToDocument = useCallback(() => {
    const scrollTo = offset - 52;
    if ("scrollTo" in scrollContainer) {
      scrollContainer.scrollTo({ top: scrollTo });
    } else {
      // @ts-ignore
      scrollContainer.scrollTop = scrollTo;
    }
  }, [offset, scrollContainer]);

  const computedScrollTop = useMemo(() => {
    const sTop = -offset + scrollTop + 53;
    return Math.max(sTop, 0);
  }, [offset, scrollTop]);

  return (
    <div>
      {!contentFitContainer &&
        !withoutBookmarks &&
        window.outerWidth >= 1024 && (
          <Bookmark
            bgcolor={backgroundColor}
            style={{
              position: "fixed",
              transform: "translate(calc(-100% - 20px))",
              ...bookmarkPosition,
            }}
            onClick={scrollToDocument}
          >
            {title}
          </Bookmark>
        )}

      <Collapse
        title={title}
        expanded={isExpanded}
        toggleExpand={toggleExpand}
        classes={{
          title: classes.collapserTitleColor,
          arrow: classes.collapserTitleColor,
        }}
        TitleComponent={({ ...props }) => (
          <ListSubheader component="div" {...props} />
        )}
      >
        {isExpanded && (
          <List
            style={{ outline: "none" }}
            scrollTop={computedScrollTop}
            width={containerSizes.width}
            autoHeight
            height={containerSizes.height}
            rowHeight={imgSizes.height}
            rowCount={rowCount}
            rowRenderer={({ index, key, style }: any) => (
              <Grid
                container
                direction="row"
                alignItems="center"
                key={key}
                style={{
                  ...style,
                  padding: "0 10px",
                  boxSizing: "border-box",
                }}
              >
                {columns.map((_, rIndex) => {
                  const pageIndex = index * pagePerRow + rIndex;
                  const path = `${parentIndex}.${pageIndex}`;

                  const renderedComponent = children({
                    style: {
                      height: imgSizes.height - 10,
                    },
                    path,
                  });

                  if (!renderedComponent) return null;

                  return (
                    <div
                      className={path}
                      key={path}
                      style={{
                        padding: 5,
                        height: imgSizes.height,
                        width: imgSizes.width - 20,
                      }}
                    >
                      {renderedComponent}
                    </div>
                  );
                })}
              </Grid>
            )}
          />
        )}
      </Collapse>
    </div>
  );
};

export default DocumentPages;
