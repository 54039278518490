import React, { FC, HtmlHTMLAttributes, useEffect } from "react";
import { Layout as LuiLayout, useLayoutContext } from "@periplus/ui-library";
import { useAuth } from "contexts/AuthContext";
import NotificationsWidget from "./NotificationsWidget";
import SidebarContent from "./SidebarContent";
import UserMenuContent from "./UserMenuContent";
import ChatButton from "./ChatButton";
import ChatBox from "./ChatBox";
import { useApplicationContext } from "contexts/ApplicationContext";

const SIDE_BAR_OPEN_LS_KEY = "SIDE_BAR_OPEN_LS_KEY";

export interface NavigationItemType {
  text: string;
  redirecting: string;
  indicator?: any;
}

export interface NavigationGroup {
  groupTitle: string;
  items?: NavigationItemType[];
  Icon: React.ComponentType<any>;
  redirecting?: string;
  indicator?: any;
}

interface LayoutProps extends HtmlHTMLAttributes<HTMLElement> {
  navigation: NavigationGroup[];
  renderActions?: (
    isSidebarOpen: boolean
  ) => React.ComponentType<any> | JSX.Element;
}

const Layout: FC<LayoutProps> = ({ navigation, renderActions, children }) => {
  const { user } = useAuth();

  const { layoutActions, layoutState } = useLayoutContext();
  const [appState] = useApplicationContext();

  useEffect(() => {
    layoutActions.setSidebarOpen(
      JSON.parse(localStorage.getItem(SIDE_BAR_OPEN_LS_KEY) || "false")
    );
  }, [layoutActions]);

  useEffect(() => {
    localStorage.setItem(
      SIDE_BAR_OPEN_LS_KEY,
      JSON.stringify(layoutState.sidebarOpen)
    );
  }, [layoutState.sidebarOpen]);

  return (
    <>
      <LuiLayout
        user={user!}
        renderUserMenuContent={(close) => <UserMenuContent close={close} />}
        renderSidebarContent={() => (
          <SidebarContent
            navigation={navigation}
            renderActions={renderActions}
          />
        )}
      >
        {children}
      </LuiLayout>
      <NotificationsWidget />
      <ChatButton />
      {appState.chat.show && <ChatBox />}
    </>
  );
};

export default Layout;
