import React, { useState, useEffect, useContext, useCallback } from "react";
import { Role } from "rbac-rules";
import IToken from "./IToken";
import config from "../config";
import { useKeycloak } from "@react-keycloak/web";
import { SasStore } from "../utils/blockBlobClient";
import { useLazyQuery, gql } from "@apollo/client";
import { GET_USERS_ORGIDS, IGetOrgIds, OrgId } from "graphql/queries/common";
import { GET_TENANT_SETTING } from "../graphql/hooks/useGetTenantSettings";
import client from "../graphql/client";
import useUpdateMyUiSettings from "domain/user/useUpdateMyUiSettings";
import { changeAppLanguage } from "i18n";

const namespace = "https://hasura.io/jwt/claims";

const delay = async (timeout: number) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};
/**
 * Properties from the IdToken + the custom defined properties (in the Auth0 Rules!)
 */

export interface UserUiSettings {
  language?: string;
  sidebarOpen?: boolean;
  pages: { [key: string]: any };
  [key: string]: any;
}

export interface IUser extends IToken {
  userId: string;
  allowedOrganizations: string[];
  currentOrganization: string;
  defaultRole: Role;
  currentRole: Role;
  allowedRoles: Role[];
  tenantId: string;
  orgs: OrgId[];
  hasAllowedRoles: (roles: Role[]) => boolean;
  uiSettings: UserUiSettings;
  [key: string]: any;
}

interface IOwnIdToken extends IToken {
  [key: string]: any;
}

/**
 * Translates decoded Auth0-Id-token into a custom, flat user object
 * @param idToken
 */
function getUserFromIdToken(idToken: IOwnIdToken) {
  // Strip the namespace, since we want to have a flat user object
  const { [namespace]: customProperties, ...authProperties } = idToken;
  return {
    userId: customProperties["x-hasura-user-id"],
    allowedOrganizations: customProperties["x-hasura-allowed-organizations"],
    currentOrganization: customProperties["x-hasura-current-organization"],
    defaultRole: customProperties["x-hasura-default-role"],
    currentRole: customProperties["x-hasura-current-role"],
    allowedRoles: customProperties["x-hasura-allowed-roles"],
    tenantId: customProperties["x-hasura-tenant-id"],
    orgs: [],
    hasAllowedRoles(roles: Role[]) {
      //@ts-ignore
      return this.allowedRoles.some((allowedRole) =>
        roles.includes(allowedRole)
      );
    },
    ...authProperties,
  };
}

export interface IAuthContext {
  isAuthenticated: boolean;
  isAuthorized: boolean;
  user: IUser | undefined;
  updateUiSettings: (partialNewUiSettings: Partial<UserUiSettings>) => void;
  idToken: string | undefined;
  tenantConfig: any | undefined;
  tenantConfigLoading: boolean;
  loading: boolean;
  loginWithRedirect: CallableFunction;
  logout: CallableFunction;
  token?: string;
}

const initialAuthContext: IAuthContext = {
  isAuthenticated: false,
  isAuthorized: false,
  user: undefined,
  updateUiSettings: () => undefined,
  idToken: undefined,
  tenantConfig: undefined,
  tenantConfigLoading: true,
  loading: true,
  loginWithRedirect: () => console.info("Initializing..."),
  logout: () => console.info("Initializing..."),
};

// /**
//  * A function that routes the user to the right place after login
//  * @param appState
//  */
// export const onRedirectCallback = (appState: any) => {
//   window.history.replaceState(
//     {},
//     document.title,
//     appState && appState.targetUrl
//       ? appState.targetUrl
//       : window.location.pathname
//   );
// };

export const AuthContext =
  React.createContext<IAuthContext>(initialAuthContext);
export const useAuth = () => useContext(AuthContext);

// TODO(df): Improve typing...
export const AuthProvider = ({ children }: any) => {
  const updateMyUiSettings = useUpdateMyUiSettings();

  const [user, setUser] = useState<IUser>();
  const userBasePart = React.useMemo(
    () => ({
      uiSettings: { pages: {} },
    }),
    []
  );

  const [tenantConfig, setTenantConfig] = useState<any>();
  const [tenantConfigLoading, setTenantConfigLoading] = useState(true);
  const [idToken, setIdToken] = useState();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isAuthorized, setAuthorized] = useState(false);
  const { keycloak, initialized } = useKeycloak();

  const [getOrgIds] = useLazyQuery<IGetOrgIds>(GET_USERS_ORGIDS, {
    onCompleted: (data) => {
      setUser(
        (prev) =>
          prev && {
            ...prev,
            orgs: data ? data.orgIds.map((orgId) => ({ ...orgId.orgId })) : [],
          }
      );
    },
  });

  const updateSasTokens = useCallback(
    (tenantSettings: any = tenantConfig) => {
      if (tenantSettings) {
        const sasStore = new SasStore();
        const { ThumbnailsContainer, DocumentsContainer } = tenantSettings;
        sasStore.updateSASForContainer(DocumentsContainer.value);
        sasStore.updateSASForContainer(
          DocumentsContainer.value,
          "application/pdf"
        );
        sasStore.updateSASForContainer(ThumbnailsContainer.value);
      }
    },
    [tenantConfig]
  );

  //@ts-ignore
  keycloak.onReady = useCallback(() => {}, []);

  //@ts-ignore
  keycloak.onAuthSuccess = () => {
    if (!keycloak) return null;
    const idToken = keycloak.idTokenParsed || {};
    const userFromIdToken = getUserFromIdToken(idToken);
    setUser((prev) => ({
      ...userBasePart,
      ...userFromIdToken,
      orgs: prev ? prev.orgs : [],
    }));
  };

  //@ts-ignore
  keycloak.onTokenExpired = () => {
    //@ts-ignore
    keycloak.updateToken(25).then((refreshed) => {
      if (refreshed) {
        updateSasTokens();
      }
    });
  };

  //@ts-ignore
  keycloak.onAuthRefreshError = () => {
    setAuthenticated(false);
    //@ts-ignore
    keycloak.login({
      redirectUri: config.keycloak.redirectUrl,
    });
  };

  const getDBUser = useCallback(async () => {
    const { data } = await client.query({
      query: gql`
        query AditGetCurrentUser {
          pp_users {
            user {
              ui_settings
            }
          }
        }
      `,
      fetchPolicy: "no-cache",
    });
    const dbUser = data?.pp_users[0].user;
    const uiSettings = {
      pages: {},
      ...data?.pp_users[0].user.ui_settings.adit,
    };

    return {
      ...dbUser,
      uiSettings,
    };
  }, []);

  let initAuthClient = useCallback(async () => {
    while (!initialized) {
      await delay(10);
    }
    if (keycloak.authenticated) {
      const user = keycloak.idTokenParsed || {};
      setAuthenticated(true);
      if (namespace in user) {
        const userFromIdToken = getUserFromIdToken(user);
        const dbUser = await getDBUser();

        setUser((prev) => ({
          ...userBasePart,
          ...userFromIdToken,
          ...dbUser,
          orgs: prev ? prev.orgs : [],
        }));
        client
          .query({
            query: GET_TENANT_SETTING,
            variables: { tenantId: userFromIdToken.tenantId },
          })
          .then((tenantConfig) => {
            setTenantConfig(tenantConfig.data?.getTenantConfig);
            updateSasTokens(tenantConfig.data?.getTenantConfig);
            setTenantConfigLoading(false);
          });
        getOrgIds({
          variables: {
            userId: `{${userFromIdToken.userId}}`,
          },
        });
        setAuthorized(true);
        setIdToken(idToken);
      } else {
        setAuthorized(false);
      }
    }

    setLoading(false);
    //@ts-ignore
  }, [
    updateSasTokens,
    initialized,
    //@ts-ignore
    keycloak.authenticated,
    //@ts-ignore
    keycloak.idTokenParsed,
    getOrgIds,
    idToken,
    getDBUser,
  ]);

  useEffect(() => {
    if (user?.uiSettings.language) {
      changeAppLanguage(user.uiSettings.language);
    }
  }, [user?.uiSettings.language]);

  useEffect(() => {
    initAuthClient();
  }, [initAuthClient]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAuthorized,
        user,
        idToken,
        loading,
        tenantConfigLoading,
        tenantConfig,
        token: keycloak?.token,
        loginWithRedirect: () => {
          //@ts-ignore
          keycloak.login({
            redirectUri: config.keycloak.redirectUrl,
            scope: "adit-api:call",
          });
        },
        //@ts-ignore
        logout: () => keycloak.logout(),
        updateUiSettings: (partialNewUiSettings: Partial<UserUiSettings>) => {
          const newUiSettings = {
            ...user!.uiSettings,
            ...partialNewUiSettings,
          };
          setUser((prev) => prev && { ...prev, uiSettings: newUiSettings });
          updateMyUiSettings({
            variables: {
              id: user!.userId,
              ui_settings: { adit: newUiSettings },
            },
          });
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
