import { useEffect, useMemo } from "react";
import {
  AutocompleteProps,
  Box,
  Chip,
  Paper,
  Tooltip,
  Typography,
} from "@periplus/ui-library";
import {
  useGetAddressesLazy,
  AddressListEntity,
  getAddressesQueryVariables,
} from "../useGetAddresses";
import { useDebounceCallback } from "@react-hook/debounce";
import { StrictOmit } from "utils/utilityTypes";
import React from "react";
import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector";
import { makeStyles } from "tss-react/mui";

interface AddressAutocompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends AutocompleteProps<
    AddressListEntity,
    Multiple,
    DisableClearable,
    false
  > {}

export interface AddressAutocompleteHookProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends StrictOmit<
    AddressAutocompleteProps<Multiple, DisableClearable>,
    "options"
  > {}

const useStyles = makeStyles()(() => ({
  companyColumn: {
    minWidth: "30%",
    maxWidth: "30%",
  },
  detailsColumn: {
    minWidth: "17.5%",
    maxWidth: "17.5%",
    wordBreak: "break-all",
  },
}));

export default function <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>({
  loading,
  InputProps,
  open: outerOpen,
  onOpen,
  onClose,
  inputValue: outerInputValue,
  onInputChange,
  ...rest
}: AddressAutocompleteHookProps<
  Multiple,
  DisableClearable
>): AddressAutocompleteProps<Multiple, DisableClearable> {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { width: inputWidth, ref: inputRef } = useResizeDetector();
  const { width: popperWidth, ref: popperRef } = useResizeDetector({
    refreshRate: 1,
  });
  const optionWidth = popperWidth ? popperWidth - 8 : "100%";
  const compactMode = inputWidth && inputWidth < 600;
  const [open, setOpen] = React.useState(!!outerOpen);
  useEffect(() => setOpen(!!outerOpen), [outerOpen]);
  const [inputValue, setInputValue] = React.useState(outerInputValue ?? "");
  useEffect(() => setInputValue(outerInputValue ?? ""), [outerInputValue]);

  const [
    searchAddresses,
    {
      data: { addresses },
      loading: searchAddressesLoading,
    },
  ] = useGetAddressesLazy();

  const debouncedSearch = useDebounceCallback((search?: string) => {
    searchAddresses({
      variables: getAddressesQueryVariables({
        search,
        limit: 10,
      }),
    });
  }, 300);

  const AddressDetails = ({ address }: { address: AddressListEntity }) => {
    const companyDetails = (
      <>
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <Typography variant="subtitle2">
            {address.companyName}
            {address.companyName2 && `, ${address.companyName2}`}
          </Typography>
          {address.isPlatformUser && (
            <Chip label={t("Platform")} size="small" color="primary" />
          )}
        </Box>
        <Typography variant="body2">
          {[address.street, address.zipcode, address.city, address.country]
            .filter((el) => el)
            .join(", ")}
        </Typography>
      </>
    );

    if (compactMode) return <Box>{companyDetails}</Box>;

    return (
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          width: "100%",
          gap: 0.5,
        }}
      >
        <Box className={classes.companyColumn}>{companyDetails}</Box>
        <Typography
          className={classes.detailsColumn}
          variant="caption"
          sx={{
            color: address.customsAccountNumber ? "inherit" : "lightgray",
          }}
        >
          {address.customsAccountNumber || "-"}
        </Typography>
        <Typography
          className={classes.detailsColumn}
          variant="caption"
          sx={{
            color: address.uidNumber ? "inherit" : "lightgray",
          }}
        >
          {address.uidNumber || "-"}
        </Typography>
        <Typography
          className={classes.detailsColumn}
          variant="caption"
          sx={{
            color: address.traderIdentificationNumber ? "inherit" : "lightgray",
          }}
        >
          {address.traderIdentificationNumber || "-"}
        </Typography>
        <Typography
          className={classes.detailsColumn}
          variant="caption"
          sx={{
            color: address.reference ? "inherit" : "lightgray",
          }}
        >
          {address.reference || "-"}
        </Typography>
      </Box>
    );
  };

  const transformedOptions = useMemo(() => {
    const counts: { [key: string]: number } = {};
    return addresses.map((address) => {
      counts[address.companyName] =
        counts[address.companyName] === undefined
          ? 0
          : counts[address.companyName] + 1;

      return {
        ...address,
        companyName:
          address.companyName +
          (counts[address.companyName]
            ? ` (${counts[address.companyName]})`
            : ""),
      };
    });
  }, [addresses]);

  return {
    open,
    onOpen: (e) => {
      setOpen(true);
      debouncedSearch();

      onOpen?.(e);
    },
    onClose: (e, reason) => {
      if (rest.clearOnBlur === false && !rest.value) {
        if (reason === "blur") return;

        setInputValue("");
      }

      setOpen(false);

      onClose?.(e, reason);
    },
    inputValue,
    onInputChange: (e, newValue, reason) => {
      setInputValue(newValue);
      if (reason === "input") debouncedSearch(newValue);

      onInputChange?.(e, newValue, reason);
    },
    options: transformedOptions,
    isOptionEqualToValue: (option, value) => option.id === value.id,
    loading: loading || searchAddressesLoading,
    getOptionLabel: (option) =>
      `${[option.companyName, option.companyName2]
        .filter((el) => el)
        .join(", ")}\n${t(`classify:Customs`)}: ${
        option.customsAccountNumber || "-"
      }, ${t(`classify:UID`)}: ${option.uidNumber || "-"}, ${t(
        `classify:Tax`
      )}: ${option.traderIdentificationNumber || "-"}, ${t(`Ref`)}: ${
        option.reference || "-"
      }`,
    getOptionDisabled: (option) => option.isLocked,
    ...(!compactMode && {
      PaperComponent: ({ children, ...rest }) => (
        <Paper {...rest}>
          <Box
            sx={{
              width: optionWidth,
              px: 2,
              pt: 1,
              display: "flex",
              gap: 0.5,
            }}
          >
            <Typography className={classes.companyColumn} variant="subtitle2">
              {t("Company")}
            </Typography>
            <Typography className={classes.detailsColumn} variant="subtitle2">
              {t(`classify:Customs account`)}
            </Typography>
            <Typography className={classes.detailsColumn} variant="subtitle2">
              {t(`classify:UID`)}
            </Typography>
            <Typography className={classes.detailsColumn} variant="subtitle2">
              {t(`classify:Tax number`)}
            </Typography>
            <Typography className={classes.detailsColumn} variant="subtitle2">
              {t(`Reference`)}
            </Typography>
          </Box>
          {children}
        </Paper>
      ),
    }),
    renderOption: (props, option) => (
      <Box
        {...props}
        component="li"
        sx={{
          width: optionWidth,
        }}
      >
        <AddressDetails address={option} />
      </Box>
    ),
    renderTags: (value, getTagProps) =>
      value.map((option, index) => (
        <Tooltip
          title={<AddressDetails address={option} />}
          disableInteractive
          key={option.id}
        >
          <Chip label={option.companyName} {...getTagProps({ index })} />
        </Tooltip>
      )),
    autoComplete: true,
    filterSelectedOptions: true,
    filterOptions: (x) => x,
    InputProps: {
      multiline: true,
      inputRef,
      ...InputProps,
    },
    slotProps: {
      popper: {
        ref: popperRef,
      },
    },
    ...rest,
  };
}
