import { gql } from "@apollo/client";

export const GET_DOCUMENTS_BY_CHECKSUM = gql`
  query GET_DOCUMENTS_BY_CHECKSUM($checksums: [String!]!) {
    documents: document(
      where: {
        _and: [
          { checksum: { _in: $checksums } }
          { document_type: { _eq: "unknown" } }
        ]
      }
    ) {
      id
      document_name
      checksum
    }
  }
`;

export const GET_DOC_ANNOTATION = gql`
  query GET_DOC_ANNOTATION($docId: uuid!, $annoTypeId: smallint!) {
    doc_annotation(
      where: { doc_id: { _eq: $docId }, anno_type_id: { _eq: $annoTypeId } }
    ) {
      anno_type_id
      doc_id
      value
    }
  }
`;

export const GET_ANNOTATION_TYPE = gql`
  query GET_ANNOTATION_TYPE(
    $annotationProviderId: smallint!
    $documentType: String!
  ) {
    annotation_type(
      where: {
        annotation_provider_id: { _eq: $annotationProviderId }
        document_type: { _eq: $documentType }
      }
    ) {
      anno_type_id
      anno_schema
      ui_schema
    }
  }
`;

export const NEXT_INVALIDATE_DOCUMENT = gql`
  query NEXT_INVALIDATE_DOCUMENT($orgs: _int2, $docId: uuid) {
    pp_docs_for_validation(args: { _doc_id: $docId, _org_ids: $orgs }) {
      id
      document_flags
      document_type
      org_id
      doc_order
    }
  }
`;
