import React, { FC } from "react";
import {
  FormTextField,
  Dialog,
  FormAutocomplete,
  Theme,
  Grid,
} from "@periplus/ui-library";
import { Form } from "formik";
import { useTranslation } from "react-i18next";
import TenantFormik from "../TenantFormik";
import { ADDRESS_TYPES } from "domain/address/types";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import countries from "assets/countries";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  stepContentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
}));

const CreateDialog: FC<{
  onClose: () => void;
  onConfirm: () => void;
}> = ({ onClose, onConfirm }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LeG1MgfAAAAANEaL43wvqg43GZ3wLZJKwcQ0WS7">
      <TenantFormik onSubmit={onConfirm}>
        {({ submitForm, isSubmitting, isValid, dirty }: any) => (
          <Dialog
            maxWidth="xl"
            onClose={onClose}
            mainTitle={`${t("create")} ${t("Tenant")}`}
            onConfirm={submitForm}
            ConfirmButtonProps={{
              disabled: isSubmitting || !dirty || !isValid,
            }}
          >
            <Form className={classes.stepContentContainer}>
              <Grid item container>
                <Grid item xs={12}>
                  <FormTextField
                    name="tenant_name"
                    variant="outlined"
                    fullWidth
                    label={t("common:company")}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormAutocomplete
                    name="addressType"
                    fullWidth
                    options={ADDRESS_TYPES}
                    getOptionLabel={(option: { name: any }) => option.name}
                    InputProps={{
                      required: true,
                      variant: "outlined",
                      label: t("registration:companyType"),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="traderIdentificationNumber"
                    variant="outlined"
                    fullWidth
                    label={t("common:traderIdentificationNumber")}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormAutocomplete
                    name="country"
                    fullWidth
                    options={countries}
                    InputProps={{
                      required: true,
                      variant: "outlined",
                      label: t("common:country"),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="city"
                    variant="outlined"
                    fullWidth
                    label={t("common:city")}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="street"
                    variant="outlined"
                    fullWidth
                    label={t("common:street")}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="street_number"
                    variant="outlined"
                    fullWidth
                    label={t("common:streetNumber")}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <FormTextField
                    name="zipcode"
                    variant="outlined"
                    fullWidth
                    label={t("common:postcode")}
                    required
                  />
                </Grid>
              </Grid>
            </Form>
          </Dialog>
        )}
      </TenantFormik>
    </GoogleReCaptchaProvider>
  );
};

export default CreateDialog;
