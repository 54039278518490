import { useMemo, useCallback } from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

export type User = {
  id: string;
  display_name: string;
  location: string;
  email: string;
  user_type: "user" | "hasura";
};

interface IQueryResult {
  user_pp: User[];
}

export interface IVariables {
  limit?: number;
  offset?: number;
  tenantId?: string;
}

export const GET_USERS = gql`
  query GET_USERS(
    $limit: Int = 10
    $offset: Int = 0
    $tenantId: uuid = "00000000-0000-0000-0000-000000000000"
    $search: String = "%"
  ) {
    user_pp(
      limit: $limit
      offset: $offset
      where: {
        tenant_id: { _eq: $tenantId }
        deleted: { _eq: false }
        # userOrgIds: { org_id: { _neq: null } }
        display_name: { _ilike: $search }
      }
    ) {
      id
      display_name
      location
      email
      tenant_id
    }
  }
`;

const useGetUsers = ({
  limit = 1000,
  offset = 0,
  tenantId,
}: IVariables = {}) => {
  const { data, loading, error, refetch } = useQuery<IQueryResult>(GET_USERS, {
    variables: { limit, offset, tenantId },
    fetchPolicy: "no-cache",
  });

  const loadData = useCallback(
    (offset: number, limit: number) => {
      refetch({
        offset,
        limit,
      });
    },
    [refetch]
  );

  const returnValue = useMemo(() => {
    const users = (data && data.user_pp) || [];
    return {
      data: { users },
      loadData,
      loading,
      error,
      refetch,
    };
  }, [data, loadData, loading, error, refetch]);

  return returnValue;
};

export default useGetUsers;
