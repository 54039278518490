import React, { useRef } from "react";
import { useAuth } from "contexts/AuthContext";
import { useLocation } from "react-router-dom";

export default function usePageSettings<T = any>(initialState?: T) {
  const isMRTableMounted = useRef(false);
  const { user, updateUiSettings } = useAuth();
  const {
    state: { path },
  } = useLocation<{ path: string }>();

  user!.uiSettings.pages[path] = {
    ...initialState,
    ...user!.uiSettings.pages[path],
  };

  const pageSettings = user!.uiSettings.pages[path] as T;

  const setPageSettings = React.useCallback(
    (value: Partial<T> | ((prevState: T) => Partial<T>)) => {
      let updatedPageSettings: Partial<T>;
      if (typeof value === "function") {
        const func = value as (prevState: T) => Partial<T>;
        updatedPageSettings = func(user!.uiSettings.pages[path]);
      } else {
        updatedPageSettings = value;
      }
      updateUiSettings({
        pages: {
          ...user!.uiSettings.pages,
          [path]: {
            ...user!.uiSettings.pages[path],
            ...updatedPageSettings,
          },
        },
      });
    },
    [user, path, updateUiSettings]
  );

  const setPageSettingsForMRTable = React.useCallback(
    (value: Partial<T> | ((prevState: T) => Partial<T>)) => {
      if (!isMRTableMounted.current) {
        isMRTableMounted.current = true;
      } else {
        let updatedPageSettings: Partial<T>;
        if (typeof value === "function") {
          const func = value as (prevState: T) => Partial<T>;
          updatedPageSettings = func(user!.uiSettings.pages[path]);
        } else {
          updatedPageSettings = value;
        }
        updateUiSettings({
          pages: {
            ...user!.uiSettings.pages,
            [path]: {
              ...user!.uiSettings.pages[path],
              ...updatedPageSettings,
            },
          },
        });
      }
    },
    [user, path, updateUiSettings]
  );

  return { pageSettings, setPageSettings, setPageSettingsForMRTable };
}
