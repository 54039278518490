import React, { FC, useEffect, useState } from "react";
import { Tooltip } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import CopyToClipboardLib from "react-copy-to-clipboard";

type CopyToClipboardProps = {
  text: string;
  onCopy?: () => void;
  title?: string;
  copiedTitle?: string;
  children: React.ReactElement<any, any>;
};
const CopyToClipboard: FC<CopyToClipboardProps> = ({
  text,
  onCopy,
  title,
  copiedTitle,
  children,
}) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const initialTitle: string = title ?? t("tooltips:copyToClipboard");
  const initialCopiedTitle: string = copiedTitle ?? t("tooltips:copied");

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [copied]);

  const markCopied = () => {
    setCopied(true);
    if (onCopy) {
      onCopy();
    }
  };

  return (
    <CopyToClipboardLib text={text} onCopy={markCopied}>
      <Tooltip title={copied ? initialCopiedTitle : initialTitle}>
        {children}
      </Tooltip>
    </CopyToClipboardLib>
  );
};

export default CopyToClipboard;
