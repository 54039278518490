import gql from "graphql-tag";
import {
  AditGetAiConfirmedDeclarationsQuery,
  useAditGetAiConfirmedDeclarationsQuery,
} from "graphql/generated";
import { useMemo } from "react";

export type AiConfirmedDeclarationEntity =
  AditGetAiConfirmedDeclarationsQuery["declarations"][number];

gql`
  query AditGetAiConfirmedDeclarations {
    declarations: declaration(where: { ai_confirmed: { _eq: true } }) {
      file_id
      dr_no
      ai_confirmed
      file {
        identification
      }
      declaration_request {
        payer {
          tenant_name
        }
        declaration_request_addresses(where: { address_type: { _eq: 3 } }) {
          address {
            company_name
          }
        }
        documents(where: { document_type: { _neq: "unknown" } }) {
          id
          document_type
          document_extension
          pages_m2m {
            page_number
            page {
              id
              thumbnail_big_url
              ai_ignore_retraining
              rotate
            }
          }
        }
      }
    }
  }
`;

export default (
  baseOptions?: Parameters<typeof useAditGetAiConfirmedDeclarationsQuery>[0]
) => {
  const result = useAditGetAiConfirmedDeclarationsQuery({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    ...baseOptions,
  });

  return useMemo(
    () => ({
      ...result,
      data: {
        declarations: result.data?.declarations ?? [],
      },
    }),
    [result]
  );
};
