import { gql } from "@apollo/client";

export const EXTRACT_TEXT = gql`
  query pp_page_extract_text_fragment_v2(
    $pageId: uuid!
    $x: numeric!
    $y: numeric!
    $width: numeric!
    $height: numeric!
    $extract_type_id: smallint!
  ) {
    pp_page_extract_text_fragment_v2(
      args: {
        _pageid: $pageId
        _x: $x
        _y: $y
        _width: $width
        _height: $height
        _extract_type_id: $extract_type_id
      }
    ) {
      json_value
    }
  }
`;

export const EXTRACT_GRIDS_TEXT = gql`
  query pp_page_extract_grids_text(
    $pageId: uuid!
    $cellCoordinates: jsonb!
    $extractTypeId: smallint!
  ) {
    pp_page_extract_grids_text(
      args: {
        _page_id: $pageId
        _coordinates: $cellCoordinates
        _extract_type_id: $extractTypeId
      }
    ) {
      json_value
    }
  }
`;
