import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { useMemo } from "react";

const GET_IMPORT_LIST_TRIGGERS = gql`
  query ADIT_GET_IMPORT_LIST_TRIGGERS($limit: Int, $offset: Int) {
    import_list_trigger: import_list_trigger(
      limit: $limit
      offset: $offset
      order_by: { creation_date: desc_nulls_first }
    ) {
      id
      display_name
      type
      config
      creation_date
      creation_user
      last_modified_date
      last_modified_user
      creationUser {
        display_name
      }
      lastModifiedUser {
        display_name
      }
      meta
    }
    import_list_trigger_aggregate {
      aggregate {
        totalCount: count
      }
    }
  }
`;

export enum TriggerTypesEnum {
  HighCustomFeesTrigger = "HighCustomFeesTrigger",
  SpecificHsCodes = "SpecificHsCodes",
  RetrievalOfImportList = "RetrievalOfImportList",
  FTA = "FTA",
}
export const NOTIFICATION_TRIGGER_CONFIG_TYPES = [
  {
    id: TriggerTypesEnum.HighCustomFeesTrigger,
    name: "High customs fees",
  },
  {
    id: TriggerTypesEnum.SpecificHsCodes,
    name: "Specific Hs Codes",
  },
  {
    id: TriggerTypesEnum.RetrievalOfImportList,
    name: "Import list import",
  },
  {
    id: TriggerTypesEnum.FTA,
    name: "FTA",
  },
];

export type HighCustomFeesTrigger = {
  feeAmount: number;
  countryOfOrigin: string;
  recipients: string[];
};
export type SpecificHsCodesTrigger = {
  specificHsCodes: string[];
  recipients: string[];
};
export type RetrievalOfImportListTrigger = {
  recipients: string[];
};
export type ImportListTrigger = {
  id: number;
  display_name: string;
  type: TriggerTypesEnum;
  config:
    | HighCustomFeesTrigger
    | SpecificHsCodesTrigger
    | RetrievalOfImportListTrigger;
  creation_date: string;
  creation_user: string;
  last_modified_date: string;
  last_modified_user: string;
  creationUser: any;
  lastModifiedUser: any;
  meta: any;
};

const useGetImportListTriggers = (variables: {
  limit?: number;
  offset?: number;
}) => {
  const { data, ...rest } = useQuery<{
    import_list_trigger: ImportListTrigger[];
    import_list_trigger_aggregate: any;
  }>(GET_IMPORT_LIST_TRIGGERS, {
    variables,
    fetchPolicy: "no-cache",
  });

  const { import_list_triggers, totalCount } = useMemo(() => {
    const import_list_triggers = data?.import_list_trigger || [];
    const totalCount = data?.import_list_trigger_aggregate.aggregate.totalCount;
    return {
      import_list_triggers,
      totalCount,
    };
  }, [data]);

  return {
    import_list_triggers,
    totalCount,
    ...rest,
  };
};

export const StripUnusedFields = (
  config: any,
  type: TriggerTypesEnum | undefined
): any => {
  let fields: string[] = ["recipients"];
  switch (type) {
    case TriggerTypesEnum.RetrievalOfImportList:
      fields = [...fields];
      break;
    case TriggerTypesEnum.HighCustomFeesTrigger:
      fields = [...fields, "feeAmount", "countryOfOrigin"];
      break;
    case TriggerTypesEnum.SpecificHsCodes:
      fields = [...fields, "specificHsCodes"];
      break;
  }
  const l = new Set(fields);
  for (let prop of Object.keys(config)) {
    if (!l.has(prop)) {
      delete config[prop];
    }
  }
  return config;
};

export default useGetImportListTriggers;
