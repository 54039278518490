import React, { FC } from "react";
import { AlertDialog, Typography, Theme } from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { makeStyles } from 'tss-react/mui';

interface NewApiKeyPopupProps {
  apiKey: string;
  onClose: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  apiKey: {
    wordBreak: "break-all",
  },
  cancelButton: {
    display: "none",
  },
}));

const NewApiKeyPopup: FC<NewApiKeyPopupProps> = ({ apiKey, onClose }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <AlertDialog
      variant="warning"
      onClose={onClose}
      onConfirm={onClose}
      title={t("settingsAccessTokens:newApiKeyPopupTitle")}
      CancelButtonProps={{
        classes: {
          root: classes.cancelButton,
        },
      }}
      ConfirmButtonProps={{
        label: "OK",
      }}
    >
      <Typography className={classes.apiKey}>{apiKey}</Typography>
    </AlertDialog>
  );
};

export default NewApiKeyPopup;
