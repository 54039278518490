import { ReactNode } from "react";
import Children from "react-children-utilities";
import { Document } from "domain/document/types";

export default (
  name: ReactNode,
  document_blobs?: Document["document_blobs"]
) => {
  if (!name) {
    return "";
  }

  const originalBlob = document_blobs?.blobs?.find((blob: any) => blob.initial);

  if (originalBlob) {
    return originalBlob.extension;
  }

  const splitName = name ? Children.onlyText(name).split(".") : [];
  return splitName[splitName.length - 1] || "";
};

/*
 * {
 * "blobs":
 *   [
 *     {
 *       "url": "https://aditdocuments.blob.core.windows.net/documents/2d684e85-5327-4cd2-acf1-ea922ba48e36.pdf",
 *       "initial": true,
 *       "extension": "pdf"
 *     }
 *   ]
 * }*/
