import { getDefaultFormState } from "@rjsf/utils";
import { Annotation } from "graphql/hooks/useTableQuery";

export const convertAnnotations = (
  obj: any,
  callback: (obj: Annotation) => any
) => {
  if (!obj || typeof obj !== "object") return undefined;
  if ("type" in obj) {
    return callback(obj);
  }
  return Object.entries(obj).reduce((prev: any, current) => {
    const [key, value] = current as any;
    prev[key] = convertAnnotations(value, callback);
    return prev;
  }, {} as any);
};

export const transformDataPointField = (
  schema: any,
  callback: (annotation: Annotation) => void
) => {
  const emptyFormData = getDefaultFormState(schema, undefined, schema, true);
  return convertAnnotations(emptyFormData, callback);
};

export const ANNOTATION_TYPE_COLORS: { [annotationType: string]: string } = {
  additionalbicswift: "#c86c9d",
  additionalcurrency: "#9d70bd",
  additionaliban: "#0080a1",
  additionalinvoicenumber: "#007a68",
  additionallanguage: "#005B5B",
  additionalpaymenttaxes: "#40792E",
  additionalpaymentterms: "#5F7326",
  additionalpurchaseordernumber: "#8E8E0D",
  additionaluidnumber: "#006980",
  consigneeconsigneeaddress: "#0082a9",
  consigneeconsigneename: "#518394",
  consigneeconsigneereference: "#627ac9",
  consigneevatnumber: "#70a3db",
  consignorconsignoraddress: "#0066FF",
  consignorconsignorname: "#007CB7",
  consignorvatnumber: "#9f6fbf",
  dangerousgoodsdgclass: "#990078",
  dangerousgoodspackinggroup: "#8E4762",
  dangerousgoodsunnumber: "#A34E5D",
  deliveryinfodeliverydate: "#874C70",
  deliveryinfodestinationcountry: "#842E8B",
  deliveryinfoincotermplace: "#9F277E",
  deliveryinfoincoterms: "#AE6DAB",
  deliveryinfomethodofpacking: "#5D467B",
  deliveryinfoorigincountry: "#624E75",
  deliveryinfoplaceofdelivery: "#BDA5AC",
  itemsdescription: "#3d4856",
  itemsheight: "#564148",
  itemshscode: "#9e5570",
  itemslength: "#AE5A41",
  itemsprice: "#A94000",
  itemsquantity: "#D60000",
  itemssize: "#63448B",
  itemsvolume: "#806933",
  itemsweight: "#FF8749",
  itemswidth: "#C98D1D",
  totalsnumberofpackages: "#ff7a00",
  totalspricegrosstotal: "#817661",
  totalspricenettotal: "#464F75",
  totalsvolumetotal: "#303A5F",
  totalsweightgrosstotal: "#2F4858",
  totalsweightnettotal: "#23695D",
  headercode: "#842E8B",
  headerdescription: "#874C70",
  headerhscode: "#A34E5D",
  headerquantity: "#8E4762",
  headerunit: "#990078",
  headergrossweight: "#9f6fbf",
  headernetweight: "#007CB7",
  headerprice: "#0066FF",
  headertotalprice: "#70a3db",
  headerlength: "#627ac9",
  headerwidth: "#518394",
  headerheight: "#0082a9",
  headervolume: "#006980",
  headersize: "#8E8E0D",
  headernumber: "#5F7326",
  headerpurchaseordernumber: "#40792E",
  headermarks: "#005B5B",
  headercountryoforigin: "#007a68",
  headernumberofpackages: "#0080a1",
  headerpackagingtype: "#9d70bd",
  headerrownumber: "#c86c9d",
  importerimportername: "#D3D3D3",
  importerimporteraddress: "#696969",
  deliveryinfotransportationnumber: "#B0C4DE",
  deliveryinfotransportationtype: "#87CEFA",
  deliveryinfonumberofpackages: "#4682B4",
  deliveryinfomrnnumber: "#6495ED",
};

export const LINE_ITEM_TYPE_COLORS: { [lineItemType: string]: string } = {
  itemnumberofpackages: "#FF8570",
  itemcountryoforigin: "#627E0D",
  itemtotalprice: "#9f6fbf",
  itemnetweight: "#627ac9",
  itemgrossweight: "#8E8E0D",
  itemhscode: "#007a68",
  itemdescription: "#0080a1",
  itempackagingtype: "#92E9F2",
  itemcode: "#c86c9d",
  itemquantity: "#40792E",
  itemprice: "#70a3db",
  itemlength: "#9F277E",
  itemwidth: "#5D467B",
  itemheight: "#842E8B",
  itemvolume: "#BDA5AC",
  itemnumber: "#3d4856",
  itemmarks: "#9e5570",
  itempurchaseordernumber: "#FF8749",
  itemsize: "#464F75",
  itemunit: "#2F4858",
};
