import React, { useMemo } from "react";
import {
  FormTextField,
  FormSelect,
  FormAutocomplete,
  Dialog,
  MenuItem,
} from "@periplus/ui-library";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { UserType } from "graphql/hooks/useGetUsersKcAdmin";
import { Role } from "rbac-rules";
import { useMutation } from "@apollo/client";
import { CREATE_USER, UPDATE_USER } from "graphql/mutations/user";
import Countries from "i18n-iso-countries";
import { useSnackbar } from "notistack";
import { GET_USER_BY_EMAIL } from "graphql/queries/users";
import { useDebounceCallback } from "@react-hook/debounce";
import client from "graphql/client";
import { OrgId } from "graphql/hooks/useGetOrgIds";
import { Tenant } from "domain/tenant/type";
import { makeStyles } from 'tss-react/mui';

interface IUserFormDialogProps {
  tenantConfig?: any;
  orgIds?: OrgId[];
  user?: UserType;
  tenant: Tenant;
  onClose(): void;
  onConfirm(): void;
}

Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const useStyles = makeStyles()({
  form: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 16,
  },
});

const UserFormDialog: React.FC<IUserFormDialogProps> = ({
  tenantConfig,
  orgIds,
  user,
  onClose,
  onConfirm,
  tenant,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const allowAddUserInUserManagement =
    tenantConfig?.AllowAddUserInUserManagement?.value;
  const { classes } = useStyles();

  const emailValidationDebounce = useDebounceCallback((value, resolve) => {
    client
      .query({
        query: GET_USER_BY_EMAIL,
        variables: {
          email: value,
        },
      })
      .then(({ data }) =>
        resolve(
          user
            ? !data.user_pp.length || data.user_pp[0].email === user.email
            : !data.user_pp.length
        )
      );
  }, 300);

  const [createUser] = useMutation(CREATE_USER);
  const [updateUser] = useMutation(UPDATE_USER);

  const permissions = (user?.permissions || 0 >>> 0).toString(2);
  const initialValues = useMemo(
    () => ({
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      defaultRole: user?.defaultRole || "",
      email: user?.email || "",
      location: user?.location || null,
      liberRoles: user?.liberRoles || [],
      aditRoles: user?.aditRoles || [],
      permissions,
      orgIds: user?.orgIds || [],
      code: user?.code,
      password: "",
    }),
    [user]
  );

  const validationSchema = useMemo(() => {
    const validation = Yup.object().shape({
      firstName: Yup.string().trim().required().max(100),
      lastName: Yup.string().trim().required().max(100),
      permissions: Yup.string().notRequired(),
      default_role: Yup.string().notRequired().max(100),
      email: Yup.string()
        .email()
        .trim()
        .required()
        .max(254)
        .test("emailDuplication", t("validation:emailDuplication"), (value) =>
          value
            ? new Promise((resolve) => emailValidationDebounce(value, resolve))
            : true
        ),
      aditRoles: Yup.array().notRequired(),
      liberRoles: Yup.array().notRequired(),
      location: Yup.string().nullable(),
      orgIds: Yup.array().required(),
      requiredActions: Yup.string().nullable().notRequired(),
      password: (() => {
        let result = Yup.string()
          .trim()
          .min(8)
          .test(
            "noSpecialCharacters",
            t("validation:noSpecialCharacters"),
            (value: string | undefined) => {
              if (!value) return true;

              const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
              return specialChars.test(value);
            }
          )
          .test(
            "noUpperCaseCharacter",
            t("validation:noUpperCaseCharacter"),
            (value: string | undefined) => {
              if (!value) return true;

              return value
                .split("")
                .some(
                  (letter) =>
                    letter.toUpperCase() === letter &&
                    letter !== letter.toLowerCase()
                );
            }
          )
          .test(
            "noLowerCaseCharacter",
            t("validation:noLowerCaseCharacter"),
            (value: string | undefined) => {
              if (!value) return true;

              return value
                .split("")
                .some(
                  (letter) =>
                    letter.toLowerCase() === letter &&
                    letter !== letter.toUpperCase()
                );
            }
          );
        if (!user) {
          result = result.required();
        }

        return result;
      })(),
    });

    return validation;
  }, [emailValidationDebounce, t]);

  const orgIdsList: number[] = [];
  const orgIdsToLabel: any = {};

  orgIds?.forEach((orgId) => {
    orgIdsList.push(orgId.org_id);
    orgIdsToLabel[orgId.org_id] = orgId.org_id_description;
  });

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    let castedValues = validationSchema.cast(values);
    let requiredActions: string[] = [""];
    let permissions = 0;

    const onCompleted = () => {
      onConfirm();
      setSubmitting(false);
    };
    const onError = (error: any) => {
      setSubmitting(false);
      enqueueSnackbar(error.message, { variant: "error" });
    };

    if (!castedValues.password) delete castedValues.password;

    if (castedValues.requiredActions) {
      requiredActions = castedValues.requiredActions.split(",");
      requiredActions = requiredActions.map((ra: any) => {
        return ra.trim();
      });
      delete castedValues.requiredActions;
    }

    if (castedValues.permissions) {
      permissions = parseInt(castedValues.permissions, 2);
      delete castedValues.permissions;
    }

    if (user) {
      updateUser({
        variables: {
          id: user.id,
          ...castedValues,
          permissions,
          requiredActions,
        },
      }).then(onCompleted, onError);
    } else {
      createUser({
        variables: {
          ...castedValues,
          permissions,
          requiredActions: requiredActions,
          userName: `${castedValues.firstName} ${castedValues.lastName}`,
          tenant_id: tenant.id,
        },
      }).then(onCompleted, onError);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, submitForm, errors }) => (
        <Dialog
          open
          fullWidth
          maxWidth="sm"
          mainTitle={
            user
              ? `${t("common:edit")}: ${user.firstName} ${user.lastName}`
              : `${t("common:new")}: ${t("common:user")}`
          }
          onClose={onClose}
          onConfirm={submitForm}
          ConfirmButtonProps={{
            loading: isSubmitting,
          }}
        >
          <Form className={classes.form}>
            <FormTextField
              name={`firstName`}
              variant="outlined"
              fullWidth
              label={t("common:lastName")}
              disabled={!allowAddUserInUserManagement}
            />
            <FormTextField
              name={`lastName`}
              variant="outlined"
              fullWidth
              label={t("common:lastName")}
              disabled={!allowAddUserInUserManagement}
            />
            <FormTextField
              name={`permissions`}
              variant="outlined"
              fullWidth
              label={t("common:permissions")}
              disabled={!allowAddUserInUserManagement}
            />
            <FormTextField
              name={`email`}
              variant="outlined"
              fullWidth
              label={t("common:email")}
              disabled={!!user || !allowAddUserInUserManagement}
            />
            <FormTextField
              name={`defaultRole`}
              variant="outlined"
              fullWidth
              label={t("common:defaultRole")}
              disabled={!allowAddUserInUserManagement}
            />
            <FormSelect
              name={`liberRoles`}
              variant="outlined"
              fullWidth
              label={t("common:liberRoles")}
              multiple
              disabled={!allowAddUserInUserManagement}
            >
              {Object.values(Role).map((role) => {
                return (
                  <MenuItem value={role} key={role}>
                    {t(`roles:${role}`)}
                  </MenuItem>
                );
              })}
            </FormSelect>
            <FormSelect
              name={`aditRoles`}
              variant="outlined"
              fullWidth
              label={t("common:aditRoles")}
              multiple
              disabled={!allowAddUserInUserManagement}
            >
              {Object.values(Role).map((role) => {
                return (
                  <MenuItem value={role} key={role}>
                    {t(`roles:${role}`)}
                  </MenuItem>
                );
              })}
            </FormSelect>
            <FormAutocomplete
              name="location"
              freeSolo
              autoSelect
              options={Object.values(Countries.getNames("en")).map((country) =>
                Array.isArray(country) ? country[0] : country
              )}
              InputProps={{
                variant: "outlined",
                label: t("common:location"),
              }}
              disabled={!allowAddUserInUserManagement}
            />
            <FormTextField
              name={`requiredActions`}
              variant="outlined"
              fullWidth
              label={t("common:requiredActions")}
            />
            <FormTextField
              name={`password`}
              variant="outlined"
              type="password"
              fullWidth
              label={t("common:password")}
              disabled={!!user || !allowAddUserInUserManagement}
            />
            <FormAutocomplete
              multiple
              name="orgIds"
              options={orgIdsList}
              InputProps={{
                variant: "outlined",
                label: "Org ID",
              }}
              getOptionLabel={(option: any) => {
                return orgIdsToLabel[option];
              }}
              filterSelectedOptions
            />
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default UserFormDialog;
