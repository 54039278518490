import React, { FC, useState } from "react";
import { Box, BoxProps, styled, Typography } from "@periplus/ui-library";

export const Bookmark = styled(Box)({
  width: 25,
  height: 25,
  borderRadius: 5,
  position: "relative",
  padding: "3px 7px",
  cursor: "pointer",

  "&:hover": {
    width: "auto",
  },

  "&:after": {
    content: '""',
    width: 16,
    height: 16,
    backgroundColor: "inherit",
    position: "absolute",
    left: "100%",
    top: "50%",
    transform: "translate(-58%, -50%) rotate(45deg)",
    zIndex: -1,
  },
});

const BookmarkWrapper: FC<BoxProps> = ({
  color = "white",
  bgcolor = "orange",
  children,
  ...rest
}) => {
  const [isHovered, setHovered] = useState(false);
  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      zIndex={isHovered ? 99 : 3}
      maxWidth={250}
      {...rest}
    >
      <Bookmark color={color} bgcolor={bgcolor}>
        {isHovered && <Typography noWrap>{children}</Typography>}
      </Bookmark>
    </Box>
  );
};

export default BookmarkWrapper;
