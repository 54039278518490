import {
  AditGetAddressesQuery,
  AditGetAddressesQueryVariables,
} from "graphql/generated";
import { useMemo } from "react";
import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from "@apollo/client";
import { GET_ADDRESSES_QUERY } from "./query";
import { NotNullable, Overwrite } from "utils/utilityTypes";

export type AddressListEntity = NotNullable<
  AditGetAddressesQuery["addresses"][number],
  "companyName"
>;

type Query = Overwrite<
  AditGetAddressesQuery,
  { addresses: AddressListEntity[] }
>;

export const getAddressesQueryVariables = ({
  search,
  ...rest
}: AditGetAddressesQueryVariables) => ({
  ...(search && { search }),
  ...rest,
});

export const useGetAddressesLazy = (
  baseOptions?: LazyQueryHookOptions<Query, AditGetAddressesQueryVariables>
) => {
  const [query, result] = useLazyQuery<Query, AditGetAddressesQueryVariables>(
    GET_ADDRESSES_QUERY,
    { ...baseOptions, fetchPolicy: "no-cache" }
  );

  return useMemo(() => {
    return [
      query,
      {
        ...result,
        data: {
          addresses: result.data?.addresses ?? [],
        },
      },
    ] as const;
  }, [result, query]);
};

export default (
  baseOptions?: QueryHookOptions<Query, AditGetAddressesQueryVariables>
) => {
  const result = useQuery(GET_ADDRESSES_QUERY, {
    ...baseOptions,
    fetchPolicy: "no-cache",
  });

  return useMemo(() => {
    return {
      ...result,
      data: {
        addresses: result.data?.addresses ?? [],
      },
    };
  }, [result]);
};
