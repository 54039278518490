import React, { FC, HtmlHTMLAttributes } from "react";
import { DocumentTypeFlag } from "domain/documentType/types";
import Dot from "components/Dot";
import { Text } from "components/Text";

interface DocumentStatusProps extends HtmlHTMLAttributes<HTMLElement> {
  status?: string;
  defaultFlags: number;
  adoptTextColor?: boolean;
}

const DocumentStatus: FC<DocumentStatusProps> = ({
  status,
  defaultFlags,
  adoptTextColor = false,
}) => {
  const isAutoClassified =
    (defaultFlags & DocumentTypeFlag.autoclassification_completed) ===
    DocumentTypeFlag.autoclassification_completed;

  const getColorProp = (color: "green" | "red" | "orange" | "blue") =>
    adoptTextColor ? { color, adoptTextColor } : { bgColor: color };

  if (status === "thumbnails_generated") {
    if (isAutoClassified) {
      return (
        <Dot {...getColorProp("blue")}>
          <Text variant="caption">ready</Text>
        </Dot>
      );
    }
    return (
      <Dot {...getColorProp("green")}>
        <Text variant="caption">ready</Text>
      </Dot>
    );
  } else if (status && ["thumbnails_pending", "uploaded"].includes(status)) {
    return (
      <Dot {...getColorProp("orange")}>
        <Text variant="caption">processing</Text>
      </Dot>
    );
  } else {
    return (
      <Dot {...getColorProp("red")}>
        <Text variant="caption">failed</Text>
      </Dot>
    );
  }
};

export default DocumentStatus;
