import React from "react";
import { useTranslation } from "react-i18next";
import NestedTable from "../components/NestedTable";
import {
  DECLARATION_FORM_DEBOUNCE,
  DeclarationSpecialMentionForm,
  FieldsRefs,
  SetDeclarationFormFieldRefFunction,
} from "../Declaration";
import { LanguageType } from "i18n";
import { FormTextField, MRTable, Typography } from "@periplus/ui-library";
import NestedTableFormId from "../components/NestedTable/NestedTableFormId";
import FormField from "../components/FormField/FormField";
import NestedTableFormContainer from "../components/NestedTable/NestedTableFormContainer";
import { useApplicationState } from "contexts/ApplicationContext";

interface Props {
  onReturn: () => void;
  setFormFieldRef: SetDeclarationFormFieldRefFunction;
  onFormFieldFocus: (value: string) => void;
  onFormFieldBlur: () => void;
  formFieldsRefs: React.MutableRefObject<FieldsRefs>;
  pageContainerHeight: number;
}

export default function ({
  onReturn,
  setFormFieldRef,
  onFormFieldFocus,
  onFormFieldBlur,
  formFieldsRefs,
  pageContainerHeight,
}: Props) {
  const { edecData } = useApplicationState();
  const { t, i18n } = useTranslation("declarationNew");
  const language = i18n.language as LanguageType;

  const columns = React.useMemo<
    MRTable.MRT_ColumnDef<DeclarationSpecialMentionForm>[]
  >(
    () => [
      {
        header: t("Text"),
        accessorKey: "text",
      },
    ],
    [t, language, edecData.edec_domains]
  );

  return (
    <NestedTable
      name="declaration_special_mentions"
      label={t("Special Mentions")}
      columns={columns}
      defaultItem={{
        sequence_number: null,
        text: null,
      }}
      onReturn={onReturn}
      formFieldsRefs={formFieldsRefs}
      pageContainerHeight={pageContainerHeight}
      renderForm={({
        selectedItemRowNumber,
        getTableGeneralFormFieldProps,
        ref,
      }) => (
        <NestedTableFormContainer
          ref={ref}
          sx={{
            gridTemplateColumns: "auto 1fr",
            alignItems: "start",
          }}
        >
          <Typography>{t("Row Nr.")}</Typography>
          <NestedTableFormId id={selectedItemRowNumber} />
          <FormField
            {...getTableGeneralFormFieldProps("text")}
            label={t("Text")}
            onFocus={onFormFieldFocus}
            onBlur={onFormFieldBlur}
            setFormFieldRef={setFormFieldRef}
            sx={{
              gridColumn: "1",
            }}
            fillByClickMode
            renderInput={({ ...rest }) => (
              <FormTextField {...rest} debounce={DECLARATION_FORM_DEBOUNCE} />
            )}
          />
        </NestedTableFormContainer>
      )}
    />
  );
}
