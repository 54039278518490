import React from "react";
import { Loader, Grid, Theme } from "@periplus/ui-library";
import Text from "components/Text/Text";
import { makeStyles } from 'tss-react/mui';

interface IShipmentResponse {
  loading: any;
  data: any;
  error: any;
}

const ShipmentResponse: React.FC<IShipmentResponse> = ({
  loading,
  data,
  error,
}) => {
  const { classes } = useStyles();

  return (
    <>
      {loading && <Loader />}
      {data && data.data && !error && !loading && (
        <Grid container={true} justifyContent="space-between">
          <Grid item sm={12} style={{ marginTop: 20, marginBottom: 20 }}>
            <Text>{data.data.shipmentNumber} Details</Text>
          </Grid>
          <Grid item sm={6} container>
            <Grid item sm={5}>
              <Text className={classes.detailName}>specificTerms:orgid</Text>
              <Text className={classes.detailName}>specificTerms:traffic</Text>
              <Text className={classes.detailName}>
                specificTerms:orderedby
              </Text>
              <Text className={classes.detailName}>specificTerms:shipper</Text>
            </Grid>
            <Grid item sm={7}>
              <Text className={classes.details}>{data.data.orgId}</Text>
              <Text className={classes.details}>{data.data.traffic}</Text>
              <Text className={classes.details}>
                {data.data.orderedBy.name}
              </Text>
              <Text className={classes.details}>{data.data.shipper.name}</Text>
            </Grid>
          </Grid>
          <Grid item sm={6} container>
            <Grid item sm={5}>
              <Text className={classes.detailName}>
                specificTerms:consignee
              </Text>
              <Text className={classes.detailName}>specificTerms:quantity</Text>
              <Text className={classes.detailName}>
                specificTerms:packaging
              </Text>
              <Text className={classes.detailName}>
                specificTerms:grossweight
              </Text>
            </Grid>
            <Grid item sm={7}>
              <Text className={classes.details}>
                {data.data.consignee.name}
              </Text>
              {data.data.lines && data.data.lines.length > 0 ? (
                <>
                  <Text className={classes.details}>
                    {data.data.lines[0].quantity}
                  </Text>
                  <Text className={classes.details}>
                    {data.data.lines[0].packaging}
                  </Text>
                  <Text className={classes.details}>
                    {data.data.lines[0].grossWeight}
                  </Text>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles()((theme: Theme) =>
  ({
    detailName: {
      fontSize: 14,
      fontWeight: "bold",
      marginRight: theme.spacing(1),
    },

    details: {
      fontSize: 14,
      width: "90%",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textTransform: "none",
    }
  }));

export default ShipmentResponse;
