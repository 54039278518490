import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { Dayjs } from "dayjs";

export type IApiKey = {
  id: number;
  api_key: string;
  meta: {
    scopes: string;
    expiresAt?: Dayjs;
  };
  display_name: string;
};

export type IApiKeyScope =
  | "adit-api:call"
  | "adit-api:import_edec_data"
  | "adit-api:create_document"
  | "adit-api:update_file_status";

export const GET_API_KEYS = gql`
  query GET_API_KEYS {
    accessTokens: api_key(order_by: { dsplay_name: asc }) {
      id
      api_key
      meta
      display_name: dsplay_name
    }
  }
`;

const useGetApiKeys = () => {
  const response = useQuery<{ accessTokens: IApiKey[] }>(GET_API_KEYS);

  return response;
};

export default useGetApiKeys;
