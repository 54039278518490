import React, { FC } from "react";
import { BoxProps, Carousel, Paper } from "@periplus/ui-library";
import { AiConfirmedDeclarationEntity } from "../useGetAiConfirmedDeclarations";
import useSasToken from "hooks/useSasToken";
const { CarouselDialog } = Carousel;

interface BigThumbnailProps extends BoxProps {
  page: NonNullable<
    NonNullable<
      AiConfirmedDeclarationEntity["declaration_request"]
    >["documents"][number]["pages_m2m"][number]["page"]
  >;
  onClose: () => void;
}

const BigThumbnail: FC<BigThumbnailProps> = ({ page, onClose }) => {
  const thumbnailsSasToken = useSasToken({ container: "thumbs" });

  return (
    <CarouselDialog onClose={onClose}>
      <Paper
        elevation={6}
        style={{
          transform: `rotate(${page.rotate}deg)`,
        }}
      >
        <img
          style={{ width: "100%" }}
          src={`${page.thumbnail_big_url}?${thumbnailsSasToken}`}
          alt="page"
        />
      </Paper>
    </CarouselDialog>
  );
};

export default BigThumbnail;
