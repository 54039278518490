import React, { useMemo } from "react";
import {
  Button,
  FormCheckbox,
  Typography,
  Autocomplete,
  AutocompleteRenderInputParams,
  InputAdornment,
  Paper,
  TextField,
  Theme,
  IconButton,
  FormLabel,
  OutlinedInput,
} from "@periplus/ui-library";
import CloseRounded from "@mui/icons-material/CloseRounded";
import Delete from "@mui/icons-material/Delete";
import { Formik } from "formik";
import * as Yup from "yup";
import AutosizeInput from "react-18-input-autosize";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";

import { DataPoint } from "graphql/hooks/useGetValidationDocument";
import { Annotation } from "graphql/hooks/useTableQuery";
import { TextNaked } from "../Text";
import {
  ANNOTATION_TYPE_COLORS,
  LINE_ITEM_TYPE_COLORS,
} from "../AnnotationList/utils";
import { makeStyles } from "tss-react/mui";

interface Props {
  annotationTypes: { name: string; group: string; assigned: boolean }[];
  lineItemTypes: string[];
  dataPoint: DataPoint;
  innerDataPoints?: DataPoint[];
  annotation?: Annotation;
  onClose(): void;
  onSubmit(values: {
    value: string;
    annotationType?: string;
    dataPointsInside?: { [key: string]: DataPoint };
    lineItemType?: string;
    clearAreaAnnotations: boolean;
  }): void;
  onRemove?(): void;
  assignToLineItem?: boolean;
  newlyCreated?: boolean;
  prevSelectedDataPointType: {
    annotation?: string;
    lineItem?: string;
  };
}

const useStyles = makeStyles<void, "formLabel">()(
  (theme: Theme, _params, classes) => ({
    container: {
      padding: 16,
      width: 367,
      overflow: "auto",
    },
    formContent: {
      [`& .${classes.formLabel}:not(:first-of-type)`]: {
        marginTop: 16,
      },
    },
    formLabel: {
      display: "block",
      textTransform: "uppercase",
      marginBottom: 8,
      color: "#737a96",
    },
    dataPointInputs: {
      display: "flex",
      flexWrap: "wrap",
      marginRight: -8,
    },
    input: {
      padding: "9.5px 0 9.5px 14px",
    },
    dataPointInput: {
      marginRight: 8,
      marginBottom: 8,
      "& .MuiInputBase-input": {
        padding: "9.5px 0 9.5px 14px",
        "& > input": {
          height: "1.1876em",
          border: "none",
          background: "none",
          outline: "none",
          font: "inherit",
        },
      },
      "& .MuiSvgIcon-root": {
        fontSize: "1.25rem",
      },
    },
    annotationTypesInput: {
      "& .MuiInputBase-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    formActions: {
      display: "flex",
      justifyContent: "flex-end",
      paddingTop: 16,
      alignItems: "center",
      "& > .MuiButtonBase-root": {
        width: 100,
        "&:nth-child(2)": {
          marginRight: 16,
        },
      },
    },
    formActionsLeft: {
      flex: 1,
    },
    color: {
      width: 14,
      height: 14,
      flexShrink: 0,
      borderRadius: 3,
      marginRight: 8,
      marginTop: 2,
    },
    text: {
      flexGrow: 1,
    },
  })
);

const DataPointPopoverSchema = Yup.object().shape({
  value: Yup.string().required("Required"),
});

const DataPointPopover = ({
  annotationTypes,
  lineItemTypes,
  dataPoint,
  innerDataPoints,
  annotation,
  onClose,
  onSubmit,
  onRemove,
  assignToLineItem,
  newlyCreated,
  prevSelectedDataPointType,
}: Props) => {
  const { classes } = useStyles();

  const { t } = useTranslation();

  const entityAutocompleteProps = useMemo(
    () => ({
      renderInput: (params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          variant="outlined"
          autoFocus
          onFocus={(e) => setTimeout(() => e.target.select(), 1)}
        />
      ),
      ListboxProps: {
        "data-no-clickoutside": true,
      } as any,
      autoHighlight: true,
    }),
    []
  );

  return (
    <Paper className={classes.container} data-no-clickoutside>
      <Formik
        initialValues={{
          value: dataPoint.value,
          annotationType: annotationTypes.find(
            (type) =>
              (annotation?.type || prevSelectedDataPointType.annotation) ===
              type.name
          ),
          dataPointsInside: innerDataPoints?.reduce((a, dp) => {
            a[dp.id] = dp.value;
            return a;
          }, {} as { [key: string]: string }),
          lineItemType:
            dataPoint.lineItemType ?? prevSelectedDataPointType.lineItem,
          clearAreaAnnotations: false,
        }}
        validationSchema={DataPointPopoverSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            onSubmit({
              value: values.dataPointsInside
                ? Object.values(values.dataPointsInside).join(" ")
                : values.value,
              annotationType: values.annotationType?.name,
              dataPointsInside:
                values.dataPointsInside &&
                Object.entries(values.dataPointsInside).reduce(
                  (acc, [dpKey, dpValue]) => {
                    acc[dpKey] = {
                      ...(innerDataPoints?.find(
                        (el) => el.id === dpKey
                      ) as DataPoint),
                      value: dpValue,
                    };
                    return acc;
                  },
                  {} as { [key: string]: DataPoint }
                ),
              lineItemType: values.lineItemType,
              clearAreaAnnotations: values.clearAreaAnnotations,
            });
          });
          onClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          submitForm,
        }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitForm();
              }}
            >
              <div className={classes.formContent}>
                {values.dataPointsInside ? (
                  <>
                    <FormLabel className={classes.formLabel}>
                      <TextNaked>validation:innerDataPoints</TextNaked>
                    </FormLabel>
                    <div className={classes.dataPointInputs}>
                      {Object.entries(values.dataPointsInside)
                        .sort(
                          ([aId], [bId]) =>
                            (innerDataPoints!.find((el) => el.id === aId)!
                              .position ?? 0) -
                            (innerDataPoints!.find((el) => el.id === bId)!
                              .position ?? 0)
                        )
                        .map(([dpId, dpValue]) => {
                          return (
                            <OutlinedInput
                              key={dpId}
                              value={dpValue}
                              onChange={(event) => {
                                setFieldValue("dataPointsInside", {
                                  ...values.dataPointsInside,
                                  [dpId]: event.target.value,
                                });
                              }}
                              className={classes.dataPointInput}
                              inputComponent={AutosizeInput}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    size="small"
                                    aria-label="delete inner data point"
                                    edge="end"
                                    onClick={() =>
                                      setFieldValue(
                                        "dataPointsInside",
                                        omit(values.dataPointsInside, dpId)
                                      )
                                    }
                                  >
                                    <CloseRounded />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          );
                        })}
                    </div>
                  </>
                ) : (
                  <>
                    <FormLabel className={classes.formLabel}>
                      <TextNaked>common:text</TextNaked>
                    </FormLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={values["value"]}
                      onChange={(event) =>
                        setFieldValue("value", event.target.value)
                      }
                      onFocus={() => setFieldTouched("value")}
                      style={{ width: "100%" }}
                      error={!!(errors.value && touched.value)}
                      helperText={touched.value && errors.value}
                      InputProps={{
                        classes: {
                          input: classes.input,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              aria-label="clear data point value"
                              edge="end"
                              onClick={() => setFieldValue("value", "")}
                            >
                              <CloseRounded />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}

                <FormLabel className={classes.formLabel}>
                  <TextNaked>validation:entity</TextNaked>
                </FormLabel>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                  }}
                >
                  {newlyCreated && (
                    <div
                      style={{
                        display: "flex",
                        gap: 16,
                      }}
                    >
                      <FormCheckbox name="clearAreaAnnotations" />
                      <Typography color="textSecondary">
                        Remove all existing annotations in the area
                      </Typography>
                    </div>
                  )}
                  {assignToLineItem ? (
                    <Autocomplete
                      {...entityAutocompleteProps}
                      value={
                        values["lineItemType"] ? values["lineItemType"] : null
                      }
                      onChange={(event: any, value: any, reason: any) => {
                        setFieldValue("lineItemType", value);
                      }}
                      options={lineItemTypes}
                      getOptionLabel={(option) => {
                        return t(`specificTerms:${option?.toLowerCase()}`);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.toLowerCase() === value?.toLowerCase()
                      }
                      className={classes.annotationTypesInput}
                      renderOption={(props, option, { selected }) => {
                        return (
                          <li {...props}>
                            <span
                              className={classes.color}
                              style={{
                                backgroundColor:
                                  LINE_ITEM_TYPE_COLORS[option?.toLowerCase()],
                              }}
                            />
                            <div className={classes.text}>
                              {t(`specificTerms:${option?.toLowerCase()}`)}
                            </div>
                          </li>
                        );
                      }}
                    />
                  ) : (
                    <Autocomplete
                      {...entityAutocompleteProps}
                      value={
                        values["annotationType"]
                          ? values["annotationType"]
                          : {
                              name: "",
                              group: "",
                              assigned: false,
                            }
                      }
                      onChange={(event: any, value: any) => {
                        setFieldValue("annotationType", value);
                      }}
                      options={annotationTypes}
                      defaultValue={{
                        name: "",
                        group: "",
                        assigned: false,
                      }}
                      getOptionLabel={(option: (typeof annotationTypes)[0]) => {
                        if (option.name === "") return "";
                        return t(`specificTerms:${option.name?.toLowerCase()}`);
                      }}
                      isOptionEqualToValue={(option: any, value: any) => {
                        if (value.name === "") {
                          return true;
                        } else if (
                          value.name?.toLowerCase() ===
                          option.name?.toLowerCase()
                        ) {
                          return true;
                        }
                        return false;
                      }}
                      groupBy={(option) => option.group}
                      className={classes.annotationTypesInput}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <span
                            className={classes.color}
                            style={{
                              backgroundColor:
                                ANNOTATION_TYPE_COLORS[
                                  option.name?.toLowerCase()
                                ],
                            }}
                          />
                          <div className={classes.text}>
                            {t(`specificTerms:${option.name?.toLowerCase()}`)}
                          </div>
                        </li>
                      )}
                    />
                  )}
                </div>
              </div>

              <div className={classes.formActions}>
                {onRemove && innerDataPoints?.length && (
                  <div className={classes.formActionsLeft}>
                    <IconButton onClick={onRemove} size="large">
                      <Delete fontSize="small" color="error" />
                    </IconButton>
                  </div>
                )}
                <Button onClick={onClose}>
                  <TextNaked>common:cancel</TextNaked>
                </Button>
                <Button type="submit" color="primary" variant="contained">
                  <TextNaked>common:confirm</TextNaked>
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Paper>
  );
};

export default DataPointPopover;
