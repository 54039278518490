import React from "react";
import {
  Skeleton,
  Typography,
  Divider,
  Grid,
  Theme,
  Box,
  IconButton,
} from "@periplus/ui-library";
import { Link } from "react-router-dom";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AddIcon from "@mui/icons-material/Add";
import WeDeclareIcon from "components/Icons/WeDeclare";
import useValidateFile, { ResponseData } from "graphql/hooks/useValidateFile";
import CircleIcon from "./CircleIcon";
import ContentWrapper from "./ContentWrapper";
import { useTranslation } from "react-i18next";
import config from "config";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    fontWeight: "bold",
    wordBreak: "break-all",
  },
  grey: {
    color: "#737A96",
  },
  columnTitle: {
    textTransform: "uppercase",
    fontSize: "0.625rem",
  },
  divider: {
    margin: `${theme.spacing(2)} -${theme.spacing(2)}`,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

type ReferenceBodyProps = {
  reference?: ResponseData;
  loading?: boolean;
  error?: any;
};

const ReferenceBody = ({
  reference,
  loading = false,
  error,
}: ReferenceBodyProps) => {
  const { t, i18n } = useTranslation();
  const { classes, cx } = useStyles();

  if (loading) {
    return <Skeleton variant="rectangular" height="135px" width="100%" />;
  }

  if (error) {
    const translatedError = `error:${error}`;
    return (
      <Typography className={classes.grey}>
        {i18n.exists(translatedError) ? t(translatedError) : error}
      </Typography>
    );
  }

  if (reference) {
    return (
      <Grid container direction="row" spacing={1}>
        <Grid item container direction="column" md={5} sm={12}>
          <Typography
            variant="caption"
            className={cx(classes.columnTitle, classes.grey)}
          >
            {t("specificTerms:consignor")}
          </Typography>
          <Typography className={classes.grey}>
            {reference?.shipper.street}
          </Typography>
          <Typography className={classes.grey}>
            {reference?.shipper.postalCode}, {reference?.shipper.city}
          </Typography>
          <Typography className={classes.grey}>
            {reference?.shipper.country}
          </Typography>
        </Grid>
        <Grid item container direction="column" md={5} sm={12}>
          <Typography
            variant="caption"
            className={cx(classes.columnTitle, classes.grey)}
          >
            {t("specificTerms:consignee")}
          </Typography>
          <Typography className={classes.grey}>
            {reference?.consignee.street}
          </Typography>
          <Typography className={classes.grey}>
            {reference?.consignee.postalCode}, {reference?.consignee.city}
          </Typography>
          <Typography className={classes.grey}>
            {reference?.consignee.country}
          </Typography>
        </Grid>
        <Grid item container direction="column" md={2} sm={12}>
          <Typography
            variant="caption"
            className={cx(classes.columnTitle, classes.grey)}
          >
            {t("classify:orgId")}
          </Typography>
          <Typography className={classes.grey}>{reference?.orgId}</Typography>
        </Grid>
      </Grid>
    );
  }

  return <Typography className={classes.grey}>{t("error:3005")}</Typography>;
};

type ReferenceProps = {
  identification: string;
  declarations?: {
    file_id: string;
    dr_no: number;
  }[];
};

const Reference = ({ identification, declarations }: ReferenceProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const {
    data: { data, error_code },
    loading,
    error,
  } = useValidateFile(identification);

  return (
    <ContentWrapper>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "nowrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "nowrap",
            alignItems: "flex-start",
          }}
        >
          <CircleIcon color="#604DFF">
            <ListAltIcon />
          </CircleIcon>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography className={classes.title}>{identification}</Typography>
            <Typography className={classes.grey}>
              {t("common:detailsOfSelectedFile")}
            </Typography>
          </Box>
        </Box>
        {!!declarations?.length && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <a
              href={`${config.wedclare}/declaration/${declarations[0].file_id}/${declarations[0].dr_no}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton tooltip={t("We Declare")}>
                <WeDeclareIcon style={{ height: 24, width: 24 }} />
              </IconButton>
            </a>
            <Link
              to={`/select/declaration/${declarations[0].file_id}/${declarations[0].dr_no}`}
            >
              <IconButton color="primary" tooltip={t("New Declaration")}>
                <AddIcon />
              </IconButton>
            </Link>
          </Box>
        )}
      </Box>
      <Divider flexItem={false} className={classes.divider} />
      <ReferenceBody
        reference={data}
        loading={loading}
        error={error || error_code}
      />
    </ContentWrapper>
  );
};

export default Reference;
