import React, { FC, HtmlHTMLAttributes } from "react";
import { Box } from "@periplus/ui-library";

const colors = {
  green: "46, 231, 175",
  purple: "187, 0, 255",
  orange: "255, 153, 0",
  red: "230, 28, 28",
  blue: "34, 174, 248",
  grey: "115, 122, 150",
};

interface DotProps extends HtmlHTMLAttributes<HTMLElement> {
  color?: "green" | "red" | "orange" | "blue" | "purple" | "grey";
  bgColor?: "green" | "red" | "orange" | "blue" | "purple" | "grey";
  adoptTextColor?: boolean;
}

const Dot: FC<DotProps> = ({ bgColor, color, adoptTextColor, ...rest }) => {
  const backgroundColor = bgColor && colors[bgColor];
  const textColor = color && colors[color];

  return (
    <Box
      {...rest}
      id="dot"
      sx={{
        width: "fit-content",
        //@ts-ignore
        padding: "6px",
        borderRadius: "20px",
        display: "inline-block",
        position: "relative",
        ":before": {
          content: '""',
          width: "9px",
          height: "9px",
          borderRadius: "20px",
          opacity: "0.7",
          display: "inline-block",
          position: "absolute",
          top: "50%",
          transform: "translate(0, -50%)",
          backgroundColor: `rgba(${textColor || backgroundColor}, 0.7)`,
          left: `${bgColor ? 6 : 0}px`,
        },
        ...(backgroundColor && {
          backgroundColor: `rgba(${backgroundColor}, 0.2)`,
        }),
        color: adoptTextColor
          ? `rgb(${textColor || backgroundColor})`
          : "inherit",
        whiteSpace: "nowrap",
        ...(rest.children
          ? {
              padding: "3px 8px",
              paddingLeft: `${bgColor ? 24 : 14}px`,
            }
          : {
              minWidth: "14px",
              minHeight: "14px",
            }),
      }}
    />
  );
};

export default Dot;
