/**
 * Contains Role Based Access Control (RBAC) rules
 * Insipired by: https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/
 */

/**
 * Role constants
 * TODO(df): Should use the generated types from GraphQL (the auth/user-mgt endpoint should query the management API to get the currently defined roles)!
 */
export enum Role {
  Manager = "manager",
  Default = "default",
  TechnicalAdministrator = "technical_administrator",
  AlphaTester = "alpha_tester",
  CustomsBroker = "customs_broker",
  Importer = "importer",
  Supplier = "supplier",
  SupplierExtended = "supplier_extended",
}

/**
 * Permission constants
 * Note the "syntax" with the double __ as a seperator between resource and verb
 */
export enum Permission {
  DASHBOARD_PAGE__VISIT = "dashboard:pageVisit",
  USERS__QUERY = "users:query",
  USERS__QUERY_SELF = "users:querySelf",
  DOCUMENTS__CREATE = "documents:create",
  DOCUMENTS__LIST = "documents:list",
  LABELS__MANAGMENT = "documentTypes:managment",
  USERS__LIST = "users:list",
  TENANTS_PAGE__VISIT = "tenants:pageVisit",
  DECLARATION_VIEW__NAVIGATE = "declarationView:navigate",
  DECLARATION_CREATE__NAVIGATE = "declarationCreate:navigate",
  ACCESS_TOKENS__VISIT = "accessTokens:pageVisit",
}

export interface IRule {
  static?: Permission[];
  dynamic?: any; // TODO(df): Type
}

export type TRules<R> = { [key in Role]: R };

const rules: TRules<IRule> = {
  // TODO(df): Or should we by default assume that the administrator can do everything?
  [Role.Manager]: {
    static: [
      Permission.DASHBOARD_PAGE__VISIT,
      Permission.USERS__QUERY,
      Permission.USERS__QUERY_SELF,
      Permission.DOCUMENTS__CREATE,
      Permission.DOCUMENTS__LIST,
      Permission.LABELS__MANAGMENT,
      Permission.USERS__LIST,
    ],
    dynamic: {
      "posts:edit": ({ userId, postOwnerId }: any) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      },
    },
  },
  [Role.TechnicalAdministrator]: {
    static: [Permission.TENANTS_PAGE__VISIT, Permission.ACCESS_TOKENS__VISIT],
  },
  [Role.Default]: {
    static: [Permission.USERS__QUERY_SELF, Permission.DOCUMENTS__LIST],
  },
  [Role.AlphaTester]: {
    static: [Permission.DECLARATION_CREATE__NAVIGATE],
  },
  [Role.CustomsBroker]: {
    static: [Permission.DECLARATION_VIEW__NAVIGATE],
  },
  [Role.Importer]: {
    static: [],
  },
  [Role.Supplier]: {
    static: [],
  },
  [Role.SupplierExtended]: {
    static: [],
  },
};

export default rules;
