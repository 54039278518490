import React, { useCallback } from "react";
import {
  Box,
  Typography,
  useTheme,
  BoxProps,
  styled,
} from "@periplus/ui-library";
import { hexToRgbA } from "utils/colors";
import { DocumentType } from "domain/documentType/types";
import { get } from "lodash";

const PaperBox = styled(Box)<{ selected?: boolean; shadow?: string }>(
  ({ selected = false, shadow }) => ({
    position: "relative",
    borderRadius: "4px",
    boxShadow: shadow,
    ...(selected && {
      "&:after": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: "4px",
        border: "3px solid rgb(63, 81, 181)",
      },
    }),
  })
);

interface ISelectionWrapperProps extends BoxProps {
  selected?: boolean;
  label?: boolean;
  shadowColor?: string; // hex should be 6 digit string with #
  documentData?: {
    documentType: DocumentType | undefined;
    sequenceIndex: number;
  };
  elevation?: number;
}

const darkDocTypes = [
  "commercial_invoice",
  "forwarders_invoice",
  "movement_certificate",
  "ata_carnet",
  "delivery_note",
  "road_consignment_note",
  "customs_summary_declaration_with_commercial_detail_export",
  "customs_summary_declaration_without_commercial_detail",
  "transport_loading_report",
  "transport_loading_report_unloading",
  "fumigation_certificate",
  "transport_equipment_acceptance_order_vessel",
  "house_bill_of_lading",
  "master_air_waybill",
  "house_waybill",
  "settlement_of_a_letter_of_credit",
  "credit_note",
  "dangerous_goods_declaration",
  "safety_hazard_datasheet",
  "power_of_attorney",
];

function SelectionWrapper({
  label,
  children,
  selected,
  documentData,
  shadowColor,
  elevation,
  ...rest
}: ISelectionWrapperProps) {
  const theme = useTheme();
  let shadow = elevation ? theme.shadows[elevation] : "";
  if (shadowColor) {
    shadow = shadow.replace(/rgb.*?\)/g, hexToRgbA(shadowColor, 0.6));
  }

  const renderDocumentType = useCallback(
    ({ sequenceIndex, documentType }) => {
      const sequenceIndexInc = sequenceIndex + 1;
      const fontColor = darkDocTypes.includes(
        documentType && documentType.name
      );

      if (selected || !shadowColor || !label) {
        return null;
      }

      return (
        <div
          style={{
            backgroundColor: `${shadowColor}`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            borderRadius: "4px 4px 0px 0px",
            zIndex: 3,
            marginBottom: "-30px",
          }}
        >
          <Typography
            style={{
              color: !fontColor ? "white" : "black",
              padding: "5px 8px",
            }}
            variant="subtitle2"
            noWrap
          >
            {`${sequenceIndexInc}. ${get(documentType, "translated_name", "")}`}
          </Typography>
        </div>
      );
    },
    [label, selected, shadowColor]
  );

  return (
    <PaperBox
      selected={selected}
      shadow={shadow}
      {...rest}
      style={{ overflow: "hidden" }}
    >
      {documentData && renderDocumentType(documentData)}
      {children}
    </PaperBox>
  );
}

export default SelectionWrapper;
