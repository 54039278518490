import React, { FC } from "react";
import { Loader } from "@periplus/ui-library";
import { RouteChildrenProps } from "react-router";
import { Switch, Redirect } from "react-router-dom";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import PrivateRoute from "components/PrivateRoute/PrivateRoute";

import ImportListTriggers from "./ImportListTriggers/ImportListTriggersList";
import TenantsList from "./Tenants/TenantsList";
import TenantSettings from "./Tenants/TenantSettings";

//import Labels from "./Labels";
import DocumentTypeTemplates from "./DocumentTypeTemplates";
import AccessTokens from "./AccessTokens";
import AddressManagement from "./AdressManagement";
import OrgIds from "./OrgIds";
import RetrainModel from "./RetrainModel";

import { Permission } from "rbac-rules";
import { accessCheck } from "components/PrivateRoute/Can";
import { useAuth } from "contexts/AuthContext";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import Tenant from "./Tenants/Tenant";
import Layout from "components/Layout";
import { NavigationGroup } from "components/Layout/Layout";

const SelectTabs: FC<RouteChildrenProps> = ({ match }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const translate = (text: string) => t(`navigation:${text}`);

  const menuItems: NavigationGroup[] = [
    ...(user &&
    (accessCheck(user.allowedRoles, Permission.LABELS__MANAGMENT) ||
      accessCheck(user.allowedRoles, Permission.USERS__LIST))
      ? [
          {
            groupTitle: translate("general"),
            Icon: SettingsOutlinedIcon,
            items: [
              ...(user &&
              accessCheck(user.allowedRoles, Permission.TENANTS_PAGE__VISIT)
                ? [
                    {
                      text: translate("tenants"),
                      redirecting: "/settings/tenants",
                    },
                    {
                      text: translate("import-list-triggers"),
                      redirecting: "/settings/import-list-triggers",
                    },
                  ]
                : []),
              // ...(user &&
              // accessCheck(user.allowedRoles, Permission.LABELS__MANAGMENT)
              //   ? [
              //       {
              //         text: translate("labels"),
              //         redirecting: "/settings/labels",
              //       },
              //     ]
              //   : []),
              ...(user &&
              accessCheck(user.allowedRoles, Permission.ACCESS_TOKENS__VISIT)
                ? [
                    {
                      text: translate("access-tokens"),
                      redirecting: "/settings/access-tokens",
                    },
                  ]
                : []),
              {
                text: "OrgIds",
                redirecting: "/settings/org-ids",
              },
            ],
          },
        ]
      : []),
    {
      groupTitle: translate("documentClasses"),
      Icon: LibraryBooksOutlinedIcon,

      items: [
        {
          text: translate("address-management"),
          redirecting: "/settings/address-management",
        },
        {
          text: translate("classifier-templates"),
          redirecting: "/settings/classifier-templates",
        },
        {
          text: translate("ai-settings"),
          redirecting: "/settings/retrain-model",
        },
      ],
    },
  ];

  if (!match) {
    return <Loader />;
  }

  return (
    <Layout navigation={menuItems}>
      <Switch>
        <PrivateRoute
          path="/settings"
          exact
          component={() => (
            <Redirect
              to={get(menuItems, "[0].items[0].redirecting") as string}
            />
          )}
        />
        <PrivateRoute
          path={`${match.path}/tenants`}
          exact
          component={TenantsList}
          permission={Permission.TENANTS_PAGE__VISIT}
        />
        <PrivateRoute
          path={`${match.path}/import-list-triggers`}
          exact
          component={ImportListTriggers}
          permission={Permission.TENANTS_PAGE__VISIT}
        />
        <PrivateRoute
          path={`${match.path}/tenants/:id`}
          exact
          component={Tenant}
          permission={Permission.TENANTS_PAGE__VISIT}
        />
        <PrivateRoute
          path={`${match.path}/tenants/:id/settings`}
          exact
          component={TenantSettings}
          permission={Permission.TENANTS_PAGE__VISIT}
        />
        {/* <PrivateRoute
          path={`${match.path}/labels`}
          component={Labels}
          permission={Permission.LABELS__MANAGMENT}
        /> */}
        <PrivateRoute
          path={`${match.path}/address-management`}
          component={AddressManagement}
        />
        <PrivateRoute
          path={`${match.path}/classifier-templates`}
          component={DocumentTypeTemplates}
        />
        <PrivateRoute
          path={`${match.path}/access-tokens`}
          component={AccessTokens}
          permission={Permission.ACCESS_TOKENS__VISIT}
        />
        <PrivateRoute path={`${match.path}/org-ids`} component={OrgIds} />
        <PrivateRoute
          path={`${match.path}/retrain-model`}
          component={RetrainModel}
          permission={Permission.DECLARATION_CREATE__NAVIGATE}
        />
      </Switch>
    </Layout>
  );
};

export default SelectTabs;
