import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation CREATE_USER(
    $userName: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $location: String!
    $orgIds: [Int]
    $password: String
    $requiredActions: [String] = []
    $tenant_id: ID!
    $liberRoles: [String] = []
    $aditRoles: [String] = []
    $permissions: Int
    $defaultRole: String
  ) {
    create_user(
      request: {
        username: $userName
        email: $email
        firstName: $firstName
        lastName: $lastName
        location: $location
        orgIds: $orgIds
        tenantId: $tenant_id
        password: $password
        requiredActions: $requiredActions
        liberRoles: $liberRoles
        aditRoles: $aditRoles
        permissions: $permissions
        defaultRole: $defaultRole
      }
    ) {
      success
      info
    }
  }
`;

export const UPDATE_USER = gql`
  mutation ADIT_UPDATE_USER(
    $id: ID!
    $firstName: String
    $lastName: String
    $location: String
    $tenant_id: ID
    $orgIds: [Int]
    $email: String
    $password: String
    $requiredActions: [String] = []
    $liberRoles: [String] = []
    $aditRoles: [String] = []
    $permissions: Int
    $defaultRole: String
  ) {
    modify_user(
      request: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        location: $location
        tenantId: $tenant_id
        orgIds: $orgIds
        email: $email
        password: $password
        requiredActions: $requiredActions
        liberRoles: $liberRoles
        aditRoles: $aditRoles
        permissions: $permissions
        defaultRole: $defaultRole
      }
    ) {
      success
      info
    }
  }
`;

export const DELETE_USER = gql`
  mutation DELETE_USER($id: ID!) {
    delete_user(request: { id: $id }) {
      success
      info
    }
  }
`;
